import React, { useEffect, useState } from 'react'
import EditIcon from '../../assets/icons/EditIcon'
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import { Line } from 'rc-progress'
import Cropper from 'react-easy-crop'
import AddIcon from '../../assets/icons/AddIcon'
import { TextField, Tooltip } from '@material-ui/core'
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon'
import UserProfileValidation from '../ReusableComponents/UserProfileValidation'
import UploadBanner from '../EditProfile/UploadBanner'
import UploadProfile from '../EditProfile/UploadProfile'
import { useHistory } from 'react-router-dom'
import Topics from './Topics'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserBasicInfo, selectUserDetails, userDetails } from '../../slices/userSlice'
import { TopicsData as topicsData } from '../UserProfile/TopicsData'
import { FileUploader } from 'react-drag-drop-files'
import { handleFreelanceData, updateOrganization, updateShowcase, uploadFile } from '../../service/service'
import { Viewer } from '@react-pdf-viewer/core'
import { preview } from '../../slices/projectSlice'
import { toast } from 'react-hot-toast'

function BasicInfo() {
    const dispatch = useDispatch();
    const {
        bannerImage,
        onButtonClick,
        setSelectedPic,
        inputFile,
        selectImg,
        openBox1,
        closeUploadBanner,
        saveBanner,
        defaultBanner,
        lineProgress,
        setZoom,
        profileImage,
        openBox2,
        closeUploadProfile,
        showCroppedImage,
        crop,
        setCrop,
        zoom,
        onCropComplete,
        fields,
        handleChange,
        flagLoaderButton,
        submitUserForm,
        progressDisplay,
        username
    } = UserProfileValidation({});

    const history = useHistory();
    const user = useSelector(selectUserBasicInfo);
    const userInfo = useSelector(selectUserDetails)
    const [userTopics, setUserTopics] = useState([])

    const [open, setOpen] = useState(false)
    const [showcase, setShowCase] = useState(['', '', ''])
    const [portfolio, setPortfolio] = useState()
    const [isDraggingOver, setIsDraggingOver] = useState(false);


    useEffect(() => {
        if (user) {
            if (user?.showcase) {
                let arr = user?.showcase?.split(",")
                setShowCase(arr);
            }
            setPortfolio(user?.portfolio || '');

            let topics = user?.topics.map(item => Number(item.topic_id));
            let data = topicsData.map(item => {
                return {
                    ...item,
                    icon: item.icon,
                    selectedIcon: item.selectedIcon,
                    selected: topics.includes(Number(item.id))
                }
            });
            setUserTopics(data)
        }
        // if (userInfo)
        //     setPortfolio(userInfo?.organization?.portfolio || '')
    }, [user])

    const uploadImage = (file) => {
        let formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }

    const handleFileUpload = (file, id) => {
        let arr = [...showcase]
        arr[id] = file
        setShowCase(arr)
    }

    const submitShowCase = async () => {
        if (showcase[0] || showcase[1] || showcase[2]) {
            let arr = [...showcase]
            if (typeof (showcase[0]) == 'object')
                arr[0] = await uploadImage(showcase[0]).then(res => res.data?.url) || ''
            if (typeof (showcase[1]) == 'object')
                arr[1] = await uploadImage(showcase[1]).then(res => res.data?.url) || ''
            if (typeof (showcase[2]) == 'object')
                arr[2] = await uploadImage(showcase[2]).then(res => res.data?.url) || ''

            setShowCase(arr)
            let res = await updateShowcase({ showcase: arr?.join(",") })
        }
    }

    const updatePortfolio = async (file) => {
        let url = await uploadImage(file).then(res => res.data?.url);
        if (url) {
            setPortfolio(url);
            let res = await handleFreelanceData({ portfolio: url })
            if (res.success && res.data != null) {
                let userInfo = { ...user, portfolio: url }
                dispatch(userDetails(userInfo));
                toast.success("Portfolio Updated", { id: 'success' })
            }
            else {
                toast.error("Unable to update the portfolio", { id: 'error' });
            }
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDraggingOver(false);
        const file = e.dataTransfer.files[0];
        if (file?.type?.slice(0, 5) == 'image')
            updatePortfolio(file)
    };

    return (
        <>
            <h1 className="text-2xl font-semibold text-left mb-3">Basic Information</h1>
            <div className='rounded-lg overflow-hidden shadow-lg shadow-gray-200 bg-[#F6FAFD]'>
                <div className='relative w-full'>
                    <EditIcon
                        className='bg-gray-50 rounded-full p-2 w-10 h-10 cursor-pointer absolute top-2 right-2'
                        onClick={() => {
                            onButtonClick()
                            setSelectedPic('banner')
                        }}
                    />
                    <img
                        src={
                            !bannerImage
                                ? UserBannerImage
                                : bannerImage
                        }
                        alt='banner'
                        className='w-full h-44 object-cover z-10'
                    />
                    <input
                        type='file'
                        ref={inputFile}
                        onChange={e => selectImg(e)}
                        className='hidden'
                        accept='image/*'
                    />
                    <UploadBanner
                        image={bannerImage}
                        openBox={openBox1}
                        closeUpload={closeUploadBanner}
                        isSaved={saveBanner}
                        default={defaultBanner}
                    >
                        {progressDisplay && <Line
                            percent={lineProgress}
                            strokeWidth='2'
                            strokeColor='#1D85E8'
                            trailWidth='2'
                            trailColor='#DAE9F7'
                            className='w-1/4 top-[35%] left-[35%] fixed z-50'
                        />}
                    </UploadBanner>
                </div>
                <div className='grid place-items-center -mt-20'>
                    {
                        !profileImage ?
                            <DefaultUserIcon className='w-40 h-40 z-20 shadow-lg rounded-full bg-white' />
                            :
                            <img src={profileImage} alt='profile_pic' className='w-40 h-40 z-20 shadow-lg rounded-full bg-white' />
                    }
                    <AddIcon className='bg-gray-50 rounded-full p-1 w-9 h-9 shadow-md cursor-pointer transform -translate-y-12 translate-x-14 z-30'
                        onClick={() => {
                            onButtonClick()
                            setSelectedPic('profile')
                        }} />
                    <UploadProfile
                        image={profileImage}
                        openBox={openBox2}
                        closeUpload={closeUploadProfile}
                        showCroppedImage={showCroppedImage}
                    >
                        <Cropper
                            showGrid={false}
                            image={profileImage}
                            crop={crop}
                            onCropChange={setCrop}
                            zoom={zoom}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            cropShape='round'
                            zoomWithScroll={true}
                            cropSize={{ width: 300, height: 300 }}
                        />
                        {progressDisplay && <Line
                            percent={lineProgress}
                            strokeWidth='2'
                            strokeColor='#1D85E8'
                            trailWidth='2'
                            trailColor='#DAE9F7'
                            className='w-1/4 top-3/4 left-[35%] fixed z-50'
                        />}
                    </UploadProfile>
                </div>

                <form className="flex flex-col justify-center items-center w-full mx-auto gap-6 px-5 pb-5">
                    <div className='w-full flex items-center gap-6 flex-wrap sm:flex-nowrap'>
                        <div className='max-w-xs'>
                            <TextField size="small" label="Full Name" name="fullname" id="fullname" value={fields.fullname} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                        </div>
                        <div className='max-w-xs'>
                            <TextField size="small" label="Username" name="username" id="username" value={username || ''} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                        </div>
                        <div className='max-w-xs'>
                            <TextField size="small" label="Profession" name="profession" id="profession" value={fields.profession || ''} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                        </div>
                    </div>
                    <div className='w-full max-w-xs mr-auto'>
                        <h1 className='text-gray-800 font-medium mb-1'>User Email</h1>
                        <div className='py-2 px-3 w-full rounded-lg border border-gray-300 bg-gray-100 text-gray-800'>
                            {fields.email}
                        </div>
                    </div>
                    <div className='w-full h-32 rounded-md relative'>
                        <textarea id='bio' name='bio' value={fields.bio} onChange={handleChange} placeholder="Tell something about yourself under 120 characters" className='border resize-none outline-non p-3 leading-relaxed text-lg w-full h-full'>
                        </textarea>
                        <p className='text-blue text-xs py-1 px-2 transform -translate-y-8 text-right tracking-wide'>{fields.bio?.length}/120 characters</p>
                    </div>
                    <div className='flex items-center gap-2 w-full'>
                        <h1 className='text-lg font-medium'>Interests</h1>
                        <EditIcon className='cursor-pointer' onClick={() => setOpen(true)} />
                    </div>
                    <div className='flex items-center gap-4 flex-wrap -mt-4 w-full'>
                        {userTopics?.map(obj => obj.selected && <div key={obj.id} className="flex flex-col items-center justify-center gap-2 cursor-pointer">
                            <Tooltip className='w-12' title={obj.title}>
                                {obj.icon}
                            </Tooltip>
                            {/* <h1 className='text-center text-xs font-medium'>
                                {obj.title}
                            </h1> */}
                        </div>
                        )}
                    </div>

                    {userInfo?.plan?.base_price != 0 ? <div className='w-full my-4'>
                        <h1 className='text-lg font-medium mb-2'>Portfolio</h1>
                        <div
                            onDragEnter={() => setIsDraggingOver(true)}
                            onDragLeave={() => setIsDraggingOver(false)}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            className='w-[300px] h-60 relative rounded-lg bg-white cursor-pointer group overflow-hidden grid place-items-center'>
                            <div className='absolute inset-0 bg-black bg-opacity-50 hidden group-hover:flex z-10 flex-col gap-5 items-center justify-center text-white'>

                                <label htmlFor='pdf'>
                                    <div className='bg-white text-gray-800 px-4 rounded-lg py-2 font-medium cursor-pointer'>
                                        Upload PDF
                                        <input multiple={false} onChange={(e) => {
                                            e.preventDefault();
                                            const file = e.target.files[0];
                                            if (file) {
                                                if (file?.type == 'application/pdf')
                                                    updatePortfolio(file)
                                                else
                                                    toast.error("Invalid File Format")
                                            }
                                        }} type='file' id='pdf' accept='application/pdf' className='hidden' />
                                    </div>
                                </label>

                                {portfolio ? <div className='bg-white text-gray-800 px-4 rounded-lg py-2 font-medium cursor-pointer' onClick={() => dispatch(preview({ isOpen: true, pdfUrl: portfolio, title: 'Portfolio' }))}>
                                    View PDF
                                </div> : ''}
                            </div>

                            {portfolio ?
                                <div className='rounded-lg h-60 hover:shadow-lg pb-2 max-w-[300px] min-w-[300px] transition-all duration-100 ease-out'>
                                    <div className='w-full h-52 relative overflow-y-scroll cursor-pointer rounded-lg transform transition-all duration-300 ease-out'>
                                        <Viewer
                                            fileUrl={portfolio}
                                        />
                                    </div>
                                </div>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-gray-800">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            }

                            {isDraggingOver && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: 'rgba(0, 0, 0, 0.2)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        fontSize: '20px',
                                    }}
                                >
                                    Drop pdf here
                                </div>
                            )}
                        </div>
                    </div> : <></>}

                    {userInfo?.plan?.base_price == 0 ? <></> : <div className='w-full my-4'>
                        <h1 className='text-lg font-medium'>Showcase</h1>
                        <p>Upload three of your favorite words as jpegs/png you would like to showcase to the word</p>
                        <div className='flex items-baseline gap-4 relative py-4'>
                            <FileUploader
                                multiple={false} // set true if multiple file needs to be uploaded
                                handleChange={(file) => handleFileUpload(file, 0)} // function to get uploaded files
                                name="file"
                                types={['jpg', 'jpeg', 'png']} // array of extension
                                children={
                                    <div className='w-80 h-80 rounded-lg bg-gray-300 cursor-pointer overflow-hidden grid place-items-center'>
                                        {showcase[0] ? <img className='w-full h-full object-cover' src={typeof (showcase[0]) == 'object' ? URL.createObjectURL(showcase[0]) : showcase[0]} alt='' /> :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-gray-800">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                            </svg>
                                        }
                                    </div>
                                }
                            />


                            <div>
                                <FileUploader
                                    multiple={false} // set true if multiple file needs to be uploaded
                                    handleChange={(file) => handleFileUpload(file, 1)} // function to get uploaded files
                                    name="file"
                                    types={['jpg', 'jpeg', 'png']} // array of extension
                                    children={
                                        <div className='w-60 h-40 rounded-lg bg-gray-300 cursor-pointer overflow-hidden grid place-items-center'>
                                            {showcase[1] ? <img className='w-full h-full object-cover' src={typeof (showcase[1]) == 'object' ? URL.createObjectURL(showcase[1]) : showcase[1]} alt='' /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-gray-800">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                                </svg>
                                            }
                                        </div>
                                    }
                                />
                                <FileUploader
                                    multiple={false} // set true if multiple file needs to be uploaded
                                    handleChange={(file) => handleFileUpload(file, 2)} // function to get uploaded files
                                    name="file"
                                    types={['jpg', 'jpeg', 'png']} // array of extension
                                    children={
                                        <div className='w-40 h-40 rounded-lg bg-gray-300 mt-4 cursor-pointer overflow-hidden grid place-items-center'>
                                            {showcase[2] ? <img className='w-full h-full object-cover' src={typeof (showcase[2]) == 'object' ? URL.createObjectURL(showcase[2]) : showcase[2]} alt='' /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-gray-800">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                                </svg>
                                            }
                                        </div>
                                    }
                                />

                            </div>
                        </div>
                    </div>}

                    <div className="flex items-center w-full justify-end gap-6">
                        <button onClick={() => history.replace(`/userProfile/${username}`)} className='py-2 px-6 text-lg font-medium rounded-lg flex-shrink-0 text-blue border border-blue-500 disabled:text-gray-600'>
                            Back
                        </button>
                        <button disabled={flagLoaderButton} type='submit' onClick={(e) => { submitShowCase(); submitUserForm(e) }} className='flex-shrink-0 bg-blue py-2 px-10 text-lg text-white font-semibold rounded-lg'>
                            Save
                            {flagLoaderButton ? <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block ml-2" viewBox="0 0 24 24"></svg> : ''}
                        </button>
                    </div>
                </form >
            </div >

            {
                user && open && <Topics setOpen={setOpen} topics={userTopics} />
            }
        </>
    )
}

export default BasicInfo