import React, { useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { addUserTopics } from '../../service/service'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserBasicInfo, userBasicInfo } from '../../slices/userSlice'


function Topics({ setOpen, topics }) {
    const [flag, setFlag] = useState(false)
    const [id, setId] = useState(0)
    const [topicsDetails, setTopicsDetails] = useState(topics);
    const userData = useSelector(selectUserBasicInfo);
    const dispatch = useDispatch()
    const [flagLoaderButton, setFlagLoaderButton] = useState(false)

    const setTopicAndFlag = () => {
        if (flag) {
            topicsDetails.forEach(topic => {
                if (Number(topic.id) === Number(id)) {
                    topic.selected = !topic.selected
                }
            })
            setTopicsDetails(topicsDetails)
        }
        setFlag(false);
    }

    useEffect(() => {
        if (flag)
            setTopicAndFlag();
    }, [flag])

    const setSelectedIcon = (id, e) => {
        setFlag(true)
        setId(id)
    }

    const saveTopic = async (e) => {
        e.preventDefault();
        setFlagLoaderButton(true)
        let data = [];
        let newTopics = []
        topicsDetails.forEach(i => {
            if (i.selected === true) {
                data.push(i.id)
                newTopics.push({
                    topic_id: i.id,
                    topic: i.topics
                })
            }
        })
        const res = await addUserTopics({ topics: data.toString() })
        if (res && res.success) {
            dispatch(userBasicInfo({ ...userData, topics: newTopics }))
            setOpen(false);
            toast.success('Updated Successfully', { id: 'success' })
        } else {
            toast.error("Error while adding topics", { id: 'error' })
        }
        setFlagLoaderButton(false)

    }

    console.log(topicsDetails)

    return (
        <>
            <> <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 ' style={{ zIndex: 1000 }} onClick={() => setOpen(false)}>
            </div>
                <div className='p-5 md:px-10 bg-white w-full max-w-4xl rounded-lg absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' style={{ zIndex: 1001 }}>

                    <div className='mx-auto w-full'>
                        <h1 className="text-3xl lg:text-4xl font-bold text-left mb-3">Pick topics you love</h1>
                        <p className='text-lg'>This will help us to bring best recommendations for you</p>
                        <div className='flex items-center justify-around gap-6 flex-wrap w-full my-10 mx-auto'>
                            {topicsDetails?.map(obj => <div key={obj.id}>
                                <IconButton onClick={e => setSelectedIcon(obj.id, e)}>
                                    {obj.selected ? obj.selectedIcon : obj.icon}
                                </IconButton>
                                <h1 className='text-center text-lg font-medium'>
                                    {obj.title}
                                </h1>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-around w-full mb-6 mt-10'>
                        <button className='bg-red rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => setOpen(false)}>
                            Cancel
                        </button>
                        <button className='bg-blue rounded-lg px-8 py-2 text-lg text-white capitalize disabled:bg-gray-500' disabled={flagLoaderButton} onClick={(e) => saveTopic(e)}>
                            {flagLoaderButton ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </div>
            </>
        </>
    )
}

export default Topics
