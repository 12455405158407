import React from 'react'
import { Line } from 'rc-progress'
import Cropper from 'react-easy-crop'
import AddIcon from '../../assets/icons/AddIcon'
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import UploadBanner from '../EditProfile/UploadBanner'
import UploadProfile from '../EditProfile/UploadProfile'
import UserProfileValidation from '../ReusableComponents/UserProfileValidation'
import { TextField } from '@material-ui/core'
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon'
import ArrowWhite from '../../assets/icons/ArrowWhite'
import EditIcon from '../../assets/icons/EditIcon'
import { useState } from 'react'
import { useEffect } from 'react'

function ProfileStepper(props) {
  const { handleBack, activeStep } = props
  const {
    bannerImage,
    onButtonClick,
    setSelectedPic,
    inputFile,
    selectImg,
    openBox1,
    closeUploadBanner,
    saveBanner,
    defaultBanner,
    lineProgress,
    setZoom,
    profileImage,
    openBox2,
    closeUploadProfile,
    showCroppedImage,
    crop,
    setCrop,
    zoom,
    onCropComplete,
    fields,
    handleChange,
    flagLoaderButton,
    submitUserForm,
    progressDisplay,
  } = UserProfileValidation(props)

  return (
    <div className='max-w-3xl mx-auto'>
      <h1 className="text-3xl lg:text-4xl font-bold text-left mb-3">Profile</h1>
      <div className='relative w-full'>
        <EditIcon
          className='bg-gray-50 rounded-full p-2 w-10 h-10 cursor-pointer absolute top-2 right-2'
          onClick={() => {
            onButtonClick()
            setSelectedPic('banner')
          }}
        />
        <img
          src={
            !bannerImage
              ? UserBannerImage
              : bannerImage
          }
          alt='banner'
          className='w-full h-44 object-cover z-10'
        />
        <input
          type='file'
          ref={inputFile}
          onChange={e => selectImg(e)}
          className='hidden'
          accept='image/*'
        />
        <UploadBanner
          image={bannerImage}
          openBox={openBox1}
          closeUpload={closeUploadBanner}
          isSaved={saveBanner}
          default={defaultBanner}
        >
          {progressDisplay && <Line
            percent={lineProgress}
            strokeWidth='2'
            strokeColor='#1D85E8'
            trailWidth='2'
            trailColor='#DAE9F7'
            className='w-1/4 top-[35%] left-[35%] fixed z-50'
          />}
        </UploadBanner>
      </div>
      <div className='grid place-items-center -mt-20'>
        {
          !profileImage ?
            <DefaultUserIcon className='w-40 h-40 z-20 shadow-lg rounded-full bg-white' />
            :
            <img src={profileImage} alt='profile_pic' className='w-40 h-40 z-20 shadow-lg rounded-full bg-white' />
        }
        <AddIcon className='bg-gray-50 rounded-full p-1 w-9 h-9 shadow-md cursor-pointer transform -translate-y-12 translate-x-14 z-30'
          onClick={() => {
            onButtonClick()
            setSelectedPic('profile')
          }} />
        <UploadProfile
          image={profileImage}
          openBox={openBox2}
          closeUpload={closeUploadProfile}
          showCroppedImage={showCroppedImage}
        >
          <Cropper
            showGrid={false}
            image={profileImage}
            crop={crop}
            onCropChange={setCrop}
            zoom={zoom}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            cropShape='round'
            zoomWithScroll={true}
            cropSize={{ width: 300, height: 300 }}
          />
          {progressDisplay && <Line
            percent={lineProgress}
            strokeWidth='2'
            strokeColor='#1D85E8'
            trailWidth='2'
            trailColor='#DAE9F7'
            className='w-1/4 top-3/4 left-[35%] fixed z-50'
          />}
        </UploadProfile>
      </div>
      <h1 className="text-3xl lg:text-4xl font-bold text-left mb-3">Account</h1>

      <form className="flex flex-col justify-center items-center w-full mx-auto gap-6 p-5">
        <div className='w-full flex items-center gap-6 flex-wrap'>
          <div className=' max-w-xs'>
            <TextField size="small" label="Full Name" name="fullname" id="fullname" value={fields.fullname} onChange={handleChange} variant="outlined" className={`w-full`} />
          </div>
          <div className='max-w-xs'>
            <TextField size="small" label="Profession" name="profession" id="profession" value={fields.profession || ''} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
          </div>
        </div>
        <div className='w-full h-32 rounded-md relative'>
          <textarea id='bio' name='bio' value={fields.bio} onChange={handleChange} placeholder="Tell something about yourself under 120 characters" className='border resize-none outline-non p-3 leading-relaxed text-lg w-full h-full'>
          </textarea>
          <p className='text-blue text-xs py-1 px-2 transform -translate-y-8 text-right'>{fields.bio?.length}/120 characters</p>
        </div>

        <div className="flex items-center gap-6">
          <button disabled={activeStep === 0} onClick={handleBack} className='py-2 px-6 text-lg font-semibold rounded-lg flex-shrink-0 mx-4 disabled:text-gray-600 border border-blue-500 text-blue disabled:border-gray-500'>
            Back
          </button>
          <button disabled={flagLoaderButton} type='submit' onClick={submitUserForm} className='flex-shrink-0 bg-blue py-2 px-6 text-lg text-white font-semibold rounded-lg border border-blue-500'>
            Save & Next
            {flagLoaderButton ? <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block ml-2" viewBox="0 0 24 24"></svg> : <ArrowWhite className="inline-flex w-7 h-7 mx-2" />}
          </button>
        </div>
      </form >

    </div >
  )
}
export default ProfileStepper
