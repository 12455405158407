import React, { useRef, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { addNewPreview, addNewPreviewFolder, deletefileAndFolder, editRepositoryById, fileDeletePreview, folderDeletePreview, getAllFilesList, uploadFile } from '../../service/service';
import { useClickOutside } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux'
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { useHistory } from 'react-router-dom';
import fileimg from '../../assets/images/Files.png'
import toast from 'react-hot-toast';
import moment from 'moment';
import { Popover, Tooltip } from '@material-ui/core';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import { fileList, filePreview } from '../../slices/fileSlice';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import { selectUserDetails } from '../../slices/userSlice';
import { preview } from '../../slices/projectSlice';

const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', 'jfif']

function FileList({ isAccess, fileData, repoName, projectName, userName, setFolderList, isRoot, setIsRoot, setFileData, repoDetail, downloadFolderZip, setLastFolder, retailer }) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const imgRef = useRef()
    const moreRef = useRef()
    const dispatch = useDispatch();
    const user = useSelector(selectUserDetails)

    useClickOutside(() => setMore(false), moreRef)

    async function getFreshFileList() {
        const res = await getAllFilesList(Number(fileData.branch_id));

        if (res.data?.length) {
            if (res.data?.length > 1) {
                setFolderList(res.data)
                dispatch(fileList(res.data));
                setIsRoot(true)
            }
            else if (res.data[0]?.children) {
                setFolderList(res.data[0]?.children)
                dispatch(fileList(res.data));
                setIsRoot(true)
            }
        }
        else {
            setFolderList([])
            dispatch(fileList([]));
            setIsRoot(true)
        }
    }

    const deleteClick = () => {
        let data = {}
        let { type, branch_id, folder_id, file_id } = fileData
        data = { type: type, branch_id: branch_id, folder_id: folder_id, file_id: file_id }
        deleteFileAndFolder(data)
        setDeleteModal(false);
    }
    const deleteFileAndFolder = async (data) => {
        const res = await deletefileAndFolder(data);
        if (res.success) {
            toast.success(res.message, { id: 'success' })
            getFreshFileList()
        }
    }

    const handleFolderClick = () => {
        if (fileData?.type == "file") {
            if (extensions.includes(fileData?.fileFormat?.toLowerCase())) {
                if (fileData?.commitAttachment && fileData?.commitAttachment !== 'undefined') {
                    dispatch(filePreview({ url: fileData?.commitAttachment, title: fileData.name, open: true }));
                }
                else if (fileData?.file_url && fileData?.file_url !== 'undefined') {
                    dispatch(filePreview({ url: fileData?.file_url, title: fileData.name, open: true }));
                }
            }
            else {
                if (fileData?.fileFormat?.toLowerCase() == '.pdf' && fileData?.file_url && fileData?.file_url !== 'undefined') {
                    dispatch(preview({ isOpen: true, pdfUrl: fileData?.file_url, title: fileData?.name }))
                }
            }
        }
        else if (fileData?.children?.length) {
            setLastFolder((prev) => [... new Set([...prev, fileData.folder_id])])
            setFolderList(fileData?.children);
            setIsRoot(false)
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }


    const handleImage = async (file) => {
        if (file) {
            const res = await uploadImage(file);
            if (res.data) {
                try {
                    const formData = new FormData();
                    let previewRes = ''
                    if (fileData.type === "folder") {
                        formData.append('repo_id', fileData.repo_id)
                        formData.append('branch_id', fileData.branch_id)
                        formData.append('type', fileData.type)
                        formData.append('dir_path', fileData.path)
                        formData.append('previewUrl', res.data.url)
                        previewRes = await addNewPreviewFolder(formData)
                    } else {
                        formData.append('previewUrl', res.data.url)
                        previewRes = await addNewPreview(fileData.file_id, formData)
                    }
                    getFreshFileList();
                    handleClose()
                }
                catch (e) {
                    console.log(e)
                    throw new Error(e)
                }
            }
        }
    }

    const deletePreview = async () => {
        let res = {}
        if (fileData?.type == 'folder')
            res = await folderDeletePreview(fileData?.folder_id);

        else
            res = await fileDeletePreview(fileData?.file_id);

        if (res?.success) {
            toast.success("Preview removed successfully")
            getFreshFileList();
            handleClose()
        }
        else
            toast.error("Unable to remove Preview")
    }

    async function editRepo(data) {
        try {
            await editRepositoryById(data);
        } catch (error) {
            return error
        }
    }

    return (
        <>
            <tr className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                <td onClick={handleFolderClick}>
                    {fileData.type === 'folder' ? (
                        <FolderIcon className='w-10 h-10 object-contain cursor-pointer' />
                    ) : (
                        <img
                            className='w-10 h-10 object-contain'
                            src={fileimg}
                            alt='file-icon'
                        />
                    )}
                </td>
                <td className='font-medium text-gray-800 cursor-pointer' >
                    <p className='flex items-center gap-1'>
                        <Tooltip title={fileData.name}><span onClick={handleFolderClick}>{fileData.name.length > 16 ? `${fileData.name.slice(0, 15)}...` : fileData.name}</span></Tooltip>
                        {(fileData?.commitLink && fileData?.commitLink !== 'undefined')
                            &&
                            <a href={fileData?.commitLink?.includes('http') ? fileData?.commitLink : `https://${fileData?.commitLink}`} rel="noreferrer" target="_blank">  <svg className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                                <g clip-path="url(#clip0_840_314)">
                                    <path d="M9.77215 8.22823C9.3626 7.81884 8.80722 7.58887 8.22815 7.58887C7.64907 7.58887 7.0937 7.81884 6.68415 8.22823L5.13965 9.77222C4.73009 10.1818 4.5 10.7373 4.5 11.3165C4.5 11.8957 4.73009 12.4512 5.13965 12.8607C5.54921 13.2703 6.10469 13.5004 6.6839 13.5004C7.26311 13.5004 7.81859 13.2703 8.22815 12.8607L9.00015 12.0887" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                                    <path d="M8.22852 9.77215C8.63807 10.1815 9.19344 10.4115 9.77252 10.4115C10.3516 10.4115 10.907 10.1815 11.3165 9.77215L12.861 8.22815C13.2706 7.81859 13.5007 7.26311 13.5007 6.6839C13.5007 6.10469 13.2706 5.54921 12.861 5.13965C12.4515 4.73009 11.896 4.5 11.3168 4.5C10.7376 4.5 10.1821 4.73009 9.77252 5.13965L9.00052 5.91165" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_840_314">
                                        <rect width="12" height="12" fill="white" transform="translate(3 3)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            </a>
                        }
                        {(fileData?.commitAttachment && fileData?.commitAttachment !== 'undefined') &&
                            <svg onClick={() => dispatch(filePreview({ url: fileData?.commitAttachment, title: fileData.name, open: true }))} className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                                <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
                            </svg>
                        }
                    </p>
                    <p className='text-gray-500 font-light mt-3 text-xs'>Added {moment(fileData.created_at).fromNow()}</p>
                </td>
                <td className='max-w-sm'>
                    <p className='max-w-sm'>{(fileData.commitText && fileData.commitText !== "undefined") ? fileData.commitText : 'file uploaded'}</p>
                </td>
                <td>{fileData?.user_name || ''}</td>
                <td>{fileData.fileFormat || fileData.type || ''}</td>
                <td className='relative'>
                    <div className='flex gap-4 relative max-w-fit mx-auto' ref={moreRef}>
                        {fileData?.user_name == user?.username && <DeleteIcon className='cursor-pointer' onClick={() => setDeleteModal(true)} />}
                        {(retailer ? retailer?.status == 'accepted' : true) ? <DownloadIcon className='cursor-pointer' onClick={async () => {
                            if (fileData.type === 'folder') {
                                downloadFolderZip(fileData?.children, { path: fileData.name, name: fileData.name }, 'folder')
                            }
                            else {
                                let tId = toast.loading("Downloading file ...");
                                fetch(fileData.file_url, {
                                    method: 'GET',
                                    mode: 'cors',
                                    cache: 'no-cache',
                                    credentials: 'same-origin',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    redirect: 'follow',
                                    referrerPolicy: 'no-referrer'
                                })
                                    .then(resp => resp.blob())
                                    .then(blob => {
                                        const url = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.style.display = 'none';
                                        a.href = url;
                                        a.download = fileData.name;
                                        document.body.appendChild(a);
                                        a.click();
                                        window.URL.revokeObjectURL(url);
                                        toast.success("File is downloaded ")
                                    })
                                    .catch(() => toast.error('Unable to download file at this moment'));
                                toast.dismiss(tId)
                            }
                        }} /> : ''}

                        {fileData?.user_name == user?.username ? <MoreVertIcon className='cursor-pointer' onClick={handleClick} /> : <>
                            {(isAccess && fileData.type !== 'folder') && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-yellow cursor-pointer" onClick={() => history.push(`/${userName}/${projectName}/${repoName}/${fileData.file_id}/issue/new`)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            }
                        </>}
                        <Popover
                            className='mt-2 min-w-32'
                            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className='popup-bg py-1 px-2 relative'>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => {
                                    if (fileData?.commitAttachment && fileData?.commitAttachment !== 'undefined') {
                                        dispatch(filePreview({ url: fileData?.commitAttachment, title: fileData.name, open: true }));
                                        handleClose();
                                    }
                                    else if (fileData?.file_url && fileData?.file_url !== 'undefined' && extensions.includes(fileData?.fileFormat?.toLowerCase())) {
                                        dispatch(filePreview({ url: fileData?.file_url, title: fileData.name, open: true }));
                                        handleClose();
                                    }
                                    else if (fileData?.commitLink && fileData?.commitLink !== 'undefined') {
                                        window.open(fileData?.commitLink, "_blank")
                                        handleClose();
                                    }
                                    else
                                        toast("Preview not available", { id: 'preview', style: { border: '1px solid #FF8082', color: 'red' } })
                                }}>Open Preview</h1>
                                {fileData?.file_url && fileData?.file_url !== 'undefined' && extensions.includes(fileData?.fileFormat?.toLowerCase()) ?
                                    <></>
                                    : <><h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => { handleClose(); setFileData(fileData) }}>Upload New Preview</h1>
                                        <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => (fileData?.commitAttachment && fileData?.commitAttachment !== 'undefined' || fileData?.commitLink && fileData?.commitLink !== 'undefined') ? deletePreview() : toast("No preview available", { id: 'preview', style: { border: '1px solid #FF8082', color: 'red' } })}>Delete Preview</h1>
                                    </>}
                                {fileData.type !== 'folder' && <h1 className='font-medium text-base text-gray-800 hover:text-yellow-500 cursor-pointer p-1' onClick={() => history.push(`/${userName}/${projectName}/${repoName}/${fileData.file_id}/issue/new`)}>Create Issue</h1>}
                                {(fileData?.file_url && fileData?.file_url !== 'undefined' && extensions.includes(fileData?.fileFormat?.toLowerCase())) &&
                                    <h1 className='font-medium text-base text-gray-800 hover:text-yellow-500 cursor-pointer p-1' onClick={() => {
                                        let data = { name: repoDetail.name, description: repoDetail.description, repository_type: repoDetail.repository_type, id: repoDetail.id, project_id: repoDetail.project_id, thumbnail: fileData?.file_url }
                                        toast.promise(
                                            editRepo(data),
                                            {
                                                loading: 'Updating Thumbnail...',
                                                success: <p>Thumbnail Uploaded!</p>,
                                                error: <p>Could not update Thumbnail!</p>,
                                            }
                                        )
                                    }}>Set as thumbnail</h1>}
                                <input type="file" className='hidden' ref={imgRef} onChange={(e) => toast.promise(
                                    handleImage(e.target.files[0]),
                                    {
                                        loading: 'Uploading Preview...',
                                        success: <p>Preview Uploaded!</p>,
                                        error: <p>Could not upload Preview!</p>,
                                    }
                                )} />
                            </div>
                        </Popover>
                    </div>

                    <p className={`text-gray-500 font-light text-xs mt-3 text-center`}>{fileData.type === 'folder' ? '' : <span className={`${fileData?.type == 'file' ? 'font-medium text-blue-500' : ''} cursor-pointer`} onClick={() => fileData?.type == 'file' ? history.push(`/${userName}/${projectName}/${repoName}/${fileData?.branch_id}/file/${fileData?.file_id}`) : {}}>{(retailer ? retailer?.status == 'accepted' : true) ? <>Version History<br /></> : <></>}</span>}Updated {moment(fileData.updated_at).fromNow()}</p>
                </td>
            </tr>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${fileData.name}"`} type="warn" onCancel={() => setDeleteModal(false)} onOk={() => deleteClick()} />
        </>
    )
}

export default FileList