import React, { useState, useEffect, useRef } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone';
import { CloudUpload } from '@material-ui/icons';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import fileimg from '../../assets/images/Files.png'
import { useClickOutside } from '../../utils/helper';
import { addFiles, getProjectDetails, uploadFile } from '../../service/service';
import ConfirmModal from '../ReusableComponents/ConfirmModal';

const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', 'jfif']

const arrayUniqueByKey = (key, array) => [...new Map(array.map(item =>
    [item[key], item])).values()];

function formatBytes(a, b = 2) { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return `${Math.round(parseFloat((a / Math.pow(1024, d)).toFixed(c)))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }

function Upload({ storage, setIsUpload, repoDetail, downloadFolderName, getFiles, currentBranch, folders }) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [fileJson, setFileJson] = useState();
    const [folderName, setFolderName] = useState('');
    const [modal, setModal] = useState(false);
    const [notEnoughStorage, setNotEnoughStorage] = useState(false);
    const [projectDetail, setProjectDetail] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isNewFolder, setIsNewFolder] = useState(false);
    const [isOldFolder, setIsOldFolder] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [folderCount, setFolderCount] = useState(0);
    const [folderList, setFolderList] = useState([]);
    const [showFile, setShowFile] = useState([])

    useEffect(() => {
        setFolderList(folders)
    }, [folders])

    const projectInfo = async () => {
        try {
            let res = await getProjectDetails(repoDetail.projectname);
            if (res.success) {
                setProjectDetail(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => { projectInfo() }, [])


    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve(result.data)
        })
    }

    const uploadFiles = async (newJson) => {
        let json = newJson
        for (let i = 0; i < json?.length; i++) {
            console.log(json[i].type == 'folder')
            if (json[i].type == 'folder') {
                break;
            }
            let url = await uploadImage(acceptedFiles[i]);
            console.log(url)
            json[i].azureUrl = url;
            // console.log(json[i]);
        }
        return json;
    }

    const handleFiles = async () => {
        setIsLoading(true);
        if (fileJson?.length) {
            let toastId = toast.loading("Uploading Files");
            let newJson = await uploadFiles(fileJson);
            setIsNewFolder(false);
            setIsUpload(false);
            setFileJson();
            setIsOldFolder(false);
            setFileCount(0);
            setFolderCount(0)
            let obj = {
                branch_id: currentBranch.id,
                repo_id: repoDetail.id,
                msg: folderName,
                json_data: newJson,
                platform: 'web'
            }
            setFolderName('')
            let res = await addFiles(obj);
            if (res.success) {
                getFiles();
                toast.success("Files uploaded successfully", { id: toastId });
                setIsNewFolder(false);
                setIsUpload(false);
                setFileJson();
                setIsOldFolder(false)
            }
            else
                toast.error("Unable to upload Files", { id: toastId })
        }
        setIsLoading(false);
    }
    const handleDone = (data) => {
        const { fileData, openType } = data;
        let fileCopy = fileJson;
        let idx = fileCopy.findIndex(item => item.name == fileData.name);
        if (idx > -1) {
            let newObj = { ...fileCopy[idx] }
            switch (openType) {
                case "text":
                    if (data.commitText)
                        newObj.commitText = data.commitText
                    break;
                case "link":
                    if (data.commitLink)
                        newObj.commitLink = data.commitLink
                    break;
                case "image":
                    if (data.commitImage)
                        newObj.commitAttachment = data.commitImage
                    break;
                default: console.log("hello");
            }
            fileCopy[idx] = newObj;
            setFileJson([...fileCopy])
        }
    }

    const onFolderClick = (pth) => {
        let arr = []
        for (let i = 0; i < fileJson?.length; i++) {
            if (fileJson[i].type == 'folder')
                break;
            else {
                if (fileJson[i].path == `${pth}/${fileJson[i].name}`) {
                    arr.push(fileJson[i])
                }
            }
        }
        setShowFile(arr)
    }

    useEffect(() => {
        if (!folderCount && fileJson) {
            setShowFile(fileJson)
        }
    }, [fileJson, folderCount])

    useEffect(() => {
        let size = 0;
        let folders = []
        let fl = acceptedFiles.map(file => {
            size += file.size;
            let arr = file.path.split("/");
            arr.pop();
            let folderName = arr.pop();
            let folderPath = arr.join("/");
            if (folderName)
                folders.push({
                    "path": folderPath,
                    "name": folderName,
                    "type": "folder",
                })
            return {
                "path": file.path,
                "name": file.name,
                "type": "file",
                "size": file.size,
                "extension": `.${file.name.split(".").pop()?.toLowerCase()}`,
                "createdAt": new Date(file.lastModified).toISOString(),
                "modifiedAt": new Date(file.lastModified).toISOString(),
                "preview": URL.createObjectURL(file)
            }
        });
        let newFolders = arrayUniqueByKey('path', folders)
        if (Number(Number(projectDetail.size) + Number(size)) > Number(storage.project_storage)) {
            setNotEnoughStorage(true);
        } else if (Number(size) > Number(storage.file_upload)) {
            setModal(true);
        }
        else {
            if (fl.length) {
                console.log(fl, newFolders)
                setFileCount(fl.length);
                setFolderCount(newFolders.length)
                setFileJson([...fl, ...newFolders])
            }
        }

    }, [acceptedFiles])

    return (
        <>
            <div className='fixed top-0 left-0 w-full min-h-screen overflow-hidden grid place-items-center' style={{ zIndex: 2000 }}>
                <div className={`rounded-lg w-full max-w-xl overflow-hidden z-10 bg-white p-5 lg:p-10 relative`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 cursor-pointer absolute top-4 right-4" onClick={() => setIsUpload(false)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                    <h1 className='text-xl text-center font-semibold text-gray-800 mb-6 max-w-xs mx-auto'>Upload Files </h1>
                    <div {...getRootProps({ className: 'dropzone flex items-center justify-center flex-col border border-dashed p-4' })}>
                        <input {...getInputProps()} />
                        <h1 className='text-gray-500 text-lg mb-4'>Drag & drop or Browse your file from <span className='text-blue hover:text-blue-600 cursor-pointer'>here</span></h1>
                        <svg width="238" height="158" viewBox="0 0 238 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M181.219 44.4147C177.948 44.4147 175.111 44.6202 171.994 45.1429C164.221 19.0729 139.493 0 110.97 0C76.1432 0 48.5273 28.4519 48.5273 63.3456C48.5273 66.457 48.7984 69.0688 49.2368 72.0829C47.5503 71.8689 45.2596 72.0874 43.5602 72.0829C19.8839 72.0829 0.985352 91.305 0.985352 115.041C0.985352 138.778 19.8839 158 43.5602 158H96.0692V109.217H66.2668L119.485 48.7834L172.704 109.217H142.902V158H181.219C212.538 158 237.985 132.613 237.985 101.207C237.985 69.7775 212.538 44.4147 181.219 44.4147Z" fill="#2DC8A8" />
                        </svg>
                    </div>
                </div>
                <div className='absolute top-0 w-full min-h-screen overflow-hidden bg-black bg-opacity-80 filter backdrop-blur-sm' onClick={() => setIsUpload(false)}></div>
            </div>

            {fileJson?.length && <div className='fixed w-full h-screen top-0 left-0 grid place-items-center' style={{ zIndex: 2001 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 z-10' onClick={() => { setFileJson(false); }}>
                </div>
                <div className='max-w-3xl bg-white p-5 xl:px-10 w-full rounded-md flex flex-col overflow-y-auto z-20 relative'>
                    <h1 className='font-medium text-xl text-left'>{fileCount} files selected</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer text-gray-500 absolute top-4 right-4" onClick={() => { setFileJson(false); }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                    <div className='w-full mx-auto flex-grow flex flex-col h-[65vh] m-4 border rounded-lg p-2'>
                        <div className='flex items-start justify-start gap-4 flex-wrap px-4 pb-4 overflow-y-auto'>
                            {fileJson?.map((fileData, i) => (fileData?.type === 'folder' && fileData.path == '' && fileData?.name?.charAt(0) != '.') && <File type="folder" key={i} handleDone={handleDone} fileData={fileData} onFolderClick={onFolderClick} />)}
                        </div>
                        {folderCount ? <hr className='w-full mx-auto' /> : <></>}
                        <div className='flex items-start justify-start gap-4 flex-wrap px-4 pt-4 pb-16 overflow-y-auto'>
                            {fileJson?.map((fileData, i) => (fileData?.type === 'file' && !fileData.path?.includes("/") && fileData?.name?.charAt(0) != '.') && <File type="file" key={i} handleDone={handleDone} fileData={fileData} />)}
                        </div>
                        {/* {
                            showFile.length ? <>
                                <div className='flex items-start justify-start gap-4 flex-wrap px-4 pb-16 overflow-y-auto'>
                                    {showFile?.map((fileData, i) => (fileData?.type === 'file' && fileData?.name?.charAt(0) != '.') && <File type="file" key={i} handleDone={handleDone} fileData={fileData} />)}
                                </div>
                            </>
                                : <></>
                        } */}
                    </div>

                    <div className='flex items-center gap-4 justify-end text-base font-medium text-gray-500'>
                        <button onClick={() => handleFiles()}>Skip &amp; Upload</button>
                        <button onClick={() => setIsOldFolder(true)}>Choose Folder</button>
                        <button onClick={() => setIsNewFolder(true)} className='py-1.5 px-4 bg-blue text-white rounded-sm'>New Folder</button>
                    </div>
                </div>
            </div>}

            {(fileJson?.length && isNewFolder) && <div className='fixed w-full h-screen top-0 left-0 grid place-items-center' style={{ zIndex: 2004 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black z-10' onClick={() => { setFileJson(false); }}>
                </div>
                <div className='max-w-xl bg-white p-5 xl:px-10 w-full rounded-md flex flex-col overflow-y-auto z-20 relative'>
                    <h1 className='font-medium text-xl text-left'>New Folder</h1>
                    {/* <p className='text-base text-center text-gray-500 '>{fileJson.length} Files Uploading</p> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer text-gray-500 absolute top-4 right-4" onClick={() => { setFolderName(''); setIsNewFolder(false) }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <div className='w-full mx-auto flex-grow flex flex-col h-[35vh] m-4 border rounded-lg p-2'>
                        <File type="folder" handleDone={handleDone} fileData={{
                            "path": folderName,
                            "name": folderName,
                            "type": "folder"
                        }} />
                        <input type={"text"}
                            value={folderName}
                            onChange={e => setFolderName(e.target.value)}
                            placeholder="New Folder Name"
                            className='text-sm p-1 border border-blue-100 w-max focus:border-blue-100 focus:outline-none' />

                    </div>

                    <div className='flex items-center gap-4 justify-between w-full text-base font-medium text-gray-500'>
                        <button onClick={() => { setFolderName(''); setIsNewFolder(false) }}>Back</button>
                        <button className='py-1.5 px-4 bg-blue text-white rounded-sm' disabled={isLoading} onClick={() => { handleFiles() }}>Upload</button>
                    </div>
                    {/* <button className='py-1.5 px-8 text-lg font-medium text-white bg-blue max-w-max mx-auto disabled:bg-gray-500' disabled={isLoading} onClick={() => setIsNewFolder(true)}>Upload &amp; Continue</button> */}
                </div>
            </div>}

            {(fileJson?.length && isOldFolder) && <div className='fixed w-full h-screen top-0 left-0 grid place-items-center' style={{ zIndex: 2004 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black z-10' onClick={() => { setFileJson(false); }}>
                </div>
                <div className='max-w-3xl bg-white p-5 xl:px-10 w-full rounded-md flex flex-col overflow-y-auto z-20 relative'>
                    <h1 className='font-medium text-xl text-left'>Select Folder to upload</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer text-gray-500 absolute top-4 right-4" onClick={() => { setFolderName(''); setIsOldFolder(false) }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <div className='w-full mx-auto flex-grow flex flex-col h-[55vh] m-4 border rounded-lg p-2'>
                        {
                            folderList?.length ? <>
                                <div className='flex items-start flex-wrap gap-6'>
                                    {
                                        folderList.map((folder, i) => <div key={i} className="flex flex-col items-center w-32 justify-center relative m-2">
                                            <label htmlFor='folder'>
                                                <FolderIcon className='w-20 h-20 object-contain cursor-pointer' />
                                                <div className='flex items-start break-words gap-1' style={{}}>
                                                    <input id='folder' type="checkbox" onChange={() => setFolderName(folder.name)} className='m-0.5' checked={folderName == folder.name} /><span className='text-xs' style={{ wordBreak: 'break-word' }}>{folder.name}</span>
                                                </div>
                                            </label>
                                        </div>)
                                    }
                                </div>
                            </>
                                :
                                <h1 className='text-2xl font-medium text-gray-500 text-center my-6'>No folder available</h1>
                        }
                    </div>

                    <div className='flex items-center gap-4 justify-between w-full text-base font-medium text-gray-500'>
                        <button onClick={() => { setFolderName(''); setIsOldFolder(false) }}>Back</button>
                        <button className='py-1.5 px-4 bg-blue text-white rounded-sm' disabled={isLoading} onClick={() => { handleFiles() }}>Upload</button>
                    </div>
                </div>
            </div>}

            {/* {isNewFolder && <NewFolder folderName={folderName} setFolderName={setFolderName} setIsNewFolder={setIsNewFolder} handleFiles={handleFiles} />} */}
            <ConfirmModal open={modal} heading={`You're not allowed to upload files of size more than "${formatBytes(storage.file_upload)}"`} type="warn" okText='Ok' cancelText="Back" onCancel={() => setModal(false)} onOk={(e) => setModal(false)} />
            <ConfirmModal open={notEnoughStorage} heading={`Insufficient storage to upload files`} description={`remove some files in project "${projectDetail.name}" to upload new ones`} type="warn" okText='Ok' cancelText="Back" onCancel={() => setNotEnoughStorage(false)} onOk={(e) => setNotEnoughStorage(false)} />
        </>
    )
}

export default Upload



const File = ({ fileData, handleDone, type, onFolderClick }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [openType, setOpenType] = useState("")
    const moreRef = useRef();
    useClickOutside(() => setIsOpen(false), moreRef);

    const [commitText, setCommitText] = useState('')
    const [commitLink, setCommitLink] = useState('')
    const [commitImage, setCommitImage] = useState('')

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }

    const handleImage = async (file) => {
        let type = file?.name?.split(".").pop()
        const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', 'jfif']

        if (extensions.includes(`.${type?.toLowerCase()}`)) {
            toast.promise(uploadImage(file),
                {
                    loading: 'Uploading File...',
                    success: (e) => { setCommitImage(e?.data?.url); return <p>File uploaded</p> },
                    error: <p>Could not upload file!</p>,
                })
        }
        else
            toast.error("Invalid File type, only image is supported", { id: 'error' })
    }

    return <>
        <div className='flex flex-col items-center w-20 justify-center gap-1 relative m-2'>
            {
                type == 'folder' ?
                    <>
                        <svg onClick={() => setIsOpen(true)} className='absolute left-0 top-[52px] cursor-pointer' width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 7.584 7.584 4 12 4C16.416 4 20 7.584 20 12C20 16.416 16.416 20 12 20C7.584 20 4 16.416 4 12ZM11 11V7H13V11H17V13H13V17H11V13H7V11H11Z" fill="white" />
                        </svg>

                        <FolderIcon
                            onClick={() => setIsOpen(true)}
                            // onClick={() => onFolderClick(`${fileData.path}/${fileData.name}`)}
                            className='w-20 h-20 object-contain cursor-pointer' />
                        <h1 className='text-sm font-medium' style={{ wordBreak: 'break-word' }} title={fileData?.path}>{fileData?.name?.length > 12 ? `...${fileData?.name?.slice(fileData?.name?.length - 12, fileData?.name?.length)}` : fileData?.name}</h1>
                    </>
                    :
                    <>
                        {
                            extensions.includes(`${fileData?.extension?.toLowerCase()}`) ?
                                <svg onClick={() => setOpenType("text")} className='absolute left-5 top-[52px] cursor-pointer m-[1px]' width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <circle cx="8" cy="8" r="8" fill="white" />
                                    <path d="M10.8848 5.39453H8.14258V13H7.02344V5.39453H4.28711V4.46875H10.8848V5.39453Z" fill="#FFC258" />
                                    <path d="M10.8848 5.39453V6.39453H11.8848V5.39453H10.8848ZM8.14258 5.39453V4.39453H7.14258V5.39453H8.14258ZM8.14258 13V14H9.14258V13H8.14258ZM7.02344 13H6.02344V14H7.02344V13ZM7.02344 5.39453H8.02344V4.39453H7.02344V5.39453ZM4.28711 5.39453H3.28711V6.39453H4.28711V5.39453ZM4.28711 4.46875V3.46875H3.28711V4.46875H4.28711ZM10.8848 4.46875H11.8848V3.46875H10.8848V4.46875ZM10.8848 4.39453H8.14258V6.39453H10.8848V4.39453ZM7.14258 5.39453V13H9.14258V5.39453H7.14258ZM8.14258 12H7.02344V14H8.14258V12ZM8.02344 13V5.39453H6.02344V13H8.02344ZM7.02344 4.39453H4.28711V6.39453H7.02344V4.39453ZM5.28711 5.39453V4.46875H3.28711V5.39453H5.28711ZM4.28711 5.46875H10.8848V3.46875H4.28711V5.46875ZM9.88477 4.46875V5.39453H11.8848V4.46875H9.88477Z" fill="#FFC258" />
                                </svg >
                                :
                                <svg onClick={() => setIsOpen(true)} className='absolute left-5 top-12 cursor-pointer' width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 7.584 7.584 4 12 4C16.416 4 20 7.584 20 12C20 16.416 16.416 20 12 20C7.584 20 4 16.416 4 12ZM11 11V7H13V11H17V13H13V17H11V13H7V11H11Z" fill="white" />
                                </svg>
                        }
                        <img
                            className='w-16 h-16 object-contain cursor-pointer'
                            src={fileimg}
                            alt='file-icon'
                            onClick={() => extensions.includes(`${fileData?.extension?.toLowerCase()}`) ? setOpenType("text") : setIsOpen(true)}
                        />
                        <h1 className='text-xs text-center max-w-[96px] font-medium' style={{ overflowWrap: 'break-word' }} title={fileData?.path}>{fileData?.name?.length > 28 ? `...${fileData?.name?.slice(fileData?.name?.length - 28, fileData?.name?.length)}` : fileData?.name}</h1>
                    </>
            }

            {isOpen && <div ref={moreRef} className={`absolute ${type == 'folder' ? 'top-6' : 'top-5'} ${type == 'folder' ? 'left-2' : 'left-7'} px-2 py-1 flex items-center gap-2 `}>
                <svg className='absolute top-0 left-0' width="84" height="32" viewBox="0 0 117 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L116 1L116 34.8933L10.1063 34.8933L1 42L1 1Z" fill="white" stroke="#1D85E8" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <svg onClick={() => setOpenType("text")} className='z-20 mt-[1px] cursor-pointer' width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                    <path d="M12.0798 6.61987H12.3298V6.36987V5.50195V5.25195H12.0798H5.89453H5.64453V5.50195V6.36987V6.61987H5.89453H8.20984V13.5V13.75H8.45984H9.50903H9.75903V13.5V6.61987H12.0798Z" fill="#1D85E8" stroke="#1D85E8" stroke-width="0.5" />
                </svg>

                <svg onClick={() => setOpenType("link")} className='z-20 mt-[1px] cursor-pointer' width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                    <g clip-path="url(#clip0_840_314)">
                        <path d="M9.77215 8.22823C9.3626 7.81884 8.80722 7.58887 8.22815 7.58887C7.64907 7.58887 7.0937 7.81884 6.68415 8.22823L5.13965 9.77222C4.73009 10.1818 4.5 10.7373 4.5 11.3165C4.5 11.8957 4.73009 12.4512 5.13965 12.8607C5.54921 13.2703 6.10469 13.5004 6.6839 13.5004C7.26311 13.5004 7.81859 13.2703 8.22815 12.8607L9.00015 12.0887" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.22852 9.77215C8.63807 10.1815 9.19344 10.4115 9.77252 10.4115C10.3516 10.4115 10.907 10.1815 11.3165 9.77215L12.861 8.22815C13.2706 7.81859 13.5007 7.26311 13.5007 6.6839C13.5007 6.10469 13.2706 5.54921 12.861 5.13965C12.4515 4.73009 11.896 4.5 11.3168 4.5C10.7376 4.5 10.1821 4.73009 9.77252 5.13965L9.00052 5.91165" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_840_314">
                            <rect width="12" height="12" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>

                <svg onClick={() => setOpenType("image")} className='z-20 mt-[1px] cursor-pointer' width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                    <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
                </svg>
            </div>}
        </div>

        {openType && <div className='fixed w-full h-screen top-0 left-0 grid place-items-center' style={{ zIndex: 1000 }}>
            <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-80 z-10' onClick={() => setOpenType("")}>
            </div>
            <div className='max-w-xl bg-white p-5 w-full rounded-md flex flex-col overflow-y-auto z-20' ref={moreRef}>
                <h1 className='font-medium text-xl text-center'>
                    {openType == "text" ? "Add Commit text" : ''}
                    {openType == "link" ? "Add Preview link" : ''}
                    {openType == "image" ? "Add Preview image" : ''}
                </h1>
                {openType == "text" ? <textarea
                    minLength={5}
                    onChange={(e) => setCommitText(e.target.value)}
                    className='resize-none h-40 w-full border border-blue-100 my-4 p-2 text-base'
                    placeholder="Commit text here...">
                </textarea> : ''}
                {openType == "link" ?
                    <input type={"url"}
                        onChange={e => setCommitLink(e.target.value)}
                        placeholder="Paste preview link here..."
                        className='text-base p-2 my-4 border border-blue-100 w-full' />
                    : ''}
                {openType == "image" ?
                    <div className='border border-blue-100 m-4 p-4 relative'>
                        {commitImage ?
                            <>
                                <DeleteIcon className="absolute top-2 right-2 cursor-pointer" onClick={() => setCommitImage('')} />
                                <img className='w-40 h-32 object-cover mx-auto' src={commitImage} alt="" />
                            </>
                            :
                            <FileUploader
                                multiple={false}
                                handleChange={handleImage}
                                name="file"
                                children={
                                    <div className='flex flex-col items-center justify-center'>
                                        <CloudUpload className='text-gray-300' style={{ fontSize: 100 }} />
                                        <h1>Drag & drop to upload or <span className='text-blue hover:text-blue-600 cursor-pointer'>browse</span></h1>
                                    </div>
                                }
                            />
                        }
                    </div>
                    : <></>}
                <div className='flex items-center w-full justify-center gap-10'>

                    <button className='py-1.5 px-6 bg-red text-white font-medium max-w-max text-lg rounded-md' onClick={() => { setOpenType(""); setIsOpen(false) }}>
                        Cancel
                    </button>

                    <button type='submit' className='py-1.5 px-6 bg-blue text-white font-medium max-w-max text-lg rounded-md' onClick={() => { handleDone({ fileData: fileData, openType: openType, commitText: commitText, commitLink: commitLink, commitImage: commitImage }); setOpenType(""); setIsOpen(false) }}>
                        Done
                    </button>
                </div>
            </div>
        </div>}
    </>
}

const NewFolder = ({ folderName, setFolderName, setIsNewFolder, handleFiles }) => {

    const [show, setShow] = useState(false)

    return (<>
        <div className='fixed w-full h-screen top-0 left-0 grid place-items-center' style={{ zIndex: 2002 }}>
            <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-80 z-10' onClick={() => { setFolderName(''); setIsNewFolder(false) }}>
            </div>
            <div className='max-w-lg bg-white p-5 w-full rounded-md flex flex-col overflow-y-auto z-20'>
                <h1 className='font-medium text-xl text-center mb-4'>
                    Create a directory for uploaded files?
                </h1>

                {show ? <div className=''>
                    <input type={"text"}
                        value={folderName}
                        onChange={e => setFolderName(e.target.value)}
                        placeholder="New Folder Name"
                        className='text-base p-2 mb-4 border border-blue-100 w-full' />

                    <div className='flex items-center w-full justify-center gap-10'>
                        <button className='py-1.5 px-6 bg-red text-white font-medium max-w-max text-lg rounded-md' onClick={() => { setFolderName(''); setShow(false) }}>
                            Cancel
                        </button>
                        <button type='button' className='py-1.5 px-6 bg-blue text-white font-medium max-w-max text-lg rounded-md' onClick={() => { setIsNewFolder(false); handleFiles() }}>
                            Save
                        </button>
                    </div>
                </div>
                    :
                    <div className='flex items-center w-full justify-center gap-10'>

                        <button className='py-1.5 px-6 bg-red text-white font-medium max-w-max text-lg rounded-md' onClick={() => { setIsNewFolder(false); handleFiles() }}>
                            No
                        </button>

                        <button type='button' className='py-1.5 px-6 bg-blue text-white font-medium max-w-max text-lg rounded-md' onClick={() => setShow(true)}>
                            Yes
                        </button>
                    </div>
                }
            </div>
        </div>
    </>)
}