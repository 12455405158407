import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { addEventWinner, getEventById, getEventSubmission, getEventWinner, updateEventSubmission } from '../../service/service';
import { selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar';
import { useClickOutside } from '../../utils/helper';
import moment from 'moment';
import { filePreview } from '../../slices/fileSlice';
import Loader from '../ReusableComponents/Loader';
import ConfirmModal from '../ReusableComponents/ConfirmModal';

function Submission() {
    const { organizationId, eventId } = useParams();
    const user = useSelector(selectUserDetails);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState('all')
    const [submissions, setSubmissions] = useState([]);
    const [search, setSearch] = useState('');
    const [event, setEvent] = useState();
    const [isWinner, setIsWinner] = useState(false);
    const [winners, setWinners] = useState([])
    const [shortlisted, setShortlisted] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [winnerList, setWinnerList] = useState([])

    const fetchEventById = async () => {
        let res = await getEventById(eventId);
        if (res.success) {
            setEvent(res.data);
        }
        else {
            toast.error("Invalid Event URL")
            history.replace('/events')
        }
    }


    const getSubmissions = async () => {
        let res = await getEventSubmission(eventId, active == "shortlisted", active == "winners");
        if (res.success) {
            setSubmissions(res.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (user) {
            if (user?.organization && eventId) {
                getSubmissions();
                fetchEventById();
            }
            else {
                toast.error("Invalid Event Information")
                history.push("/404")
            }
        }
    }, [user, active]);

    const getUsers = async () => {
        let res = await getEventSubmission(eventId, true, false);
        if (res.success) {
            setShortlisted(res.data)
        }
    }
    useEffect(() => {
        getUsers();
        getWinners()
    }, [])


    const [isSort, setIsSort] = useState(false);

    const moreRef = useRef()
    useClickOutside(() => setIsSort(false), moreRef);


    const addWinner = (win) => {
        setWinners([...winners, win])
    }

    const getWinners = async () => {
        let res = await getEventWinner(eventId);
        console.log(res)
        if (res.success && res.data?.length) {
            setWinnerList(res.data)
        }
    }

    const publishWinner = async () => {
        setConfirm(false);
        setIsWinner(false)
        for (let winner of winners) {
            await addEventWinner(winner);
        }
        getWinners();
    }


    const Winner = ({ initial, index }) => {
        let newWin = !Boolean(initial)
        const positionRef = useRef();
        const userRef = useRef();
        const [positionFocus, setPositionFocus] = useState(false)
        const [userFocus, setUserFocus] = useState(false);

        useClickOutside(() => userFocus ? setUserFocus(false) : setPositionFocus(false), userFocus ? userFocus : positionRef)

        const [data, setData] = useState({
            event_id: eventId,
            organization_id: organizationId,
            submission_id: '',
            user_id: initial?.user_id || '',
            username: initial?.username || '',
            position: initial?.position || '',
            custom_text: initial?.custom_text || ''
        })
        const [search, setSearch] = useState('')
        const handleClick = () => {
            if (data.username && data.user_id && data.position) {
                addWinner(data);
                setData({
                    event_id: eventId,
                    organization_id: organizationId,
                    submission_id: '',
                    user_id: '',
                    username: '',
                    position: '',
                    custom_text: ''
                })
            }
            else {
                toast.error("Fill all the details")
            }
        }
        const handleChange = (e) => {
            setData({ ...data, [e.target.name]: e.target.value })
        }

        return (<>
            <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block'>Winner {index + 1}</label>
            <div className='relative' ref={userRef}>
                <input disabled={!newWin} type="text" value={newWin ? search : data?.username} onChange={(e) => setSearch(e.target.value)} onClick={() => setUserFocus(true)} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Winner' />
                {userFocus && <div className='bg-white p-4 rounded-lg shadow absolute top-12 left-0 w-full z-30'>
                    {shortlisted.map((user, i) => (user?.username?.toLowerCase()?.includes(search?.toLowerCase())) && <div className='flex items-center gap-6 py-2 border-b last:border-0' key={i}>
                        <svg width="30" height="26" viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.68 6.81869V5.21999C20.68 4.24799 19.8384 3.45996 18.8 3.45996H1.88003C0.841657 3.45996 0 4.24789 0 5.21999V15.5787H47V9.75206C47 8.78007 46.1583 7.99204 45.12 7.99204H21.9334C21.2411 7.99204 20.68 7.46672 20.68 6.81869Z" fill="#FE8083" />
                            <path d="M44.4933 40.5397H2.5067C1.12228 40.5397 0 39.4891 0 38.193V15.1601H20.7502C21.2789 15.1601 21.7832 14.9517 22.1394 14.5859L24.4491 12.2143C24.8053 11.8485 25.3096 11.6401 25.8383 11.6401H47V38.193C47 39.4891 45.8777 40.5397 44.4933 40.5397Z" fill="#FFC258" />
                        </svg>
                        <span className='flex-grow font-medium'>{user?.username}</span>
                        <button className='py-1.5 px-10 bg-blue font-medium text-white rounded' onClick={() => { setData({ ...data, username: user?.username, user_id: user?.user_id, submission_id: user.id }); setSearch(user?.username); setUserFocus(false) }}>Select</button>
                    </div>)}
                </div>}
            </div>
            <div className='relative' ref={positionRef}>
                <input disabled={!newWin} type="text" onClick={() => setPositionFocus(true)} value={data.position} onChange={handleChange} name='position' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Position (Ex: 1st Prize)' />
                {positionFocus && <div className='bg-white p-4 rounded-lg shadow absolute top-12 left-0 w-full z-30 position'>
                    <div onClick={() => { setData({ ...data, position: '1st Position' }); setPositionFocus(false) }} className='text-lg font-medium'>
                        <span>1st Position</span>
                    </div>
                    <div onClick={() => { setData({ ...data, position: '2nd Position' }); setPositionFocus(false) }} className='text-lg font-medium'>
                        <span>2nd Position</span>
                    </div>
                    <div onClick={() => { setData({ ...data, position: '3rd Position' }); setPositionFocus(false) }} className='text-lg font-medium'>
                        <span>3rd Position</span>
                    </div>
                    <div onClick={() => { setData({ ...data, position: 'Custom' }); setPositionFocus(false) }} className='text-lg font-medium'>
                        <span>Custom</span>
                    </div>
                </div>}
            </div>
            {
                data.position == 'Custom' && <div>
                    <input disabled={!newWin} type="text" value={data.custom_text} onChange={handleChange} name='custom_text' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Write Custom field' />
                </div>
            }
            {!initial?.user_id && <div className={`bg-gray-400 text-gray-50 rounded-lg p-2 text-base font-semibold mt-4 max-w-max ${Boolean(!Boolean(data.username) || !Boolean(data.position)) ? 'bg-gray-500' : 'bg-blue'} cursor-pointer z-10 relative disabled:cursor-not-allowed`} disabled={Boolean(!Boolean(data.user_id) || !Boolean(data.position))} onClick={() => Boolean(!Boolean(data.user_id) || !Boolean(data.position)) ? {} : handleClick()}>{'Add Winner'}</div>}
        </>)
    }

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <Navbar background={"#FFF"} />
                        <section className='w-full p-5 lg:px-10 max-w-7xl mx-auto'>
                            <div className='flex items-center justify-between gap-4 border-b pb-2'>
                                <h1 className='text-2xl lg:text-3xl font-semibold text-gray-500'>{active == "all" ? 'List of Submissions' : active == "shortlisted" ? 'Shortlisted Submissions' : 'Winners'}</h1>
                                <div className='flex items-center gap-1'>
                                    <button className={`px-4 py-1 rounded-3xl font-medium text-lg ${active == "all" ? 'bg-navy text-white' : 'bg-transparent text-gray-500'}`} onClick={() => setActive('all')}>All</button>
                                    <button className={`px-4 py-1 rounded-3xl font-medium text-lg ${active == "shortlisted" ? 'bg-navy text-white' : 'bg-transparent text-gray-500'}`} onClick={() => setActive('shortlisted')}>Shortlisted</button>
                                    <button className={`px-4 py-1 rounded-3xl font-medium text-lg ${active == "winners" ? 'bg-navy text-white' : 'bg-transparent text-gray-500'}`} onClick={() => setActive('winners')}>Winners</button>
                                </div>
                            </div>


                            <div className='w-full relative'>
                                {active == 'winners' ? <></> : <div className='flex items-center justify-between my-4 gap-4'>
                                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full relative'>
                                        <SearchOutlined />
                                        <input type="text" placeholder='Search files here...' onChange={(e) => submissions.length ? setSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                    </div>

                                    <div className='flex items-center gap-4 relative z-30'>
                                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                            {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12'>
                                                <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}><span>Name</span></p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}>Date Created</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}>Date Updated</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => { }}>Project Size</p>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}
                                <div className='my-4'>
                                    {!submissions?.length && (!winnerList?.length)
                                        ?
                                        <>
                                            {
                                                (new Date(event?.event_end) > new Date()) ?
                                                    <>
                                                        <div className=' bg-[#F6FAFD] h-[80vh] w-full max-w-7xl m-5 lg:mx-0 rounded-lg p-5 lg:p-10 overflow-y-auto lg:flex items-start gap-10' style={{ zIndex: 5002 }}>
                                                            {/* <div className='w-full h-full absolute inset-0 z-0' onClick={() => { setUserFocus(false); setPositionFocus(false) }}>

                                                            </div> */}
                                                            <div className='w-full lg:max-w-[200px] text-center'>
                                                                <h1 className='text-3xl font-semibold text-gray-500 underline'>Winners</h1>
                                                            </div>
                                                            <div className='flex-grow flex flex-col w-full h-full'>
                                                                <div className='flex-grow'>
                                                                    {winners.map((winner, i) => <Winner initial={winner} key={i} index={i} />)}
                                                                    <Winner index={winners.length} />
                                                                </div>
                                                                <div className='flex items-center justify-end p-4 w-full z-10'>
                                                                    <button className='text-white bg-blue px-8 py-2 font-medium rounded' onClick={() => setConfirm(true)}>Publish</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>{active == "all" ? 'No Submission Added yet' : active == "shortlisted" ? 'No Submission Shortlisted yet' : 'You can announce winner after submission end date'}</h1>
                                                    </>
                                            }
                                            {/* {(new Date(event?.event_end) < new Date()) && active == 'winners' && <div className='flex items-center justify-center w-full p-6'><button className='w-full max-w-xs py-2 px-6 bg-blue text-white rounded-lg shadow' onClick={() => setIsWinner(true)}>Add Winners</button></div>} */}
                                        </>
                                        :
                                        <>
                                            {
                                                <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                    <thead>
                                                        <tr className='font-medium text-gray-500'>
                                                            <td></td>
                                                            <td>User Name</td>
                                                            <td className='text-center'>Date</td>
                                                            <td className='text-center'>Time</td>
                                                            <td className='text-center'>View</td>
                                                            <td className='text-center'>Shortlist</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                        {active == 'winners' ?

                                                            winnerList?.map((submission, i) => <SubmissionList key={i} submission={submission} getSubmissions={getSubmissions} event={event} win={true} />)
                                                            :
                                                            submissions?.map((submission, i) => <SubmissionList key={i} submission={submission} getSubmissions={getSubmissions} event={event} />)
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </section>

                    </>
            }
            {isWinner && <><div className='bg-black bg-opacity-60 fixed inset-0 overflow-hidden w-full min-h-screen cursor-pointer' style={{ zIndex: 5001 }} onClick={() => setIsWinner(false)}></div>
                <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F6FAFD] h-[80vh] w-full max-w-5xl m-5 rounded-lg p-5 lg:p-10 overflow-y-auto' style={{ zIndex: 5002 }}>
                    {winners.map((winner, i) => <Winner initial={winner} key={i} index={i} />)}
                    <Winner index={winners.length} />

                    <div className='flex items-center justify-end p-4 w-full'>
                        <button className='text-white bg-blue px-8 py-2 font-medium rounded' onClick={() => setConfirm(true)}>Publish</button>
                    </div>
                </div>
            </>}
            <ConfirmModal open={confirm} heading={`Are you sure, you want to publish the winners`} description={`This process is irreversible, make sure you've checked all the details`} type="warn" okText='Yes' onCancel={() => setConfirm(false)} onOk={(e) => publishWinner()} />

        </>
    )
}

export default Submission


const SubmissionList = ({ submission, getSubmissions, event, win }) => {
    console.log(win)

    const dispatch = useDispatch()

    const updateSubmission = async (data) => {
        let res = await updateEventSubmission({ ...submission, ...data });
        if (res.success) {
            getSubmissions()
        } else toast.error("Something went wrong")
    }

    return (
        <>
            <tr className='border shadow'>
                <td>
                    <svg width="47" height="44" viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.68 6.81869V5.21999C20.68 4.24799 19.8384 3.45996 18.8 3.45996H1.88003C0.841657 3.45996 0 4.24789 0 5.21999V15.5787H47V9.75206C47 8.78007 46.1583 7.99204 45.12 7.99204H21.9334C21.2411 7.99204 20.68 7.46672 20.68 6.81869Z" fill="#FE8083" />
                        <path d="M44.4933 40.5397H2.5067C1.12228 40.5397 0 39.4891 0 38.193V15.1601H20.7502C21.2789 15.1601 21.7832 14.9517 22.1394 14.5859L24.4491 12.2143C24.8053 11.8485 25.3096 11.6401 25.8383 11.6401H47V38.193C47 39.4891 45.8777 40.5397 44.4933 40.5397Z" fill="#FFC258" />
                    </svg>
                </td>
                <td>{submission.username}</td>
                <td className='text-center'>{moment(submission.updated_at).format("DD/MM/YY")}</td>
                <td className='text-center'>{moment(submission.updated_at).format("HH:mm A")}</td>
                <td className='text-center'>
                    <button className='py-2 text-white bg-blue rounded-lg w-full' onClick={() => dispatch(filePreview({ url: submission?.submission_url, title: `${submission.username}`, open: true }))} >View Project</button>
                </td>
                <td className='text-center'>
                    <input type="checkbox" className='w-4 h-4 cursor-pointer disabled:cursor-not-allowed' disabled={(new Date(event?.event_end) < new Date())} checked={submission.shortlisted} defaultChecked={submission.shortlisted} onChange={() => updateSubmission({ shortlisted: !submission.shortlisted })} />
                </td>
            </tr>
        </>
    )
}