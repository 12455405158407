import React, { useState } from "react";
import { forgotPassword } from "../../service/service";
import validator from 'validator';
import toast from 'react-hot-toast'

const useForm = () => {
  const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.id.match("email")) {
      !validator.isEmail(values.email) 
        ? (errors.email= 'Enter valid Email Address!') 
        : (errors.email= ''); 
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const validation = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Email address is required.";
      toast.error('Email address is required', {id: 'error'})
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Enter valid Email Address!";
      toast.error('Enter valid Email Address!', {id: 'error'})
    }

    if (Object.keys(errors).length === 0) {
      forgotPasswordAPI();
    }
    return errors;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));
  };

  async function forgotPasswordAPI() {
    setFlagLoaderButton(true);
    const res = await forgotPassword(values);
    if (!res.data) {
      toast.error('Email does not exist!', {id: 'error'})
      setValues({ email: "" });
    } else {
      toast.error('Reset Password link is sent to your email Id', {id: 'error'})
      window.location.href = "/checkMail";
      setValues({ email: "" });
    }
    setFlagLoaderButton(false);
  }

  return { handleChange, handleFormSubmit, errors, values,flagLoaderButton };
};

export default useForm;
