import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { routeList } from '../../slices/routeSlice';
import { selectRepoBranches } from '../../slices/repoSlice';
import { useRef } from 'react';
import { useClickOutside } from '../../utils/helper';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import { fileDetail, getVersions } from '../../service/service';
import FileHistoryList from '../Files/FileHistoryList';
import fileimg from '../../assets/images/Files.png'
import { selectUserDetails } from '../../slices/userSlice';
import MiniLoader from '../ReusableComponents/MiniLoader';

function FileHistory() {
    const history = useHistory();
    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)
    const { username, repo, project, branchId, fileId } = useParams()
    const branchList = useSelector(selectRepoBranches)
    const [loading, setLoading] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [isAsc, setIsAsc] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [fileInfo, setFileInfo] = useState();

    const moreRef = useRef()
    useClickOutside(() => setIsSort(false), moreRef)

    const getFileList = async () => {
        const res = await getVersions(fileId, 'latest');
        if (res.data) {
            setFileList(res.data);
        }
        setLoading(false)
    }
    const getFileInfo = async () => {
        const res = await fileDetail(fileId);
        if (res.data) {
            setFileInfo(res.data);
        }
    }

    useEffect(() => {
        if (branchId && fileId) {
            getFileInfo();
            getFileList();
        }
    }, [branchId, fileId]);

    const sortByName = () => {
        if (fileList) {
            let arr = [...fileList]
            let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
            setFileList(sorted);
            setIsSort(false)
        }
    }
    const sortByTime = () => {
        if (fileList) {
            let arr = [...fileList]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            setFileList(sorted)
            setIsSort(false)
        }
    }

    const handleOrder = () => {
        if (fileList) {
            let arr = [...fileList];
            arr.reverse();
            setFileList(arr);
            setIsAsc(!isAsc)
        }
    }


    const navigateProject = () => {
        dispatch(routeList(`/${username}/${project}`))
        history.push(`/${username}/${project}`)
    }
    const navigateRepo = () => {
        if (history.length > 2) {
            history.goBack();
        }
        else {
            history.push(`/${username}/${project}/${repo}/${branchList[0]?.id}`)
        }
    }

    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {(loading) ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard className="lg:max-w-sm hidden md:inline-flex">
                            <>
                                <div className='p-5 xl:px-10'>
                                    <div className='flex items-center gap-2 flex-wrap text-gray-500'>
                                        <span className='text-gray-800 cursor-pointer hover:text-blue-500' onClick={() => history.push('/dashboard')}>Dashboard {" >"}</span>
                                        <span className='cursor-pointer hover:text-blue-500' onClick={navigateProject}>{project} {" >"}</span>
                                        <span className='text-gray-800 font-medium cursor-pointer hover:text-blue-500' onClick={navigateRepo}>{repo}</span>
                                    </div>
                                    <div className='flex items-baseline gap-4 my-6'>
                                        <img
                                            className='w-10 h-10 object-contain'
                                            src={fileimg}
                                            alt='file-icon'
                                        />
                                        <h1 className='text-2xl font-semibold block'>{fileInfo?.name}</h1>
                                    </div>
                                    <p className='block'>{fileInfo?.description || `This is the description text for the file ${fileInfo?.name}`}</p>
                                </div>
                            </>
                        </UserCard>

                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center justify-between gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500'>Versions {fileList?.length > 0 ? `(${fileList.length})` : ''}</h1>
                                <div className='flex items-center gap-4 z-30 justify-end'>
                                    <div ref={moreRef} className='p-2 max-w-max min-w-max relative border rounded-lg bg-white flex justify-between gap-4 cursor-pointer w-full'>
                                        <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                        {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                            : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                        {isSort && <div className='p-2 bg- min-w-max rounded-lg shadow-lg absolute top-12 right-0 popup-bg border'>
                                            <div className='absolute transform -top-1 right-2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                            <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortByName}>Name</p>
                                            <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={sortByTime}>Date Created</p>
                                        </div>}
                                    </div>
                                    <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleOrder}>
                                        <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full py-1'>

                                {!fileList?.length
                                    ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>{loading ? 'Loading ...' : 'No history present'}</h1>
                                    : <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                        <thead>
                                            <tr className='font-medium text-gray-500'>
                                                <td className='text-center'>Version</td>
                                                <td className='text-center'>Date</td>
                                                <td className='text-center'>Description</td>
                                                <td className='text-center'>Created By</td>
                                                <td className='text-center'>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                            {
                                                fileList.slice(0, user?.plan?.limit.version_history || 5)?.map((fileData, i) => <FileHistoryList getFileList={getFileList} getFileInfo={getFileInfo} key={i} fileData={fileData} current={fileInfo?.file_url} user={user} />)
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default FileHistory