import React, { useState } from "react";
import { resetPassword } from "../../service/service";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { passwordValidator } from "../../utils/validation";

const useForm = () => {
  const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
  var { key } = useParams();
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    repeatPassword: "",
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickConfirmShowPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const validation = (values) => {
    let errors = {};
    const regPass = passwordValidator(values.password);
    if (!values.password && !values.repeatPassword) {
      errors.password = " ";
      errors.repeatPassword = " ";
      toast.error("Please enter all fields", { id: 'error' })
    }
    else {
      if (!values.password) {
        errors.password = "Password is required.";
        toast.error("Please enter the password", { id: 'error' })
      }else if (regPass === false) {
        errors.password = "Password Must Contain one special character, one number, one uppercase";
        toast.error("Password Must Contain one special character, one number, one uppercase", { id: 'error' })
      }
      if (!values.repeatPassword) {
        errors.repeatPassword = "Repeat Password is required.";
        toast.error("Please enter the Repeat Password", { id: 'error' })
      } else if (values.repeatPassword !== values.password) {
        errors.repeatPassword = "Password do not match.";
        toast.error("Passwords do not match.Try again", { id: 'error' })
      }
      if (values.password?.length < 8) {
        errors.repeatPassword = "Password length must be greater than 8";
        toast.error('Password length must be greater than 8', { id: 'error' })
      }
    }

    if (Object.keys(errors).length === 0) {
      console.log("INSIDE API CALLER---");
      resetPasswordAPI();
    }
    return errors;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));
  };

  async function resetPasswordAPI() {
    setFlagLoaderButton(true);
    let password = values.password;
    let data = { password, key };
    const res = await resetPassword(data);
    if (!res.data) {
      if (res.response && res.response.data) {
        let message = res.response.data.message;
        if (message === "Invalid Verification Key") {
          toast.error("Invalid link please, select the latest mail to proceed", { id: 'error' })
        } else {
          toast.error(message, { id: 'error' })
        }
      } else {
        toast.error('Something went wrong!!!', { id: 'error' })
      }
      setValues({ password: "", repeatPassword: "", key: "" });
    } else {
      toast.success('Password Changed Successfully', { id: 'success' })
      window.location.href = "/passwordChanged";
      setValues({ password: "", repeatPassword: "", key: "" });
    }
    setFlagLoaderButton(false);
  }

  return {
    handleChange,
    handleFormSubmit,
    handleClickShowPassword,
    handleClickConfirmShowPassword,
    errors,
    values,
    flagLoaderButton,
  };
};

export default useForm;
