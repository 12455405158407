import { React } from 'react'
import confirmMail from '../../assets/images/Confirm_Mail_Art.png'
import logo from '../../assets/images/Greyffiti-New-Logo-White 1.png'
import validation from './validation'
import { Link } from 'react-router-dom';

function EmailConfirm() {
  const {
    submitUserForm,
    values,
    flagLoaderButton
  } = validation()

  return (
    <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
      <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
      <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
        <h1 className="text-3xl md:text-4xl font-bold text-blue">Hi {values}</h1>
        <h1 className="text-3xl md:text-4xl font-bold text-blue">Welcome to Greyffiti!</h1>
        <img className='m-2 max-h-40' alt="" src={confirmMail} />
        <p className="md:text-lg font-medium text-center my-4">Please confirm your email address</p>
        <button disabled={flagLoaderButton} type='submit' onClick={submitUserForm} className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
          Confirm Email
          {flagLoaderButton && <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block mx-4" viewBox="0 0 24 24"></svg>}
        </button>
      </div>
    </div>
  )
}

export default EmailConfirm
