import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  logo: {
    width: "12vh",
    height: "12vh",
    margin: "2vh",
    float: "left",
  },
  main: {
    backgroundColor: "#2DC8A8",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#1D85E8",
    textAlign: "center",
  },
  heading1: {
    textAlign: "center",
  },
  contentContainer: {
    boxShadow: "0 15px 20px rgba(0, 0, 0, .2)",
    textAlign: "left",
    marginTop: "20px",
    background: "white",
    padding: '50px',
    marginBottom: '50px'
  },
  mContent: {
    overflowY: "auto",
    background: "white",
  },
  list: {
    paddingLeft: "18px",
    color: "#000000",
  },
  headingOne: {
    paddingBottom: "10px",
    paddingTop: "10px",
  },
}));

export default useStyles;
