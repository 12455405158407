import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { projectList } from '../../slices/projectSlice';
import { useHistory, useParams } from 'react-router-dom';
import ProjectList from '../Project/ProjectList';
import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import AddProjectForm from '../Project/AddProject';
import { getProjectByUsername, getUserByUsername } from '../../service/service';
import { useDispatch } from 'react-redux'
import ProjectCard from '../Project/ProjectCard';
import ViewListIcon from '@material-ui/icons/ViewList';
import { useClickOutside, useSort } from '../../utils/helper';
import FilterIcon from '../ReusableComponents/FilterIcon';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import AppsIcon from '@material-ui/icons/Apps';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import MiniLoader from '../ReusableComponents/MiniLoader';
import ConfirmModal from '../ReusableComponents/ConfirmModal';

function Project() {
    const history = useHistory();
    const { username } = useParams()
    const dispatch = useDispatch()
    const sort = useSort()
    const [loading, setLoading] = useState(true);
    const user = useSelector(selectUserDetails);
    const [userData, setUserData] = useState();
    const [projects, setProjects] = useState([])
    const [addModal, setAddModal] = useState(false);
    const [cardView, setCardView] = useState(localStorage.getItem('projectCardView') == 'true');
    const [projectSearch, setProjectSearch] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [isAsc, setIsAsc] = useState(true);
    const [modal, setModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [filterOption, setFilterOption] = useState({ createdByOthers: false, createdByMe: false, public: false })

    const moreRef = useRef()
    const filterRef = useRef()
    useClickOutside(() => { isSort ? setIsSort(false) : setIsFilter(false) }, isSort ? moreRef : filterRef)

    async function getFreshProjectList() {
        setLoading(true)
        const res = await getProjectByUsername(username);
        if (res.data) {
            setProjects(res.data)
            dispatch(projectList(res.data || []));
        }
        setLoading(false)
    }

    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            setUserData(res.data);
            getProjects();
        }
        else {
            history.push("/404")
        }
    }

    const getProjects = async () => {
        const res = await getProjectByUsername(username);
        if (res.data) {
            setProjects(res.data)
        }
        if (!localStorage.getItem('greyffiti_token')) {
            history.push(`/userProfile/${username}`)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (username) {
            getUser();
            if (!localStorage.getItem('greyffiti_token')) {
                history.push(`/userProfile/${username}`)
            }
            else {
                if (user) {
                    if (user?.username !== username) {
                        history.push(`/userProfile/${username}`)
                    }
                }
            }
        }
    }, [username, user])

    useEffect(() => {
        if ((!user || user?.username !== username) && projects?.length) {
            let arr = [...projects];
            let newArr = arr.filter(project => {
                if (!user) {
                    return project.project_type_id == 1;
                }
                else {
                    let is = project?.contributorList?.filter(item => item.username == user?.username);

                    return (project.project_type_id == 2 ? Boolean(is?.length) : true);
                }
            });
            setProjects([...newArr])
        }

    }, [userData, projects?.length]);

    const handleCardView = (value) => {
        setCardView(value);
        localStorage.setItem('projectCardView', value)
    }

    const handleProjectOrder = () => {
        let arr = [...projects];
        arr.reverse();
        setProjects(arr)
        dispatch(projectList(arr));
        setIsAsc(!isAsc)
    }

    useEffect(() => {
        if (!loading) {
            let personal = projects.filter(project => project.username == user?.username)
            if (personal.length < user?.plan.limit.project)
                setCanAdd(true);
        }
    }, [projects, user, loading])


    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData }} className="lg:max-w-sm hidden lg:inline-flex">
                        </UserCard>

                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push('/dashboard')}>Insights</h1>
                                <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500' onClick={() => history?.push(`/${userData?.username}/`)}>Projects</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/all`)}>Repositories</h1>
                                {user && <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${user?.username}/issues`)}>Issues</h1>}
                                {user && <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/events`)}>Events</h1>}
                                {user?.plan?.name == 'organization' ? <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/retailer`)}>Retailer</h1> : <></>}
                                <div className='flex flex-grow justify-end pr-4 gap-2'>
                                    <div className={`px-1 pb-1 rounded border ${cardView ? 'bg-white border' : 'bg-red'}`}><ViewListIcon fontSize='small' className={`${cardView ? 'text-gray-300' : 'text-white'} cursor-pointer`} onClick={() => handleCardView(false)} /></div>
                                    <div className={`px-1 rounded border ${cardView ? 'bg-red' : 'bg-white'}`}><AppsIcon fontSize='small' className={`${cardView ? 'text-white' : 'text-gray-300'} cursor-pointer`} onClick={() => handleCardView(true)} /></div>
                                </div>
                            </div>
                            <div className='w-full relative'>
                                <div className='flex items-center justify-between my-4 gap-4'>
                                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full relative' ref={filterRef}>
                                        <SearchOutlined />
                                        <input type="text" placeholder='Search project here...' onChange={(e) => projects.length ? setProjectSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                        <FilterIcon onClick={() => setIsFilter(!isFilter)} />
                                        {isFilter && <div className='absolute z-30 w-full top-14 left-0 popup-bg shadow-lg rounded-lg pt-2 pb-4 px-4 border font-medium text-gray-800'>
                                            <div className='absolute transform -top-2 right-3 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />

                                            <li className='text-right list-none hover:text-blue-500 cursor-pointer' onClick={() => setFilterOption({ createdByOthers: false, createdByMe: false })}>Reset Filters</li>
                                            <FormGroup>
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByMe} onChange={e => setFilterOption({ ...filterOption, createdByMe: !filterOption.createdByMe })} />} label="Created by you" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByOthers} onChange={e => setFilterOption({ ...filterOption, createdByOthers: !filterOption.createdByOthers })} />} label="Invited" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.public} onChange={e => setFilterOption({ ...filterOption, public: !filterOption.public })} />} label="Public" />
                                            </FormGroup>
                                        </div>}
                                    </div>

                                    <div className='flex items-center gap-4 relative z-30'>
                                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                            {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12'>
                                                <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => sort.sortProjectByName(() => setIsSort(false), setProjects)}><span>Name</span></p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => sort.sortProjectByCreated(() => setIsSort(false), setProjects)}>Date Created</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => sort.sortProjectByUpdated(() => setIsSort(false), setProjects)}>Date Updated</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => sort.sortProjectBySize(() => setIsSort(false), username, setProjects)}>Project Size</p>
                                            </div>}
                                        </div>
                                        <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleProjectOrder}>
                                            <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                        </div>
                                        {user && <button className={`px-10 py-2 text-white rounded relative group min-w-max ${(user?.organization ? user.role !== 'user' : true) ? 'bg-blue' : 'bg-gray-300'}`} onClick={() => (user?.organization ? user.role !== 'user' : true) ? canAdd ? setAddModal(true) : setModal(true) : {}}>
                                            Create Project
                                            {!(user?.organization ? user.role !== 'user' : true) ? <span className='absolute -top-12 right-0 w-[300px] bg-gray-100 text-gray-800 text-xs py-1.5 px-3 rounded hidden shadow group-hover:block'>
                                                To create project/repo contact an admin or sub admin. Or request for admin access
                                            </span> : <></>}
                                        </button>}
                                    </div>
                                </div>
                                <div className='my-4' style={{ maxHeight: '70dvh', overflowY: 'auto' }}>
                                    {!projects?.length
                                        ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Project Added</h1>
                                        :
                                        <>
                                            {
                                                !cardView ? <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                    <thead>
                                                        <tr className='font-medium text-gray-500'>
                                                            <td>Name</td>
                                                            <td>Privacy</td>
                                                            <td>Description</td>
                                                            <td className='text-center'>Repositories</td>
                                                            <td className='text-center'>Action</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                        {
                                                            projects?.map(project => ((filterOption.createdByMe && project.created_by == userData.id) || (filterOption.createdByOthers && project.created_by != userData.id) || (filterOption.createdByMe == filterOption.createdByOthers)) && project?.name?.toLowerCase().includes(projectSearch.trim().toLowerCase()) && (filterOption.public ? project.project_type_id === 1 ? true : false : true) && <ProjectList key={project.id} username={username} project={project} setProjects={setProjects} />)
                                                        }
                                                    </tbody>
                                                </table>
                                                    :
                                                    <>
                                                        <div className='flex flex-wrap items-center justify-start gap-8'>
                                                            {
                                                                projects?.map(project => ((filterOption.createdByMe && project.created_by == userData.id) || (filterOption.createdByOthers && project.created_by != userData.id) || (filterOption.createdByMe == filterOption.createdByOthers)) && project?.name?.toLowerCase().includes(projectSearch.trim().toLowerCase()) && (filterOption.public ? project.project_type_id === 1 ? true : false : true) && <ProjectCard key={project.id} username={username} project={project} setProjects={setProjects} />)
                                                            }
                                                        </div>
                                                    </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {addModal &&
                <AddProjectForm getFreshProjectList={getFreshProjectList} setAddModal={setAddModal} username={user?.username} />
            }

            <ConfirmModal open={modal} heading={`You have reached your project limit`} description={`to create a new one, remove at least one of your project`} type="warn" okText='Ok' cancelText="Back" onCancel={() => setModal(false)} onOk={(e) => setModal(false)} />

        </>
    )
}

export default Project