import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import useStyle from './Styling'
function Access() {
    const classes = useStyle();
    return (
        <div>
            <List className={classes.listBox}>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Change user name
                            </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                        <Button className={classes.changeBtn}>
                            Change
                        </Button>
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Change email
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                        <Button className={classes.changeBtn}>
                            Change
                        </Button>
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Change password
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                        <Button className={classes.changeBtn}>
                            Change
                        </Button>
                    </ListItemIcon>
                </ListItem>
            </List>
        </div>
    )
}

export default Access
