import React from 'react'
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { userDetails } from '../../slices/userSlice';
import { handleFreelanceData } from '../../service/service';

var key = process.env.REACT_APP_MAP_API_KEY
function BusinessDetails({user}) {
    const dispatch = useDispatch();
    const [tags, setTags] = useState([])
    const [tag, setTag] = useState('')
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState("");
    const [locationModal, setLocationModal] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([])

    const onSave = async (e) => {
        e?.preventDefault();
        if (!tags?.length) {
            return toast.error("Add at least one tag", { id: 'error' })
        }

        setLoading(true);
        let res = await handleFreelanceData({ work_email: '', work_number: '', skills: tags?.join(",") })
        if (res.success && res.data != null) {
            let userInfo = { ...user, skills: tags?.join(",") }
            dispatch(userDetails(userInfo));
        }
        // update other fields

        setLoading(false);
    }

    const fetchPlace = async (text) => {
        try {
            // country, region, place, district, locality, postcode, neighborhood, address, poi, poi.landmark
            const res = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${key}&cachebuster=1625641871908&autocomplete=true&types=place,region,country`
            );
            if (!res.ok) throw new Error(res.statusText);

            return res.json();
        } catch (err) {
            return { error: "Unable to retrieve places" };
        }
    };

    const handleLocationChange = async (e) => {
        setLocation(e.target.value);
        setLocationModal(true)
        if (!location) return;

        const res = await fetchPlace(location);

        !autocompleteCities.includes(e.target.value) &&
            res.features &&
            setAutocompleteCities(res.features.map((place) => place.place_name));
    };

    return (
        <section>
            <h1 className="text-2xl font-semibold text-left mb-3">Business Details</h1>
            <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                <div className='relative'>
                    <h1 className='text-lg mb-1'>Service Location</h1>
                    <input value={location} onChange={handleLocationChange} type="text" placeholder='Choose Country' className='w-full p-2 rounded-md bg-white border' />
                    {location.length > 0 && locationModal ?
                        <div style={{ zIndex: 60, }} className='w-full bg-white border border-gray-200 rounded absolute left-0 top-20 shadow-md"'>
                            <div >
                                {autocompleteCities.length == 0 ? <div>
                                    No Results found</div> :
                                    <div className='p-1 z-10'>
                                        {autocompleteCities.map((data, i) =>
                                        (
                                            <div className="border-b last:border-none cursor-pointer p-2 hover:bg-blue-100 rounded" onClick={() => { setLocation(data); setLocationModal(false) }}>
                                                {data}
                                            </div>
                                        ))}
                                    </div>}
                            </div>
                        </div> : ""
                    }
                </div>
                <div className='mt-6'>
                    <h1 className='text-lg mb-1'>Service Offered</h1>
                    <textarea placeholder='Add your service details here.' className='w-full p-2 rounded-md bg-white border h-40 overflow-y-auto resize-none'>
                    </textarea>
                </div>

                <div className='mt-6'>
                    <h1 className='text-lg mb-1'>Tags</h1>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (tag) {
                            setTags([...tags, tag]);
                            setTag('')
                        }
                    }} className='bg-white border py-1 px-3 rounded text-gray-500 min-h-[120px] relative'>
                        <div className='flex flex-wrap gap-2 py-3'>
                            {tags?.map((tag, i) => <div key={i} className='bg-white rounded-full shadow py-1 px-3 flex items-center gap-1'>
                                <span className='text-gray-800'>{tag}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-rose-500 cursor-pointer"
                                    onClick={() => {
                                        let arr = [...tags]
                                        arr.splice(i, 1)
                                        setTags(arr)
                                    }}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>

                            </div>)}
                            {tags?.length < 7 && <input value={tag || ''} onChange={(e) => setTag(e.target.value)} placeholder='Type here...' type="text" className='p-1 px-3 rounded-full border-none outline-none w-full max-w-max text-gray-800' />}
                        </div>
                        <input type="submit" className='hidden' />
                        <span className='absolute bottom-2 right-4 text-lg tracking-widest text-gray-300 font-medium'>
                            {tags?.length}/7
                        </span>
                    </form>
                    <p className='mt-2'>Choose from popular tags: <span className='text-blue'>sticker, printing, t-shirts, banner, books, covers, notebooks</span></p>
                </div>
                <div className='w-full flex justify-end py-6'>
                    <button disabled={loading} onClick={onSave} className='bg-blue disabled:bg-gray-300 text-white w-36 py-2 rounded font-medium flex items-center justify-center'>
                        {
                            loading ?
                                <span className='border-4 border-gray-300 border-r-white animate-spin rounded-full w-6 h-6 flex-shrink-0'></span>
                                :
                                <span>Save</span>
                        }
                    </button>
                </div>
            </div>
        </section>
    )
}

export default BusinessDetails