import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    repoList: [],
    repoContributors: [],
    repoBranches: [],
}

export const repoSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        repoList: (state, action) => {
            state.repoList = action.payload
        },
        repoContributors: (state, action) => {
            state.repoContributors = action.payload
        },
        repoBranches: (state, action) => {
            state.repoBranches = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { repoList, repoContributors, repoBranches } = repoSlice.actions

export const selectRepoList = (state) => state.repo.repoList
export const selectRepoContributors = (state) => state.repo.repoContributors
export const selectRepoBranches = (state) => state.repo.repoBranches

export default repoSlice.reducer