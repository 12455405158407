import React, { useRef, useState } from 'react'
import { removeContributors } from '../../service/service'
import { useClickOutside } from '../../utils/helper'
import UserBordered from '../../assets/icons/UserBordered';
import WarningIcon from '../../assets/icons/WarningIcon';
import { accessRights } from '../../utils/constant';
import { toast } from 'react-hot-toast';

const Default_State = {
    open: false,
    id: null,
    type: '',
    access: ''
}

function RemoveContributor({ contributors, setContributors, setRemoveCreator, type, id, setCanInvite, plan }) {
    const [removeModal, setRemoveModal] = useState(Default_State)

    const cancelRef = useRef()

    useClickOutside(() => setRemoveCreator(false), cancelRef)

    const handleRemoveBtn = async (user_id, eventType, access) => {
        let data = {
            type: type,
            user_id: user_id,
            project_id: type == 'project' ? id : null,
            repo_id: type == 'repo' ? id : null,
            access: access,
            eventType: eventType
        }
        let i = contributors.findIndex(item => item.contributer_id == user_id);
        if (i > -1) {
            var newArr = [...contributors];
            if (eventType == 'remove') {
                setCanInvite((pr) => pr - 1)
                newArr.splice(i, 1);
                setContributors([...newArr])
            }
            else if (eventType == 'update') {
                let obj = { ...newArr[i] }
                obj.access = access
                newArr[i] = obj
                setContributors([...newArr])
            }
            setRemoveModal(Default_State)
            const res = await removeContributors(data);
            if (res.success) {
                let msg = ''
                if (eventType == 'remove') msg = 'Contributor removed successfully'
                else if (eventType == 'update') msg = 'Contributor updated successfully'
                toast.success(msg, { id: 'success' })
            }
            else {
                toast.error("Unable to make the request", { id: 'error' })
            }
        }
    }

    return (
        <>
            <div style={{ width: '100%', padding: '10px', flexGrow: 1 }}>
                {contributors.length > 0 ?
                    contributors.map(user => <UserRow key={user.contributer_id} user={user} setRemoveModal={setRemoveModal} plan={plan} />)
                    :
                    <p className='text-center text-2xl font-semibold text-gray-500 my-6'>No Contributor available</p>
                }
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', padding: '10px', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                <button type='submit' className='bg-blue py-1.5 px-6 rounded text-lg text-white' onClick={() => setRemoveCreator(false)}>
                    Done
                </button>
            </div>
            {removeModal.open && <div className='fixed top-0 left-0 w-full h-full overflow-hidden bg-black bg-opacity-75 grid place-items-center' style={{ zIndex: 1000 }}>
                <div className='flex flex-col items-center justify-center p-5 md:px-10 bg-white w-full max-w-xl rounded-lg'>
                    <WarningIcon />
                    <h1 className='text-3xl font-medium my-6 text-center'>{removeModal?.type == 'update' ? `update access to ${removeModal?.access == 1 ? 'View & Edit' : removeModal?.access == 2 ? 'View Only' : ''}` : 'Are you sure you want remove the contributor?'}</h1>
                    <div className='flex justify-around w-full my-6'>
                        <button className='bg-red rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => setRemoveModal(Default_State)}>
                            Cancel
                        </button>
                        <button className='bg-blue rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => handleRemoveBtn(removeModal.id, removeModal.type, removeModal.access)}>
                            {removeModal?.type}
                        </button>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default RemoveContributor

const UserRow = ({ user, setRemoveModal, plan }) => {
    const [active, setActive] = useState(false)

    const ref = useRef()
    useClickOutside(() => setActive(false), ref)

    const userRow = {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20,
        marginBottom: 20
    }

    let access = accessRights?.find(item => item?.id == user.access)
    return <div style={userRow}>
        {
            user.profile_pic ?
                <img className='w-10 h-10 rounded-full' src={user.profile_pic} alt='' />
                : <UserBordered className='w-10 h-10 rounded-full' />
        }
        <div style={{ flexGrow: 1 }}>
            <p className='text-lg font-semibold text-gray-800'>{user.username}</p>
            <p className='text-gray-500'>{user.name}</p>
        </div>
        <button style={{ backgroundColor: access ? access?.color : '#1C85E8' }} className='bg-red rounded-lg px-5 py-1.5 font-medium text-white capitalize relative'
            ref={ref}
            onClick={() => setActive(!active)}
        >
            <div className='flex items-center justify-between gap-2 w-[64px]'>
                <img src={access?.icon} className='w-6 h-6 filter invert' alt="" />
                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.63398 10.5C6.01888 11.1667 6.98113 11.1667 7.36603 10.5L12.1292 2.25C12.5141 1.58333 12.0329 0.75 11.2631 0.75H1.73686C0.967059 0.75 0.485935 1.58333 0.870835 2.25L5.63398 10.5Z" fill="white" />
                </svg>
            </div>

            {active && <div className='p-3 bg-white rounded-md shadow-lg absolute top-9 right-0 min-w-max space-y-3 z-20'>
                {
                    plan?.name == 'free'
                        ?
                        <></>
                        :
                        accessRights?.map(item => (
                            <div className='flex items-center text-gray-800 gap-2'
                                onClick={() => user.access != item.id ? setRemoveModal({ open: true, id: user.contributer_id, type: 'update', access: item.id }) : {}}
                            >
                                <img src={item.icon} className='w-6 h-6' alt="" />
                                <h1>{item.name}</h1>
                            </div>
                        ))
                }
                <div className='flex items-center text-rose-500 gap-2'
                    onClick={() => setRemoveModal({ open: true, id: user.contributer_id, type: 'remove', access: user.access })}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1>Remove</h1>
                </div>
            </div>
            }
        </button >
    </div >
}