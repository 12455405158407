import React from 'react'
import { useState } from 'react';
import moment from 'moment';
import { Popover } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteVersion, restoreFile } from '../../service/service';
import toast from 'react-hot-toast';
import { filePreview } from '../../slices/fileSlice';
import { preview } from '../../slices/projectSlice';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../ReusableComponents/ConfirmModal';


const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', 'jfif']

function FileHistoryList({ fileData, user, current, getFileInfo, getFileList }) {
    const [deleteModal, setDeleteModal] = useState(false);

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fileRestore = async () => {
        try {
            return await restoreFile({ file_id: fileData.id, version: fileData.version })
        } catch (error) {
            console.log(error)
            return error
        }
    }

    const removeVersion = async () => {
        try {
            return await deleteVersion({ fileId: fileData?.id, version: fileData?.version })
        } catch (error) {
            return error
        }
    }


    const handleFolderClick = () => {
        if (extensions.includes(fileData?.file_format?.toLowerCase())) {
            if (fileData?.preview && fileData?.preview !== 'undefined') {
                dispatch(filePreview({ url: fileData?.preview, title: fileData.name, open: true }));
            }
            else if (fileData?.file_url && fileData?.file_url !== 'undefined') {
                dispatch(filePreview({ url: fileData?.file_url, title: fileData.name, open: true }));
            }
        }
        else {
            if (fileData?.file_format?.toLowerCase() == '.pdf' && fileData?.file_url && fileData?.file_url !== 'undefined') {
                dispatch(preview({ isOpen: true, pdfUrl: fileData?.file_url, title: fileData?.name }))
            }
        }
    }

    return (
        <>
            <tr className='bg-white rounded-sm shadow transform hover:bg-gray-50 relative'>
                <td className='font-medium text-gray-800 cursor-pointer justify-center flex items-center gap-2' onClick={() => handleFolderClick()}>
                    <span>
                        V{fileData.version}
                    </span>
                    {(fileData?.link && fileData?.link !== 'undefined')
                        &&
                        <a href={fileData?.link?.includes('http') ? fileData?.link : `https://${fileData?.link}`} rel="noreferrer" target="_blank">  <svg className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                            <g clip-path="url(#clip0_840_314)">
                                <path d="M9.77215 8.22823C9.3626 7.81884 8.80722 7.58887 8.22815 7.58887C7.64907 7.58887 7.0937 7.81884 6.68415 8.22823L5.13965 9.77222C4.73009 10.1818 4.5 10.7373 4.5 11.3165C4.5 11.8957 4.73009 12.4512 5.13965 12.8607C5.54921 13.2703 6.10469 13.5004 6.6839 13.5004C7.26311 13.5004 7.81859 13.2703 8.22815 12.8607L9.00015 12.0887" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                                <path d="M8.22852 9.77215C8.63807 10.1815 9.19344 10.4115 9.77252 10.4115C10.3516 10.4115 10.907 10.1815 11.3165 9.77215L12.861 8.22815C13.2706 7.81859 13.5007 7.26311 13.5007 6.6839C13.5007 6.10469 13.2706 5.54921 12.861 5.13965C12.4515 4.73009 11.896 4.5 11.3168 4.5C10.7376 4.5 10.1821 4.73009 9.77252 5.13965L9.00052 5.91165" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_840_314">
                                    <rect width="12" height="12" fill="white" transform="translate(3 3)" />
                                </clipPath>
                            </defs>
                        </svg>
                        </a>
                    }
                    {(fileData?.preview && fileData?.preview !== 'undefined') &&
                        <svg onClick={() => dispatch(filePreview({ url: fileData?.preview, title: fileData.name, open: true }))} className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                            <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
                        </svg>
                    }
                    {current == fileData?.file_url && <h1 className='absolute -top-2 -left-4 bg-blue text-white rounded-xl px-3 py-0.5 text-xs'>In use</h1>}
                </td>
                <td className='text-center'>
                    {moment(fileData?.created_at).format("DD/MM/YYYY")}
                </td>
                <td className='max-w-sm text-center'>
                    <p className='max-w-sm'>{fileData.description || '-'}</p>
                </td>
                <td className='text-center'>{fileData.user_name}</td>
                <td className='relative'>
                    <div className='flex gap-4 relative max-w-fit mx-auto'>
                        {user?.username == fileData?.username && <MoreVertIcon className='cursor-pointer' onClick={handleClick} />}
                        <Popover
                            className='mt-2 min-w-32'
                            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className='popup-bg py-1 px-2 relative'>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => {
                                    handleClose();
                                    toast.promise(fileRestore(),
                                        {
                                            loading: 'Restoring File...',
                                            success: () => { getFileInfo(); return <p>File Restored Successfully!</p> },
                                            error: <p>Could not restore File!</p>,
                                        }
                                    )
                                }}>Restore File</h1>
                                <h1 className='font-medium text-base text-gray-800 hover:text-red-500 cursor-pointer p-1' onClick={() => {
                                    handleClose();
                                    setDeleteModal(true);
                                }}>Remove Version</h1>
                            </div>
                        </Popover>
                    </div>
                    <p className='text-gray-500 hover:text-blue-500 cursor-pointer font-light text-xs text-center mt-3'>Updated {moment(fileData.updated_at).fromNow()}</p>
                </td>
            </tr>

            <ConfirmModal open={deleteModal} heading={`Are you sure you want to remove the "${fileData.name}" v${fileData?.version}`} type="warn" okText='Yes' onCancel={() => setDeleteModal(false)} onOk={(e) => {
                toast.promise(removeVersion(),
                    {
                        loading: 'Removing File Version...',
                        success: () => { getFileList(); setDeleteModal(false); return <p>File Version Removed Successfully!</p> },
                        error: <p>Could not remove File Version!</p>,
                    }
                )
            }} />

        </>
    )
}

export default FileHistoryList