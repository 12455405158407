import { InputAdornment, Link, TextField } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import React, { useEffect, useRef, useState } from 'react'
import { inviteMembers, sendInviteToUsers } from '../../service/service'
import toast from 'react-hot-toast'
import { useClickOutside, useDebounce } from '../../utils/helper'
import UserBordered from '../../assets/icons/UserBordered'
import { accessRights } from '../../utils/constant'


function AddContributor({ setOpenPopUp, type, id, setEmailInvite, setRemoveCreator, canInvite, setCanInvite, plan, retailers }) {
    const [users, setUsers] = useState([])
    const [temp, setTemp] = useState([])
    const [name, setName] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [invitees, setInvitees] = useState([])
    const cancelRef = useRef()
    useClickOutside(() => setOpenPopUp(false), cancelRef)

    console.log(plan?.name == 'free')

    const membersList = async () => {
        let res = await inviteMembers(type, id, 'recent')
        setUsers(res.data);
        setTemp(res.data)
        let all = await inviteMembers(type, id);
        setAllUsers(all.data)
    }
    const debounced = useDebounce(name, 400)
    useEffect(() => {
        if (!users?.length && name.length) {
            const data = allUsers.filter(item => item.username?.toLowerCase().includes(name?.toLowerCase()) || item.email?.toLowerCase().includes(name?.toLowerCase()));
            setUsers(data)
        }
        else if (users?.length && name.length) {
            let is = users.findIndex(item => item.username?.toLowerCase().includes(name?.toLowerCase()) || item.email?.toLowerCase().includes(name?.toLowerCase()))
            if (is < 0) {
                setUsers(allUsers)
            } else {
                setUsers(temp)
            }
        }
        else if (!name.length)
            setUsers(temp)
    }, [debounced])

    useEffect(() => membersList(), [])

    const userRow = {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20,
        marginBottom: 20
    }

    const UserRow = ({ user }) => {
        const [invited, setInvited] = useState(invitees.find(item => item.id == Number(user.id)));
        const [active, setActive] = useState(false)

        const ref = useRef()
        useClickOutside(() => setActive(false), ref)

        const handleInviteBtn = (access) => {
            let idx = invitees.findIndex(item => item.id == user.id);
            if (idx > -1) {
                setInvited(false);
                let arr = [...invitees];
                arr.splice(idx, 1)
                setInvitees(arr)
                setCanInvite(canInvite + 1)
            }
            else {
                setInvited({ ...user, access: access });
                setInvitees([...invitees, { ...user, access: access }])
                setCanInvite(canInvite - 1)
            }
        }

        return <div style={userRow}>
            {
                user.profile_pic ?
                    <img className='w-10 h-10 rounded-full' src={user.profile_pic} alt='' />
                    :
                    <UserBordered className='w-10 h-10 rounded-full' />
            }
            <div style={{ flexGrow: 1 }}>
                <p className='text-lg text-gray-800 font-semibold'>{user.username}</p>
                <p className='text-gray-500'>{user.name}</p>
            </div>
            {
                plan?.name == 'free'
                    ?
                    <button onClick={() => handleInviteBtn(accessRights[0])} style={{ backgroundColor: '#1C85E8' }} className={`rounded-lg px-5 py-1.5 font-medium text-white capitalize relative group`}>

                        {
                            invited ?
                                <div className='flex items-center justify-between gap-2 w-[64px]'>
                                    <span>Selected</span>
                                </div>
                                :
                                <div className='flex items-center justify-between gap-2'>
                                    <span>Select</span>
                                </div>
                        }
                    </button>
                    :

                    <button style={{ backgroundColor: invited ? invited?.access?.color : '#1C85E8' }} className={`rounded-lg px-5 py-1.5 font-medium text-white capitalize relative group`}
                        ref={ref}
                        onClick={() => {
                            let i = retailers?.findIndex(item => item?.invited_to == user?.id)
                            if (i > -1) {
                                toast.error(`${user?.username} is already part of your retailers cannot send contributor invite`, { id: 'err' })
                            } else
                                setActive(!active);
                        }}
                    >
                        {
                            invited ?
                                <div className='flex items-center justify-between gap-2 w-[64px]'>
                                    <img src={invited?.access?.icon} className='w-6 h-6 filter invert' alt="" />
                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.63398 10.5C6.01888 11.1667 6.98113 11.1667 7.36603 10.5L12.1292 2.25C12.5141 1.58333 12.0329 0.75 11.2631 0.75H1.73686C0.967059 0.75 0.485935 1.58333 0.870835 2.25L5.63398 10.5Z" fill="white" />
                                    </svg>
                                </div>
                                :
                                <div className='flex items-center justify-between gap-2'>
                                    <span>Select</span>
                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.63398 10.5C6.01888 11.1667 6.98113 11.1667 7.36603 10.5L12.1292 2.25C12.5141 1.58333 12.0329 0.75 11.2631 0.75H1.73686C0.967059 0.75 0.485935 1.58333 0.870835 2.25L5.63398 10.5Z" fill="white" />
                                    </svg>
                                </div>
                        }
                        {active && <div className='p-3 bg-white rounded-md shadow-lg absolute top-9 right-0 min-w-max space-y-3 z-20'>
                            {
                                accessRights?.map(item => (
                                    <div className='flex items-center text-gray-800 gap-2' onClick={() => handleInviteBtn(item)}>
                                        <img src={item.icon} className='w-6 h-6' alt="" />
                                        <h1>{item.name}</h1>
                                    </div>
                                ))
                            }
                            {invited ? <div className='flex items-center text-rose-500 gap-2' onClick={() => {
                                let idx = invitees.findIndex(item => item.id == user.id);
                                if (idx > -1) {
                                    setInvited(false);
                                    let arr = [...invitees];
                                    arr.splice(idx, 1)
                                    setInvitees(arr)
                                    setCanInvite(canInvite + 1)
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <h1>Cancel</h1>
                            </div> : <></>}
                        </div>}
                    </button>
            }
        </div>
    }

    const handleLink = () => {
        setOpenPopUp(false);
        setEmailInvite(true)
    }

    const inviteSelected = async () => {
        if (!invitees?.length) {
            return toast.error(`No user is selected to invite`, { id: 'error' });
        }
        const data = {
            invite_for: type,
            id: id,
            invite_ids: invitees?.map(item => { return { id: item?.id, access: item?.access?.id } })
        }
        let res = await sendInviteToUsers(data)
        if (res.success) {
            toast.success(`Invite sent to the selected users`, { id: 'success' });
        }
        else if (!res.success && res.err == "invite_ids is not allowed to be empty") {
            toast.error(`No user is selected to invite`, { id: 'error' });
        }
        else {
            toast.error(`Something went wrong`, { id: 'error' });
        }
        setOpenPopUp(false)
    }

    return (
        <>
            <TextField style={{ marginTop: 10 }} size="small" fullWidth placeholder="search by username or email" id="fullWidth" variant="outlined"
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                }} />
            {
                Boolean(canInvite) ?

                    <div style={{ width: '100%', padding: '10px', flexGrow: 1 }}>
                        {users?.length > 0 ?
                            users.map(user => (user?.username?.toLowerCase()?.includes(name.toLowerCase()) || user?.email?.toLowerCase()?.includes(name.toLowerCase())) && <UserRow key={user.id} user={user} />)
                            :
                            <p className='text-center text-2xl font-semibold text-gray-500 my-6'>Recently invited users appear here</p>
                        }
                    </div>
                    :
                    <>
                        <p className='text-center text-2xl font-semibold text-gray-500 my-6'>Can't Invite user, {plan.name == 'free' ? 'upgrade to pro to invite users' : "You've reached to invite limit"} </p>
                    </>
            }

            {Boolean(canInvite) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '10px', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                <div>
                    <p>
                        Didn't find creators you're looking for?
                    </p>
                    <Link style={{ cursor: 'pointer' }} onClick={handleLink}>Send invitation email</Link>
                </div>
                {<button type='submit' className='bg-green py-1.5 px-6 rounded text-lg text-white' onClick={inviteSelected}>
                    Invite Selected
                </button>}
            </div>}
        </>
    )
}

export default AddContributor