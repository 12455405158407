import React from 'react';
import errorPic from '../../assets/images/Error_404_Art.png';
import logo from '../../assets/images/Greyffiti-White-Logo.png';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-navy '>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1001 }} /></Link>
            <img src={errorPic} className="absolute top-0 left-0 w-full h-full object-contain" alt="errorPic" />
            <div className='flex flex-col items-end justify-end p-10 lg:p-20 absolute top-0 left-0 w-full h-full' style={{ zIndex: 1 }}>
                <h1 className="text-3xl md:text-4xl 2xl:text-6xl font-bold text-white">Ooops...</h1>
                <h1 className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-white my-2 text-right">Looks like you’ve lost!</h1>
                <Link to='/dashboard'><button type='submit' className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                    Go To Home
                </button>
                </Link>
            </div>
        </div>
    );
}