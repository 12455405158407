import { Switch } from '@material-ui/core'
import React, { useRef } from 'react'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useParams } from 'react-router-dom'
import { addEvent, uploadFile as UPLOAD } from '../../service/service'
import { useClickOutside } from '../../utils/helper'
import Loader from '../ReusableComponents/Loader'
import Navbar from '../ReusableComponents/Navbar'

const options = [
    {
        id: 0,
        title: 'Basic Details'
    },
    {
        id: 1,
        title: 'Contact'
    },
    {
        id: 2,
        title: 'Speaker'
    },
    {
        id: 3,
        title: 'Pricing'
    },
    {
        id: 4,
        title: 'Submission'
    },
    {
        id: 5,
        title: 'Itenary'
    },
    {
        id: 6,
        title: 'Banner & Tags'
    },
]

function NewEvent() {
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(options[0])
    const history = useHistory();
    const { organizationId } = useParams();

    const [data, setData] = useState({
        title: 'Test',
        description: 'Test',
        type: 'hackathon',
        mode: 'online',
        event_link: '',
        primary_number: '564454645',
        secondary_number: '564454645',
        whatsapp: '564454645',
        primary_email: 'test@email.com',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        submission_start_date: '',
        submission_start_time: '',
        submission_end_date: '',
        submission_end_time: '',
        banner_url: '',
        thumbnail: 'https://res.cloudinary.com/ankit628792/image/upload/v1663929270/Greyffiti/byk8mqr9brdmwf84enrj.svg',
        guidelines: '',
        organization_id: organizationId,
        closed: false,
        tags: [],
        location: '',
        maplink: '',
        withGreyffiti: false,
        freeEvent: false
    });

    const [tiers, setTiers] = useState([])
    const [speakers, setSpeakers] = useState([])

    const handleChange = (e) => {
        e.preventDefault();
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const addSpeaker = (data) => {
        setSpeakers([...speakers, data]);
    }
    const addTier = (data) => {
        setTiers([...tiers, data]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // '2022-12-09T10:26:00.000Z'
        let formData = {
            title: data.title,
            description: data.description,
            type: data.type,
            mode: data.mode,
            event_link: data.event_link,
            primary_number: data.primary_number,
            primary_email: data.primary_email,
            banner_url: data.banner_url,
            thumbnail: data.thumbnail,
            guidelines: data.guidelines,
            organization_id: data.organization_id,
            closed: false,
            tags: data.tags.join(","),
            event_start: new Date(`${data.start_date}T${data.start_time}:00.000Z`) == 'Invalid Date' ? null : new Date(`${data.start_date}T${data.start_time}:00.000Z`).toISOString(),
            event_end: new Date(`${data.end_date}T${data.end_time}:00.000Z`) == 'Invalid Date' ? null : new Date(`${data.end_date}T${data.end_time}:00.000Z`).toISOString(),
            submission_start: new Date(`${data.submission_start_date}T${data.submission_start_time}:00.000Z`) == 'Invalid Date' ? null : new Date(`${data.submission_start_date}T${data.submission_start_time}:00.000Z`).toISOString(),
            submission_end: new Date(`${data.submission_end_date}T${data.submission_end_time}:00.000Z`) == 'Invalid Date' ? null : new Date(`${data.submission_end_date}T${data.submission_end_time}:00.000Z`).toISOString(),
            location: data.location,
            maplink: data.maplink,
            whatsapp: data.whatsapp,
            secondary_number: data.secondary_number,
            withgreyffiti: data.withGreyffiti,
            freeevent: data.freeEvent,
            speakers: speakers,
            tiers: tiers
        }
        console.log(formData)
        let res = await addEvent(formData);
        if (res.success && res?.data?.id)
            history.push(`/events/${res.data.id}`)
    }


    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await UPLOAD(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }
    const handleFile = async (e) => {
        e.preventDefault()
        let file = e.target.files[0];
        let imgRes = {};
        if (file.size > 1048576) {
            toast.error("File size is large to upload")
        } else if (e.target.name != "guidelines") {
            if (file.type.includes('image')) {
                imgRes = await uploadFile(file);
            }
            else {
                toast.error("Invalid Image format")
            }
        }
        else {
            imgRes = await uploadFile(file);
        }
        if (imgRes?.data?.url) {
            setData({ ...data, [e.target.name]: imgRes?.data?.url })
        }
    }

    const handleTag = async (type, tag, callback) => {
        let tags = [...data.tags]
        if (type == 'add') {
            if (!tag.length) return toast.error("Please add a tag", { id: 'error' })
            tags.push(tag)
        }
        else if (type == 'remove') {
            let idx = tags.indexOf(tag);
            tags.splice(idx, 1);
        }
        else
            return;
        setData({ ...data, tags: tags });
        callback();
    }

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <Navbar background='#FFF' />
                        <section className='w-full min-h-screen bg-[#FFF] p-5'>
                            <div className='max-w-7xl w-full mx-auto p-5 flex items-start bg-[#F6FAFD] min-h-[75vh] border-2 rounded-md'>
                                <div className='w-full max-w-xs lg:px-8'>
                                    <h1 className='text-2xl lg:text-3xl font-semibold text-gray-800 py-1 border-b-2 border-blue-500 max-w-max'>{active.title}</h1>

                                    <ul className='mt-8 text-lg space-y-2'>
                                        {options.map((option, i) => <li onClick={() => setActive(options[option.id])} className={`${Number(option.id) == Number(active?.id) ? 'text-gray-800 font-medium' : 'text-gray-500'} cursor-pointer`} key={i}>{option.title}</li>)}
                                    </ul>
                                </div>
                                <form onSubmit={(e) => e.preventDefault()} className='flex-grow px-5 min-h-[75vh] flex flex-col items-stretch justify-between w-full'>
                                    {Boolean(active.id == 0) ?
                                        <div>
                                            <div className='mb-4'>
                                                <label htmlFor="title" className='text-gray-400 font-medium text-xl p-2 block'>Name of the Event</label>
                                                <input type="text" name='title' value={data.title} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="description" className='text-gray-400 font-medium text-xl p-2 block'>Event Description <span className='text-base text-gray-300'>{`(200 words)`}</span></label>
                                                <textarea type="text" value={data.description} name="description" onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block resize-none h-28' placeholder='event description'></textarea>
                                            </div>
                                            <div className='mb-4 flex items-center gap-8'>
                                                <div className='flex items-center gap-1'>
                                                    <Switch
                                                        color="primary"
                                                        checked={data.withGreyffiti}
                                                        onChange={(e) => { setData({ ...data, withGreyffiti: Boolean(e.target.checked) }) }}
                                                    />
                                                    <label htmlFor="withGreyffiti" className='text-gray-400 font-medium text-xl p-2 block'>Host with greyffiti</label>
                                                </div>
                                                <div className='flex items-center gap-1'>
                                                    <Switch
                                                        color="primary"
                                                        checked={data.freeEvent}
                                                        onChange={(e) => { setData({ ...data, freeEvent: Boolean(e.target.checked) }) }}
                                                    />
                                                    <label htmlFor="freeEvent" className='text-gray-400 font-medium text-xl p-2 block'>This Event is free</label>
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block'>Type of Event</label>
                                                <div className='flex items-center gap-4 flex-wrap w-full p-1'>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.type == 'competition' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='competition'><input className='hidden' type="radio" id="competition" name="type" value="competition" onChange={handleChange} />Competitions</label>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.type == 'hackathon' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='hackathon'><input className='hidden' type="radio" id="hackathon" name="type" value="hackathon" onChange={handleChange} />Hackathons</label>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.type == 'meetup' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='meetup'><input className='hidden' type="radio" id="meetup" name="type" value="meetup" onChange={handleChange} />Meet ups</label>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.type == 'conference' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='conference'><input className='hidden' type="radio" id="conference" name="type" value="conference" onChange={handleChange} />Conference</label>
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block'>Mode of Event</label>
                                                <div className='flex items-center gap-4 flex-wrap w-full p-1'>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.mode == 'online' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='online'><input className='hidden' type="radio" id="online" name="mode" value="online" onChange={handleChange} />Online</label>
                                                    <label className={`text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 ${data.mode == 'offline' ? 'bg-blue-500' : 'bg-gray-400'}`} htmlFor='offline'><input className='hidden' type="radio" id="offline" name="mode" value="offline" onChange={handleChange} />Offline</label>
                                                </div>
                                            </div>

                                            {data.mode == "offline" && <>
                                                <div className='mb-4'>
                                                    <label htmlFor="location" className='text-gray-400 font-medium text-xl p-2 block'>Add location</label>
                                                    <input type="text" name='location' value={data.location} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event address' />
                                                    <input type="text" name='maplink' value={data.maplink} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mt-4' placeholder='Paste Google Maps Link' />
                                                </div>
                                            </>}

                                            <div className='mb-4'>
                                                <label htmlFor="event_link" className='text-gray-400 font-medium text-xl p-2 block'>Event Link</label>
                                                <input type="url" name='event_link' onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Paste event link' />
                                            </div>

                                            <div className='flex items-center justify-start flex-wrap gap-4'>
                                                <div className='mb-4 flex-grow'>
                                                    <label htmlFor="start_date" className='text-gray-400 font-medium text-xl p-2 block'>Start Date</label>
                                                    <input type="date" name='start_date' value={data.start_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                </div>
                                                <div className='mb-4 flex-grow'>
                                                    <label htmlFor="end_date" className='text-gray-400 font-medium text-xl p-2 block'>End Date</label>
                                                    <input type="date" name='end_date' value={data.end_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-start flex-wrap gap-4'>
                                                <div className='mb-4 flex-grow'>
                                                    <label htmlFor="start_time" className='text-gray-400 font-medium text-xl p-2 block'>Start Time</label>
                                                    <input type="time" name='start_time' value={data.start_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                </div>
                                                <div className='mb-4 flex-grow'>
                                                    <label htmlFor="end_time" className='text-gray-400 font-medium text-xl p-2 block'>End Time</label>
                                                    <input type="time" name='end_time' value={data.end_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        Boolean(active.id == 1) ?
                                            <div>
                                                <div className='mb-4'>
                                                    <label htmlFor="primary_number" className='text-gray-400 font-medium text-xl p-2 block'>Phone Number</label>
                                                    <input type="text" name='primary_number' value={data.primary_number} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='phone number' />
                                                </div>
                                                <div className='mb-4'>
                                                    <label htmlFor="secondary_number" className='text-gray-400 font-medium text-xl p-2 block'>Alternate Phone Number</label>
                                                    <input type="text" name='secondary_number' value={data.secondary_number} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='phone number' />
                                                </div>
                                                <div className='mb-4'>
                                                    <label htmlFor="primary_email" className='text-gray-400 font-medium text-xl p-2 block'>Email ID</label>
                                                    <input type="text" name='primary_email' value={data.primary_email} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='email' />
                                                </div>
                                                <div className='mb-4'>
                                                    <label htmlFor="whatsapp" className='text-gray-400 font-medium text-xl p-2 block'>Whatsapp Number</label>
                                                    <input type="text" name='whatsapp' value={data.whatsapp} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='phone number' />
                                                </div>
                                            </div>
                                            :
                                            Boolean(active.id == 2) ?
                                                <div>
                                                    <div className='mb-4'>
                                                        {speakers.map((speaker, i) => <Speaker initial={speaker} key={i} index={i} uploadFile={uploadFile} />)}
                                                        <Speaker addSpeaker={addSpeaker} index={speakers.length} uploadFile={uploadFile} />
                                                    </div>
                                                </div>
                                                :
                                                Boolean(active.id == 3) ?
                                                    <div>
                                                        {
                                                            (data.freeEvent || data.freeEvent == 'true') ?
                                                                <h1 className='text-gray-500 text-3xl font-medium p-1'>This event is free</h1>
                                                                :
                                                                <div className='mb-4'>
                                                                    {tiers.map((tier, i) => <Tier initial={tier} key={i} index={i} />)}
                                                                    <Tier addTier={addTier} index={tiers.length} />
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    Boolean(active.id == 4) ?
                                                        <div>
                                                            {
                                                                ((data.withGreyffiti || data.withGreyffiti == 'true') && ['competition', 'hackathon'].includes(data.type))
                                                                    ?
                                                                    <>
                                                                        <div className='flex-grow'>
                                                                            <label htmlFor="" className='text-gray-400 font-medium text-2xl p-2 block'>Submission <span className='font-bold'>Open</span>s From</label>
                                                                        </div>
                                                                        <div className='flex items-center justify-start flex-wrap gap-4'>
                                                                            <div className='mb-4 flex-grow'>
                                                                                <label htmlFor="submission_start_date" className='text-gray-400 font-medium text-xl p-2 block'>Date</label>
                                                                                <input type="date" name='submission_start_date' value={data.submission_start_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                                            </div>
                                                                            <div className='mb-4 flex-grow'>
                                                                                <label htmlFor="submission_start_time" className='text-gray-400 font-medium text-xl p-2 block'>Time</label>
                                                                                <input type="time" name='submission_start_time' value={data.submission_start_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex-grow'>
                                                                            <label className='text-gray-400 font-medium text-2xl p-2 block'>Submission <span className='font-bold'>End</span>s On</label>
                                                                        </div>
                                                                        <div className='flex items-center justify-start flex-wrap gap-4 mb-4'>
                                                                            <div className='mb-4 flex-grow'>
                                                                                <label htmlFor="submission_end_date" className='text-gray-400 font-medium text-xl p-2 block'>Date</label>
                                                                                <input type="date" name='submission_end_date' value={data.submission_end_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                                            </div>
                                                                            <div className='mb-4 flex-grow'>
                                                                                <label htmlFor="submission_end_time" className='text-gray-400 font-medium text-xl p-2 block'>Time</label>
                                                                                <input type="time" name='submission_end_time' value={data.submission_end_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <h1 className='text-gray-500 text-3xl font-medium p-1'>The submission option is not available in conference, meetups and the events hosted on other platforms </h1>
                                                                    </>}
                                                        </div>
                                                        :
                                                        Boolean(active.id == 5) ?
                                                            <div>
                                                                <h1 className='text-3xl font-semibold text-gray-500 mb-4'>Itenary & Schedule</h1>
                                                                <div className='p-4 bg-white w-full'>
                                                                    <div className='flex items-center w-full justify-between'>
                                                                        <label htmlFor='guidelines' className='text-gray-400 cursor-pointer'>{data.guidelines || <>Upload a detailed Guideline Document <span className='font-bold'>(PDF)</span> for Submissions under 10MB</>}</label>
                                                                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15 22.2953C14.4688 22.2953 14.0238 22.1104 13.665 21.7406C13.305 21.3721 13.125 20.9149 13.125 20.3692V6.59711L9.60938 10.2087C9.23438 10.5939 8.79688 10.7865 8.29688 10.7865C7.79688 10.7865 7.34375 10.5778 6.9375 10.1605C6.5625 9.77528 6.38313 9.3175 6.39937 8.78716C6.41438 8.25811 6.59375 7.81701 6.9375 7.46388L13.6875 0.529694C13.875 0.337078 14.0781 0.200321 14.2969 0.119422C14.5156 0.039807 14.75 0 15 0C15.25 0 15.4844 0.039807 15.7031 0.119422C15.9219 0.200321 16.125 0.337078 16.3125 0.529694L23.0625 7.46388C23.4375 7.84912 23.6169 8.30626 23.6006 8.83531C23.5856 9.36565 23.4062 9.80738 23.0625 10.1605C22.6875 10.5457 22.2425 10.7461 21.7275 10.7615C21.2113 10.7782 20.7656 10.5939 20.3906 10.2087L16.875 6.59711V20.3692C16.875 20.9149 16.6956 21.3721 16.3369 21.7406C15.9769 22.1104 15.5312 22.2953 15 22.2953ZM3.75 30C2.71875 30 1.83625 29.6231 1.1025 28.8693C0.3675 28.1143 0 27.2071 0 26.1477V22.2953C0 21.7496 0.179375 21.2918 0.538125 20.922C0.898125 20.5535 1.34375 20.3692 1.875 20.3692C2.40625 20.3692 2.85187 20.5535 3.21187 20.922C3.57062 21.2918 3.75 21.7496 3.75 22.2953V26.1477H26.25V22.2953C26.25 21.7496 26.43 21.2918 26.79 20.922C27.1488 20.5535 27.5938 20.3692 28.125 20.3692C28.6562 20.3692 29.1012 20.5535 29.46 20.922C29.82 21.2918 30 21.7496 30 22.2953V26.1477C30 27.2071 29.6331 28.1143 28.8994 28.8693C28.1644 29.6231 27.2812 30 26.25 30H3.75Z" fill="#CFD8DC" />
                                                                        </svg>
                                                                        <input type="file" value="" name="guidelines" id="guidelines" className="hidden" onChange={handleFile} />
                                                                    </div>
                                                                </div>
                                                                <p>or</p>
                                                                <input type="text" placeholder='Paste link' value={data.guidelines} name="guidelines" id="guidelines" className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block my-2' />
                                                            </div>
                                                            :
                                                            Boolean(active.id == 6) ?
                                                                <div>
                                                                    <div className='flex items-start justify-between gap-20 w-full'>
                                                                        <label htmlFor='banner_url' className='flex-grow w-full max-w-md'>
                                                                            <h1 className='text-gray-400 font-medium text-xl p-2 block'>Event Banner</h1>
                                                                            <div className='h-44 w-full overflow-hidden max-w-md bg-white rounded-lg grid place-items-center'>
                                                                                {
                                                                                    data.banner_url
                                                                                        ?
                                                                                        <img src={data.banner_url} alt="" />
                                                                                        :
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-gray-300">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                                                        </svg>
                                                                                }
                                                                            </div>
                                                                            <input type="file" value="" name='banner_url' id='banner_url' className='hidden' accept='image/*' alt='' onChange={handleFile} />
                                                                        </label>
                                                                        <div className='flex-grow'>
                                                                            <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block'>Card Banner</label>

                                                                            <div className='w-full max-w-xs bg-white rounded-xl overflow-hidden'>
                                                                                <div className='w-full h-52 relative'>
                                                                                    {
                                                                                        data.thumbnail ?
                                                                                            <img className='w-full h-full object-cover' src={data.thumbnail} alt="" />
                                                                                            : <img className='w-full h-full object-cover' src={"https://images.unsplash.com/photo-1505373877841-8d25f7d46678?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80"} alt="" />
                                                                                    }
                                                                                    <div className='w-full h-52 bg-gradient-to-t from-black to-transparent grid place-items-center absolute inset-0'>
                                                                                        <span className='text-gray-800 font-medium py-1 px-4 rounded-3xl bg-white absolute top-2 right-2 capitalize'>{data.type}</span>
                                                                                        <label htmlFor="thumbnail">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-white">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                                                            </svg>
                                                                                        </label>
                                                                                        <input type="file" value="" name='thumbnail' id='thumbnail' className='hidden' accept='image/*' alt='' onChange={handleFile} />
                                                                                        <p className='text-white font-medium text-lg absolute bottom-2 left-2 capitalize'>{data.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {data.start_date && <div className='px-4 py-2 bg-white'>
                                                                                    <p>{new Date(data.start_date).toDateString()} | <span className='capitalize'>{data.mode}</span></p>
                                                                                    <p>{new Date(data.start_date).getHours()}:{new Date(data.end_date).getMinutes()} to {new Date(data.end_date).getHours()}:{new Date(data.end_date).getMinutes()}</p>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='my-4 flex-grow'>
                                                                        <label htmlFor="tags" className='text-gray-400 font-medium text-xl p-2 block'>Tags</label>
                                                                        <div className='flex items-center flex-wrap gap-4 mb-3'>
                                                                            {data?.tags?.map((tag, i) => <div key={i} className='text-base font-medium px-5 py-1.5 rounded-3xl bg-white shadow text-gray-500 relative'>
                                                                                <span>#{tag}</span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 bg-red text-white rounded-full p-0.5 cursor-pointer absolute -top-1 -right-1" onClick={() => handleTag('remove', tag, () => { })}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                                </svg>
                                                                            </div>)}
                                                                        </div>
                                                                        {data?.tags?.length < 5 && <AddTag handleTag={handleTag} />}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                </>
                                    }
                                    <div className='mb-4 mt-8 flex items-center justify-between gap-4 '>
                                        <span>
                                        </span>
                                        <span className='bg-blue text-gray-50 rounded-lg py-2 px-8 text-lg font-semibold cursor-pointer' onClick={(e) => Boolean(active.id == 6) ? handleSubmit(e) : setActive(options[Number(active.id) + 1])}>{Boolean(active.id == 6) ? 'Submit' : 'Next'}</span>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </>
            }

        </>
    )
}

export default NewEvent

const AddTag = ({ handleTag }) => {
    const [tag, setTag] = useState('');

    return <>
        <div className={`w-full border-gray-100 max-w-xs border border-transparent rounded-full flex items-center gap-4 transform transition-all duration-100 ease-in-out bg-transparent`}>
            {/* <input value={tag} placeholder="Tag" onChange={(e) => setTag(e.target.value)} className={`flex-grow py-2 px-5 outline-none border-none bg-transparent ${active ? '' : 'hidden'}`} type="text" /> */}
            <input type="text" name='tag' id='tag' value={tag} onChange={(e) => setTag(e.target.value)} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Add a tag' />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick={async () => handleTag('add', tag, () => setTag(''))} className="rounded-full w-10 h-10 text-white bg-blue p-1 flex-shrink-0 cursor-pointer">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
        </div>

    </>
}


const Speaker = ({ addSpeaker, initial, index, uploadFile }) => {
    let newSp = !Boolean(initial)
    const [data, setData] = useState({
        name: initial?.name || '',
        designation: initial?.designation || '',
        company: initial?.company || '',
        speaker: initial?.speaker || ''
    })
    const handleClick = () => {
        if (data.name && data.designation && data.company) {
            addSpeaker(data);
            setData({
                name: '',
                designation: '',
                company: '',
                speaker: ''
            })
        }
        else {
            toast.error("Fill all the details")
        }
    }
    const handleChange = (e) => {
        e.preventDefault()
        setData({ ...data, [e.target.name]: e.target.value })
    }


    const handleFile = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let imgRes = {};
        if (file.size > 1048576) {
            toast.error("File size is large to upload")
        } else if (file.type.includes('image')) {
            imgRes = await uploadFile(file);
        }
        else {
            toast.error("Invalid Image format")
        }
        if (imgRes?.data?.url) {
            setData({ ...data, [e.target.name]: imgRes?.data?.url })
        }
    }


    return (<>
        <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block mt-2'>Speaker {index + 1}</label>
        <input disabled={!newSp} type="text" value={data.name} onChange={handleChange} name='name' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Name' />
        <input disabled={!newSp} type="text" value={data.designation} onChange={handleChange} name='designation' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Designation' />
        <input disabled={!newSp} type="text" value={data.company} onChange={handleChange} name='company' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Company' />
        <div className='p-4 bg-white w-full'>
            <div className='flex items-center w-full justify-between'>
                <label htmlFor='speaker' className='text-gray-400 cursor-pointer'>{data.speaker || 'Upload 200 x 200pX Photo of Speakers'}</label>
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 22.2953C14.4688 22.2953 14.0238 22.1104 13.665 21.7406C13.305 21.3721 13.125 20.9149 13.125 20.3692V6.59711L9.60938 10.2087C9.23438 10.5939 8.79688 10.7865 8.29688 10.7865C7.79688 10.7865 7.34375 10.5778 6.9375 10.1605C6.5625 9.77528 6.38313 9.3175 6.39937 8.78716C6.41438 8.25811 6.59375 7.81701 6.9375 7.46388L13.6875 0.529694C13.875 0.337078 14.0781 0.200321 14.2969 0.119422C14.5156 0.039807 14.75 0 15 0C15.25 0 15.4844 0.039807 15.7031 0.119422C15.9219 0.200321 16.125 0.337078 16.3125 0.529694L23.0625 7.46388C23.4375 7.84912 23.6169 8.30626 23.6006 8.83531C23.5856 9.36565 23.4062 9.80738 23.0625 10.1605C22.6875 10.5457 22.2425 10.7461 21.7275 10.7615C21.2113 10.7782 20.7656 10.5939 20.3906 10.2087L16.875 6.59711V20.3692C16.875 20.9149 16.6956 21.3721 16.3369 21.7406C15.9769 22.1104 15.5312 22.2953 15 22.2953ZM3.75 30C2.71875 30 1.83625 29.6231 1.1025 28.8693C0.3675 28.1143 0 27.2071 0 26.1477V22.2953C0 21.7496 0.179375 21.2918 0.538125 20.922C0.898125 20.5535 1.34375 20.3692 1.875 20.3692C2.40625 20.3692 2.85187 20.5535 3.21187 20.922C3.57062 21.2918 3.75 21.7496 3.75 22.2953V26.1477H26.25V22.2953C26.25 21.7496 26.43 21.2918 26.79 20.922C27.1488 20.5535 27.5938 20.3692 28.125 20.3692C28.6562 20.3692 29.1012 20.5535 29.46 20.922C29.82 21.2918 30 21.7496 30 22.2953V26.1477C30 27.2071 29.6331 28.1143 28.8994 28.8693C28.1644 29.6231 27.2812 30 26.25 30H3.75Z" fill="#CFD8DC" />
                </svg>
                <input disabled={!newSp} type="file" value="" name="speaker" id="speaker" className="hidden" accept='image/*' alt='' onChange={handleFile} />
            </div>
        </div>
        {newSp && <div className={`bg-gray-400 text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 mt-4 max-w-max bg-blue cursor-pointer`} onClick={handleClick}>{'Add Speaker'}</div>}
    </>)
}

const Tier = ({ addTier, initial, index }) => {
    const [data, setData] = useState({
        name: initial?.name || '',
        cost: initial?.cost || '',
        capacity: initial?.capacity || '',
        benefits: initial?.benefits || ''
    })
    const handleClick = () => {
        if (data.name && data.cost && data.capacity && data.benefits) {
            addTier(data);
            setData({
                name: '',
                cost: '',
                capacity: '',
                benefits: ''
            })
        }
        else {
            toast.error("Fill all the details")
        }
    }
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    return (<>
        <label htmlFor="" className='text-gray-400 font-medium text-xl p-2 block'>Tier {index + 1}</label>
        <input type="text" value={data.name} onChange={handleChange} name="name" className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Tier Name' />
        <input type="text" value={data.cost} onChange={handleChange} name="cost" className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Cost of the Ticket' />
        <input type="text" value={data.capacity} onChange={handleChange} name='capacity' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mb-4' placeholder='Capacity' />
        <textarea type="text" value={data.benefits} onChange={handleChange} name='benefits' className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block resize-none h-28' placeholder='List of benefits'></textarea>
        <div className='bg-gray-400 text-gray-50 rounded-lg p-2 text-base font-semibold hover:bg-blue-500 mt-4 max-w-max bg-blue cursor-pointer' onClick={handleClick}>{(data.name && data.designation && data.company) ? 'Save' : 'Add Tier'}</div>
    </>)
}



