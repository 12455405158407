import React, { useRef, useState } from 'react'
import CardBG from '../../assets/images/CardBG.png'
import { deleteRepositoryById, editRepositoryById, getRepositoryList, getReposBasedOnProjectId, getAllFilesList } from '../../service/service';
import { useClickOutside } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux'
import { repoList } from '../../slices/repoSlice';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { routeList } from '../../slices/routeSlice';
import { selectUserDetails } from '../../slices/userSlice';
import { MoreHoriz } from '@material-ui/icons';
import AddRepository from './AddRepository';
import EditIcon from '../../assets/icons/EditIcon';
import Picture from '../../assets/images/picture.png'
import toast from 'react-hot-toast';
import moment from 'moment';
import { filePreview } from '../../slices/fileSlice';
import { downloadFolderZip } from '../../utils/downloadFolder';
import Like from '../../assets/images/Like.svg'


function RepoCard({ repo, project, projectDetail, username, setRepos, retailer }) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)

    const moreRef = useRef()
    useClickOutside(() => setMore(false), moreRef)

    async function getFreshRepoList() {
        const repoData = Number(project) ? await getReposBasedOnProjectId({ projectId: project }) : await getRepositoryList(username);
        setRepos(repoData.data || [])
        dispatch(repoList(repoData.data || []));
    }

    async function deleteRepo(repoId) {
        await deleteRepositoryById(repoId);
        setDeleteModal(false);
        getFreshRepoList()
    }

    async function editRepo(data) {
        const res = await editRepositoryById(data);
        if (res.data != null) {
            getFreshRepoList()
        }
    }

    const changeRepoType = repo => {
        let id = repo.id
        let name = repo.name
        let description = repo.description
        let repository_type = 1
        if (repo.repository_type === 1) {
            repository_type = 2
        } else {
            repository_type = 1
        }
        let data = { name, description, repository_type, id, project_id: repo.project_id }
        setMore(false)
        editRepo(data)
    }

    const downloadRepo = async () => {
        const res = await getAllFilesList(Number(repo.branchid));
        if (res.data) {
            let folder = { name: repo.name, path: res.data[0].path }
            await downloadFolderZip(res.data, folder, 'repository');
        }
    }

    const navigate = () => {
        dispatch(routeList(`/${username}/${repo.projectname}/${repo.name}/${repo.branchname}`))
        history.push(`/${username}/${repo.projectname}/${repo.name}/${repo.branchname}`)
    }

    return (
        <>
            <div className='w-full max-w-[336px] rounded-xl bg-yellow h-[250px] group overflow-hidden shadow-lg relative'>
                <img src={(repo?.thumbnail && repo?.thumbnail != 'undefined') ? repo?.thumbnail : CardBG} className="w-full h-full object-cover" alt='' />
                <div className={`bg-white h-[180px] rounded-lg absolute -bottom-28 group-hover:bottom-0 flex flex-col w-full transition-all ease-in-out duration-300`}>
                    <div className='absolute w-full h-full overflow-hidden top-0 left-0 cursor-pointer' onClick={() => more ? setMore(false) : navigate()}></div>
                    <div className='justify-end items-center px-2 m-2 z-20 hidden group-hover:inline-flex gap-2'>
                        {(repo?.thumbnail && repo?.thumbnail != 'undefined') && <img src={Picture} alt='' className='mr-auto cursor-pointer w-5 h-5' style={{ filter: 'drop-shadow(2px 2px 8px rgba(0,0,0,0.3))' }} onClick={() => dispatch(filePreview({ url: repo?.thumbnail, title: repo?.name, open: true }))} />}
                        {repo.username === user?.username && <> <EditIcon className='cursor-pointer' onClick={() => setAddModal(true)} />
                            <MoreHoriz className='cursor-pointer text-gray-600' onClick={() => setMore(!more)} />
                            {more && <div ref={moreRef} className="absolute top-8 right-2 border min-w-max py-1 px-2 popup-bg shadow-lg rounded-md">
                                <div className='absolute transform -top-1 right-2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => repo?.thumbnail && repo?.thumbnail != 'undefined' ? dispatch(filePreview({ url: repo?.thumbnail, title: repo?.name, open: true })) : toast("No preview available", { id: 'preview' })}>Open Preview</h1>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => changeRepoType(repo)}>{repo.repository_type === 1 ? 'Make it Private' : 'Make it Public'}</h1>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => history.push(`/${username}/${repo.projectname}/${repo.name}/${repo.branchid}?open=true`)}>Invite People</h1>
                                {(retailer ? retailer?.status == 'accepted' : true) ? <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={downloadRepo}>Download as zip</h1> : ''}
                            </div>}
                        </>}
                    </div>
                    <div className={`flex items-center justify-between px-4 group-hover:mb-1 py-4 group-hover:py-0`}>
                        <h1 className='font-medium z-10 text-lg text-blue cursor-pointer' onClick={navigate}>{repo.name}</h1>
                        <button className='py-1 px-4 rounded bg-blue text-white z-10'>
                            {repo.repository_type === 1
                                ? 'Public'
                                : 'Private'}
                        </button>
                    </div>
                    <p className='text-gray-600 px-4 flex-grow line-clamp-2 text-sm py-0.5'>{repo.description}</p>
                    <div className='flex items-end justify-between p-4 pt-0'>
                        <div>
                            <p className=''>{repo.filesLength} files</p>
                            <p className='text-gray-500 text-xs'>Updated {moment(repo.updated_at).fromNow()}</p>
                        </div>

                        <div>
                            <p className='font-medium'>{repo.projectname}</p>
                            <p className='text-gray-500 text-xs'>Added {moment(repo.created_at).fromNow()}</p>
                        </div>
                    </div>

                </div>
            </div>

            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${repo.name}"`} description="This will also delete your all files and folders" type="warn" onCancel={() => setDeleteModal(false)} onOk={() => deleteRepo(repo.id)} />
            {addModal && <AddRepository getFreshRepositoryList={getFreshRepoList} projectData={projectDetail} setAddModal={setAddModal} isEdit={true} repoData={repo} username={username} />}

        </>
    )
}

export default RepoCard

