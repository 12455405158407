import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { getEventById, revokeEvent, updateEvent, uploadFile, withdrawEvent } from '../../service/service';
import NoResults from '../../assets/images/NoResults.svg'
import { selectUserDetails } from '../../slices/userSlice';
import { useDebounce } from '../../utils/helper';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import Loader from '../ReusableComponents/Loader';
import Navbar from '../ReusableComponents/Navbar'
import draftToHtml from 'draftjs-to-html';
import TextEditor from '../ReusableComponents/TextEditor';

const initial = `{"blocks":[{"key":"31ak3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`

let date = new Date()
function EditEvent() {
    const user = useSelector(selectUserDetails)

    const history = useHistory()
    const dispatch = useDispatch()
    const { eventId } = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState();
    const [isUpload, setIsUpload] = useState(false);
    const [bannerURL, setBannerURL] = useState('')
    const [uploadConfirm, setUploadConfirm] = useState(false)
    const [removeEvent, setRemoveEvent] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [edit, setEdit] = useState({
        about: false,
        location: false
    })
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value })
    }

    const about = useDebounce(data?.description, 5000);

    useEffect(() => {
        if (about && event?.description !== about && about !== initial)
            saveDetails();
        else
            setData({ ...data, description: '' })
    }, [about])

    const fetchEventById = async () => {
        let res = await getEventById(eventId);
        if (res.success) {
            if (res.data?.user_id == user?.id) {
                setEvent(res.data);
            }
            else
                setNotFound(true)
            setLoading(false)
        }
        else {
            toast.error("Invalid Event URL")
            history.replace('/events')
        }
    }


    useEffect(() => {
        window.scrollTo({ top: 0 })
        if (eventId && user) {
            fetchEventById();
        }
    }, [user])

    const revoke = async () => {
        setRemoveEvent(false)
        let res = await revokeEvent(eventId);
        if (res.success) {
            toast.success("Event Removed successfully");
            history.push(`/organizationId/events`)
        }
        else {
            toast.error("Unable to withdraw")
        }
    }

    const saveDetails = async () => {
        let tId = toast.loading("Saving Changes");
        let save = await updateEvent({ id: eventId, ...data });
        toast.dismiss(tId);
        if (save.success) {
            fetchEventById()
            toast.success("Changes Saved successfully");
            setEdit({
                ...edit,
                location: false
            })
        }
        else
            toast.error("Changes are not saved")
    }

    const updateBanner = async () => {
        setUploadConfirm(false)
        let tId = toast.loading("Submitting Image");
        let save = await updateEvent({ id: eventId, banner: bannerURL });
        toast.dismiss(tId)
        if (save.success) {
            fetchEventById()
            toast.success("File submitted");
            setBannerURL('')
        }
        else
            toast.error("Unable to make submission")
    }

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }

    const handleImage = async (file) => {
        if (file) {
            const res = await uploadImage(file);
            if (res.data) {
                setBannerURL(res.data.url)
                setIsUpload(false);
                setUploadConfirm(true)
            }
        }
    }

    // function createMarkup() {
    //     return { __html: `${event.description}` };
    // }

    function createMarkup(data) {
        return {
            __html: draftToHtml(JSON.parse(data))
        };
    }

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <Navbar background='#F6FAFD' />
                        {notFound ?
                            <div className='max-w-3xl mx-auto pt-10'>
                                <img src={NoResults} className='mx-auto max-w-xs lg:max-w-md' alt="" />
                                <h1 className='text-2xl lg:text-3xl font-semibold text-gray-600 text-center mt-10 p-5'>Looks like, the event you're searching is removed or does not exist </h1>
                            </div>
                            :
                            <div className='min-h-[90vh] bg-[#F6FAFD] p-5'>
                                <div className='max-w-5xl mx-auto'>
                                    <div className='flex items-start flex-wrap lg:gap-x-8'>
                                        <div className='max-w-2xl flex-grow'>
                                            <div className='flex items-center gap-4'>
                                                <img src={event?.organization_profile_pic} className='w-20 h-20 p-2 rounded-full shadow' alt="" />
                                                <div>
                                                    <h1 className='text-2xl font-medium'>{event?.title}</h1>
                                                    <p className='text-sm text-gray-400 line-clamp-1 max-w-xl'>{event?.organization_bio}</p>
                                                </div>
                                            </div>
                                            <div className='p-5 my-1 relative'>
                                                <div className='w-9 h-9 p-2 rounded-full bg-white shadow-lg border border-blue-500 text-blue cursor-pointer absolute right-2 top-2' onClick={() => setIsUpload(true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                                    </svg>
                                                </div>
                                                <img src={event?.banner} className='h-80 w-full object-cover bg-white rounded-lg border' alt="" />
                                            </div>
                                        </div>

                                        <div className='max-w-2xl flex-grow'>
                                            <div className='max-w-2xl flex-grow px-5 pb-5 bg-white rounded shadow-sm my-6'>
                                                <div className='py-5 relative'>
                                                    {edit.location ?
                                                        <div title='Cancel Edit' className='w-9 h-9 p-2 rounded-full bg-white shadow-lg border border-red-500 text-red cursor-pointer absolute right-2 top-2' onClick={() => setEdit({ ...edit, location: false })}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </div>
                                                        : <div title='Edit Location' className='w-9 h-9 p-2 rounded-full bg-white shadow-lg border border-blue-500 text-blue cursor-pointer absolute right-2 top-2' onClick={() => setEdit({ ...edit, location: true })}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                    <h1 className='text-2xl font-medium'>Event Location</h1>
                                                    {
                                                        edit.location ?
                                                            <>
                                                                <div className='flex items-center gap-2 my-4'>
                                                                    <label htmlFor="location" className='font-medium text-gray-800'>Location:</label>
                                                                    <input value={data?.location || event?.location} onChange={handleChange} className='bg-blue-50 rounded p-1 border-none text-sm outline-none max-w-xs w-full flex-grow' type="text" name='location' id='location' />
                                                                </div>
                                                                <div className='flex items-center gap-2 my-4'>
                                                                    <label htmlFor="contact" className='font-medium text-gray-800'>Contact:</label>
                                                                    <input value={data?.contact || event?.contact} onChange={handleChange} className='bg-blue-50 rounded p-1 border-none text-sm outline-none max-w-xs w-full flex-grow' type="text" name='contact' id='contact' />
                                                                </div>
                                                                <div className='flex items-center gap-2 my-4 mb-6'>
                                                                    <label htmlFor="email" className='font-medium text-gray-800'>Location:</label>
                                                                    <input value={data?.email || event.email} onChange={handleChange} className='bg-blue-50 rounded p-1 border-none text-sm outline-none max-w-xs w-full flex-grow' type="email" name='email' id='email' />
                                                                </div>
                                                                <div className='font-medium text-white bg-blue text-center text-lg py-1 px-4 rounded-md cursor-pointer' onClick={saveDetails}>
                                                                    Save
                                                                </div>
                                                            </>
                                                            : <>
                                                                <div className='flex items-center gap-2 text-gray-600 my-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                                    </svg>
                                                                    <span>
                                                                        {event?.location || '-'}
                                                                    </span>
                                                                </div>
                                                                <div className='flex items-center gap-2 text-gray-600 my-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                                    </svg>

                                                                    <span>
                                                                        {event?.contact || '-'}
                                                                    </span>
                                                                </div>
                                                                <div className='flex items-center gap-2 text-gray-600 my-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                                    </svg>
                                                                    <span>
                                                                        {event?.email || '-'}
                                                                    </span>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='max-w-2xl p-4 bg-white rounded shadow-sm my-6 relative'>
                                        {edit.about ?
                                            <div title='Cancel Edit' className='w-9 h-9 p-2 rounded-full bg-white shadow-lg border border-red-500 text-red cursor-pointer absolute right-2 top-2' onClick={() => setEdit({ ...edit, about: false })}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                            : <div title='Edit Location' className='w-9 h-9 p-2 rounded-full bg-white shadow-lg border border-blue-500 text-blue cursor-pointer absolute right-2 top-2' onClick={() => setEdit({ ...edit, about: true })}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                                </svg>
                                            </div>
                                        }
                                        <h1 className='text-2xl leading-relaxed font-semibold '>About</h1>
                                        {
                                            edit.about ?
                                                <>
                                                    <TextEditor placeholder="Leave a comment" onChange={(value) => setData({ ...data, description: value })} initialValue={event?.description || initial} />
                                                    {/* <textarea id='description' name="description" value={data?.description || event?.description} className='w-full h-80 resize-none border outline-none border-none bg-blue-50 my-2 p-3 leading-relaxed rounded' onChange={handleChange}>

                                                    </textarea> */}
                                                </>
                                                :
                                                <>
                                                    {event?.description && <p dangerouslySetInnerHTML={createMarkup(event?.description)}></p>}
                                                </>
                                        }
                                    </div>

                                    <div className='max-w-2xl p-4 bg-white rounded shadow-sm my-6'>
                                        <div className='flex items-center gap-4 justify-between'>
                                            <h1 className='text-2xl leading-relaxed font-semibold line-clamp-1'>Announcements from event</h1>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 flex-shrink-0 cursor-pointer hover:text-blue-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </div>
                                        <div className='flex items-start gap-4 my-6'>
                                            <img src="https://images.unsplash.com/profile-1579171056760-0293bb679901image" className='w-10 h-10 rounded-full shadow' alt="" />
                                            <div>
                                                <p className='text-gray-600 pb-1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit, asperiores.</p>
                                                <p className='text-gray-400 text-xs italic'>{new Date(date.setDate(date.getDate() - Math.round(Math.random() * 100))).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-start gap-4 my-6'>
                                            <img src="https://images.unsplash.com/profile-1579171056760-0293bb679901image" className='w-10 h-10 rounded-full shadow' alt="" />
                                            <div>
                                                <p className='text-gray-600 pb-1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit, asperiores. Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda temporibus iste tempora, voluptate quia fugiat aliquid magni asperiores consequuntur molestiae. </p>
                                                <p className='text-gray-400 text-xs italic'>{new Date(date.setDate(date.getDate() - Math.round(Math.random() * 100))).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-start gap-4 my-6'>
                                            <img src="https://images.unsplash.com/profile-1579171056760-0293bb679901image" className='w-10 h-10 rounded-full shadow' alt="" />
                                            <div>
                                                <p className='text-gray-600 pb-1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit, asperiores. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos harum nobis quia exercitationem facere, ducimus magnam dicta pariatur repellat iure laudantium et nostrum rerum molestias est dignissimos consequatur fugiat, ad, recusandae cupiditate quasi animi delectus quo obcaecati! Atque, neque doloremque!</p>
                                                <p className='text-gray-400 text-xs italic'>{new Date(date.setDate(date.getDate() - Math.round(Math.random() * 100))).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='w-full p-10 grid place-items-center'>
                                    <button className='border border-red-400 text-red py-2 px-6 text-lg font-medium bg-white rounded' onClick={() => setRemoveEvent(true)}>Revoke Event</button>
                                </div>
                            </div>
                        }
                    </>
            }

            {isUpload && <div className='fixed top-0 left-0 w-full min-h-screen overflow-hidden grid place-items-center' style={{ zIndex: 2000 }}>
                <div className={`rounded-lg w-full max-w-xl overflow-hidden z-10 bg-white p-5 lg:p-10`}>
                    <h1 className='text-xl text-center font-semibold text-gray-800 max-w-xs mx-auto'>Upload File for Submission</h1>
                    <p className='text-center mb-3 mt-1 text-sm text-gray-400'>Supported Image type: .png, .jpg, .jpeg</p>
                    <FileUploader
                        multiple={false}
                        handleChange={(file) => toast.promise(
                            handleImage(file),
                            {
                                loading: 'Uploading File...',
                                success: <p>File Uploaded!</p>,
                                error: <p>Could not upload file!</p>,
                            }
                        )}
                        name="file"
                        children={
                            <div className='flex items-center justify-center flex-col border border-dashed p-4'>
                                <h1 className='text-gray-500 text-lg mb-4'>Drag & drop or Browse your file from <span className='text-blue hover:text-blue-600 cursor-pointer'>here</span></h1>
                                <svg width="238" height="158" viewBox="0 0 238 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M181.219 44.4147C177.948 44.4147 175.111 44.6202 171.994 45.1429C164.221 19.0729 139.493 0 110.97 0C76.1432 0 48.5273 28.4519 48.5273 63.3456C48.5273 66.457 48.7984 69.0688 49.2368 72.0829C47.5503 71.8689 45.2596 72.0874 43.5602 72.0829C19.8839 72.0829 0.985352 91.305 0.985352 115.041C0.985352 138.778 19.8839 158 43.5602 158H96.0692V109.217H66.2668L119.485 48.7834L172.704 109.217H142.902V158H181.219C212.538 158 237.985 132.613 237.985 101.207C237.985 69.7775 212.538 44.4147 181.219 44.4147Z" fill="#2DC8A8" />
                                </svg>

                            </div>
                        }
                    />
                </div>
                <div className='absolute top-0 w-full min-h-screen overflow-hidden bg-black bg-opacity-80 filter backdrop-blur-sm' onClick={() => setIsUpload(false)}></div>
            </div>}

            <ConfirmModal open={uploadConfirm} heading={`Are you sure you want to save this banner?`} okText='Yes' img={bannerURL} onCancel={() => { setBannerURL(''); setUploadConfirm(false) }} onOk={(e) => updateBanner()} />
            <ConfirmModal open={removeEvent} heading={`Are you sure you want to revoke the event?`} description={"After revoking, changes can't be reversed"} okText='Yes' type="warn" onCancel={() => setRemoveEvent(false)} onOk={revoke} />

        </>
    )
}

export default EditEvent
