import { Business, InfoOutlined, Public, Security } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import Navbar from '../ReusableComponents/Navbar'
import BasicInfo from './BasicInfo'
import Deactivate from './Deativate'
import Social from './Social'
import Status from './Status'
import Upgrade from './Upgrade'
import Hire from './Hire'
import { selectUserDetails } from '../../slices/userSlice'
import { useSelector } from 'react-redux'
import BusinessDetails from '../Company/BusinessDetails'
import MemberAccess from '../Company/MemberAccess'
import ProUserAccount from './ProUserAccount'
import CompanyUserAccount from '../Company/CompanyUserAccount'
import CompanyDetail from '../Company/CompanyDetail'
import CompanyInfo from './CompanyInfo'
import { useEffect } from 'react'

function Edit() {
    const [index, setIndex] = useState('basicInfo')
    const user = useSelector(selectUserDetails)

    useEffect(() => {
        if (!user?.plan?.name?.includes('free') && !user?.plan?.name?.includes('pro')) {
            if (user?.plan) {
                setIndex('companyInfo')
            }
        }
    }, [user])

    return (
        <>
            <Navbar />
            {
                user ?
                    <div className='flex items-start flex-col lg:flex-row w-full max-w-7xl mx-auto p-5 gap-10'>
                        <div className='w-full max-w-sm sticky top-[100px] rounded-lg overflow-hidden border shadow-lg shadow-gray-200 bg-[#F6FAFD]'>
                            {(!user?.plan?.name?.includes('free') && !user?.plan?.name?.includes('pro')) ? <div onClick={() => setIndex('companyInfo')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'companyInfo' ? 'bg-red' : ''} p-5`}>
                                <InfoOutlined className={index == 'companyInfo' ? 'text-white' : 'text-gray-600'} />
                                <div className={`flex-grow ${index == 'companyInfo' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Company Information</h1>
                                    <p className='text-sm py-1'>Provide details about your company which will be showcased to users visiting your company page</p>
                                </div>
                            </div>
                                :
                                <div onClick={() => setIndex('basicInfo')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'basicInfo' ? 'bg-red' : ''} p-5`}>
                                    <InfoOutlined className={index == 'basicInfo' ? 'text-white' : 'text-gray-600'} />
                                    <div className={`flex-grow ${index == 'basicInfo' ? 'text-gray-50' : 'text-gray-600'}`}>
                                        <h1 className='font-semibold'>Basic Information</h1>
                                        <p className='text-sm py-1'>Change your basic information or upgrade your account</p>
                                    </div>
                                </div>}
                            {!user?.plan?.name?.includes('free') && !user?.plan?.name?.includes('pro') ? <div onClick={() => setIndex('business')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'business' ? 'bg-red' : ''} p-5`}>
                                <svg className={`w-7 ${index == 'business' ? 'text-white' : 'text-gray-600'}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3334 18.3337H3.66675C2.15058 18.3337 0.916748 17.0998 0.916748 15.5837V6.41699C0.916748 4.90083 2.15058 3.66699 3.66675 3.66699H18.3334C19.8496 3.66699 21.0834 4.90083 21.0834 6.41699V15.5837C21.0834 17.0998 19.8496 18.3337 18.3334 18.3337ZM3.66675 5.50033C3.16166 5.50033 2.75008 5.91191 2.75008 6.41699V15.5837C2.75008 16.0887 3.16166 16.5003 3.66675 16.5003H18.3334C18.8385 16.5003 19.2501 16.0887 19.2501 15.5837V6.41699C19.2501 5.91191 18.8385 5.50033 18.3334 5.50033H3.66675ZM9.16675 13.7503H5.50008C5.25697 13.7503 5.02381 13.6537 4.8519 13.4818C4.67999 13.3099 4.58342 13.0768 4.58342 12.8337C4.58342 12.5905 4.67999 12.3574 4.8519 12.1855C5.02381 12.0136 5.25697 11.917 5.50008 11.917H9.16675C9.40986 11.917 9.64302 12.0136 9.81493 12.1855C9.98684 12.3574 10.0834 12.5905 10.0834 12.8337C10.0834 13.0768 9.98684 13.3099 9.81493 13.4818C9.64302 13.6537 9.40986 13.7503 9.16675 13.7503ZM9.16675 10.0837H5.50008C5.25697 10.0837 5.02381 9.98708 4.8519 9.81517C4.67999 9.64326 4.58342 9.41011 4.58342 9.16699C4.58342 8.92388 4.67999 8.69072 4.8519 8.51881C5.02381 8.3469 5.25697 8.25033 5.50008 8.25033H9.16675C9.40986 8.25033 9.64302 8.3469 9.81493 8.51881C9.98684 8.69072 10.0834 8.92388 10.0834 9.16699C10.0834 9.41011 9.98684 9.64326 9.81493 9.81517C9.64302 9.98708 9.40986 10.0837 9.16675 10.0837Z" fill="currentColor" />
                                    <path d="M14.6666 11.4587C15.6791 11.4587 16.4999 10.6378 16.4999 9.62533C16.4999 8.6128 15.6791 7.79199 14.6666 7.79199C13.6541 7.79199 12.8333 8.6128 12.8333 9.62533C12.8333 10.6378 13.6541 11.4587 14.6666 11.4587Z" fill="currentColor" />
                                    <path d="M14.6667 12.2432C13.2348 12.2432 12.375 12.8986 12.375 13.5531C12.375 13.8803 13.2348 14.2085 14.6667 14.2085C16.0105 14.2085 16.9583 13.8812 16.9583 13.5531C16.9583 12.8986 16.06 12.2432 14.6667 12.2432Z" fill="currentColor" />
                                </svg>

                                <div className={`flex-grow ${index == 'business' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Business Details</h1>
                                    <p className='text-sm py-1'>Change your business Details or upgrade your account</p>
                                </div>
                            </div> : ''}
                            {user?.plan?.name == 'pro' ? <div onClick={() => setIndex('hireMe')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'hireMe' ? 'bg-red' : ''} p-5`}>
                                <Public className={index == 'hireMe' ? 'text-white' : 'text-gray-600'} />
                                <div className={`flex-grow ${index == 'hireMe' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Hire me</h1>
                                    <p className='text-sm py-1'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, quos.</p>
                                </div>
                            </div> : ''}
                            {(!user?.plan?.name?.includes('free') && !user?.plan?.name?.includes('pro')) ? < div onClick={() => setIndex('access')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'access' ? 'bg-red' : ''} p-5`}>
                                <svg className={`w-7 ${index == 'access' ? 'text-white' : 'text-gray-600'}`} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 1.75C5.67 1.75 1.75 5.67 1.75 10.5C1.75 15.33 5.67 19.25 10.5 19.25C15.33 19.25 19.25 15.33 19.25 10.5C19.25 5.67 15.33 1.75 10.5 1.75ZM3.5 10.5C3.5 9.96624 3.57 9.44124 3.68375 8.94249L7.86625 13.125V14C7.86625 14.9625 8.65375 15.75 9.61625 15.75V17.4387C6.1775 17.0012 3.5 14.0612 3.5 10.5ZM13.9913 14C14.7788 14 15.4263 14.5163 15.6538 15.225C16.7913 13.9825 17.5 12.32 17.5 10.5C17.5 7.56877 15.68 5.04877 13.1163 4.01627V4.37502C13.1163 5.33752 12.3288 6.12502 11.3663 6.12502H9.61625V7.87502C9.61625 8.35627 9.2225 8.75002 8.74125 8.75002H6.99125V10.5H12.2413C12.7225 10.5 13.1163 10.8938 13.1163 11.375V14H13.9913Z" fill="currentColor" />
                                </svg>

                                <div className={`flex-grow ${index == 'access' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Member Access</h1>
                                    <p className='text-sm py-1'>Choose all the members who are part of your organization.</p>
                                </div>
                            </div> : ''}
                            {true ? <div onClick={() => setIndex('socials')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'socials' ? 'bg-red' : ''} p-5`}>
                                <Public className={index == 'socials' ? 'text-white' : 'text-gray-600'} />
                                <div className={`flex-grow ${index == 'socials' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Web & social</h1>
                                    <p className='text-sm py-1'>Connect accounts from various websites to let users know more about you</p>
                                </div>
                            </div> : ''}
                            {user?.plan?.name?.includes('free') || user?.plan?.name?.includes('pro') ? <div onClick={() => setIndex('status')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'status' ? 'bg-red' : ''} p-5`}>
                                <Security className={index == 'status' ? 'text-white' : 'text-gray-600'} />
                                <div className={`flex-grow ${index == 'status' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Status</h1>
                                    <p className='text-sm py-1'>Change status to help with user interaction</p>
                                </div>
                            </div> : ''}
                            {(user?.organization?.created_by == user?.id || (!user?.organization && (user?.plan?.name?.includes('pro') || user?.plan?.name?.includes('free')))) ? <div onClick={() => setIndex('upgrade')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'upgrade' ? 'bg-red' : ''} p-5`}>
                                <Security className={index == 'upgrade' ? 'text-white' : 'text-gray-600'} />
                                <div className={`flex-grow ${index == 'upgrade' ? 'text-gray-50' : 'text-gray-600'}`}>
                                    <h1 className='font-semibold'>Account upgrades</h1>
                                    <p className='text-sm py-1'>upgrade to Pro membership</p>
                                </div>
                            </div> : <></>}
                            {(user?.organization?.created_by == user?.id || (!user?.organization && (user?.plan?.name?.includes('pro') || user?.plan?.name?.includes('free')))) ? <></> : <>
                                <div onClick={() => setIndex('companyDetail')} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'companyDetail' ? 'bg-red' : ''} p-5`}>
                                    <svg className={`w-7 ${index == 'companyDetail' ? 'text-white' : 'text-gray-600'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                    </svg>

                                    <div className={`flex-grow ${index == 'companyDetail' ? 'text-gray-50' : 'text-gray-600'}`}>
                                        <h1 className='font-semibold'>Company Details</h1>
                                        <p className='text-sm py-1'>about your company</p>
                                    </div>
                                </div></>}
                            {/* <div onClick={() => setIndex(4)} className={`w-full flex items-start cursor-pointer border gap-4 ${index == 'upgrade' ? 'bg-red' : ''} p-5`}>
                        <Security className={index == 'upgrade' ? 'text-white' : 'text-gray-600'} />
                        <div className={`flex-grow ${index == 'upgrade' ? 'text-gray-50' : 'text-gray-600'}`}>
                            <h1 className='font-semibold'>Account Setting</h1>
                            <p className='text-sm py-1'>Disable or Remove account from Greyffiti</p>
                        </div>
                    </div> */}
                        </div>

                        <div className='w-full flex-grow'>
                            {index == 'basicInfo' && <BasicInfo />}
                            {index == 'companyInfo' && <CompanyInfo />}
                            {index == 'business' && <BusinessDetails />}
                            {index == 'socials' && <Social />}
                            {index == 'hireMe' && <Hire />}
                            {index == 'access' && <MemberAccess user={user} />}
                            {index == 'status' && <Status />}
                            {index == 'upgrade' && (user?.plan?.name?.includes('pro') ? <ProUserAccount user={user} subscription={user?.subscription} /> : (!user?.plan?.name?.includes('pro') && !user?.plan?.name?.includes('free')) ? <CompanyUserAccount user={user} subscription={user?.subscription} /> : <Upgrade user={user} />)}
                            {index == 'companyDetail' && <CompanyDetail user={user} />}
                        </div>
                    </div >
                    :
                    <></>}
        </>
    )
}

export default Edit