import React from 'react'
import { useHistory } from 'react-router-dom'
import UserBordered from '../../assets/icons/UserBordered'

function UserCard({ user, className, children }) {
    const history = useHistory();

    return (
        <div className={`max-w-xs flex flex-col items-center justify-center w-full gap-2 py-5 ${className}`}>
            {children ? children
                :
                <>
                    {
                        ((user?.organization?.created_by == user?.id) ? user?.organization?.profile_pic : user?.profile_pic) ?
                            <img
                                alt='profile'
                                src={(user?.organization?.created_by == user?.id) ? user?.organization?.profile_pic : user?.profile_pic}
                                className={`w-40 h-40 rounded-full border-4 object-cover ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`}
                            />
                            : <UserBordered className={`w-40 h-40 rounded-full`} />
                    }
                    <h1 className='text-2xl font-medium text-gray-800 cursor-pointer text-center' onClick={() => history.push(`/userProfile/${user.username}`)}>
                        {user?.organization?.created_by == user?.id ? user?.organization?.name : user.username}
                    </h1>
                    <p className='text-gray-400 -mt-2 text-center'>
                        {user?.organization?.created_by == user?.id ? user?.organization?.display_name : user.name}
                    </p>

                    {
                        user?.organization ?
                            <p className='text-gray-600 capitalize'>
                                {user.role} at {user?.organization?.display_name}
                            </p>
                            :
                            user.profession && <p className='text-gray-600'>
                                {user.profession}
                            </p>
                    }
                    {user?.organization?.created_by == user?.id ?
                        <p className='text-base font-light text-gray-400 px-3 text-center'>
                            <span className='block text-lg font-medium text-gray-800'>About</span>
                            {(user?.organization?.bio?.length > 100 ? `${user?.organization?.bio?.slice(0, 100)}...` : user?.organization?.bio) || '-'}
                        </p>
                        :
                        <p className='text-base font-light text-gray-400 px-3 text-center'>
                            <span className='block text-lg font-medium text-gray-800'>Bio</span>
                            {user.bio || '-'}
                        </p>}
                    {user.status_type && <button className={`border px-4 mt-4 rounded font-medium ${user?.status_type == 'online' ? 'border-green-400 text-green' : user?.status_type == 'offline' ? 'border-red-400 text-red' : user?.status_type == 'working' ? 'border-blue-400 text-blue' : user?.status_type == 'customized' ? 'border-yellow-400 text-yellow' : ''}`}>{user.status_type}</button>}
                </>}
        </div>
    )
}

export default UserCard