import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    makeStyles
} from '@material-ui/core'

const style = makeStyles((theme) => ({
    paper: {
        minWidth: "60%",
        height: '50%',
        position: 'fixed'
    },
    title: {
        margin: '0% 3%'
    },
    titleFont1: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        color: '#00000',
        display: 'inline-block'
    },
    titleFont2: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#AAAAAA',
        display: 'inline-block',
        marginLeft: '15px'
    },
    dividerTop: {
        margin: '0% 5%',
        border: 'solid 1px black'
    },
    imageConatiner: {
        top: '25%',
        margin: '3% 5% 0% 3%',
    },
    imageContent: {
        width: '100%',
        height: '80%',
        objectFit: 'cover'
    },
    actionsBtn: {
        height: '50%',
        margin: '0% 2%'
    },
    defaultBttn: {
        color: "#1D85E8",
        borderRadius: '25px',
        float: 'left',
        '&:hover': {
            backgroundColor: "#1D85E8 ",
            color: "#ffff"
        }
    },
    buttonalignRight: {
        float: 'right',
    },
    cancleBttn: {
        color: "#FE8083",
        borderRadius: '25px',
        width: '100px',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: "#FE8083 ",
            color: "#ffff"
        }
    },
    saveBttn: {
        backgroundColor: "#2DC8A8",
        color: "#ffff",
        borderRadius: '25px',
        width: '170px',
        '&:hover': {
            backgroundColor: "#25A98E ",
        }
    }
}));


function UploadBanner(props) {
    const classes = style()
    return (
        <div>
            <Dialog
                open={props.openBox}
                onClose={props.closeUpload}
                classes={{ paper: classes.paper }}>
                <DialogTitle className={classes.title}>
                    <div className={classes.titleFont1}>
                        Replace Cover Picture
                    </div>
                    <div className={classes.titleFont2}>
                        Optimal Dimensions 256px X 1024px
                    </div>
                </DialogTitle>
                <Divider className={classes.dividerTop} />
                <DialogContent className={classes.imageConatiner}>
                    <img
                        src={props.image}
                        className={classes.imageContent}
                        alt=" "
                    />
                    <div className={classes.progress}>
                        {props.children}
                    </div>
                    <Divider style={{ margin: '1% 0%' }} />
                    <div>
                        <Button
                            className={classes.defaultBttn}
                            onClick={props.default}>
                            Set Default
                        </Button>
                    </div>
                    <div className={classes.buttonalignRight}>
                        <Button
                            className={classes.cancleBttn}
                            onClick={props.closeUpload}>
                            Cancel
                        </Button>
                        <Button
                            className={classes.saveBttn}
                            onClick={(e) => { props.isSaved(e) }}>
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UploadBanner