import React from "react";
import Greyfitti from "../../../src/assets/images/Greyffiti-White-Logo.png";
import { Grid, List, ListItem, ListItemIcon, Typography } from "@material-ui/core";
import { CheckCircle, Info } from "@material-ui/icons";
import useStyles from "./pricingStyle";
import { Link } from "react-router-dom";

function Pricing() {
    const classes = useStyles();

    const rows = [
        {
            name: "GitHub Actions",
            free: "2,000 minutes/month",
            free2: "Free for public repositories",
            team: "3,000 minutes/month",
            team2: "Free for public repositories",
            enterprise: "50,000 minutes/month",
            enterprise2: "Free for public repositories",
        },
        {
            name: "GitHub Packages",
            free: "500 MB",
            free2: "Free for public repositories",
            team: "2 GB",
            team2: "Free for public repositories",
            enterprise: "50 GB",
            enterprise2: "Free for public repositories",
        },
        {
            name: "Code reviews",
            free: <CheckCircle color="primary" />,
            team: <CheckCircle color="primary" />,
            enterprise: <CheckCircle color="primary" />,
        },
        {
            name: "Pull requests",
            free: <CheckCircle color="primary" />,
            team: <CheckCircle color="primary" />,
            enterprise: <CheckCircle color="primary" />,
        },
        {
            name: "Protected branches",
            free: "Public respositories",
            team: <CheckCircle color="primary" />,
            enterprise: <CheckCircle color="primary" />,
        },
        {
            name: "Code owners",
            free: "Public respositories",
            team: <CheckCircle color="primary" />,
            enterprise: <CheckCircle color="primary" />,
        },
        {
            name: "Collaborators for",
            name2: "public repositories",
            free: "Unlimited",
            team: "Unlimited",
            enterprise: "Unlimited",
        },
        {
            name: "Collaborators for",
            name2: "private repositories",
            free: "Unlimited",
            team: "Purchased amount",
            enterprise: "Purchased amount",
        },
        {
            name: "Issues",
            free: <CheckCircle color="primary" />,
            team: <CheckCircle color="primary" />,
            enterprise: <CheckCircle color="primary" />,
        },
    ];
    return (
        <div className={classes.main}>
            <Grid container >
                <Grid item xs={2}>
                    <Link to='/'><img alt="" src={Greyfitti} className='w-12 m-4' /></Link>
                </Grid>
                <Grid item xs={10}>
                    <div className={classes.contentContainer}>
                        <div className={classes.heading1}>
                            <span className={classes.heading}>
                                Choose the plan that’s right for you.
                            </span>
                        </div>
                        <Grid container spacing={1} rowSpacing={4}>
                            <Grid container item spacing={3} className=''>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        <span className={classes.h1}>Free</span>
                                        <div className={classes.space}>
                                            <sup className={classes.dollar}>$</sup><span className={classes.small1}>0 </span>
                                            <span className={classes.small}>per month</span>
                                        </div>
                                        <div className={classes.space}>
                                            {/* <Button color="color"  theme={theme} variant="contained">Join for Free</Button> */}
                                            <button className="py-2 px-5 bg-blue text-white rounded">Join For Free</button>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        <span className={classes.h1}>Team</span>
                                        <div className={classes.space}>
                                            <sup className={classes.dollar}>$</sup> <span className={classes.small1}>15 </span>
                                            <span className={classes.small}>per month</span>
                                        </div>
                                        <div className={classes.space}>
                                            <button className="py-2 px-5 bg-blue text-white rounded">Continue with Team</button>
                                            {/* <Button color="color" theme={theme} variant="contained">Continue with Team</Button> */}
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        <span className={classes.h1}>Enterprise</span>
                                        <div className={classes.space}>
                                            <sup className={classes.dollar}>$</sup><span className={classes.small1}>42 </span>
                                            <span className={classes.small}>per month</span>
                                        </div>
                                        <div className={classes.space}>
                                            <button className="py-2 px-5 bg-blue text-white rounded">Contact Sales</button>
                                            {/* <Button color="color"  theme={theme} variant="contained">Contact Sales</Button> */}
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.mContent}>
                            <Grid container item rowSpacing={4}>
                                {rows.map((row) => (
                                    <Grid container item rowSpacing={6}>
                                        <Grid item xs={3}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Info className={classes.info} />
                                                    <div className={classes.changeRow}>{row.name}<br />{row.name2}</div>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <List>
                                                <div >{row.free}</div>
                                                <div className={classes.color}>{row.free2}</div>
                                            </List>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <List>
                                                <div >{row.team}</div>
                                                <div className={classes.color}>{row.team2}</div>
                                            </List>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <List>
                                                <div>{row.enterprise}</div>
                                                <div className={classes.color}>{row.enterprise2}</div>
                                            </List>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Pricing;
