export const UploadData=[
    {
        "value": 5 ,
        "text": "5 GB"
    }, {
        "value": 10,
        "text": "10 GB"
    }, {
        "value": 15,
        "text": "15 GB"
    }, {
        "value": 20,
        "text": "20 GB"
    },
]