import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({

logo: {
  width: "12vh",
  height:"12vh",
  margin:'2vh',
  float: "left"
  },
  main: {
    backgroundColor:"#2DC8A8",
  },
  heading: {
    fontSize: "25px",
    fontWeight: "bold",
    color:"#092C4C"
  },
  info: {
    width: "15px"
  },
  h1: {
    color: "#1D85E8",
    fontWeight: "bold",
  },
  changeRow:{
    paddingLeft:"7px",
    color:"black"
  },
  space:{
    marginTop:"10px",
  },
  small1:{
    fontWeight: "bold",
    fontSize:"20px",
    color:"#062C51"
  },
  
  color1:{
    color:"#062C51"
  },
  color:{
    color:"#5B5766"

  },
  small:{
    fontSize: "13px",
    color:"#5B5766"
  },
  mContent:{
    height:"calc(120vh - 350px)",
    overflowY: "scroll",
    background: "white",
    paddingTop:"20px"

  },
  contentContainer:{
    marginRight:"160px",
    boxShadow: "0 15px 20px rgba(0, 0, 0, .2)",
    textAlign:"center",
    marginTop: "20px",
    background:"white",
    paddingRight:"20px"
  },
  heading1:{
    paddingTop:"20px",
    paddingBottom:"20px"
  },
  dollar:{
    fontSize:"11px",
    color:"#062C51"
  }
}));

export default useStyles;
