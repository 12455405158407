import React, { useRef, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteRepositoryById, editRepositoryById, getRepositoryList, getReposBasedOnProjectId, getAllFilesList, likeDislikeRepo } from '../../service/service';
import { useClickOutside } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux'
import { repoList } from '../../slices/repoSlice';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { routeList } from '../../slices/routeSlice';
import { selectUserDetails } from '../../slices/userSlice';
import AddRepository from './AddRepository';
import toast from 'react-hot-toast';
import { Popover, Tooltip } from '@material-ui/core';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import Picture from '../../assets/images/picture.png'
import EditIcon from '../../assets/icons/EditIcon';
import moment from 'moment';
import { downloadFolderZip } from '../../utils/downloadFolder';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import { filePreview } from '../../slices/fileSlice';

function RepoList({ repo, project, projectDetail, username, setRepos, retailer }) {

    const history = useHistory()
    const [more, setMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)

    const moreRef = useRef()
    useClickOutside(() => setMore(false), moreRef)

    async function getFreshRepoList() {
        const repoData = Number(project) ? await getReposBasedOnProjectId({ projectId: project }) : await getRepositoryList(username);
        setRepos(repoData.data || [])
        dispatch(repoList(repoData.data || []));
    }

    async function deleteRepo(repoId) {
        await deleteRepositoryById(repoId);
        setDeleteModal(false);
        getFreshRepoList()
    }

    async function editRepo(data) {
        const res = await editRepositoryById(data);
        if (res.data != null) {
            if (data.repository_type == 1) {
                toast.success(`Repository ${data.name} set to public`)
            }
            else if (data.repository_type == 2) {
                toast.success(`Repository ${data.name} set to private`)
            }
            getFreshRepoList()
        }
    }

    const changeRepoType = repo => {
        let id = repo.id
        let name = repo.name
        let description = repo.description
        let repository_type = 1
        if (repo.repository_type === 1) {
            repository_type = 2
        } else {
            repository_type = 1
        }
        let data = { name, description, repository_type, id, project_id: repo.project_id }
        setMore(false)
        editRepo(data)
    }

    const downloadRepo = async () => {
        const res = await getAllFilesList(Number(repo.branchid));
        if (res.data) {
            let folder = { name: repo.name, path: res.data[0].path }
            await downloadFolderZip(res.data, folder, 'repository');
        }
    }

    const navigate = () => {
        dispatch(routeList(`/${username}/${repo.projectname}/${repo.name}/${repo.branchname}`))
        history.push(`/${username}/${repo.projectname}/${repo.name}/${repo.branchname}`)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <tr key={repo.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                <td className='font-medium text-gray-800 cursor-pointer'>
                    <div className='absolute top-0 left-0 w-full h-full bg-transparent cursor-pointer' onClick={navigate}></div>
                    <p className='flex items-center gap-1'>
                        <Tooltip title={repo.name}><span>{repo.name.length > 16 ? `${repo.name.slice(0, 15)}...` : repo.name}</span></Tooltip>
                        {(repo?.thumbnail && repo?.thumbnail != 'undefined') && <img src={Picture} alt='' className='mr-auto cursor-pointer w-5 h-5' style={{ filter: 'drop-shadow(2px 2px 8px rgba(0,0,0,0.3))' }} onClick={() => dispatch(filePreview({ url: repo?.thumbnail, title: repo?.name, open: true }))} />}
                    </p>
                    <p className='text-gray-500 font-light text-xs mt-3'>Added {moment(repo.created_at).fromNow()}</p>
                </td>
                <td>
                    {repo.repository_type === 1
                        ? 'Public'
                        : 'Private'}
                </td>
                <td className='max-w-sm'>
                    <p className='max-w-sm'>{repo.description}</p>
                </td>
                <td>{repo.filesLength}</td>
                <td className='text-center'>{repo.projectname}</td>
                <td className='relative'>
                    {repo.username === user?.username ? <div className='flex gap-4 mx-auto max-w-fit' ref={moreRef} >
                        <EditIcon className='cursor-pointer' onClick={() => setAddModal(true)} />
                        <DeleteIcon className='cursor-pointer' onClick={() => setDeleteModal(true)} />
                        <MoreVertIcon className='cursor-pointer' onClick={handleClick} />
                        <Popover
                            className='mt-2'
                            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className='popup-bg py-1 px-2'>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => repo?.thumbnail && repo?.thumbnail != 'undefined' ? dispatch(filePreview({ url: repo?.thumbnail, title: repo?.name, open: true })) : toast("No preview available", { id: 'preview' })}>Open Preview</h1>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => changeRepoType(repo)}>{repo.repository_type === 1 ? 'Make it Private' : 'Make it Public'}</h1>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => history.push(`/${username}/${repo.projectname}/${repo.name}/${repo.branchid}?open=true`)}>Invite People</h1>
                                {(retailer ? retailer?.status == 'accepted' : true) ? <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={downloadRepo}>Download as zip</h1> : ''}
                            </div>
                        </Popover>
                    </div> :
                        <>
                            <div className='flex gap-4 mx-auto max-w-fit'>
                                {(retailer ? retailer?.status == 'accepted' : true) ? <a className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1 text-center' href={downloadRepo}><DownloadIcon /></a> : ''}
                            </div>
                        </>
                    }
                    <p className='text-gray-500 font-light mt-3 text-xs text-center'>Updated {moment(repo.updated_at).fromNow()}</p>
                </td>
            </tr>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${repo.name}"`} description="This will also delete your all files and folders" type="warn" okText="Yes" onCancel={() => setDeleteModal(false)} onOk={() => deleteRepo(repo.id)} />
            {addModal && <AddRepository getFreshRepositoryList={getFreshRepoList} projectData={projectDetail} setAddModal={setAddModal} isEdit={true} repoData={repo} username={username} />}

        </>
    )
}

export default RepoList