import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { getDecodedToken, getSubscription, getUserPlan } from "./service/service";
import { useEffect, useState } from "react";
import { getIssueList, getProjectsList, getTopicList, userBasicDetail, userDetail } from "./service/service";
import { userBasicInfo, userDetails } from "./slices/userSlice";
import { useDispatch } from "react-redux";
import Loader from "./components/ReusableComponents/Loader";
import { projectList, topicList } from "./slices/projectSlice";
import { issueList } from "./slices/issueSlice";

const PrivateRoute = ({ component: Component, verified, path, ...rest }) => {
  const auth = getDecodedToken();
  const isVerified = auth.isVerified;
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const history = useHistory()

  useEffect(() => getUserBasicInfo(), [])

  async function getUserBasicInfo() {
    const info = await userBasicDetail();
    if (!info.data && window.location.pathname !== '/login' && window.location.pathname !== '/') {
      localStorage.setItem("greyffiti_token", "")
      history.push('/login')
    }
    else {
      dispatch(userBasicInfo(info.data))
    }
    setLoading(false)

    let details = await userDetail();
    if (details.data != null) {
      let userInfo = details.data
      const planData = await getUserPlan();
      userInfo.plan = planData.data
      const subscription = await getSubscription();
      userInfo.subscription = subscription.data

      dispatch(userDetails(userInfo));

      const projectData = await getProjectsList();
      dispatch(projectList(projectData.data || []));

      const issueData = await getIssueList();
      dispatch(issueList(issueData.data || []));

      const topicsData = await getTopicList();
      dispatch(topicList(topicsData.data || []))
    }
  }

  if (loading) return <Loader />

  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? (
          <Redirect to="/login" />
        ) : auth && !isVerified ? (
          <Redirect to="/verifyEmail" />
        ) : auth && !isVerified ? (
          <Redirect to="/thankyou" />
        ) : auth && isVerified ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
