import React from 'react'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-hot-toast';
import { read, utils } from 'xlsx';
import { createMemberLink, getMembers, removeMember, updateMember } from '../../service/service';
import { useEffect } from 'react';
import ConfirmModal from '../ReusableComponents/ConfirmModal';

function MemberAccess({ user }) {
    const [search, setSearch] = useState('')
    const [isMember, setIsMember] = useState(false)
    const [members, setMembers] = useState([])
    const [deleteModal, setDeleteModal] = useState(false);

    const fetchMembers = async () => {
        let res = await getMembers()
        if (res.data?.length) {
            setMembers(res.data)
        }
    }
    useEffect(() => {
        fetchMembers();
    }, [])


    const handleRemoveMember = async (id) => {
        setDeleteModal(false)
        let res = await removeMember({ user_id: id })
        if (res.success) {
            fetchMembers()
            toast.success("User removed successfully!", { id: 'success' })
        }
    }

    const handleUpdateMember = async (id, role) => {
        let res = await updateMember({ user_id: id, role: role })
        if (res.success) {
            toast.success("Role updated successfully!", { id: 'success' })
            fetchMembers()
        }
    }
    return (
        <>
            <section>
                <div className='flex items-center justify-between'>
                    <h1 className="text-2xl font-semibold text-left">Member Access</h1>
                    <button className='bg-blue text-white py-1.5 px-6 rounded-lg outline-none' onClick={() => setIsMember(true)}>Add New Member</button>
                </div>
                <div className='flex items-center justify-between py-3 px-6 rounded-lg shadow-sm bg-cyan-100 mt-4 mb-6 font-medium text-gray-800 tracking-wide'>
                    <h1>{user?.name}</h1>
                    <h1>{user?.email}</h1>
                    <h1>{user?.organization?.created_by == user?.id ? 'Admin' : 'User'}</h1>
                </div>

                <div className='mb-4'>
                    <div className='bg-slate-50 rounded-lg flex-grow px-4 relative group shadow-md max-w-lg'>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className='w-full p-2 outline-none bg-transparent tracking-wide' placeholder='Search for member' />
                    </div>
                </div>
                <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-4 lg:px-8'>
                    <table className='table-auto w-full'>
                        <thead>
                            <tr className='border-b text-gray-400 font-medium'>
                                <td className='p-2'>SNo</td>
                                <td className='p-2'>Name</td>
                                <td className='p-2'>Email</td>
                                <td className='p-2'>Role</td>
                            </tr>
                        </thead>
                        <tbody>
                            {members?.filter(item => item?.name?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) || item?.email?.toLowerCase()?.includes(search?.trim()?.toLowerCase()))?.map((item, i) => <tr key={i}>
                                <td className='p-2'>{i + 1}</td>
                                <td className='p-2'>{item?.name}</td>
                                <td className='p-2'>{item?.email}</td>
                                <td className='p-2 capitalize'>
                                    <select name="" id="" className='bg-transparent outline-none' value={item?.role} onChange={(e) => handleUpdateMember(item.id, e.target.value)}>
                                        <option value="user">User</option>
                                        <option value="subadmin">Subadmin</option>
                                    </select>
                                </td>
                                <td className='p-2 max-w-[100px]'><button onClick={() => setDeleteModal(item)} className='text-red'>Remove</button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </section>
            {isMember ? <AddMember user={user} onClose={() => setIsMember(false)} /> : <></>}
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to remove user ${deleteModal.name}`} description={`You have to invite again to add in your members list`} type="warn" okText='Yes' onCancel={() => setDeleteModal(false)} onOk={(e) => handleRemoveMember(deleteModal.id)} />

        </>
    )
}

export default MemberAccess


const AddMember = ({ user, onClose }) => {

    const [users, setUsers] = useState([])

    const [data, setData] = useState({
        email: '',
        role: 'user'
    })

    let organization_invite = {
        id: '',
        link_id: '',
        email: '',
        role: '',
        orgId: '',
        status: 'pending',
        timestamp: 'auto remove after 1week'
    }

    const handleFileDrop = (file) => {

        // Check file extension
        const validExtensions = ['.xlsx', '.xls'];
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
            toast.error('Invalid file format. Please upload an Excel file.');
            return;
        }

        // Check file MIME type
        const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (!validMimeTypes.includes(file.type)) {
            toast.error('Invalid file format. Please upload an Excel file.');
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;
            const workbook = read(contents, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const excelData = utils.sheet_to_json(worksheet, { header: 1 });

            let headings = excelData[0];
            let emailIndex = headings?.findIndex(item => item?.toLowerCase() == 'email')
            let roleIndex = headings?.findIndex(item => item?.toLowerCase() == 'role');

            let userArr = []
            for (let i = 1; i < excelData?.length; i++) {
                userArr.push({
                    index: i,
                    email: excelData[i][emailIndex]?.toLowerCase(),
                    role: excelData[i][roleIndex]?.toLowerCase()
                })
            }
            console.log(userArr)
            setUsers(userArr)
        };

        reader.readAsBinaryString(file);
    };

    const sendInvite = async (e) => {
        e?.preventDefault();
        if (users?.length || data.email) {
            let arr = [...users]
            if (data.email) {
                arr.push({ email: data.email, role: data?.role })
            }

            let res = await createMemberLink({ organization_id: user?.organization?.id, members: arr })
            console.log("INVITE RES ", res)
            if (res.success) {
                alert(res?.data[0]?.link)
                toast.success("Users Invited");
                setUsers([])
                setData({ email: '', role: 'user' })
                onClose();
            }
        }
        else {
            toast.error("No Member is added")
        }
    }

    return (
        <>

            <div className='fixed inset-0 bg-transparent z-[100] grid place-items-center'>
                <div className='fixed inset-0 bg-black bg-opacity-70 cursor-pointer' onClick={onClose}>
                </div>

                <div className='p-10 bg-white z-10 rounded-lg w-full max-w-3xl min-h-[60vh]'>
                    <h1 className='text-2xl font-medium'>Add New Member</h1>

                    <div className='grid grid-cols-2 gap-6 min-h-[18rem]'>

                        <div className=''>
                            <div className='mt-4'>
                                <h1>Email Address</h1>
                                <input type="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} placeholder='Enter email address' className='p-2 bg-slate-100 rounded-lg outline-none w-full max-w-xs' />
                            </div>
                            <div className='mt-4'>
                                <h1>Choose Role</h1>
                                <select value={data.role} onChange={(e) => setData({ ...data, role: e.target.value })} className='p-2 bg-slate-100 rounded-lg outline-none w-full max-w-xs' >
                                    <option value="user">User</option>
                                    <option value="subadmin">Subadmin</option>
                                </select>
                            </div>
                        </div>

                        <div className='border-l border-gray-400 border-dashed p-8'>
                            <FileUploader
                                multiple={false}
                                handleChange={handleFileDrop}
                                name="file"
                                children={
                                    <div className='bg-slate-100 rounded-lg flex flex-col items-center justify-center h-60 relative z-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 m-3">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <h1>Multiple members?</h1>
                                        <h1 className='my-1 text-sm'>Upload excel sheet with email and role</h1>
                                        <p className='text-sm text-gray-600'>Roles format: Subadmin/User</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='pt-6 flex items-center justify-center'>
                        <button onClick={sendInvite} disabled={!users?.length && !data.email} className='text-white bg-blue rounded-lg disabled:bg-gray-400 py-2 px-10'>Send Invite</button>
                    </div>
                </div>
            </div>
        </>
    )
} 