import React, { useRef, useState } from 'react'
import { removeContributors, transferOwnerShip } from '../../service/service'
import { useClickOutside } from '../../utils/helper'
import UserBordered from '../../assets/icons/UserBordered';
import WarningIcon from '../../assets/icons/WarningIcon';
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { toast } from 'react-hot-toast';
import { accessRights } from '../../utils/constant';

const Default_State = {
    open: false,
    user: null
}

function TransferOwnership({ contributors, setIsTransfer, type, id }) {

    const [confirmModel, setConfirmModel] = useState(Default_State)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false);
    const cancelRef = useRef()
    useClickOutside(() => setIsTransfer(false), cancelRef)

    const userRow = {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20,
        marginBottom: 20
    }

    const handleTransferBtn = async (user) => {
        const data = {
            type: type,
            contributor_id: user.contributer_id,
            project_id: type == 'project' ? id : null,
            repo_id: type == 'repo' ? id : null,
        }
        let i = contributors.findIndex(item => item.contributer_id == user.contributer_id);

        if (i > -1) {
            let tId = toast.loading("Transferring Ownership")
            setConfirmModel(Default_State)
            setLoading(true)
            const res = await transferOwnerShip(data)
            setLoading(false)
            if (res.success) {
                toast.dismiss(tId);
                toast.success("Ownership transferred ")
                window.location.reload();
            }
            else {
                toast.dismiss(tId);
                toast.error("Unable to Transfer!")
            }
        }
    }
    const UserRow = ({ user }) => {
        let access = accessRights?.find(item => item?.id == user.access)
        return <div style={userRow}>
            {
                user.profile_pic ?
                    <img className='w-10 h-10 rounded-full' src={user.profile_pic} alt='' />
                    : <UserBordered className='w-10 h-10 rounded-full' />
            }
            <div style={{ flexGrow: 1 }}>
                <p className='text-lg font-semibold text-gray-800'>{user.username} <span className='text-gray-400 font-normal text-base'>({access?.name})</span></p>
                <p className='text-gray-500'>{user.name}</p>
            </div>
            <button disabled={loading} className='bg-[#FFC48D] disabled:bg-gray-500 rounded-lg px-5 py-1.5 font-medium text-white capitalize' onClick={() => setConfirmModel({ open: true, user: user })}>
                Transfer
            </button>
        </div>
    }

    return (
        <>
            <div className='fixed inset-0 bg-transparent bg-opacity-50 grid place-items-center z-[100]'>
                <div onClick={() => setIsTransfer(false)} className='fixed inset-0 bg-black bg-opacity-50 grid place-items-center'>
                </div>
                <div className='bg-white w-full max-w-xl min-h-[600px] rounded-lg flex flex-col p-7 z-10'>
                    <h1 className='text-3xl text-red-500 font-medium mb-2'>Change Ownership</h1>
                    <p className='text-rose-400'>This will no longer be considered as “your” project.</p>
                    <p className='text-rose-400'>You will be made as a contributor of this project</p>
                    <p className='text-rose-400'>Members with View only access will be removed from the project.</p>
                    <TextField style={{ marginTop: 20, marginBottom: 10 }} size="small" fullWidth placeholder="search by username or email" id="fullWidth" variant="outlined"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }} />
                    <div className='w-full p-2 flex-grow h-[50dvh] overflow-y-auto'>
                        {contributors?.filter(item => item?.user?.toLowerCase().includes(search?.toLowerCase()) || item?.username?.toLowerCase()?.includes(search?.toLowerCase())).length > 0 ?
                            contributors?.filter(item => item?.user?.toLowerCase().includes(search?.toLowerCase()) || item?.username?.toLowerCase()?.includes(search?.toLowerCase())).map(user => <UserRow key={user.contributer_id} user={user} />)
                            :
                            <p className='text-center text-2xl font-semibold text-gray-500 my-6'>No Contributor available</p>
                        }
                    </div>

                    {confirmModel.open && <div className='fixed top-0 left-0 w-full h-full overflow-hidden bg-black bg-opacity-75 grid place-items-center' style={{ zIndex: 1000 }}>
                        <div className='flex flex-col items-center justify-center p-5 md:px-10 bg-white w-full max-w-2xl rounded-lg min-h-[600px]'>
                            <svg className='mt-10' width="200" height="200" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="75" cy="75" r="75" fill="#FFC48D" />
                                <rect x="17" y="68" width="116" height="13" rx="6.5" fill="#FF6666" />
                            </svg>

                            <h1 className='text-3xl font-medium my-6 text-center'>Are you sure you want to transfer the {type}’s ownership to</h1>
                            <div className='flex items-center gap-2'>
                                {
                                    confirmModel?.user.profile_pic ?
                                        <img className='w-16 h-16 rounded-full' src={confirmModel?.user.profile_pic} alt='' />
                                        : <UserBordered className='w-16 h-16 rounded-full' />
                                }
                                <h1 className='text-xl font-medium text-blue tracking-wide'>{confirmModel?.user?.username}</h1>
                            </div>
                            <div className='flex justify-around w-full mb-6 mt-auto'>
                                <button className='bg-[#2DC8A8] rounded-lg px-8 py-2 text-lg text-white capitalize font-medium' onClick={() => setConfirmModel(Default_State)}>
                                    Cancel
                                </button>
                                <button className='bg-[#FFC48D] rounded-lg px-8 py-2 text-lg text-white capitalize font-medium' onClick={() => handleTransferBtn(confirmModel.user)}>
                                    Yes, Transfer
                                </button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>

        </>
    )
}

export default TransferOwnership