import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CloseOutlined, CloudDownloadOutlined, CloudUploadOutlined, ExpandLess, ExpandMore, PlayArrow, SearchOutlined } from '@material-ui/icons';
import { fileList } from '../../slices/fileSlice';
import { addNewPreview, addNewPreviewFolder, getAllFilesList, getAllFilesListByUserId, getBranch, getBranchList, getIssueListByRepo, getRepoByName, getRepoContributors, getRepositoryList, getRetailerInvite, getUserByUsername, listLinks, listTagsByRepo, updateRetailerInvite, uploadFile } from '../../service/service';
import FileList from '../Files/FileList';
import { routeList } from '../../slices/routeSlice';
import { repoContributors, repoList } from '../../slices/repoSlice';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import RemoveContributor from '../ReusableComponents/RemoveContributor';
import AddContributor from '../ReusableComponents/AddContributor';
import InviteViaEmail from '../ReusableComponents/InviteViaEmail';
import IssueList from '../Issue/IssueList';
import BranchIcon from '../../assets/icons/BranchIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useRef } from 'react';
import { fetchFoldersFromJson, useClickOutside } from '../../utils/helper';
import AddBranch from '../Branch/AddBranch';
import FilterIcon from '../ReusableComponents/FilterIcon';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import { FileUploader } from 'react-drag-drop-files';
import toast from 'react-hot-toast';
import UserImage from '../../assets/images/Default_User_Image.png';
import ContributorHover from '../ReusableComponents/ContributorHover';
import Upload from '../Files/Upload';
import { downloadFolderZip } from '../../utils/downloadFolder'
import ContributorList from '../ReusableComponents/ContibutorList';
import MiniLoader from '../ReusableComponents/MiniLoader';
import Tag from '../ReusableComponents/Tag';
import AddLink from '../Files/AddLink';
import LinkList from '../Files/LinkList';
import TransferOwnership from '../ReusableComponents/Transfer';
import InviteRetailer from '../Retailer/InviteRetailer';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Files() {
    let query = useQuery();
    const user = useSelector(selectUserDetails);
    const [userData, setUserData] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch()
    const { branchInfo, repo, project, username } = useParams()
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([])
    const [repos, setRepos] = useState([])
    const [branches, setBranches] = useState([])
    const [repoDetail, setRepoDetail] = useState(false)
    const [contributors, setContributors] = useState([]);
    const [removeCreator, setRemoveCreator] = useState(false);
    const [openPopup, setOpenPopUp] = useState(false)
    const [emailInvite, setEmailInvite] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [state, setState] = useState(query.get('issue') ? 'issue' : 'file');
    const [issues, setIssues] = useState([]);
    const [branchPopUp, setBranchPopUp] = useState(false)
    const [currentBranch, setCurrentBranch] = useState({})
    const [addModal, setAddModal] = useState(false);
    const [issueSearch, setIssueSearch] = useState('')
    const [fileSearch, setFileSearch] = useState('')
    const [isSort, setIsSort] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [availableFileType, setAvailableFileType] = useState([])
    const [filterOption, setFilterOption] = useState({ createdByOthers: false, createdByMe: false, fileType: [] })
    const [isAsc, setIsAsc] = useState(true);
    const [isRoot, setIsRoot] = useState(true);
    const [fileData, setFileData] = useState(false)
    const [isAccess, setIsAccess] = useState(false);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [openIssue, setOpenIssue] = useState(false);
    const [closedIssue, setClosedIssue] = useState(false);
    const [URL, setURL] = useState('');
    const [isUpload, setIsUpload] = useState(false);
    const [downloadFolderName, setDownloadFolderName] = useState('')
    const [isViewContributor, setIsViewContributor] = useState(false);
    const [lastFolder, setLastFolder] = useState([]);
    const [canInvite, setCanInvite] = useState(0);
    const [branchId, setBranchId] = useState()
    const [folders, setFolders] = useState([])
    const [tagList, setTagList] = useState([])
    const [isLink, setIsLink] = useState(false)
    const [links, setLinks] = useState([])
    const [isTransfer, setIsTransfer] = useState(false);
    const [accessRight, setAccessRight] = useState()
    const [isRetailer, setIsRetailer] = useState(false)
    const [retailer, setRetailer] = useState()
    const [retailerLoading, setRetailerLoading] = useState(true);
    const [retailerReqLoading, setRetailerReqLoading] = useState(false)

    const cancelRef = useRef()
    const moreRef = useRef();
    const filterRef = useRef();
    const accordionRef = useRef();

    useClickOutside(() => { isSort ? setIsSort(false) : isFilter ? setIsFilter(false) : setBranchPopUp(false) }, isSort ? moreRef : isFilter ? filterRef : cancelRef)


    const fetchRetailer = async () => {
        let res = await getRetailerInvite()
        let idx = res.data?.findIndex(item => item?.repo_id == repoDetail?.id)
        if (idx > -1) {
            setRetailer(res.data[idx])
        }
        else {
            setRetailer(null);
        }
        setRetailerLoading(false)
    }

    useEffect(() => {
        if (userData && user && repoDetail) {
            fetchRetailer()
        }
    }, [userData, user, repoDetail])

    useEffect(() => {
        if (retailer && branches?.length) {
            let bh = branches.find(item => item.id == retailer?.branch_id)
            if (bh.name !== currentBranch?.name) {
                history.push(`/${username}/${project}/${repo}/${bh?.name}`)
            }
            setCurrentBranch(bh)

        }
    }, [retailer, branches])

    useEffect(() => {
        if (issues?.length) {
            let open = 0;
            let close = 0;
            issues?.forEach(item => {
                if (item.issue_status === "Open")
                    open += 1;

                if (item.issue_status === "Closed")
                    close += 1;
            });

            setClosedCount(close);
            setOpenCount(open)
        }
    }, [issues])

    useEffect(() => {
        if (contributors) {
            dispatch(repoContributors(contributors))
        }
    }, [contributors])

    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            setUserData(res.data)
        }
        else {
            history.push("/404")
        }
    }

    useEffect(() => {
        if (repoDetail && username) {
            let is = repoDetail?.contributorList?.filter(item => item.username == user?.username);
            // consoles to take time to get user
            console.log("checking repo access...")
            console.log("got access criteria...")
            console.log("making decision...");
            console.log("process finished...");
            let access = Boolean(repoDetail?.username === user?.username || Boolean(is?.length))
            if (is?.length) {
                setAccessRight(is[0]?.access)
            }
            setIsAccess(Boolean(access))
            if (!access && repoDetail?.repository_type == 2) {
                if (!retailerLoading && typeof (retailer) !== 'undefined') {
                    if (retailer) {
                    } else
                        history.push('/404')
                }
            }
        }
    }, [repoDetail, user, username, contributors, retailerLoading])

    useEffect(() => {
        if (username) {
            getUser();
        }
    }, [username])

    function getFolderDetailsFunction(folderList, last) {
        for (let i = 0; i < folderList?.length; i++) {
            if (folderList[i].type == 'folder') {
                if (folderList[i]?.folder_id == last) {
                    setFolderList(folderList[i].children);
                    break;
                }
                else if (folderList[i].children)
                    getFolderDetailsFunction(folderList[i].children, last);

            }
        }
    }

    const backFolder = () => {
        if (lastFolder.length > 1) {
            let arr = [...lastFolder]
            arr.pop();
            let last = arr[arr.length - 1];
            getFolderDetailsFunction(files, last)
            setLastFolder(arr)
        }
        else {
            setFolderList(files)
            setLastFolder([])
        }
    }

    const fetchBranchList = async () => {
        const res = await getBranchList({ repo: repoDetail?.id });
        if (res.data) {
            setBranches(res.data)
            // dispatch(repoBranches(res.data));
        }
    }

    async function fetchFolders() {
        let flds = await fetchFoldersFromJson(JSON.stringify(files, 'web'));
        if (flds.length) {
            setFolders(flds)
        }
    }

    useEffect(() => {
        if (files.length) {
            fetchFolders();
        }
    }, [files])

    useEffect(() => {
        if (repoDetail?.id) {
            fetchBranchList()
        }
    }, [repoDetail?.id])

    const getFiles = async () => {
        const res = await getAllFilesList(Number(branchId));
        if (res.data?.length) {
            if (res.data?.length > 0) {
                setDownloadFolderName({ name: repoDetail?.name, path: res.data[0].path })
                setFolderList(res.data)
                setFiles(res.data)
                dispatch(fileList(res.data));
                setIsRoot(true)
                setLastFolder([])
            }
            else if (res.data[0]?.children) {
                setDownloadFolderName({ name: res.data[0].name, path: res.data[0].path })
                setFolderList(res.data[0]?.children)
                setFiles(res.data[0]?.children)
                dispatch(fileList(res.data));
                setIsRoot(true)
                setLastFolder([])
            }
        } else {
            setDownloadFolderName()
            setFolderList([])
            setFiles([])
            dispatch(fileList([]));
            setIsRoot(false)
        }
        let { data: file } = await getAllFilesListByUserId(userData?.id);
        let newFile = file?.filter(item => item.branch_id == branchId && item.repo_id == repoDetail?.id)
        let formats = newFile?.map(item => `${item.file_format}`)
        // setAvailableFileType([...new Set([...formats])])
        // setFilterOption({ ...filterOption, fileType: [...new Set([...formats])] })
    }

    useEffect(() => {
        if (folderList) {
            let formats = folderList?.map(item => `${item.fileFormat}`)
            setAvailableFileType([...new Set([...formats])])
        }
    }, [folderList])

    const setBranch = () => {
        let idx = branches.findIndex(item => item.id == branchId);
        if (idx > -1) {
            setCurrentBranch(branches[idx])
        }
    }
    useEffect(() => {
        if (userData) {
            if (branchId)
                getFiles()
            if (branchId && branches) {
                setBranch()
            }
        }
    }, [branchId, branches, userData, currentBranch])

    useEffect(() => {
        if (currentBranch?.repo_id && currentBranch?.id) {
            getLinks()
        }
    }, [currentBranch])

    const getContributors = async () => {
        const res = await getRepoContributors(repoDetail.id);
        if (res.success) {
            setContributors(res.data.filter(item => item.username != repoDetail.username))
        }
        else
            setContributors([])
        setLoading(false)
    }

    useEffect(() => {
        if (repoDetail?.id) {
            getContributors()
        }
    }, [repoDetail])

    useEffect(() => {
        if (contributors.length < user?.plan.limit.repo_contributors)
            setCanInvite(user?.plan.limit.repo_contributors - contributors.length)

    }, [contributors])

    const getAllRepos = async () => {
        try {
            const res = await getRepositoryList(username);
            if (res.data) {
                setRepos(res.data);
                dispatch(repoList(res.data))
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getRepoDetails = async () => {
        try {
            const res = await getRepoByName({ project, repo });
            if (res.data) {
                setRepoDetail(res.data);
                setOpenPopUp(query?.get('open') == 'true')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBranchInfo = async () => {
        let res = await getBranch(project, repo, branchInfo);
        if (res.success) {
            setBranchId(res.data.id)
        }
    }
    useEffect(() => {
        if (branchInfo) {
            getBranchInfo();
        }
    }, [branchInfo]);

    useEffect(() => {
        if (branchId && userData) {
            getRepoDetails();
            getAllRepos()
            getFiles();
        }
    }, [userData, branchId])

    const getLinks = async () => {
        let res = await listLinks(currentBranch.repo_id, currentBranch.id)
        if (res.success && Array.isArray(res.data))
            setLinks(res.data)
    }

    const getIssues = async () => {
        const res = await getIssueListByRepo(repoDetail.id);
        if (res.success) {
            setIssues(res.data);
            setState('issue')
        }
    }

    const changeState = async () => {
        setIsSort(false);
        getIssues();
    }

    useEffect(() => {
        if (query.get('issue') && repoDetail) {
            getIssues();
        }
        if (repoDetail) {
            getTagsListByProject();
        }
    }, [repoDetail])

    const getTagsListByProject = async () => {
        const res = await listTagsByRepo(repoDetail?.id)
        if (res.data)
            setTagList(res.data);
    }


    const sortFileByName = () => {
        if (issues) {
            let arr = [...folderList]
            let sorted = arr.sort((a, b) => b.name.localeCompare(a.name));
            setFolderList(sorted)
            setIsSort(false);
        }
    }
    const sortFileByCreated = () => {
        if (issues) {
            let arr = [...folderList]
            let sorted = arr.sort((a, b) => (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
            setFolderList(sorted)
            setIsSort(false);
        }
    }
    const sortFileByUpdated = () => {
        if (issues) {
            let arr = [...folderList]
            let sorted = arr.sort((a, b) => (new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()));
            setFolderList(sorted)
            setIsSort(false);
        }
    }
    const sortIssueByName = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => a.title.localeCompare(b.title));
            setIssues(sorted)
            setIsSort(false);
        }
    }
    const sortIssueByCreated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            setIssues(sorted)
            setIsSort(false);
        }
    }

    const sortIssueByUpdated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
            setIssues(sorted)
            setIsSort(false);
        }
    }

    const handleFileOrder = () => {
        let arr = [...folderList];
        arr.reverse();
        setFolderList(arr)
        setIsAsc(!isAsc)
    }
    const handleIssueOrder = () => {
        let arr = [...issues];
        arr.reverse();
        setIssues(arr)
        setIsAsc(!isAsc)
    }

    const navigateProject = () => {
        if (retailer) {
            window.location.reload();
        }
        else {
            dispatch(routeList(`/${username}/${project}`))
            history.push(`/${username}/${project}`)
        }
    }
    const navigateRepo = () => {
        setIsSort(false)
        setState('file');
        getFiles();
        setIsRoot(true);
        setLastFolder([])
        dispatch(routeList(`/${username}/${project}/${repo}/${branchInfo}`))
        history.push(`/${username}/${project}/${repo}/${branchInfo}`)
    }

    const handleFileType = (item) => {
        let newOption = [...filterOption.fileType]
        let idx = newOption.findIndex(data => data == item);
        if (idx > -1) {
            newOption.splice(idx, 1)
        }
        else {
            newOption.push(item)
        }
        setFilterOption({ ...filterOption, fileType: [...newOption] })
    }

    const uploadImage = (file) => {
        let formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await uploadFile(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }

    const savePreview = async () => {
        if (!URL) return;
        try {
            const formData = new FormData();
            if (fileData?.type === "folder") {
                formData.append('repo_id', fileData?.repo_id)
                formData.append('branch_id', fileData?.branch_id)
                formData.append('type', fileData?.type)
                formData.append('dir_path', fileData?.path)
                formData.append('commitLink', URL);
                await addNewPreviewFolder(formData)
            } else {
                formData.append('commitLink', URL)
                await addNewPreview(fileData?.file_id, formData)
            }
            setURL('')
            getFiles();
            setFileData(false)
        }
        catch (e) {
            console.log(e)
        }
    }
    const handleImage = async (file) => {
        if (file) {
            const res = await uploadImage(file);

            if (res.data) {
                try {
                    const formData = new FormData();
                    if (fileData?.type === "folder") {
                        formData.append('repo_id', fileData?.repo_id)
                        formData.append('branch_id', fileData?.branch_id)
                        formData.append('type', fileData?.type)
                        formData.append('dir_path', fileData?.path)
                        formData.append('previewUrl', res.data.url)
                        await addNewPreviewFolder(formData)
                    } else {
                        formData.append('previewUrl', res.data.url)
                        await addNewPreview(fileData?.file_id, formData)
                    }
                    getFiles();
                    setFileData(false)
                }
                catch (e) {
                    console.log(e)
                    throw new Error(e)
                }
            }
        }
    }

    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData, ...user }} className="lg:max-w-sm hidden md:inline-flex">
                            <>
                                {repoDetail &&
                                    <div className='px-10 py-5 w-full flex flex-col min-h-[85dvh]'>
                                        <div className='flex items-center gap-2 flex-wrap text-gray-500'>
                                            <span className='cursor-pointer font-semibold text-gray-900 hover:text-blue-500' onClick={() => retailer ? {} : history?.push(`/${username}/`)}>{retailer ? 'Retailer' : 'Project'} {" >"}</span>
                                            <span className='cursor-pointer hover:text-blue-500' onClick={navigateProject}>{project} {" >"}</span>
                                            <span className='text-gray-800 font-medium cursor-pointer hover:text-blue-500' onClick={navigateRepo}>{repo}</span>
                                        </div>
                                        <div className='flex items-center justify-between w-full mt-6'>
                                            <h1 className='text-2xl xl:text-3xl font-semibold'>{repoDetail?.name}</h1>
                                            <span className='bg-blue text-white px-5 py-1 rounded-full font-medium'>{repoDetail.repository_type === 1 ? 'Public' : 'Private'}</span>
                                        </div>
                                        <h1 className='text-lg lg:text-xl font-semibold text-blue my-4'>{project}</h1>

                                        <div className='relative flex items-center gap-4' ref={cancelRef}>
                                            <button className='bg-blue text-white flex items-center gap-2 px-2 rounded-md' onClick={() => setBranchPopUp(!branchPopUp)}>
                                                <BranchIcon />
                                                <p className=''>{currentBranch?.name}</p>
                                                <ArrowDropDownIcon fontSize='large' className={`${retailer ? 'opacity-0' : ''}`} />
                                            </button>
                                            {
                                                retailer ?
                                                    ''
                                                    :
                                                    branchPopUp && <div className='text-gray-500 font-medium absolute top-12 left-3 popup-bg rounded-lg py-3 px-5 shadow-lg border min-w-[120px]'>
                                                        <div className='absolute transform -top-2 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                        {branches.map(branch => <p key={branch.id} className={`cursor-pointer hover:text-blue-500 text-lg pb-1 ${branchId == branch.id ? 'text-gray-800' : ''}`} onClick={() => { setBranchPopUp(false); history.push(`/${username}/${project}/${repo}/${branch?.name}`); setCurrentBranch(branch); }}>{branch?.name}</p>)}
                                                        {repoDetail?.username == user?.username && <div className='flex items-center justify-between gap-4 text-blue text-sm mt-4'>
                                                            <p className='cursor-pointer' onClick={() => history.push(`/${username}/${project}/${repo}/branches/${repoDetail?.id}`)}>View</p>
                                                            <p className='cursor-pointer' onClick={() => setAddModal(true)}>Create Branch</p>
                                                        </div>}
                                                    </div>
                                            }
                                            {
                                                retailer ? '' :
                                                    <button className='flex items-center opacity-60 cursor-pointer min-w-max' onClick={() => history.push(`/${username}/${project}/${repo}/branches/${repoDetail?.id}`)}>
                                                        <BranchIcon className="filter invert transform scale-75" />
                                                        <p className='font-medium'>{branches.length > 1 ? `${branches.length} branches` : `1 branch`}</p>
                                                    </button>
                                            }
                                        </div>
                                        <p className='text-gray-500 mt-8 h-[15vh] overflow-y-hidden '>{repoDetail.description || `This is the description text for the Repository ${repo}`}</p>
                                        <div className='flex items-center mt-8'>
                                            <h3 className='text-xl font-semibold text-gray-800'>Contributors {contributors?.length > 0 && `(${contributors?.length + 1}/${user?.plan?.limit?.repo_contributors || 0})`}</h3>
                                        </div>
                                        <div className='my-3 flex items-center flex-wrap'>
                                            {contributors.length ? <div className='relative group'>
                                                <ContributorHover data={{ name: repoDetail?.user_name, username: repoDetail?.username, profile_pic: repoDetail?.profile_pic }} />
                                                <img alt='' className='w-10 h-10 rounded-full my-1 mx-2 border' src={repoDetail?.profile_pic || UserImage} />
                                            </div> : '😓😓😓'}
                                            {
                                                contributors?.map((contributor, i) =>
                                                    <div className='relative group' key={i}>
                                                        <ContributorHover data={contributor} name={contributor?.contributor_name} />
                                                        <img alt='' className='w-10 h-10 rounded-full my-1 mx-2 border' src={contributor.profile_pic || UserImage} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {repoDetail?.username == user?.username ?
                                            <>
                                                <p className='text-blue cursor-pointer'
                                                    onClick={() => setOpenPopUp(true)}
                                                >Manage Contributors</p>
                                                <p className='text-red cursor-pointer'
                                                    onClick={() => setIsTransfer(true)}
                                                >Transfer Ownership</p>
                                            </>
                                            :
                                            <p className='text-blue cursor-pointer'
                                                onClick={() => setIsViewContributor(true)}
                                            >View Contributors</p>
                                        }

                                        {(repoDetail?.username === user?.username && (user?.plan?.name?.includes('free') || user?.plan?.name?.includes('pro'))) ?
                                            <div className='flex-grow flex flex-col justify-end'>
                                                <button className='py-2 px-8 rounded-lg bg-blue text-white mt-4 flex items-center gap-4 max-w-max' onClick={() => setIsRetailer(true)}>
                                                    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.5 24C16.5278 24 15.7014 23.65 15.0208 22.95C14.3403 22.25 14 21.4 14 20.4C14 20.26 14.0097 20.1148 14.0292 19.9644C14.0486 19.8148 14.0778 19.68 14.1167 19.56L5.89167 14.64C5.56111 14.94 5.19167 15.1748 4.78333 15.3444C4.375 15.5148 3.94722 15.6 3.5 15.6C2.52778 15.6 1.70139 15.25 1.02083 14.55C0.340278 13.85 0 13 0 12C0 11 0.340278 10.15 1.02083 9.45C1.70139 8.75 2.52778 8.4 3.5 8.4C3.94722 8.4 4.375 8.4848 4.78333 8.6544C5.19167 8.8248 5.56111 9.06 5.89167 9.36L14.1167 4.44C14.0778 4.32 14.0486 4.1852 14.0292 4.0356C14.0097 3.8852 14 3.74 14 3.6C14 2.6 14.3403 1.75 15.0208 1.05C15.7014 0.35 16.5278 0 17.5 0C18.4722 0 19.2986 0.35 19.9792 1.05C20.6597 1.75 21 2.6 21 3.6C21 4.6 20.6597 5.45 19.9792 6.15C19.2986 6.85 18.4722 7.2 17.5 7.2C17.0528 7.2 16.625 7.1148 16.2167 6.9444C15.8083 6.7748 15.4389 6.54 15.1083 6.24L6.88333 11.16C6.92222 11.28 6.95139 11.4148 6.97083 11.5644C6.99028 11.7148 7 11.86 7 12C7 12.14 6.99028 12.2848 6.97083 12.4344C6.95139 12.5848 6.92222 12.72 6.88333 12.84L15.1083 17.76C15.4389 17.46 15.8083 17.2248 16.2167 17.0544C16.625 16.8848 17.0528 16.8 17.5 16.8C18.4722 16.8 19.2986 17.15 19.9792 17.85C20.6597 18.55 21 19.4 21 20.4C21 21.4 20.6597 22.25 19.9792 22.95C19.2986 23.65 18.4722 24 17.5 24Z" fill="white" />
                                                    </svg>

                                                    <span>Share with Retailer</span>
                                                </button>
                                            </div>
                                            : retailer?.status == 'pending' ?
                                                retailerReqLoading ?
                                                    <>
                                                        <div className='border-4  border-blue-500 border-b-white w-8 h-8 m-2 rounded-full animate-spin'>

                                                        </div>
                                                    </>
                                                    :
                                                    <div className='flex-grow flex flex-col justify-end'>
                                                        <h1>Going Ahead with Order?</h1>
                                                        <div className='w-full flex items-center gap-4 mt-2'>
                                                            <button className='py-1.5 px-6 rounded-lg bg-blue text-white' onClick={async () => {
                                                                setRetailerReqLoading(true);
                                                                let res = await updateRetailerInvite({ id: retailer?.id, status: 'accepted' })
                                                                if (res.data.id) {
                                                                    toast.success('Invite Accepted');
                                                                    window.location.reload();
                                                                }
                                                            }}>Accept</button>
                                                            <button className='py-1.5 px-6 rounded-lg bg-red text-white' onClick={async () => {
                                                                setRetailerReqLoading(true)
                                                                let res = await updateRetailerInvite({ id: retailer?.id, status: 'rejected' })
                                                                if (res.data.id) {
                                                                    toast.success('Invite Rejected');
                                                                    window.location.reload();
                                                                }
                                                            }}>Reject</button>
                                                        </div>
                                                    </div>
                                                : ''}
                                    </div>
                                }
                            </>
                        </UserCard>


                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className={`text-lg font-medium cursor-pointer leading-loose ${state === 'file' ? 'text-navy border-b-2 border-blue-500' : 'text-gray-500'}`} onClick={navigateRepo}>Files {folderList?.length > 0 && repoDetail ? `(${folderList.length})` : ''}</h1>
                                {isAccess && <><h1 className={`text-lg font-medium cursor-pointer leading-loose  ${state === 'issue' ? 'text-yellow border-b-2 border-yellow-400' : 'text-yellow'}`} onClick={changeState}>Issues {issues?.length > 0 && repoDetail ? `(${issues.length})` : ''}</h1>
                                    <h1 className='text-lg font-medium text-green cursor-pointer leading-loose' onClick={() => repoDetail?.project_owner == user?.id ? history?.push(`/${username}/${repoDetail.projectname}/tags`) : setState('tag')}>Tags</h1>
                                </>}

                            </div>
                            {state === 'file' || !state ?
                                <div className='w-full'>

                                    <div className='flex flex-wrap xl:flex-nowrap items-center gap-4 justify-between my-4'>
                                        <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full relative' ref={filterRef}>
                                            <SearchOutlined />
                                            <input type="text" onChange={(e) => setFileSearch(e.target.value)} placeholder='Search file here...' className=" flex-grow outline-none border-none px-2" />
                                            <FilterIcon onClick={() => setIsFilter(!isFilter)} />
                                            {isFilter && <div className='absolute z-30 w-full top-14 left-0 popup-bg shadow-lg rounded-lg pt-2 pb-4 px-4 border font-medium text-gray-800'>
                                                <div className='absolute transform -top-2 right-3 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                <li className='text-right list-none hover:text-blue-500 cursor-pointer' onClick={() => setFilterOption({ createdByOthers: false, createdByMe: false, fileType: [] })}>Reset Filters</li>
                                                <FormGroup>
                                                    <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByMe} onChange={e => setFilterOption({ ...filterOption, createdByMe: !filterOption.createdByMe })} />} label={<h1 className={`font-medium text-gray-800 ${filterOption.createdByMe ? 'text-blue' : ''}`}>Created By You</h1>} />
                                                    <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByOthers} onChange={e => setFilterOption({ ...filterOption, createdByOthers: !filterOption.createdByOthers })} />} label={<h1 className={`font-medium text-gray-800 ${filterOption.createdByOthers ? 'text-blue' : ''}`}>Created By Others</h1>} />
                                                </FormGroup>
                                                <div className='flex w-full items-start -mx-1 max-w-sm'>
                                                    <PlayArrow className='cursor-pointer text-gray-800' onClick={() => accordionRef.current?.click()} />
                                                    <Accordion style={{ backgroundColor: '#EBEEF3', boxShadow: 'none', border: 'none', margin: 0, flexGrow: 1 }}>
                                                        <AccordionSummary
                                                            ref={accordionRef}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{ minHeight: '26px', height: '26px', padding: '4px 8px' }}
                                                        >
                                                            <h1 className={`font-medium text-gray-800 ${filterOption.fileType.length ? 'text-blue' : ''}`} style={{ fontWeight: 400, textShadow: '0px 0px 0px #333' }}>File Type</h1>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ flexWrap: 'wrap' }}>
                                                            {availableFileType.map(item => <div className={`${filterOption.fileType.includes(item) ? 'folder' : 'inActive_folder'} flex items-center justify-center cursor-pointer m-2`} onClick={() => handleFileType(item)}><p className='text-xs text-center'>{item}</p></div>)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>}
                                        </div>

                                        <div className='flex items-center gap-4 relative z-30'>
                                            <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                                <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                                {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                    : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                                {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12 -left-2 border'>
                                                    <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortFileByName}>Name</p>
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortFileByCreated}>Date Created</p>
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max' onClick={sortFileByUpdated}>Date Updated</p>
                                                </div>}
                                            </div>
                                            <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleFileOrder}>
                                                <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                            </div>
                                            {isAccess && !lastFolder?.length && <button title={!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? 'you have view only access' : ''} disabled={!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username))} className={`px-6 py-2 ${(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? 'bg-blue cursor-pointer' : 'bg-gray-500'} text-white rounded min-w-max hover-white flex items-center gap-2 group relative`} onClick={() => setIsLink(true)} >
                                                <span>Add Link</span>
                                                {!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? <span className='absolute -top-9 left-1/2 transform -translate-x-1/2 bg-gray-100 text-gray-800 text-xs py-1.5 px-3 rounded min-w-max hidden shadow group-hover:block'>
                                                    you have view only access
                                                </span> : <></>}
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.94 7.43803C12.5899 7.74828 13.1557 8.20996 13.5901 8.78427C14.0245 9.35857 14.3148 10.0287 14.4365 10.7385C14.5581 11.4482 14.5077 12.1768 14.2894 12.863C14.0712 13.5492 13.6914 14.173 13.182 14.682L8.68203 19.182C7.83811 20.0259 6.69351 20.5001 5.50003 20.5001C4.30655 20.5001 3.16195 20.0259 2.31803 19.182C1.47411 18.3381 1 17.1935 1 16C1 14.8065 1.47411 13.6619 2.31803 12.818L4.07503 11.061M17.425 10.439L19.182 8.68203C20.0259 7.83811 20.5001 6.69351 20.5001 5.50003C20.5001 4.30655 20.0259 3.16195 19.182 2.31803C18.3381 1.47411 17.1935 1 16 1C14.8065 1 13.6619 1.47411 12.818 2.31803L8.31803 6.81803C7.80866 7.32703 7.42889 7.95084 7.21061 8.63705C6.99233 9.32326 6.94191 10.0518 7.0636 10.7616C7.18528 11.4713 7.47552 12.1415 7.90992 12.7158C8.34432 13.2901 8.9102 13.7518 9.56003 14.062" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </button>}
                                            {isAccess && <button title={!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? 'you have view only access' : ''} disabled={!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username))} className={`px-6 py-2 ${(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? 'bg-blue cursor-pointer' : 'bg-gray-500'} text-white rounded min-w-max hover-white relative group`} onClick={() => setIsUpload(true)} >
                                                Upload <CloudUploadOutlined className='ml-2' />
                                                {!(repoDetail && (accessRight == 1 || repoDetail?.username == user?.username)) ? <span className='absolute -top-9 left-1/2 transform -translate-x-1/2 bg-gray-100 text-gray-800 text-xs py-1.5 px-3 rounded min-w-max hidden shadow group-hover:block'>
                                                    you have view only access
                                                </span> : <></>}
                                            </button>}
                                            <button disabled={(retailer ? retailer?.status == 'accepted' : !files.length)} onClick={() => downloadFolderZip(files, downloadFolderName, 'repository')} className={`px-6 py-2 cursor-pointer ${(retailer ? retailer?.status == 'accepted' : files.length) ? 'bg-blue' : 'bg-gray-500'} text-white rounded min-w-max hover-white`} >
                                                Repository <CloudDownloadOutlined className='ml-2' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='my-4' style={{ maxHeight: '70dvh', overflowY: 'auto' }}>
                                        {!folderList?.length && !links?.length
                                            ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No File or Link Added</h1>
                                            : <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                <thead>
                                                    <tr className='font-medium text-gray-500'>
                                                        <td className={`${lastFolder.length ? 'opacity-100' : 'opacity-0'}`}>
                                                            {lastFolder.length ?
                                                                <svg onClick={backFolder} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer">
                                                                    <path fillRule="evenodd" d="M7.28 7.72a.75.75 0 010 1.06l-2.47 2.47H21a.75.75 0 010 1.5H4.81l2.47 2.47a.75.75 0 11-1.06 1.06l-3.75-3.75a.75.75 0 010-1.06l3.75-3.75a.75.75 0 011.06 0z" clipRule="evenodd" />
                                                                </svg>
                                                                : <></>
                                                            }
                                                        </td>
                                                        <td>Name</td>
                                                        <td>Description</td>
                                                        <td>Uploaded By</td>
                                                        <td>Format</td>
                                                        <td className='text-center'>Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                    {
                                                        !lastFolder.length && links.map((item, i) => <LinkList key={i} isAccess={isAccess} fileData={item} setIsLink={setIsLink} />)
                                                    }
                                                    {
                                                        !filterOption.createdByMe && !filterOption.createdByOthers && !filterOption.fileType?.length
                                                            ?
                                                            <>
                                                                {
                                                                    retailer && repoDetail?.repository_type == 2 ?
                                                                        folderList?.filter(item => retailer ? isRoot ? JSON.parse(retailer?.file_list)?.includes(Number(item?.folder_id || item?.file_id)) : true : true)?.map((file, i) => file?.name?.charAt(0) !== "." && file?.name?.toLowerCase().includes(fileSearch.trim().toLowerCase()) ? <FileList retailer={retailer} setLastFolder={setLastFolder} isAccess={isAccess} downloadFolderZip={downloadFolderZip} repoDetail={repoDetail} setFileData={setFileData} key={i} isRoot={isRoot} setIsRoot={setIsRoot} fileData={file} repoName={repo} projectName={project} userName={username || userData?.name} setFolderList={setFolderList} /> : <></>)
                                                                        : folderList?.map((file, i) => file?.name?.charAt(0) !== "." && file?.name?.toLowerCase().includes(fileSearch.trim().toLowerCase()) ? <FileList setLastFolder={setLastFolder} isAccess={isAccess} downloadFolderZip={downloadFolderZip} repoDetail={repoDetail} setFileData={setFileData} key={i} isRoot={isRoot} setIsRoot={setIsRoot} fileData={file} repoName={repo} projectName={project} userName={username || userData?.name} setFolderList={setFolderList} /> : <></>)
                                                                }
                                                            </>
                                                            : <>
                                                                {
                                                                    retailer && repoDetail?.repository_type == 2 ?
                                                                        folderList?.filter(item => retailer ? isRoot ? JSON.parse(retailer?.file_list)?.includes(Number(item?.folder_id || item?.file_id)) : true : true)?.map((file, i) => file?.name?.charAt(0) !== "." && (filterOption.fileType.includes(file?.fileFormat) || filterOption.fileType.length && (filterOption.createdByMe && file.created_by == userData?.id) || (filterOption.createdByOthers && file.created_by != userData?.id)) && file?.name?.toLowerCase().includes(fileSearch.trim().toLowerCase()) ? <FileList retailer={retailer} setLastFolder={setLastFolder} isAccess={isAccess} downloadFolderZip={downloadFolderZip} repoDetail={repoDetail} setFileData={setFileData} key={i} isRoot={isRoot} setIsRoot={setIsRoot} fileData={file} repoName={repo} projectName={project} userName={username || userData?.name} setFolderList={setFolderList} /> : <></>)
                                                                        : folderList?.map((file, i) => file?.name?.charAt(0) !== "." && (filterOption.fileType.includes(file?.fileFormat) || filterOption.fileType.length && (filterOption.createdByMe && file.created_by == userData?.id) || (filterOption.createdByOthers && file.created_by != userData?.id)) && file?.name?.toLowerCase().includes(fileSearch.trim().toLowerCase()) ? <FileList setLastFolder={setLastFolder} isAccess={isAccess} downloadFolderZip={downloadFolderZip} repoDetail={repoDetail} setFileData={setFileData} key={i} isRoot={isRoot} setIsRoot={setIsRoot} fileData={file} repoName={repo} projectName={project} userName={username || userData?.name} setFolderList={setFolderList} /> : <></>)
                                                                }
                                                            </>
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                                :
                                state === 'issue' ?
                                    <div className='w-full'>

                                        <div className='flex items-center my-2 py-2 gap-10 border-b'>
                                            <h1 className={`text-lg cursor-pointer text-gray-500 border-b ${(!openIssue && !closedIssue) ? 'border-gray-500' : 'border-white'}`} onClick={() => { setOpenIssue(false); setClosedIssue(false); }}>All ({issues?.length ?? 0})</h1>
                                            <h1 className={`text-lg cursor-pointer text-blue border-b ${openIssue ? 'border-blue-500' : 'border-white'}`} onClick={e => { setOpenIssue(true); setClosedIssue(false); }}>Open {`(${openCount})`}</h1>
                                            <h1 className={`text-lg cursor-pointer text-green border-b ${closedIssue ? 'border-green-500' : 'border-white'}`} onClick={e => { setClosedIssue(true); setOpenIssue(false); }}>Closed {`(${closedCount})`}</h1>
                                        </div>
                                        <div className='flex items-center justify-between my-4'>
                                            <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                                                <SearchOutlined />
                                                <input type="text" placeholder='Search issue here...' onChange={(e) => issues.length ? setIssueSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                            </div>

                                            <div className='flex items-center gap-4 relative z-30'>
                                                <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                                    <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                                    {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                        : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                                    {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12 -left-2 border'>
                                                        <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByName}>Name</p>
                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByCreated}>Date Created</p>
                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max' onClick={sortIssueByUpdated}>Date Updated</p>
                                                    </div>}
                                                </div>
                                                <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleIssueOrder}>
                                                    <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                                </div>
                                                <button className='px-10 py-2 bg-blue text-white rounded min-w-max' onClick={() => history.push(`/${username}/${project}/${repo}/file/issue/new`)}>
                                                    Create Issue
                                                </button>
                                            </div>
                                        </div>
                                        <div className='my-4' style={{ maxHeight: '65dvh', overflowY: 'auto' }}>
                                            {!issues?.length
                                                ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Issue Added</h1>
                                                : <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                    <thead>
                                                        <tr className='font-medium text-gray-500'>
                                                            <td className='opacity-0'>opacity</td>
                                                            <td>Issue</td>
                                                            <td>Author</td>
                                                            <td className='text-center'>Repository</td>
                                                            <td className='text-center'>Assignee</td>
                                                            <td className='text-center'>Action</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                        {
                                                            (openIssue || closedIssue) ?
                                                                issues?.map(issue => (closedIssue ? issue.issue_status == 'Closed' : openIssue ? issue.issue_status == 'Open' : '') && issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                                :
                                                                issues?.map(issue => issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='w-full'>
                                        <div className='my-4' style={{ maxHeight: '70dvh', overflowY: 'auto' }}>
                                            {!tagList?.length
                                                ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Tag Added</h1>
                                                :
                                                <>
                                                    <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                        <thead>
                                                            <tr className='font-medium text-gray-500'>
                                                                <td className='opacity-0'>opacity</td>
                                                                <td>Description</td>
                                                                <td>Assigned Repos</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                            {
                                                                tagList.map(tag => <tr key={tag.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50 relative'>
                                                                    <td>
                                                                        <div className='px-4'>
                                                                            <Tag text={tag.name} color={tag.color} />
                                                                        </div>
                                                                    </td>
                                                                    <td className='max-w-sm'>
                                                                        <p className='max-w-sm'>{tag.description}</p>
                                                                    </td>
                                                                    <td className=''>4 repos</td>
                                                                </tr>)
                                                            }
                                                        </tbody>
                                                    </table>

                                                </>
                                            }
                                        </div>
                                    </div>
                            }

                        </div>
                    </>
                )}
            </div>

            {emailInvite && <InviteViaEmail setEmailInvite={setEmailInvite} setOpenPopUp={setOpenPopUp} type='repo' id={repoDetail.id} />}
            {addModal &&
                <AddBranch getFreshBranchList={fetchBranchList} setAddModal={(value) => setAddModal(value)} mainBranchId={branches[0]?.id} />
            }
            {(openPopup || removeCreator) && <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1001 }}>
                <div className='max-w-xl bg-white p-5 w-full rounded-md h-[80vh] flex flex-col overflow-y-auto'>
                    <div className='flex items-center gap-5 border-b mb-4'>
                        <button className={`text-lg font-semibold text-blue hover:text-blue-600 border py-1 px-2 select-none bg-white border-b-0 ${openPopup ? '-mb-0.5' : ''}`} onClick={() => { setOpenPopUp(true); setRemoveCreator(false) }}>Add Contributor</button>
                        <button className={`text-lg font-semibold text-red hover:text-red-500 border py-1 px-2 select-none  bg-white border-b-0 ${removeCreator ? '-mb-0.5' : ''}`} onClick={() => { setRemoveCreator(true); setOpenPopUp(false) }}>Edit Contributor</button>
                        <button className='ml-auto' onClick={() => { setOpenPopUp(false); setRemoveCreator(false) }}><CloseOutlined fontSize='large' className='cursor-pointer text-gray-800 hover:text-black' /></button>
                    </div>
                    <>
                        {
                            openPopup ?
                                <AddContributor retailers={repoDetail?.retailers} setOpenPopUp={setOpenPopUp} setEmailInvite={setEmailInvite} setRemoveCreator={setRemoveCreator} type='repo' canInvite={canInvite} setCanInvite={setCanInvite} plan={user?.plan} id={repoDetail.id} />
                                :
                                removeCreator ?
                                    <RemoveContributor contributors={contributors} setContributors={setContributors} setRemoveCreator={setRemoveCreator} type='repo' setCanInvite={setCanInvite} id={repoDetail.id} />
                                    :
                                    <></>
                        }
                    </>
                </div>
            </div>}

            {fileData && <div className='fixed top-0 left-0 w-full min-h-screen overflow-hidden grid place-items-center' style={{ zIndex: 2000 }}>
                <div className={`rounded-lg w-full max-w-xl overflow-hidden z-10 bg-white p-5 lg:p-10 relative`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer absolute top-4 right-4" onClick={() => setFileData(false)}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1 className='text-xl text-center font-semibold text-gray-800 mb-4 max-w-xs mx-auto'>No preview uploaded yet for {fileData?.name}</h1>
                    <FileUploader
                        multiple={false}
                        handleChange={(file) => toast.promise(
                            handleImage(file),
                            {
                                loading: 'Uploading Preview...',
                                success: <p>Preview Uploaded!</p>,
                                error: <p>Could not upload Preview!</p>,
                            }
                        )}
                        name="file"
                        children={
                            <div className='flex items-center justify-center flex-col border border-dashed p-4'>
                                <h1 className='text-gray-500 text-lg mb-4'>Drag & drop or Browse your file from <span className='text-blue hover:text-blue-600 cursor-pointer'>here</span></h1>
                                <svg width="238" height="158" viewBox="0 0 238 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M181.219 44.4147C177.948 44.4147 175.111 44.6202 171.994 45.1429C164.221 19.0729 139.493 0 110.97 0C76.1432 0 48.5273 28.4519 48.5273 63.3456C48.5273 66.457 48.7984 69.0688 49.2368 72.0829C47.5503 71.8689 45.2596 72.0874 43.5602 72.0829C19.8839 72.0829 0.985352 91.305 0.985352 115.041C0.985352 138.778 19.8839 158 43.5602 158H96.0692V109.217H66.2668L119.485 48.7834L172.704 109.217H142.902V158H181.219C212.538 158 237.985 132.613 237.985 101.207C237.985 69.7775 212.538 44.4147 181.219 44.4147Z" fill="#2DC8A8" />
                                </svg>

                            </div>
                        }
                    />
                    <p className='text-center my-3'>or</p>
                    <div className='flex items-center gap-6'>
                        <input type="text" value={URL} onChange={(e) => setURL(e.target.value)} placeholder="Add a public url" className='border py-1.5 px-4 w-full flex-grow rounded-lg' style={{ zIndex: 1000 }} />
                        <button className='border border-blue-500 text-blue text-base py-1.5 px-6 rounded-lg' onClick={() => savePreview()}>Save</button>
                    </div>
                </div>
                <div className='absolute top-0 w-full min-h-screen overflow-hidden bg-black bg-opacity-80 filter backdrop-blur-sm' onClick={() => setFileData(false)}></div>
            </div>}

            {isUpload && <Upload folders={folders} storage={user?.plan.storage} setIsUpload={setIsUpload} repoDetail={repoDetail} downloadFolderName={downloadFolderName} getFiles={getFiles} currentBranch={currentBranch} />}
            {isViewContributor && <ContributorList contributors={[...contributors, { name: repoDetail?.user_name, username: repoDetail?.username, profile_pic: repoDetail?.profile_pic }]} onClose={() => setIsViewContributor(false)} />}
            {isLink && currentBranch && <AddLink isLink={isLink} setIsLink={setIsLink} currentBranch={currentBranch} getLinks={getLinks} />}
            {isTransfer ? <TransferOwnership contributors={contributors} setIsTransfer={setIsTransfer} type='repo' id={repoDetail?.id} /> : <></>}
            {isRetailer ? <InviteRetailer contributors={contributors} type={"repo"} projectId={repoDetail?.project_id} repoId={repoDetail?.id} files={files} branchId={repoDetail?.invite?.branch_id} fileArr={repoDetail?.invite?.file_list} onClose={() => setIsRetailer(false)} retailerLink={repoDetail?.link_id} linkExpired={repoDetail?.generated_at} currentBranch={currentBranch} branches={branches} refetch={() => getRepoDetails()} /> : <></>}

        </>
    )
}

export default Files
