import React from 'react'

function UserBordered({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="500" height="495" viewBox="0 0 500 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path d="M248 417.5C352.88 417.5 438.5 332.988 438.5 228C438.5 123.066 352.934 37.5 248 37.5C143.012 37.5 58.5 123.12 58.5 228C58.5 332.934 143.066 417.5 248 417.5Z" fill="#1D85E8" stroke="#2DC8A8" strokeWidth="15" />
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="50" y="10" width="400" height="400">
                    <path d="M250 410C360.457 410 450 320.457 450 210C450 99.5431 360.457 10 250 10C139.543 10 50 99.5431 50 210C50 320.457 139.543 410 250 410Z" fill="white" />
                </mask>
                <g mask="url(#mask0)">
                    <path d="M99 335C43.6175 369.448 -15.636 479.517 -36 529C-56.1737 578.483 -40.6099 665.508 85 589C210.61 512.492 99 335 99 335Z" fill="#CFD8DC" />
                    <path d="M425 344C425 344 547.733 447.759 562 536C576.267 624.051 495.726 650.015 432 541C368.464 431.794 425 344 425 344Z" fill="#CFD8DC" />
                    <path d="M117 328C117 328 172.577 299.095 253 301C333.423 302.905 386.707 322.667 401 336C415.293 349.333 382 799 382 799C382 799 236.4 808.761 111 780C111 780 130.24 626.947 127 522C123.951 416.862 117 328 117 328Z" fill="#FFC258" />
                    <path d="M223 261C223 261 230.601 292.323 219 305C219 305 238.018 336.308 281 305C281 305 272.822 284.445 281 271L223 261Z" fill="#EF6E4B" />
                    <path d="M306 204C306 204 323.072 188.217 325 207C327.314 228.818 306.857 238.02 303 231C298.951 224.17 306 204 306 204Z" fill="#FF8B66" />
                    <path d="M194 206C194 206 176.935 190.217 175 209C172.679 230.818 193.131 240.02 197 233C200.87 226.17 194 206 194 206Z" fill="#FF8B66" />
                    <path d="M252 284C287.868 284 308 260.198 308 190C308 153.855 289.257 132 249 132C208.743 132 192 153.855 192 190C192.191 265.524 214.796 284 252 284Z" fill="#FF8B66" />
                    <path d="M204.001 215C203.412 218.502 206.296 222.263 211.001 223C215.705 223.737 220.412 221.502 221.001 218C221.589 214.498 218.705 210.737 214.001 210C209.492 209.263 204.785 211.498 204.001 215Z" fill="#DB614D" />
                    <path d="M278.001 217C278.196 220.524 282.321 223.185 287.001 223C291.681 222.629 295.196 219.709 295.001 216C294.806 212.477 290.681 209.815 286.001 210C281.321 210.371 277.611 213.291 278.001 217Z" fill="#DB614D" />
                    <path d="M216 178C216.389 178 216.612 178.371 217 178C222.829 173.734 231.806 175 232 175C232.972 175.185 233.806 174.113 234 173C234.194 172.073 233.166 171.185 232 171C231.612 171 222.189 169.806 215 175C214.223 175.556 213.417 176.258 214 177C214.583 177.556 215.612 178 216 178Z" fill="#092C4C" />
                    <path d="M283 178C283.375 178 284.813 177.364 285 177C285.563 176.271 284.751 175.547 284 175C277.248 169.717 268.375 171 268 171C267.062 171.182 265.813 172.089 266 173C266.188 173.911 267.062 175.182 268 175C268 175 276.561 173.628 282 178C282.375 178.182 282.438 178.182 283 178Z" fill="#092C4C" />
                    <path d="M219 199C219 203.381 221.563 207 224 207C226.437 207 228 203.381 228 199C228 194.619 226.437 191 224 191C221.375 191 219 194.619 219 199Z" fill="#092C4C" />
                    <path d="M267 199C267 203.381 268.563 207 271 207C273.437 207 276 203.381 276 199C276 194.619 273.437 191 271 191C268.563 191 267 194.619 267 199Z" fill="#092C4C" />
                    <path d="M292.001 149C292.001 149 266.965 147.019 212.001 143C212.001 143 191.665 164.525 193.001 216C193.001 216 161.294 123 255.001 123C348.899 123 306.001 214 306.001 214C306.001 214 309.368 166.414 292.001 149Z" fill="#092C4C" />
                    <path d="M249 222L252 219L245 212C251.646 202.253 252 179.956 252 179H248C248 179.191 247.831 203.356 241 211L240 212L249 222Z" fill="#DB614D" />
                    <path d="M273.001 233C273.001 233 272.761 235.751 272.001 239C270.099 246.262 263.972 257.956 244.001 257C225.741 256.236 220.952 243.644 220.001 236C219.43 232.178 220.001 230 220.001 230C237.689 234.778 273.001 233 273.001 233Z" fill="#092C4C" />
                    <path d="M205.001 154C205.001 154 189.34 139.939 192.001 123C194.661 106.061 231.456 93.5288 263.001 100C263.001 100 279.577 90.5349 302.001 105C324.424 119.465 339.763 162.354 320.001 189C320.001 189 324.844 143.774 299.001 159C299.001 159 257.828 137.441 205.001 154Z" fill="#092C4C" />
                    <path d="M296 303C296 303 258.994 548.537 296 667C296 667 419.982 663.19 432 659C443.826 655 455.901 398.042 425 343C424.809 343.19 374.017 301.476 296 303Z" fill="#2DC8A8" />
                    <path d="M337 306C337 306 316.304 336.238 290 350C290 350 289.192 345.307 289 339C288.808 337.28 289 336.102 289 334C289 330.751 289.616 326.823 290 323C290.192 320.898 290.616 319.294 291 317C293.688 302.856 299.872 287.69 316 281C316 281 320.2 281.986 325 286C326.152 286.956 327.848 287.853 329 289C330.536 290.72 331.656 293.515 333 296C333.768 297.529 334.424 298.28 335 300C335.96 301.911 336.616 303.898 337 306Z" fill="#1D85E8" />
                    <path d="M335.001 301C325.648 311.952 310.188 326.742 289.001 340C288.81 338.271 289.001 336.114 289.001 334C309.615 320.742 324.412 306.376 333.001 296C333.955 297.345 334.428 299.271 335.001 301Z" fill="#CFD8DC" />
                    <path d="M329 289C320.355 298.487 307.483 311.426 290 323C290.192 320.913 290.616 319.277 291 317C306.369 306.374 318.315 294.538 326 286C327.153 286.949 327.847 287.862 329 289Z" fill="#CFD8DC" />
                    <path d="M365 396L345 398L325 472H341L348 446H366L378 467L397 464L365 396ZM351 434L355 414L365 434H351Z" fill="#CFD8DC" />
                    <path d="M208 303C208 303 227.248 549.016 191 668C191 668 88.5392 663.998 79.0004 660C69.4616 655.812 71.7655 389.018 100 334C99.8096 333.81 143.709 301.477 208 303Z" fill="#2DC8A8" />
                    <path d="M164 306C164 306 184.696 336.238 211 350C211 350 211.808 345.307 212 339C212 337.28 212 336.102 212 334C212 330.751 211.384 326.823 211 323C210.808 320.898 210.384 319.294 210 317C207.312 302.856 201.128 287.69 185 281C185 281 180.8 281.986 176 286C174.848 286.956 173.152 287.853 172 289C170.464 290.72 169.344 293.515 168 296C167.232 297.529 166.576 298.28 166 300C165.232 301.911 164.576 303.898 164 306Z" fill="#1D85E8" />
                    <path d="M166 301C175.353 311.952 190.813 326.742 212 340C212 338.271 212 336.114 212 334C191.386 320.742 176.589 306.376 168 296C167.046 297.345 166.763 299.271 166 301Z" fill="#CFD8DC" />
                    <path d="M172 289C180.645 298.487 193.517 311.426 211 323C210.808 320.913 210.384 319.277 210 317C194.631 306.374 182.685 294.538 175 286C173.847 286.949 173.153 287.862 172 289Z" fill="#CFD8DC" />
                    <path d="M11.0002 367C41.029 374.609 59.2953 527.048 42.0002 563C24.7052 598.952 -0.0472717 622.86 -42.9998 589C-85.9523 555.14 -66.5578 379.984 -38.9998 368C-25.886 362.484 11.0002 367 11.0002 367Z" fill="#CFD8DC" />
                    <path d="M273 233C273 233 272.76 235.807 272 239C251.268 242.381 226.467 237.878 220 236C219.429 232.244 220 230 220 230C237.688 234.695 273 233 273 233Z" fill="white" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d" x="0" y="0" width="500" height="495" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="20" />
                    <feGaussianBlur stdDeviation="25" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default UserBordered