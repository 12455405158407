import { TextField } from '@material-ui/core'
import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react'
import Tag from '../ReusableComponents/Tag';
import { createTag, editTag, getProjectByName, getReposBasedOnProjectId, listTagsByProject, removeTag } from '../../service/service';
import Navbar from '../ReusableComponents/Navbar';
import toast from 'react-hot-toast';
import { useClickOutside } from '../../utils/helper';
import moment from 'moment';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import EditIcon from '../../assets/icons/EditIcon';
import WarningIcon from '../../assets/icons/WarningIcon';
// import { useHistory, useParams } from 'react-router-dom';
import Loader from '../ReusableComponents/Loader';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';


const colors = [
    '#1C85E8',
    '#FF8082',
    '#2CC8A7',
    '#FFC258',
    '#092C4C',
    '#8B383E',
    '#D10071',
    '#7E0093',
]

const Default_State = {
    open: false,
    id: null
}

function Tags() {
    const history = useHistory()
    const { username, project } = useParams()
    const [isEdit, setIsEdit] = useState(false)
    const [tagName, setTagName] = useState('')
    const [tagDesc, setTagDesc] = useState('')
    const [tagColor, setTagColor] = useState('#FFC258')
    const [tagList, setTagList] = useState([])
    const [removeModal, setRemoveModal] = useState(Default_State);
    const [tagSearch, setTagSearch] = useState('')
    const [isSort, setIsSort] = useState(false);
    const [isForm, setIsForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [projectDetail, setProjectDetail] = useState(null);
    const [repos, setRepos] = useState([]);
    const [open, setOpen] = useState(false);
    const [repoArr, setRepoArr] = useState([])

    const moreRef = useRef()
    const openRef = useRef()
    useClickOutside(() => open ? setOpen(false) : setIsSort(false), open ? openRef : moreRef);

    const getTagsListByProject = async () => {
        const res = await listTagsByProject(projectDetail.id)
        if (res.data)
            setTagList(res.data);
        setLoading(false)
    }

    useEffect(() => {
        getProject();
    }, [])

    useEffect(() => {
        if (isEdit) {
            setIsForm(true)
            setTagName(isEdit?.name);
            setTagDesc(isEdit?.description);
            setTagColor(isEdit?.color);
            if (isEdit.repo_ids.length)
                setRepoArr(isEdit.repo_ids.split(","))
        }
    }, [isEdit]);

    useEffect(() => {
        if (projectDetail?.id) {
            getTagsListByProject();
            getRepos();
        }
    }, [projectDetail])

    const getRepos = async () => {
        let res = await getReposBasedOnProjectId({ projectId: projectDetail?.id });
        if (res.success && res.data.length) {
            setRepos(res.data)
        }
    }

    const getProject = async () => {
        const res = await getProjectByName(project);
        if (res.success && res.data.id) {
            setProjectDetail(res.data);
        }
        else {
            history.push('/404')
        }
    }

    const onSave = async () => {
        if (!tagName) return toast.error('Tag Name is Required', { id: 'error' })
        if (tagName?.length > 15) return toast.error('Tag Name is too long, maximum 15 characters allowed', { id: 'error' })
        if (!repoArr?.length) return toast.error("Please select a repository", { id: 'error' });
        const data = {
            name: tagName,
            description: tagDesc,
            color: tagColor,
            issue: 0,
            project_id: projectDetail.id,
            repo_ids: repoArr.toString()
        }
        const res = await createTag(data);
        if (res.id) {
            setIsForm(false)
            toast.success('New tag created', { id: 'success' })
            setTagList([{ id: res.id, ...data, author_name: username, repos: repos?.map(repo => repoArr.includes(repo.id) ? repo.name : '') }, ...tagList]);
            setTagName('')
            setTagDesc('');
            setTagColor('#FFC258');
            setRepoArr([])
        }
    }
    const onEdit = async () => {
        if (!tagName) return alert('Tag Name is Required');
        if (tagName?.length > 15) return toast.error('Tag Name is too long, maximum 15 characters allowed', { id: 'error' });
        if (!repoArr?.length) return toast.error("Please select a repository", { id: 'error' });

        const data = {
            id: isEdit.id,
            name: tagName,
            description: tagDesc,
            color: tagColor,
            issue: isEdit.issue,
            repo_ids: repoArr.toString()
        }
        const res = await editTag(data)
        if (res.id) {
            toast.success("Tag updated", { id: 'success' })
            setIsForm(false)
            getTagsListByProject();
            setTagName('')
            setTagDesc('');
            setTagColor('#FFC258')
            setRepoArr([])
        }
    }

    const handleRemoveBtn = async (tagId) => {
        let i = tagList?.findIndex(tag => tag.id == tagId);
        if (i > -1) {
            let newArr = [...tagList];
            newArr.splice(i, 1);
            setTagList([...newArr])
            setRemoveModal(Default_State)
            let res = await removeTag(tagId)
            if (!res.success) {
                toast.error(res.message, { id: 'error' })
                getTagsListByProject()
            }
            else
                toast.success("Tag removed", { id: 'success' })
        }
    }

    const sortByName = () => {
        if (tagList) {
            let arr = [...tagList]
            let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
            setTagList(sorted)
            setIsSort(false)
        }
    }
    const sortByTime = () => {
        if (tagList) {
            let arr = [...tagList]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            setTagList(sorted)
            setIsSort(false)
        }
    }

    const handleRepoSelect = (e) => {
        let { id, name } = e.target;
        let data = tagList.filter((item) => item?.repos?.includes(name))
        if (data.length >= 3) {
            return toast("Maximum 3 tags per repository assigning allowed", { id: 'info' })
        }
        if (repoArr.includes(id)) {
            let arr = [...repoArr]
            let i = arr.findIndex(item => item == id);
            arr.splice(i, 1);
            setRepoArr([...arr])
        }
        else {
            setRepoArr((pre => [...pre, id]))
        }
    }

    if (loading) return <Loader />

    return (
        <>
            <Navbar />
            <div className='p-5 md:px-10 xl:px-20'>
                <div className='flex items-center gap-4 justify-between border-b-2 w-full'>
                    <h1 className='text-xl font-semibold text-gray-700 leading-loose'><span className='hover:text-blue-500 cursor-pointer' onClick={() => history.push("/" + username + "/" + project)}>{projectDetail.name}</span>{' > '}Tags</h1>
                    {history.length > 2 && <button className='text-base font-medium cursor-pointer text-blue-400 hover:text-blue-500 leading-loose select-none' onClick={() => { console.log('back clicked'); history.goBack() }}>Go Back</button>}
                </div>

                <div className='flex items-center justify-between my-4'>
                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                        <SearchOutlined />
                        <input type="text" placeholder='Search tags here...' onChange={(e) => tagList?.length ? setTagSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                    </div>

                    <div className='flex items-center gap-4 relative z-30'>
                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                            {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12'>
                                <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                <p className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortByName}>Name</p>
                                <p className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={sortByTime}>Created</p>
                            </div>}
                        </div>
                        <button className='px-10 py-2 bg-blue text-white rounded min-w-max' onClick={() => tagList.length <= 5 ? setIsForm(true) : toast("Maximum 5 tags per project allowed", { id: 'info' })}>
                            Create Tag
                        </button>
                    </div>
                </div>

                {isForm && <div className='border pt-4 px-6 xl:px-8 rounded'>
                    {tagName && <Tag text={tagName} color={tagColor} />}
                    <div className='flex items-end gap-4 py-4 justify-between'>
                        <TextField id="outlined-basic" size="small" label="Tag Name" value={tagName} onChange={e => setTagName(e.target.value)} variant="outlined" />
                        <TextField id="outlined-basic" size="small" label="Tag Description" value={tagDesc} onChange={e => setTagDesc(e.target.value)} variant="outlined" className='w-full max-w-xs' />
                        <div ref={openRef} className='relative w-full max-w-[200px] py-2 px-4 rounded-md border border-gray-300'>
                            <div className='cursor-pointer' onClick={() => setOpen(!open)}>
                                <span className='text-gray-500 select-none'>{repoArr.length ? `${repoArr.length} repository selected` : 'Select Repositories'}</span>
                            </div>
                            {open && <div className='w-full max-w-[200px] max-h-96 overflow-y-auto px-3 py-1 rounded-md bg-white shadow-lg absolute top-12 left-0 z-50 border border-gray-100'>
                                <div>
                                    {repos.map(repo => <div key={repo.id} className="flex items-center gap-2 my-2 cursor-pointer">
                                        <input name={repo.name} type="checkbox" id={repo.id} checked={repoArr.includes(repo.id)} onChange={handleRepoSelect} />
                                        <label htmlFor={repo.id}>{repo.name}</label>
                                    </div>)}
                                </div>
                                <div className='flex items-center gap-4 justify-end p-2'>
                                    <span className='text-red cursor-pointer' onClick={() => setOpen(false)}>Close</span>
                                </div>
                            </div>}

                        </div>
                        <div>
                            <p className='text-xs font-medium leading-relaxed'>Select Color</p>
                            <div className='flex gap-2'>
                                {colors.map(i => <div key={i} onClick={() => setTagColor(i)} className={`cursor-pointer w-6 h-6 rounded-full`} style={{ backgroundColor: i }} />)}
                            </div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h1 className='cursor-pointer text-red' onClick={() => {
                                setIsEdit(false)
                                setIsForm(true)
                                setTagName('');
                                setTagDesc('');
                                setTagColor('#FFC258');
                                setRepoArr([])
                                setIsForm(false);
                            }}>Cancel</h1>
                            <h1 className='cursor-pointer text-blue' onClick={() => isEdit ? onEdit() : onSave()}>Save</h1>
                        </div>
                    </div>
                </div>}

                <div className='my-4'>
                    {!tagList?.length
                        ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Tags Added</h1>
                        : <table className='w-full tagsList' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                            <thead>
                                <tr className='font-medium text-gray-500'>
                                    <td>Tag</td>
                                    <td>Tag Description</td>
                                    <td>Repositories</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                {
                                    tagList?.map(tag => tag.name.toLowerCase().includes(tagSearch.trim().toLowerCase()) &&
                                        <tr key={tag.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                                            <td className='font-medium text-gray-800'>
                                                <div className='px-4'><Tag text={tag.name} color={tag.color} /></div>
                                                <p className='text-gray-500 font-light text-xs mt-3'>Updated {moment(tag.created_at).fromNow()}</p>
                                            </td>
                                            <td className='max-w-sm'>
                                                <p className='max-w-sm'>{tag.description || 'no description provided'}</p>
                                            </td>
                                            <td className='max-w-sm'>
                                                <p className='max-w-sm'>{tag?.repos?.length ? tag.repos?.filter(item => item.trim() !== '')?.map((item, i) => item).join(", ") : '-'}</p>
                                            </td>
                                            <td>
                                                <div className='flex gap-4'>
                                                    <EditIcon className='cursor-pointer' onClick={() => setIsEdit(tag)} />
                                                    <DeleteIcon className='cursor-pointer' onClick={() => setRemoveModal({ open: true, id: tag.id })} />
                                                </div>
                                                <p className='text-gray-500 font-light mt-3 text-xs'>Added {moment(tag.created_at).fromNow()}</p>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    }
                </div>
                {removeModal.open && <div className='fixed top-0 left-0 w-full h-full overflow-hidden bg-black bg-opacity-75 grid place-items-center' style={{ zIndex: 1000 }}>
                    <div className='flex flex-col items-center justify-center p-5 md:px-10 bg-white w-full max-w-xl rounded-lg'>
                        <WarningIcon />
                        <h1 className='text-3xl font-medium my-6 text-center'>Are you sure you want remove to the tag?</h1>
                        <div className='flex justify-around w-full my-6'>
                            <button className='bg-red rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => setRemoveModal(Default_State)}>
                                No
                            </button>
                            <button className='bg-blue rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => handleRemoveBtn(removeModal.id)}>
                                Yes
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Tags