import { Button, InputAdornment, FormControlLabel, FormControl, List, ListItem, ListItemText, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import useStyle from './Styling'


function Deactivate() {
    const classes = useStyle();
    return (
        <div>
            <List className={classes.listBox}>
                <ListItem className={classes.listContent}>
                    <FormControl >
                        <RadioGroup row >
                            <FormControlLabel
                                className={classes.radioDeactivate}
                                value="top"
                                control={<Radio />}
                                label="Temporary"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                className={classes.radioDeactivate}
                                value="start"
                                control={<Radio />}
                                label="Permanent"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </ListItem>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Email*
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    placeholder="Email"
                                    className={classes.textFieldDeactivate}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon style={{ color: '#DADADA' }} />
                                            </InputAdornment>
                                        ),
                                    }}>
                                </TextField>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Password*
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    placeholder="Password"
                                    className={classes.textFieldDeactivate}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon style={{ color: '#DADADA' }} />
                                            </InputAdornment>
                                        ),
                                    }}>
                                </TextField>
                            </React.Fragment>
                        }>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Reason*
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    placeholder="Password"
                                    className={classes.securitySelectInput}>
                                </TextField>
                            </React.Fragment>
                        }>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Explain Reason (optional)
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    placeholder="Write here"
                                    multiline
                                    rows={4}
                                    className={classes.securitySelectInput}>
                                </TextField>
                            </React.Fragment>
                        }>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <React.Fragment>
                        <Button
                            variant='contained'
                            className={classes.deactivateBtn}>
                            Deactivate
                        </Button>
                    </React.Fragment>
                </ListItem>
            </List>
        </div>
    )
}

export default Deactivate
