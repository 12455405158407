import React from 'react'

function AddIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0C10.3008 0.0446294 6.6159 1.3841 4 4C1.3841 6.6159 0.0446294 10.3008 0 14C0.0446294 17.6992 1.3841 21.3841 4 24C6.6159 26.6159 10.3008 27.9554 14 28C17.6992 27.9554 21.3841 26.6159 24 24C26.6159 21.3841 27.9554 17.6992 28 14C27.9554 10.3008 26.6159 6.6159 24 4C21.3841 1.3841 17.6992 0.0446294 14 0ZM22 15H15V22H13V15H6V13H13V6H15V13H22V15Z" fill="#1E85E7" />
        </svg>

    )
}

export default AddIcon