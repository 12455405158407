import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    issueList: [],
    issueComments: [],
    issueModal: {
        isOpen: false,
        data: null // assignee, contributors, issue, userData, project, repo , file
    }
}

export const issueSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        issueList: (state, action) => {
            state.issueList = action.payload
        },
        issueComments: (state, action) => {
            if (action.payload)
                state.issueComments = action.payload
        },
        setIssueModal: (state, action) => {
            if (action.payload)
                state.issueModal = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { issueList, issueComments, setIssueModal } = issueSlice.actions

export const selectIssueList = (state) => state.issue.issueList
export const selectIssueComments = (state) => state.issue.issueComments
export const selectIssueModal = (state) => state.issue.issueModal

export default issueSlice.reducer