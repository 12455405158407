import React from "react";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import { Link } from "react-router-dom";
import MailIcon from "../../assets/icons/MailIcon";

function CheckMail() {
  return (
    <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
      <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
      <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
        <MailIcon className='m-2' />
        <h1 className="text-3xl md:text-4xl font-bold text-blue">Check your mail</h1>
        <p className="md:text-lg font-medium text-center my-4">We have sent a link to create a new password</p>
        <p className="md:text-lg text-center mb-4">Did not receive the mail? Check your spam folder or <Link to="/forgotPassword"><span className="text-gray-800 hover:text-blue font-bold cursor-pointer">enter email again</span></Link></p>
      </div>
    </div>
  );
}

export default CheckMail;