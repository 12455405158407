import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import { getUserByUsername, listOrgEvents, listUserEvents } from '../../service/service';
import { useClickOutside, useSort } from '../../utils/helper';
import FilterIcon from '../ReusableComponents/FilterIcon';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import EventCard from '../Event/EventCard';
import MiniLoader from '../ReusableComponents/MiniLoader';

const eventData = [
    {
        id: 1,
        title: 'Buidl the Future',
        type: 'hackathon',
        image: 'https://media-fastly.hackerearth.com/media/hackathon/buidl-the-future/images/0419a4484f-banner_image.png'
    },
    {
        id: 2,
        title: 'IBM zStudent Contest',
        type: 'meetup',
        image: 'https://media-fastly.hackerearth.com/media/hackathon/ibm-z-student-contest-2022/images/d108c7e448-hebanner_cover.png'
    },
    {
        id: 3,
        title: 'Smart Odisha Hackathon',
        type: 'hackathon',
        image: 'https://media-fastly.hackerearth.com/media/hackathon/smart-odisha-hackathon-2018/images/9413649060-cover2x_3.png'
    },
    {
        id: 4,
        title: 'Intel® oneAPI',
        type: 'competition',
        image: 'https://media-fastly.hackerearth.com/media/hackathon/intel-oneapi-hackathon-for-open-innovation/images/b5ace3f865-cover_6.png'
    },
    {
        id: 5,
        title: 'Build a Climate Fintech App',
        type: 'conference',
        image: 'https://media-fastly.hackerearth.com/media/hackathon/build-a-climate-fintech-app/images/d2431a1c39-rapyd_climate_tech_hackaton_website_banner_800x200.png'
    },
]


function Events() {
    const history = useHistory();
    const { username } = useParams()
    const sort = useSort()
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUserDetails);
    // const userData = useSelector(selectUserDetails);
    const [userData, setUserData] = useState();
    const [events, setEvents] = useState([])
    const [eventSearch, setEventSearch] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [isAsc, setIsAsc] = useState(true)
    const [filterOption, setFilterOption] = useState({ hackathon: false, competition: false, conference: false, meetup: false })

    const moreRef = useRef()
    const filterRef = useRef()
    useClickOutside(() => { isSort ? setIsSort(false) : setIsFilter(false) }, isSort ? moreRef : filterRef)


    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            setUserData(res.data);
        }
        else {
            history.push("/404")
        }
        fetchEvents()
    }

    const fetchEvents = async () => {
        let res = user?.organization ? await listOrgEvents(user?.organization?.id) : await listUserEvents();
        if (res.success)
            setEvents(res.data)
        else
            setEvents([])
    }

    useEffect(() => {
        if (username) {
            getUser();
        }
    }, [username])


    const handleEventOrder = () => {
        let arr = [...events];
        arr.reverse();
        setEvents(arr)
        setIsAsc(!isAsc)
    }


    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData }} className="lg:max-w-sm hidden lg:inline-flex">
                        </UserCard>

                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push('/dashboard')}>Insights</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${user?.username}/`)}>Projects</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${user?.username}/all`)}>Repositories</h1>
                                {user && <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${user?.username}/issues`)}>Issues</h1>}
                                {user && <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500' onClick={() => history?.push(`/${user?.username}/events`)}>Events</h1>}
                                {user?.plan?.name == 'organization' ? <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/retailer`)}>Retailer</h1> : <></>}
                            </div>
                            <div className='w-full'>
                                <div className='flex items-center justify-between my-4 gap-4'>
                                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full relative'>
                                        <SearchOutlined />
                                        <input type="text" placeholder='Search event here...' onChange={(e) => events.length ? setEventSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                        <FilterIcon
                                            onClick={() => setIsFilter(!isFilter)}
                                        />
                                        {isFilter && <div ref={filterRef} className='absolute z-30 w-full top-14 left-0 popup-bg shadow-lg rounded-lg pt-2 pb-4 px-4 border font-medium text-gray-800'>
                                            <div className='absolute transform -top-2 right-3 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />

                                            <li className='text-right list-none hover:text-blue-500 cursor-pointer' onClick={() => setFilterOption({ hackathon: false, competition: false, conference: false, meetup: false })}>Reset Filters</li>
                                            <FormGroup>
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.competition} onChange={e => setFilterOption({ ...filterOption, competition: !filterOption.competition })} />} label="Competition" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.hackathon} onChange={e => setFilterOption({ ...filterOption, hackathon: !filterOption.hackathon })} />} label="Hackathon" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.conference} onChange={e => setFilterOption({ ...filterOption, conference: !filterOption.conference })} />} label="Conference" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.meetup} onChange={e => setFilterOption({ ...filterOption, meetup: !filterOption.meetup })} />} label="Meetup" />
                                            </FormGroup>
                                        </div>}
                                    </div>

                                    <div className='flex items-center gap-4 relative z-30'>
                                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                            {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12'>
                                                <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}><span>Name</span></p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}>Upcoming</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={() => { }}>Live</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => { }}>Previous</p>
                                            </div>}
                                        </div>
                                        <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleEventOrder}>
                                            <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                        </div>

                                    </div>
                                </div>
                                <div className='my-4 py-5 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 place-items-center xl:place-items-start gap-x-6 gap-y-8 xl:gap-y-10'>
                                    {
                                        user?.organization ?
                                            <>
                                                <div className='p-5 w-full max-w-[300px] cursor-pointer h-44 rounded-lg shadow-lg grid place-items-center relative bg-gradient-to-t from-black to-white' onClick={() => history.push(`/organization/${user.organization.id}/events/create`)}>
                                                    <p className='absolute bottom-4 left-4 font-semibold text-white z-20 text-xl'>Create New Event</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-white">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                </div>
                                                {events.map(event => ((!filterOption.hackathon && !filterOption.conference && !filterOption.competition && !filterOption.meetup) || ((filterOption.competition && event.type == 'competition') || (filterOption.conference && event.type == 'conference') || (filterOption.hackathon && event.type == 'hackathon') || (filterOption.meetup && event.type == 'meetup'))) && <EventCard owner={true} user={user} key={event.id} event={event} />)}
                                            </>
                                            :
                                            <>
                                                {!events?.length
                                                    ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10 col-span-full w-full'>No Event Joined</h1>
                                                    :
                                                    <>
                                                        {events.map(event => ((!filterOption.hackathon && !filterOption.conference && !filterOption.competition && !filterOption.meetup) || ((filterOption.competition && event.type == 'competition') || (filterOption.conference && event.type == 'conference') || (filterOption.hackathon && event.type == 'hackathon') || (filterOption.meetup && event.type == 'meetup'))) && <EventCard owner={false} key={event.id} event={event} />)}
                                                    </>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default Events