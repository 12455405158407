import React, { useEffect } from 'react'
import useForm from "./useForm";
import bgImage from "../../assets/images/Login_Art_Updated.png";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

function Login() {
    const history = useHistory()
    useEffect(() => {
        let token = localStorage.getItem("greyffiti_token");
        if (token) {
            history.push("/dashboard")
        }
    })

    const { handleChange, handleFormSubmit, flagLoaderButton, values, showPassword, handleClickShowPassword } = useForm();

    return (
        <>
            <div className='max-h-screen h-screen overflow-hidden relative bg-red'>
                <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4 cursor-pointer"} style={{ zIndex: 10 }} /></Link>
                <img src={bgImage} alt="" className={"w-full max-w-3xl h-full object-contain absolute m-5 -top-10 left-0"} style={{ zIndex: 1 }} />
                <div className='flex flex-col items-end justify-center p-5 lg:px-10 2xl:px-20 absolute top-0 right-0 w-full h-full' style={{ zIndex: 1 }}>
                    <h1 className='text-white text-3xl md:text-4xl xl:text-5xl font-bold my-1'>Hello again!</h1>
                    <h1 className='text-white text-3xl md:text-4xl xl:text-5xl font-bold my-1'>Welcome back</h1>

                    <form method="post" onSubmit={handleFormSubmit} name="UserLoginForm" className='flex flex-col w-full max-w-sm gap-4 mt-10'>
                        <div className='max-w-sm bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
                            <input required type="email" name="email" id="email" value={values.email} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your email" onChange={handleChange} />
                        </div>

                        <div className='max-w-sm bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
                            <input required type={showPassword ? 'text' : 'password'} name="password" id="password" value={values.password} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your password" onChange={handleChange} />
                            {<IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                                size='small'
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>}
                        </div>
                        <p className='lg:text-lg text-white font-semibold text-right'><Link to="/forgotPassword">Forget Password ?</Link></p>
                        <button disabled={flagLoaderButton} type='submit' onClick={handleFormSubmit} className='w-full bg-blue py-3 text-lg text-white font-semibold rounded-lg'>
                            Continue
                            {flagLoaderButton && <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block mx-4" viewBox="0 0 24 24"></svg>}
                        </button>
                        <p className='lg:text-lg text-right text-white font-semibold'>Don't have an account? <span className='text-blue cursor-pointer hover:text-yellow'><Link to='/register'>Register</Link></span></p>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login