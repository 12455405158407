const regPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
const regEx = new RegExp('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)');

const descriptionFieldregEx = new RegExp('^[ A-Za-z0-9]*$');
const nameFieldregEx = new RegExp('^[ A-Za-z0-9_-]*$');
const usernameFieldregEx = new RegExp('^[ A-Za-z0-9_-]*$');

export const passwordValidator = (password) => {
    let isValid = false
    if ((regPass.test(password))) {
        isValid = true;
    }
    return isValid;
}

export const urlValidator = (url) => {
    let isValid = false
    if ((regEx.test(url))) {
        isValid = true;
    }
    return isValid;
}

export const nameFieldValidator = (nameField) => {
    let isValid = false
    if ((nameFieldregEx.test(nameField))) {
        isValid = true;
    }
    return isValid;
}
export const usernameFieldValidator = (nameField) => {
    let isValid = false
    if ((usernameFieldregEx.test(nameField))) {
        isValid = true;
    }
    return isValid;
}

export const descriptionFieldValidator = (nameField) => {
    let isValid = false
    if ((descriptionFieldregEx.test(nameField))) {
        isValid = true;
    }
    return isValid;
}