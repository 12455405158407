import { Divider, List, ListItem, ListItemText, MenuItem, TextField, Typography,Select } from '@material-ui/core'
import React, { useEffect } from 'react'
import Restore from './components/Restore'
import Save from './components/Save'
import useStyle from './Styling'
import { TimeZone as timeZoneData } from './data/TimeZone'
import { Language as languageData } from './data/Language'
import { UploadData as uploadData } from './data/UploadData'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function SystemSettings(props) {
    const [oldData, setOldData] = React.useState([])
    const [data, setData] = React.useState({timezone:'(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', language:'English', upload_limit:'10 GB' })
    const [settingsType, setSettingsType] = React.useState("system")

    const [timeZone, setTimeZone] = React.useState("(GMT +5:30) Bombay, Calcutta, Madras, New Delhi");
    const [language, setLanguage] = React.useState("English");
    const [upload, setUpload] = React.useState("10 GB")

    useEffect(() => {
        setOldValue() 
    }, [])
    
    const restoreUpdate =() =>{
            setTimeZone('(GMT +5:30) Bombay, Calcutta, Madras, New Delhi') 
            setLanguage('English') 
            setUpload('10 GB') 

    }
    const setOldValue = () => {
        const settingsData = props.settingsListOld.data
        if (settingsData !== undefined){
            settingsData.map(function (item) {
                if (item.setting_type === "system") {
                    if(item.setting_key === "timezone"){
                        setData({
                            timezone:item.key_value
                        })
                        setTimeZone(item.key_value)
                    }
                    if(item.setting_key === "language"){
                        setData({
                            language:item.key_value
                        })
                        setLanguage(item.key_value)
                    }
                    if(item.setting_key === "upload_limit"){
                        setData({
                            upload_limit:item.key_value
                        })
                        setUpload(item.key_value)
                    }
                };
            })
        } 
    }
    const iconStyle = { position: 'absolute',  
                        color: "#092C4C", 
                        right: "3%", 
                        bottom: "25%", 
                        pointerEvents : 'none', 
                        width:"23px",
                        height:"23px" };
 
    const selectTimeZone = (event) => {
        setTimeZone(event.target.value)
        setNewData(event.target.value,"timeZone")
    };

    const selectLanguage = (event) => {
        setLanguage(event.target.value);
        setNewData(event.target.value,"language")
    }

    const selectUpload = (event) => {
         setUpload(event.target.value)
        setNewData(event.target.value,"upload")
    }
    const setNewData = (value, entry) => {
        if(entry === 'timeZone'){
        setData({
            timezone:value,
            language:language,
            upload_limit:upload
        })
        }
        if(entry === 'language'){
            setData({
                timezone:timeZone,
                language:value,
                upload_limit:upload
            })
        }
        if(entry === 'upload'){
            setData({
                timezone:timeZone,
                language:language,
                upload_limit:value
            })
        }
    }

    const classes = useStyle();
    return (
        <div>
            <List className={classes.listBox}>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Set timezone
                                </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <TextField
                        select
                        value={timeZone}
                        onChange={selectTimeZone}
                        variant="outlined"
                        className={classes.systemSelectInput}
                        SelectProps={{
                            IconComponent: () => <KeyboardArrowDownIcon style={iconStyle} />,
                        }}>
                        {timeZoneData.map((time) => (
                            <MenuItem key={time.value}
                                value={time.text}>
                                {time.text}
                            </MenuItem>
                        ))}
                       
                    </TextField>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Set language
                                </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <TextField
                        select
                        value={language}
                        onChange={selectLanguage}
                        variant="outlined"
                        className={classes.systemSelectInput}
                        SelectProps={{
                            IconComponent: () => <KeyboardArrowDownIcon style={iconStyle} />,
                        }}>
                        {languageData.map((lang) => (
                            <MenuItem key={lang.value}
                                value={lang.name}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Set upload limit
                                </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <TextField
                        select
                        value={upload}
                        onChange={selectUpload}
                        variant="outlined"
                        className={classes.systemSelectInput}
                        SelectProps={{
                            IconComponent: () => <KeyboardArrowDownIcon style={iconStyle} />,
                        }}>
                        {uploadData.map((up) => (
                            <MenuItem key={up.value}
                                value={up.text}>
                                {up.text}
                            </MenuItem>
                        ))}
                    </TextField>
                </ListItem>
                <Divider />
                <ListItem>
                    <Restore type={settingsType} 
                             getSettingsList={props.getSettingsList} 
                             restoreUpdate={restoreUpdate}  
                             alertMsg={props.alertMsg}/>
                    <Save data={data} 
                          getSettingsList={props.getSettingsList}  
                          alertMsg={props.alertMsg}/>
                </ListItem>
            </List>
        </div>
    )
}

export default SystemSettings
