import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectUserDetails } from '../../slices/userSlice'
import { useEffect } from 'react'
import { validateRetailerLink } from '../../service/service'
import { useState } from 'react'
import Navbar from '../ReusableComponents/Navbar'
import Loader from '../ReusableComponents/Loader'


function RetailerInviteLink() {
    let { data } = useParams()
    const user = useSelector(selectUserDetails);
    const [loading, setLoading] = useState(true)

    const [error, setError] = useState('')

    const validateLink = async () => {
        let res = await validateRetailerLink({
            link_id: data
        })
        if (res.success && res.data.username) {
            window.location.replace(`${window.location.origin}/${res.data.username}/${res.data.projectname}/${res.data.reponame}/main`)
        }
        else {
            setError(res?.data?.toString())
        }
        setLoading(false)
    }
    useEffect(() => {
        if (user) {
            if (!user?.plan?.name?.includes('free') && !user?.plan?.name?.includes('pro') && data) {
                validateLink();
            }
        }
    }, [user])
    return (
        <>
            <Navbar />
            {
                loading ?
                    <Loader />
                    :
                    <div className='min-h-[60dvh] grid place-items-center'>
                        <h1 className='text-gray-300 text-3xl font-medium'>
                            {error}
                        </h1>
                    </div>
            }
        </>
    )
}

export default RetailerInviteLink