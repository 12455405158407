import React from 'react'
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { handleFreelance, handleFreelanceData } from '../../service/service';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails, userDetails } from '../../slices/userSlice';
import { useEffect } from 'react';
import getTimeZoneDetails from '../../utils/getTimeZone';

function Hire() {

    const user = useSelector(selectUserDetails)
    const dispatch = useDispatch()
    const [toggleOn, setToggleOn] = useState(user?.freelance);
    const [skills, setSkills] = useState(user?.skills ? user?.skills?.split(",") : [])
    const [skill, setSkill] = useState('')
    const [pageLoad, setPageLoad] = useState(user?.id ? false : true)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        email: '',
        phone: '',
    })

    useEffect(() => {
        if (user) {
            setToggleOn(user?.freelance);
            setData({ email: user?.work_email, phone: user?.work_number })
            setPageLoad(false)
        }
    }, [user])

    const handleToggle = () => {
        setToggleOn(!toggleOn)
        if (toggleOn === true) {
            updateHireStatus(false)
        } else {
            updateHireStatus(true)
        }
    }

    const updateHireStatus = async (toggleValue) => {
        const res = await handleFreelance({ freelance: toggleValue });
        if (res.success && res.data != null) {
            let userInfo = { ...user, freelance: toggleValue }
            dispatch(userDetails(userInfo));
            toast.success("Status Updated Successfully", { id: 'success' })
        }
        else {
            setToggleOn(user?.freelance)
            toast.error("Unable to update the status", { id: 'error' });
        }
    }

    const onSave = async (e) => {
        e?.preventDefault();

        if (!data?.email || !validator.isEmail(data?.email)) {
            return toast.error("Please Enter a valid email", { id: 'error' })
        }
        if (!skills?.length) {
            return toast.error("Add at least one skill", { id: 'error' })
        }

        if (data?.email == user?.work_email && data?.phone == user?.work_number && user?.skills == skills?.join(",")) {
            return toast("Information upto dated")
        }

        setLoading(true);
        let res = await handleFreelanceData({ work_email: data.email, work_number: data.phone, skills: skills?.join(",") })
        if (res.success && res.data != null) {
            let userInfo = { ...user, work_email: data.email, work_number: data.phone, skills: skills?.join(",") }
            dispatch(userDetails(userInfo));
            toast.success("Freelance Info Updated", { id: 'success' })
        }
        else {
            toast.error("Unable to update the freelance", { id: 'error' });
        }
        setLoading(false);
    }

    const updateLocation = async () => {
        if (user?.timeZone != Intl.DateTimeFormat().resolvedOptions().timeZone) {
            let res = await handleFreelanceData({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            if (res.success && res.data != null) {
                let userInfo = { ...user, time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone }
                dispatch(userDetails(userInfo));
                toast.success("Location Info Updated", { id: 'success' })
            }
            else {
                toast.error("Unable to update the location", { id: 'error' });
            }
        }
        else {
            toast.success("Location Already Upto date", { id: 'success' })
        }
    }

    return (
        <>
            <h1 className="text-2xl font-semibold text-left mb-3">Hire me</h1>
            <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                {
                    pageLoad ?
                        <div className='w-full min-h-[50vh] grid place-items-center'>
                            <div className='border-8 border-blue-500 border-r-white animate-spin rounded-full w-20 h-20 flex-shrink-0'></div>
                        </div>
                        :
                        <>

                            <h1 className="text-lg font-semibold">Are you open to freelancing opportunities through Greyffiti?</h1>
                            <div className='flex justify-end w-full'>
                                <button className={`w-28 h-9 rounded-full px-1.5 relative ${toggleOn ? 'bg-green' : 'bg-red'}`} onClick={handleToggle}>
                                    <div className={`w-12 h-6 rounded-full bg-white ${toggleOn ? 'translate-x-12' : 'translate-x-0'} transform transition-all duration-300 text-sm flex items-center justify-center`}>{toggleOn ? 'Yes' : 'No'}</div>
                                </button>
                            </div>

                            {
                                toggleOn ?
                                    <>
                                        <div className='bg-white border mt-4 py-1 px-3 rounded text-gray-500'>
                                            <span className='text-sm'>Work email</span>
                                            <input value={data.email || ''} onChange={(e) => setData({ ...data, email: e.target.value })} type="email" className='p-1 border-none outline-none w-full text-gray-800' />
                                        </div>
                                        <div className='bg-white border mt-4 py-1 px-3 rounded text-gray-500'>
                                            <span className='text-sm'>Work Phone number <span className='text-gray-300'>(Optional)</span></span>
                                            <input value={data.phone || ''} onChange={(e) => setData({ ...data, phone: e.target.value })} type="text" className='p-1 border-none outline-none w-full text-gray-800' />
                                        </div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            if (skill) {
                                                setSkills([...skills, skill]);
                                                setSkill('')
                                            }
                                        }} className='bg-white border mt-4 py-1 px-3 rounded text-gray-500 min-h-[120px] relative'>
                                            <p className='text-sm'>I can help you with</p>
                                            <div className='flex flex-wrap gap-2 py-3'>
                                                {skills?.map((skill, i) => <div key={i} className='bg-white rounded-full shadow py-1 px-3 flex items-center gap-1'>
                                                    <span className='text-gray-800'>{skill}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-rose-500 cursor-pointer"
                                                        onClick={() => {
                                                            let arr = [...skills]
                                                            arr.splice(i, 1)
                                                            setSkills(arr)
                                                        }}
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>

                                                </div>)}
                                                {skills?.length < 7 && <input value={skill || ''} onChange={(e) => setSkill(e.target.value)} placeholder='Type here...' type="text" className='p-1 px-3 rounded-full border-none outline-none w-full max-w-max text-gray-800' />}
                                            </div>
                                            <input type="submit" className='hidden' />
                                            <span className='absolute bottom-2 right-4 text-lg tracking-widest text-gray-300 font-medium'>
                                                {skills?.length}/7
                                            </span>
                                        </form>
                                        <p className='text-gray-600 text-sm mt-1'>Enter skills such as Web design</p>

                                        <div className='py-5 flex items-center gap-4'>
                                            <h1 className='text-xl text-gray-600'>Location: <span className='font-medium'>{user?.time_zone ? getTimeZoneDetails(user?.time_zone)?.userCountry : 'None'}</span></h1>
                                            <button className='py-2 px-5 rounded-lg bg-green text-white' onClick={updateLocation}>Update Location</button>
                                        </div>

                                        <div className='w-full flex justify-end'>
                                            <button disabled={loading} onClick={onSave} className='bg-blue disabled:bg-gray-300 text-white w-36 py-2 rounded font-medium flex items-center justify-center'>
                                                {
                                                    loading ?
                                                        <span className='border-4 border-gray-300 border-r-white animate-spin rounded-full w-6 h-6 flex-shrink-0'></span>
                                                        :
                                                        <span>Save</span>
                                                }
                                            </button>
                                        </div>

                                    </>
                                    :
                                    <></>
                            }
                        </>
                }
            </div>
        </>
    )
}

export default Hire