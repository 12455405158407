const displayAction = (recentActivityAction, recentActivityType) => {
    let action = ''
    if (recentActivityAction === 'update') action = 'updated'
    else if (recentActivityAction === 'edit') action = 'edited'
    else if (recentActivityAction === 'delete') action = 'deleted'
    else if (
        (recentActivityAction === 'add' || recentActivityAction == 'added') &&
        (recentActivityType === 'repository' ||
            recentActivityType === 'project' ||
            recentActivityType === 'branch' ||
            recentActivityType === 'file' ||
            recentActivityType === 'folder')
    )
        action = 'created'
    else if ((recentActivityAction === 'add' || recentActivityAction == 'added') && recentActivityType === 'file')
        action = 'uploaded to'
    else if (recentActivityAction === 'sync') action = 'synced to'
    else if (
        recentActivityAction === 'deleted' &&
        recentActivityType === 'file'
    )
        action = 'deleted in'
    return action
}

export default displayAction