import validator from "validator"

const linkedInRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(linkedin))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
const twitterRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(twitter))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
const dribbbleRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(dribbble))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
const instagramRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(instagram))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
const pinterestRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(pinterest))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
const gitHubRegEx = new RegExp(
  '(((http(s?)://)(www.)|(http(s?)://))(github))+\\.[a-zA-Z0-9/~\\-_,&=\\?\\.;]+[^\\.,\\s<]'
)
export const urlValidator = (url, field) => {
  let isValid = false
  if (field === 'instagram') {
    if (instagramRegEx.test(url)) isValid = true
  }

  if (field === 'linkedin') {
    if (linkedInRegEx.test(url)) isValid = true
  }

  if (field === 'website') {
    if (validator.isURL(url)) isValid = true
  }

  if (field === 'twitter') {
    if (twitterRegEx.test(url)) isValid = true
  }

  if (field === 'dribbble') {
    if (dribbbleRegEx.test(url)) isValid = true
  }

  if (field === 'pinterest') {
    if (pinterestRegEx.test(url)) isValid = true
  }

  if (field === 'gitHub') {
    if (gitHubRegEx.test(url)) isValid = true
  }
  return isValid
}
