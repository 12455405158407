import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getDecodedToken, getUserPlan } from "./service/service";
import { userBasicDetail, userDetail } from "./service/service";
import { userBasicInfo, userDetails } from "./slices/userSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const PublicRoute = ({ component: Component, restricted, path, ...rest }) => {
  const auth = getDecodedToken();
  const verified = auth.isVerified;
  const verifyPath = path;
  const urlPath = window.location.pathname;

  const dispatch = useDispatch()

  useEffect(() => getUserBasicInfo(), [])

  async function getUserBasicInfo() {
    const info = await userBasicDetail();
    if (info.data)
      dispatch(userBasicInfo(info.data))

    const details = await userDetail();
    if (details.data != null) {
      let userInfo = details.data
      const planData = await getUserPlan();
      userInfo.plan = planData.data
      dispatch(userDetails(userInfo));
    }

  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth && verified && restricted ? (
          <Redirect to="/dashboard" />
        ) : !auth && verifyPath === "/verifyEmail" ? (
          <Redirect to="/login" />
        ) : !auth && urlPath === "/termsAndConditions/true" ? (
          <Redirect to="/login" />
        ) : !auth && verifyPath === "/notifyEmail" ? (
          <Redirect to="/login" />
        ) : !auth && verifyPath === "/emailVerified/:key" ? (
          <Redirect to="/login" />
        ) : !auth && verifyPath === "/userProfile" ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
