import { CloseRounded } from '@material-ui/icons';
import React from 'react'
import { useHistory } from 'react-router-dom';
import UserBordered from '../../assets/icons/UserBordered';

const UserRow = ({ user }) => {
    const history = useHistory()
    return <div className='flex items-center justify-between w-full gap-8 my-4 border px-4 py-2 rounded-lg'>
        {
            user.profile_pic ?
                <img className='w-10 h-10 rounded-full' src={user.profile_pic} alt='' />
                : <UserBordered className='w-10 h-10 rounded-full flex-shrink-0' />
        }
        <div style={{ flexGrow: 1 }}>
            <p className='text-sm sm:text-lg font-semibold text-gray-800'>{user.username}</p>
            <p className='text-gray-500 text-xs sm:text-base'>{user.name || user.contributor_name || ''}</p>
        </div>
        <button className='bg-blue rounded-lg px-5 py-1.5 font-medium text-white capitalize' onClick={() => history.push(`/userProfile/${user.username}`)}>
            <span className='hidden sm:inline-block'>View</span> Profile
        </button>
    </div>
}

function ContributorList({ contributors, onClose }) {
    return (
        <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1001 }}>
            <div className='max-w-xl bg-white p-5 w-full rounded-md h-[80vh] flex flex-col overflow-y-auto'>
                <div className='pb-1 border-b flex items-center justify-between gap-2 relative'>
                    <h1 className='text-xl font-semibold text-gray-900'>Contributors</h1>
                    <CloseRounded fontSize='large' className='cursor-pointer' onClick={onClose} />
                </div>
                <div style={{ width: '100%', padding: '10px', flexGrow: 1 }}>
                    {contributors.length > 0 ?
                        contributors.map(user => <UserRow key={user.contributer_id} user={user} />)
                        :
                        <p className='text-center text-2xl font-semibold text-gray-500 my-6'>No Contributor available</p>
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', padding: '10px', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                    <button type='submit' className='bg-red py-1.5 px-6 rounded text-lg text-white' onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContributorList