import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import Loader from '../ReusableComponents/Loader'
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import Navbar from '../ReusableComponents/Navbar'
import { getUserByUsername } from '../../service/service';
import { useHistory } from 'react-router-dom';
import { Github, Instagram, LinkedIn, Pinterest, Dribbble, Twitter } from '../../assets/icons';


function Profile() {
    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const me = useSelector(selectUserDetails);
    const [user, setUser] = useState();
    const getUserInfo = async (username) => {
        const userInfo = await getUserByUsername(username);
        console.log(userInfo)
        if (userInfo?.data) {
            setUser(userInfo?.data)
        }
        else {
            history.push("/404")
        }
        setLoading(false)
    }

    useEffect(() => { if (me) { getUserInfo(me.username) } }, [me])

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <Navbar background='#F6FAFD' />
                        <section className='w-full min-h-screen bg-[#F6FAFD]'>
                            <div>
                                <img
                                    src={user?.banner_pic || UserBannerImage}
                                    alt='banner'
                                    className='w-full h-44 object-cover z-10'
                                />
                            </div>
                            <div className='w-full max-w-lg mx-auto px-5 lg:px-10 pb-4 hidden lg:block'>
                                <div className='grid place-items-center -mt-20'>
                                    {
                                        !user?.profile_pic ?
                                            <DefaultUserIcon className={`w-40 h-40 z-20 shadow-lg rounded-full border-8 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                            :
                                            <img src={user?.profile_pic} alt='profile_pic' className={`w-40 h-40 z-20 shadow-lg rounded-full border-8 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                    }
                                </div>
                                <h1 className='text-2xl font-medium text-navy text-center my-2'>{user?.username}</h1>
                                {user?.profession && <p className='text-gray-600 text-center'>
                                    {user.profession}
                                </p>}
                                <div className='my-4'>
                                    <span className='text-base font-medium text-gray-600'>Bio</span>
                                    <p className='text-sm text-gray-700 line-clamp-2'>
                                        {user?.bio || '-'}
                                    </p>
                                </div>

                                <div className='my-2'>
                                    <span className='text-base font-medium text-gray-600 block'>Website</span>
                                    {user?.socials?.website ? <a className='text-blue hover:text-blue-700 flex items-center gap-1' target="_blank" href={user?.socials?.website}><span>Visit Website </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                        </svg>
                                    </a> : <p>-</p>}
                                </div>

                                <div>
                                    <span className='text-base font-medium text-gray-600'>Socials</span>
                                    <div className='flex items-center gap-2 mt-1'>
                                        {user?.socials?.github && <a href={user?.socials?.github} target="_blank"><img className='w-5' src={Github} alt='' /></a>}
                                        {user?.socials?.dribbble && <a href={user?.socials?.dribbble} target="_blank"><img className='w-5' src={Dribbble} alt='' /></a>}
                                        {user?.socials?.twitter && <a href={user?.socials?.twitter} target="_blank"><img className='w-5' src={Twitter} alt='' /></a>}
                                        {user?.socials?.instagram && <a href={user?.socials?.instagram} target="_blank"><img className='w-5' src={Instagram} alt='' /></a>}
                                        {user?.socials?.linkedin && <a href={user?.socials?.linkedin} target="_blank"><img className='w-5' src={LinkedIn} alt='' /></a>}
                                        {user?.socials?.pinterest && <a href={user?.socials?.pinterest} target="_blank"><img className='w-5' src={Pinterest} alt='' /></a>}
                                        {(user?.socials?.github || user?.socials?.dribbble || user?.socials?.twitter || user?.socials?.instagram || user?.socials?.linkedin || user?.socials?.pinterest) ? '' : <p className='text-gray-600 block'>No Social Link</p>}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
            }
        </>
    )
}

export default Profile