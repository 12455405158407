import useRazorpay from 'react-razorpay';
import { useState } from 'react';
import { createOrder, handleFreelanceData } from '../service/service';
import toast from 'react-hot-toast';

const usePayment = () => {
    const [success, setSuccess] = useState(false)
    const [Razorpay] = useRazorpay();
    // const { mutate: createOrder } = useCreateOrder((order) => {
    //     const options = {
    //         ...order,
    //         handler: function (response) {
    //             setSuccess({
    //                 planName: order.planName,
    //                 icon: order.icon
    //             })
    //         },
    //     };

    //     const rzp1 = new Razorpay(options);

    //     rzp1.on("payment.failed", function (response) {
    //         console.log("FAILED ", response)
    //     });

    //     rzp1.open();
    // })

    const handleOrder = async (data) => {
        let res = await createOrder(data);
        if (res?.data) {
            const options = {
                ...res.data,
                handler: function (response) {
                    setSuccess({
                        planName: res.data.planName,
                    })
                    handleFreelanceData({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                    toast.success("Profile Upgraded Successfully!");
                    window.location.reload();
                },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
                console.log("FAILED ", response)
            });

            rzp1.open();
        }
        else {
            console.error("Oops! Unable to create order")
            toast("Oops! Unable to create order", { id: 'err' })
        }
    }

    return { success, setSuccess, handleOrder }
}


export default usePayment