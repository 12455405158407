import { useState, useEffect, useRef, useCallback } from 'react'
import {
  createBasicProfile,
  updateUserName,
  userBasicDetail,
  userDetail
} from '../../service/service'
import {
  nameFieldValidator,
  usernameFieldValidator
} from '../../utils/validation'
import getCroppedImg from '../../utils/cropImage'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { userBasicInfo, userDetails } from '../../slices/userSlice'

const UserProfileValidation = (props) => {
  const { refresh, handleNext } = props;
  const dispatch = useDispatch()
  const [flagLoaderButton, setFlagLoaderButton] = useState(false)
  const [count, setCount] = useState(1)
  const [countBasic, setCountBasic] = useState(1)
  const [countDetail, setCountDetail] = useState(1)

  const [fields, setFields] = useState({
    fullname: '',
    profession: '',
    bio: ''
  })
  const inputFile = useRef(null)
  const [errors, setErrors] = useState({})
  const [openBox1, setOpenBox1] = useState(false)
  const [openBox2, setOpenBox2] = useState(false)
  const [selectedPic, setSelectedPic] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [bannerFile, setBannerFile] = useState()
  const [bannerImage, setBannerImage] = useState('')
  const [oldImage, setOldImage] = useState()
  const [oldBannerImage, setOldBannerImage] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [loading, setLoading] = useState(true)
  const [lineProgress, setLineProgress] = useState(0)
  const [validName, setValidName] = useState(true)
  const [username, setUsername] = useState('')
  const [progressDisplay, setProgressDisplay] = useState(false)


  useEffect(() => {
    getUserBasicInfo()
    getUserDetails()
  }, [countBasic])

  const handleChange = e => {
    errors.fullname = ' '
    errors.bio = ' '
    errors.username = ' '
    if (e.preventDefault)
      e?.preventDefault()
    const { id } = e.target
    let newValue = e.target.value
    if (e.target.id.match('username')) {
      if (!usernameFieldValidator(newValue)) {
        newValue = newValue.replace(/[^[ A-Za-z0-9_-]]/gi, '')
        errors.username = 'No special characters except "-" and "_" are allowed'
        toast.error('No special characters except "-" and "_" are allowed in username', { id: 'error' })
      } else if (newValue.length >= 20) {
        errors.username = 'Maximum of 20 chracters'
        toast.error('Username must be less than 20 chracters', { id: 'error' })
      }
    }
    if (e.target.id.match('fullname')) {
      if (!nameFieldValidator(newValue)) {
        newValue = newValue.replace(/[^A-Za-z ]/gi, '')
        errors.fullname = 'No special characters except "-" and "_" are allowed'
        toast.error('No special characters except "-" and "_" are allowed in name', { id: 'error' })
      } else if (newValue.length >= 50) {
        errors.fullname = 'Maximum of 50 characters'
        toast.error('Name must be less than 50 characters', { id: 'error' })
      }
    }

    if (e.target.id.match('bio')) {
      if (newValue.length >= 120) {
        errors.bio = 'Maximum of 120 characters'
        toast.error('Maximum of 120 characters allowed in bio', { id: 'error' })
      }
    }
    setErrors(errors)
    if (e.target.id.match('username')) {
      if (usernameFieldValidator(newValue))
        setUsername(newValue)
    }
    // setUsername(newValue)
    setFields(prevState => ({
      ...prevState,
      [id]: newValue
    }))
  }

  async function getUserDetails() {
    if (countDetail === 1) {
      const res = await userDetail();
      if (res.data !== null) {
        const name = res.data.name;

        if (res.data.username) {
          setUsername(res.data.username)
        }

        let data = {
          fullname: name,
          email: res.data.email
        }
        if (res.data.profession) {
          data.profession = res.data.profession
        }

        setFields(prevState => ({
          ...prevState,
          ...data
        }))
      }
      setCountDetail(countDetail + 1)
    }
  }

  async function getUserBasicInfo() {
    if (countBasic === 1) {
      const res = await userBasicDetail()

      if (res.data !== null) {
        const bioText = res.data.bio
        const profession = res.data.profession
        setBannerImage(res.data.banner_pic)
        setProfileImage(res.data.profile_pic)
        setFields(prevState => ({
          ...prevState,
          bio: bioText,
          profession: profession
        }))
      }
      setCountBasic(countBasic + 1)
    }
    setLoading(false)
  }

  const validation = () => {
    let formIsValid = true
    if (fields.fullname === '') {
      formIsValid = false
    } else {
      formIsValid = true
    }
    return formIsValid
  }

  async function createProfileAPI(croppedImage = '', defaultBannerParam, save) {
    let name = fields.fullname
    let profession = fields.profession
    let bio = fields.bio
    setFlagLoaderButton(true)
    const formData = new FormData()
    formData.append('name', name)
    formData.append('profession', profession)
    formData.append('bio', bio);
    const blobFile = new File([croppedImage], 'ABCD.jpeg', {
      type: 'image/jpeg'
    })

    if (defaultBannerParam) {
      formData.append('default', '1')
    } else if (
      !defaultBannerParam &&
      selectedPic === 'banner' &&
      bannerFile !== ''
    ) {
      formData.append('banner_pic', bannerFile)
    }
    else {
      formData.append('banner_pic', '')
    }

    if (profileImage !== '' && croppedImage !== '') {
      formData.append('profile_pic', blobFile)
    }
    else {
      formData.append('profile_pic', '')
    }
    if (username) {
      let data = { name: username };
      const res = await updateUserName(data);
      if (res?.success) {
        const details = await userDetail();
        if (details.data != null)
          dispatch(userDetails(details.data));
      }
    }
    const res = await createBasicProfile(formData)
    setLineProgress(100)

    if (res.data !== null) {
      toast.success('Basic Info Updated Successfully', { id: 'success' })
      setOpenBox1(false)
      const info = await userBasicDetail();
      if (info?.data) {
        dispatch(userBasicInfo(info.data))
      }
    }
    if (refresh) refresh()
    setFlagLoaderButton(false);

    if (handleNext && save == 'save') handleNext()
  }
  const selectImg = e => {
    setProgressDisplay(false)
    setLineProgress(0)
    const { files } = e.target
    if (files && files.length) {
      const filePath = URL.createObjectURL(files[0])
      if (selectedPic === 'banner') {
        setBannerImage(filePath)
        setBannerFile(files[0])
        setOldBannerImage(bannerImage)
        openUploadBanner()
      } else if (selectedPic === 'profile') {
        setProfileImage(filePath)
        setOldImage(profileImage)
        openUploadProfile()
      }
    }
    e.target.value = null
  }

  const openUploadBanner = () => {
    setOpenBox1(true)
  }

  const closeUploadBanner = () => {
    setBannerFile('')
    setBannerImage(oldBannerImage)
    setOpenBox1(false)
  }

  const openUploadProfile = () => {
    setOpenBox2(true)
  }
  const closeUploadProfile = () => {
    setProfileImage(oldImage)
    setOpenBox2(false)
  }
  const defaultBanner = () => {
    setBannerFile('')
    setBannerImage('')
    const defaultParam = true
    createProfileAPI('', defaultParam)
    setOpenBox1(false)
    toast.success('Banner Image Changed to Default', { id: 'success' })
  }
  const saveBanner = () => {
    setProgressDisplay(true)
    setLineProgress(60);
    toast('Saving Banner Image', { id: 'warn' })
    setLineProgress(80)
    createProfileAPI()
  }
  const submitUserForm = e => {
    e.preventDefault();
    if (validation()) {
      if (!fields?.profession) { return toast.error("Profession is required", { id: 'error' }); }
      if (!fields?.fullname) { return toast.error("Name is required", { id: 'error' }); }
      createProfileAPI('', '', 'save')
    } else if (fields.fullname === '') {
      setValidName(false)
    }
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    setProgressDisplay(true)
    setLineProgress(60)
    try {
      const croppedImage = await getCroppedImg(
        profileImage,
        croppedAreaPixels,
        0
      )
      const url = URL.createObjectURL(
        new Blob([croppedImage], { type: 'image/jpeg' })
      )
      setProfileImage(url)
      setLineProgress(80)
      await createProfileAPI(croppedImage)
    } catch (e) {
      console.error(e)
    }
    setOpenBox2(false)
  }, [croppedAreaPixels])

  return {
    loading,
    bannerImage,
    onButtonClick,
    errors,
    setSelectedPic,
    inputFile,
    selectImg,
    openBox1,
    closeUploadBanner,
    saveBanner,
    defaultBanner,
    lineProgress,
    setZoom,
    profileImage,
    openBox2,
    closeUploadProfile,
    showCroppedImage,
    crop,
    setCrop,
    zoom,
    onCropComplete,
    fields,
    handleChange,
    flagLoaderButton,
    submitUserForm,
    validName,
    progressDisplay,
    username
  }
}
export default UserProfileValidation
