import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import { Github, Instagram, LinkedIn, Pinterest, Dribbble, Twitter } from '../../assets/icons';
import HeartIcon from '../../assets/images/heartIcon.svg'
import Float from '../../assets/images/floatImage.png'
import { getProjectsListBySpotlight, getPublicProjects, getPublicRepository } from '../../service/service'
import ProjectSpotlight from '../Project/ProjectSpotlight';
import PublicProjects from '../Project/PublicProjects';
import PublicRepository from '../Repository/PublicRepository';
import getTimeZoneDetails from '../../utils/getTimeZone';
import { Viewer } from '@react-pdf-viewer/core';
import { CloseOutlined } from '@material-ui/icons';

let sortOptions = [
    {
        id: 1,
        name: 'Name',
    },
    {
        id: 2,
        name: 'Date Created',
    },
    {
        id: 3,
        name: 'Date Updated',
    },
]

function ProUser({ me, user, free, addFollow, withdrawFollow, stats }) {
    const history = useHistory()
    const [showcase, setShowcase] = useState(['', '', ''])
    const [counts, setCounts] = useState({
        followers: stats?.followers || 0,
        following: stats?.following || 0,
        projects: stats?.projects || 0,
        sprays: 0
    })
    const [active, setActive] = useState('portfolio');
    const [sortBy, setSortBy] = useState(1);
    const [hireClick, setHireClick] = useState(false)
    const [pdf, setPdf] = useState({
        isOpen: false,
        pdfUrl: '',
        title: ''
    })

    useEffect(() => {
        if (user) {
            if (user?.showcase) {
                setShowcase(user?.showcase?.split(","))
            }
        }
    }, [user])

    useEffect(() => {
        setCounts({
            followers: stats?.followers || 0,
            following: stats?.following || 0,
            projects: stats?.projects || 0,
            sprays: 0
        })
    }, [stats])

    const [projects, setProjects] = useState({
        publicProjects: [],
        spotProjects: [],
        publicRepos: []
    });

    const getProjects = async (username) => {
        const projects = await getPublicProjects(username);
        const spotProjects = await getProjectsListBySpotlight(username);
        const repositories = await getPublicRepository(username);

        let data = {
            publicProjects: [],
            spotProjects: [],
            publicRepos: []
        }
        if (projects.data) {
            data.publicProjects = projects.data;
        }
        if (spotProjects.data)
            data.spotProjects = spotProjects.data;

        if (repositories.data)
            data.publicRepos = repositories.data

        setProjects(data)
    }
    useEffect(() => {
        if (user?.username) {
            getProjects(user?.username)
        }
    }, [user?.username]);


    const sort = (a, b) => {
        if (sortBy == 1) {
            return a.name.localeCompare(b.name)
        } else if (sortBy == 2) {
            return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        }
        else if (sortBy == 3) {
            return (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())
        }
        else
            return a.name.localeCompare(b.name)
    }

    return (
        <section>

            <div className='w-full h-44 border-b-2'>
                <img
                    src={
                        !user?.banner_pic
                            ? UserBannerImage
                            : user?.banner_pic
                    }
                    alt='banner'
                    className='w-full h-full object-cover z-10'
                />
            </div>
            <div className='flex items-start w-full max-w-7xl mx-auto p-5 xl:px-10'>
                <div className='w-1/2'>
                    <div className='flex items-start gap-6 border-r-2'>
                        <div className=''>
                            <div className='w-28 h-28 transform scale-150 -translate-y-10 translate-x-0'>
                                {
                                    !user?.profile_pic ?
                                        <DefaultUserIcon className={`w-full h-full z-20 shadow-lg rounded-full border-4 bg-white ${user?.toggle_state == "off" ? 'border-gray-300' : user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                        :
                                        <img src={user?.profile_pic} alt='profile_pic' className={`w-full h-full z-20 shadow-lg rounded-full border-4 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                }
                            </div>
                            <div className='flex items-center gap-3'>
                                <p className='bg-yellow py-1 px-6 font-medium rounded-full'>Pro User</p>
                                <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.869584 27.3838L26.4364 29.3321L28.3315 19.3421L11.2458 0.717197L0.912938 1.50919L0.869584 27.3838Z" fill="#FFC258" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M28.3313 19.3417L0.869391 27.3834L11.2456 0.716797" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M1.66406 1.89648L26.3141 29.1064" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.95102 9.18497L19.7867 10.0312L19.6128 21.8973" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M31.9175 24.4961C36.0153 20.737 36.4708 17.1205 33.284 13.6465C36.4708 17.1205 40.1131 16.9779 44.2109 13.2187C40.1131 16.9779 39.6576 20.5944 42.8445 24.0683C39.6576 20.5944 36.0153 20.737 31.9175 24.4961Z" fill="#DB614D" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>

                        <div className=''>
                            <div className='flex items-center gap-3'>
                                <h1 className='text-navy text-3xl font-bold'>{user?.username}</h1>
                                <div className={` w-5 h-5 rounded-full ${user?.status_type == 'online' ? 'bg-green-400' : user?.status_type == 'offline' ? 'bg-red-400' : user?.status_type == 'working' ? 'bg-blue-400' : user?.status_type == 'customized' ? 'bg-yellow-400' : ''}`}></div>
                            </div>
                            <p className='text-gray-500 text-xl capitalize'>{user?.organization?.name}</p>
                            <p className='text-gray-300 text-lg capitalize'>{user?.skills?.split(",")?.slice(0, 3)?.join(" | ")}</p>
                            {/* {user?.freelance ? <div className='flex py-3 gap-2'>
                                <img src={HeartIcon} className='w-5' alt="" />
                                <img src={HeartIcon} className='w-5' alt="" />
                                <img src={HeartIcon} className='w-5' alt="" />
                                <img src={HeartIcon} className='w-5' alt="" />
                                <img src={HeartIcon} className='w-5' alt="" />
                            </div> : <></>} */}
                        </div>
                    </div>

                    <div className='p-5 rounded-xl border border-teal-500 text-navy mt-8 mr-6'>
                        <h1 className='font-medium'>👋 Hello I am {user?.name}</h1>
                        <p className='mt-4'>{user?.bio || '-'}</p>
                    </div>

                    {user?.skills?.split(",")?.length && user?.freelance ? <div className='p-5 rounded-xl border border-teal-500 text-navy mt-8 mr-6'>
                        <h1 className='font-medium'>🔧  I can help you with</h1>
                        <p className='mt-4'>{user?.skills?.split(",")?.join(", ")}</p>
                    </div> : ''}

                    <div className='py-6 flex items-center gap-4'>
                        {(me && me?.id == user?.id) ? <button onClick={() => history.push('/userProfile/edit')} className='bg-blue text-white py-1.5 px-8 uppercase rounded-full'>Edit Profile</button> :

                            user?.freelance ?
                                <>
                                    <button onClick={() => user.following ? withdrawFollow() : addFollow()} className='bg-green text-white py-1.5 px-8 uppercase rounded-full'>{user.following ? 'Following' : 'Follow'}</button>
                                    <button className='text-green border border-teal-500 bg-white py-1.5 px-8 uppercase rounded-full' onClick={() => setHireClick(true)}>Hire me</button>
                                </>
                                :
                                <button onClick={() => user.following ? withdrawFollow() : addFollow()} className='bg-green text-white py-1.5 px-8 uppercase rounded-full'>{user.following ? 'Following' : 'Follow'}</button>
                        }
                    </div>
                </div>

                <div className='pl-5 w-1/2'>
                    <div className='my-2 flex items-center border-b-2 w-full'>
                        <div className='flex-grow p-2 border-r-2'>
                            <p className='text-base text-center text-gray-500'>Followers</p>
                            <p className='text-3xl font-semibold text-blue text-center'>{counts?.followers}</p>
                        </div>
                        <div className='flex-grow p-2 border-r-2'>
                            <p className='text-base text-center text-gray-500'>Following</p>
                            <p className='text-3xl font-semibold text-green text-center'>{counts?.following}</p>
                        </div>
                        <div className='flex-grow p-2 border-r-2'>
                            <p className='text-base text-center text-gray-500'>Projects</p>
                            <p className='text-3xl font-semibold text-red text-center'>{counts?.projects}</p>
                        </div>
                        <div className='flex-grow p-2'>
                            <p className='text-base text-center text-gray-500'>Sprays</p>
                            <p className='text-3xl font-semibold text-yellow text-center'>{counts?.sprays}</p>
                        </div>
                    </div>

                    <div>
                        <div className='flex items-center gap-2 p-2'>
                            {user?.socials?.website ? <a className='text-navy hover:text-blue-900 flex' target="_blank" rel="noreferrer" href={user?.socials?.website}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                            </a> : ''}
                            {user?.socials?.github && <a href={user?.socials?.github} target="_blank" rel="noreferrer"><img className='w-7' src={Github} alt='' /></a>}
                            {user?.socials?.dribbble && <a href={user?.socials?.dribbble} target="_blank" rel="noreferrer"><img className='w-7' src={Dribbble} alt='' /></a>}
                            {user?.socials?.twitter && <a href={user?.socials?.twitter} target="_blank" rel="noreferrer"><img className='w-7' src={Twitter} alt='' /></a>}
                            {user?.socials?.instagram && <a href={user?.socials?.instagram} target="_blank" rel="noreferrer"><img className='w-7' src={Instagram} alt='' /></a>}
                            {user?.socials?.linkedin && <a href={user?.socials?.linkedin} target="_blank" rel="noreferrer"><img className='w-7' src={LinkedIn} alt='' /></a>}
                            {user?.socials?.pinterest && <a href={user?.socials?.pinterest} target="_blank" rel="noreferrer"><img className='w-7' src={Pinterest} alt='' /></a>}
                            {(user?.socials?.github || user?.socials?.dribbble || user?.socials?.twitter || user?.socials?.instagram || user?.socials?.linkedin || user?.socials?.pinterest) ? '' : <p className='text-gray-600 block'>No Social Link</p>}
                        </div>
                        <div className='flex items-baseline gap-3 relative'>
                            <div className='w-80 h-80 rounded-lg bg-gray-300 cursor-pointer overflow-hidden grid place-items-center'>
                                {showcase[0] ? <img className='w-full h-full object-cover' src={typeof (showcase[0]) == 'object' ? URL.createObjectURL(showcase[0]) : showcase[0]} alt='' />
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-gray-800">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>
                                }
                            </div>

                            <div>
                                <div className='w-60 h-40 rounded-lg bg-gray-300 cursor-pointer overflow-hidden grid place-items-center'>
                                    {showcase[1] ? <img className='w-full h-full object-cover' src={typeof (showcase[1]) == 'object' ? URL.createObjectURL(showcase[1]) : showcase[1]} alt='' />
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-gray-800">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                    }
                                </div>
                                <div className='w-40 h-40 rounded-lg bg-gray-300 mt-3 cursor-pointer overflow-hidden grid place-items-center'>
                                    {showcase[2] ? <img className='w-full h-full object-cover' src={typeof (showcase[2]) == 'object' ? URL.createObjectURL(showcase[2]) : showcase[2]} alt='' />
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-gray-800">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                    }
                                </div>
                            </div>
                            {/* <img src={Float} className=' absolute -bottom-10 right-16 w-20' alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className='flex items-center justify-between w-full max-w-7xl mx-auto p-5 xl:px-10 mt-10'>
                <div className='text-navy flex items-center gap-8 text-lg'>
                    <button className={`${active == 'portfolio' ? 'font-medium' : 'font-normal'} outline-none`} onClick={() => setActive('portfolio')}>Portfolio</button>
                    <button className={`${active == 'spotlight' ? 'font-medium' : 'font-normal'} outline-none`} onClick={() => setActive('spotlight')}>Spotlight</button>
                    <button className={`${active == 'projects' ? 'font-medium' : 'font-normal'} outline-none`} onClick={() => setActive('projects')}>Projects</button>
                    <button className={`${active == 'repositories' ? 'font-medium' : 'font-normal'} outline-none`} onClick={() => setActive('repositories')}>Repositories</button>
                    {user?.freelance ? <button className={`${active == 'rating' ? 'font-medium' : 'font-normal'} outline-none`} onClick={() => setActive('rating')}>Customer Rating</button> : ''}
                </div>
                <div className='border rounded flex items-center gap-4 w-40 py-1 px-4 relative group'>
                    <h1 className='flex-grow text-gray-400 tracking-wide cursor-pointer'>Sort by</h1>
                    <svg className='cursor-pointer' width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.46967 6.13033C5.76256 6.42322 6.23744 6.42322 6.53033 6.13033L11.3033 1.35736C11.5962 1.06447 11.5962 0.589592 11.3033 0.296699C11.0104 0.0038057 10.5355 0.00380572 10.2426 0.296699L6 4.53934L1.75736 0.296699C1.46447 0.00380603 0.989592 0.00380605 0.696699 0.296699C0.403806 0.589593 0.403806 1.06447 0.696699 1.35736L5.46967 6.13033ZM5.25 4L5.25 5.6L6.75 5.6L6.75 4L5.25 4Z" fill="#7F92A4" />
                    </svg>
                    <div className='p-3 w-full rounded-lg shadow-lg min-w-max absolute top-9 right-0 bg-white hidden group-hover:block z-20'>
                        {
                            sortOptions?.map(item => <h1 key={item?.id} className={`cursor-pointer py-1 border-b last:border-b-0 ${item?.id == sortBy ? 'font-medium' : ''}`} onClick={() => setSortBy(item?.id)}>{item?.name}</h1>)
                        }
                    </div>
                </div>
            </div>
            <hr className='w-full max-w-7xl mx-auto rounded-lg bg-navy h-0.5' />
            <div className='p-5 xl:px-10 max-w-[90rem] mx-auto mt-5 min-h-[50dvh]'>

                {
                    active == "portfolio" ?
                        user?.portfolio
                            ?

                            <div className='rounded-lg w-full h-[400px] max-w-2xl mx-auto hover:border hover:shadow-lg pb-2 group transition-all duration-100 ease-out'>
                                <div className='w-full h-[400px] relative overflow-y-scroll cursor-pointer rounded-lg' onClick={() => setPdf({ isOpen: true, pdfUrl: user?.portfolio, title: 'Portfolio' })}>
                                    <Viewer
                                        fileUrl={user?.portfolio}
                                    />
                                </div>
                            </div >
                            :
                            <h1 className='my-5 text-3xl font-medium text-center text-gray-300'>No Proftfolio Added yet</h1>
                        :

                        active == "spotlight" ? <ProjectSpotlight free={free} projects={projects.spotProjects?.sort((a, b) => sort(a, b))} /> :
                            active == "projects" ? <PublicProjects free={free} projects={projects.publicProjects?.sort((a, b) => sort(a, b))} /> :
                                active == "repositories" ? <PublicRepository free={free} repos={projects.publicRepos?.sort((a, b) => sort(a, b))} /> : <></>
                }
            </div>
            {hireClick ? <HirePopUp user={user} onCancel={() => setHireClick(false)} /> : <></>}

            {pdf.isOpen ? <><div className='fixed top-0 left-0 w-full min-h-screen bg-black bg-opacity-60' style={{ zIndex: 90 }} onClick={() => setPdf({ isOpen: false, pdfUrl: '', title: '' })}></div>
                <div className='fixed bottom-0 px-5 py-2 w-full h-[98vh] overflow-hidden rounded-t-3xl bg-white transition-y' style={{ zIndex: 95 }}>
                    <div className='w-full flex items-center justify-between p-2 border-b'>
                        <div className='flex items-center gap-3'>
                            {pdf?.title ? <h1 className='text-2xl lg:text-3xl font-semibold text-gray-900'>{pdf?.title}</h1> : ''}
                        </div>
                        <CloseOutlined onClick={() => setPdf({ isOpen: false, pdfUrl: '', title: '' })} fontSize='large' className='text-grey-800 cursor-pointer' />
                    </div>
                    <div className='flex items-start w-full justify-center gap-5'>
                        <div className='flex flex-col items-center justify-center gap-6 w-full max-w-xs py-10'>
                            <div className='w-36 h-36'>
                                {
                                    !user?.profile_pic ?
                                        <DefaultUserIcon className={`w-full h-full z-20 shadow-lg rounded-full border-4 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                        :
                                        <img src={user?.profile_pic} alt='profile_pic' className={`w-full h-full z-20 shadow-lg rounded-full border-4 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                }
                            </div>
                            <h1 className='capitalize font-medium text-xl text-gray-500 mb-4'>{user?.username}</h1>
                            {(me && me?.id == user?.id) ? <></> :
                                <>
                                    <button onClick={() => user.following ? withdrawFollow() : addFollow()} className='bg-blue text-white py-1.5 px-16 uppercase rounded-full'>{user?.following ? 'Following' : 'Follow'}</button>
                                    {
                                        user?.freelance ?
                                            <button className='text-white transition-all duration-200 bg-teal-500 py-1.5 px-16 uppercase rounded-full' onClick={() => setHireClick(true)}>Hire me</button>
                                            :
                                            <></>}</>}

                        </div>
                        <div className='w-full lg:w-2/3 xl:1/2 h-full m-2'>
                            {pdf?.pdfUrl && <Viewer
                                fileUrl={pdf?.pdfUrl}
                            />}
                        </div>
                    </div>
                </div></> : <></>}
        </section>
    )
}

export default ProUser

const HirePopUp = ({ user, onCancel }) => {
    const [emailCopy, setEmailCopy] = useState(true);
    const [numberCopy, setNumberCopy] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setEmailCopy(false);
            setNumberCopy(false);
        }, 1500)
    }, [numberCopy, emailCopy])

    return (
        <div className='fixed inset-0 bg-transparent grid place-items-center' style={{ zIndex: 97 }}>
            <div className='fixed inset-0 bg-black bg-opacity-50' onClick={onCancel}>
            </div>
            <div className='z-10 p-10 bg-white w-full max-w-3xl min-h-[50vh] rounded-lg relative'>
                <span onClick={onCancel} className='cursor-pointer absolute top-4 right-5 text-gray-600'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>

                <div className='flex items-center gap-3'>
                    <h1 className='text-navy text-3xl font-bold'>{user?.username}</h1>
                    <div className={` w-5 h-5 rounded-full ${user?.status_type == 'online' ? 'bg-green-400' : user?.status_type == 'offline' ? 'bg-red-400' : user?.status_type == 'working' ? 'bg-blue-400' : user?.status_type == 'customized' ? 'bg-yellow-400' : ''}`}></div>
                </div>
                <p className='text-gray-500 text-xl capitalize'>{user?.profession}</p>
                <p className='text-gray-300 text-lg capitalize'>{user?.skills?.split(",")?.slice(0, 3)?.join(" | ")}</p>
                <div className='w-full max-w-xl bg-gray-400 flex h-0.5 my-4'></div>

                <p className='text-gray-500 text-lg capitalize'>I work from <span className='font-medium'>{user?.time_zone ? getTimeZoneDetails(user?.time_zone)?.userCountry : 'None'}</span></p>
                <p className='text-gray-500 text-lg capitalize mt-2'>My time zone falls under <span className='font-medium'>{user?.time_zone ? getTimeZoneDetails(user?.time_zone).timeZoneHours : 'None'}</span></p>

                <div className='w-full max-w-xl bg-gray-400 flex h-0.5 my-4'></div>

                {user?.work_number ? <div className='flex items-center gap-2'>
                    <h1 className='text-gray-500 text-lg'>Ph: +91 <span className='text-blue'>{user?.work_number}</span></h1>
                    <svg onClick={() => { setNumberCopy(true); navigator.clipboard.writeText(user?.work_number) }} className='cursor-pointer' width="20" height="20" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75 16.4163C1.3375 16.4163 0.98425 16.2614 0.69025 15.9516C0.39675 15.6413 0.25 15.2684 0.25 14.833V3.74967H1.75V14.833H10V16.4163H1.75ZM4.75 13.2497C4.3375 13.2497 3.9845 13.0948 3.691 12.785C3.397 12.4746 3.25 12.1018 3.25 11.6663V2.16634C3.25 1.73092 3.397 1.35805 3.691 1.04772C3.9845 0.73791 4.3375 0.583008 4.75 0.583008H11.5C11.9125 0.583008 12.2657 0.73791 12.5597 1.04772C12.8533 1.35805 13 1.73092 13 2.16634V11.6663C13 12.1018 12.8533 12.4746 12.5597 12.785C12.2657 13.0948 11.9125 13.2497 11.5 13.2497H4.75ZM4.75 11.6663H11.5V2.16634H4.75V11.6663ZM4.75 11.6663V2.16634V11.6663Z" fill="#727272" />
                    </svg>
                    {numberCopy ? <p className='text-sm text-blue-800'>Copied!!!</p> : <></>}
                </div> : <></>}

                {user?.work_email ? <div className='flex items-center gap-2'>
                    <h1 className='text-gray-500 text-lg'>Mail: <span className='text-blue'>{user?.work_email}</span></h1>
                    <svg onClick={() => { setEmailCopy(true); navigator.clipboard.writeText(user?.work_email) }} className='cursor-pointer' width="20" height="20" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75 16.4163C1.3375 16.4163 0.98425 16.2614 0.69025 15.9516C0.39675 15.6413 0.25 15.2684 0.25 14.833V3.74967H1.75V14.833H10V16.4163H1.75ZM4.75 13.2497C4.3375 13.2497 3.9845 13.0948 3.691 12.785C3.397 12.4746 3.25 12.1018 3.25 11.6663V2.16634C3.25 1.73092 3.397 1.35805 3.691 1.04772C3.9845 0.73791 4.3375 0.583008 4.75 0.583008H11.5C11.9125 0.583008 12.2657 0.73791 12.5597 1.04772C12.8533 1.35805 13 1.73092 13 2.16634V11.6663C13 12.1018 12.8533 12.4746 12.5597 12.785C12.2657 13.0948 11.9125 13.2497 11.5 13.2497H4.75ZM4.75 11.6663H11.5V2.16634H4.75V11.6663ZM4.75 11.6663V2.16634V11.6663Z" fill="#727272" />
                    </svg>
                    {emailCopy ? <p className='text-sm text-blue-800'>Copied!!!</p> : <></>}
                </div> : <></>}

                <div className='mt-10 flex items-center w-full justify-between'>
                    <div className='flex items-center gap-2'>
                        {user?.socials?.website ? <a className='text-navy hover:text-blue-900 flex' target="_blank" rel="noreferrer" href={user?.socials?.website}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                            </svg>
                        </a> : ''}
                        {user?.socials?.github && <a href={user?.socials?.github} target="_blank" rel="noreferrer"><img className='w-7' src={Github} alt='' /></a>}
                        {user?.socials?.dribbble && <a href={user?.socials?.dribbble} target="_blank" rel="noreferrer"><img className='w-7' src={Dribbble} alt='' /></a>}
                        {user?.socials?.twitter && <a href={user?.socials?.twitter} target="_blank" rel="noreferrer"><img className='w-7' src={Twitter} alt='' /></a>}
                        {user?.socials?.instagram && <a href={user?.socials?.instagram} target="_blank" rel="noreferrer"><img className='w-7' src={Instagram} alt='' /></a>}
                        {user?.socials?.linkedin && <a href={user?.socials?.linkedin} target="_blank" rel="noreferrer"><img className='w-7' src={LinkedIn} alt='' /></a>}
                        {user?.socials?.pinterest && <a href={user?.socials?.pinterest} target="_blank" rel="noreferrer"><img className='w-7' src={Pinterest} alt='' /></a>}
                        {(user?.socials?.github || user?.socials?.dribbble || user?.socials?.twitter || user?.socials?.instagram || user?.socials?.linkedin || user?.socials?.pinterest) ? '' : <p className='text-gray-600 block'>No Social Link</p>}
                    </div>
                    {user?.work_email && <div>
                        <a href={`mailto:${user?.work_email}`} className='text-green border border-teal-500 bg-white py-2 px-8 uppercase rounded-full hover:text-teal-500'>Draft an Email</a>
                    </div>}
                </div>
            </div>
        </div>
    )
}
