import { useState } from "react";
import React from 'react'

import { login, userDetail } from "../../service/service";
import toast from "react-hot-toast";


const useForm = () => {
    const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validation = (values) => {
        let errors = {};
        if (!values.email && !values.password) {
            errors.email = " ";
            errors.password = " ";
            toast.error("Please enter all fields", { id: 'error' })
        }
        else {
            if (!values.email) {
                toast.error("email is required", { id: 'error' })
                errors.email = "Email is required";
            }
            else if (!/\S+@\S+\.\S+/.test(values.email)) {
                toast.error("Email is invalid", { id: 'error' })
                errors.email = "Email is invalid";
            }
            if (!values.password) {
                toast.error("Password is required", { id: 'error' })
                errors.password = "Password is required";
            } else if (values.password.length < 8) {
                toast.error("Password length must be greater than 8", { id: 'error' })
                errors.password = "Password must be more than Eight characters";
            }
        }

        if (Object.keys(errors).length === 0) {
            loginAPI();
        }
        return errors;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values));
    };

    async function loginAPI() {
        setFlagLoaderButton(true);
        const res = await login(values);
        if (res.data)
            localStorage.setItem("greyffiti_token", res.data)
        const userData = await userDetail(res.data);
        let AlertMessage = res.message;
        if (!res.data) {
            toast.error("Invalid Credentials", { id: 'error' })
            setShowPassword(false)
        } else {
            setValues({ email: "", password: "" });
            if (res.message === "User not confirmed") {
                if (userData.data.terms_accepted === true) {
                    toast.info(AlertMessage + ", Please check your mail and confirm", { id: 'info' })
                    window.location = "/verifyEmail";
                } else {
                    toast.info(AlertMessage + ", Please Accept terms and Condition", { id: 'info' })
                    window.location = "/termsAndConditions/true";
                }
            }
            else {
                toast.success("Logged In Successfully", { id: "success" })
                setValues({ email: "", password: "" });
                setShowPassword(false)
                window.location = '/dashboard'
            }
        }
        setFlagLoaderButton(false);
    }

    return { handleChange, handleFormSubmit, flagLoaderButton, errors, values, showPassword, handleClickShowPassword };
};

export default useForm;