import React from 'react'
import NewLoader from '../Loader/Loader'

export default function Loader() {
    function createMarkup() {
        return {
            __html: ` 
            <div style="--i:0"></div>
            <div style="--i:1"></div>
            <div style="--i:2"></div>
            <div style="--i:3"></div>
            <div style="--i:4"></div>
        `};
    }
    return <NewLoader />
    return (
        <div className='bg-white fixed h-screen top-0 left-0 w-full flex items-center justify-center' style={{ zIndex: 1000 }}>
            <div className='loader' dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
    )
}

