import React, { useEffect, useState } from 'react'
import {
    nameFieldValidator,
    descriptionFieldValidator
} from '../../utils/validation';
import './Project.css';
import { addNewProject, editProjectById, getTopicList } from '../../service/service';
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux';
import { selectProjectList } from '../../slices/projectSlice';
import { FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default function AddProjectForm({ getFreshProjectList, setAddModal, isEdit = false, projectData, username }) {
    const history = useHistory()
    const projects = useSelector(selectProjectList)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: isEdit ? projectData.name : "",
        description: isEdit ? projectData.description : "",
        project_type: isEdit ? projectData.project_type_id == 1 ? 'public' : 'private' : "public",
        project_topics: isEdit ? projectData.project_topics : ''
    })
    const [topics, setTopics] = useState([])
    const [open, setOpen] = useState(false);

    async function getTopics() {
        const res = await getTopicList();
        setTopics(res.data);
    }
    useEffect(() => { getTopics() }, [])

    const handleChange = (event) => {
        setFormData({
            ...formData,
            "project_topics": event.target.value,
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    function submit(e) {
        e.preventDefault();
        setErrors(validation(formData));
    }

    const validation = (formData) => {
        let errors = {};
        if (!formData.name) {
            toast.error("Name is Required", { id: 'error' })
            errors.name = "Name is required";
        }
        if (formData.name) {
            formData.name = formData.name.trim()
            const isExist = projects.findIndex(item => item.name?.toLowerCase() == formData.name?.toLowerCase())
            if (!isEdit && isExist > -1) {
                toast.error("Project Name already exist", { id: 'error' })
                errors.name = "Project Name already exist";
            }
            if (formData.name.length < 3) {
                toast.error("Project Name should have minimum 3 characters", { id: 'error' })
                errors.name = "Project Name should have minimum 3 characters";
            }
            if (formData.name.length > 20) {
                toast.error("Project Name can have maximum 20 characters", { id: 'error' })
                errors.name = "Project Name can have maximum 20 characters";
            }
        }
        if (formData.description) {
            if (formData.description.length < 5) {
                toast.error("Project description should have minimum 5 character", { id: 'error' })
                errors.description = "Project description should have minimum 5 characters";
            }
            if (formData.description.length > 120) {
                toast.error("Project description can have maximum 120 characters", { id: 'error' })
                errors.description = "Project description can have maximum 120 characters";
            }
        }
        if (Object.keys(errors).length === 0) {
            addProject();
        }
        return errors;
    };

    async function addProject() {
        let name = formData.name;
        let description = formData.description;
        let project_type = formData.project_type;
        let project_topics = formData.project_topics || 1;
        let data = { name, description, project_type, project_topics };
        const res = isEdit ? await editProjectById({ ...data, id: projectData.id }) : await addNewProject(data);
        let alertMessage = res.response?.data?.message
        if (!res.success) {
            toast.error(alertMessage || `Unable to ${isEdit ? 'Update' : 'Create'} the project`, { id: 'error' })
        } else {
            toast.success(`Project ${isEdit ? 'Updated' : 'Created'} Successfully`, { id: 'success' });
            if (!isEdit) {
                history.push(`/${username}/${name}`)
            }
            setFormData({ name: "", description: "" });
            getFreshProjectList();
            setAddModal(false);
        }
    }
    const handle = e => {
        errors.name = " ";
        errors.description = " ";
        const { id } = e.target
        let newValue = e.target.value

        if (e.target.id.match("name")) {
            if (!nameFieldValidator(newValue)) {
                newValue = newValue.replace(/[^ A-Za-z0-9_-]/gi, "");
                toast.error("No special characters except \"-\" and \"_\" are allowed", { id: 'error' })
                errors.name = "No special characters except \"-\" and \"_\" are allowed";
            } else if (newValue.length > 20) {
                toast.error("Maximum of 20 characters in name", { id: 'error' })
                errors.name = "Maximum of 20 characters"
            }
        }
        if (e.target.id.match("description")) {
            if (!descriptionFieldValidator(newValue)) {
                newValue = newValue.replace(/[^ A-Za-z0-9]/gi, "");
                toast.error("No special characters are allowed", { id: 'error' })
                errors.description = "No special characters are allowed";
            } else if (newValue.length > 120) {
                toast.error("Maximum of 120 characters in description", { id: 'error' })
                errors.description = "Maximum of 120 characters"
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [id]: newValue
        }))
    };

    const selectOne = (event) => {
        let ptype = event.target.value;
        setFormData({
            ...formData,
            "project_type": ptype,
        });
    };


    return (
        <>
            <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
                <div className='max-w-lg bg-white p-5 rounded-md w-full'>
                    <form onSubmit={submit} className='p-5 w-full'>
                        <h1 className='pb-1 border-b text-xl lg:text-3xl font-semibold text-gray-900 mb-6'>{isEdit ? 'Edit' : 'Add'} Project</h1>
                        <div className='mb-6'>
                            <TextField size="small" label="Project Name" name="name" id="name" value={formData.name} onChange={handle} variant="outlined" className={`w-full`} />
                        </div>
                        <div className='mb-4'>
                            <TextField size="small" label="Project Description" name="description" id="description" value={formData.description} onChange={handle} variant="outlined" className={`w-full`} />
                        </div>

                        <FormControl className='w-full h-12'>
                            <InputLabel id="selectInput">Project Topic</InputLabel>
                            <Select
                                labelId="selectInput"
                                id="open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={formData.project_topics || ''}
                                onChange={handleChange}
                                className="w-full"
                                defaultValue=""
                            >
                                <option value="" disabled={true} className="py-1 px-2 font-medium cursor-pointer">Select Project Topic</option>
                                {topics.map(item => <option key={item.id} value={item.id} className="py-1 px-2 font-medium cursor-pointer">{item.topics}</option>)}
                            </Select>
                        </FormControl>

                        <div className='text-lg text-gray-800 font-medium pb-1 mt-6 border-b'>Project Type
                            <div className='group relative inline-block mx-4'>
                                <InfoRounded fontSize="medium" className="text-gray-500 cursor-pointer" />
                                <div className='text-gray-500 p-2 rounded bg-white border font-light absolute bottom-6 w-48 z-10 hidden group-hover:block transition-all duration-300 ease-in-out select-none shadow-md' style={{ fontSize: '10px', lineHeight: 1.2 }}>
                                    <h1 className='font-medium text-gray-900'>Public:</h1>
                                    All files and folders will be visible to users visiting project page. The project can be downloaded and modified. Users can search for this project.
                                    <br />
                                    <br />
                                    <h1 className='font-medium text-gray-900'>Private:</h1>
                                    All file and folders will be visible to users contributing to the project on invitation bases. Users not invited to the project and cannot search for this project.
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-start items-center my-2 gap-6 text-lg" onChange={selectOne}>
                            <div className="form-check">
                                <input onChange={selectOne} value="public" className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="ProjectType" id="public" checked={formData.project_type == 'public'} />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="public">
                                    Public
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={selectOne} value="private" className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="ProjectType" id="private" checked={formData.project_type == 'private'} />
                                <label className="form-check-label inline-block text-gray-800" htmlFor="private">
                                    Private
                                </label>
                            </div>
                        </div>
                        <div className='flex items-center gap-8 mb-4 mt-10'>
                            <button type='reset' className='bg-red py-1.5 px-6 rounded text-lg text-white' onClick={() => setAddModal(false)} >
                                Cancel
                            </button>
                            <button type='submit' className='bg-blue py-1.5 px-6 rounded text-lg text-white' onClick={submit}>
                                {isEdit ? 'Update' : 'Create'} Project
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}