import React from "react";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import { Link } from "react-router-dom";
import PasswordChangedIcon from "../../assets/icons/PasswordChangedIcon";

function PasswordChanged() {

  return (
    <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
      <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
      <div className="w-full max-w-2xl bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
        <h1 className="text-3xl md:text-4xl font-bold text-blue text-center">Password Changed</h1>
        <p className="md:text-lg font-medium text-center my-4">You can now login with your new password</p>
        <PasswordChangedIcon className='m-2 max-h-80 object-contain' />
        <Link to="/login"><button type='submit' className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
          Login Here
        </button>
        </Link>
      </div>
    </div>
  );
}

export default PasswordChanged;
