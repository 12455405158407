import { Button, Divider, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, makeStyles, Radio, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import toggle_off_24px from '../../assets/images/toggle_off_24px.svg'
import toggle_on_24px from '../../assets/images/toggle_on_24px.svg'
import Save from './components/Save'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useStyle from './Styling'

function SecurityandLogin(props) {
    const classes = useStyle();
    const [data, setData] = useState({
        two_step_verification: "", 
        security_question: ""
    })
    const [lockAccount, setLockAccount] = useState(false)
    const [twoStep, setTwoStep] = useState("false")
    const [securityQue, setSecurityQue] = useState("false")

    useEffect(() => {
        setOldValue()
    }, [])
    const iconStyle = {
        position: 'absolute',
        color: "#092C4C",
        right: "3%",
        bottom: "25%",
        pointerEvents: 'none',
        width: "25px",
        height: "25px"
    };

    const setOldValue = () => {
        const settingsData = props.settingsListOld.data
        if (settingsData !== undefined){
            settingsData.map(function (item) {
                if (item.setting_type === "security") {
                    if(item.setting_key === "two_step_verification"){
                        setData({
                            two_step_verification: item.key_value
                        })
                        setTwoStep(item.key_value)
                    }
                    if(item.setting_key === "security_question"){
                        setData({
                            security_question: item.key_value
                        })
                        setSecurityQue(item.key_value)
                    }
                };
            })
        } 
    }
        
    const setToggle = (value) => {
        setTwoStep(value)
        setData({
            two_step_verification: value,
            security_question:data.security_question
        })
    }
    const setQuestion = (value) => {
        setSecurityQue(value)
        setData({
            two_step_verification: data.two_step_verification,
            security_question: value
        })
    }
    const toggleRadio=() =>{
        setLockAccount(!lockAccount)
    }
    return (
        <div>
            <List className={classes.listBox}>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Two-step verification
                        </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                    {twoStep ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> setToggle("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> setToggle("true")}/>
                     }
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Security Question
                        </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                    {securityQue ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggleQue} onClick={(e)=> setQuestion("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggleQue} onClick={(e)=> setQuestion("true")}/>
                     }
                    </ListItemIcon>
                </ListItem>
                <Divider />
               { securityQue ==="true" ? <div>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Current Question
                            </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.currentQuestion}>
                                    Q: What is your birth place?
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.securitySpacing}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    New Question
                        </Typography>
                            </React.Fragment>}
                        secondary={
                            <React.Fragment>
                                <TextField
                                    select
                                    variant="outlined"
                                    className={classes.securitySelectInput}
                                    SelectProps={{
                                        IconComponent: () => <KeyboardArrowDownIcon style={iconStyle} />,
                                    }}>
                                </TextField>
                            </React.Fragment>}>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.securitySpacing} >
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Answer
                        </Typography>
                            </React.Fragment>}
                        secondary={
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    className={classes.securitySelectInput}
                                    placeholder="Answer here">
                                </TextField>
                            </React.Fragment>}>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.securitySpacing}>
                    <FormControlLabel 
                        className={classes.radio} 
                        value={lockAccount} onClick={toggleRadio} 
                        control={<Radio checked={lockAccount? true:false }/>} 
                        label="I understand my account will be locked if I am unable to answer this question" />
                </ListItem>
                </div> : ""}
                <ListItem>
                    <React.Fragment>
                        <Button
                            variant='contained'
                            className={classes.cancleBtn}>
                            Cancel
                    </Button>
                    </React.Fragment>
                    <Save data={data} 
                          getSettingsList={props.getSettingsList}  
                          alertMsg={props.alertMsg}/>
                </ListItem>
            </List>
        </div>
    )
}

export default SecurityandLogin
