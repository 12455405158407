import { Avatar } from '@material-ui/core'
import React from 'react'
import UserImage from '../../assets/images/Default_User_Image.png';

function ContributorHover({ data, name, username, profile_pic }) {
    return (
        <div className='absolute bottom-12 left-4 min-w-max py-2 px-4 hidden group-hover:block popup-bg text-gray-900 font-medium shadow rounded text-sm z-20' style={{ opacity: '100%' }}>
            <div className='absolute transform -bottom-1 left-3 -translate-x-1/2 w-4 h-4 -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
            <div className='flex items-center gap-2 cursor-pointer my-1'>
                <Avatar src={data?.profile_pic || profile_pic || UserImage} className="border" />
                <div className='flex-grow'>
                    <h1 className='text-sm font-medium line-clamp-1'>{data?.username || username}</h1>
                    <p className='text-xs text-gray-500 line-clamp-1'>{data?.name || name}</p>
                </div>
            </div>
        </div>
    )
}

export default ContributorHover