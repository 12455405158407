import { React, useState } from "react";
import StepperProgress from "./StepToProgress";
import logo from "../../assets/images/logo.png"
import { Link } from "react-router-dom";
import { UserPageWebPortal } from "../../assets/cloud";

function UserProfile() {
    const [step, setStep] = useState(0);
    const stepChange = (stepNumber) => {
        setStep(stepNumber)
    }

    return (
        <div className='max-h-screen h-screen w-full overflow-hidden relative flex items-start'>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <img src={UserPageWebPortal} alt="" className="h-full w-full max-w-xl object-cover object-right hidden lg:inline-flex" />
            <div className="flex-grow h-screen overflow-y-auto">
                <StepperProgress step={step} stepChange={stepChange} />
            </div>
        </div>
    );
}
export default UserProfile;

