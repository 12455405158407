import React from 'react'
import Navbar from '../ReusableComponents/Navbar'
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'
import { register, updateMemberRequest, validateMemberLink } from '../../service/service'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import Loader from '../ReusableComponents/Loader';
import { toast } from 'react-hot-toast';
import validator from 'validator';
import { passwordValidator } from '../../utils/validation';

function MemberInvite() {

    const history = useHistory()
    const { data } = useParams()
    const [invite, setInvite] = useState();
    const user = useSelector(selectUserDetails);
    const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        repeat: ''
    })

    const checkLink = async () => {
        let res = await validateMemberLink({ id: data });
        console.log(res)
        if (res.success) {
            setInvite(res.data)
            setFormData({ ...formData, email: res.data.user_email })
        }
    }

    useEffect(() => {
        if (invite && user) {
            if (invite?.user?.id == user?.id) {
                setLoading(false);
            }
            else {
                history.push('/404')
            }
        }
    }, [user, invite])

    useEffect(() => {
        if (data) {
            checkLink()
        }
    }, [data])

    const updateRequest = async (status) => {
        setFlagLoaderButton(true)
        let res = await updateMemberRequest({ status: status });
        if (res.success) {
            toast.success(`Request ${status} Successfully`);
            window.location.replace('/dashboard')
        }
        setFlagLoaderButton(false)
    }


    const handleChange = e => {
        e.preventDefault();
        const { id } = e.target
        let newValue = e.target.value
        if (e.target.id.match("name")) {
            newValue = newValue.replace(/[^A-Za-z ]/gi, "");
        }
        setFormData(prevState => ({
            ...prevState,
            [id]: newValue
        }))

    };

    const validationForm = () => {
        let formIsValid = true;
        if (
            formData.name === "" &&
            formData.email === "" &&
            formData.password === "" &&
            formData.repeat === ""
        ) {
            formIsValid = false;
            toast.error('Please fill all the details', { id: 'invalid' })
        }
        if (!formData.name) {
            if (formData.name.length < 3) {
                formIsValid = false;
                toast.error('Name must be greater than 3 characters', { id: 'invalid' })
            }
        }
        if (!validator.isEmail(formData.email)) {
            formIsValid = false;
            toast.error('Invalid Email', { id: 'invalid' })
        }
        if (!passwordValidator(formData.password)) {
            formIsValid = false;
            toast.error('Password Must Contain one special character, one number, one uppercase', { id: 'invalid' })
        }
        if (formData.password?.length < 7) {
            formIsValid = false;
            toast.error('Password must be greater than 6', { id: 'invalid' })
        }
        if (!(formData.repeat === formData.password)) {
            formIsValid = false;
            toast.error('Password is not matched', { id: 'invalid' })
        }

        return formIsValid;
    }

    const submitUserForm = (e) => {
        e.preventDefault();
        if (validationForm()) {
            registerAPI();
        }
    }


    async function registerAPI() {
        setFlagLoaderButton(true);
        let name = formData.name;
        let password = formData.password;
        let email = formData.email;
        let data = { email, password, name, terms_accepted: true, organization_id: invite?.organization?.id };
        const res = await register(data);
        if (res.success) {
            window.localStorage.setItem("greyffiti_token", res.data)
            toast.success('User Registered Successfully', { id: 'success' })
            window.location.href = "/userProfileSetup";
        }
        else {
            console.log(res)
            if (res.message == "Email already exists")
                toast.error('Email already exists', { id: 'error' })
            else
                toast.error('Unable to register user', { id: 'error' })
            // setEmailExistFlag(false);
        }
        setFlagLoaderButton(false);
    }

    return (
        <>
            <section className='flex flex-col w-full min-h-screen'>
                <Navbar />
                {
                    loading
                        ?
                        <Loader />
                        :
                        <div className={`flex-grow flex flex-col items-center overflow-y-auto justify-center ${invite?.user ? '-mt-12' : 'mt-0'}`}>
                            <div className='flex flex-col items-center justify-center gap-4'>
                                <h1 className='text-2xl font-medium text-blue'>You have been invited to an Organization</h1>
                                <div className='w-auto h-20 rounded-lg overflow-hidden filter drop-shadow'>
                                    {!invite?.organization?.profile_pic
                                        ?
                                        <DefaultUserIcon className={'w-full h-full'} />
                                        :
                                        <img
                                            src={invite?.organization?.profile_pic}
                                            alt='profile_pic'
                                            className='w-full h-full object-contain z-10'
                                        />}
                                </div>
                                <div className=''>
                                    <h1 className='text-yellow font-semibold tracking-wide text-xl capitalize text-center'>{invite?.organization?.display_name}</h1>
                                    <h1 className='text-gray-800  italic tracking-wide text-xl text-right mt-1 capitalize'>As {invite?.user_role}</h1>
                                </div>

                                {
                                    invite?.user
                                        ?
                                        <div className='flex flex-col items-center justify-center mt-3'>

                                            {
                                                // user ?
                                                <div className='w-full max-w-xs flex flex-col items-center'>
                                                    <h1 className='text-lg w-full'>Signed in as</h1>
                                                    <div className='flex items-center gap-2 my-1 cursor-pointer' onClick={() => !user ? history.push('/login') : {}}>
                                                        <div className='w-12 h-12 rounded-full border-2 shadow overflow-hidden'>
                                                            {!invite?.user?.profile_pic
                                                                ?
                                                                <DefaultUserIcon className={'w-full h-full'} />
                                                                :
                                                                <img
                                                                    src={invite?.user?.profile_pic}
                                                                    alt='profile'
                                                                    className='w-full h-full object-cover z-10'
                                                                />}
                                                        </div>
                                                        <h1 className='text-blue font-medium text-lg'>{invite?.user?.username}</h1>
                                                    </div>
                                                </div>
                                                // :
                                                // <button className='bg-blue text-white py-1.5 px-8 rounded-lg'>SignIn to Continue</button>
                                            }

                                            <h1 className='text-xl text-center font-medium my-6'>Would you like to go accept this request?</h1>
                                            <div className='flex items-center justify-between w-full'>
                                                <button disabled={!user || flagLoaderButton} onClick={() => updateRequest('rejected')} className='py-2 px-10 bg-red disabled:bg-gray-400 text-white rounded-lg'>Reject</button>
                                                <button disabled={!user || flagLoaderButton} onClick={() => updateRequest('accepted')} className='py-2 px-10 bg-green disabled:bg-gray-400 text-white rounded-lg'>Accept</button>
                                            </div>
                                        </div>
                                        :
                                        <div className='w-full max-w-lg py-5 px-10 flex flex-col gap-3 border rounded-lg mt-3'>
                                            <div className='w-full'>
                                                <h1 className='font-medium mb-1'>Enter Your Name</h1>
                                                <input type='text' id='name' value={formData.name} onChange={handleChange} className='p-2 border rounded-lg bg-white w-full' />
                                            </div>
                                            <div className='w-full'>
                                                <h1 className='font-medium mb-1'>Email address</h1>
                                                <input type='email' id='email' value={formData.email} disabled readOnly className='p-2 border rounded-lg bg-white w-full' />
                                            </div>
                                            <div className='w-full'>
                                                <h1 className='font-medium mb-1'>Enter Password</h1>
                                                <input type='password' id='password' value={formData.password} onChange={handleChange} className='p-2 border rounded-lg bg-white w-full' />
                                            </div>
                                            <div className='w-full'>
                                                <h1 className='font-medium mb-1'>Confirm Password</h1>
                                                <input type='password' id='repeat' value={formData.repeat} onChange={handleChange} className='p-2 border rounded-lg bg-white w-full' />
                                            </div>

                                            <div className='mt-3 flex justify-end'>
                                                <button disabled={flagLoaderButton} className='bg-blue disabled:bg-gray-400 text-white rounded-lg py-1.5 px-10' onClick={submitUserForm}>SignUp</button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </section>
        </>
    )
}

export default MemberInvite