import { CloseOutlined } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { filePreview, selectFilePreview } from '../../slices/fileSlice';

function Thumbnail() {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const thumbnail = useSelector(selectFilePreview);
    const dispatch = useDispatch();
    return (
        <>{
            (thumbnail.open && thumbnail.title && thumbnail.url) ?
                <div className='fixed top-0 left-0 w-full min-h-screen overflow-hidden grid place-items-center transition-y' style={{ zIndex: 2000 }}>
                    <div className={`rounded-lg overflow-hidden z-10 ${isFullScreen ? 'w-full h-screen' : ''} transform transition-all duration-300 ease-in-out`}>
                        <div className='flex items-center py-2 px-5 gap-4 bg-white min-w-[300px]'>
                            <h1 className='flex-grow text-lg font-semibold text-gray-800'>{thumbnail?.title} Preview</h1>
                            {isFullScreen ?
                                <svg onClick={() => setIsFullScreen(false)} width="24" height="24" viewBox="0 0 29 29" className='cursor-pointer' fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00184 0.325195H10.0018V10.325H0.00195312V6.32509H6.00184L6.00184 0.325195ZM10.0018 28.3238H6.00184L6.00184 22.3239H0.00195312V18.324H10.0018V28.3238ZM22.0016 22.3239H28.0015V18.324H18.0017V28.3238H22.0016V22.3239ZM28.0015 6.32509V10.325H18.0017V0.325195H22.0016V6.32509H28.0015Z" fill="#1D85E8" />
                                </svg>

                                : <svg onClick={() => setIsFullScreen(true)} width="24" height="24" className='cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.55794 8.74154H0.224609V0.408203H8.55794V3.74154H3.55794V8.74154ZM0.224609 15.4082H3.55794V20.4082H8.55794V23.7415H0.224609V15.4082ZM20.2246 20.4082H15.2246V23.7415H23.5579V15.4082H20.2246V20.4082ZM15.2246 3.74154V0.408203H23.5579V8.74154H20.2246V3.74154H15.2246Z" fill="#1D85E8" />
                                </svg>}
                            <CloseOutlined fontSize='large' className='text-red cursor-pointer' onClick={() => dispatch(filePreview({ open: false }))} />
                        </div>
                        <img src={thumbnail?.url} loading="lazy" alt="" className={`object-contain mx-auto ${isFullScreen ? 'w-full h-full' : 'max-w-full max-h-[55vh]'} transform transition-all duration-300 ease-in-out`} />
                    </div>
                    <div className='absolute top-0 w-full min-h-screen overflow-hidden bg-black bg-opacity-80 filter backdrop-blur-sm'></div>
                </div>
                :
                <></>
        }
        </>
    )
}

export default Thumbnail