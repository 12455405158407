import React, { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { inviteAccept } from "../../service/service";
import logo from '../../assets/images/Greyffiti-New-Logo-White 1.png'
import toast from 'react-hot-toast';

function AcceptEmail() {
    var { key } = useParams();
    useEffect(() => {
        inviteAcceptAPI()
    })

    async function inviteAcceptAPI() {
        const res = await inviteAccept(key);
        if (res.message === "Already added as a contributor") {
            toast.error("Already added as a contributor", { id: 'res' })
        }
        else {
            toast.success("You are added as a Contributor.", { id: 'res' })
        }
    }

    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
                <h1 className="text-3xl md:text-4xl font-bold text-blue">Thank You!</h1>
                <p className="md:text-lg font-medium text-center my-4">You have successfully accepted the invitation</p>
                <Link to='/login'><button type='submit' className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                    Click Here To Login
                </button>
                </Link>
            </div>
        </div>
    )
}

export default AcceptEmail