import React, { useRef, useState } from 'react'
import CardBG from '../../assets/images/CardBG.png'
import { deleteProjectById, editProjectById, frameProject, getProjectByUsername, getProjectsList, likeDislikeProject } from '../../service/service';
import { useClickOutside } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux'
import { preview, projectList } from '../../slices/projectSlice';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import AddProjectForm from './AddProject';
import { useHistory } from 'react-router-dom';
import { routeList } from '../../slices/routeSlice';
import { selectUserDetails } from '../../slices/userSlice';
import { MoreHoriz } from '@material-ui/icons';
import toast from 'react-hot-toast';
import ProjectPDF from '../../assets/images/ProjectPDF.png'
import EditIcon from '../../assets/icons/EditIcon';
import { Viewer } from '@react-pdf-viewer/core';
import moment from 'moment';
import Like from '../../assets/images/Like.svg'
import Frame from '../../assets/images/Frame.svg'

function ProjectCard({ project, username, setProjects }) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [privateModal, setPrivateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [likes, setLikes] = useState(project?.likes || 0)
    const [frames, setFrames] = useState(project?.frames || 0)

    const moreRef = useRef()
    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)

    useClickOutside(() => setMore(false), moreRef)

    async function getFreshProjectList() {
        const projectData = await getProjectByUsername(username);
        setProjects(projectData?.data || [])
        dispatch(projectList(projectData.data || []));
    }

    async function deleteProject(projectId) {
        await deleteProjectById(projectId);
        setDeleteModal(false);
        getFreshProjectList()
    }

    async function editProject(data) {
        const res = await editProjectById(data)
        if (res.data) {
            if (data.project_type == 'private') {
                toast.success(`Project ${data.name} set to private`)
            }
            else if (data.project_type == 'public') {
                toast.success(`Project ${data.name} set to public`)
            }
            getFreshProjectList()
        }
        setPrivateModal(false)
    }

    const changeProjectType = project => {
        let id = project.id
        let name = project.name
        let description = project.description
        let project_topics = project.project_topics || ''
        let data = { name, description, project_type: project.project_type_id === 1 ? 'private' : 'public', id, project_topics }
        setMore(false)
        editProject(data)
    }

    const navigate = () => {
        dispatch(routeList(`/${username}/${project.name}`))
        history.push(`/${username}/${project.name}`)
    }

    const likeAndDislikeProject = async (action) => {
        let res = await likeDislikeProject({ project_id: project.id, action })
        if (res.data) {
            setLikes(res.data.likes)
        }
    }
    const handleFrame = async (action) => {
        let res = await frameProject({ project_id: project.id, action })
        if (res.data) {
            setFrames(res.data.frames)
        }
    }

    return (
        <>
            <div className='w-full max-w-[336px] rounded-xl bg-white h-[250px] overflow-hidden group shadow-lg relative'>
                {
                    project?.readme && project?.readme !== 'undefined' ?
                        <div className='h-full w-full overflow-hidden relative transform scale-125'>
                            <div className='absolute top-0 left-0 w-full h-full z-10 cursor-pointer' />
                            <Viewer
                                fileUrl={project?.readme}
                            />
                        </div>
                        :
                        <img src={CardBG} className="w-full h-full object-cover" alt='' />
                }
                <div className={`bg-white h-[180px] z-20 rounded-lg absolute -bottom-28 group-hover:bottom-0 flex flex-col w-full transition-all ease-in-out duration-300`} style={{ boxShadow: '0px 10px 20px rgba(0,0,0,0.3)' }}>
                    <div className='absolute w-full h-full overflow-hidden top-0 left-0 cursor-pointer' onClick={() => more ? setMore(false) : navigate()}></div>
                    <div className='justify-end items-center px-2 m-2 z-20 hidden group-hover:inline-flex gap-2'>
                        {(project?.readme && project?.readme != 'undefined') && <img src={ProjectPDF} alt='' className='mr-auto cursor-pointer w-5 h-5' style={{ filter: 'drop-shadow(2px 2px 8px rgba(0,0,0,0.3))' }} onClick={() => dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name }))} />}
                        {project.username === user?.username && <> <EditIcon className='cursor-pointer' onClick={() => setAddModal(true)} />
                            <MoreHoriz className='cursor-pointer text-gray-600' onClick={() => setMore(!more)} />
                            {more && <div ref={moreRef} className="absolute top-8 right-2 border min-w-max py-1.5 px-2 popup-bg text-base font-medium shadow-lg rounded-md text-gray-800 cursor-pointer">
                                <div className='absolute transform -top-1 right-2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                {project?.username === user?.username && <h1 className='hover:text-blue-500' onClick={() => project.project_type_id === 1 ? setPrivateModal(true) : changeProjectType(project)}>
                                    {project.project_type_id === 1
                                        ? 'Make Private'
                                        : 'Make Public'}
                                </h1>}
                                <h1 onClick={() => (project?.readme && project?.readme != 'undefined') ? dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name })) : toast("No preview available", { id: 'preview' })} className='hover:text-blue-500'>View Project Overview</h1>
                            </div>}
                        </>}
                    </div>
                    <div className={`flex items-center justify-between px-4 group-hover:mb-1 py-4 group-hover:py-0`}>
                        <h1 className='font-medium z-10 text-lg text-blue cursor-pointer' onClick={navigate}>{project.name}</h1>
                        <button className='py-1 px-4 rounded bg-blue text-white z-10'>
                            {project.project_type_id === 1
                                ? 'Public'
                                : 'Private'}
                        </button>
                    </div>
                    <p className='text-gray-600 px-4 flex-grow line-clamp-2 text-sm py-1'>{project.description}</p>
                    <div className='flex items-end justify-between p-4 pt-0'>
                        <div>
                            <p className=''>{project.repoLength} repositories</p>
                            <p className='text-gray-500 text-xs'>Updated {moment(project.updated_at).fromNow()}</p>
                        </div>

                        <div>
                            <div className='max-w-max relative z-20 flex items-center justify-end gap-6 pt-2'>
                                <div className='flex items-center gap-1 cursor-pointer' onClick={() => likeAndDislikeProject(1)}><img src={Like} className='w-5' alt="" /> <span>{likes || 0}</span></div>
                                <div className='flex items-center gap-1 cursor-pointer' onClick={() => handleFrame(1)}><img src={Frame} className='w-4' alt="" /> <span>{frames || 0}</span></div>
                            </div>
                            <p className='text-gray-500 text-xs'>Added {moment(project.created_at).fromNow()}</p>
                        </div>
                    </div>

                </div>
            </div>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${project.name}"`} description="This will also delete your all repository" type="warn" onCancel={() => setDeleteModal(false)} onOk={() => deleteProject(project.id)} />
            <ConfirmModal open={privateModal} heading={`Are you sure you want to set the project "${project.name}" to private`} description="This will also set your all repositories of this project to private" type="warn" okText="Yes" onCancel={() => setPrivateModal(false)} onOk={() => changeProjectType(project)} />
            {addModal &&
                <AddProjectForm getFreshProjectList={getFreshProjectList} setAddModal={setAddModal} isEdit={true} projectData={project} username={user?.username} />
            }
        </>
    )
}

export default ProjectCard