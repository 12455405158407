import React from 'react'

function MailIcon({ className }) {
    return (
        <svg className={className} width="156" height="146" viewBox="0 0 156 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7373 74.1572L75.4803 25.7415L138.832 74.1572V133.115H11.7373V74.1572Z" fill="#E6E6E6" />
            <path d="M138.832 146H11.7373V74.1572L75.4803 110.469L138.832 74.1572V146Z" fill="#FF8B66" />
            <path d="M127.101 81.1854L75.4804 111.25L23.4692 81.1854V49.9495H127.101V81.1854Z" fill="#FFC258" />
            <path d="M63.7485 87.823L67.6591 93.6797L86.8212 82.3567" stroke="white" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.2849 34.3314L34.8101 26.9128" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M107.548 39.4073L113.805 33.1602" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.3687 8.17139V17.1517" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M49.6702 12.8567H40.6758" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M106.375 15.9802V24.9606" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M110.676 20.6655H101.682" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M59.8377 21.837C60.7016 21.837 61.4019 21.1377 61.4019 20.2752C61.4019 19.4126 60.7016 18.7134 59.8377 18.7134C58.9738 18.7134 58.2734 19.4126 58.2734 20.2752C58.2734 21.1377 58.9738 21.837 59.8377 21.837Z" fill="#FF8B66" />
            <path d="M129.838 46.8257C130.702 46.8257 131.402 46.1265 131.402 45.2639C131.402 44.4014 130.702 43.7021 129.838 43.7021C128.974 43.7021 128.273 44.4014 128.273 45.2639C128.273 46.1265 128.974 46.8257 129.838 46.8257Z" fill="#FF8B66" />
            <path d="M17.9944 37.8455C18.8583 37.8455 19.5587 37.1463 19.5587 36.2837C19.5587 35.4212 18.8583 34.7219 17.9944 34.7219C17.1305 34.7219 16.4302 35.4212 16.4302 36.2837C16.4302 37.1463 17.1305 37.8455 17.9944 37.8455Z" fill="#FF8B66" />
            <path d="M84.8658 12.4663C86.3777 12.4663 87.6033 11.2426 87.6033 9.73315C87.6033 8.22367 86.3777 7 84.8658 7C83.354 7 82.1284 8.22367 82.1284 9.73315C82.1284 11.2426 83.354 12.4663 84.8658 12.4663Z" fill="#FF8B66" />
            <path d="M18.7762 10.1235L20.7315 13.6376C21.36 14.9321 22.4459 15.7841 23.86 15.9803L27.7706 16.3707L25.0332 19.1039C24.0119 20.1238 23.6243 21.5962 23.86 23.0084L24.2511 26.9129L21.1226 24.9606C19.8263 24.2937 18.0779 24.2937 16.8209 24.9606L13.6924 26.9129L14.0835 23.0084C14.3191 21.5962 13.9316 20.1238 12.9103 19.1039L10.1729 16.3707L14.0835 15.9803C15.4976 15.7841 16.5835 14.9321 17.212 13.6376L18.7762 10.1235Z" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M142.352 34.7219L143.525 37.0646C143.955 37.9779 144.503 38.5073 145.48 38.6264L148.218 39.0169L146.262 40.9691C145.558 41.6838 145.324 42.7096 145.48 43.7023L145.871 46.4354L143.525 45.2641C142.665 44.7876 141.687 44.7876 140.787 45.2641L138.441 46.4354L138.832 43.7023C138.989 42.7096 138.754 41.6441 138.05 40.9691L136.095 39.0169L138.832 38.6264C139.81 38.4676 140.748 37.9779 141.179 37.0646L142.352 34.7219Z" stroke="#FF8B66" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default MailIcon