import React from 'react'

function DownloadIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={`cursor-pointer ${className}`} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 14C11.0159 14.0203 10.9768 13.9887 11 14C11.0232 14.0113 10.9741 14 11 14C10.9768 14.0113 11.0258 14 11 14C10.9841 14.0203 11.0232 13.9887 11 14L14 11C14.0872 10.8895 13.1426 10 13 10H12V3C12 2.90651 12.0936 3 12 3H9.99999C9.90636 3 9.99999 2.90651 9.99999 3V10H8.99999C8.85742 10 7.91274 10.8895 7.99999 11L11 14ZM19 13H18C17.9064 13 17 12.9065 17 13V16H4.99999V13C4.99999 12.9065 4.09361 13 3.99999 13H2.99999C2.90636 13 2.99999 12.9065 2.99999 13V17C2.99999 17.3761 3.62335 18 3.99999 18H18C18.3766 18 19 17.3761 19 17V13C19.0936 13 19 12.9065 19 13Z" fill="#2DC8A8" />
        </svg>

    )
}

export default DownloadIcon