import React from 'react'
import './Loader.css'
import HeartIcon from '../../assets/images/heartIcon.svg'
import SprayIcon from '../../assets/images/sprayIcon.svg'

function Loader() {
    return (
        <>
            <div className='absolute w-full min-h-[50vh] inset-0 bg-white flex flex-col items-center justify-center' style={{ zIndex: 50001 }}>
                <div className='relative select-none'>
                    <img src={HeartIcon} className="heart" alt='' />
                    <img src={SprayIcon} className="spray " alt='' />
                </div>
            </div>
        </>
    )
}

export default Loader