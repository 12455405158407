import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import { issueList, selectIssueList } from '../../slices/issueSlice';
import IssueList from '../Issue/IssueList';
import { useClickOutside } from '../../utils/helper';
import FilterIcon from '../ReusableComponents/FilterIcon';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { getUserByUsername } from '../../service/service';
import MiniLoader from '../ReusableComponents/MiniLoader';


function Issues() {
    const dispatch = useDispatch()
    const history = useHistory();
    const { username } = useParams()
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUserBasicInfo);
    const userData = useSelector(selectUserDetails);
    const [userD, setUserData] = useState()
    const issues = useSelector(selectIssueList);
    const [issueSearch, setIssueSearch] = useState('')
    const [isFilter, setIsFilter] = useState(false);
    const [filterOption, setFilterOption] = useState({ createdByOthers: false, createdByMe: false, issueClosed: false })
    const [isAsc, setIsAsc] = useState(true)
    const [isSort, setIsSort] = useState(false);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [openIssue, setOpenIssue] = useState(false);
    const [closedIssue, setClosedIssue] = useState(false);

    const moreRef = useRef()
    const filterRef = useRef()
    useClickOutside(() => { isSort ? setIsSort(false) : setIsFilter(false) }, isSort ? moreRef : filterRef)

    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            if (res.data.username !== userData?.username) {
                history.push(`/${userData?.username}/issues`)
            }
            setUserData(res.data)
        }
        else {
            history.push("/404")
        }
    }

    useEffect(() => {
        if (userData) {
            setLoading(false)
            if (username) {
                getUser();
            }
        }
        else {
            setLoading(true)
        }
    }, [userData, username]);

    const sortIssueByName = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => a.title.localeCompare(b.title));
            dispatch(issueList(sorted));
            setIsSort(false);
        }
    }
    const sortIssueByCreated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            dispatch(issueList(sorted));
            setIsSort(false);
        }
    }
    const sortIssueByUpdated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
            dispatch(issueList(sorted));
            setIsSort(false);
        }
    }

    const handleIssueOrder = () => {
        let arr = [...issues];
        arr.reverse();
        dispatch(issueList(arr));
        setIsAsc(!isAsc)
    }

    useEffect(() => {
        if (issues?.length) {
            let open = 0;
            let close = 0;
            issues?.forEach(item => {
                if (item.issue_status === "Open")
                    open += 1;

                if (item.issue_status === "Closed")
                    close += 1;
            });

            setClosedCount(close);
            setOpenCount(open)
        }
    }, [issues])


    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData, ...user }} className="lg:max-w-sm hidden lg:inline-flex">
                        </UserCard>


                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push('/dashboard')}>Insights</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${username}/`)}>Projects</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${username}/all`)}>Repositories</h1>
                                <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500' onClick={() => history?.push(`/${username}/issues`)}>Issues</h1>
                                <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${username}/events`)}>Events</h1>
                                {userData?.plan?.name == 'organization' ? <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${userData?.username}/retailer`)}>Retailer</h1> : <></>}
                                {/* <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/dashboard/tags`)}>Tags</h1> */}
                            </div>
                            <div className='w-full'>

                                <div className='flex items-center my-2 py-2 gap-10 border-b'>
                                    <h1 className={`text-lg cursor-pointer text-gray-500 border-b ${(!openIssue && !closedIssue) ? 'border-gray-500' : 'border-white'}`} onClick={() => { setOpenIssue(false); setClosedIssue(false); }}>All ({issues?.length ?? 0})</h1>
                                    <h1 className={`text-lg cursor-pointer text-blue border-b ${openIssue ? 'border-blue-500' : 'border-white'}`} onClick={e => { setOpenIssue(true); setClosedIssue(false); }}>Open {`(${openCount})`}</h1>
                                    <h1 className={`text-lg cursor-pointer text-green border-b ${closedIssue ? 'border-green-500' : 'border-white'}`} onClick={e => { setClosedIssue(true); setOpenIssue(false); }}>Closed {`(${closedCount})`}</h1>
                                </div>
                                <div className='flex items-center justify-between my-4'>
                                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full relative'>
                                        <SearchOutlined />
                                        <input type="text" placeholder='Search issue here...' onChange={(e) => issues.length ? setIssueSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                        <FilterIcon onClick={() => setIsFilter(!isFilter)} />
                                        {isFilter && <div ref={filterRef} className='absolute z-30 w-full top-14 left-0 bg-white shadow-lg rounded-lg pt-2 pb-4 px-4 border font-medium text-gray-800 popup-bg'>
                                            <div className='absolute transform -top-2 right-3 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                            <li className='text-right list-none hover:text-blue-500 cursor-pointer' onClick={() => setFilterOption({ createdByOthers: false, createdByMe: false, issueClosed: false })}>Reset Filters</li>
                                            <FormGroup>
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByMe} onChange={e => setFilterOption({ ...filterOption, createdByMe: !filterOption.createdByMe })} />} label="Created by you" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.createdByOthers} onChange={e => setFilterOption({ ...filterOption, createdByOthers: !filterOption.createdByOthers })} />} label="Assigned" />
                                                <FormControlLabel className='text-sm font-medium' control={<Checkbox size='small' color='primary' checked={filterOption.issueClosed} onChange={e => setFilterOption({ ...filterOption, issueClosed: !filterOption.issueClosed })} />} label="Issue Closed" />
                                            </FormGroup>
                                            {/* <li className={`hover:text-blue-500  cursor-pointer ${filterOption.createdByMe ? 'text-blue' : ''}`} onClick={() => setFilterOption({ ...filterOption, createdByMe: !filterOption.createdByMe })}>Created by you</li>
                                    <li className={`hover:text-blue-500  cursor-pointer ${filterOption.createdByOthers ? 'text-blue' : ''}`} onClick={() => setFilterOption({ ...filterOption, createdByOthers: !filterOption.createdByOthers })}>Assigned</li> */}
                                        </div>}
                                    </div>

                                    <div className='flex items-center gap-4 relative z-30 pr-4'>
                                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                            {isSort && <div className='p-2 bg-white rounded-lg shadow-lg absolute top-12 -left-4'>
                                                <p className='font-medium text-gray-600 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByName}>Name</p>
                                                <p className='font-medium text-gray-600 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByCreated}>Date Created</p>
                                                <p className='font-medium text-gray-600 hover:text-blue-500 cursor-pointer p-1 min-w-max' onClick={sortIssueByUpdated}>Date Updated</p>
                                            </div>}
                                        </div>
                                        <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleIssueOrder}>
                                            <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                        </div>
                                    </div>
                                </div>
                                <div className='my-4' style={{ maxHeight: '65dvh', overflowY: 'auto' }}>
                                    {!issues?.length
                                        ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Issue Added</h1>
                                        : <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                            <thead>
                                                <tr className='font-medium text-gray-500'>
                                                    <td className='opacity-0'>opacity</td>
                                                    <td>Issue</td>
                                                    <td>Author</td>
                                                    <td className='text-center'>Repository</td>
                                                    <td className='text-center'>Assignee</td>
                                                    <td className='text-center'>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                {
                                                    (openIssue || closedIssue) ?
                                                        issues?.map(issue => (closedIssue ? issue.issue_status == 'Closed' : openIssue ? issue.issue_status == 'Open' : '') && issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                        :
                                                        issues?.map(issue => (((filterOption.createdByMe && issue.author_id == userData.id) || (filterOption.createdByOthers && issue.author_id != userData.id) || (filterOption.createdByMe == filterOption.createdByOthers) && !filterOption.issueClosed) || (issue.issue_status == 'Closed' && filterOption.issueClosed)) && issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default Issues