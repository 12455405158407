import React from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons/";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import resetPasswordValidation from "./resetPasswordValidation";

import { Link } from "react-router-dom";

function ResetPassword() {
    const {
        handleChange,
        handleFormSubmit,
        handleClickShowPassword,
        handleClickConfirmShowPassword,
        values,
        flagLoaderButton,
    } = resetPasswordValidation();


    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <form onSubmit={handleFormSubmit} className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
                <h1 className="text-3xl md:text-4xl font-bold text-blue">Create new password</h1>
                <p className="md:text-lg font-medium text-center my-4">Your password must be different from previous used password</p>
                <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4 border border-gray-300 focus-within:border-gray-700 my-4'>
                    <input required type={values.showPassword ? "text" : "password"} name="password" id="password" value={values.password} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter new password" onChange={handleChange} />
                    {values.showPassword ? (
                        <VisibilityOutlined onClick={handleClickShowPassword} />
                    ) : (
                        <VisibilityOffOutlined onClick={handleClickShowPassword} />
                    )}
                </div>
                <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4 border border-gray-300 focus-within:border-gray-700 my-4'>
                    <input required type={values.showConfirmPassword ? "text" : "password"} name="repeatPassword" id="repeatPassword" value={values.repeatPassword} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Repeat Password" onChange={handleChange} />
                    {values.showConfirmPassword ? (
                        <VisibilityOutlined onClick={handleClickConfirmShowPassword} />
                    ) : (
                        <VisibilityOffOutlined onClick={handleClickConfirmShowPassword} />
                    )}
                </div>
                <button disabled={flagLoaderButton} type='submit' onClick={handleFormSubmit} className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                    Reset
                    {flagLoaderButton && <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block mx-4" viewBox="0 0 24 24"></svg>}
                </button>
            </form>
        </div>
    );
}

export default ResetPassword;
