import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { CloseOutlined, CloudUpload, Delete, DeleteOutline, Edit, ExpandLess, ExpandMore, Help, PictureAsPdf, RemoveRedEye, SearchOutlined, Visibility } from '@material-ui/icons';
import { repoList, selectRepoList } from '../../slices/repoSlice';
import { editProjectById, frameProject, getIssueListByProject, getProjectByName, getProjectContributors, getReposBasedOnProjectId, getRepositoryList, getRepositoryListBySize, getRetailerInvite, getUserByUsername, likeDislikeProject, listTagsByProject, updateProjectSpotlight, updateRetailerInvite } from '../../service/service';
import RepoList from '../Repository/RepoList';
import { preview, projectContributors, selectProjectList } from '../../slices/projectSlice';
import { routeList } from '../../slices/routeSlice';
import { Switch } from '@material-ui/core'
import AddRepository from '../Repository/AddRepository';
import RemoveContributor from '../ReusableComponents/RemoveContributor';
import AddContributor from '../ReusableComponents/AddContributor';
import InviteViaEmail from '../ReusableComponents/InviteViaEmail';
import IssueList from '../Issue/IssueList';
import AppsIcon from '@material-ui/icons/Apps';
import ViewListIcon from '@material-ui/icons/ViewList';
import RepoCard from '../Repository/RepoCard';
import { formatLargeNumber, useClickOutside, useSort } from '../../utils/helper';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import toast from 'react-hot-toast';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { FileUploader } from 'react-drag-drop-files';
import UserImage from '../../assets/images/Default_User_Image.png';
import ContributorHover from '../ReusableComponents/ContributorHover';
import ContributorList from '../ReusableComponents/ContibutorList';
import MiniLoader from '../ReusableComponents/MiniLoader';
import Tag from '../ReusableComponents/Tag';
import moment from 'moment';
import TransferOwnership from '../ReusableComponents/Transfer';
// import InviteRetailer from '../Retailer/InviteRetailer';
import Like from '../../assets/images/Like.svg'
import Frame from '../../assets/images/Frame.svg'
import LikeWh from '../../assets/images/Spray-wh.svg'
import FrameWh from '../../assets/images/Frame-wh.svg'

function Repository() {
    const history = useHistory();
    const { username, project } = useParams();
    const dispatch = useDispatch();
    const sort = useSort()
    const [loading, setLoading] = useState(true);
    const user = useSelector(selectUserDetails);
    const [userData, setUserData] = useState(false);
    const [readme, setReadme] = useState({})
    const [addModal, setAddModal] = useState(false);
    const [projectDetail, setProjectDetail] = useState();
    const [contributors, setContributors] = useState(false);
    const [removeCreator, setRemoveCreator] = useState(false);
    const [openPopup, setOpenPopUp] = useState(false)
    const [emailInvite, setEmailInvite] = useState(false);
    const [state, setState] = useState('repository')
    const [issues, setIssues] = useState([])
    const [cardView, setCardView] = useState(Boolean(localStorage.getItem('repoCardView') == 'true'));
    const [repoSearch, setRepoSearch] = useState('')
    const [issueSearch, setIssueSearch] = useState('')
    const [isAsc, setIsAsc] = useState(true)
    const [isSort, setIsSort] = useState(false);
    const [spotlight, setSpotlight] = useState(projectDetail?.spotlight);
    const [load, setLoad] = useState(false);
    const [repos, setRepos] = useState([])
    const [isAccess, setIsAccess] = useState(true)
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [openIssue, setOpenIssue] = useState(false);
    const [closedIssue, setClosedIssue] = useState(false);
    const [modal, setModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canInvite, setCanInvite] = useState(0);
    const [isViewContributor, setIsViewContributor] = useState(false);
    const [tagList, setTagList] = useState([])
    const [accessRight, setAccessRight] = useState(1)
    const [isRetailer, setIsRetailer] = useState(false);
    const [retailer, setRetailer] = useState()
    const [retailerLoading, setRetailerLoading] = useState(true)
    const [likes, setLikes] = useState(0);
    const [frames, setFrames] = useState(0);
    const [liked, setLiked] = useState(false);
    const [framed, setFramed] = useState(false);
    const [updating, setUpdating] = useState(false);

    const fileRef = useRef()
    const moreRef = useRef()
    useClickOutside(() => setIsSort(false), moreRef)

    const [isTransfer, setIsTransfer] = useState(false)
    useEffect(() => {
        if (issues?.length) {
            let open = 0;
            let close = 0;
            issues?.forEach(item => {
                if (item.issue_status === "Open")
                    open += 1;

                if (item.issue_status === "Closed")
                    close += 1;
            });

            setClosedCount(close);
            setOpenCount(open)
        }
    }, [issues])

    const fetchRetailer = async () => {
        setRetailerLoading(true)
        let res = await getRetailerInvite()
        let idx = res.data?.findIndex(item => item?.project_id == projectDetail?.id)
        if (idx > -1) {
            setRetailer(res.data[idx])
        }
        else {
            setRetailer()
        }
        setRetailerLoading(false)
    }

    useEffect(() => {
        if (userData && user && projectDetail) {
            fetchRetailer();
            setLikes(projectDetail?.likes)
            setFrames(projectDetail?.frames)
            setLiked(Boolean(projectDetail?.liked))
            setFramed(Boolean(projectDetail?.framed))
        }
    }, [userData, user, projectDetail])


    useEffect(() => {
        if (contributors) {
            dispatch(projectContributors(contributors))
        }
    }, [contributors]);

    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            setUserData(res.data)
        }
        else {
            history.push("/404")
        }
    }
    useEffect(() => {
        if (user?.username) {
            if (projectDetail && username && contributors) {
                let is = contributors.filter(item => item.username == user?.username)
                console.log("checking project access...")
                console.log("got access criteria...")
                console.log("making decision...");
                console.log("process finished...");
                let access = Boolean(projectDetail?.username === user?.username || Boolean(is?.length));
                if (is?.length)
                    setAccessRight(is[0]?.access)
                setIsAccess(Boolean(access))
                if (!access && projectDetail?.project_type_id == 2) {
                    if (!retailerLoading)
                        if (retailer) {
                        } else
                            history.push('/404')
                } else {
                    let arr = [...repos];
                    let newArr = arr.filter(repo => repo.repository_type == '2' ? access ? user ? true : false : false : true);
                    setRepos([...newArr])
                }
            }

            else if ((!user || user?.username !== username) && repos?.length) {
                let arr = [...repos];
                let newArr = arr.filter(repo => {
                    if (!user) {
                        return repo.repository_type == 1;
                    }
                    else {
                        let is = repo?.contributorList?.filter(item => item.username == user?.username);

                        return (repo.repository_type == 2 ? Boolean(is?.length) : true);
                    }
                });
                setRepos([...newArr])
            }
        }
        else if (!localStorage.getItem("greyffiti_token") && projectDetail?.project_type_id == 2) {
            history.push('/404')
        }
        if (projectDetail)
            getTagsListByProject();

    }, [projectDetail, user, username, contributors?.length, repos?.length, retailerLoading])

    useEffect(() => {
        if (username) {
            getUser();
        }
    }, [username])

    const getAllRepos = async () => {
        try {
            const res = await getRepositoryList(username);
            if (res.data) {
                setRepos(res.data)
                // dispatch(repoList(res.data))
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const getOneRepo = async (projectName) => {
        try {
            const res = await getProjectByName(projectName);
            if (res.success) {
                setProjectDetail(res.data);
                setContributors(res.data.contributorList);
                setSpotlight(res.data.spotlight);
                setRepos(res.data.repoList);
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!loading) {
            let personal = repos.filter(repo => repo.username == user?.username)
            if (personal.length < user?.plan.limit.repository)
                setCanAdd(true);
        }
    }, [repos, user, loading])

    useEffect(() => {
        if (contributors.length < user?.plan?.limit?.project_contributors)
            setCanInvite(user?.plan?.limit?.project_contributors - contributors.length)
    }, [contributors])

    useEffect(() => {
        if (project === 'all') {
            getAllRepos()
        } else {
            getOneRepo(project);
        }
    }, [])

    const changeState = async () => {
        const res = await getIssueListByProject(projectDetail.id);
        if (res.success) {
            setIssues(res.data);
            setState('issue')
        }
    }

    const getTagsListByProject = async () => {
        const res = await listTagsByProject(projectDetail?.id)
        if (res.data)
            setTagList(res.data);
    }


    const sortRepoByName = () => {
        if (repos) {
            let arr = [...repos]
            let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
            setRepos(sorted);
            setIsSort(false)
        }
    }
    const sortRepoByCreated = () => {
        if (repos) {
            let arr = [...repos]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            setRepos(sorted);
            setIsSort(false)
        }
    }
    const sortRepoByUpdated = () => {
        if (repos) {
            let arr = [...repos]
            let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
            setRepos(sorted);
            setIsSort(false)
        }
    }

    const sortRepoBySize = async () => {
        const res = await getRepositoryListBySize();
        if (res.data) {
            setRepos(res.data);
        }
        setIsSort(false)
    }

    const sortIssueByName = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => a.title.localeCompare(b.title));
            setIssues(sorted)
            setIsSort(false);
        }
    }
    const sortIssueByCreated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            setIssues(sorted)
            setIsSort(false);
        }
    }
    const sortIssueByUpdated = () => {
        if (issues) {
            let arr = [...issues]
            let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
            setIssues(sorted)
            setIsSort(false);
        }
    }

    const handleRepoOrder = () => {
        let arr = [...repos];
        arr.reverse();
        setRepos(arr)
        dispatch(repoList(arr));
        setIsAsc(!isAsc)
    }
    const handleIssueOrder = () => {
        let arr = [...issues];
        arr.reverse();
        setIssues(arr)
        setIsAsc(!isAsc)
    }

    const navigate = () => {
        dispatch(routeList(`/${username}/${projectDetail.name}`))
        history.push(`/${username}/${projectDetail.name}`)
    }

    const handleCardView = (value) => {
        localStorage.setItem('repoCardView', value)
        setCardView(value);
    }
    const uploadReadme = async (file) => {
        let data = new FormData()
        data.append("id", projectDetail.id)
        data.append("name", projectDetail.name)
        data.append("description", projectDetail.description)
        data.append("project_type", projectDetail.project_type_id == 1 ? 'public' : 'private')
        data.append("project_topics", projectDetail.project_topics || 1);
        data.append("readme", file || '');
        const res = await editProjectById(data);
        if (res.data) {
            setProjectDetail({ ...res.data, username: user?.username })
            setReadme({});
            if (!file) return toast.success("File removed successfully");
        }
    }

    const handleFileUpload = (file) => {
        if (!file.type.includes("pdf"))
            return toast.error("Only PDF format is supported")
        else
            return toast.promise(
                uploadReadme(file),
                {
                    loading: 'Uploading File...',
                    success: <p>Readme file Uploaded!</p>,
                    error: <p>Could not upload file!</p>,
                }
            )
    }

    const onChange = async () => {
        const res = await updateProjectSpotlight({ status: spotlight, projectId: projectDetail?.id })
        if (res.success) {
            toast.success(`${Boolean(spotlight) ? 'Added to' : 'Removed from'} project spotlight`, { id: 'success' })
        }
        setLoad(false)
    }

    useEffect(() => { if (load) onChange() }, [spotlight]);


    const likeProject = async () => {
        if (!updating) {
            setUpdating(true);
            setLiked(!liked);
            let res = await likeDislikeProject({ project_id: projectDetail?.id, action: 1 })
            if (res.data) {
                setLikes(res.data.likes)
            }
            setUpdating(false);
        }
    }
    const handleFrame = async () => {
        if (!updating) {
            setUpdating(true);
            setFramed(!framed);
            let res = await frameProject({ project_id: projectDetail.id, action: 1 })
            if (res.data) {
                setFrames(res.data.frames)
            }
            setUpdating(false);
        }
    }

    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData }} className="lg:max-w-sm hidden lg:inline-flex items-center">
                            {projectDetail &&
                                <div className='px-10 py-5 w-full'>
                                    <div className='flex items-center gap-2 flex-wrap'>
                                        <span className='cursor-pointer font-semibold text-gray-900 hover:text-blue-500' onClick={() => history?.push(`/${userData?.username}/`)}>Project {" >"}</span>
                                        <span className='text-gray-800 font-medium cursor-pointer hover:text-blue-500' onClick={navigate}>{projectDetail?.name}</span>
                                    </div>
                                    <div className='flex items-start justify-between w-full my-6'>
                                        <div className='flex-grow'>
                                            <h1 className='text-2xl xl:text-3xl font-semibold flex-grow'>{project}</h1>
                                            <p className='text-base text-blue'>{projectDetail?.topic}</p>
                                        </div>
                                        <span className='bg-blue text-white px-5 rounded-full py-1 my-1 font-medium'>{projectDetail?.project_type_id === 1 ? 'Public' : 'Private'}</span>
                                        {projectDetail?.project_type_id === 1 &&
                                            <span className='p-1 m-1 w-8 h-8 cursor-pointer' title="Share Project" onClick={() => { window.navigator.clipboard.writeText(`${window.location.href}`); toast("Project Path Copied", { id: 'copy' }) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                    <path fillRule="evenodd" d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z" clipRule="evenodd" />
                                                </svg>
                                            </span>}
                                    </div>
                                    <p className='text-gray-500 mt-8 overflow-y-hidden'>{projectDetail.description || `This is the description text for the Project ${project}`}</p>

                                    <div className='flex items-center mt-8'>
                                        <h3 className='text-xl font-semibold text-gray-800'>Contributors {contributors?.length > 0 && `(${contributors?.length + 1}/${user?.plan?.limit?.project_contributors || 0})`}</h3>
                                    </div>

                                    <div className='my-3 flex items-center flex-wrap'>
                                        {contributors.length ? <div className='relative group'>
                                            <ContributorHover data={{ name: projectDetail?.user_name, username: projectDetail?.username, profile_pic: projectDetail?.profile_pic }} />
                                            <img className='w-10 h-10 rounded-full my-1 mx-2 border' src={projectDetail?.profile_pic || UserImage} alt='' />
                                        </div> : '😓😓😓'}
                                        {
                                            contributors?.map((contributor, i) => <div key={i} className='relative group'>
                                                <ContributorHover data={contributor} />
                                                <img alt='' className='w-10 h-10 rounded-full my-1 mx-2 border' src={contributor.profile_pic || UserImage} />
                                            </div>
                                            )
                                        }
                                    </div>
                                    {projectDetail?.username === user?.username ?
                                        <>
                                            <p className='text-blue cursor-pointer'
                                                onClick={() => setOpenPopUp(true)}
                                            >Manage Contributors</p>
                                            <p className='text-red cursor-pointer'
                                                onClick={() => setIsTransfer(true)}
                                            >Transfer Ownership</p>
                                        </>
                                        :
                                        <p style={{ color: 'blue', cursor: 'pointer' }}
                                            onClick={() => setIsViewContributor(true)}
                                        >View Contributors</p>
                                    }

                                    <div className='flex items-center gap-5 w-full my-3'>
                                        {
                                            liked
                                                ?
                                                <div onClick={likeProject} className='flex items-center gap-2 rounded-xl border-2 border-transparent bg-blue text-white px-2 py-1 flex-grow cursor-pointer'>
                                                    <img src={LikeWh} className='w-10' alt="" />
                                                    <div className='text-right flex-grow'>
                                                        <p className='text-sm font-medium'>Spray it!</p>
                                                        <p className=''>{formatLargeNumber(likes)}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div onClick={likeProject} className='flex items-center gap-2 rounded-xl border-2 border-[#092C4C]  text-navy px-2 py-1 flex-grow cursor-pointer'>
                                                    <img src={Like} className='w-10' alt="" />
                                                    <div className='text-right flex-grow'>
                                                        <p className='text-sm font-medium'>Spray it!</p>
                                                        <p className=''>{formatLargeNumber(likes)}</p>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            framed
                                                ?
                                                <div onClick={handleFrame} className='flex items-center gap-2 rounded-xl border-2 border-transparent bg-blue text-white px-2 py-1 flex-grow cursor-pointer'>
                                                    <img src={FrameWh} className='w-8' alt="" />
                                                    <div className='text-right flex-grow'>
                                                        <p className='text-sm font-medium'>Frame it!</p>
                                                        <p className=''>{formatLargeNumber(frames)}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div onClick={handleFrame} className='flex items-center gap-2 rounded-xl border-2 border-[#092C4C] text-navy px-2 py-1 flex-grow cursor-pointer'>
                                                    <img src={Frame} className='w-8' alt="" />
                                                    <div className='text-right flex-grow'>
                                                        <p className='text-sm font-medium'>Frame it!</p>
                                                        <p className=''>{formatLargeNumber(frames)}</p>
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                    {(projectDetail?.username === user?.username && (user?.plan?.name?.includes('free') || user?.plan?.name?.includes('pro'))) && <div className='my-5 border border-dashed p-3 bg-white rounded-md w-full'>
                                        <>
                                            <div className='flex items-center justify-between'>
                                                <h1 className='font-medium text-navy group relative min-w-max max-w-max'>Project Overview <Help fontSize='small' className='text-gray-500 cursor-pointer mx-2' />
                                                    <div className='text-gray-500 p-2 rounded popup-bg border font-light absolute bottom-8 w-48 z-10 hidden group-hover:block transition-all duration-300 ease-in-out select-none' style={{ fontSize: '10px', lineHeight: 1.2 }}>
                                                        <div className='absolute transform -bottom-2 right-9 w-4 h-4 border rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                        <h1 className='font-medium text-gray-900'>Public:</h1>
                                                        All files and folders will be visible to users visiting project page. The project can be downloaded and modified. Users can search for this project.
                                                    </div>
                                                </h1>
                                                {(projectDetail?.readme && projectDetail?.readme != 'undefined') && <PictureAsPdf
                                                    fontSize='large'
                                                    onClick={() => dispatch(preview({ isOpen: true, pdfUrl: projectDetail?.readme, title: projectDetail?.name }))}
                                                    className='text-green cursor-pointer' />}
                                            </div>
                                            {(projectDetail?.readme && projectDetail?.readme != 'undefined') ? <>
                                                <div className='flex items-center gap-4 my-4'>
                                                    <h1 className='text-blue font-medium cursor-pointer' onClick={() => fileRef.current?.click()}>Edit <Edit fontSize='small' /></h1>
                                                    <h1 className='text-red font-medium cursor-pointer' onClick={() => setReadme({ delete: true })}>Delete <Delete fontSize='small' /></h1>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <h1 className='text-base font-medium text-gray-800'>View in Spotlight</h1>
                                                    <Switch
                                                        color="primary"
                                                        disabled={load}
                                                        defaultChecked={projectDetail?.spotlight}
                                                        checked={spotlight}
                                                        onChange={(e) => { setLoad(true); setSpotlight(Boolean(e.target.checked)) }}
                                                    />
                                                </div>
                                            </>
                                                :
                                                <></>}
                                            {projectDetail?.readme && projectDetail?.readme != 'undefined' ?
                                                <>
                                                </>
                                                :
                                                <div className='flex items-center justify-center mt-4'>
                                                    <FileUploader
                                                        multiple={false} // set true if multiple file needs to be uploaded
                                                        handleChange={handleFileUpload} // function to get uploaded files
                                                        name="file"
                                                        types={['pdf']} // array of extension
                                                        children={
                                                            // add your own class as these classes are not supported in bootstrap
                                                            <div className='flex flex-col items-center justify-center'>
                                                                {/* upload icon  */}
                                                                <CloudUpload className='text-gray-300' style={{ fontSize: 100 }} />
                                                                <h1>Drag & drop to upload or <span className='text-blue hover:text-blue-600 cursor-pointer'>browse</span></h1>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            }
                                        </>
                                        <input type="file" ref={fileRef} className='hidden' accept="application/pdf" value="" onChange={(e) => handleFileUpload(e.target.files[0])} />
                                    </div>}

                                    {(projectDetail?.username === user?.username && (user?.plan?.name?.includes('free') || user?.plan?.name?.includes('pro'))) ?
                                        <>
                                            {/* <button className='py-2 px-4 rounded-lg bg-blue text-white mt-4' onClick={() => setIsRetailer(true)}>
                                                Manage Retailers
                                            </button> */}
                                        </>
                                        : retailer?.status == 'pending' ?
                                            <div className='mt-4'>
                                                <h1>Going Ahead with Order?</h1>
                                                <div className='w-full flex items-center gap-4 mt-2'>
                                                    <button className='py-1.5 px-6 rounded-lg bg-blue text-white' onClick={async () => {
                                                        let res = await updateRetailerInvite({ id: retailer?.id, status: 'accepted' })
                                                        if (res.data.id) {
                                                            toast.success('Invite Accepted');
                                                            window.location.reload();
                                                        }
                                                    }}>Accept</button>
                                                    <button className='py-1.5 px-6 rounded-lg bg-red text-white' onClick={async () => {
                                                        let res = await updateRetailerInvite({ id: retailer?.id, status: 'rejected' })
                                                        if (res.data.id) {
                                                            toast.success('Invite Rejected');
                                                            window.location.reload();
                                                        }
                                                    }}>Reject</button>
                                                </div>
                                            </div>
                                            : ''}
                                </div>
                            }
                        </UserCard>


                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                {!projectDetail && <> <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push('/dashboard')}>Insights</h1>
                                    <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/`)}>Projects</h1>
                                </>
                                }
                                <h1 className={`text-lg font-medium cursor-pointer leading-loose ${state === 'repository' ? 'text-navy border-b-2 border-blue-500' : 'text-gray-500'}`} onClick={() => setState('repository')}>Repositories {repos?.length > 0 && projectDetail ? `(${repos.length})` : ''}</h1>
                                <> {projectDetail ?
                                    <>
                                        {(projectDetail?.created_by == user?.id || accessRight == 1) && <h1 className={`text-lg font-medium cursor-pointer leading-loose ${state === 'issue' ? 'text-yellow border-b-2 border-yellow-400' : 'text-yellow'}`} onClick={changeState}>Issues {issues?.length > 0 && projectDetail ? `(${issues.length})` : ''}</h1>}
                                        {projectDetail?.created_by == user?.id ? <h1 className='text-lg font-medium text-green cursor-pointer leading-loose' onClick={() => history?.push(`/${username}/${projectDetail?.name}/tags`)}>Tags</h1> :
                                            isAccess ?
                                                <h1 className='text-lg font-medium text-green cursor-pointer leading-loose' onClick={() => setState('tag')}>Tags</h1>
                                                :
                                                <></>
                                        }
                                    </>
                                    : <>
                                        {user && <h1 className={`text-lg font-medium cursor-pointer leading-loose ${state === 'issue' ? 'text-navy border-b-2 border-blue-500' : 'text-gray-500'}`} onClick={() => history?.push(`/${user?.username}/issues`)}>Issues</h1>}
                                        {user && <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/events`)}>Events</h1>}
                                        {user?.plan?.name == 'organization' ? <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/retailer`)}>Retailer</h1> : <></>}
                                    </>
                                }
                                </>
                                {state === 'repository' && <div className='flex flex-grow justify-end pr-4 gap-2'>
                                    <div className={`px-1 pb-1 rounded border ${cardView ? 'bg-white border' : 'bg-red'}`}><ViewListIcon fontSize='small' className={`${cardView ? 'text-gray-300' : 'text-white'} cursor-pointer`} onClick={() => handleCardView(false)} /></div>
                                    <div className={`px-1 rounded border ${cardView ? 'bg-red' : 'bg-white'}`}><AppsIcon fontSize='small' className={`${cardView ? 'text-white' : 'text-gray-300'} cursor-pointer`} onClick={() => handleCardView(true)} /></div>
                                </div>}
                            </div>

                            {state === 'repository' ?
                                <div className='w-full'>
                                    <div className='flex items-center justify-between my-4 gap-4'>
                                        <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                                            <SearchOutlined />
                                            <input type="text" placeholder='Search repository here...' onChange={(e) => repos.length ? setRepoSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                        </div>

                                        <div className='flex items-center gap-4 relative z-30'>
                                            <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                                <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                                {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                    : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                                {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12 left-0'>
                                                    <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortRepoByName}>Name</p>
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortRepoByCreated}>Date Created</p>
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortRepoByUpdated}>Date Updated</p>
                                                    <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={sortRepoBySize}>Repo Size</p>
                                                </div>}
                                            </div>
                                            <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleRepoOrder}>
                                                <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                            </div>
                                            {((projectDetail?.created_by == user?.id && user) || (isAccess && accessRight == 1)) && <button className={`px-10 py-2 text-white rounded min-w-max relative group ${(user?.organization ? user.role !== 'user' : true) ? 'bg-blue' : 'bg-gray-300'}`} onClick={() => (user?.organization ? user.role !== 'user' : true) ? canAdd ? setAddModal(true) : setModal(true) : {}}>
                                                Create Repository

                                                {!(user?.organization ? user.role !== 'user' : true) ? <span className='absolute -top-12 right-0 w-[300px] bg-gray-100 text-gray-800 text-xs py-1.5 px-3 rounded hidden shadow group-hover:block'>
                                                    To create project/repo contact an admin or sub admin. Or request for admin access
                                                </span> : <></>}
                                            </button>}
                                        </div>
                                    </div>
                                    <div className='my-4' style={{ maxHeight: '70dvh', overflowY: 'auto' }}>
                                        {!repos?.length
                                            ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Repository Added</h1>
                                            :
                                            <>
                                                {!cardView ? <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                    <thead>
                                                        <tr className='font-medium text-gray-500'>
                                                            <td>Name</td>
                                                            <td>Privacy</td>
                                                            <td>Description</td>
                                                            <td>Files</td>
                                                            <td className='text-center'>Project</td>
                                                            <td className='text-center'>Action</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                        {
                                                            retailer && projectDetail?.project_type_id == 2 ?
                                                                repos?.filter(item => retailer ? JSON.parse(retailer?.repo_list)?.includes(Number(item?.id)) : true).map(repo => repo.name.toLowerCase().includes(repoSearch.trim().toLowerCase()) && <RepoList retailer={retailer} setRepos={setRepos} username={username} key={repo.id} repo={repo} project={project} projectDetail={projectDetail} />)
                                                                :
                                                                repos?.filter(item => !projectDetail ? item : item.project_id == projectDetail.id).map(repo => repo.name.toLowerCase().includes(repoSearch.trim().toLowerCase()) && <RepoList setRepos={setRepos} username={username} key={repo.id} repo={repo} project={project} projectDetail={projectDetail} />)
                                                        }
                                                    </tbody>
                                                </table>
                                                    :
                                                    <>
                                                        <div className='flex flex-wrap items-center justify-start gap-8'>
                                                            {
                                                                retailer && projectDetail?.project_type_id == 2 ?
                                                                    repos?.filter(item => retailer ? JSON.parse(retailer?.repo_list)?.includes(Number(item?.id)) : true)?.map(repo => repo.name.toLowerCase().includes(repoSearch.trim().toLowerCase()) && <RepoCard retailer={retailer} setRepos={setRepos} username={username} key={repo.id} repo={repo} project={project} projectDetail={projectDetail} />)
                                                                    : repos?.filter(item => !projectDetail ? item : item.project_id == projectDetail.id).map(repo => repo.name.toLowerCase().includes(repoSearch.trim().toLowerCase()) && <RepoCard setRepos={setRepos} username={username} key={repo.id} repo={repo} project={project} projectDetail={projectDetail} />)
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                :
                                state === 'issue' ?
                                    <div className='w-full'>
                                        <div className='flex items-center my-2 py-2 gap-10 border-b'>
                                            <h1 className={`text-lg cursor-pointer text-gray-500 border-b ${(!openIssue && !closedIssue) ? 'border-gray-500' : 'border-white'}`} onClick={() => { setOpenIssue(false); setClosedIssue(false); }}>All ({issues?.length ?? 0})</h1>
                                            <h1 className={`text-lg cursor-pointer text-blue border-b ${openIssue ? 'border-blue-500' : 'border-white'}`} onClick={e => { setOpenIssue(true); setClosedIssue(false); }}>Open {`(${openCount})`}</h1>
                                            <h1 className={`text-lg cursor-pointer text-green border-b ${closedIssue ? 'border-green-500' : 'border-white'}`} onClick={e => { setClosedIssue(true); setOpenIssue(false); }}>Closed {`(${closedCount})`}</h1>
                                        </div>
                                        <div className='flex items-center justify-between my-4'>
                                            <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                                                <SearchOutlined />
                                                <input type="text" placeholder='Search issue here...' onChange={(e) => issues.length ? setIssueSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                            </div>

                                            <div className='flex items-center gap-4 relative z-30'>
                                                <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                                    <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                                    {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                        : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                                    {isSort && <div className='p-2 popup-bg rounded-lg shadow-lg absolute top-12 -left-2'>
                                                        <div className='absolute transform -top-1 left-1/2 -translate-x-1/2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />

                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByName}>Name</p>
                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max border-b' onClick={sortIssueByCreated}>Date Created</p>
                                                        <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 min-w-max' onClick={sortIssueByUpdated}>Date Updated</p>
                                                    </div>}
                                                </div>
                                                <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleIssueOrder}>
                                                    <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                                </div>
                                                {/* <button className='px-10 py-2 bg-blue text-white rounded min-w-max' onClick={() => history.push(`/${userData.username}/${project}/${repo}/file/issue/new`)}>
                                        Create Issue
                                    </button> */}
                                            </div>
                                        </div>
                                        <div className='my-4' style={{ maxHeight: '65dvh', overflowY: 'auto' }}>
                                            {!issues?.length
                                                ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Issue Added</h1>
                                                :
                                                <>
                                                    <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                        <thead>
                                                            <tr className='font-medium text-gray-500'>
                                                                <td className='opacity-0'>opacity</td>
                                                                <td>Issue</td>
                                                                <td>Author</td>
                                                                <td className='text-center'>Repository</td>
                                                                <td className='text-center'>Assignee</td>
                                                                <td className='text-center'>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                            {
                                                                (openIssue || closedIssue) ?
                                                                    issues?.map(issue => (closedIssue ? issue.issue_status == 'Closed' : openIssue ? issue.issue_status == 'Open' : '') && issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                                    :
                                                                    issues?.map(issue => issue.title.toLowerCase().includes(issueSearch.trim().toLowerCase()) && <IssueList key={issue.id} issue={issue} />)
                                                            }
                                                        </tbody>
                                                    </table>

                                                </>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='w-full'>
                                        <div className='my-4' style={{ maxHeight: '70dvh', overflowY: 'auto' }}>
                                            {!tagList?.length
                                                ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Tag Added</h1>
                                                :
                                                <>
                                                    <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                                        <thead>
                                                            <tr className='font-medium text-gray-500'>
                                                                <td className='opacity-0'>Tag</td>
                                                                <td>Tag Description</td>
                                                                <td>Repositories</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                            {
                                                                tagList?.map(tag =>
                                                                    <tr key={tag.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                                                                        <td className='font-medium text-gray-800'>
                                                                            <div className='px-4'><Tag text={tag.name} color={tag.color} /></div>
                                                                            <p className='text-gray-500 font-light text-xs mt-3'>Updated {moment(tag.created_at).fromNow()}</p>
                                                                        </td>
                                                                        <td className='max-w-sm'>
                                                                            <p className='max-w-sm'>{tag.description || 'no description provided'}</p>
                                                                        </td>
                                                                        <td className='max-w-sm'>
                                                                            <p className='max-w-sm'>{tag?.repos?.length ? tag.repos.map((item, i) => item.name).join(", ") : '-'}</p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>

                                                </>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </>
                )}
            </div >
            {addModal && <AddRepository getFreshRepositoryList={getAllRepos} projectData={projectDetail} setAddModal={setAddModal} username={username} />
            }
            {emailInvite && <InviteViaEmail setEmailInvite={setEmailInvite} setOpenPopUp={setOpenPopUp} type='project' id={projectDetail.id} />}
            <ConfirmModal open={readme?.delete} heading={`Are you sure you want to delete the readme file`} description="You can upload a new one again" type="warn" okText="Yes" onCancel={() => setReadme({})} onOk={() => uploadReadme(null)} />
            {
                (openPopup || removeCreator) && <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1001 }}>
                    <div className='max-w-xl bg-white p-5 w-full rounded-md h-[80vh] flex flex-col overflow-y-auto'>
                        <div className='flex items-center gap-5 border-b mb-4'>
                            <button className={`text-lg font-semibold text-blue hover:text-blue-600 border py-1 px-2 select-none bg-white border-b-0 ${openPopup ? '-mb-0.5' : ''}`} onClick={() => { setOpenPopUp(true); setRemoveCreator(false) }}>Add Contributor</button>
                            <button className={`text-lg font-semibold text-red hover:text-red-500 border py-1 px-2 select-none  bg-white border-b-0 ${removeCreator ? '-mb-0.5' : ''}`} onClick={() => { setRemoveCreator(true); setOpenPopUp(false) }}>Edit Contributor</button>
                            <button className='ml-auto' onClick={() => { setOpenPopUp(false); setRemoveCreator(false) }}><CloseOutlined fontSize='large' className='cursor-pointer text-gray-800 hover:text-black' /></button>
                        </div>
                        <>
                            {
                                openPopup ?
                                    <AddContributor setOpenPopUp={setOpenPopUp} setEmailInvite={setEmailInvite} type='project' canInvite={canInvite} setCanInvite={setCanInvite} plan={user?.plan} id={projectDetail.id} setRemoveCreator={setRemoveCreator} />
                                    :
                                    removeCreator ?
                                        <RemoveContributor contributors={contributors} setContributors={setContributors} setRemoveCreator={setRemoveCreator} type='project' plan={user?.plan} setCanInvite={setCanInvite} id={projectDetail.id} />
                                        :
                                        <></>
                            }
                        </>

                    </div>
                </div>
            }
            {isTransfer ? <TransferOwnership contributors={contributors} setIsTransfer={setIsTransfer} type='project' id={projectDetail.id} /> : <></>}

            {isViewContributor && <ContributorList contributors={[...contributors, { name: projectDetail?.user_name, username: projectDetail?.username, profile_pic: projectDetail?.profile_pic }]} onClose={() => setIsViewContributor(false)} />}

            <ConfirmModal open={modal} heading={`You have reached your repository limit`} description={`to create a new one, remove at least one of your repository`} type="warn" okText='Ok' cancelText="Back" onCancel={() => setModal(false)} onOk={(e) => setModal(false)} />
            {/* {isRetailer ? <InviteRetailer type={"project"} projectId={projectDetail?.id} fileList={repos} onClose={() => setIsRetailer(false)} /> : <></>} */}
        </>
    )
}

export default Repository

