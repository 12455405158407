import React, { useState } from 'react'
import {
    Button,
} from "@material-ui/core";
import { restorSettings } from '../../../service/service'
import useStyle from '../Styling'
import toast from 'react-hot-toast';

function Restore(props) {
    const settings_type = props.type
    let data = settings_type;

    const callRestore = async () => {
        const res = await restorSettings(data);
        props.getSettingsList()
        props.restoreUpdate()
        if (res.success) {
            toast.success("Settings Restored Sucessfully")
        } else {
            toast.error("Something went wrong")
        }
    }

    const classes = useStyle();
    return (
        <div>
            <div className={classes.buttonGroup}>
                <Button
                    variant="contained"
                    className={classes.restoreBtn}
                    onClick={callRestore}>
                    Restore
                </Button>
            </div>
        </div>
    )
}

export default Restore
