import React from 'react'

function EditIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7156 2.5C14.5073 2.5 14.2906 2.58333 14.1323 2.74167L12.6073 4.26667L15.7323 7.39167L17.2573 5.86667C17.5823 5.54167 17.5823 5.01667 17.2573 4.69167L15.3073 2.74167C15.1406 2.575 14.9323 2.5 14.7156 2.5ZM11.7156 7.51665L12.4823 8.28332L4.93228 15.8333H4.16561V15.0667L11.7156 7.51665ZM2.49893 14.375L11.7156 5.15834L14.8406 8.28334L5.62393 17.5H2.49893V14.375Z" fill="#2DC8A8" />
        </svg>

    )
}

export default EditIcon