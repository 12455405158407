import React, { useState } from 'react';
import './ViewCommonQs.css';
import { commonQs } from '../../utils/constant';
import faqArt from "../../assets/images/FAQ_Art.png";
import logo from "../../assets/images/Greyffiti-White-Logo.png";
import {
    Add,
    Close,
} from "@material-ui/icons";
import { Link } from 'react-router-dom';

export default function FAQs() {

    const [data, setData] = useState(commonQs);

    const handleAddIcon = (index) => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: id === index
            }
        })
        setData(newData)
    }

    const handleCloseIcon = () => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: false
            }
        })
        setData(newData)
    }

    return (
        <div className="faqcontainer relative">
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <div className="main-container">
                <div className="left-container hidden sm:inline-block">
                    <img alt="" src={faqArt} className="questionSymbol" />
                </div>
                <div className="right-container">
                    <div className="top-container">
                        <p className="question">Have a <span style={{ color: "white" }}>Question?</span></p>
                    </div>
                    <br />
                    <div className='mainquestions'>
                        {data.map((obj, id) =>
                            obj.open ? <>
                                <p className="repo cursor-pointer" onClick={handleCloseIcon} >{obj.question}
                                    <Close className="closeIcon"/></p>
                                <p className="content">{obj.answer}</p>
                                <hr />
                            </>
                                :
                                <>
                                    <p className="addcontent cursor-pointer" onClick={() => handleAddIcon(id)}>{obj.question}
                                        <Add className="addIcon" /></p>
                                    <hr />
                                </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}