import React, { useState, useEffect } from 'react'
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core'

import { SettingsData as settingsData } from './components/data'
import Header from './components/Header';
import AccountSettings from './AccountSettings'
import Access from './Access'
import Privacy from './Privacy'
import SystemSettings from './SystemSettings';
import SecurityandLogin from './SecurityandLogin';
import Deactivate from './Deactivate';
import { settingsList } from '../../service/service';
import Navbar from '../ReusableComponents/Navbar';
import toast from 'react-hot-toast';

const useStyle = makeStyles((theme) => ({
    settingMenuScroll: {
        height: 'calc(100% - 100px)',
        overflow: 'scroll'
    },
    settingsMenu: {
        // maxHeight: 'calc(100% - 123px)',
        // overflow: 'scroll'
        border: "2px solid #EEEEEE",
        borderRadius: "10px",
        overflow: 'hidden',
        marginTop: "10px",
        width: "100%"
    },
    settingBox: {
        display: 'block',
        clear: 'both',
        marginTop: '40px',
        marginLeft: '0px',
        position: 'absolute',
        height: '70%',
        width: 'calc(100% - 37%)',
        overflowY: 'auto',
        backgroundColor: "#fffff",
    },
    settingBoxHeader: {
        marginBottom: '60px',
        height: '100px',
        width: '100%',
        // marginLeft: '0.5%',
        marginTop: '0px'
    },
    divSettingMain: {
        width: "80%",
        // height: '50%',
        marginLeft: "9%",
        backgroundColor: "#fffff",
        // height: 'calc(100% - 100px)',
        // overflow: 'scroll',
        '@media (min-width: 1280px)': {
            flexGrow: '0',
        }
    },
    list: {
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        paddingTop: '0px',
        paddingBottom: '0px',
    },

    unSelectedList: {
        backgroundColor: '#F6FAFD',
        color: '#727272',
        height: '100%',
        paddingTop: '6px',
        paddingBottom: '6px',
        '& ..MuiListItem-root': {
            paddingTop: '200px',
        },
        cursor: 'pointer'
    },
    selectedList: {
        backgroundColor: '#FE8083',
        color: 'white',
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '100%',
        '& .MuiTypography-colorTextSecondary': {
            color: 'white'
        },
        cursor: 'pointer'
    },
    settingsTitle: {
        // fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '18px',
    },
    settingsDecroption: {
        // fontFamily: 'Poppins',
        fontSize: '14px',
    },
    divider: {
        // height:'101%',
        width: '10px',
        // backgroundColor:'#F6FAFD'
    },
    rowContainer: {
        // height: '75vh'
    },
    descriptionDiv: {
        marginTop: "1%",
        width: '100%'
    },
    conTainerborder: {
        // height: '75vh'
    }
}));

function SettingsDashboard(props) {
    const classes = useStyle();
    const [load, setLoad] = useState(false)
    const [settingsListFromDB, setSettingsListFromDB] = useState([])
    const [tempArray, setTempArray] = useState([])
    const [selectedSettings, setSelectedSettings] = useState('account');
    const [pageTitle, setPageTitle] = useState("Account Settings")
    const [account, setAccount] = useState("")

    useEffect(() => {
        //   if(!load) {
        getSettingsList();
        //    }
    }, [])

    const getSettingsList = async () => {
        const response = await settingsList();
        setSettingsListFromDB(response)
        if (response.data !== undefined) {
            response.data.map(function (item) {
                if (item.setting_type === "account") {
                    if (item.setting_key === "email_notification") {
                        setAccount(item.key_value)
                    }
                };
            })
        }
        setLoad(true)
    }
    const alertMsg = (isOpen, message, type) => {
        toast(message);
    }
    const selectListItem = (id, title) => {
        setSelectedSettings(id)
        setPageTitle(title)
        // tempArray.map((item) => {
        //        if (item.setting_type == selectedSettings) {
        //             setSettingsListFromDB(item.key_value)
        //             console.log("Account---->",settingsListFromDB)
        //        };
        //     })
    }

    return (
        <>
            <Navbar />
            <div className={classes.divSettingMain}>
                <Grid container direction='row' xs={12} sm={12} className={classes.rowContainer}>
                    <Grid container direction='column' xs={4} sm={4} lg={4} className={classes.settingsMenu}>
                        <Grid item xs={12} sm={12} lg={12}>
                            {settingsData.map((settings) => (
                                <List className={classes.list} >
                                    <ListItem className={selectedSettings === settings.id ? classes.selectedList : classes.unSelectedList}>
                                        <ListItemAvatar>
                                            {selectedSettings === settings.id ? settings.whiteIcon : settings.icon}
                                        </ListItemAvatar>
                                        <ListItemText
                                            onClick={() => { selectListItem(settings.id, settings.title) }}
                                            primary={
                                                <React.Fragment>
                                                    <h1 className={`font-semibold ${selectedSettings === settings.id ? 'text-white' : 'text-gray-600'}`}>{settings.title}</h1>
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <p className={`text-sm py-1 ${selectedSettings === settings.id ? 'text-white' : 'text-gray-600'}`}>{settings.description}</p>
                                                </React.Fragment>
                                            }>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </List>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container item direction='column' xs={8} sm={8} lg={8} className={classes.conTainerborder}>
                        <div className={classes.settingBoxHeader}>
                            <Header title={pageTitle} />
                        </div>
                        
                        {/* <br /> */}
                        {load && <div className={classes.settingBox}>
                            {selectedSettings === 'account' ? <AccountSettings settingsListOld={settingsListFromDB} getSettingsList={getSettingsList} accountSetting={account} alertMsg={alertMsg} /> : " "}
                            {selectedSettings === 'access' ? <Access /> : " "}
                            {selectedSettings === 'privacy' ? <Privacy settingsListOld={settingsListFromDB} getSettingsList={getSettingsList} alertMsg={alertMsg} /> : " "}
                            {selectedSettings === 'system' ? <SystemSettings settingsListOld={settingsListFromDB} getSettingsList={getSettingsList} alertMsg={alertMsg} /> : " "}
                            {selectedSettings === 'security' ? <SecurityandLogin settingsListOld={settingsListFromDB} getSettingsList={getSettingsList} alertMsg={alertMsg} /> : " "}
                            {selectedSettings === 'deactivate' ? <Deactivate /> : " "}
                        </div>}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default SettingsDashboard
