export const UserPageWebPortal = 'https://res.cloudinary.com/ankit628792/image/upload/v1663926518/Greyffiti/lbrrb7etxaolc8rp4ioy.svg'

export const UserBannerImage = "https://res.cloudinary.com/ankit628792/image/upload/v1663929270/Greyffiti/byk8mqr9brdmwf84enrj.svg"

export const Readme_Not_Present = "https://res.cloudinary.com/ankit628792/image/upload/v1663926407/Greyffiti/ob71a9epkvfvdirfcmqa.png"

export const Readme_Present = "https://res.cloudinary.com/ankit628792/image/upload/v1663926408/Greyffiti/jcghb5eowlr2fisbjqys.png"

export const UserImage = "https://res.cloudinary.com/ankit628792/image/upload/v1664790599/Greyffiti/k2tuxjobsbcd1melvnto.png"

export const GImage = "https://res.cloudinary.com/ankit628792/image/upload/v1664790444/Greyffiti/u5wuwyp81fkysgztexfg.png"

export const BranchIcon = "https://res.cloudinary.com/ankit628792/image/upload/v1664790522/Greyffiti/fcxa6zijep4ekls69db8.svg"

export const Thumbnail = "https://res.cloudinary.com/ankit628792/image/upload/v1664790646/Greyffiti/ouocew7wh8a69k6bsvip.png"

export const ProjectPDF = "https://res.cloudinary.com/ankit628792/image/upload/v1664790646/Greyffiti/gqwfgxrjpuig8ugiacmb.png"