import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import Landing from "./components/Landing/Landing";
import Login from "./components/Login/Login";
import Registration from "./components/Register/Registration";
import PublicRoute from "./PublicRoute";
import './App.css'
import TermsAndCondition from "./components/SingleUseComponent/TermsAndCondition";
import Pricing from "./components/Landing/Pricing";
import Privacy from "./components/PrivacyPolicy/Privacy";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import CheckMail from "./components/ForgotPassword/checkMail";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import PasswordChanged from "./components/ForgotPassword/PasswordChanged";
import NotifyEmail from "./components/ConfirmEmail/NotifyEmail";
import EmailVerified from "./components/ConfirmEmail/EmailVerified";
import EmailConfirm from "./components/ConfirmEmail/EmailConfirm";
import Thankyou from "./components/SingleUseComponent/Thankyou";
import FAQs from "./components/SingleUseComponent/FAQs";
import AcceptEmail from "./components/ConfirmEmail/AcceptEmail";
import ErrorPage from "./components/ReusableComponents/ErrorPage";
import UserProfile from "./components/UserProfile/UserProfile";
import Home from "./components/Dashboard/Home";
import PrivateRoute from "./PrivateRoute";
import Project from "./components/Dashboard/Project";
import Tags from "./components/Dashboard/Tags";
import Repository from "./components/Dashboard/Repository";
import Files from "./components/Dashboard/Files";
import Issues from "./components/Dashboard/Issues";
import FeedHome from "./components/Feed/FeedHome";
import CreateIssue from "./components/Issue/CreateIssue";
import Branch from "./components/Dashboard/Branch";
import IssueDetail from "./components/Issue/IssueDetail";
import EditIssue from "./components/Issue/EditIssue";
import PdfViewer from "./components/ReusableComponents/PdfViewer";
import ProfileHome from "./components/Profile/ProfileHome";
import Edit from "./components/Profile/Edit";
import SettingsDashboard from "./components/Account/SettingsDashboard";
import FileHistory from "./components/Dashboard/FileHistory";
import ExploreHome from "./components/Explore/ExploreHome";
import NewPricing from "./components/ReusableComponents/NewPricing";
import Thumbnail from "./components/ReusableComponents/Thumbnail";
import EventsHome from "./components/Event/EventsHome";
import DetailedEvent from "./components/Event/DetailedEvent";
import Events from "./components/Dashboard/Events";
import EventAdmin from "./components/Event/EventAdmin";
import EditEvent from "./components/Event/EditEvent";
import Profile from "./components/Organization/Profile";
import Members from "./components/Organization/Members";
import NewEvent from "./components/Event/NewEvent";
import Submission from "./components/Event/Submission";
import AdminHome from "./components/Admin/AdminHome";
import ProUser from "./components/Profile/ProUser";
import ProfileScreen from "./components/Profile";
import Retailer from "./components/Dashboard/Retailer";
import RetailerInviteLink from "./components/Dashboard/RetailerInviteLink";
import MemberInvite from "./components/Company/MemberInvite";

function App() {

  return (
    <>
      <div><Toaster containerStyle={{ textAlign: 'center' }} /></div>

      <PdfViewer />
      <Thumbnail />
      <Router>
        <div className="max-w-8xl mx-auto">
          <Switch>
            <PublicRoute exact path="/" component={Landing} />
            <PublicRoute path="/404" component={ErrorPage} exact />
            <PublicRoute path="/pricing" component={NewPricing} exact />
            <PublicRoute path="/privacy" component={Privacy} exact />
            <PublicRoute path="/checkMail" component={CheckMail} exact />
            <PublicRoute path="/resetPassword/:key" component={ResetPassword} exact />
            <PublicRoute path="/passwordChanged" component={PasswordChanged} exact />
            <PublicRoute path="/register" component={Registration} exact />
            <PublicRoute path="/login" component={Login} exact />
            <PublicRoute path="/forgotPassword" component={ForgotPassword} exact />
            <PublicRoute path="/termsAndConditions/:portal" component={TermsAndCondition} exact />
            <PublicRoute path="/notifyEmail" component={NotifyEmail} exact />
            <PublicRoute path="/emailVerified/:key" component={EmailVerified} exact />
            <PublicRoute path="/confirmEmail/:verificationKey" component={EmailConfirm} exact />
            <PublicRoute exact path="/verifyEmail" component={() => <NotifyEmail verified={true} />} />

            <PrivateRoute path="/admin/settings" component={AdminHome} exact />

            <PrivateRoute path="/userProfile/edit" component={Edit} exact />
            <PublicRoute path="/userProfile/:username" component={ProfileScreen} exact />
            {/* <PublicRoute path="/userProfile/:username/pro" component={ProUser} exact /> */}
            <PrivateRoute path="/setting" component={SettingsDashboard} exact />

            <PublicRoute path="/userProfileSetup" component={UserProfile} exact />
            <PublicRoute path="/faq" component={FAQs} exact />
            <PublicRoute path="/thankyou" component={Thankyou} exact />
            <PublicRoute path="/feed" component={FeedHome} exact />
            <PublicRoute path="/explore" component={ExploreHome} exact />
            <PublicRoute path="/events" component={EventsHome} exact />
            <PublicRoute path="/organization/organizationId" component={Profile} exact />
            <PrivateRoute path="/organization/:organizationId/event/:eventId/submissions" component={Submission} exact />
            <PublicRoute path="/organization/organizationId/members" component={Members} exact />
            <PrivateRoute path="/organization/:organizationId/events/create" component={NewEvent} exact />
            <PrivateRoute path="/organizationId/events" component={EventAdmin} exact />
            <PrivateRoute path="/organizationId/event/:eventId" component={EditEvent} exact />
            <PublicRoute path="/events/:eventId" component={DetailedEvent} exact />

            <PublicRoute path="/acceptInvitation/:key" component={AcceptEmail} exact />
            <PrivateRoute path="/retailer/:data" component={RetailerInviteLink} exact />
            <PublicRoute path="/user/invite/:data" component={MemberInvite} exact />
            <PrivateRoute path="/:username/:project/tags" component={Tags} exact />
            <PrivateRoute path="/dashboard" component={Home} exact />
            <PrivateRoute path="/:username/events" component={Events} exact />
            <PrivateRoute path="/:username/retailer" component={Retailer} exact />
            <PrivateRoute path="/:username/issues" component={Issues} exact />
            <PublicRoute path="/:username/:project/:repo/:branchInfo" component={Files} exact />
            <PublicRoute path="/:username/:project/:repo/:branchId/file/:fileId" component={FileHistory} exact />
            <PrivateRoute path="/:username/:project/:repo/branches/:repoId" component={Branch} exact />
            <PrivateRoute path="/:username/:project/:repo/issue/:issueId" component={IssueDetail} exact />
            <PrivateRoute path="/:username/:project/:repo/:fileId/issue/:issueId/edit" component={EditIssue} exact />
            <PrivateRoute path="/:username/:project/:repo/:fileId/issue/new" component={CreateIssue} exact />
            <PublicRoute path="/:username/" component={Project} exact />
            <PublicRoute path="/:username/:project" component={Repository} exact />


            <Redirect to="/404" />
          </Switch>

        </div>
      </Router>
    </>
  );
}
export default App;

