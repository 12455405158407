import React, { useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { addUserTopics } from '../../service/service'
import toast from 'react-hot-toast'
import ArrowWhite from '../../assets/icons/ArrowWhite'

function SelectTopic(props) {
  const { handleBack, activeStep, topicList , handleNext} = props
  const [flag, setFlag] = useState(false)
  const [id, setId] = useState(0)
  const [topicsDetails, setTopicsDetails] = useState(topicList)
  const [flagLoaderButton, setFlagLoaderButton] = useState(false)

  const setTopicAndFlag = () => {
    if (flag) {
      topicsDetails.forEach(topic => {
        if (Number(topic.id) === Number(id)) {
          topic.selected = !topic.selected
        }
      })
      setTopicsDetails(topicsDetails)
    }
    setFlag(false)
  }
  useEffect(() => setTopicAndFlag(), [flag])

  const setSelectedIcon = (id, e) => {
    setFlag(true)
    setId(id)
  }

  const handleSkip = e => {
    e.preventDefault();
    window.location.href = '/login'
  }

  const goToNext = async (e) => {
    e.preventDefault();
    setFlagLoaderButton(true)
    let data = []
    topicsDetails.forEach(i => {
      if (i.selected === true) {
        data.push(i.id)
      }
    })
    const res = await addUserTopics({ topics: data.toString() })
    if (res && res.success) {
      toast.success('Updated Successfully', { id: 'success' })
      window.location = '/login'
    } else {
      toast.error("Add at least one topic", { id: 'error' })
      setFlagLoaderButton(false)
    }
  }

  return (
    <>
      <div className='max-w-3xl mx-auto'>
        <h1 className="text-3xl lg:text-4xl font-bold text-left mb-3">Pick topics you love</h1>
        <p className='text-lg'>This will help us to bring best recommendations for you</p>

        <div className='w-full'>
          <div className='flex items-center justify-start gap-6 flex-wrap w-full my-10'>
            {topicsDetails.map(obj => <div key={obj.id}>
              <IconButton onClick={e => setSelectedIcon(obj.id, e)}>
                {obj.selected ? obj.selectedIcon : obj.icon}
              </IconButton>
              <h1 className='text-center text-lg font-medium'>
                {obj.topics}
              </h1>
            </div>
            )}
          </div>

          <div className="flex items-center justify-end mt-8 gap-6">
            <button disabled={activeStep === 0} onClick={handleBack} className='py-2 px-6 text-lg font-semibold rounded-lg flex-shrink-0 disabled:text-gray-600 text-blue border border-blue-500'>
              Back
            </button>
            <button onClick={handleSkip} className='py-2 px-6 text-lg font-semibold rounded-lg flex-shrink-0 disabled:text-gray-600 text-blue border border-blue-500'>
              Skip
            </button>
            <button disabled={flagLoaderButton} type='submit' onClick={(e) => goToNext(e)} className='flex-shrink-0 bg-blue py-2 px-4 text-lg text-white font-semibold rounded-lg'>
              Save & Next
              {flagLoaderButton ? <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block ml-2" viewBox="0 0 24 24"></svg> : <ArrowWhite className="inline-flex w-7 h-7 mx-2" />
              }
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectTopic
