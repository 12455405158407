import React from 'react'

function EmailApprovedIcon({ className }) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.73796 2.8643C10.892 1.40302 13.108 1.40302 14.262 2.8643C14.8825 3.64994 15.8662 4.05739 16.8604 3.94058C18.7097 3.72332 20.2767 5.29028 20.0594 7.13959C19.9426 8.13385 20.3501 9.11751 21.1357 9.73796C22.597 10.892 22.597 13.108 21.1357 14.262C20.3501 14.8825 19.9426 15.8662 20.0594 16.8604C20.2767 18.7097 18.7097 20.2767 16.8604 20.0594C15.8662 19.9426 14.8825 20.3501 14.262 21.1357C13.108 22.597 10.892 22.597 9.73796 21.1357C9.11751 20.3501 8.13385 19.9426 7.13959 20.0594C5.29028 20.2767 3.72332 18.7097 3.94058 16.8604C4.05739 15.8662 3.64994 14.8825 2.8643 14.262C1.40302 13.108 1.40302 10.892 2.8643 9.73796C3.64994 9.11751 4.05739 8.13385 3.94058 7.13959C3.72332 5.29028 5.29028 3.72332 7.13959 3.94058C8.13385 4.05739 9.11751 3.64994 9.73796 2.8643Z" fill="#1D85E8" />
            <path d="M10.3059 15.7412L7.05884 12.4942L8.4706 11.153L10.3059 12.9883L15.5294 7.76477L16.9412 9.17654L10.3059 15.7412Z" fill="white" />
        </svg>

    )
}

export default EmailApprovedIcon