import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    projectList: [],
    projectContributors: [],
    topicList: [],
    preview: {
        isOPen: false,
        pdfUrl: '',
        title: '',
        projectInfo: null
    }
}

export const projectSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        projectList: (state, action) => {
            state.projectList = action.payload
        },
        projectContributors: (state, action) => {
            state.projectContributors = action.payload
        },
        topicList: (state, action) => {
            state.topicList = action.payload
        },
        preview: (state, action) => {
            if (action.payload)
                state.preview = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { projectList, projectContributors, topicList, preview } = projectSlice.actions

export const selectProjectList = (state) => state.project.projectList
export const selectProjectContributors = (state) => state.project.projectContributors
export const selectTopicList = (state) => state.project.topicList
export const selectPreview = (state) => state.project.preview

export default projectSlice.reducer