import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import Insight from './Insight';
import { getProjectsListBySize, recentActivities } from '../../service/service';
import displayAction from '../../utils/displayAction';
import { useHistory } from 'react-router-dom';

const colors = ['bg-red', 'bg-yellow', 'bg-blue', 'bg-green', 'bg-purple']

function formatBytes(a, b = 2) { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return `${Math.round(parseFloat((a / Math.pow(1024, d)).toFixed(c)))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }

function Home() {
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [recentActivitiesList, setRecentActivitiesList] = useState([])
  const user = useSelector(selectUserBasicInfo);
  const userData = useSelector(selectUserDetails);
  const [projects, setProjects] = useState([])

  async function getRecentActivity() {
    const recentActivityResponse = await recentActivities()
    if (recentActivityResponse.data && recentActivityResponse.data.length) {
      const recentActivityList = recentActivityResponse.data.slice(0, 4)
      setRecentActivitiesList(recentActivityList)
    }
  }

  const getProjects = async () => {
    const res = await getProjectsListBySize(userData?.username);
    if (res.data) {
      setProjects(res.data.slice(0, 5))
    }
  }

  useEffect(() => {
    if (user && userData) {
      setLoading(false);
      getRecentActivity();
      getProjects();
    } else {
      setLoading(true)
    }
  }, [user, userData])

  return (
    <>
      <Navbar />
      <div className='flex items-start'>
        {loading ? (
          <></>
        ) : (
          <UserCard user={{ ...userData, ...user }} className="lg:max-w-sm hidden lg:inline-flex" />
        )}

        <div className='w-full flex-grow h-full p-5'>
          <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
            <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500' onClick={() => history?.push('/dashboard')}>Insights</h1>
            <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/`)}>Projects</h1>
            <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/all`)}>Repositories</h1>
            <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/issues`)}>Issues</h1>
            <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${userData?.username}/events`)}>Events</h1>
            {userData?.plan?.name == 'organization' ? <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${userData?.username}/retailer`)}>Retailer</h1> : <></>}
          </div>
          <div className='w-full'>
            <Insight />
          </div>
        </div>
        <div className='flex-grow max-w-sm w-full px-5 pt-9 hidden lg:inline-block'>
          <div>
            <h1 className='text-xl 2xl:text-2xl font-medium'>Recent Activities</h1>
            {recentActivitiesList.length ? (
              recentActivitiesList.map((item, i) =>
                <div key={i} className='text-gray-400 flex items-center justify-between gap-6 my-4'>
                  {(item.type === 'repository') && <p>Repository{' '}
                    <span className='font-medium text-gray-900'>
                      {item.related_name}
                    </span>{' '}
                    {displayAction(item.action, item.type)}</p>
                  }

                  {(item.type === 'project') && <p>Project{' '}
                    <span className='font-medium text-gray-900'>
                      {item.related_name}
                    </span>{' '}
                    {displayAction(item.action, item.type)}
                  </p>}

                  {(item.type === 'file') && <p>
                    File{' '}
                    {displayAction(item.action, item.type)} in{' '}
                    <span className='font-medium text-gray-900'>
                      {item.repo_name}
                    </span>{' '}
                    repository
                  </p>}
                  {(item.type === 'folder') && <p>
                    Folder{' '}
                    {displayAction(item.action, item.type)} in{' '}
                    <span className='font-medium text-gray-900'>
                      {item.repo_name}
                    </span>{' '}
                    repository
                  </p>}

                  {(item.type === 'branch') && <p>
                    Branch{' '}
                    {displayAction(item.action, item.type)} in{' '}
                    <span className='font-medium text-gray-900'>
                      {item.repo_name}
                    </span>{' '}
                    repository
                  </p>}
                  <p className='text-xs italic min-w-max'>
                    {item.updatedDifference} ago
                  </p>
                </div>))
              : <h1 className='font-medium text-gray-500 text-base'>No Activity yet</h1>
            }
          </div>
          <div className='pt-9'>
            <h1 className='text-xl 2xl:text-2xl font-medium'>Storage</h1>
            {projects?.map((project, i) => <div key={project.id} className='w-full flex items-center gap-4 my-2'>
              <div className='flex-grow relative p-1 px-2 bg-transparent max-w-[250px]'>
                <h1 className='text-base font-medium z-10'>{project?.name}</h1>
                {/* <div className={`absolute top-0 left-0 h-full bg-red rounded-sm`} style={{ zIndex: -1, width: `${((project.size / 1099511627776) * 100) > 0.5 ? ((project.size / 1099511627776) * 100) : 0.5}%` }}> */}
                <div className={`absolute top-0 left-0 h-full ${colors[i]} rounded-sm`} style={{ zIndex: -1, width: `1%` }}>
                </div>
              </div>
              <h1 className='font-medium text-lg'>
                {formatBytes(project.size)}
              </h1>
            </div>)}
          </div>
        </div>
      </div>

    </>
  )
}

export default Home