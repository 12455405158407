import React, { useRef, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteProjectById, editProjectById, frameProject, getProjectByUsername, getProjectsList, likeDislikeProject } from '../../service/service';
import { useClickOutside } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux'
import { preview, projectList } from '../../slices/projectSlice';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import AddProjectForm from './AddProject';
import { useHistory } from 'react-router-dom';
import { routeList } from '../../slices/routeSlice';
import { selectUserDetails } from '../../slices/userSlice';
import toast from 'react-hot-toast';
import { Popover, Tooltip } from '@material-ui/core';
import ProjectPDF from '../../assets/images/ProjectPDF.png'
import DeleteIcon from '../../assets/icons/DeleteIcon';
import EditIcon from '../../assets/icons/EditIcon';
import moment from 'moment';
import Like from '../../assets/images/Like.svg'
import Frame from '../../assets/images/Frame.svg'


function ProjectList({ project, username, setProjects }) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [privateModal, setPrivateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [likes, setLikes] = useState(project?.likes || 0)
    const [frames, setFrames] = useState(project?.frames || 0)

    const moreRef = useRef()
    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)

    useClickOutside(() => setMore(false), moreRef)

    async function getFreshProjectList() {
        const projectData = await getProjectByUsername(username);
        setProjects(projectData?.data || [])
        dispatch(projectList(projectData?.data || []));
    }

    async function deleteProject(projectId) {
        await deleteProjectById(projectId);
        setDeleteModal(false);
        getFreshProjectList()
    }

    async function editProject(data) {
        const res = await editProjectById(data)
        if (res.data) {
            if (data.project_type == 'private') {
                toast.success(`Project ${data.name} set to private`)
            }
            else if (data.project_type == 'public') {
                toast.success(`Project ${data.name} set to public`)
            }
            getFreshProjectList()
        }
        setPrivateModal(false)
    }

    const changeProjectType = project => {
        let id = project.id
        let name = project.name
        let description = project.description
        let project_topics = project.project_topics || ''
        let data = { name, description, project_type: project.project_type_id === 1 ? 'private' : 'public', id, project_topics }
        handleClose()
        editProject(data)
    }

    const navigate = () => {
        dispatch(routeList(`/${username}/${project.name}`))
        history.push(`/${username}/${project.name}`)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const likeProject = async (action) => {
        let res = await likeDislikeProject({ project_id: project.id, action })
        if (res.data) {
            setLikes(res.data.likes)
        }
    }
    const handleFrame = async (action) => {
        let res = await frameProject({ project_id: project.id, action })
        if (res.data) {
            setFrames(res.data.frames)
        }
    }

    return (
        <>
            <tr key={project.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50 relative'>
                <td className='font-medium text-gray-800 cursor-pointer'>
                    <div className='absolute top-0 left-0 w-full h-full cursor-pointer' onClick={navigate}></div>

                    <p className='flex items-center gap-1'>
                        <Tooltip title={project.name}><span>{project.name.length > 16 ? `${project.name.slice(0, 15)}...` : project.name}</span></Tooltip>
                        {(project?.readme && project?.readme != 'undefined') && <img src={ProjectPDF} alt='' className='cursor-pointer w-5 h-5' style={{ filter: 'drop-shadow(2px 2px 8px rgba(0,0,0,0.3))' }} onClick={() => dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name }))} />}
                    </p>
                    <p className='text-gray-500 font-light text-xs mt-3'>Updated {moment(project.updated_at).fromNow()}</p>

                    <div className='max-w-max relative z-20 flex items-center gap-6 pt-2'>
                        <div className='flex items-center gap-1 cursor-pointer' onClick={() => likeProject(1)}><img src={Like} className='w-5' alt="" /> <span>{likes || 0}</span></div>
                        <div className='flex items-center gap-1 cursor-pointer' onClick={() => handleFrame(1)}><img src={Frame} className='w-4' alt="" /> <span>{frames || 0}</span></div>
                    </div>
                </td>
                <td>
                    {project.project_type_id === 1
                        ? 'Public'
                        : 'Private'}
                </td>
                <td className='max-w-sm'>
                    <p className='max-w-sm'>{project.description}</p>
                </td>
                <td className='text-center'>{project.repoLength}</td>
                <td className='relative' ref={moreRef}>
                    {project?.username === user?.username && <div className='flex mx-auto gap-4 max-w-fit'>
                        <EditIcon className='cursor-pointer' onClick={() => setAddModal(true)} />
                        <DeleteIcon className='cursor-pointer' onClick={() => setDeleteModal(true)} />
                        <MoreVertIcon className='cursor-pointer' onClick={handleClick} />
                        <Popover
                            className='mt-2'
                            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className='popup-bg py-1 px-2'>
                                <h1 className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={() => project.project_type_id === 1 ? setPrivateModal(true) : changeProjectType(project)}>{project.project_type_id === 1 ? 'Make it Private' : 'Make it Public'}</h1>
                                <h1 onClick={() => (project?.readme && project?.readme != 'undefined') ? dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name })) : toast("No preview available", { id: 'preview' })} className='font-medium text-base text-gray-800 hover:text-blue-500 cursor-pointer p-1'>View Project Overview</h1>
                            </div>
                        </Popover>
                    </div>}
                    <p className='text-gray-500 font-light mt-3 text-xs text-center'>Added {moment(project.created_at).fromNow()}</p>
                </td>
            </tr>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${project.name}"`} description="This will also delete your all repositories" type="warn" okText="Yes" onCancel={() => setDeleteModal(false)} onOk={() => deleteProject(project.id)} />
            <ConfirmModal open={privateModal} heading={`Are you sure you want to set the project "${project.name}" to private`} description="This will also set your all repositories of this project to private" type="warn" okText="Yes" onCancel={() => setPrivateModal(false)} onOk={() => changeProjectType(project)} />
            {addModal &&
                <AddProjectForm getFreshProjectList={getFreshProjectList} setAddModal={setAddModal} isEdit={true} projectData={project} username={user?.username} />
            }
        </>
    )
}

export default ProjectList