import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import AccountStepper from './AccountStepper';
import ProfileStepper from './ProfileStepper';
import WebSocialLinkStepper from './WebSocialLinkStepper';
import SelectTopic from './SelectTopic';
import { getTopicList } from '../../service/service';
import { TopicsData as topicsData } from './TopicsData'

const ColorConnector = withStyles({
  alternativeLabel: {
    top: 9,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#1D85E8',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1D85E8',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
})(StepConnector);

const useColorStepStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 21,
    height: 21,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#1D85E8',
    color: '#1D85E8',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#1D85E8',
  },

});

function ColorStepIcon({ active, completed }) {
  const classes = useColorStepStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    </div>
  );
}

ColorStepIcon.propTypes = {
  // Whether this step is active.
  active: PropTypes.bool,

  //  Mark the step as completed. Is passed to child components.
  completed: PropTypes.bool,

  //  The label displayed in the step icon.
  icon: PropTypes.node,
};

function getSteps() {
  return ['Account', 'Profile', 'Web & Social', 'Select Topic'];
}

function getStepContent(step, handleNext, handleBack, topics) {
  switch (step) {
    case 0:
      return <AccountStepper handleNext={handleNext} handleBack={handleBack} activeStep={step} />;
    case 1:
      return <ProfileStepper handleNext={handleNext} handleBack={handleBack} activeStep={step} />;
    case 2:
      return <WebSocialLinkStepper handleNext={handleNext} handleBack={handleBack} activeStep={step} />;
    case 3:
      return <SelectTopic handleNext={handleNext} handleBack={handleBack} activeStep={step} topicList={topics} />;
    default:
      return 'Unknown step';
  }
}

export default function StepToProgress({ step, stepChange }) {
  const [activeStep, setActiveStep] = React.useState(step);
  const [topics, setTopics] = useState([])
  const steps = getSteps();
  useEffect(() => getTopics(), [])

  const getTopics = async () => {
    let topicsFromDb = []
    const res = await getTopicList()
    topicsFromDb = res.data
    topicsFromDb.forEach(e => {
      topicsData.forEach(i => {
        if (Number(e.id) === Number(i.id)) {
          e.icon = i.icon
          e.selectedIcon = i.selectedIcon
          e.selected = false
        }
      })
    });
    setTopics(topicsFromDb)
  }
  const handleNext = () => {
    stepChange((prevActiveStep) => prevActiveStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    stepChange((prevActiveStep) => prevActiveStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <div className="w-full h-full overflow-y-auto p-5">
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorStepIcon} >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className='py-5'>
        {getStepContent(activeStep, handleNext, handleBack, topics)}
      </div>
    </div>
  );
}