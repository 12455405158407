
// export const url = 'http://localhost:8001/api';
export const url = 'https://greyffiti-backend.azurewebsites.net/api';
// export const url = 'https://backend.greyffiti.com/api'

export const acceptImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/tiff", "image/jiff", 'pdf'];
export const endpoints = {
    USER: '/user',
    login: '/login',
    register: '/register',
    forgotPassword: '/forgotPassword',
    resetPassword: '/resetPassword/',
    confirmEmail: '/confirmUser',
    confirmUserInfo: '/getConfirmUserInfo',
    createBasicProfile: '/createProfile/basicInfo',
    userBasicDetail: '/getProfile/basicInfo',
    userSocialDetail: '/getProfile/socialInfo',
    createProfileSocialInfo: '/createProfile/socialInfo',
    userDetail: '/getUserDetail',
    userByUsername: '/getUserByUsername',
    userCountables: '/getUserCountables',
    professionName: '/getProfessionName',
    statusUpdate: '/createProfile/statusInfo',
    getStatus: '/getProfile/statusInfo',
    getProjectsList: '/project/list',
    projectAdd: '/project/add',
    getProjectsListByName: '/project/sortByName',
    getProjectsListByCreatedDate: '/project/sortByDateCreated',
    getProjectsListByUpdatedDate: '/project/sortByUpdatedDate',
    getProjectsListBySize: '/project/sortBySize',
    projectViews: '/project/views',
    projectViewsCount: '/project/views/count',
    deleteProjectById: '/project/deleteProjectById',
    editProjectById: '/project/editProjectById',

    updateUserName: '/updateUserName',
    acceptTerms: '/acceptTerms',
    toggleStateUpdate: '/createProfile/toggleState',
    getToggleState: '/getProfile/toggleState',
    settingsRestore: '/setting/restore',
    settingsUpdate: '/setting/update',
    settingsList: '/setting/list',

    addRepository: '/repository/add',
    getRepositoryList: '/repository/list',
    getRepositoryListByUpdatedDate: '/repository/sortByUpdatedDate',
    getRepositoryListByCreatedDate: '/repository/sortByDateCreated',
    getRepositoryListBySize: '/repository/sortBySize',
    getReposBasedOnProjectId: '/repository/listRepoBasedOnProjectId',
    editRepositoryById: '/repository/editRepositoryById',
    deleteRepositoryById: '/repository/deleteRepositoryById',
    getRepoByName: '/repository/byName',
    getAllTopics: '/project/listTopics',

    fileRestore: '/file/restore',
    getAllFilesList: '/filesandfolders/list',
    addFiles: '/filesandfolders/web/add',
    listBranch: '/branch/list',
    getBranch: '/branch/get',
    getFilesAndFoldersList: '/filesandfolders/list',
    getVersions: '/filesandfolders/versions',
    getFilesAndFoldersListByUpdatedDate: '/filesandfolders/sortByUpdatedDate',
    recentFiles: '/filesandfolders/recent',
    deletefileAndFolder: '/filesandfolders/delete',
    downloadFile: '/filesandfolders/getFile',
    getFileDetail: '/filesandfolders/getFile',
    downloadFolder: '/filesandfolders/downloadFolder',
    editFileAndFolder: '/filesandfolders/edit',
    getFilesBasedOnRepoId: '/filesandfolders/listByRepoId',
    deleteVersion: '/filesandfolders/version/delete',
    getFilesAndFoldersListByUserId: '/filesandfolders/byUserId',
    getFoldersListByUserId: '/folders/byUserId',

    recentActivities: '/user/recentActivity',
    isEmailAvailable: '/isEmailAvailable',
    notificationCount: '/user/notificationCount',
    updateNotificationCount: '/user/updateNotification',

    downloadWindows: '/download/windows',
    downloadMac: '/download/mac',
    fileDeletePreview: '/filesandfolders/deletePreview',
    folderDeletePreview: '/filesandfolders/folder/deletePreview',
    downloadMacIntel: '/download/macIntel',
    subscribe: '/Subscribe',
    createNewBreanch: '/branch/add',
    editBranch: '/branch/update',
    deleteBranch: '/branch/delete',
    addUserTopics: '/user/addTopics',
    issueList: '/issue/list',
    issueListByRepo: '/issue/listByRepo',
    issueListByProject: '/issue/listByProject',
    deleteIssue: '/issue/delete',
    createIssue: '/issue/add',
    viewDetailedIssue: '/issue/detail',
    closeIssue: '/issue/closed',
    editIssue: '/issue/update',
    contributorsCountAgainstYear: '/contributors/insights',
    contributionsOfMonth: '/user/insights',
    inviteAccept: '/invite/accept',
    inviteRemove: '/invite/remove',
    inviteMembers: '/invite/members',
    inviteUsers: '/invite/send',
    inviteNonUsers: '/invite/send/manual',
    inviteList: '/user/invites',
    addNewPreview: '/filesandfolders/addNewPreview',
    addNewPreviewFolder: '/filesandfolders/folder/addNewPreview',
    uploadFile: '/issue/upload/file',
    repositoryContributors: '/repository/contributors',
    projectContributors: '/project/contributors',
    removeContributors: '/contributors/remove',
    addAssignee: '/issue/update/assignee',
    addIssueTag: '/issue/update/tags',
    addTag: '/tag/add',
    listTags: '/tag/list',
    removeTag: '/tag/remove',
    editTag: '/tag/edit',
    issueComment: '/issue/comments',

    projectSpotlight: '/project/spotlight',
    projectPublic: '/project/public',
    projectByUsername: '/project/projectByUsername',
    repositoryPublic: '/repository/public',
    getSearchResults: '/user/search',
    fileNotifications: '/file/notifications',
    projectByName: '/project/details',

    listEvents: '/events',
    eventById: '/event',
    userEvent: '/user/event',
    userEventList: '/user/events',

    userPlan: '/getProfile/plan',
    orgEvents: '/organization/events',
    eventSubmission: '/organization/event/submissions',
    eventWinner: '/organization/event/winners',
    eventAnnouncement: '/organization/event/announcements',
    eventSpeaker: '/organization/event/speakers',

    adminUsers: '/admin/setting/users',
    adminPlans: '/admin/setting/plans',

    addCommentTag: '/issue/comment/tag',
    listTagsByProject: '/project/tags',
    listTagsByRepo: '/repository/tags',
    isUsernameAvailable: '/isUsernameAvailable',

    addLinks: '/repolinks/add',
    listLinks: '/repolinks/list',
    removeLink: '/repolinks/remove',
    editLink: '/repolinks/edit',

    transferOwnerShip: '/transferOwnerShip',
    showcase: '/user/showcase',
    freelance: '/user/freelance',
    freelanceData: '/user/freelanceData',

    organization: '/organization',
    organizations: '/organizations',
    retailerInvite: '/retailer/invite',
    generateInviteLink: '/retailer/invite/link',
    validateRetailerLink: '/retailer/invite/link/validate',
    retailerInviteList: '/retailer/invite/list',
    updateInviteFiles: '/retailer/invite/files',
    updateUserPlan: '/user/plan',
    organizationInvite: '/organization/invite/link',
    organizationMembers: '/organization/members',

    likeDislikeRepo: '/likeDislikeRepo',
    likeDislikeProject: '/likeDislikeProject',
    frameProject: '/frameProject',
    userStatistics: '/userStatistics',
    getFollowing: '/getFollowing',
    getFollowers: '/getFollowers',
    getFramesActivity: '/getFramesActivity',
    getFollowActivity: '/getFollowActivity',

    subscription: '/subscription',
    createOrder: '/order/create',
    downgradePlan: '/downgradePlan'
}
