import React from 'react'
import WarningIcon from '../../assets/icons/WarningIcon';

function ConfirmModal({ open, onCancel, onOk, cancelText, okText, img, type, heading, description }) {

    return (
        <>
            {
                open &&
                <> <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 ' style={{ zIndex: 10000 }} onClick={onCancel}>
                </div>
                    <div className='flex flex-col items-center justify-center p-5 md:px-10 bg-white w-full max-w-xl rounded-lg fixed transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' style={{ zIndex: 10001 }}>
                        {(type || img) &&
                            <>
                                {
                                    type ? type == 'warn' ?
                                        <WarningIcon />
                                        : ''
                                        : <img src={img} alt="" />
                                }
                            </>
                        }
                        <h1 className='text-2xl md:text-3xl font-medium my-6 text-center'>{heading}</h1>
                        {description && <p className='md:text-lg my-4 text-gray-500 text-center'>{description}</p>}
                        <div className='flex justify-around w-full my-6'>
                            <button className='bg-red rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={onCancel}>
                                {cancelText || 'Cancel'}
                            </button>
                            <button className='bg-blue rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={onOk}>
                                {okText || 'Ok'}
                            </button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ConfirmModal