import { Delete } from '@material-ui/icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { addLinks, editLink, removeLink } from '../../service/service'
import ConfirmModal from '../ReusableComponents/ConfirmModal'
import validator from 'validator'
import { toast } from 'react-hot-toast'
import { nameFieldValidator } from '../../utils/validation'

function AddLink({ isLink, setIsLink, currentBranch, getLinks }) {

    const [links, setLinks] = useState([
        {
            link: '',
            name: '',
            description: '',
            type: 'link'
        }
    ])
    const [deleteModal, setDeleteModal] = useState(false);

    const deleteClick = async () => {
        let res = await removeLink(links[0].id)
        if (res.data?.id && res.success) {
            getLinks();
            closePopUp();
            setDeleteModal(false);
        }
    }

    useEffect(() => {
        if (isLink?.length) {
            setLinks(isLink)
        }
    }, [isLink])

    const closePopUp = () => {
        setLinks([{
            link: '',
            name: '',
            description: '',
            type: 'link'
        }]);
        setIsLink(false);
    }

    const handleChange = (e, i) => {
        e.preventDefault();
        let arr = [...links]
        let id = e.target.id
        arr[i][id] = e.target.value
        setLinks(arr)
    }

    const handleRemove = (i) => {
        let arr = [...links]
        if (arr.length > 1)
            arr.splice(i, 1);

        setLinks(arr)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            branch_id: currentBranch.id,
            repo_id: currentBranch.repo_id,
            project_id: currentBranch.project_id,
        }
        let res;
        if (isLink?.length) {
            if (!links[0].name) {
                return toast.error(`Link Title is required`, { id: 'error' });
            }
            if (!nameFieldValidator(links[0].name)) {
                return toast.error(`No special characters except "-" and "_" are allowed in title`, { id: 'error' });
            }
            if (!validator.isURL(links[0].link)) {
                return toast.error("Website url is invalid", { id: 'error' });
            }
            let { username, ...updatedLink } = links[0]
            res = await editLink({ ...data, ...updatedLink })
        }
        else {
            for (let link of links) {
                if (!link.name) {
                    return toast.error(`Link Title is required`, { id: 'error' });
                }
                if (!nameFieldValidator(link.name)) {
                    return toast.error(`No special characters except "-" and "_" are allowed in title "${link.name}"`, { id: 'error' });
                }
                if (!validator.isURL(link.link)) {
                    return toast.error(`Website url is invalid for title "${link.name}"`, { id: 'error' });
                }
            }
            res = await addLinks({ ...data, links: links })
        }

        if (res.success && (res.data?.length || res.data?.id)) {
            getLinks();
            closePopUp();
        }
    }
    return (
        <>

            <div className='fixed inset-0 w-full min-h-screen overflow-hidden bg-transparent grid place-items-center' style={{ zIndex: 5001 }}>
                <div className='fixed inset-0 w-full min-h-screen overflow-hidden bg-black bg-opacity-50 cursor-pointer' onClick={() => closePopUp()}></div>
                <div className='flex m-4 flex-col w-full max-w-3xl min-h-[400px] bg-white rounded-lg z-20 relative p-8 2xl:p-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" onClick={() => closePopUp()} className="w-8 h-8 absolute top-3 right-4 cursor-pointer text-gray-700 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                    <h1 className='text-3xl font-medium mb-2'>Add link to repository</h1>
                    <div className='flex-grow'>
                        {links.map((item, i) => <div className='flex flex-wrap items-end gap-4 py-2 w-full' key={i}>
                            <div className='flex flex-col gap-1'>
                                <label className='font-medium text-lg'>Link</label>
                                <input type="text" value={item.link} id="link" onChange={e => handleChange(e, i)} className='border-2 bg-transparent rounded py-1.5 px-2 outline-none focus:border-blue-200' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <label className='font-medium text-lg'>Link title</label>
                                <input type="text" value={item.name} id="name" onChange={e => handleChange(e, i)} className='border-2 bg-transparent rounded py-1.5 px-2 outline-none focus:border-blue-200' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <label className='font-medium text-lg'>Description</label>
                                <input type="text" value={item.description} id="description" onChange={e => handleChange(e, i)} className='border-2 bg-transparent rounded py-1.5 px-2 outline-none focus:border-blue-200' />
                            </div>
                            <div className='pb-2 w-10'>
                                {links.length > 1 ?
                                    <Delete onClick={() => handleRemove(i)} className='text-red cursor-pointer' />
                                    : <></>}
                            </div>
                        </div>)}
                        {!isLink?.length && <>
                            {links.length < 5 &&
                                <div className='py-4 text-center'>
                                    <button className='bg-green text-white py-2 px-6 font-medium text-lg rounded outline-none shadow-lg' onClick={() => setLinks([...links, {
                                        link: '',
                                        name: '',
                                        description: '',
                                        type: 'link'
                                    }])
                                    }>+One More Link</button>
                                </div>}
                        </>}
                    </div>

                    <div className='pt-4 flex items-center justify-end gap-8'>
                        {Boolean(isLink?.length) && <button className='bg-red text-white py-2 px-6 font-medium text-lg rounded outline-none shadow-lg' onClick={() => setDeleteModal(true)}>Remove Link</button>}
                        <button className='bg-blue text-white py-2 px-6 font-medium text-lg rounded outline-none shadow-lg' onClick={handleSubmit}>{Boolean(isLink?.length) ? 'Update Link' : 'Confirm'}</button>
                    </div>

                </div>
            </div>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to remove the link "${links[0].name}"`} type="warn" okText={'Yes'} onCancel={() => setDeleteModal(false)} onOk={() => deleteClick()} />
        </>
    )
}

export default AddLink
