import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import share from "../../assets/images/Share_Connect_Collab.svg";
import ArrowDownIcon from '../../assets/images/more_down_icon.svg';
import Collaborate from '../../assets/images/Collaborate.svg'
import WorkAny from '../../assets/images/Work Anywhere.svg'
import Engage from '../../assets/images/Engage.svg'
import WorkTogether from '../../assets/images/Work together Illustration.svg'
import Geometry from '../../assets/images/Geometry.svg'
import EngagePeer from '../../assets/images/Engage with peers illustration.svg'
import Creator from '../../assets/images/The creators.svg'
import Market from '../../assets/images/The marketplace.svg'
import Viewer from '../../assets/images/The viewer.svg'
import Contributer from '../../assets/images/The contributer.svg'
import Download from '../../assets/images/Download.svg'
import DownloadTab from '../../assets/images/Download tab illustration.svg'
import Support from '../../assets/images/Support.svg'
import ViewCommon from '../../assets/images/View common question.svg'
import GreyffitiWithLogo from '../../assets/images/Full_logo.png'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link, useHistory } from 'react-router-dom';
import { saveAs } from "file-saver";
import { toast } from 'react-hot-toast';

const url = 'https://seahorse-app-x9ss8.ondigitalocean.app'

const works = [
  {
    id: 1,
    img: Collaborate,
    heading: 'Collaborate',
    detail: 'Collaborate with people whose work matches your vibes, and let your work shine!'
  },
  {
    id: 2,
    img: WorkAny,
    heading: 'Work Anywhere',
    detail: 'With Greyffiti, location is no more a boundary!'
  },
  {
    id: 3,
    img: Engage,
    heading: 'Engage On the Go',
    detail: 'Engagement works on your side, and Greyffiti makes it so much easier!'
  }
]

const features = [
  {
    id: 1,
    img: Creator,
    heading: 'The Creator',
    detail: 'A creator must create with passion, and build your profile!'
  },
  {
    id: 2,
    img: Contributer,
    heading: 'The Collaborator',
    detail: 'Collaborating on Greyffiti is real yet virtual. Collaborate with artists from anywhere and everywhere!'
  },
  {
    id: 3,
    img: Market,
    heading: 'The Marketplace',
    detail: ' Develop plug-ins for your favourite software, advertise software, finished design work and monetize!'
  },
  {
    id: 4,
    img: Viewer,
    heading: 'The Viewer',
    detail: ' See and get inspired, look at the work of professionals and learn from the best!'
  },
]


function Landing() {

  const history = useHistory()
  const [Platform, setPlatform] = useState('')

  useEffect(() => {
    let token = localStorage.getItem("greyffiti_token");
    if (token) {
      history.push("/dashboard")
    }
    setPlatform(window?.navigator.platform);
  }, [])


  const downloadFile = (os) => {
    toast.success("Downloading file...")
    if (os === 'mac') {
      saveAs(
        url + '/downloads/greyffiti.dmg',
        "Greyffiti.dmg"
      );
    } else if (os === 'macIntel') {
      saveAs(
        url + '/downloads/greyffiti.dmg',
        "Greyffiti.dmg"
      );
    } else {
      saveAs(
        url + '/downloads/greyffiti.exe',
        "Greyffiti.exe"
      );
    }
  };

  return (
    <>
      <nav className="flex items-center justify-between px-5 py-3 lg:px-10 sticky top-0 bg-white z-50">
        <div className="flex items-center justify-center">
          <div className="h-12 w-10 object-contain">
            <img src={logo} alt="logo" />
          </div>
          <ul className="hidden lg:flex items-center space-x-6 md:space-x-8 xl:space-x-10 pl-10 text-lg font-medium">
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='#home'>Home</a></li>
            {/* 
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='#about'>About Us</a></li>
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='#features'>Features</a></li>
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='/pricing'>Pricing</a></li>
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='#support'>Support</a></li> */}
            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href='/faq'>FAQ’s</a></li>
          </ul>
        </div>

        <div className="flex items-center space-x-4">
          <Link to="/login"><button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium">Login</button></Link>
          <span className='hidden sm:inline-flex'>or</span>
          <Link to="/register"> <button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium hidden sm:inline-flex">Create Account</button></Link>
        </div>
      </nav>


      <section id='home' className='flex flex-col lg:flex-row items-center justify-center lg:min-h-[500px] p-5 lg:px-10 w-full'>
        <div className='p-5 flex flex-col justify-center lg:justify-start items-center lg:items-start'>
          <h1 className='text-5xl xl:text-6xl font-bold text-blue-900 lg:max-w-xs text-center lg:text-left'>Share
            Connect,
            Collaborate</h1>
          <p className='text-gray-500 font-semibold my-4 text-lg text-center lg:text-left'>Greyffiti, Enabling you to put your work, in front of the right people at right time.</p>
          <button className='px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold' onClick={() => history.push('/register')} >Try For Free</button>
        </div>
        <div className='flex-grow sm:p-10 w-full max-w-4xl'>
          <img className="w-full h-full " src={share} alt="alt" />
        </div>
      </section>
      <a href="#about" className='hidden lg:block'><img src={ArrowDownIcon} alt="arrowdownIcon" className='w-16 h-16 cursor-pointer mx-auto mb-8 mt-8 lg:mt-0 rounded-full' /></a>

      <div id='about'></div>
      <section className='lg:min-h-[600px] p-5 py-10 lg:px-10 mt-20'>
        <h1 className='text-4xl md:text-5xl font-bold text-center text-blue-900'>Greyffiti Works For You</h1>
        <div className='flex flex-wrap justify-around items-center gap-10 py-4'>
          {works.map(work => <ScrollAnimation key={work.id} duration={1.2} animateOnce={true} animateIn="bounceInRight" offset={300} delay={work.id * 100}>
            <div className='max-w-xs flex flex-col items-center justify-center sm:p-5'>
              <img src={work.img} alt={work.heading} className="m-4" />
              <h1 className='text-blue-900 text-3xl font-bold'>{work.heading}</h1>
              <p className='text-center text-gray-500 text-lg'>{work.detail}</p>
            </div>
          </ScrollAnimation>)}
        </div>
      </section>

      <section className='lg:flex items-center justify-center lg:min-h-[500px] w-full p-5 sm:px-10 mx-auto'>
        <ScrollAnimation duration={1} animateOnce={true} animateIn="bounceInLeft" offset={300}>
          <div className='flex-1 sm:p-10 w-full max-w-4xl'>
            <img className="w-full h-full" src={WorkTogether} alt="alt" />
          </div>
        </ScrollAnimation>
        <ScrollAnimation duration={1.2} animateOnce={true} animateIn="fadeIn" offset={300} delay={0}>
          <div className='lg:max-w-sm p-5 flex flex-col items-center justify-center lg:items-start lg:justify-start'>
            <h1 className='text-5xl xl:text-6xl font-bold text-blue-900 lg:max-w-xs text-center lg:text-left'>Work Together</h1>
            <p className='text-gray-500 font-semibold my-4 text-lg text-center lg:text-left'>Like-minded people, are hard to find; but not on Greyffiti! Work with people whose work sparks the fire in you!</p>
          </div>
        </ScrollAnimation>
      </section>

      <section className='lg:flex items-center lg:min-h-screen my-10 py-10 relative'>
        <img src={Geometry} alt='geometry' className={''} />
        <ScrollAnimation duration={1.2} animateOnce={true} animateIn="fadeIn" offset={300}>
          <h1 className='font-bold text-blue-900 text-3xl md:text-4xl lg:text-5xl px-5 text-center w-full max-w-4xl absolute transform top-1/4 lg:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Creating the new wave of open source visual engineering</h1>
        </ScrollAnimation>
      </section>

      <section className='lg:flex items-center justify-center min-h-screen lg:min-h-[500px] w-full my-10 p-5 sm:px-10 mx-auto'>
        <div className='lg:max-w-sm p-5 flex flex-col items-center justify-center lg:items-start lg:justify-start'>
          <h1 className='text-4xl md:text-5xl xl:text-6xl font-bold text-blue-900 lg:max-w-xs text-center lg:text-left'>Engage With Your Peers</h1>
          <p className='text-gray-500 font-semibold my-4 text-lg text-center lg:text-left'>Stay upto date with your projects. Connect Greyffiti with Microsoft Teams, Slack and other collaborative work tools to get notified on your projects.</p>
        </div>
        <ScrollAnimation duration={1.2} animateOnce={true} animateIn="bounceInRight" offset={300}>
          <div className='flex-1 sm:p-10 w-full max-w-4xl'>
            <img className="w-full h-full " src={EngagePeer} alt="alt" />
          </div>
        </ScrollAnimation>
      </section>

      <div id='features'></div>
      <section className='lg:min-h-screen p-5 py-10 lg:px-10 lg:mt-32'>
        <h1 className='text-4xl md:text-5xl font-bold text-center text-blue-900'>A Social Collaboration Platform</h1>
        <div className='flex flex-wrap justify-around items-center gap-5 py-4'>
          {features.map(feature => <ScrollAnimation key={feature.id} duration={1.2} animateOnce={true} animateIn='fadeIn' delay={feature.id * 100}>
            <div className='max-w-xs sm:p-5 flex flex-col items-center justify-center'>
              <img src={feature.img} alt={feature.heading} className="m-4" />
              <h1 className='text-blue-900 text-2xl sm:text-3xl font-bold text-center'>{feature.heading}</h1>
              <p className='text-center text-gray-500 text-lg'>{feature.detail}</p>
            </div>
          </ScrollAnimation>)}
        </div>
      </section>

      <section className='lg:min-h-[500px] py-10 lg:my-20 2xl:mt-0 relative'>
        <ScrollAnimation duration={0.8} animateOnce={true} animateIn='fadeIn' offset={300}>
          <h1 className='text-4xl lg:text-5xl font-bold text-center text-blue-900 my-4 px-5'>Make Work More Fun Together With Greyffiti!</h1>
          {/* <h1 className='text-xl md:text-2xl lg:text-3xl text-center text-blue-900 my-8 px-5'>Download And Install Our Desktop Application</h1> */}
          <h1 className='text-xl md:text-2xl lg:text-3xl text-center text-blue-900 my-8 px-5'>Get Started for Now</h1>
          <div className='flex flex-wrap items-center justify-center gap-10 lg:gap-16 max-w-4xl mx-auto my-10 z-20 px-5'>
            <button onClick={() => history.push('/register')} className='flex items-center justify-center text-lg font-medium space-x-2 px-4 py-2 w-full max-w-xs rounded bg-blue-500 text-white'>
              <p>Signup for Free</p>
            </button>
          </div>
          {/* <div className='flex flex-wrap items-center justify-center gap-10 lg:gap-16 max-w-4xl mx-auto my-10 z-20 px-5'>
            {Platform?.includes('Win') && <button onClick={() => downloadFile()} className='flex items-center justify-center text-lg font-medium space-x-2 px-4 py-2 w-full max-w-xs rounded bg-blue-500 text-white'>
              <img src={Download} className="w-5 h-5" alt='download' />
              <p>Windows</p>
            </button>}
            {Platform?.toLowerCase()?.includes('mac') && <button onClick={() => downloadFile('mac')} className='flex items-center justify-center text-lg font-medium space-x-2 px-4 py-2 w-full max-w-xs rounded bg-blue-500 text-white'>
              <img src={Download} className="w-5 h-5" alt='download' />
              <p>Mac OS (Apple Silicon)</p>
            </button>}
            {Platform?.toLowerCase()?.includes('macintel') && <button onClick={() => downloadFile('mac')} className='flex items-center justify-center text-lg font-medium space-x-2 px-4 py-2 w-full max-w-xs rounded bg-blue-500 text-white'>
              <img src={Download} className="w-5 h-5" alt='download' />
              <p>Mac OS (Intel)</p>
            </button>}
          </div> */}
        </ScrollAnimation>
        <img
          src={DownloadTab}
          alt='downloadTab'
          className='transform xl:-translate-y-36'
          style={{ zIndex: -1, position: 'relative' }}
        />
      </section>

      <div id='support'></div>
      <div className='px-5 lg:px-10 flex flex-wrap gap-16 justify-around transform xl:-translate-y-32'>
        <ScrollAnimation duration={1.2} animateOnce={true} animateIn='bounceInLeft' offset={300}>

          <div className='max-w-sm flex flex-col items-center justify-center'>
            <img src={ViewCommon} alt="" />
            <h1 className='text-3xl text-blue-900 font-bold mt-4 text-center'>View common questions</h1>
            <p className='text-gray-500 text-lg text-center my-2'>From signup till prices every queries, answered at one place.</p>
            <a className='text-blue-900 hover:underline text-lg font-medium cursor-pointer' href='/faq'>Go to FAQ’s</a>
          </div>
        </ScrollAnimation>
        {/* <ScrollAnimation duration={1.2} animateOnce={true} animateIn='bounceInRight' offset={300}>

          <div className='max-w-sm flex flex-col items-center justify-center'>
            <img src={Support} alt="" />
            <h1 className='text-3xl text-blue-900 font-bold mt-4 text-center'>Looking for support?</h1>
            <p className='text-gray-500 text-lg text-center my-2'>24x7 support for any problem that you encounter.</p>
          </div>
        </ScrollAnimation> */}
      </div>

      <footer className='transform xl:-translate-y-16 pt-10 px-5 max-w-7xl mx-auto'>
        <div className='flex items-center justify-between flex-wrap gap-y-4 gap-x-8'>
          <div className="w-24 object-contain">
            <img src={GreyffitiWithLogo} alt="logo" />
          </div>
          <p className='text-base sm:text-lg text-gray-600 max-w-sm'>Greyffiti, Enabling you to put your work, in front of the right people at right time.</p>
        </div>
        <hr className='my-6 w-full mx-auto bg-blue-50' />
        <div className='flex flex-wrap gap-10 justify-start'>
          <div className='w-full max-w-sm'>
            <h1 className='text-3xl text-blue-900 font-bold mb-4'>Reach us at</h1>
            {/* <p className='text-gray-500 font-medium'>+91 9966908431</p>
          <p className='text-gray-500 font-medium my-2'>21 Ananya Chaitanya Layout, Seegehalli Kadugodi Post, Bangalore, 560067</p> */}
            <p className='text-gray-500 font-medium'>info@greyffiti.com</p>
          </div>

          <div className='w-full max-w-sm'>
            <h1 className='text-3xl text-blue-900 font-bold mb-4'>Links</h1>
            <p className='text-gray-500 font-medium'>Terms and Conditions</p>
            <p className='text-gray-500 font-medium my-2'>Privacy Policy</p>
            <p className='text-gray-500 font-medium'>Pricing Plans</p>
          </div>

          <div className='w-full max-w-sm'>
            <h1 className='text-3xl text-blue-900 font-bold mb-4'>Stay in Touch</h1>
            <p className='text-gray-500 font-medium my-2'>Get the best new products in your inbox . Get the latest content first!</p>
            <div className='border max-w-xs relative'>
              <input className=' h-12 w-full pr-20 pl-4' />
              <button className='bg-blue-500 p-3 absolute right-0'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Landing