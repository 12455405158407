import React, { useState } from 'react'
import { deleteIssue, getIssueList } from '../../service/service';
import { useDispatch, useSelector } from 'react-redux'
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { routeList } from '../../slices/routeSlice';
import { selectUserDetails } from '../../slices/userSlice';
import { issueList } from '../../slices/issueSlice';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import toast from 'react-hot-toast';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import EditIcon from '../../assets/icons/EditIcon';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import LikeIcon from '../../assets/icons/LikeIcon';
import Dislike from '../../assets/icons/Dislike';

function IssueList({ issue }) {
    const history = useHistory()
    const [deleteModal, setDeleteModal] = useState(false);

    const dispatch = useDispatch()
    const user = useSelector(selectUserDetails)

    async function getFreshIssueList() {
        const issueData = await getIssueList();
        dispatch(issueList(issueData.data || []));
    }

    async function deleteIssueById(issueId) {
        await deleteIssue(issueId);
        setDeleteModal(false);
        getFreshIssueList()
    }

    const navigate = () => {
        dispatch(routeList(`/${issue}/issue/${issue.id}`))
        history.push(`/${user.username}/${issue.project}/${issue.repo}/issue/${issue.id}`)
    }

    return (
        <>
            <tr key={issue.id} tabIndex={1} className='bg-white rounded-sm shadow transform hover:bg-gray-50 z-10'>
                <td>
                    {issue.issue_status === 'Open' && <h1 className='text-white bg-blue px-4 py-1 rounded-full font-medium max-w-max'>{issue.issue_status}</h1>}
                    {issue.issue_status === 'Closed' && <h1 className='text-white bg-green px-4 py-1 rounded-full font-medium max-w-max'>{issue.issue_status}</h1>}

                </td>
                <td className='font-medium text-gray-800 cursor-pointer' onClick={navigate}>
                    <p>{issue.title}</p>
                    {/* <div className='flex items-center my-3 gap-1'>
                        <LikeIcon className='w-4' />
                        <span className="text-xs text-gray-500">0</span>
                        <Dislike className='w-4 ml-4' />
                        <span className="text-xs text-gray-500">0</span>
                    </div> */}
                    <p className='text-gray-500 font-light text-xs'>Added {moment(issue.created_at).fromNow()}</p>
                </td>
                <td>
                    {issue.author}
                </td>
                <td className=''>
                    <p className='text-center text-xs'>{issue.project}/{issue.repo}/{issue?.file ? `${issue?.file?.name}` : ''}</p>
                    <p className='text-center text-xs'>{issue?.branch_name} branch</p>
                    {/* {issue?.tags?.map(tag => <Tag key={tag.id} text={tag.name} color={tag.color} />)} */}
                </td>
                <td className='flex items-center justify-center flex-wrap gap-1 max-w-[150px] mx-auto'>
                    {
                        issue?.assignee?.map(contributor =>
                            <Tooltip title={contributor.name} key={contributor.contributer_id}>
                                {
                                    contributor.profile_pic ?
                                        <img alt='' className='w-8 h-8 rounded-full object-cover my-0.5 mx-1 border' src={contributor.profile_pic} />
                                        : <DefaultUserIcon alt='' className='w-8 h-8 rounded-full object-cover border my-0.5 mx-1' />
                                }
                            </Tooltip>
                        )
                    }
                </td>
                <td>
                    {issue.author_id == user?.id && <div className='flex gap-4 relative justify-center'>
                        {issue.issue_status === 'Open' && <EditIcon className='cursor-pointer' onClick={() => user.id == issue.author_id ? history.push(`/${user.username}/${issue.project}/${issue.repo}/${issue.file_id}/issue/${issue.id}/edit`) : toast.error("You are not authorized to edit this issue!", { id: 'error' })} />}
                        <DeleteIcon className='cursor-pointer' onClick={() => user.id == issue.author_id ? setDeleteModal(true) : toast.error('You are not authorized to delete this issue!', { id: 'error' })} />
                    </div>}
                    <p className='text-gray-500 font-light mt-3 text-xs text-center'>Updated {moment(issue.updated_at).fromNow()}</p>
                </td>
            </tr>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${issue.title}"`} type="warn" okText="Yes" onCancel={() => setDeleteModal(false)} onOk={() => deleteIssueById(issue.id)} />
        </>
    )
}

export default IssueList