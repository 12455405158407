import { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { AtomicBlockUtils, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useDebounce } from '../../utils/helper';
import { useEffect } from 'react';
import { uploadFile } from '../../service/service';

function TextEditor({ placeholder = '', onChange, initialValue, loading }) {
  const [editorState, setEditorState] = useState(initialValue ? EditorState.createWithContent(convertFromRaw(JSON.parse(initialValue))) : EditorState.createEmpty())
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const onEditorStateChange = (state) => {
    setEditorState(state);
  }

  useEffect(() => {
    if (loading) {
      setEditorState(EditorState.createEmpty());
    }
  }, [loading])

  const debounced = useDebounce(editorState, 700);

  useEffect(() => {
    if (debounced) {
      let data = convertToRaw(editorState.getCurrentContent())
      onChange(JSON.stringify(data))
    }
  }, [debounced])

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("issue_file", file);
    return new Promise(async (resolve, reject) => {
      const result = await uploadFile(formData)
      if (!result) {
        reject()
        return
      }
      resolve({
        data: {
          url: result.data,
          width: 300,
          height: 220,
          alignment: "left", // or "center", "right"
          type: "image" // or "video"
        },
        "success": true,
        "status": 200
      })
    })
  }

  const handleImageUpload = async (file) => {

    try {
      // Send the file to the image upload API and get the URL or identifier in response
      const imageUrl = await uploadImage(file);

      // Create an entity with the image URL
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        {
          src: imageUrl?.data?.url, height: '150px',
          width: '250px',
        }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      // Insert the image as a block
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
      );

      setEditorState(newEditorState);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];
    if (file?.type?.slice(0, 5) == 'image')
      handleImageUpload(file)
  };

  return (
    <div
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onPaste={(e) => {
        var item = Array.from(e.clipboardData.items).find(x => /^image\//.test(x.type));
        if (item) {
          var blob = item.getAsFile();
          handleImageUpload(blob)
        }
      }} className='relative'>
      {isDraggingOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '20px',
          }}
        >
          Drop image here
        </div>
      )}
      <label htmlFor='image' className='sticky top-20 z-[60]'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-5 border rounded-sm text-gray-900 absolute top-1.5 left-72 z-[100] bg-white cursor-pointer">
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>
        <input multiple={false} onChange={(e) => {
          e.preventDefault();
          const file = e.target.files[0];
          if (file?.type?.slice(0, 5) == 'image')
            handleImageUpload(file)
        }} type='file' id='image' accept='image/*' className='hidden' />
      </label>
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="flex sticky top-20 left-0 z-50 w-full mt-4 !justify-start"
        editorClassName="bg-white shadow-sm w-full border p-5 min-h-[200px] max-h-[700px] overflow-y-auto"
        toolbar={{
          options: ['inline', 'list', 'link', 'emoji'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'Blockquote', 'Code'],
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],

          },
          image: {
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImage,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg, image/*',
            alt: { present: false, mandatory: false, default: 'img' },
            defaultSize: {
              height: '150px',
              width: '250px',
            }
          },
        }}
      />
    </div>
  )
}

export default TextEditor