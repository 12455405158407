import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import FullWidthTabs from './TabBar';
import { Github, Instagram, LinkedIn, Pinterest, Dribbble, Twitter } from '../../assets/icons';


function ProfileHome({ me, user, free }) {
    const history = useHistory()
    const [counts, setCounts] = useState({
        followers: 0,
        following: 0,
        projects: 0,
        sprays: 0
    })


    useEffect(() => {
        if (user)
            setCounts({
                followers: user?.countables?.followers || 0,
                following: user?.countables?.following || 0,
                projects: user?.countables?.projects || 0,
                sprays: user?.countables?.sprays || 0
            })

    }, [user])

    return (
        <>

            <div className='min-h-screen overflow-y-hidden'>
                <img
                    src={
                        !user?.banner_pic
                            ? UserBannerImage
                            : user?.banner_pic
                    }
                    alt='banner'
                    className='w-full h-44 object-cover z-10 border-b-2'
                />
                <div className='flex items-start '>
                    <div className='w-full max-w-[18rem] px-5 lg:px-10 pb-4 bg-[#F6FAFD] hidden lg:inline-block' style={{ minHeight: 'calc(100vh - 11rem)' }}>
                        <div className='grid place-items-center -mt-20'>
                            {
                                !user?.profile_pic ?
                                    <DefaultUserIcon className={`w-40 h-40 z-20 shadow-lg rounded-full border-8 bg-white ${user?.toggle_state == "off" ? 'border-gray-300' : user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                                    :
                                    <img src={user?.profile_pic} alt='profile_pic' className={`w-40 h-40 z-20 shadow-lg rounded-full border-8 bg-white ${user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                            }
                        </div>
                        <h1 className='text-2xl font-medium text-navy text-center my-2'>{user?.username}</h1>
                        {user?.profession && <p className='text-gray-600 text-center'>
                            {user.profession}
                        </p>}
                        <div className='my-4'>
                            <span className='text-base font-medium text-gray-600'>Bio</span>
                            <p className='text-sm text-gray-700 line-clamp-2'>
                                {user?.bio || '-'}
                            </p>
                            {me && me?.id == user?.id && <button onClick={() => history.push('/userProfile/edit')} className='py-2 px-8 my-4 w-full bg-blue text-white rounded-md font-semibold'>Edit Profile</button>}
                        </div>
                        <div className='my-2 flex items-center flex-wrap gap-x-8 gap-y-2'>
                            <div className='flex-grow'>
                                <p className='text-base text-center font-medium text-gray-600'>Followers</p>
                                <p className='text-2xl font-semibold text-blue text-center'>{counts?.followers}</p>
                            </div>
                            <div className='flex-grow'>
                                <p className='text-base text-center font-medium text-gray-600'>Following</p>
                                <p className='text-2xl font-semibold text-green text-center'>{counts?.following}</p>
                            </div>
                            <div className='flex-grow'>
                                <p className='text-base text-center font-medium text-gray-600'>Projects</p>
                                <p className='text-2xl font-semibold text-red text-center'>{counts?.projects}</p>
                            </div>
                            <div className='flex-grow'>
                                <p className='text-base text-center font-medium text-gray-600'>Sprays</p>
                                <p className='text-2xl font-semibold text-yellow text-center'>{counts?.sprays}</p>
                            </div>
                        </div>

                        <div className='my-2'>
                            <span className='text-base font-medium text-gray-600 block'>Website</span>
                            {user?.socials?.website ? <a className='text-blue hover:text-blue-700 flex items-center gap-1' target="_blank" rel="noreferrer" href={user?.socials?.website}><span>Visit Website </span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                </svg>
                            </a> : <p>-</p>}
                        </div>

                        <div>
                            <span className='text-base font-medium text-gray-600'>Socials</span>
                            <div className='flex items-center gap-2 mt-1'>
                                {user?.socials?.github && <a href={user?.socials?.github} target="_blank" rel="noreferrer"><img className='w-5' src={Github} alt='' /></a>}
                                {user?.socials?.dribbble && <a href={user?.socials?.dribbble} target="_blank" rel="noreferrer"><img className='w-5' src={Dribbble} alt='' /></a>}
                                {user?.socials?.twitter && <a href={user?.socials?.twitter} target="_blank" rel="noreferrer"><img className='w-5' src={Twitter} alt='' /></a>}
                                {user?.socials?.instagram && <a href={user?.socials?.instagram} target="_blank" rel="noreferrer"><img className='w-5' src={Instagram} alt='' /></a>}
                                {user?.socials?.linkedin && <a href={user?.socials?.linkedin} target="_blank" rel="noreferrer"><img className='w-5' src={LinkedIn} alt='' /></a>}
                                {user?.socials?.pinterest && <a href={user?.socials?.pinterest} target="_blank" rel="noreferrer"><img className='w-5' src={Pinterest} alt='' /></a>}
                                {(user?.socials?.github || user?.socials?.dribbble || user?.socials?.twitter || user?.socials?.instagram || user?.socials?.linkedin || user?.socials?.pinterest) ? '' : <p className='text-gray-600 block'>No Social Link</p>}
                            </div>
                        </div>
                    </div>

                    <div className='flex-grow p-2'>
                        <FullWidthTabs free={free} />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProfileHome