import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Tab, Tabs, Button, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ProjectSpotlight from '../Project/ProjectSpotlight'
import PublicProjects from '../Project/PublicProjects'
import { getProjectsListBySpotlight, getPublicProjects, getPublicRepository } from '../../service/service'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PublicRepository from '../Repository/PublicRepository'
import Members from './Members'


const useStyles = makeStyles(theme => ({
  tabsCss: {
    backgroundColor: 'white',
    color: '#092C4C',
    padding: '0 2%',
  },
  selectedButton: {
    backgroundColor: '#092C4C',
    color: 'white',
    borderRadius: '25px',
  },
  tabText: {
    fontFamily: 'Poppins',
    textTransform: 'capitalize'
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ free }) {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const [spotlightProject, setSpotlightProject] = useState(true)
  const [publicProject, setPublicProject] = useState(false)
  const [publicRepo, setPublicRepo] = useState(false);
  const [members, setMembers] = useState(false);
  const [projects, setProjects] = useState({
    publicProjects: [],
    spotProjects: [],
    publicRepos: []
  });
  const { username } = useParams()

  const getProjects = async (username) => {
    const projects = await getPublicProjects(username);
    const spotProjects = await getProjectsListBySpotlight(username);
    const repositories = await getPublicRepository(username);

    let data = {
      publicProjects: [],
      spotProjects: [],
      publicRepos: []
    }
    if (projects.data) {
      data.publicProjects = projects.data;
    }
    if (spotProjects.data)
      data.spotProjects = spotProjects.data;

    if (repositories.data)
      data.publicRepos = repositories.data

    setProjects(data)
  }
  useEffect(() => {
    if (username) {
      getProjects(username)
    }
  }, [username]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSpotlightProject(true)
      setPublicProject(false)
      setPublicRepo(false);
      setMembers(false);
    } else if (newValue === 1) {
      setSpotlightProject(false)
      setPublicProject(true)
      setPublicRepo(false)
      setMembers(false)
    } else if (newValue === 2) {
      setSpotlightProject(false)
      setPublicProject(false)
      setPublicRepo(true)
      setMembers(false)
    } else if (newValue === 3) {
      setSpotlightProject(false)
      setPublicProject(false)
      setPublicRepo(false)
      setMembers(true)
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        aria-label="full width tabs example"
        className={classes.tabsCss}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Tab
          disableRipple
          sx={{
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 600,
          }}
          label={
            !spotlightProject ? (
              <h1 className='font-semibold text-base capitalize'>{"Project Spotlight"}</h1>
            ) : (
              <span>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  // In this case only inline Styling is working className css is not working -@Shraddha
                  style={{
                    backgroundColor: '#092C4C',
                    color: 'white',
                    borderRadius: '25px',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  }}
                >
                  {'Project Spotlight'}
                </Button>
              </span>
            )
          } {...a11yProps(0)} />
        <Tab
          disableRipple
          sx={{
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 600,
            color: '#092C4C',
            minWidth: '300px'
          }}
          label={
            !publicProject ? (
              <h1 className='font-semibold text-base capitalize'>{'Projects'}</h1>
            ) : (
              <span>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  // In this case only inline Styling is working className css is not working -@Shraddha
                  style={{
                    backgroundColor: '#092C4C',
                    color: 'white',
                    borderRadius: '25px',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  }}
                >
                  {'Projects'}
                </Button>
              </span>
            )
          } {...a11yProps(1)} />
        <Tab
          sx={{
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 600,
            color: '#092C4C',
            minWidth: '300px'
          }}
          disableRipple
          label={
            !publicRepo ? (
              <h1 className='font-semibold text-base capitalize'>{'Repositories'}</h1>
            ) : (
              <span>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  // In this case only inline Styling is working className css is not working -@Shraddha
                  style={{
                    backgroundColor: '#092C4C',
                    color: 'white',
                    borderRadius: '25px',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  }}
                >
                  {'Repositories'}
                </Button>
              </span>
            )
          } {...a11yProps(2)} />
        {/* <Tab
          sx={{
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 600,
            color: '#092C4C',
            minWidth: '300px'
          }}
          disableRipple
          label={
            !members ? (
              <h1 className='font-semibold text-base capitalize'>{'Members'}</h1>
            ) : (
              <span>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  // In this case only inline Styling is working className css is not working -@Shraddha
                  style={{
                    backgroundColor: '#092C4C',
                    color: 'white',
                    borderRadius: '25px',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  }}
                >
                  {'Members'}
                </Button>
              </span>
            )
          } {...a11yProps(3)} /> */}
      </Tabs>
      <Divider sx={{ m: '1% 3%' }} />

      <TabPanel value={value} index={0} dir={theme.direction}>
        <ProjectSpotlight projects={projects.spotProjects} free={free} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <PublicProjects projects={projects.publicProjects} free={free} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <PublicRepository repos={projects.publicRepos} free={free} />
      </TabPanel>
      {/* <TabPanel value={value} index={3} dir={theme.direction}>
        <Members />
      </TabPanel> */}
    </Box>
  );
}
