import JSZip from "jszip"
import { saveAs } from "file-saver"
import toast from "react-hot-toast"

export const fetchChildren = (obj, localObj) => {
    for (let i = 0; i < obj.length; i++) {
        if (obj[i].children) {
            fetchChildren(obj[i].children, localObj)
        } else {
            localObj.push(obj[i])
        }
    }
    return localObj
}

export const downloadFolderZip = async (files, downloadFolderName, type) => {
    let Platform = window?.navigator.platform;
    let folder = downloadFolderName

    let localObj = []
    localObj = fetchChildren(files, localObj);
    if (localObj.length !== 0) {
        let childrenList = localObj.filter(obj => {
            if (obj.path.includes(folder.path))
                return true
            return false
        })
        try {
            var zip = new JSZip();
            var img = zip.folder();
            let root = childrenList[0].path;

            toast('Downloading in Progress! Please Wait!', { id: 'file' })
            Promise.all(childrenList.map(async (child) => {
                if (child.file_url && child.file_url !== "" && child.file_url !== 'undefined') {
                    const result = await fetch(child.file_url, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer'
                    })
                    let updatedPath = ''
                    let isPresent = child.path.substring(0, root.length + 1) == root + "/";
    
                    if (isPresent)
                        updatedPath = `${child.path + "/"}`.slice(root.length + 1);
                    else {
                        if (child.path.length) {
                            updatedPath = `${child.path + "/"}`.slice(root.length + Number(type == 'folder'));
                        }
                        else {
                            updatedPath = ""
                        }
                    }
    
                    if (child.path !== folder.name && Platform.includes("Win")) {
                        img.folder(updatedPath);
                    }
                    let filePath = '';
                    filePath = updatedPath + child.name;
                    filePath = filePath.split("\\").join("/");
                    // if (child.path !== folder.name && Platform.includes("Win")) {
                    //     img.folder(child.path);
                    // }
                    // let filePath = '';
                    // filePath = child.path + "/" + child.name;
                    // filePath = filePath.split("\\").join("/");

                    img.file(filePath, result.blob());
                } else {
                    toast.error("Cannot download", { id: 'file' })
                }
            })).then(res => {
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, `${folder.name}.zip`);
                })
            }
            )
        } catch (err) {
            toast.error('Error in Downloading! Contact Admin', { id: 'file' })
        }
    }
}