import React from 'react'

function Tag({ text, extraClass="bg-yellow", color="#FFC258", width }) {
    return (
        <>
            <div className={`px-3 rounded-lg min-w-24 max-w-max pl-5 h-10 relative flex flex-shrink-0 items-center justify-end ${extraClass} ${width}`} style={{ backgroundColor: color }}>
                <div className={`rounded-lg w-8 h-8 transform rotate-45 absolute top-1 -left-2.5 grid place-items-center z-10 ${extraClass}`} style={{ backgroundColor: color }}>
                    <div className='w-2 h-2 rounded-full bg-white' />
                </div>
                <h1 className='text-right text-white text-sm px-1 tracking-wide z-20'>{text}</h1>
            </div>
        </>
    )
}

export default Tag