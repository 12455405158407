import React from "react";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import { Link } from "react-router-dom";

function NotifyEmail() {
    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
                <h1 className="text-3xl md:text-4xl font-bold text-blue text-center">Please verify your email</h1>
                <p className="md:text-lg font-medium text-center mt-4">Verification email sent to your registered email ID</p>
                <p className="md:text-lg font-medium text-center">or</p>
                <p className="md:text-lg font-medium text-center mb-4">Confirm Later</p>
                <Link to="/userProfileSetup"><button type='submit' className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                    Next
                </button>
                </Link>
            </div>
        </div>
    );
}

export default NotifyEmail;