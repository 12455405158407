import React, { useEffect, useState } from 'react'
import { getContributorsCountAgainstYear, getContributorsOfMonth, projectViewsCount, recentActivities, userStatistics } from '../../service/service';
import InsightBG from '../../assets/images/Insight_BG.png'
import PaintIcon from '../../assets/images/paint.png'
import { ArrowDropDown, ArrowUpward, CallMerge } from '@material-ui/icons';
import { useClickOutside } from '../../utils/helper';
import { useRef } from 'react';
import ProjectIcon from '../../assets/images/project.png'
import FileIcon from '../../assets/images/FileIcon.png'
import RepoIcon from '../../assets/images/repo.png'
import BranchIcon from '../../assets/images/branch.png'
import CalendarIcon from '../../assets/images/calendar.png'
import moment from 'moment';

// const opacArr = [0, 25, 50, 75, 100, 0, 25, 50, 75, 100]
const opacArr = [100, 75, 50, 25, 10]
const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

function dateFromDay(year, day) {
    var date = new Date(year, 0); // initialize a date in `year-01-01`
    return new Date(date.setDate(day)); // add the number of days
}
function Insight() {

    const [projectViews, setProjectViews] = useState({ kcount: 0, count: 0 })
    const [recentActivitiesList, setRecentActivitiesList] = useState([])
    const [contributions, setContributions] = useState(0)
    const [contributionsYears, setContributionsYears] = useState([])
    const [contributionsMonth, setContributionsMonth] = useState([])
    const [contributionsData, setContributionsData] = useState([])
    const [currentContributorYear, setCurrentContributorYear] = useState(currentYear);
    const [contributionsOfMonthList, setContributionsOfMonthList] = useState([])
    const [currentContributorMonth, setCurrentContributorMonth] = useState(currentMonth + 1)
    const [listUpdated, setListUpdated] = useState({ updated: false, list: [] })
    const [stats, setStats] = useState()


    const [more, setMore] = useState(false)
    const moreRef = useRef()
    useClickOutside(() => setMore(false), moreRef)

    const getStats = async () => {
        let res = await userStatistics()
        if (res.data) {
            setStats(res.data)
        }
    }

    useEffect(() => { getStats() }, [])

    function kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
            : Math.sign(num) * Math.abs(num)
    }

    async function getProjectViews() {
        const pViewsResponse = await projectViewsCount()
        if (pViewsResponse && pViewsResponse.data && pViewsResponse.data.count) {
            setProjectViews({
                kcount: kFormatter(pViewsResponse.data.count),
                count: pViewsResponse.data.count
            })
        }
    }

    const getInsights = async () => {
        const res = await getContributorsOfMonth(currentContributorYear, new Date().getMonth() + 1);
        if (res.data) {
            // setContributionsMonth(res.data);
            setContributionsData(res.data)
        }
    }

    async function getRecentActivity() {
        const recentActivityResponse = await recentActivities()
        if (recentActivityResponse.data && recentActivityResponse.data.length) {
            const recentActivityList = recentActivityResponse.data.slice(0, 4)
            setRecentActivitiesList(recentActivityList)
        }
    }

    async function getContributorsAgainstYear() {
        const listResponse = await getContributorsCountAgainstYear()
        if (listResponse && listResponse.success && listResponse.data) {
            const currentContribution = listResponse.data.filter(
                s => s.year === currentYear
            )
            setContributions(
                currentContribution.length > 0 ? currentContribution[0].count : 0
            )
            setContributionsYears(listResponse.data)
        } else {
            setContributionsYears([])
        }
    }

    useEffect(() => {
        // getContributorsAgainstYear()
        getProjectViews();
        getRecentActivity();
        getInsights()
    }, [])

    useEffect(() => {
        getContributorsOfEachMonth()
    }, [currentContributorMonth])

    useEffect(() => {
        if (contributionsData?.length) {
            let arr = [...contributionsData]
            let newArr = arr.filter(item => new Date(item.created_at).getFullYear() == currentContributorYear);
            setContributionsMonth(newArr)
        }
    }, [currentContributorYear, contributionsData])

    useEffect(() => {
        if (listUpdated?.updated) {
            setYearContributions()
        }
    }, [listUpdated?.updated])

    const getContributionOfYear = (year) => {
        if (contributionsData?.length) {
            let arr = [...contributionsData]
            let newArr = arr.filter(item => new Date(item.created_at).getFullYear() == year);
            return newArr.length;
        }
        else {
            return 0
        }
    }

    async function getContributorsOfEachMonth() {
        const listResponse = await getContributorsOfMonth(
            currentContributorYear,
            currentContributorMonth
        )
        if (listResponse && listResponse.success && listResponse.data) {
            const _tempList = contributionsOfMonthList
            let projects = []
            const realProjects = listResponse.data.filter(p => p.type === 'project')
            projects = [...realProjects]
            projects.map(p => (p.expand = false))
            let repos = []
            repos = listResponse.data.filter(p => p.type === 'repository')
            repos.map(s => {
                if (!projects.find(p => p.project_id === s.project_id)) {
                    projects.push(s)
                }
            })
            let branches = []
            branches = listResponse.data.filter(p => p.type === 'branch')
            branches.map(s => {
                if (!projects.find(p => p.project_id === s.project_id)) {
                    projects.push(s)
                }
            })
            let files = []
            files = listResponse.data.filter(p => p.type === 'branch')
            files.map(s => {
                if (!projects.find(p => p.project_id === s.project_id)) {
                    projects.push(s)
                }
            })
            _tempList.push({
                id: currentContributorMonth,
                projects: projects,
                repositories: repos,
                branches: branches,
                files: files,
                expand: false,
                projectsCount: realProjects.length
            })
            setListUpdated({ updated: true, list: _tempList })
        }
    }

    async function setYearContributions() {
        setListUpdated({ updated: false, list: listUpdated.list })
        setContributionsOfMonthList(listUpdated.list)
    }

    let contributionsByDay = contributionsMonth.length ? contributionsMonth?.reduce(function (r, a) {
        let item = dayOfYear(new Date(a.created_at));
        r[item] = r[item] || [];
        r[item].push(a);
        return r;
    }, Object.create(null)) : [];

    let contributionsByMonth = contributionsMonth.length ? contributionsMonth?.reduce(function (r, a) {
        let item = new Date(a.created_at).getMonth();
        r[item] = r[item] || [];
        r[item].push(a);
        return r;
    }, Object.create(null)) : [];

    const contributionsByType = (data) => {
        return data?.reduce(function (r, a) {
            if (a.type) {
                let item = a?.type
                r[item] = r[item] || [];
                r[item].push(a);
                return r;
            }
        }, Object.create(null));
    }

    const ContributionRow = ({ data, index, month }) => {
        const [isOpen, setIsOpen] = useState(index == 1)
        return <div className='py-4 px-10 border-l relative'>
            <div className='w-8 h-8 rounded-full absolute -left-3 top-4'>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.166992 11C0.166992 16.6507 4.44616 21.2889 9.91699 21.8333V14.25C8.66033 13.7927 7.75033 12.4154 7.75033 11C7.75033 9.58458 8.66033 8.20728 9.91699 7.74999V0.166656C4.44616 0.711045 0.166992 5.34924 0.166992 11ZM21.8337 9.91666H14.2503C13.9126 8.99068 13.0096 8.0877 12.0837 7.74999V0.166656C17.2256 0.678668 21.3216 4.77476 21.8337 9.91666ZM18.5837 7.74999C17.7797 5.78921 16.2111 4.22058 14.2503 3.41666V6.66666C14.6131 6.94117 15.0591 7.38724 15.3337 7.74999H18.5837ZM7.75033 6.66666V3.41666C4.54366 4.7079 2.33366 7.33417 2.33366 11C2.33366 14.6658 4.54366 18.3645 7.75033 19.6667V16.4167C6.41783 15.399 5.58366 12.7399 5.58366 11C5.58366 9.26009 6.41783 7.67339 7.75033 6.66666ZM12.0837 14.25V21.8333C17.2256 21.3213 21.3216 17.2252 21.8337 12.0833H14.2503C13.9126 13.0093 13.0096 13.9123 12.0837 14.25ZM15.3337 14.25C15.0591 14.6225 14.6131 15.0588 14.2503 15.3333V18.5833C16.2111 17.7794 17.7797 16.2108 18.5837 14.25H15.3337Z" fill="#7F92A3" />
                </svg>

            </div>
            <h1 className='text-lg select-none font-semibold cursor-pointer' onClick={() => setIsOpen(!isOpen)}>Contribution in {months[month]}, {currentContributorYear}</h1>
            {
                isOpen ?
                    <div className='px-4 py-2 text-gray-500'>
                        {data.project ? <div className='flex items-center gap-1.5'><img src={ProjectIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{data.project.length} Project Created</span></div> : ''}
                        {data.repository ? <div className='flex items-center gap-1.5'><img src={RepoIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{data.repository.length} Repository Created</span></div> : ''}
                        {data.branch ? <div className='flex items-center gap-1.5'><img src={BranchIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{data.branch.length} Branch Created</span></div> : ''}
                        {data.file ? <div className='flex items-center gap-1.5'><img src={FileIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{data.file.length} File Created</span></div> : ''}
                    </div>
                    : ''
            }
        </div>
    }

    const BoxHover = ({ data, day }) => {
        const contribution = contributionsByType(data);
        return <div className='absolute bottom-6 -left-2 min-w-max p-2 pb-4 bg-white text-gray-900 font-medium shadow hidden group-hover:block rounded text-sm' style={{ opacity: '100%' }}>
            <div className='absolute transform -bottom-1 left-3 -translate-x-1/2 w-4 h-4 -rotate-45 bg-white' style={{ borderColor: '#eee #eee transparent transparent' }} />
            {/* <h1 className='mt-1 mb-3'>{moment(dateFromDay(currentContributorYear, day)).format('DD MMM YYYY')}</h1> */}
            {<div className='flex items-center gap-2 mt-1 mb-2 pb-1 border-b text-base'><img src={CalendarIcon} alt="" className="inline-block w-4 h-4" /><span>{moment(dateFromDay(currentContributorYear, day)).format('DD MMM YYYY')}</span></div>}
            {contribution.project ? <div className='flex items-center gap-1.5'><img src={ProjectIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{contribution.project.length} Project Created</span></div> : ''}
            {contribution.repository ? <div className='flex items-center gap-1.5'><img src={RepoIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{contribution.repository.length} Repository Created</span></div> : ''}
            {contribution.branch ? <div className='flex items-center gap-1.5'><img src={BranchIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{contribution.branch.length} Branch Created</span></div> : ''}
            {contribution.file ? <div className='flex items-center gap-1.5'><img src={FileIcon} alt="" className="inline-block w-4 h-4 bg-blue-50" /><span>{contribution.file.length} File Created</span></div> : ''}
        </div>
    }

    return (
        <>
            <div className='flex items-center gap-8 xl:gap-12 py-4'>
                <div className='p-2'>
                    <h1 className='text-5xl font-semibold text-red'>{projectViews.kcount}</h1>
                    <h1 className='font-medium text-gray-500'>Project Views</h1>
                </div>
                <div className='p-2'>
                    <h1 className='text-5xl font-semibold text-yellow'>0</h1>
                    <h1 className='font-medium text-gray-500'>Sprays</h1>
                </div>
                <div className='p-2'>
                    <h1 className='text-5xl font-semibold text-blue'>{stats?.followers || 0}</h1>
                    <h1 className='font-medium text-gray-500'>Followers</h1>
                </div>
                <div className='p-2'>
                    <h1 className='text-5xl font-semibold text-green'>{stats?.following || 0}</h1>
                    <h1 className='font-medium text-gray-500'>Followings</h1>
                </div>
            </div>
            <div className='flex items-center justify-between text-navy text-lg w-[674px]'>
                <h1 className='p-1'>{contributionsMonth?.length} Contributions</h1>
                <div className='ml-auto relative px-4' ref={moreRef}>
                    <h1 className='inline-block'>Contribution Map&nbsp;</h1>
                    <span className='font-semibold cursor-pointer' onClick={() => setMore(!more)}>{currentContributorYear} <ArrowDropDown /></span>
                    {more && <div className='absolute top-8 right-0 w-full border bg-white shadow-md rounded-lg z-30 py-3'>
                        <div className='absolute transform -top-2 right-5 w-4 h-4 border -rotate-45 bg-white' style={{ borderColor: '#eee #eee transparent transparent' }} />
                        <div className='w-full flex items-center justify-between gap-2 px-2 py-1'><ArrowUpward fontSize='small' className='text-blue cursor-pointer' /><h1 className='text-navy text-xs'><span className='font-medium'>Less</span> &nbsp;0% <span className='font-medium'>Opacity</span> 100% &nbsp; <span className='font-medium'>More</span></h1></div>

                        <div className='py-2 px-4 w-full flex items-center justify-between gap-2 hover:bg-blue-500 hover:text-white cursor-pointer group' onClick={() => { setCurrentContributorYear(2023); setMore(false) }}><span className='font-medium text-base flex-grow text-center'>2023</span><span className=' text-gray-500 group-hover:text-gray-50 text-sm'>{getContributionOfYear(2023)} contributions</span></div>
                        <div className='py-2 px-4 w-full flex items-center justify-between gap-2 hover:bg-blue-500 hover:text-white cursor-pointer group' onClick={() => { setCurrentContributorYear(2022); setMore(false) }}><span className='font-medium text-base flex-grow text-center'>2022</span><span className=' text-gray-500 group-hover:text-gray-50 text-sm'>{getContributionOfYear(2022)} contributions</span></div>
                    </div>}
                </div>
                <div className=' flex items-center cursor-pointer w-10 overflow-hidden hover:w-40 transform transition-all duration-500 ease-in-out'>
                    <img src={PaintIcon} className="w-8 h-8 m-2 bg-purple-50 rounded-full" alt='' />
                    <h1 className='text-base font-medium min-w-max'>Theme: Space</h1>
                </div>
            </div>
            <div className='p-3 rounded-lg border max-w-max'>
                <div className='h-[144px] flex flex-wrap relative rounded-lg w-[656px] min-w-[656px] max-w-[656px]'>
                    <img className='absolute h-full z-10 object-cover rounded-lg w-[656px] min-w-[656px] max-w-[656px]' src={InsightBG} alt="" />
                    {Array(365).fill(1).map((item, i) => {
                        // console.log(opacArr[Math.round(Math.random() * 10)])
                        return <div key={i} className={`${i == 0 || i == 38 || i == 326 || i == 364 ? 'w-8' : 'w-4'} h-4 relative border-gray-200 z-20 group`} >
                            <div className='w-full bg-white h-full' style={{ opacity: `${contributionsByDay[i]?.length ? contributionsByDay[i]?.length > 5 ? 0 : opacArr[contributionsByDay[i]?.length] : 90}%`, cursor: contributionsByDay[i] ? 'pointer' : 'auto' }}></div>
                            {contributionsByDay[i] ? <BoxHover data={contributionsByDay[i]} day={i + 1} /> : ''}
                        </div>
                        // return <div key={i} className='w-4 h-4 border border-gray-200 bg-white z-20' style={{ opacity: `${opacArr[Math.round(Math.random() * 10)]}%` }}></div>
                    })}
                </div>
                <div className='flex items-center justify-between mt-2'>
                    <h1 className='text-navy italic text-sm'>Art Credit: Team Greyffiti</h1>
                    <h1 className='text-navy text-xs'><span className='font-medium'>Less</span> &nbsp;0% <span className='font-medium'>Opacity</span> 100% &nbsp; <span className='font-medium'>More</span></h1>
                </div>
            </div>

            <p className='text-navy text-2xl mb-4 mt-6 border-b py-1 font-medium'>Contribution Insights</p>

            <div className='max-w-xl w-full'>
                {Object.keys(contributionsByMonth).length ?
                    <>
                        {
                            Object.keys(contributionsByMonth)?.reverse().map((key, i) => {
                                const data = contributionsByType(contributionsByMonth[key]);
                                return <ContributionRow data={data} key={key} month={key} index={i + 1} />
                            })
                        }
                    </>
                    : <h1 className='text-gray-500 text-lg text-center my-4'>No contributions yet get started by creating a project</h1>}
            </div>

        </>
    )
}

export default Insight