import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUserDetails } from '../../slices/userSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { follow, getUserByUsername, unfollow, userStatistics } from '../../service/service';
import ProUser from './ProUser';
import ProfileHome from './ProfileHome';
import logo from '../../assets/images/logo.png'
import { useEffect } from 'react';
import Loader from '../ReusableComponents/Loader';
import Navbar from '../ReusableComponents/Navbar';
import CompanyUser from '../Company/CompanyUser';

function ProfileScreen() {
    const history = useHistory()
    const { username } = useParams();
    const me = useSelector(selectUserDetails);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();

    const [stats, setStats] = useState()

    const getStats = async () => {
        let res = await userStatistics(username)
        if (res.data) {
            setStats(res.data)
        }
    }

    const getUserInfo = async () => {
        const userInfo = await getUserByUsername(username);
        if (userInfo?.data) {
            setUser(userInfo?.data)
            getStats()
        }
        else {
            history.push("/404")
        }
        setLoading(false)
    }

    const addFollow = async () => {
        let res = await follow({ organization_id: user?.organization?.id, user_id: user?.user_id })
        if (res.data) {
            let obj = { ...user }
            obj.following = true;
            setStats({ ...stats, followers: stats.followers + 1 })
            setUser(obj)
        }
    }
    const withdrawFollow = async () => {
        let res = await unfollow({ organization_id: user?.organization?.id, user_id: user?.user_id })
        if (res.data) {
            let obj = { ...user }
            obj.following = false;
            setStats({ ...stats, followers: stats.followers - 1 })
            setUser(obj)
        }
    }
    useEffect(() => { getUserInfo() }, [username])

    if (loading) return <Loader />

    return (
        <>
            {me ? <Navbar /> :
                <nav className="flex items-center justify-between px-5 py-3 lg:px-10 sticky top-0 bg-white z-50">
                    <div className="flex items-center justify-center">
                        <div className="h-12 w-10 object-contain" onClick={() => history.push("/")}>
                            <img src={logo} alt="logo" />
                        </div>
                        <ul className="hidden lg:flex items-center space-x-6 md:space-x-8 xl:space-x-10 pl-10 text-lg font-medium">
                            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#home`}>Home</a></li>
                            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#about`}>About Us</a></li>
                            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#features`}>Features</a></li>
                            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}/pricing`}>Pricing</a></li>
                            <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#support`}>Support</a></li>
                        </ul>
                    </div>

                    <div className="flex items-center space-x-4">
                        <Link to="/login"><button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium">Login</button></Link>
                        <span className='hidden sm:inline-flex'>or</span>
                        <Link to="/register"> <button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium hidden sm:inline-flex">Create Account</button></Link>
                    </div>
                </nav>
            }
            {
                user?.planName?.includes('pro')
                    ?
                    <ProUser me={me} user={user} free={false} addFollow={addFollow} withdrawFollow={withdrawFollow} stats={stats} />
                    :
                    user?.planName?.includes('free')
                        ?
                        <ProfileHome me={me} user={user} free={true} />
                        :
                        !user?.planName?.includes('free') && !user?.planName?.includes('pro') && user?.organization?.created_by == user?.id ?
                            <CompanyUser me={me} user={user} addFollow={addFollow} withdrawFollow={withdrawFollow} stats={stats} />
                            :
                            <ProfileHome me={me} user={user} free={true} />
            }
        </>
    )
}

export default ProfileScreen