import React, { useState } from 'react'
import {
    Button,
} from "@material-ui/core";
import useStyle from '../Styling'
import { updateSettings } from '../../../service/service'
import toast from 'react-hot-toast';

function Save(props) {
    const {data} =props;
    const classes = useStyle();
    const save = async() => {
        const res = await updateSettings(data)
        props.getSettingsList()
        if (res.success) {
            toast.success("Settings Saved Sucessfully")
        } else {
            toast.error("Something went wrong")
        }
    }
    return (
        <div className={classes.buttonGroup}>
            <Button
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                onClick={save}>
                Save
            </Button>    
        </div>
    )
}

export default Save
