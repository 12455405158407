import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    makeStyles
} from '@material-ui/core'

const style = makeStyles((theme) => ({
    paper: {
        minWidth: "38%",
        height: '80vh',
        position: 'fixed'
    },
    title: {
        margin: '0% 3%'
    },
    titleFont1: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        color: '#00000',
        display: 'inline-block'
    },
    titleFont2: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#AAAAAA',
        display: 'inline-block',
        marginLeft: '15px'
    },
    dividerTop: {
        margin: '0% 5%',
        border: 'solid 1px black'
    },
    imageConatiner: {
        marginLeft: '1%',
    },
    actionsBtn: {
        height: '12%',
        margin: '0% 2%'
    },
    buttonalignRight: {
        textAlign:'right',
        display: 'inline-block',
        margin: '10px 30px',
    },
    cancleBttn: {
        color: "#FE8083",
        borderRadius: '25px',
        width: '150px',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: "#FE8083 ",
            color: "#ffff"
        }
    },
    saveBttn: {
        backgroundColor: "#2DC8A8",
        color: "#ffff",
        borderRadius: '25px',
        width: '170px',
        '&:hover': {
            backgroundColor: "#25A98E ",
        }
    },
}));

function UploadProfile(props) {
    const {clickable} = props
    const classes = style()

    return (
        <div>
            <Dialog
                open={props.openBox}
                onClose={props.closeUpload}
                classes={{ paper: classes.paper }}>
                <DialogTitle className={classes.title}>
                    <div className={classes.titleFont1}>
                        Replace Profile Picture
                    </div>
                </DialogTitle>
                <Divider className={classes.dividerTop} />
                <DialogContent className={classes.imageConatiner}>
                    {props.children}
                </DialogContent>
                <Divider />
                <div className={classes.buttonalignRight}>
                    <Button
                        className={classes.cancleBttn}
                        onClick={props.closeUpload}>
                        Cancel
                    </Button>
                    <Button
                        className={classes.saveBttn}
                        disabled={clickable}
                        onClick={props.showCroppedImage}>
                        Crop & Save
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}

export default UploadProfile