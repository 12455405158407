import { Button, InputAdornment, TextField, Typography } from '@material-ui/core'
import { EmailOutlined, PlusOne } from '@material-ui/icons'
import React, { useRef, useState } from 'react'
import { sendInviteToNonUsers } from '../../service/service'
import validator from 'validator'
import toast from 'react-hot-toast'
import { useClickOutside } from '../../utils/helper'
import UserBordered from '../../assets/icons/UserBordered'

function InviteViaEmail({ setEmailInvite, setOpenPopUp, type, id }) {
    const [users, setUsers] = useState([])
    const cancelRef = useRef()
    useClickOutside(() => setEmailInvite(false), cancelRef)

    const classes = {
        userRow: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 20,
            marginBottom: 20
        },

        colContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 20,
            height: '100%',
        },
        formText: {
            fontFamily: 'Poppins',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            textAlign: 'left',
            color: '#7F92A4',
            fontSize: '16px',
            fontWeight: '400',
            marginBottom: '20px'
        },
    }

    const removeUser = (email) => {
        const i = users.findIndex(item => item == email);
        if (i > -1) {
            let newArr = [...users]
            newArr.splice(i, 1);
            setUsers([...newArr])
        }
    }

    const UserRow = ({ user }) => {
        return <div style={classes.userRow}>
            <UserBordered className='w-10 h-10 rounded-full' />
            <div style={{ flexGrow: 1 }}>
                <p className='text-gray-800 font-semibold text-lg'>{user}</p>
            </div>
            <button className='bg-red rounded-lg px-5 py-1.5 font-medium text-white capitalize' onClick={() => removeUser(user)}>
                Remove
            </button>
        </div>
    }

    const [newUser, setNewUser] = useState('')

    const handleAdd = () => {
        const i = users.findIndex(item => item == newUser)
        if (i > -1) {
            return toast.error('Email already added')
        }
        setUsers((prev) => [...prev, newUser]);
        setNewUser('')
    }

    const handleDone = async () => {
        const data = {
            invite_for: type,
            id: id,
            invite_emails: users.join()
        }
        const res = await sendInviteToNonUsers(data);
        if (users.join()) {
            toast.success('Invitation email sent')
        }
        setOpenPopUp(true)
        setEmailInvite(false)
    }
    const handleLink = () => {
        setOpenPopUp(true);
        setEmailInvite(false)
    }

    return (
        <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
            <div className='max-w-xl bg-white p-5 w-full rounded-md h-[80vh] flex flex-col overflow-y-auto' ref={cancelRef}>
                <h1 className='pb-1 border-b text-xl font-semibold to-gray-900 mb-6'>Invite Contributor</h1>

                <Typography
                    style={{ textAlign: 'left', width: '100%' }}>
                    Some description here...
                </Typography>
                <div className='w-full p-4 flex-grow'>
                    {
                        users.map((user, i) => <UserRow key={i} user={user} />)

                    }
                    <div className='flex items-center gap-6'>
                        <TextField style={{ marginLeft: 10, flexGrow: 1 }} size="small" fullWidth placeholder="email address" id="fullWidth" variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            value={newUser}
                            onChange={(e) => {
                                setNewUser(e.target.value)
                            }}
                        />
                        <Button variant="contained" color='primary' endIcon={<PlusOne />} disabled={!validator.isEmail(newUser)} onClick={() => { if (validator.isEmail(newUser)) handleAdd() }}>
                            Add
                        </Button>
                    </div>
                </div>

                <div className='flex items-center justify-between w-full p-4 sticky bottom-0 bg-white'>
                    <Button
                        variant='contained'
                        onClick={handleLink}
                    >
                        Back
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleDone}
                    >
                        Done
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default InviteViaEmail