import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { UserImage } from '../../assets/cloud';
import { getAllPlans, getAllUsers } from '../../service/service';
import Loader from '../ReusableComponents/Loader'
import Navbar from '../ReusableComponents/Navbar'

function formatBytes(a, b = 2) { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return `${Math.round(parseFloat((a / Math.pow(1024, d)).toFixed(c)))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }

function AdminHome() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [sort, setSort] = useState(1);
  const [search, setSearch] = useState('')
  const fetchData = async () => {
    let planRes = await getAllPlans()
    setPlans(planRes.data)
    let userRes = await getAllUsers()
    setUsers(userRes.data);

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSort = (e) => {
    let arr = [...users]
    if (e.target.value == 1) {
      let sorted = arr.sort((a, b) => (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
      setUsers(sorted)
    }
    else if (e.target.value == 2) {
      let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
      setUsers(sorted)
    }
    else if (e.target.value == 3) {
      let sorted = arr.sort((a, b) => a.username.localeCompare(b.username));
      setUsers(sorted)
    }

  }

  return (
    <>
      <Navbar />
      <div className='relative'>
        {loading ? (
          <><Loader /></>
        ) : (
          <>
            <div className='w-full max-w-[90rem] mx-auto p-4'>
              <h1 className='text-xl lg:text-2xl font-medium mb-2'>Plans</h1>
              <div className='flex flex-wrap justify-start items-start gap-10 lg:gap-12'>
                {plans.map(plan => <div key={plan.id} className='w-full max-w-xs my-4 p-4 shadow-lg shadow-gray-100 rounded grid gap-y-6 gap-x-3 grid-cols-2'>
                  <div>
                    <h1 className='text-xl text-center font-medium text-navy'>{plan.name}</h1>
                    <h1 className='text-gray-500 text-center'>{plan.validity} days</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>₹{plan.base_price}</h1>
                    <h1 className='text-center'>Price</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{plan.repository}</h1>
                    <h1 className='text-center'>Repository</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{plan.project}</h1>
                    <h1 className='text-center'>Projects</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{plan.repo_contributors}</h1>
                    <h1 className='text-center'>Repository Contributors</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{plan.project_contributors}</h1>
                    <h1 className='text-center'>Project Contributors</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{formatBytes(plan.file_upload)}</h1>
                    <h1 className='text-center'>Upload Limit</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{formatBytes(plan.project_storage)}</h1>
                    <h1 className='text-center'>Storage</h1>
                  </div>
                  <div>
                    <h1 className='text-xl text-center font-semibold text-blue'>{plan.version_history}</h1>
                    <h1 className='text-center'>Version History</h1>
                  </div>
                  <div>
                    <h1 className='text-lg text-center font-semibold text-blue'>{moment(plan.updated_at).format("DD/MM/YYYY")}</h1>
                    <h1 className='text-center'>Last Updated</h1>
                  </div>
                </div>)}
              </div>

            </div>
            <section className='w-full max-w-[90rem] mx-auto p-4'>
              <div className='flex items-center justify-between gap-3'>
                <h1 className='text-xl lg:text-2xl font-medium mb-2'>Users</h1>
                <div className='flex items-center gap-4'>
                  <input type="text" placeholder='By Plan, username' value={search} onChange={(e) => setSearch(e.target.value)} className='py-1 px-2 w-full max-w-sm outline-none border border-gray-400 rounded' />
                  <select name="" onChange={handleSort} className='py-1 px-2 border border-gray-400 rounded outline-none cursor-pointer'>
                    <option value="1">Recently Added</option>
                    <option value="2">Oldest First</option>
                    <option value="3">By Name</option>
                  </select>
                </div>
              </div>
              {users.map(user => (user.username?.toLowerCase().includes(search.toLowerCase()) || user.planname.toLowerCase().includes(search.toLowerCase())) && <UserRow key={user.id} user={user} />)}

            </section>

          </>
        )}
      </div>
    </>
  )
}

export default AdminHome

const UserRow = ({ user }) => {

  return (<>
    <div className='w-full my-4 p-4 shadow-lg shadow-gray-100 rounded flex items-center gap-3 justify-between'>
      <div className='flex items-center gap-3 max-w-[280px] w-full'>
        <img src={user.profile_pic || UserImage} className='w-14 h-14 rounded-full border' alt="" />
        <div>
          <h1 className='text-lg font-medium'>{user.username}</h1>
          <h1 className='text-gray-600'>{user.planname}</h1>
        </div>
      </div>
      <div>
        <h1 className='text-xl lg:text-2xl text-center font-semibold text-blue'>{user.projects}</h1>
        <h1 className='text-center'>Projects</h1>
      </div>
      <div>
        <h1 className='text-xl lg:text-2xl text-center font-semibold text-yellow'>{user.repos}</h1>
        <h1 className='text-center'>Repository</h1>
      </div>
      <div>
        <h1 className='text-xl lg:text-xl text-center font-semibold text-red'>{formatBytes(user.storageUsed)}</h1>
        <h1 className='text-center'>Storage Used</h1>
      </div>
      <div>
        <h1 className='text-xl lg:text-xl text-center font-semibold text-green'>{formatBytes(user.project_storage)}</h1>
        <h1 className='text-center'>Total Storage</h1>
      </div>
    </div>
  </>)
}