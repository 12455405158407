import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { getRetailerInviteList, getUserByUsername } from '../../service/service';
import MiniLoader from '../ReusableComponents/MiniLoader';
import moment from 'moment';
import Picture from '../../assets/images/picture.png'
import { filePreview } from '../../slices/fileSlice';
import { Tooltip } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';


function Project() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { username } = useParams()
    const [loading, setLoading] = useState(true);
    const user = useSelector(selectUserDetails);
    const [userData, setUserData] = useState();
    const [invites, setInvites] = useState([])
    const [search, setSearch] = useState('')

    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            setUserData(res.data);
        }
        else {
            history.push("/404")
        }
    }

    const getInvites = async () => {
        let res = await getRetailerInviteList()
        if (res.success) {
            setInvites(Array.isArray(res.data) ? res.data : [])
            setLoading(false)
        }
    }

    useEffect(() => {
        getUser();
        getInvites();
    }, [username])


    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData }} className="lg:max-w-sm hidden lg:inline-flex">
                        </UserCard>

                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push('/dashboard')}>Insights</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/`)}>Projects</h1>
                                <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${userData?.username}/all`)}>Repositories</h1>
                                {user && <h1 className='text-lg font-medium text-gray-500 cursor-pointer leading-loose' onClick={() => history?.push(`/${user?.username}/issues`)}>Issues</h1>}
                                {user && <h1 className='text-lg font-medium cursor-pointer leading-loose text-gray-500' onClick={() => history?.push(`/${user?.username}/events`)}>Events</h1>}
                                {user && <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500' onClick={() => history?.push(`/${userData?.username}/retailer`)}>Retailer</h1>}

                            </div>
                            <div className='flex items-center justify-between my-4 gap-4'>
                                <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                                    <SearchOutlined />
                                    <input type="text" placeholder='Search user or repository...' value={search} onChange={(e) => invites.length ? setSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                </div>
                            </div>
                            <div className='w-full relative'>
                                {/* <div className='flex flex-wrap items-center justify-start gap-8'>
                                    {
                                        !invites?.filter(item => item?.status == 'accepted')?.length
                                            ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Invites Available</h1>
                                            :
                                            invites?.filter(item => item?.status == 'accepted')?.map((repo, i) => (
                                                <>
                                                    <div className='w-full h-60 max-w-[360px] rounded-lg overflow-hidden group cursor-pointer relative' onClick={() => history.push(`/${repo?.username}/${repo.projectname}/${repo.reponame}/main`)}>
                                                        <img src={(repo?.thumbnail && repo?.thumbnail != 'undefined') ? repo?.thumbnail : CardBG} className="w-full h-full object-cover" alt='' />
                                                        <div className='absolute inset-0 z-10 p-4 text-white flex flex-col items-start justify-end ' style={{ background: `linear-gradient(180deg, rgba(98, 98, 98, 0) 0%, #000000 100%)` }}>
                                                            <h1 className='text-white absolute top-0 right-0 py-2 px-3 bg-black bg-opacity-20 rounded-bl-3xl'>Accepted {moment(repo?.updated_at).fromNow()}</h1>
                                                            <h1 className='line-clamp-1 text-sm'>{repo?.username} / {repo?.projectname}</h1>
                                                            <h1 className='mb-3 text-lg font-medium line-clamp-1'><span className='font-medium'>/{repo.reponame}</span></h1>
                                                            <div className='flex items-center justify-between w-full'>
                                                                <button title={repo?.repo_topic} className='bg-white text-gray-800 py-1 px-4 text-sm rounded-full'>
                                                                    {repo?.repo_topic?.length > 25 ? repo?.repo_topic?.slice(0, 25) + '...' : repo?.repo_topic}
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                    }
                                </div> */}
                                {!invites?.filter(item => item?.status == 'accepted' && (item?.username?.toLowerCase()?.includes(search?.toLowerCase()) || item?.reponame?.toLowerCase()?.includes(search?.toLowerCase())))?.length
                                    ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Retailer Project</h1>
                                    :
                                    <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                        <thead>
                                            <tr className='font-medium text-gray-500'>
                                                <td>Repository</td>
                                                <td>Owner</td>
                                                <td>Description</td>
                                                <td className='text-center'>Project</td>
                                                <td className='text-center'></td>
                                            </tr>
                                        </thead>
                                        <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                            {
                                                invites?.filter(item => item?.status == 'accepted' && (item?.username?.toLowerCase()?.includes(search?.toLowerCase()) || item?.reponame?.toLowerCase()?.includes(search?.toLowerCase()))).map(repo => (
                                                    <tr onClick={() => history.push(`/${repo?.username}/${repo?.projectname}/${repo?.reponame}/main`)} key={repo.id} className='bg-white rounded-sm shadow transform hover:bg-gray-50 cursor-pointer'>
                                                        <td className='font-medium text-gray-800 cursor-pointer'>
                                                            <div className='absolute top-0 left-0 w-full h-full bg-transparent cursor-pointer'></div>
                                                            <p className='flex items-center gap-1'>
                                                                <Tooltip title={repo.reponame}><span>{repo.reponame.length > 16 ? `${repo.reponame.slice(0, 15)}...` : repo.reponame}</span></Tooltip>
                                                                {(repo?.thumbnail && repo?.thumbnail != 'undefined') && <img src={Picture} alt='' className='mr-auto cursor-pointer w-5 h-5' style={{ filter: 'drop-shadow(2px 2px 8px rgba(0,0,0,0.3))' }} onClick={() => dispatch(filePreview({ url: repo?.thumbnail, title: repo?.reponame, open: true }))} />}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {repo?.username}
                                                        </td>
                                                        <td className='max-w-sm'>
                                                            <p className='max-w-sm'>{repo.description}</p>
                                                        </td>
                                                        <td className='text-center'>{repo.projectname}</td>
                                                        <td className='relative'>
                                                            <p className='text-gray-500 font-light mt-3 text-xs text-center'>Accepted {moment(repo.updated_at).fromNow()}</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default Project
