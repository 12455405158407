import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    IconButton,
    FormControl,
    Radio,
    FormControlLabel,
    RadioGroup,
} from "@material-ui/core";
import {
    statusUpdate,
    getStatus,
    getToggleState,
    toggleStateUpdate
} from '../../service/service';

import ToggleOnIcon from "../../assets/images/toggle_on_24px.svg";
import ToggleOffIcon from "../../assets/images/toggle_off_24px.svg";
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
    radioClass: {
        float: "left",
        width: '70%',
        marginLeft: "4%",
        marginTop: "3%",
        '& .MuiFormGroup-root': {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
        },
        '& .MuiTypography-body1': {
            fontFamily: "Poppins",
            fontStyle: "Regular",
            fontSize: "14px",
            marginRight: '3%',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: '18%',
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#1D85E8',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            '& .MuiFormControlLabel-root': {
                marginRight: '10%',
            }
        }
    },
}));

function Status() {
    const classes = useStyles();
    const [radioValue, setRadioValue] = React.useState('online');
    const [count, setCount] = useState(1);
    const [toggleOn, setToggleOn] = useState(true);
    const [togglecount, setToggleCount] = useState(1);
    const [toggleValue, setToggleValue] = React.useState('on');

    const handleToggle = () => {
        setToggleOn(!toggleOn)
        if (toggleOn === true) {
            setToggleValue("on")
            updateToggleStatus("off")
        } else {
            setToggleValue("off")
            updateToggleStatus("on")
        }
    }
    const handleChange = (event) => {
        setRadioValue(event.target.value);
        updateStatus(event.target.value);
    };

    useEffect(() => {
        getStatusInfo();
        getToggleInfo();
    }, []);

    async function getStatusInfo() {
        if (count === 1) {
            const res = await getStatus();
            if (res.data != null) {
                setRadioValue(res.data);
            } else {
                setRadioValue("online");
            }
            setCount(count + 1)
        }
    }

    async function updateStatus(statusType) {
        let status_type = statusType;
        let data = { status_type }
        const res = await statusUpdate(data);
        if (res.data != null) {
            toast.success("Status Info Updated Successfully", { id: 'success' })
        }
    }

    async function getToggleInfo() {
        if (togglecount === 1) {
            const res = await getToggleState();
            if (res.data != null) {
                setToggleValue(res.data);
                if (res.data === "on") {
                    setToggleOn(true)
                } else {
                    setToggleOn(false)
                }
            } else {
                setToggleValue("on");
                setToggleOn(true)
            }
            setToggleCount(togglecount + 1)
        }
    }

    async function updateToggleStatus(toggleValue) {
        let toggle_state = toggleValue;
        let data = { toggle_state }
        const res = await toggleStateUpdate(data);
        if (res.data != null) {
            toast.success("Status Info Updated Successfully", { id: 'success' })
        }
    }

    return (
        <>
            <h1 className="text-2xl font-semibold text-left mb-3">Status</h1>
            <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                <div className='flex items-center justify-between w-full max-w-xl'>
                    <h1 className="text-lg font-semibold">
                        Profile status
                    </h1>
                    <IconButton aria-label="toggleOnIcon" onClick={handleToggle}>
                        {toggleOn ? <img
                            src={ToggleOnIcon}
                            alt="toggleOnIcon"
                        /> : <img
                            src={ToggleOffIcon}
                            alt="toggleOffIcon"
                        />}
                    </IconButton>
                </div>
                <p className='text-sm lg:text-base py-2 text-gray-600 max-w-xl'>
                    Set your profile status to help user interact with you
                </p>

                <FormControl component="fieldset" disabled={toggleOn ? "" : "true"} className={classes.radioClass}>

                    <RadioGroup aria-label="status" name="status" value={toggleOn ? radioValue : ""} onChange={handleChange}>

                        <FormControlLabel value="online" control={<Radio />} label="Online" />
                        <FormControlLabel value="offline" control={<Radio />} label="Offline" />
                        <FormControlLabel className={classes.customizedBreakpoints} value="working" control={<Radio />} label="Working" />
                        {/* <FormControlLabel value="customized" control={<Radio />} label="Customized" /> */}

                    </RadioGroup>
                </FormControl>
            </div>
        </>
    );
}
export default Status;