import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
    listBox: {
        backgroundColor: '#F6FAFD',
        border: '2px solid #EEEEEE',
        borderRadius: '5px',
        width: '87%',
        margin: '0px 0px 20px 40px ',
    },
    listContent: {
        padding: '10px 40px 10px 40px'
    },
    title: {
        fontSize: '18px',
        color: '#333333',
        fontWeight: 600
    },
    description: {
        fontSize: '14px',
        marginTop: '8px',
        textAlign: 'justify',
        textJustify: 'inter-word'
    },
    toggle: {
        margin: '-15px -3px 40px 40px'
    },
    toggleQue: {
        margin: '7px -3px 40px 40px'
    },
    buttonGroup: {
        margin: '30px 20px 30px 20px'
    },
    saveBtn: {
        width: '150px',
        height: '50px',
        backgroundColor: '#1D85E8',
        '&:hover': {
            backgroundColor: '#1165B5'
        }
    },
    restoreBtn: {
        width: '150px',
        height: '50px',
        color: '#FFFFFF',
        backgroundColor: '#FFC258',
        '&:hover': {
            backgroundColor: '#D3A308',
            color: '#FFFFFF',
        }
    },
    settingsHeadding: {
        color: '#000000',
        fontSize: '24px',
        fontWeight: 600,
        float: 'left',
        margin: '0px 40px 20px 40px',
        // backgroundColor:'#E9967A'
    },
    changeBtn: {
        color: '#1D85E8',
        margin: '-17px -3px 40px 20px'
    },
    systemSelectInput: {
        width: '60%',
        float: 'right',
        backgroundColor: '#ffff',
        margin: '10px 50px 10px 0px',
        fontWeight: 600
    },
    toggleQuestion: {
        margin: '0px 60px 0px 40px'
    },
    currentQuestion: {
        fontSize: '16px',
        margin: '10px 0px 0px 0px'
    },
    securitySelectInput: {
        width: '92%',
        float: 'left',
        backgroundColor: '#ffff',
        margin: '10px 50px 10px 0px',
        '& .MuiInputBase-input': {
            fontSize: '18px'
        }
    },
    securitySpacing: {
        padding: '0px 40px 0px 40px'
    },
    radio: {
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#1D85E8',
        },
        '& .MuiTypography-body1': {
        }
    },
    cancleBtn: {
        width: '150px',
        height: '50px',
        color: '#FFFFFF',
        backgroundColor: '#FE8083',
        '&:hover': {
            backgroundColor: '#D76C6F',
            color: '#FFFFFF',
        },
        margin: '0px 0px 0px 30px'
    },
    radioDeactivate: {
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#1D85E8',
        },
        '& .MuiTypography-body1': {
            fontWeight: 600,
            margin: '0px 100px 0px 0px ',
            fontSize: '18px'
        }
    },
    radioPermanent: {
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#1D85E8',
        },
        '& .MuiTypography-body1': {
            margin: '0px 100px 0px 0px ',
            fontSize: '18px'
        }
    },
    textFieldDeactivate: {
        width: '83%',
        float: 'left',
        backgroundColor: '#ffff',
        margin: '10px 50px 10px 0px',
        '& .MuiInputBase-input': {
            fontSize: '18px'
        }
    },
    deactivateBtn: {
        width: '150px',
        height: '50px',
        color: '#FFFFFF',
        backgroundColor: '#FFC258',
        '&:hover': {
            backgroundColor: '#D3A308',
            color: '#FFFFFF',
        },
        margin: '0px 0px 40px 28px'
    }
}));
export default useStyle;