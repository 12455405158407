import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import { confirmUserInfo } from '../../service/service';
import { Link, useParams } from "react-router-dom";
import EmailApprovedIcon from "../../assets/icons/EmailApprovedIcon";

function EmailVerified() {
    const [values, setValues] = useState("");
    var { key } = useParams();

    useEffect(() => {
        confirmUserInfoApi();
    },)

    async function confirmUserInfoApi() {
        const res = await confirmUserInfo(key);
        const name = res.data.name;
        setValues(name);
    }

    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
            <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
            <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
                <h1 className="text-3xl md:text-4xl font-bold text-blue text-center">Hi {values}</h1>
                <h1 className="text-3xl md:text-4xl font-bold text-blue text-center">Welcome to Greyffiti!</h1>
                <EmailApprovedIcon className='m-2 max-h-80 w-28 md:w-40 object-contain' />
                <p className="md:text-lg font-medium text-center my-4">Your account has been verified!</p>
                <Link to="/login"><button type='submit' className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                    Next
                </button>
                </Link>
            </div>
        </div>
    );
}

export default EmailVerified;
