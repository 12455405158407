import React, { useState } from 'react'
import { deleteBranch } from '../../service/service';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import moment from 'moment';
import AddBranch from './AddBranch';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import EditIcon from '../../assets/icons/EditIcon';


function BranchList({ branchData, repoName, projectName, userName, fetchBranchList }) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const history = useHistory()

    const deleteBranchById = async (data) => {
        const res = await deleteBranch(data)
        if (res.success === true) {
            fetchBranchList()
        }
    }

    return (
        <>
            <tr className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                <td className='font-medium text-gray-800 cursor-pointer' onClick={() => history.push(`/${userName}/${projectName}/${repoName}/${branchData?.name}`)}>
                    <p>{branchData.name}</p>
                    <p className='text-gray-500 font-light text-xs mt-3'>Added {moment(branchData.created_at).fromNow()}</p>
                </td>
                <td className='max-w-sm'>
                    <p className='max-w-sm'>{branchData.description == 'null' ? 'none' : branchData.description}</p>
                </td>
                <td>{branchData.name == 'main' ? 'Default' : 'Created'}</td>
                <td>{branchData.user_name}</td>
                <td>
                    <div className='flex gap-4 relative max-w-fit'>
                        <EditIcon className='cursor-pointer' onClick={() => setAddModal(true)} />
                        {branchData.name !== 'main' && <DeleteIcon className='cursor-pointer' onClick={() => setDeleteModal(true)} />}
                    </div>
                    <p className='text-gray-500 font-light mt-3 text-xs'>Updated {moment(branchData.updated_at).fromNow()}</p>
                </td>
            </tr>
            {addModal &&
                <AddBranch getFreshBranchList={fetchBranchList} setAddModal={(value) => setAddModal(value)} mainBranchId={branchData.parent_branch_id} isEdit={true} branchData={branchData} />
            }
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete the "${branchData.name}" branch`} description={`This will also delete your all files in ${branchData.name} branch`} type="warn" okText='Yes' onCancel={() => setDeleteModal(false)} onOk={(e) => deleteBranchById(branchData.id)} />
        </>
    )
}

export default BranchList