import React from 'react'
import Loader from '../Loader/MiniLoader'

function MiniLoader() {
  return (
    <>
       <Loader /> 
    </>
  )
}

export default MiniLoader