import React from "react";
import useStyles from "./PrivacyStyle";
import Greyfitti from "../../../src/assets/images/Greyffiti-White-Logo.png";
import { Typography, Grid, List, ListItemIcon } from "@material-ui/core";

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { privacyLists, privacyquestions, privacyQuestionAns }  from "../../utils/constant"
import { Link } from "react-router-dom";

function Privacy() {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item xs={2}>
          <Link to='/'><img alt="" src={Greyfitti} className='w-12 m-4' /></Link>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className={classes.contentContainer}>
            <div className={classes.heading1}>
              <span className={classes.heading}>Privacy Policy</span>
            </div>
            <div className={classes.mContent}>
              {privacyquestions.map((row1) => (
              <><Typography variant="h6" className={classes.headingOne}>
                     {row1.title}
                </Typography><Typography variant="body1">
                  {row1.subTitle}
                  </Typography><Typography variant="body1" className={classes.headingOne}>
                    {" "}
                    {row1.subTitle2}
                  </Typography></>
              ))}
              {privacyLists.map((row) => (
                <List>
                  <ListItemIcon>
                    <FiberManualRecordIcon sx={{ fontSize: 10, marginTop: 1 }} />
                    <Typography className={classes.list}>{row.msg}</Typography>
                  </ListItemIcon>
                </List>
              ))}
              {privacyQuestionAns.map((row2)=>(
              <><Typography variant="h6" className={classes.headingOne}>
                  {row2.question}
                </Typography><Typography variant="body1">
                   {row2.answer}
                  </Typography></>

                ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Privacy;
