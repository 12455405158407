
import React, { useState, } from 'react'
import { createBranch, editBranch } from '../../service/service'
import { nameFieldValidator, descriptionFieldValidator } from '../../utils/validation';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { selectRepoBranches } from '../../slices/repoSlice';
import { TextField } from '@material-ui/core';


function AddBranch({ getFreshBranchList, setAddModal, mainBranchId, isEdit = false, branchData }) {
    const branches = useSelector(selectRepoBranches)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: isEdit ? branchData.name : "",
        description: isEdit ? branchData.description : "",
        from_branch: mainBranchId
    })

    function submit(e) {
        e.preventDefault();
        setErrors(validation(formData));
    }

    const validation = (formData) => {
        let errors = {};
        if (!formData.name && !formData.description) {
            errors.name = " ";
            errors.description = " ";
            errors.selectedBranch = " ";
            toast.error("Please enter all fields", { id: 'error' })
        }
        else {
            if (!formData.name) {
                toast.error("Name is required", { id: 'error' })
                errors.name = "Name is required";
            } else {
                if (formData.name) {
                    formData.name = formData.name.trim()
                    const isExist = branches.findIndex(item => item.name?.toLowerCase() == formData.name?.toLowerCase())
                    if (!isEdit && isExist > -1) {
                        toast.error("Branch Name already exist", { id: 'error' })
                        errors.name = "Branch Name already exist";
                    }
                    if (formData.name.length < 3) {
                        toast.error("Branch Name should have minimum 3 characters", { id: 'error' })
                        errors.name = "Branch Name should have minimum 3 characters";
                    }
                    if (formData.name.length < 3) {
                        toast.error("Branch Name should have minimum 3 characters", { id: 'error' })
                        errors.name = "Branch Name should have minimum 3 characters";
                    }
                    if (formData.name.length > 15) {
                        toast.error("Branch Name can have maximum 15 characters", { id: 'error' })
                        errors.name = "Branch Name can have maximum 15 characters";
                    }
                }
            }
            if (!formData.description) {
                errors.description = "Description is required";
                toast.error('Description is required', { id: 'error' })
            } else {
                if (formData.description) {
                    if (formData.description.length < 5) {
                        toast.error('Branch description should have minimum 5 characters', { id: 'error' })
                        errors.description = "Branch description should have minimum 5 characters";
                    }
                    if (formData.description.length > 120) {
                        toast.error('Branch description can have maximum 120 characters', { id: 'error' })
                        errors.description = "Branch description can have maximum 120 characters";
                    }
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            addBranch();
        }
        return errors;
    };
    async function addBranch() {
        const res = isEdit ? await editBranch({ ...formData, branch_id: branchData.id }) : await createBranch(formData);
        let AlertMessage = res.message;
        if (!res.success) {
            toast.error(AlertMessage, { id: 'error' })
        } else {
            toast.success(AlertMessage, { id: 'success' })
            setAddModal(false);
            getFreshBranchList();
            setFormData({ name: "", description: "" })
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        setAddModal(false);
    }


    const handle = e => {
        errors.name = " ";
        errors.description = " ";
        const { id } = e.target
        let newValue = e.target.value

        if (e.target.id.match("name")) {
            if (!nameFieldValidator(newValue)) {
                newValue = newValue.replace(/[^ A-Za-z0-9_-]/gi, "");
                toast.error("No special characters except \"-\" and \"_\" are allowed", { id: 'error' })
                errors.name = "No special characters except \"-\" and \"_\" are allowed";
            } else if (newValue.length === 15)
                errors.name = "Maximum of 15 characters"
        }
        if (e.target.id.match("description")) {
            if (!descriptionFieldValidator(newValue)) {
                newValue = newValue.replace(/[^ A-Za-z0-9]/gi, "");
                toast.error("No special characters are allowed", { id: 'error' })
                errors.description = "No special characters are allowed";
            } else if (newValue.length === 120) {
                toast.error("Maximum of 120 characters", { id: 'error' })
                errors.description = "Maximum of 120 characters"
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [id]: newValue
        }))
    };

    return (
        <>
            <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
                <div className='max-w-md bg-white p-5 w-full rounded-md'>
                    <form onSubmit={submit} className='p-5 w-full'>
                        <h1 className='pb-1 border-b text-xl font-semibold to-gray-900 mb-6'>{isEdit ? 'Edit' : 'Create a'} Branch</h1>
                        <div className='mb-6'>
                            <TextField size="small" label="Branch Name" disabled={isEdit && branchData?.name == 'main'} name="name" id="name" value={formData.name} onChange={handle} variant="outlined" className={`w-full`} />
                        </div>
                        <div className='mb-6'>
                            <TextField size="small" label="Branch Description" name="description" id="description" value={formData.description} onChange={handle} variant="outlined" className={`w-full`} />
                        </div>
                       
                        <div className='border focus:border-gray-900 rounded-md w-full px-4 py-2 mb-4 text-lg flex items-center gap-4 bg-gray-200'>
                            <input disabled type="text" name="" id="" value="From Main" className={`w-full bg-transparent outline-none border-none flex-grow text-gray-600 font-medium`} />
                        </div>

                        <div className='flex items-center gap-8 mb-4 mt-10'>
                            <button type='reset' className='bg-red py-1.5 px-6 rounded text-lg text-white' onClick={handleCancel} >
                                Cancel
                            </button>
                            <button type='submit' className='bg-blue py-1.5 px-6 rounded text-lg text-white' onClick={submit}>
                                {isEdit ? 'Update' : 'Create'} Branch
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddBranch
