import React from 'react'

function PasswordChangedIcon({ className }) {
    return (
        <svg className={className} width="391" height="393" viewBox="0 0 391 393" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="171.5" cy="93.5" r="93.5" fill="#1D85E8" />
            <circle cx="39" cy="249" r="39" fill="#FF8B66" />
            <path d="M128.563 99.584L74.7305 131.376C74.7305 131.376 76.5193 161.584 100.732 197.126L111.132 189.292C111.132 189.292 103.477 160.792 96.4881 144.084L137.923 124.917L128.563 99.584Z" fill="#FF8B66" />
            <path d="M245.378 117.542L240.053 132.625L234.561 148.042L206.314 139.5L188.258 204.25L187.343 207.583L123.901 184.875L128.976 131.375L107.718 141.5L87 115.542L112.71 102.125C129.725 93.25 148.903 89.4583 167.998 91.2083L176.069 91.9583L245.378 117.542Z" fill="#FFC55F" />
            <path d="M174.407 99.542C174.407 99.542 180.564 103.625 177.944 105.792C175.323 107.959 166.919 105 162.676 99.542" fill="#FF8B66" />
            <path d="M160.346 76.0415C160.346 76.0415 165.962 87.4581 162.467 99.5831L178.359 104.916C179.358 99.9165 179.69 94.8748 179.732 91.1248C183.268 91.2081 186.763 89.4998 188.801 86.4165C189.259 85.7498 189.633 84.9998 189.924 84.2081C190.59 82.4581 191.006 80.5831 191.256 78.7081C193.086 64.9581 185.224 49.4165 185.224 49.4165C185.224 49.4165 168.916 42.2081 160.346 62.9165C160.346 62.9165 152.317 57.6248 151.609 64.5415C150.403 75.9581 160.346 76.0415 160.346 76.0415Z" fill="#FF8B66" />
            <path d="M179.483 96.5417C179.483 96.5417 171.08 94.25 169.582 86.25C169.582 86.25 174.574 91.4167 179.774 91.0833C179.774 91.0833 179.816 93.4167 179.525 96.625" fill="#DB6B71" />
            <path d="M162.967 84.4997C148.947 80.9164 147.449 50.1664 147.449 50.1664C147.449 41.9997 152.109 41.7914 166.836 39.6664C181.563 37.5414 195.541 38.9997 194.709 46.1247C194.126 51.083 181.729 56.1247 178.11 56.458C177.111 56.5414 178.734 54.958 179.108 53.9997C179.691 52.3747 179.441 50.708 179.441 50.708C174.407 59.8747 168.208 60.208 168.708 59.458C171.537 55.0414 170.081 51.3747 169.207 52.9164C165.088 60.2497 165.005 70.9164 165.005 70.9164L162.467 69.208L160.346 62.9164C159.472 62.708 157.35 60.4164 153.606 62.9164C149.821 65.4164 153.398 75.7497 160.346 75.9997C161.594 78.4997 162.426 81.1664 162.883 83.9164L162.967 84.4997Z" fill="#0E3944" />
            <path d="M160.344 68.4168C160.344 68.4168 158.971 64.3335 155.602 66.2501L160.344 68.4168Z" fill="#FF8B66" />
            <path d="M160.344 68.4168C160.344 68.4168 158.971 64.3335 155.602 66.2501" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M159.265 67.375C159.265 67.375 157.393 70.4583 160.346 73.3333L159.265 67.375Z" fill="#FF8B66" />
            <path d="M159.265 67.375C159.265 67.375 157.393 70.4583 160.346 73.3333" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M178.151 67.4583C178.151 68.625 178.692 69.5833 179.357 69.5417C180.023 69.5417 180.522 68.5833 180.522 67.4167C180.522 66.25 179.981 65.2917 179.316 65.3333C178.65 65.3333 178.109 66.25 178.151 67.4583Z" fill="#1A4850" />
            <path d="M172.912 77.9581C172.745 78.9164 173.577 79.8331 174.784 79.9997C175.949 80.2081 177.03 79.5831 177.197 78.6247C177.363 77.6664 176.531 76.7497 175.325 76.5831C174.16 76.3747 173.078 76.9997 172.912 77.9581Z" fill="#DB614D" />
            <path d="M187.469 65.6253C187.469 66.792 188.01 67.7503 188.675 67.7087C189.341 67.7087 189.84 66.7503 189.84 65.5837C189.84 64.417 189.299 63.4587 188.634 63.5003C187.968 63.5003 187.469 64.4587 187.469 65.6253Z" fill="#1A4850" />
            <path d="M184.973 65.75L190.006 74.6667L188.093 76.5417" fill="#FF8B66" />
            <path d="M184.973 65.75L190.006 74.6667L188.093 76.5417" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M182.434 63.5007L175.029 61.084L173.781 63.5007" fill="#FF8B66" />
            <path d="M182.434 63.5007L175.029 61.084L173.781 63.5007" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M185.848 62.2917L188.677 58.5" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M193.835 31C193.835 31 199.534 39.4167 194.376 47.3333C189.217 55.25 162.301 40.2917 162.301 40.2917C162.301 40.2917 193.128 35.9583 193.835 31Z" fill="#0E3944" />
            <path d="M188.675 80.042C188.675 80.042 183.974 82.1253 182.477 81.8337L188.675 80.042Z" fill="#FF8B66" />
            <path d="M188.675 80.042C188.675 80.042 183.974 82.1253 182.477 81.8337" stroke="#0E3944" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M186.847 204.375L157.268 314.666L91.3292 375.625L62 372.166L123.986 294.041L123.903 184.833L186.847 204.375Z" fill="#1F57E5" />
            <path d="M123.903 184.833C123.903 184.833 90.6631 240.416 203.154 270.083L217.839 375.583L250.954 372.166L248.583 263.083L186.846 204.375L123.903 184.833Z" fill="#1D85E8" />
            <path d="M126.606 213.958C126.606 213.958 100.688 206.25 93.6992 201.666L95.0305 196.291C103.351 195.458 129.893 200.666 129.893 200.666L126.606 213.958Z" fill="#2DC8A8" />
            <path d="M212.595 240.125C184.14 233.125 152.315 223.708 129.434 215.542L133.095 200.625C157.099 204 189.673 210.458 218.128 217.458C270.422 230.333 287.561 246.583 287.561 246.583C287.561 246.583 264.889 253 212.595 240.125Z" fill="#2DC8A8" />
            <path d="M130.599 206.291C135.591 207.374 140.542 208.583 145.492 209.708C150.485 210.833 155.435 212.041 160.386 213.249C170.287 215.666 180.23 218.041 190.089 220.583L204.9 224.374C209.85 225.666 214.801 226.916 219.71 228.249C229.569 230.916 239.387 233.708 249.039 237.083C238.888 235.583 228.904 233.499 218.961 231.291C213.969 230.166 209.018 228.999 204.068 227.833L189.174 224.291C179.231 221.916 169.372 219.458 159.471 216.999C154.52 215.791 149.569 214.541 144.619 213.249C139.668 211.958 134.759 210.708 129.809 209.374L130.599 206.291Z" fill="white" />
            <path d="M128.063 221.666L119.41 220.666L126.607 191.416L137.008 195.541L128.063 221.666Z" fill="#2DC8A8" />
            <path d="M89.7883 209.5C86.6265 208.708 85.2537 203.166 86.7513 197.125C88.249 191.041 92.0348 186.791 95.1965 187.583C98.3582 188.375 99.7311 193.916 98.2334 199.958C96.7357 206 92.95 210.291 89.7883 209.5Z" fill="#2DC8A8" />
            <path d="M111.131 189.25L125.899 192.667L120.5 215C120.5 215 99.7319 212.542 99.7735 204.125L100.73 197.083L111.131 189.25Z" fill="#FF8B66" />
            <path d="M245.381 117.542L335.906 141.5L360.118 139.459L366.484 157.834L353.753 161.584L346.099 157.834L347.264 162.875L334.617 154.042L237.602 139.459L245.381 117.542Z" fill="#FF8B66" />
            <path d="M354.033 85L293 113.122C293 113.122 308.195 220.564 339.451 235.862C370.707 251.159 391 107.199 391 107.199L354.033 85Z" fill="#2DC8A8" />
            <path d="M345.668 231.076C344.178 231.076 342.637 230.715 341.147 229.995C329.072 224.128 318.128 203.389 308.673 168.292C301.634 142.252 298.089 117.602 298.037 117.344C297.986 117.139 298.14 116.881 298.346 116.778L354.609 91.0474C354.763 90.9959 354.917 90.9959 355.071 91.0474L389.137 111.375C389.291 111.478 389.394 111.684 389.394 111.89C389.343 112.147 385.592 138.444 378.964 165.925C370.075 202.926 360.466 224.386 350.55 229.686C348.905 230.664 347.312 231.076 345.668 231.076ZM299.116 117.602C300.093 124.292 314.274 215.791 341.609 229.069C344.486 230.458 347.261 230.407 350.087 228.863C372.592 216.872 387.39 118.734 388.367 112.147L354.814 92.1281L299.116 117.602Z" fill="white" />
            <path d="M331.383 153.008L363.856 150.949L362.058 169.321C361.287 177.195 354.505 183.113 346.592 182.804C338.936 182.495 332.719 176.423 332.308 168.755L331.383 153.008Z" fill="white" />
            <path d="M347.516 163.403C347.413 163.403 347.31 163.403 347.207 163.403C342.223 163.249 338.267 159.029 338.473 153.986L338.986 138.907C339.14 133.915 343.405 130.004 348.389 130.159C350.804 130.262 353.065 131.291 354.709 133.041C356.353 134.842 357.227 137.106 357.124 139.576L356.61 154.655C356.508 157.073 355.48 159.338 353.733 160.984C352.037 162.58 349.828 163.403 347.516 163.403ZM348.081 132.783C344.587 132.783 341.71 135.562 341.607 139.062L341.093 154.14C340.99 157.742 343.765 160.727 347.362 160.882C349.109 160.933 350.753 160.315 352.037 159.132C353.322 157.948 354.041 156.353 354.093 154.603L354.606 139.525C354.658 137.775 354.041 136.128 352.859 134.842C351.678 133.555 350.085 132.835 348.338 132.783C348.235 132.783 348.132 132.783 348.081 132.783Z" fill="white" />
            <path d="M348 172C349.657 172 351 170.657 351 169C351 167.343 349.657 166 348 166C346.343 166 345 167.343 345 169C345 170.657 346.343 172 348 172Z" fill="#2DC8A8" />
            <path d="M347.833 177C347.333 177 347 176.669 347 176.339L347.333 169.661C347.333 169.264 347.75 169 348.167 169C348.667 169 349 169.331 349 169.661L348.667 176.339C348.667 176.736 348.333 177 347.833 177Z" fill="#2DC8A8" />
            <path d="M243.383 372.792L277.455 393H224.371V373.834L243.383 372.792Z" fill="#0E3944" />
            <path d="M84.9637 372.792L119.035 393H65.9102V370.417L84.9637 372.792Z" fill="#0E3944" />
            <path d="M128.98 131.375C128.98 131.375 138.757 126.417 143.624 128.75C148.491 131.084 132.433 164.917 137.009 174.5C141.253 183.375 154.441 193.417 186.848 204.375L123.863 184.834L128.98 131.375Z" fill="#F2AC46" />
            <path d="M240.058 132.625L234.566 148.042L206.319 139.5L188.263 204.25L186.891 204.375L202.491 132.625C202.491 132.625 229.782 134.458 240.058 132.625Z" fill="#F2AC46" />
        </svg>

    )
}

export default PasswordChangedIcon