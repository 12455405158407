import moment from 'moment'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom'
import { addEvent, addEventAnnouncement, uploadFile as UPLOAD } from '../../service/service'


function EventCard({ owner, user, event }) {
    const history = useHistory();
    const [openPopup, setOpenPopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [announcement, setAnnouncement] = useState({
        title: '',
        description: ''
    });

    const [data, setData] = useState({
        event_link: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        submission_start_date: '',
        submission_start_time: '',
        submission_end_date: '',
        submission_end_time: '',
        banner_url: '',
        thumbnail: 'https://res.cloudinary.com/ankit628792/image/upload/v1663929270/Greyffiti/byk8mqr9brdmwf84enrj.svg',
        guidelines: '',
        closed: false,
        tags: [],
        location: '',
        maplink: '',
        withGreyffiti: false,
        freeEvent: false
    })

    const handleChange = (e) => {
        e.preventDefault();
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleAnnouncement = (e) => {
        e.preventDefault();
        setAnnouncement({ ...announcement, [e.target.name]: e.target.value })
    }

    const publishAnnouncement = async (e) => {
        e.preventDefault();
        let data = { event_id: event?.id, organization_id: event?.organization_id, title: announcement.title, description: announcement.description }
        let res = await addEventAnnouncement(data);
        setAnnouncement({
            title: '',
            description: ''
        })
        if (res.success) {
            setOpenPopup(false);
            toast.success("new Announcement added")
        }
        else {
            toast.error("Unable to add announcement")
        }
    }

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("issue_file", file);
        return new Promise(async (resolve, reject) => {
            const result = await UPLOAD(formData)
            if (!result) {
                reject()
                return
            }
            resolve({
                data: {
                    url: result.data,
                    width: 300,
                    height: 220,
                    alignment: "left", // or "center", "right"
                    type: "image" // or "video"
                }
            })
        })
    }
    const handleFile = async (e) => {
        e.preventDefault()
        let file = e.target.files[0];
        let imgRes = {};
        if (file.size > 1048576) {
            toast.error("File size is large to upload")
        } else if (e.target.name != "guidelines") {
            if (file.type.includes('image')) {
                imgRes = await uploadFile(file);
            }
            else {
                toast.error("Invalid Image format")
            }
        }
        else {
            imgRes = await uploadFile(file);
        }
        if (imgRes?.data?.url) {
            setData({ ...data, [e.target.name]: imgRes?.data?.url })
        }
    }

    return (
        <>
            <div className='w-full max-w-xs bg-white border shadow-lg rounded-xl overflow-hidden cursor-pointer'>
                <div className='w-full h-44 relative'>
                    {owner && <div className='p-2 bg-white rounded-full absolute top-2 left-2 z-30' onClick={() => setIsEdit(true)}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6917 8.18141L13.7958 4.33141L15.0792 3.04808C15.4306 2.69669 15.8623 2.521 16.3744 2.521C16.8859 2.521 17.3174 2.69669 17.6687 3.04808L18.9521 4.33141C19.3035 4.6828 19.4868 5.10691 19.5021 5.60375C19.5174 6.09997 19.3493 6.52377 18.9979 6.87516L17.6917 8.18141ZM16.3625 9.5335L6.64583 19.2502H2.75V15.3543L12.4667 5.63766L16.3625 9.5335Z" fill="#5D5C5C" />
                        </svg>
                    </div>}
                    <img className='w-full h-full object-cover' src={event.thumbnail} alt="" />
                    <div className='w-full h-44 bg-gradient-to-t from-black to-transparent grid place-items-center absolute inset-0' onClick={() => history.push(`/events/${event?.event_id || event?.id}`)}>
                        <span className='text-gray-800 font-medium py-1 px-4 rounded-3xl bg-white absolute top-2 right-2 capitalize'>{event.type}</span>
                        <p className='text-white font-medium text-lg absolute bottom-2 left-4 capitalize'>{event.title}</p>
                    </div>
                </div>
                {event.event_start && <div className='px-4 py-2 bg-white'>
                    <p className='text-base'>{moment(event.event_start).format("DD MMM, ddd")} | <span className='capitalize'>{event.mode}</span></p>
                    <p className='text-base'>{moment(event.event_start).format("HH:mm A")} to {moment(event.event_end).format("HH:mm A")}</p>
                    <div className='flex items-center justify-between'>
                        <span className='text-sm mt-1 text-red'>{!event?.freeevent ? 'Paid' : 'Free'}</span>
                        {owner && <div onClick={() => setOpenPopup(true)} className='bg-gray-100 rounded-full p-1 max-w-max cursor-pointer flex items-center gap-1 group w-8 h-8 hover:w-full hover:bg-gray-500 overflow-hidden transition-all duration-150 ease-in-out'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-600 flex-shrink-0 group-hover:text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                            </svg>
                            <span className='text-white text-sm pr-2 line-clamp-1'>Add Announcement</span>
                        </div>}
                    </div>
                </div>}
            </div>

            {
                openPopup && <>
                    <div className='bg-black bg-opacity-60 fixed inset-0 overflow-hidden w-full min-h-screen cursor-pointer' style={{ zIndex: 5001 }} onClick={() => setOpenPopup(false)}>
                    </div>
                    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-start justify-center gap-8' style={{ zIndex: 5002 }}>

                        <div className='w-full max-w-xs bg-white border shadow-lg rounded-xl overflow-hidden cursor-pointer flex-shrink-0'>
                            <div className='w-full h-44 relative'>
                                <img className='w-full h-full object-cover' src={event.thumbnail} alt="" />
                                <div className='w-full h-44 bg-gradient-to-t from-black to-transparent grid place-items-center absolute inset-0' onClick={() => history.push(`/events/${event?.event_id || event?.id}`)}>
                                    <span className='text-gray-800 font-medium py-1 px-4 rounded-3xl bg-white absolute top-2 right-2 capitalize'>{event.type}</span>
                                    <p className='text-white font-medium text-lg absolute bottom-2 left-4 capitalize'>{event.title}</p>
                                </div>
                            </div>
                            {event.event_start && <div className='px-4 py-2 bg-white'>
                                <p className='text-base'>{moment(event.event_start).format("DD MMM, ddd")} | <span className='capitalize'>{event.mode}</span></p>
                                <p className='text-base'>{moment(event.event_start).format("HH:mm A")} to {moment(event.event_end).format("HH:mm A")}</p>
                                <div className='flex items-center justify-between'>
                                    <span className='text-sm mt-1 text-red'>{!event?.freeevent ? 'Paid' : 'Free'}</span>

                                </div>
                            </div>}
                        </div>

                        <div className=' bg-white rounded-xl p-5 w-full max-w-xl flex-shrink-0'>
                            <div className='rounded-full p-1 max-w-max cursor-pointer flex items-center gap-1 h-8 w-full bg-gray-500 overflow-hidden transition-all duration-150 ease-in-out'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-50 flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                                </svg>
                                <span className='text-white text-sm pr-2 line-clamp-1'>Add Announcement</span>
                            </div>
                            <input type="text" placeholder="Title: " name="title" id="title" value={announcement.title} onChange={handleAnnouncement} className="w-full bg-white rounded-lg border block my-4 p-2 focus:border-gray-300" />
                            <textarea name='description' id='description' placeholder='Description: ' value={announcement.description} onChange={handleAnnouncement} className="w-full h-20 bg-white rounded-lg border block my-4 p-2 resize-none focus:border-gray-300"></textarea>
                            <button className='text-white px-4 py-1.5 rounded-full bg-blue font-medium' onClick={publishAnnouncement}>Publish</button>
                        </div>
                    </div>
                </>
            }

            {
                isEdit && <>
                    <div className='bg-black bg-opacity-60 fixed inset-0 overflow-hidden w-full min-h-screen cursor-pointer' style={{ zIndex: 5001 }} onClick={() => setIsEdit(false)}>
                    </div>

                    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F6FAFD] h-[85vh] w-full max-w-5xl m-5 rounded-lg p-5 lg:p-10 overflow-y-auto' style={{ zIndex: 5002 }}>
                        <div className='mb-4'>
                            <label htmlFor="event_link" className='text-gray-400 font-medium text-xl p-2 block'>Event Link</label>
                            <input type="url" name='event_link' onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Paste event link' />
                        </div>

                        {event.mode == "offline" && <>
                            <div className='mb-4'>
                                <label htmlFor="location" className='text-gray-400 font-medium text-xl p-2 block'>Add location</label>
                                <input type="text" name='location' value={data.location} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event address' />
                                <input type="text" name='maplink' value={data.maplink} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block mt-4' placeholder='Paste Google Maps Link' />
                            </div>
                        </>}

                        <div className='flex items-center justify-start flex-wrap gap-4'>
                            <div className='mb-4 flex-grow'>
                                <label htmlFor="start_date" className='text-gray-400 font-medium text-xl p-2 block'>Start Date</label>
                                <input type="date" name='start_date' value={data.start_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                            </div>
                            <div className='mb-4 flex-grow'>
                                <label htmlFor="end_date" className='text-gray-400 font-medium text-xl p-2 block'>End Date</label>
                                <input type="date" name='end_date' value={data.end_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                            </div>
                        </div>
                        <div className='flex items-center justify-start flex-wrap gap-4'>
                            <div className='mb-4 flex-grow'>
                                <label htmlFor="start_time" className='text-gray-400 font-medium text-xl p-2 block'>Start Time</label>
                                <input type="time" name='start_time' value={data.start_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                            </div>
                            <div className='mb-4 flex-grow'>
                                <label htmlFor="end_time" className='text-gray-400 font-medium text-xl p-2 block'>End Time</label>
                                <input type="time" name='end_time' value={data.end_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                            </div>
                        </div>

                        {
                            ((event.withgreyffiti || event.withgreyffiti == 'true') && ['competition', 'hackathon'].includes(event.type))
                                ?
                                <>
                                    <div className='flex-grow'>
                                        <label htmlFor="" className='text-gray-400 font-medium text-2xl p-2 block'>Submission <span className='font-bold'>Open</span>s From</label>
                                    </div>
                                    <div className='flex items-center justify-start flex-wrap gap-4'>
                                        <div className='mb-4 flex-grow'>
                                            <label htmlFor="submission_start_date" className='text-gray-400 font-medium text-xl p-2 block'>Date</label>
                                            <input type="date" name='submission_start_date' value={data.submission_start_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                        </div>
                                        <div className='mb-4 flex-grow'>
                                            <label htmlFor="submission_start_time" className='text-gray-400 font-medium text-xl p-2 block'>Time</label>
                                            <input type="time" name='submission_start_time' value={data.submission_start_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                        </div>
                                    </div>
                                    <div className='flex-grow'>
                                        <label className='text-gray-400 font-medium text-2xl p-2 block'>Submission <span className='font-bold'>End</span>s On</label>
                                    </div>
                                    <div className='flex items-center justify-start flex-wrap gap-4 mb-4'>
                                        <div className='mb-4 flex-grow'>
                                            <label htmlFor="submission_end_date" className='text-gray-400 font-medium text-xl p-2 block'>Date</label>
                                            <input type="date" name='submission_end_date' value={data.submission_end_date} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                        </div>
                                        <div className='mb-4 flex-grow'>
                                            <label htmlFor="submission_end_time" className='text-gray-400 font-medium text-xl p-2 block'>Time</label>
                                            <input type="time" name='submission_end_time' value={data.submission_end_time} onChange={handleChange} className='outline-none rounded border-2 border-gray-200 focus:border-gray-400 bg-transparent p-2 w-full block' placeholder='Event Name' />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>}

                        <div className='p-4 bg-white w-full my-4'>
                            <div className='flex items-center w-full justify-between'>
                                <label htmlFor='guidelines' className='text-gray-400 cursor-pointer'>{data.guidelines || <>Upload a detailed Guideline Document <span className='font-bold'>(PDF)</span> for Submissions under 10MB</>}</label>
                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 22.2953C14.4688 22.2953 14.0238 22.1104 13.665 21.7406C13.305 21.3721 13.125 20.9149 13.125 20.3692V6.59711L9.60938 10.2087C9.23438 10.5939 8.79688 10.7865 8.29688 10.7865C7.79688 10.7865 7.34375 10.5778 6.9375 10.1605C6.5625 9.77528 6.38313 9.3175 6.39937 8.78716C6.41438 8.25811 6.59375 7.81701 6.9375 7.46388L13.6875 0.529694C13.875 0.337078 14.0781 0.200321 14.2969 0.119422C14.5156 0.039807 14.75 0 15 0C15.25 0 15.4844 0.039807 15.7031 0.119422C15.9219 0.200321 16.125 0.337078 16.3125 0.529694L23.0625 7.46388C23.4375 7.84912 23.6169 8.30626 23.6006 8.83531C23.5856 9.36565 23.4062 9.80738 23.0625 10.1605C22.6875 10.5457 22.2425 10.7461 21.7275 10.7615C21.2113 10.7782 20.7656 10.5939 20.3906 10.2087L16.875 6.59711V20.3692C16.875 20.9149 16.6956 21.3721 16.3369 21.7406C15.9769 22.1104 15.5312 22.2953 15 22.2953ZM3.75 30C2.71875 30 1.83625 29.6231 1.1025 28.8693C0.3675 28.1143 0 27.2071 0 26.1477V22.2953C0 21.7496 0.179375 21.2918 0.538125 20.922C0.898125 20.5535 1.34375 20.3692 1.875 20.3692C2.40625 20.3692 2.85187 20.5535 3.21187 20.922C3.57062 21.2918 3.75 21.7496 3.75 22.2953V26.1477H26.25V22.2953C26.25 21.7496 26.43 21.2918 26.79 20.922C27.1488 20.5535 27.5938 20.3692 28.125 20.3692C28.6562 20.3692 29.1012 20.5535 29.46 20.922C29.82 21.2918 30 21.7496 30 22.2953V26.1477C30 27.2071 29.6331 28.1143 28.8994 28.8693C28.1644 29.6231 27.2812 30 26.25 30H3.75Z" fill="#CFD8DC" />
                                </svg>
                                <input type="file" value="" name="guidelines" id="guidelines" className="hidden" onChange={handleFile} />
                            </div>
                        </div>
                        <label htmlFor='banner_url' className='flex-grow w-full max-w-md'>
                            <h1 className='text-gray-400 font-medium text-xl p-2 block'>Event Banner</h1>
                            <div className='h-52 w-full overflow-hidden max-w-md bg-white rounded-lg grid place-items-center'>
                                {
                                    event.banner_url
                                        ?
                                        <img src={event.banner_url} alt="" />
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-gray-300">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                }
                            </div>
                            <input type="file" value="" name='banner_url' id='banner_url' className='hidden' accept='image/*' alt='' onChange={handleFile} />
                        </label>

                        <div className='flex w-full justify-end mt-8'>
                            <button className='px-6 py-2 rounded-lg bg-blue text-white font-medium text-lg'>Save Changes</button>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default EventCard