import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { inviteMembers } from '../../service/service';
import Loader from '../ReusableComponents/Loader';
import Navbar from '../ReusableComponents/Navbar';
import userimg from "../../assets/images/Default_User_Image.png";
import { CloseOutlined } from '@material-ui/icons';
import AddContributor from '../ReusableComponents/AddContributor';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import InviteViaEmail from '../ReusableComponents/InviteViaEmail';


function Members() {
    const [members, setMembers] = useState([])
    const [openPopup, setOpenPopUp] = useState(false);
    const user = useSelector(selectUserDetails)
    const [emailInvite, setEmailInvite] = useState(false);
    const [canInvite, setCanInvite] = useState(1000);
    const [loading, setLoading] = useState(true)

    const membersList = async () => {
        let all = await inviteMembers('project', 1);
        setMembers(all.data)
        setLoading(false)
    }

    useEffect(() => membersList(), [])

    const MemberCard = ({ member }) => {

        return (
            <>
                <div className='bg-white p-4 max-w-xs rounded-lg shadow-sm hover:shadow-lg hover:bg-blue-50 border'>
                    <div className='flex items-start gap-4'>
                        <img className='w-12 h-12 rounded-full border-4 bg-white' src={member.profile_pic || userimg} alt="" />
                        <div>
                            <h1 className='font-medium'>{member?.username}</h1>
                            <h1 className='text-sm text-gray-500'>{member?.name}</h1>
                            <h1 className='text-sm'>{member?.email}</h1>
                        </div>
                    </div>
                    <div className='p-4 flex items-center justify-center'>
                        <button className='p-1 px-4 font-medium shadow-md bg-red text-white rounded-lg' onClick={() => { }}>
                            Remove
                        </button>
                    </div>
                    <p className='text-xs italic text-gray-500 text-center'>added {moment(member?.created_at).subtract(Math.round(Math.random() * 10), 'd').fromNow()}</p>
                </div>
            </>
        )
    }

    return (
        <>

            <div className='min-h-[300px] flex items-start gap-10 flex-wrap w-full'>
                <div className='p-10 w-40 h-40 grid place-items-center bg-white shadow-lg rounded-lg cursor-pointer' onClick={() => setOpenPopUp(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
                {
                    members?.length ?
                        members?.map((member, i) => <MemberCard key={i} member={member} />)
                        :
                        <h1 className='text-3xl font-semibold text-gray-600 text-center mx-auto mt-10'>No Member available</h1>
                }
            </div>

            {emailInvite && <InviteViaEmail setEmailInvite={setEmailInvite} setOpenPopUp={setOpenPopUp} type='repo' id={user?.id} />}

            {Boolean(openPopup) && <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1001 }}>
                <div className='max-w-xl bg-white p-5 w-full rounded-md h-[80vh] flex flex-col overflow-y-auto'>
                    <div className='flex items-center gap-5 border-b mb-4'>
                        <button className={`text-lg font-semibold text-blue hover:text-blue-600 border py-1 px-2 select-none bg-white border-b-0 ${openPopup ? '-mb-0.5' : ''}`} onClick={() => setOpenPopUp(true)}>Add Member</button>
                        <button className='ml-auto' onClick={() => setOpenPopUp(false)}><CloseOutlined fontSize='large' className='cursor-pointer text-gray-800 hover:text-black' /></button>
                    </div>
                    <>
                        {
                            openPopup ?
                                <AddContributor setOpenPopUp={setOpenPopUp} setEmailInvite={setEmailInvite} type='repo' canInvite={canInvite} setCanInvite={setCanInvite} plan={user?.plan} id={user?.id} />
                                :
                                <></>
                        }
                    </>
                </div>
            </div>}
        </>
    )
}

export default Members