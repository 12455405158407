import React, { useRef } from 'react'
import {
  TextField,
  IconButton,
} from '@material-ui/core'
import { useState } from 'react';
import { useClickOutside } from '../../utils/helper';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import { Info } from '@material-ui/icons';

export default function AddAssigneeForm({ assignee, handlePopupClose, assigneeIds, setAssigneeIds }) {
  const cancelRef = useRef()
  const [contributorsList, setContributorsList] = useState(assignee)
  const [copyRes, setCopyRes] = useState(assignee)
  useClickOutside(() => handlePopupClose('cancel'), cancelRef)

  const searchItems = e => {
    let valueText = e.target.value
    if (valueText !== '') {
      let searchData = copyRes
        .filter(s => {
          return s.contributor_name.toLowerCase().includes(valueText.toLowerCase());
        })
        .sort((a, b) => {
          if (a.contributor_name.toLowerCase().indexOf(valueText.toLowerCase()) > b.contributor_name.toLowerCase().indexOf(valueText.toLowerCase())) {
            return 1;
          } else if (a.contributor_name.toLowerCase().indexOf(valueText.toLowerCase()) < b.contributor_name.toLowerCase().indexOf(valueText.toLowerCase())) {
            return -1;
          } else {
            if (a.contributor_name > b.contributor_name)
              return 1;
            else
              return -1;
          }
        });
      if (searchData.length != 0) {
        setContributorsList(searchData)
      } else {
        setContributorsList([])
      }
    } else {
      setContributorsList(copyRes)
    }
  }

  const handleSelect = (contributer_id) => {
    let idx = assigneeIds.findIndex(item => item == contributer_id)
    if (idx > -1) {
      let newData = [...assigneeIds];
      newData.splice(idx, 1);
      setAssigneeIds([...newData])
    }
    else {
      setAssigneeIds([...assigneeIds, contributer_id])
    }
  }

  return (
    <>
      <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
        <div className='max-w-xl bg-white p-5 w-full rounded-md flex flex-col h-[80vh] overflow-y-auto' ref={cancelRef}>
          <div className='pb-1 border-b mb-6 flex items-center gap-2 relative'>
            <h1 className='text-xl font-semibold text-gray-900'>Assign to Contributors</h1>
            <div className='group'>
              <Info fontSize="small" className="cursor-pointer text-gray-600" />
              <div className='absolute -bottom-6 right-4 bg-white p-2 px-3 rounded-lg shadow-lg hidden group-hover:inline-block'>
                <p className='text-xs text-gray-800'>Repository owner will be added as an assignee by default </p>
              </div>
            </div>
          </div>

          <TextField
            placeholder='Search by name or email'
            variant='outlined'
            className='w-full'
            size='small'
            onChange={e => searchItems(e)}
            name='searchText'
            InputProps={{
              startAdornment: (
                <IconButton>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="search_24px">
                      <path id="icon/action/search_24px" fill-rule="evenodd" clip-rule="evenodd" d="M14.9649 14.255H15.7549L20.7449 19.255L19.2549 20.745L14.2549 15.755V14.965L13.9849 14.685C12.8449 15.665 11.3649 16.255 9.75488 16.255C6.16488 16.255 3.25488 13.345 3.25488 9.755C3.25488 6.165 6.16488 3.255 9.75488 3.255C13.3449 3.255 16.2549 6.165 16.2549 9.755C16.2549 11.365 15.6649 12.845 14.6849 13.985L14.9649 14.255ZM5.25488 9.755C5.25488 12.245 7.26488 14.255 9.75488 14.255C12.2449 14.255 14.2549 12.245 14.2549 9.755C14.2549 7.26501 12.2449 5.255 9.75488 5.255C7.26488 5.255 5.25488 7.26501 5.25488 9.755Z" fill="#7F92A4" />
                    </g>
                  </svg>
                </IconButton>
              )
            }}
          />
          <div className='flex-grow'>
            {contributorsList.map(i => <div key={i.email} className='flex items-center w-full gap-4 my-4'>
              {
                i.profile_pic ?
                  <img
                    className='w-16 h-16 rounded-full object-cover'
                    alt='userimg'
                    src={i.profile_pic}
                  />
                  :
                  <DefaultUserIcon className='w-16 h-16 rounded-full object-cover' />
              }
              <div className='flex-grow'>
                <h1 className='text-lg font-semibold text-gray-800'>{i.contributor_name}</h1>
                <h1 className='text-gray-500'>{i.email}</h1>
              </div>
              {
                assigneeIds.includes(i.contributer_id) ?
                  <button className='bg-red rounded-lg w-32 py-2 text-lg text-white capitalize' onClick={() => handleSelect(i.contributer_id)}>
                    Remove
                  </button>
                  : <button className='bg-blue rounded-lg w-32 py-2 text-lg text-white capitalize' onClick={() => handleSelect(i.contributer_id)}>
                    Add
                  </button>
              }
            </div>)}
            {!contributorsList?.length && <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Contributor available</h1>}
          </div>
          <div className='flex items-center justify-between w-full px-4 bg-white sticky bottom-0'>
            <button className='bg-red rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => handlePopupClose('cancel')}>
              Cancel
            </button>
            <button className='bg-blue rounded-lg px-8 py-2 text-lg text-white capitalize' onClick={() => handlePopupClose('submit')}>
              Save
            </button>

          </div>
        </div>
      </div>
    </>
  )
}


