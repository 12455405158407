import { SearchOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tag from './Tag';

const TagPopup = ({ tagList, tagIds, onTagClick, username, project, showCreate, onCancel, onSave }) => {
    const history = useHistory()
    const [tagSearch, setTagSearch] = useState('')

    return (
        <> <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 ' style={{ zIndex: 10000 }} onClick={onCancel}>
        </div>
            <div className='flex flex-col p-5 md:px-10 bg-white w-full max-w-4xl rounded-lg fixed transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-auto' style={{ zIndex: 10001 }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 absolute top-4 right-4 cursor-pointer" onClick={onCancel}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

                <h1 className='text-3xl font-semibold'>Add Tags</h1>
                <p className='my-2'>Choose tags from tag list. You’re able to add upto 10 tags.</p>

                <div className='flex items-center gap-4 w-full my-4'>
                    <div className=' p-2 border rounded-lg bg-white flex w-full'>
                        <SearchOutlined />
                        <input type="text" placeholder='Search tags here...' onChange={(e) => setTagSearch(e.target.value)} className=" flex-grow outline-none border-none px-2" />
                    </div>

                    {showCreate && <div className='py-2 px-4 flex items-center gap-2 rounded-lg border min-w-max cursor-pointer' onClick={() => history.push(`/${username}/${project}/tags`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>
                        <span className='text-lg'>Create Tags</span>
                    </div>}
                </div>

                <div className='p-4 flex flex-col gap-6 flex-grow'>
                    {tagList?.map(tag => tag.name.toLowerCase().includes(tagSearch.toLowerCase()) && <TagList key={tag.id} tag={tag} onClick={onTagClick} selected={tagIds.includes(tag.id)} />)}
                </div>

                <div className='pb-4 pt-8 flex items-center justify-end px-4'>
                    <button className='py-1.5 px-10 rounded-md text-blue text-lg font-medium' onClick={onCancel}>
                        Cancel
                    </button>
                    <button className='py-1.5 px-10 rounded-md bg-blue text-white text-lg font-medium' onClick={onSave}>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

const TagList = ({ tag, onClick, selected }) => {

    return (
        <>
            <div className='flex items-center gap-6 2xl:gap-8'>
                <div className='min-w-[100px]'>
                    <Tag text={tag.name} color={tag.color} />
                </div>
                <div className='w-8'>
                    {selected && <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.66699 10L10.167 18.5L24.3337 1.5" stroke="#2DC8A8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>}
                </div>
                <div className='flex-grow maxw'>
                    <p className='text-center text-gray-500'>{tag.description || '-'}</p>
                </div>
                <div>
                    {selected ?
                        <button className={`py-1.5 px-4 w-28 rounded-3xl text-white bg-red font-medium`} onClick={() => onClick(tag.id)}>
                            Remove
                        </button>
                        :
                        <button className={`py-1.5 px-4 w-28 rounded-3xl text-white bg-navy font-medium`} onClick={() => onClick(tag.id)}>
                            Add
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export default TagPopup