import React, { useEffect, useState } from 'react'
import MoneyPlant from '../../assets/images/MoneyPlant.svg'
import { handleFreelanceData, upgradeToPro } from '../../service/service';
import { toast } from 'react-hot-toast';
import usePayment from '../../utils/usePayment';


function Pro({ pro, setPro }) {
    const [active, setActive] = useState(pro);
    const [loading, setLoading] = useState(-1);

    const { handleOrder } = usePayment();

    useEffect(() => {
        return setTimeout(() => { setLoading(-1); setActive(false); setPro(false) }, 6000)
    }, [])

    const upgradePlan = async (e) => {
        e?.preventDefault();
        handleOrder({ planId: 2 })
        // setLoading(50);
        // let res = await upgradeToPro();
        // if (res.success) {
        //     handleFreelanceData({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        //     toast.success("Profile Upgraded Successfully!");
        //     window.location.reload();
        // }
        // else if (res.data == 'exist') {
        //     toast.error(res.message)
        // }

        // setLoading(-1);
    }


    return (
        <>

            {active && <div className='flex items-center justify-center fixed inset-0 h-screen overflow-hidden' style={{ zIndex: 1001 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 ' style={{ zIndex: 10 }} onClick={() => { setActive(false) }}>
                </div>
                <div style={{ zIndex: 10 }} className='rounded-lg bg-white w-full p-10 max-w-5xl min-h-[75vh]'>
                    <div className='w-full flex items-center gap-5'>
                        <div className={`p-5 w-96 h-96`}>
                            <img className='w-full h-full object-contain' src={MoneyPlant} alt='' />
                        </div>

                        <div className='p-5 flex-grow min-w-[320px] min-h-[58vh] flex flex-col '>
                            <div className='py-6 h-[50vh]'>
                                <h1 className='text-lg font-semibold'>Benefits you’ll get</h1>
                                <ol className='text-sm lg:text-base p-3 text-gray-600 list-decimal space-y-2'>
                                    <li>Nunc in ac neque, diam posuere felis, nec mattis.</li>
                                    <li> In sit montes, leo consectetur in venenatis pellentesque rhoncus.</li>
                                    <li> Porta sodales viverra a porta quam ut dictum ut quis.</li>
                                    <li> Vitae id libero, vulputate duis dolor, diam et ut.</li>
                                    <li> Nunc in ac neque, diam posuere felis, nec mattis.</li>
                                    <li> In sit montes, leo consectetur in venenatis pellentesque rhoncus.</li>
                                    <li> Porta sodales viverra a porta quam ut dictum ut quis.</li>
                                    <li> Vitae id libero, vulputate duis dolor, diam et ut.</li>
                                </ol>
                            </div>

                            <div className='flex items-center justify-between gap-4'>
                                <button></button>
                                <button className='text-white bg-blue rounded-md py-1.5 px-8 text-lg font-medium' onClick={upgradePlan}>Upgrade</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {(loading >= 0 && loading <= 100) && <div className='flex gap-8 flex-col items-center justify-center fixed inset-0 h-screen overflow-hidden' style={{ zIndex: 1001 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-90 ' style={{ zIndex: 10 }} onClick={() => setLoading(-1)}>
                </div>
                <h1 className='text-4xl text-white font-semibold text-center z-10'>Upgrading your profile...</h1>
                <div className='rounded-lg bg-white w-full max-w-2xl h-2 z-10 relative overflow-hidden'>
                    <div className={`rounded-xl bg-blue z-20 absolute left-0 top-0 h-2 transition-loader`} />
                </div>
            </div>
            }
        </>
    )
}

export default Pro