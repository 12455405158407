import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CardBG from '../../assets/images/CardBG.png'

const colors = ['rgba(45, 200, 168, 0.27)', '#CF98FB', '#FEBEFF', 'rgba(29, 133, 232, 0.48)', 'rgba(255, 194, 88, 0.39)']

const Repository = ({ repo }) => {
    const history = useHistory();

    return <div className='p-5 bg-white rounded-lg shadow-lg hover:shadow-xl shadow-gray-200 border w-full max-w-[336px] cursor-pointer select-none relative'>
        <h1 className='line-clamp-1 font-semibold tracking-wide'>{repo?.name}</h1>
        <p className='line-clamp-2 text-sm text-gray-600 my-3 h-10'>{repo?.description}</p>
        <p>{repo?.filesLength ?? 0} files</p>
        <p className='text-blue my-2'>{repo?.topic}</p>
        <div className='flex items-center justify-between gap-2 mt-2'>
            <span className='text-gray-400 text-sm'>
                Updated {moment(repo?.updated_at).fromNow()}
            </span>
            <p className='flex items-center gap-1 text-sm z-20'>
                <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                    <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
                </svg>
            </p>
        </div>
        <div className='absolute top-0 left-0 w-full h-full bg-transparent' onClick={() => history.push(`/${repo?.username}/${repo.projectname}/${repo.name}/${repo?.branchname}`)}></div>
    </div>
}


const RepoCard = ({ repo , color}) => {
    const history = useHistory();
    return (
        <div className=' p-10 w-full last:rounded-br-xl' style={{
            background: color
        }}>
            <div className='w-full h-60 max-w-[360px] mx-auto rounded-lg overflow-hidden group cursor-pointer relative' onClick={() => history.push(`/${repo?.username}/${repo.projectname}/${repo.name}/${repo?.branchname}`)}>
                <img src={(repo?.thumbnail && repo?.thumbnail != 'undefined') ? repo?.thumbnail : CardBG} className="w-full h-full object-cover" alt='' />
                <div className='absolute bottom-0 left-0 right-0 group-hover:inset-0 z-10 p-4 text-white flex flex-col items-start justify-end transform translate-y-12 group-hover:translate-y-0 transition-all duration-300 ease-out' style={{ background: `linear-gradient(180deg, rgba(98, 98, 98, 0) 0%, #000000 100%)` }}>
                    <h1 className='line-clamp-1 text-sm'>{repo?.username} / {repo?.projectname}</h1>
                    <h1 className='mb-3 text-lg font-medium line-clamp-1'><span className='font-medium'>/{repo.name}</span></h1>
                    <div className='flex items-center justify-between w-full'>
                        <button title={repo?.repo_topic} className='bg-white text-gray-800 py-1 px-4 text-sm rounded-full'>
                            {repo?.repo_topic?.length > 13 ? repo?.repo_topic?.slice(0, 11) + '...' : repo?.repo_topic}
                        </button>
                        <div className='flex items-center gap-4'>
                            <div className='flex items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00004 4.99967C6.28671 4.99967 7.33337 3.95301 7.33337 2.66634C7.33337 1.37967 6.28671 0.333008 5.00004 0.333008C3.71337 0.333008 2.66671 1.37967 2.66671 2.66634C2.66671 3.95301 3.71337 4.99967 5.00004 4.99967ZM5.00004 1.66634C5.55337 1.66634 6.00004 2.11301 6.00004 2.66634C6.00004 3.21967 5.55337 3.66634 5.00004 3.66634C4.44671 3.66634 4.00004 3.21967 4.00004 2.66634C4.00004 2.11301 4.44671 1.66634 5.00004 1.66634ZM2.18004 8.33301H5.03337C5.01337 8.43968 5.00004 8.55301 5.00004 8.66634V9.66634H0.333374V8.66634C0.333374 7.11301 3.44004 6.33301 5.00004 6.33301C5.37337 6.33301 5.83337 6.37967 6.32004 6.46634C5.84671 6.78634 5.45337 7.18634 5.22671 7.67301C5.19004 7.67301 5.15171 7.67134 5.11337 7.66967C5.07504 7.66801 5.03671 7.66634 5.00004 7.66634C3.98004 7.66634 2.84004 7.99967 2.18004 8.33301ZM10 6.66634C8.77337 6.66634 6.33337 7.33967 6.33337 8.66634V9.66634H13.6667V8.66634C13.6667 7.33967 11.2267 6.66634 10 6.66634ZM11.6667 3.99967C11.6667 4.62634 11.3134 5.16634 10.8067 5.45301C10.5667 5.58634 10.2934 5.66634 10 5.66634C9.70671 5.66634 9.43337 5.58634 9.19337 5.45301C8.68671 5.16634 8.33337 4.62634 8.33337 3.99967C8.33337 3.07967 9.08004 2.33301 10 2.33301C10.92 2.33301 11.6667 3.07967 11.6667 3.99967Z" fill="white" />
                                </svg>
                                <span>{repo?.contributorList?.length || 0}</span>
                            </div>
                            <div className='flex items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7146 2.58984H14.7131C14.4684 2.58984 14.27 2.79032 14.27 3.03762C14.27 3.28492 14.4684 3.4854 14.7131 3.4854H14.7146C14.9593 3.4854 15.1577 3.28492 15.1577 3.03762C15.1577 2.79032 14.9593 2.58984 14.7146 2.58984Z" fill="white" />
                                    <path d="M12.1515 0H12.15C11.9053 0 11.7069 0.200477 11.7069 0.447777C11.7069 0.695077 11.9053 0.895554 12.15 0.895554H12.1515C12.3962 0.895554 12.5946 0.695077 12.5946 0.447777C12.5946 0.200477 12.3962 0 12.1515 0Z" fill="white" />
                                    <path d="M13.875 0.447777C13.875 0.200477 13.6766 0 13.4319 0C13.1872 0 12.9888 0.200477 12.9888 0.447777C12.9888 0.695077 13.1872 0.895554 13.4319 0.895554C13.6766 0.895554 13.875 0.695077 13.875 0.447777Z" fill="white" />
                                    <path d="M17.2762 0H17.2748C17.0301 0 16.8317 0.200477 16.8317 0.447777C16.8317 0.695077 17.0301 0.895554 17.2748 0.895554H17.2762C17.5209 0.895554 17.7193 0.695077 17.7193 0.447777C17.7193 0.200477 17.5209 0 17.2762 0Z" fill="white" />
                                    <path d="M16.438 0.447777C16.438 0.200477 16.2396 0 15.9949 0C15.7501 0 15.5518 0.200477 15.5518 0.447777C15.5518 0.695077 15.7501 0.895554 15.9949 0.895554C16.2396 0.895554 16.438 0.695077 16.438 0.447777Z" fill="white" />
                                    <path d="M14.7146 1.29297H14.7131C14.4684 1.29297 14.27 1.49345 14.27 1.74075V1.7422C14.27 1.9895 14.4684 2.18998 14.7131 2.18998H14.7146C14.9593 2.18998 15.1577 1.9895 15.1577 1.7422V1.74075C15.1577 1.49345 14.9593 1.29297 14.7146 1.29297Z" fill="white" />
                                    <path d="M16.438 1.74075C16.438 1.49345 16.2396 1.29297 15.9949 1.29297C15.7501 1.29297 15.5518 1.49345 15.5518 1.74075V1.7422C15.5518 1.9895 15.7501 2.18998 15.9949 2.18998C16.2396 2.18998 16.438 1.9895 16.438 1.7422V1.74075Z" fill="white" />
                                    <path d="M13.875 1.74075C13.875 1.49345 13.6766 1.29297 13.4319 1.29297C13.1872 1.29297 12.9888 1.49345 12.9888 1.74075V1.7422C12.9888 1.9895 13.1872 2.18998 13.4319 2.18998C13.6766 2.18998 13.875 1.9895 13.875 1.7422V1.74075Z" fill="white" />
                                    <path d="M14.7146 3.88281H14.7131C14.4684 3.88281 14.27 4.08329 14.27 4.33059V4.33204C14.27 4.57934 14.4684 4.77982 14.7131 4.77982H14.7146C14.9593 4.77982 15.1577 4.57934 15.1577 4.33204V4.33059C15.1577 4.08329 14.9593 3.88281 14.7146 3.88281Z" fill="white" />
                                    <path d="M16.438 4.33059C16.438 4.08329 16.2396 3.88281 15.9949 3.88281C15.7501 3.88281 15.5518 4.08329 15.5518 4.33059V4.33204C15.5518 4.57934 15.7501 4.77982 15.9949 4.77982C16.2396 4.77982 16.438 4.57934 16.438 4.33204V4.33059Z" fill="white" />
                                    <path d="M14.7146 5.17969H14.7131C14.4684 5.17969 14.27 5.38016 14.27 5.62746C14.27 5.87476 14.4684 6.07524 14.7131 6.07524H14.7146C14.9593 6.07524 15.1577 5.87476 15.1577 5.62746C15.1577 5.38016 14.9593 5.17969 14.7146 5.17969Z" fill="white" />
                                    <path d="M14.7146 6.47852H14.7131C14.4684 6.47852 14.27 6.67899 14.27 6.92629C14.27 7.17359 14.4684 7.37407 14.7131 7.37407H14.7146C14.9593 7.37407 15.1577 7.17359 15.1577 6.92629C15.1577 6.67899 14.9593 6.47852 14.7146 6.47852Z" fill="white" />
                                    <path d="M15.9944 5.17969H15.9929C15.7482 5.17969 15.5498 5.38016 15.5498 5.62746C15.5498 5.87476 15.7482 6.07524 15.9929 6.07524H15.9944C16.2391 6.07524 16.4375 5.87476 16.4375 5.62746C16.4375 5.38016 16.2391 5.17969 15.9944 5.17969Z" fill="white" />
                                    <path d="M13.4333 5.17969H13.4319C13.1872 5.17969 12.9888 5.38016 12.9888 5.62746C12.9888 5.87476 13.1872 6.07524 13.4319 6.07524H13.4333C13.678 6.07524 13.8764 5.87476 13.8764 5.62746C13.8764 5.38016 13.678 5.17969 13.4333 5.17969Z" fill="white" />
                                    <path d="M19 1.74075C19 1.49345 18.8016 1.29297 18.5569 1.29297C18.3122 1.29297 18.1138 1.49345 18.1138 1.74075V1.7422C18.1138 1.9895 18.3122 2.18998 18.5569 2.18998C18.8016 2.18998 19 1.9895 19 1.7422V1.74075Z" fill="white" />
                                    <path d="M19 3.03762C19 2.79032 18.8016 2.58984 18.5569 2.58984C18.3122 2.58984 18.1138 2.79032 18.1138 3.03762C18.1138 3.28492 18.3122 3.4854 18.5569 3.4854C18.8016 3.4854 19 3.28492 19 3.03762Z" fill="white" />
                                    <path d="M17.2761 1.29297H17.2747C17.0299 1.29297 16.8315 1.49345 16.8315 1.74075V1.7422C16.8315 1.9895 17.0299 2.18998 17.2747 2.18998H17.2761C17.5208 2.18998 17.7192 1.9895 17.7192 1.7422V1.74075C17.7192 1.49345 17.5208 1.29297 17.2761 1.29297Z" fill="white" />
                                    <path d="M17.2761 2.58984H17.2747C17.0299 2.58984 16.8315 2.79032 16.8315 3.03762C16.8315 3.28492 17.0299 3.4854 17.2747 3.4854H17.2761C17.5208 3.4854 17.7192 3.28492 17.7192 3.03762C17.7192 2.79032 17.5208 2.58984 17.2761 2.58984Z" fill="white" />
                                    <path d="M17.2761 3.88281H17.2747C17.0299 3.88281 16.8315 4.08329 16.8315 4.33059V4.33204C16.8315 4.57934 17.0299 4.77982 17.2747 4.77982H17.2761C17.5208 4.77982 17.7192 4.57934 17.7192 4.33204V4.33059C17.7192 4.08329 17.5208 3.88281 17.2761 3.88281Z" fill="white" />
                                    <path d="M16.438 3.03762C16.438 2.79032 16.2396 2.58984 15.9949 2.58984C15.7501 2.58984 15.5518 2.79032 15.5518 3.03762C15.5518 3.28492 15.7501 3.4854 15.9949 3.4854C16.2396 3.4854 16.438 3.28492 16.438 3.03762Z" fill="white" />
                                    <path d="M12.1516 1.29297H12.1501C11.9054 1.29297 11.707 1.49345 11.707 1.74075V1.7422C11.707 1.9895 11.9054 2.18998 12.1501 2.18998H12.1516C12.3963 2.18998 12.5947 1.9895 12.5947 1.7422V1.74075C12.5947 1.49345 12.3963 1.29297 12.1516 1.29297Z" fill="white" />
                                    <path d="M12.1516 2.58984H12.1501C11.9054 2.58984 11.707 2.79032 11.707 3.03762C11.707 3.28492 11.9054 3.4854 12.1501 3.4854H12.1516C12.3963 3.4854 12.5947 3.28492 12.5947 3.03762C12.5947 2.79032 12.3963 2.58984 12.1516 2.58984Z" fill="white" />
                                    <path d="M12.1516 3.88281H12.1501C11.9054 3.88281 11.707 4.08329 11.707 4.33059V4.33204C11.707 4.57934 11.9054 4.77982 12.1501 4.77982H12.1516C12.3963 4.77982 12.5947 4.57934 12.5947 4.33204V4.33059C12.5947 4.08329 12.3963 3.88281 12.1516 3.88281Z" fill="white" />
                                    <path d="M13.875 4.33059C13.875 4.08329 13.6766 3.88281 13.4319 3.88281C13.1872 3.88281 12.9888 4.08329 12.9888 4.33059V4.33204C12.9888 4.57934 13.1872 4.77982 13.4319 4.77982C13.6766 4.77982 13.875 4.57934 13.875 4.33204V4.33059Z" fill="white" />
                                    <path d="M13.875 3.03762C13.875 2.79032 13.6766 2.58984 13.4319 2.58984C13.1872 2.58984 12.9888 2.79032 12.9888 3.03762C12.9888 3.28492 13.1872 3.4854 13.4319 3.4854C13.6766 3.4854 13.875 3.28492 13.875 3.03762Z" fill="white" />
                                    <path d="M11.313 1.74075C11.313 1.49345 11.1146 1.29297 10.8699 1.29297C10.6251 1.29297 10.4268 1.49345 10.4268 1.74075V1.7422C10.4268 1.9895 10.6251 2.18998 10.8699 2.18998C11.1146 2.18998 11.313 1.9895 11.313 1.7422V1.74075Z" fill="white" />
                                    <path d="M11.313 3.03762C11.313 2.79032 11.1146 2.58984 10.8699 2.58984C10.6251 2.58984 10.4268 2.79032 10.4268 3.03762C10.4268 3.28492 10.6251 3.4854 10.8699 3.4854C11.1146 3.4854 11.313 3.28492 11.313 3.03762Z" fill="white" />
                                    <path d="M5.69971 1.27008C5.756 1.15972 5.85337 1.07648 5.9704 1.03867C6.08743 1.00086 6.21453 1.01158 6.32373 1.06847L8.99128 2.45799C9.10015 2.5147 9.18226 2.61279 9.21956 2.73068C9.25686 2.84857 9.24629 2.97661 9.19017 3.08662C9.13405 3.19663 9.03698 3.27961 8.92032 3.31731C8.80365 3.355 8.67695 3.34432 8.56808 3.28761L5.89926 1.89742C5.79039 1.84071 5.70827 1.74262 5.67097 1.62473C5.63368 1.50683 5.64425 1.3788 5.70037 1.26878L5.69971 1.27008Z" fill="white" />
                                    <path d="M0.167529 10.9046C0.0770823 11.0808 0.0219675 11.2734 0.00534829 11.4712C-0.0112709 11.669 0.0109328 11.8681 0.0706848 12.0573C0.130437 12.2464 0.226561 12.4217 0.353539 12.5732C0.480518 12.7246 0.63585 12.8493 0.810621 12.9399L2.52122 13.8311C2.69557 13.9227 2.88612 13.9786 3.08191 13.9955C3.2777 14.0124 3.47487 13.99 3.66206 13.9296C3.84926 13.8692 4.02278 13.772 4.17266 13.6435C4.32254 13.5151 4.44581 13.358 4.53537 13.1813L8.7205 4.96282C8.84898 4.71064 8.87311 4.41722 8.78759 4.14706C8.70207 3.8769 8.5139 3.65211 8.26444 3.52209L5.78703 2.23109C5.66343 2.16639 5.5284 2.12698 5.38969 2.11513C5.25099 2.10328 5.11133 2.11922 4.97873 2.16203C4.84614 2.20485 4.72321 2.2737 4.61701 2.36464C4.51081 2.45558 4.42342 2.56681 4.35985 2.69196L0.167529 10.9046Z" fill="white" />
                                    <path d="M9.04315 0.632535L8.92236 0.857422L9.14491 0.979474L9.26569 0.754588L9.04315 0.632535Z" fill="white" />
                                    <path d="M8.21324 0.032649L7.97168 0.482422L8.63931 0.84858L8.88088 0.398807L8.21324 0.032649Z" fill="white" />
                                    <path d="M7.60394 0.620592L7.26904 1.24414L8.27429 1.79546L8.60918 1.17191L7.60394 0.620592Z" fill="white" />
                                    <path d="M9.60027 0.937223L9.47949 1.16211L9.92458 1.40621L10.0454 1.18133L9.60027 0.937223Z" fill="white" />
                                </svg>

                                <span>{repo.likes}</span>
                            </div>

                            <div className='flex items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.0999462C0 0.0447474 0.0447474 0 0.0999462 0H12.9001C12.9553 0 13 0.0447474 13 0.0999462V12.9001C13 12.9553 12.9553 13 12.9001 13H0.0999462C0.0447474 13 0 12.9553 0 12.9001V0.0999462ZM0.199892 0.199892V12.8001H12.8001V0.199892H0.199892Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.1123 1.21323C1.1123 1.15803 1.15705 1.11328 1.21225 1.11328H11.7866C11.8418 1.11328 11.8865 1.15803 11.8865 1.21323V11.7875C11.8865 11.8427 11.8418 11.8875 11.7866 11.8875H1.21225C1.15705 11.8875 1.1123 11.8427 1.1123 11.7875V1.21323ZM1.3122 1.31317V11.6876H11.6866V1.31317H1.3122Z" fill="white" />
                                    <path d="M1.2125 9.78906H0.100098V12.8994H1.2125V9.78906Z" fill="white" />
                                    <path d="M3.21045 12.8995V11.7871H0.100125V12.8995H3.21045Z" fill="white" />
                                    <path d="M11.7875 3.21094H12.8999V0.100613H11.7875V3.21094Z" fill="white" />
                                    <path d="M9.78955 0.10049V1.21289L12.8999 1.21289V0.10049L9.78955 0.10049Z" fill="white" />
                                </svg>
                                <span>100</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function PublicRepository({ repos, free }) {
    const [colorArr, setColorArr] = useState([])

    useEffect(() => {

        if (repos?.length) {

            let arr2 = ['rgba(45, 200, 168, 0.27)', 'rgba(45, 200, 168, 0.27)'];

            let count = 1;
            for (let i = 2; i < repos?.length; i = i + 6) {
                // arr[count] = [i, i + 2, i + 3]
                arr2[i] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 2] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 3] = colors[Math.abs((count - 5) % 5)]
                count += 2;
            }

            count = 2

            for (let i = 3; i < repos?.length; i = i + 6) {
                // arr[count] = [i, i + 3, i + 4]
                arr2[i] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 3] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 4] = colors[Math.abs((count - 5) % 5)]
                count += 2;
            }
            setColorArr(arr2)
        }
    }, [repos?.length])
    return (
        <>
            {
                free ?

                    <div className='min-h-[300px] flex items-start gap-10 flex-wrap w-full'>
                        {
                            repos?.length ?
                                repos?.map(repo => <Repository key={repo.id} repo={repo} />)
                                :
                                <h1 className='text-3xl font-semibold text-gray-600 text-center mx-auto mt-10'>No Repository is available</h1>
                        }
                    </div>
                    :
                    <div className='min-h-[300px] grid md:grid-cols-2 lg:grid-cols-3 w-full rounded-xl overflow-hidden'>
                        {
                            repos?.length ?
                               repos?.map((repo, i) => <RepoCard key={repo.id} color={colorArr[i]} repo={repo} />)
                                :
                                <h1 className='text-3xl font-semibold text-gray-600 text-center mx-auto mt-10'>No Repository is available</h1>
                        }
                    </div>
            }
        </>
    )
}

export default PublicRepository