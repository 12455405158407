import axios from "axios";
import { url, endpoints } from "./config";
import jwt_decode from 'jwt-decode';

export const getAuthorizationHeader = () => {

  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
  const jwtToken = localStorage.getItem("greyffiti_token");
  if (jwtToken) {
    headers.authorization = 'Bearer ' + jwtToken;
  }
  return headers;
}

// export const setCookie = (cname, cvalue, extime) => {
//   // document.cookie = cname + "=" + cvalue + ";" + ";path=/";
//   document.cookie = `${cname}=${cvalue};;path=/`;
// }

// export const getCookie = (cname) => {
//   var name = cname + "=";
//   var ca = document.cookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c?.charAt(0) === ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export const delete_cookie = (name) => {
  localStorage.setItem("greyffiti_token", '')
  return "Cookie Deleted Successfully"
}

export const getDecodedToken = () => {
  const jwtToken = localStorage.getItem("greyffiti_token")

  if (!jwtToken) {
    return false;
  }
  var decoded = jwt_decode(jwtToken);
  return decoded
}
export const login = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.login}`, data);
    if (res.data && res.data.success) {
      var token = res.data.data;
      localStorage.setItem("greyffiti_token", token)
      // setCookie('token', token)
    }
    return res.data;
  } catch (err) {
    return err;
  }
};

export const register = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.register}`, data);
    if (res.data && res.data.success) {
      var token = res.data.data;
      localStorage.setItem("greyffiti_token", token)
      // setCookie('token', token)
    }
    return res.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.forgotPassword}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const resetPassword = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.resetPassword}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const confirmEmail = async (verificationKey) => {
  try {
    const res = await axios.post(
      `${url}${endpoints.confirmEmail}?key=${verificationKey}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const confirmUserInfo = async (verificationKey) => {
  try {
    const res = await axios.get(`${url}${endpoints.confirmUserInfo}?key=${verificationKey}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const toggleStateUpdate = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.toggleStateUpdate}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const acceptTerms = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.acceptTerms}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const statusUpdate = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.statusUpdate}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const socialLink = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.createProfileSocialInfo}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getStatus = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getStatus}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userBasicDetail = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userBasicDetail}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const professionName = async () => {
  try {
    const res = await axios.get(`${url}${endpoints.professionName}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userSocialDetail = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userSocialDetail}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userDetail = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userDetail}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const getUserByUsername = async (username) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userByUsername}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userCountables = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userCountables}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateUserName = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.updateUserName}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const createBasicProfile = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.createBasicProfile}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getToggleState = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getToggleState}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const updateProjectSpotlight = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.projectSpotlight}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addNewProject = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.projectAdd}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function deleteProjectById(projectId) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.delete(`${url}${endpoints.deleteProjectById}?projectId=${projectId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function restorSettings(type) {
  try {
    const headers = getAuthorizationHeader();
    const data = [];
    const res = await axios.post(`${url}${endpoints.settingsRestore}?setting_type=${type}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function editProjectById(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editProjectById}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function updateSettings(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.settingsUpdate}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getProjectsList(currentUserList = '') {
  try {
    let projectListDB = ''
    let res = ''
    const headers = getAuthorizationHeader();
    if (currentUserList) {
      res = await axios.get(`${url}${endpoints.getProjectsList}?currentUserList=${currentUserList}`, { "headers": headers });
      projectListDB = res.data;
    } else {
      res = await axios.get(`${url}${endpoints.getProjectsList}`, { "headers": headers });
      projectListDB = res.data;
    }
    return projectListDB;
  } catch (err) {
    return err;
  }
};

export async function getProjectsListByCreatedDate(projectListDB) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getProjectsListByCreatedDate}`, { "headers": headers });
    projectListDB = res.data;
    return projectListDB;
  } catch (err) {
    return err;
  }
};

export async function getProjectByName(projectName) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectPublic}?projectName=${projectName}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getProjectByUsername(username) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectByUsername}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


export async function getPublicRepository(username) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.repositoryPublic}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getPublicProjects(username) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectPublic}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getProjectsListBySpotlight(username) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectSpotlight}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getProjectsListByName(projectListDB) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getProjectsListByName}`, { "headers": headers });
    projectListDB = res.data;
    return projectListDB;
  } catch (err) {
    return err;
  }
};

export async function getProjectsListByUpdatedDate(projectListDB) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getProjectsListByUpdatedDate}`, { "headers": headers });
    projectListDB = res.data;
    return projectListDB;
  } catch (err) {
    return err;
  }
};

export async function getProjectsListBySize(username, getStorage = '') {
  try {
    const headers = getAuthorizationHeader();
    let res = await axios.get(`${url}${endpoints.getProjectsListBySize}?username=${username}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getReposBasedOnProjectId(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.getReposBasedOnProjectId}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function settingsList(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.settingsList}`, { "headers": headers });
    data = res.data;
    return data;
  } catch (err) {
    return err;
  }
};

export const addNewRepository = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addRepository}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function deleteRepositoryById(repoId) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.delete(`${url}${endpoints.deleteRepositoryById}?repoId=${repoId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function editRepositoryById(data) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.post(`${url}${endpoints.editRepositoryById}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getFilesBasedOnRepoId(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.getFilesBasedOnRepoId}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getRepositoryList(username) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getRepositoryList}?username=${username}`, { "headers": headers });
    const repoList = res.data;
    return repoList;
  } catch (err) {
    return err;
  }
};

export async function getRepositoryListByUpdatedDate(repoList) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getRepositoryListByUpdatedDate}`, { "headers": headers });
    repoList = res.data;
    return repoList;
  } catch (err) {
    return err;
  }
};

export async function getRepositoryListBySize() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getRepositoryListBySize}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getRepositoryListByCreatedDate(repoList) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getRepositoryListByCreatedDate}`, { "headers": headers });
    repoList = res.data;
    return repoList;
  } catch (err) {
    return err;
  }
};

export const getUsersList = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.getUsersList}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const recentActivities = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.recentActivities}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
}

export const projectViews = async (projectId) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(
      `${url}${endpoints.projectViews}?projectId=${projectId}`, '', { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const projectViewsCount = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectViewsCount}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
}

export const isEmailAvailable = async (email) => {
  try {
    const res = await axios.get(`${url}${endpoints.isEmailAvailable}?email=${email}`);
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function getTopicList() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getAllTopics}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getAllFilesList(branchId, event = "name") {
  try {
    const headers = getAuthorizationHeader();
    if (event !== "") {
      const resSort = await axios.get(`${url}${endpoints.getAllFilesList}?branch_id=${branchId}&sort_by=${event}`, { "headers": headers });
      return resSort.data;
    } else {
      const res = await axios.get(`${url}${endpoints.getAllFilesList}?branch_id=${branchId}`, { "headers": headers });
      return res.data;
    }
  } catch (err) {
    return err;
  }
};
export async function getAllFilesListByUserId(id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFilesAndFoldersListByUserId}?userId=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getFoldersListByUserId() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFoldersListByUserId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const recentFiles = async (event) => {
  try {
    const headers = getAuthorizationHeader();
    if (event !== "") {
      const resSort = await axios.get(`${url}${endpoints.recentFiles}?sort_by=${event}`, { "headers": headers });
      return resSort.data;
    } else {
      const res = await axios.get(`${url}${endpoints.recentFiles}`, { "headers": headers });
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export async function deletefileAndFolder(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.deletefileAndFolder}?type=${data.type}&branch_id=${data.branch_id}&folder_id=${data.folder_id}&file_id=${data.file_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fileDetail = async (file_id) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFileDetail}?file_id=${file_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const downloadFile = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.downloadFile}?file_id=${data.file_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const downloadFolder = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.downloadFolder}?branch_id=${data.branch_id}&dir_path=${data.dir_path}&zip=1`, { "headers": headers, responseType: 'arraybuffer' });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function editFileAndFolder(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editFileAndFolder}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function fileDeletePreview(file_Id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.fileDeletePreview}?file_Id=${file_Id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function folderDeletePreview(folder_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.folderDeletePreview}?folder_id=${folder_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const subscribe = async (data) => {
  try {
    const res = await axios.post(`${url}${endpoints.subscribe}`, data);
    return res.data;
  } catch (err) {

    return err.response.data;

  }
};

export async function restoreFile(data) {
  try {
    const headers = getAuthorizationHeader();
    let res = ''
    res = await axios.get(`${url}${endpoints.fileRestore}?file_id=${data.file_id}&version=${data.version}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getVersions(fileId, event = '') {
  try {
    const headers = getAuthorizationHeader();
    let res = ''
    if (event) {
      res = await axios.get(`${url}${endpoints.getVersions}?file_id=${fileId}&event=${event}`, { "headers": headers });
    } else {
      res = await axios.get(`${url}${endpoints.getVersions}?file_id=${fileId}`, { "headers": headers });
    }
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getFilesAndFoldersList(filesAndFoldersListDB) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFilesAndFoldersList}`, { "headers": headers });
    filesAndFoldersListDB = res.data;
    return filesAndFoldersListDB;
  } catch (err) {
    return err;
  }
};

export async function fileNotifications(limit) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.fileNotifications}?limit=${limit || 100}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function updateFileNotifications(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.put(`${url}${endpoints.fileNotifications}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function deleteVersion(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.deleteVersion}?file_id=${data.fileId}&version=${data.version}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const notificationCount = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.notificationCount}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
}

export const updateNotification = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.updateNotificationCount}?activity_id=${data}`, '', { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function createBranch(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.createNewBreanch}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteBranch = async (branchId) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.deleteBranch}?branch_id=${branchId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const editBranch = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editBranch}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addUserTopics = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(
      `${url}${endpoints.addUserTopics}`, formData, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getIssueList() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.issueList}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getIssueListByRepo(repoId) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.issueListByRepo}?repo_id=${repoId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getIssueListByProject(project_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.issueListByProject}?project_id=${project_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteIssue = async (issueId) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.deleteIssue}?issue_id=${issueId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const closeIssue = async (issueId) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.closeIssue}?issue_id=${issueId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createIssue = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.createIssue}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const editIssue = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editIssue}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getContributorsCountAgainstYear() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.contributorsCountAgainstYear}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getContributorsOfMonth(year, month) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.contributionsOfMonth}?month=${month}&year=${year}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response?.data;
  }
};

export const getDetailedIssue = async (issue_id) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.viewDetailedIssue}?issue_id=${issue_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getRepoContributors(repo_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.repositoryContributors}?repo_id=${repo_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getProjectContributors(project_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectContributors}?project_id=${project_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function removeContributors(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.removeContributors}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function addIssueAssignee(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addAssignee}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getInviteList = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(
      `${url}${endpoints.inviteList}`, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export const inviteAccept = async (verificationKey) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(
      `${url}${endpoints.inviteAccept}?key=${verificationKey}`, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export const inviteRemove = async (verificationKey) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(
      `${url}${endpoints.inviteRemove}?key=${verificationKey}`, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const inviteMembers = async (type, id, category) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(
      `${url}${endpoints.inviteMembers}?invite_for=${type}&id=${id}&type=${category}`, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const sendInviteToUsers = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(
      `${url}${endpoints.inviteUsers}`, data, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const sendInviteToNonUsers = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(
      `${url}${endpoints.inviteNonUsers}`, data, { "headers": headers }
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};



export async function addNewPreview(file_Id, formData) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addNewPreview}?file_Id=${file_Id}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function addNewPreviewFolder(formData) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addNewPreviewFolder}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const uploadFile = async (body) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.uploadFile}`, body, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function createTag(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addTag}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function listTags() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.listTags}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function removeTag(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.removeTag}?tagId=${data}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function editTag(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editTag}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function addIssueTags(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addIssueTag}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function listIssueComment(id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.issueComment}?issueId=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function addIssueComment(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.issueComment}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function removeIssueComment(id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.issueComment}?commentId=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function deactivateAccount() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}/user`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getFromSearch(data = '', userId) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getSearchResults}?search=${data}&userId=${userId}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function getDir(repo_id) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.get(`${url}${endpoints.getDir}?repo_id=${repo_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


export const changeDirectory = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.changeDirectory}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addFiles = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addFiles}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const syncFiles = async (formData) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.syncFiles}`, formData, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateFilePath = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.uploadFile}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


// remaining change in desktop
export async function updateEvent(data) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.patch(`${url}${endpoints.eventById}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function revokeEvent(id) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.delete(`${url}${endpoints.eventById}?event_id=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getEvents() {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.get(`${url}${endpoints.listEvents}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getEventById(id) {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.get(`${url}${endpoints.eventById}?id=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addEvent = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.eventById}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const attendEvent = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.userEvent}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const submitToEvent = async (data) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.userEvent}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const withdrawEvent = async (event_id) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.userEvent}?event_id=${event_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const userEventById = async (event_id) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userEvent}?event_id=${event_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const listUserEvents = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userEventList}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const listOrgEvents = async (org_id) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.orgEvents}?organization_id=${org_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getProjectDetails = async (project_name) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.projectByName}?project_name=${project_name}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const getUserPlan = async () => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userPlan}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getEventSubmission = async (event_id, shortlisted, finalist) => {
  try {
    if (!event_id) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.eventSubmission}?event_id=${event_id}&shortlisted=${shortlisted}&finalist=${finalist}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const updateEventSubmission = async (data) => {
  try {
    if (!data.event_id) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.eventSubmission}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addEventWinner = async (data) => {
  try {
    if (!data) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.eventWinner}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const getEventWinner = async (event_id) => {
  try {
    if (!event_id) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.eventWinner}?event_id=${event_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


export const addEventAnnouncement = async (data) => {
  try {
    if (!data) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.eventAnnouncement}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const getEventAnnouncement = async (event_id) => {
  try {
    if (!event_id) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.eventAnnouncement}?event_id=${event_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


export const getEventSpeaker = async (event_id) => {
  try {
    if (!event_id) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.eventSpeaker}?event_id=${event_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const downloadApp = async (endpoint) => {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoint}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};


export async function getBranchList({ repo, project }) {
  try {
    const headers = getAuthorizationHeader();
    let query = !isNaN(repo) ? `repo_id=${repo}` : `repo_name=${repo}&project_name=${project}`
    const res = await axios.get(`${url}${endpoints.listBranch}?${query}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function getBranch(project_name, repo_name, branch_name) {
  try {
    const headers = getAuthorizationHeader();
    let repo = `repo_name=${repo_name}`
    let project = `project_name=${project_name}`
    let branch = `branch_name=${branch_name}`
    const res = await axios.get(`${url}${endpoints.getBranch}?${project}&${repo}&${branch}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getAllUsers() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.adminUsers}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function getAllPlans() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.adminPlans}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addCommentTag = async (data) => {
  try {
    if (!data) throw Error("Missing params")
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addCommentTag}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function listTagsByProject(project_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.listTagsByProject}?project_id=${project_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};
export async function listTagsByRepo(repo_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.listTagsByRepo}?repo_id=${repo_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const isUsernameAvailable = async (username) => {
  try {
    const res = await axios.get(`${url}${endpoints.isUsernameAvailable}?username=${username}`);
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function deleteUser() {
  try {
    const headers = getAuthorizationHeader();

    const res = await axios.delete(`${url}${endpoints.USER}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err;
  }
};



export async function addLinks(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.addLinks}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function listLinks(repo_id, branch_id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.listLinks}?repo_id=${repo_id}&branch_id=${branch_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function removeLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.removeLink}?linkId=${data}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function editLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.editLink}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function transferOwnerShip(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.transferOwnerShip}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function updateShowcase(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.showcase}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function handleFreelance(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.freelance}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function handleFreelanceData(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.freelanceData}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function createOrganization(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.organization}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function updateOrganization(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.organization}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getOrganizations(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.organizations}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function retailerInvite(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.retailerInvite}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getRetailerInvite() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.retailerInvite}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getRetailerInviteList() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.retailerInviteList}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function removeRetailerInvite(id) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.retailerInvite}?id=${id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function updateRetailerInvite(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.retailerInvite}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function generateInviteLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.generateInviteLink}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function updateInviteFiles(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.updateInviteFiles}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function validateRetailerLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.validateRetailerLink}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function upgradeToPro(data = {}) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.updateUserPlan}/pro`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function getRepoByName({ project, repo }) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getRepoByName}?project=${project}&repo=${repo}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function validateMemberLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.organizationInvite}/validate`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function createMemberLink(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.organizationInvite}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function updateMemberRequest(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.organizationInvite}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getMembers() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.organizationMembers}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function updateMember(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.patch(`${url}${endpoints.organizationMembers}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function removeMember({ user_id }) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}${endpoints.organizationMembers}?user_id=${user_id}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function exitFromOrganization() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.delete(`${url}/organization/exit`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function likeDislikeRepo(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.likeDislikeRepo}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function likeDislikeProject(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.likeDislikeProject}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function frameProject(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.frameProject}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function follow(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}/follow`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function unfollow(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}/unfollow`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function userStatistics(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.userStatistics}?username=${data}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getFollowing() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFollowing}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getFollowers() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFollowers}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getFramesActivity() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFramesActivity}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getFollowActivity() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.getFollowActivity}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function getSubscription() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.subscription}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export async function downgradePlan() {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.get(`${url}${endpoints.downgradePlan}`, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export async function createOrder(data) {
  try {
    const headers = getAuthorizationHeader();
    const res = await axios.post(`${url}${endpoints.createOrder}`, data, { "headers": headers });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};