import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import {
  IconButton,
} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { red } from '@material-ui/core/colors';
import validator from 'validator';
import ClientCaptcha from "react-client-captcha";
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

import RegistrationArt from "../../assets/images/Registration8.png";

import { register } from '../../service/service';
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import toast from 'react-hot-toast';
import { passwordValidator } from '../../utils/validation';

function Registration() {
  const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
  const [fields, setFields] = useState({
    name: "",
    email: "",
    password: "",
    repeat: "",
    captcha: ""
  });

  const [flag, setFlag] = useState({
    name: true,
    email: true,
    password: true,
    repeat: true,
    captcha: true
  });

  const [emailExistFlag, setEmailExistFlag] = useState(true)
  const [captchaCode, setCaptchaCode] = useState("");

  const [errors, setErrors] = useState({});

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const history = useHistory()
    useEffect(() => {
        let token = localStorage.getItem("greyffiti_token");
        if (token) {
            history.push("/dashboard")
        }
    })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = e => {
    e.preventDefault();
    const { id } = e.target
    let newValue = e.target.value
    if (e.target.id.match("name")) {
      newValue = newValue.replace(/[^A-Za-z ]/gi, "");
    }
    if (e.target.id.match("email")) {
      newValue = newValue.replace(/[^A-Za-z0-9@.]/gi, "");
    }
    setFields(prevState => ({
      ...prevState,
      [id]: newValue
    }))

  };

  const validationForm = () => {
    let temp = { ...errors };
    let formIsValid = true;
    if (
      fields.name === "" &&
      fields.email === "" &&
      fields.password === "" &&
      fields.repeat === "" &&
      fields.captcha === ""
    ) {
      formIsValid = false;
      toast.error('Please fill all the details', { id: 'invalid' })
      setFlag({ name: false, email: false, password: false, repeat: false, captcha: false });
    }
    if (!fields.name) {
      if (fields.name.length < 3) {
        formIsValid = false;
        toast.error('Name must be greater than 3 characters', { id: 'invalid' })
      }
    }
    if (!validator.isEmail(fields.email)) {
      formIsValid = false;
      toast.error('Invalid Email', { id: 'invalid' })
    }
    if (!passwordValidator(fields.password)) {
      formIsValid = false;
      toast.error('Password Must Contain one special character, one number, one uppercase', { id: 'invalid' })
    }
    if (fields.password?.length < 7) {
      formIsValid = false;
      toast.error('Password must be greater than 6', { id: 'invalid' })
    }
    if (!(fields.repeat === fields.password)) {
      formIsValid = false;
      toast.error('Password is not matched', { id: 'invalid' })
    }
    if (fields.captcha !== captchaCode) {
      formIsValid = false;
      toast.error('Captcha failed', { id: 'invalid' })
    }

    setErrors({
      ...temp,
    });
    return formIsValid;
  }

  const code = (co) => {
    setCaptchaCode(co);
    return code;
  }

  const submitUserForm = (e) => {
    e.preventDefault();
    if (validationForm()) {
      registerAPI();
    }

  }

  // async function emailExist() {
  //   let email = fields.email;
  //   const res = await isEmailAvailable(email)
  //   if (res.data === true) {
  //     setEmailExistFlag(true);
  //   }
  //   else if (res.data === false) {
  //     setEmailExistFlag(false);
  //   }
  // }

  async function registerAPI() {
    setFlagLoaderButton(true);
    let name = fields.name;
    let password = fields.password;
    let email = fields.email;
    let data = { email, password, name };
    const res = await register(data);
    if (res.success) {
      toast.success('User Registered Successfully', { id: 'success' })
      setFlag({ name: true, email: true, password: true, repeat: true, captcha: true })
      setEmailExistFlag(true);
      window.location.href = "/termsAndConditions/" + true;
      setFields({ name: "", email: "", password: "", repeat: "", captcha: "" });
    }
    else {
      console.log(res)
      if (res.message == "Email already exists")
        toast.error('Email already exists', { id: 'error' })
      else
        toast.error('Unable to register user', { id: 'error' })
      // setEmailExistFlag(false);
    }
    setFlagLoaderButton(false);
  }

  return (
    <>
      <div className='max-h-screen h-screen overflow-hidden relative bg-red'>
        <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 2 }} /></Link>
        <img alt='' src={RegistrationArt} className={"full h-full object-cover absolute top-0 left-0"} style={{ zIndex: 1 }} />
        <div className='flex flex-col items-end justify-center p-5 lg:px-10 2xl:px-20 absolute top-0 left-0 w-full h-full' style={{ zIndex: 1 }}>
          <h1 className='text-white text-3xl md:text-4xl xl:text-5xl font-bold my-1'>Hello!</h1>
          <h1 className='text-white text-3xl md:text-4xl xl:text-5xl font-bold my-1'>Glad to see you :)</h1>

          <form method="post" name="UserLoginForm" className='flex flex-col w-full max-w-xs gap-4 mt-10'>

            <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
              <input required type="text" name="name" id="name" value={fields.name} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your name" onChange={handleChange} />
              {(!flag.name && fields.name === "" ? <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : !flag.name && fields.name !== "" && fields.name.length < 3 ?
                <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : "")}
            </div>

            <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
              <input required type="email" name="email" id="email" value={fields.email} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your email" onChange={handleChange} />
              {(!flag.email && fields.email === "" ? <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : !flag.email && fields.email !== "" && !validator.isEmail(fields.email) ?
                <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : !emailExistFlag ? <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : '')}
            </div>

            <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
              <input required type={values.showPassword ? 'text' : 'password'} name="password" id="password" value={fields.password} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your password" onChange={handleChange} />
              {<IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size='small'
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>}
            </div>

            <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
              <input required type={values.showPassword ? 'text' : 'password'} name="repeat" id="repeat" value={fields.repeat} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Repeat password" onChange={handleChange} />
            </div>

            <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>

              <ClientCaptcha
                captchaCode={(co) => code(co)}
                charsCount={5}
                width={100}
                fontColor={"#1F6AC7"}
                backgroundColor={'#FFFFFF'}
                containerClassName="captchaContainer"
              />
              <input required type='text' name="captcha" id="captcha" value={fields.captcha} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter Captcha" onChange={handleChange} />
              {(!flag.captcha && fields.captcha === "" ? <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : !flag.captcha && fields.captcha !== "" && !(fields.captcha === captchaCode) ?
                <WarningRoundedIcon style={{ color: red[500], width: "20%" }} /> : '')}
            </div>

            <button disabled={flagLoaderButton} type='submit' onClick={submitUserForm} className='w-full bg-blue py-3 mt-4 text-lg text-white font-semibold rounded-lg'>
              SignUp
              {flagLoaderButton && <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block mx-4" viewBox="0 0 24 24"></svg>}
            </button>
            <p className='lg:text-lg text-white font-semibold text-right'>Already have an account? <span className='text-blue cursor-pointer hover:text-yellow'><Link to='/login'>login</Link></span></p>
          </form>
        </div>
      </div>
    </>
  )
}

export default Registration