import React from 'react'

function BranchIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.36827 6.43198C8.52144 6.43198 9.45627 5.49715 9.45627 4.34398C9.45627 3.19081 8.52144 2.25598 7.36827 2.25598C6.2151 2.25598 5.28027 3.19081 5.28027 4.34398C5.28027 5.49715 6.2151 6.43198 7.36827 6.43198Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5919 6.43198C18.7451 6.43198 19.6799 5.49715 19.6799 4.34398C19.6799 3.19081 18.7451 2.25598 17.5919 2.25598C16.4387 2.25598 15.5039 3.19081 15.5039 4.34398C15.5039 5.49715 16.4387 6.43198 17.5919 6.43198Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.36827 21.0239C8.52144 21.0239 9.45627 20.0891 9.45627 18.9359C9.45627 17.7827 8.52144 16.8479 7.36827 16.8479C6.2151 16.8479 5.28027 17.7827 5.28027 18.9359C5.28027 20.0891 6.2151 21.0239 7.36827 21.0239Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.36816 6.43201V16.848" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.36816 11.424H17.5922V6.43201" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default BranchIcon