import React from 'react'

function DefaultUserIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="400" height="400">
                <path d="M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200 0C89.5431 0 0 89.5431 0 200C0 310.457 89.5431 400 200 400Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path d="M49 325C-6.38252 359.448 -65.636 469.517 -86 519C-106.174 568.483 -90.6099 655.508 35 579C160.61 502.492 49 325 49 325Z" fill="#CFD8DC" />
                <path d="M375 334C375 334 497.733 437.759 512 526C526.267 614.051 445.726 640.015 382 531C318.464 421.794 375 334 375 334Z" fill="#CFD8DC" />
                <path d="M67 318C67 318 122.577 289.095 203 291C283.423 292.905 336.707 312.667 351 326C365.293 339.333 332 789 332 789C332 789 186.4 798.761 61 770C61 770 80.2398 616.947 77 512C73.9508 406.862 67 318 67 318Z" fill="#FFC258" />
                <path d="M173 251C173 251 180.601 282.323 169 295C169 295 188.018 326.308 231 295C231 295 222.822 274.445 231 261L173 251Z" fill="#EF6E4B" />
                <path d="M256 194C256 194 273.072 178.217 275 197C277.314 218.818 256.856 228.02 253 221C248.951 214.17 256 194 256 194Z" fill="#FF8B66" />
                <path d="M144 196C144 196 126.935 180.217 125 199C122.679 220.818 143.131 230.02 147 223C150.869 216.17 144 196 144 196Z" fill="#FF8B66" />
                <path d="M202 274C237.868 274 258 250.198 258 180C258 143.855 239.257 122 199 122C158.743 122 142 143.855 142 180C142.191 255.524 164.796 274 202 274Z" fill="#FF8B66" />
                <path d="M154 205C153.412 208.502 156.295 212.263 161 213C165.705 213.737 170.412 211.502 171 208C171.588 204.498 168.705 200.737 164 200C159.491 199.263 154.784 201.498 154 205Z" fill="#DB614D" />
                <path d="M228 207C228.195 210.524 232.32 213.186 237 213C241.68 212.629 245.195 209.709 245 206C244.805 202.477 240.68 199.815 236 200C231.32 200.371 227.61 203.291 228 207Z" fill="#DB614D" />
                <path d="M166 168C166.389 168 166.611 168.371 167 168C172.829 163.734 181.806 165 182 165C182.971 165.186 183.806 164.113 184 163C184.194 162.073 183.166 161.186 182 161C181.611 161 172.189 159.806 165 165C164.223 165.557 163.417 166.258 164 167C164.583 167.557 165.611 168 166 168Z" fill="#092C4C" />
                <path d="M233 168C233.375 168 234.812 167.364 235 167C235.563 166.271 234.75 165.547 234 165C227.247 159.717 218.375 161 218 161C217.062 161.182 215.812 162.089 216 163C216.188 163.911 217.062 165.182 218 165C218 165 226.56 163.628 232 168C232.375 168.182 232.437 168.182 233 168Z" fill="#092C4C" />
                <path d="M169 189C169 193.381 171.563 197 174 197C176.437 197 178 193.381 178 189C178 184.619 176.437 181 174 181C171.375 181 169 184.619 169 189Z" fill="#092C4C" />
                <path d="M217 189C217 193.381 218.563 197 221 197C223.437 197 226 193.381 226 189C226 184.619 223.437 181 221 181C218.563 181 217 184.619 217 189Z" fill="#092C4C" />
                <path d="M242 139C242 139 216.965 137.019 162 133C162 133 141.664 154.525 143 206C143 206 111.293 113 205 113C298.898 113 256 204 256 204C256 204 259.367 156.414 242 139Z" fill="#092C4C" />
                <path d="M199 212L202 209L195 202C201.646 192.253 202 169.956 202 169H198C198 169.191 197.831 193.356 191 201L190 202L199 212Z" fill="#DB614D" />
                <path d="M223 223C223 223 222.761 225.751 222 229C220.098 236.262 213.971 247.956 194 247C175.741 246.236 170.951 233.644 170 226C169.429 222.178 170 220 170 220C187.689 224.778 223 223 223 223Z" fill="#092C4C" />
                <path d="M155 144C155 144 139.34 129.939 142 113C144.66 96.0607 181.455 83.5289 213 90.0001C213 90.0001 229.577 80.535 252 95.0001C274.423 109.465 289.763 152.354 270 179C270 179 274.844 133.774 249 149C249 149 207.828 127.441 155 144Z" fill="#092C4C" />
                <path d="M246 293C246 293 208.994 538.537 246 657C246 657 369.983 653.19 382 649C393.827 645 405.902 388.041 375 333C374.809 333.19 324.017 291.476 246 293Z" fill="#2DC8A8" />
                <path d="M287 296C287 296 266.304 326.238 240 340C240 340 239.192 335.307 239 329C238.808 327.28 239 326.102 239 324C239 320.751 239.616 316.823 240 313C240.192 310.898 240.616 309.294 241 307C243.688 292.856 249.872 277.69 266 271C266 271 270.2 271.986 275 276C276.152 276.956 277.848 277.853 279 279C280.536 280.72 281.656 283.515 283 286C283.768 287.529 284.424 288.28 285 290C285.96 291.911 286.616 293.898 287 296Z" fill="#1D85E8" />
                <path d="M285 291C275.647 301.952 260.187 316.742 239 330C238.809 328.271 239 326.114 239 324C259.614 310.742 274.411 296.376 283 286C283.954 287.345 284.427 289.271 285 291Z" fill="#CFD8DC" />
                <path d="M279 279C270.355 288.487 257.483 301.426 240 313C240.192 310.913 240.616 309.277 241 307C256.369 296.374 268.315 284.538 276 276C277.153 276.949 277.847 277.862 279 279Z" fill="#CFD8DC" />
                <path d="M315 386L295 388L275 462H291L298 436H316L328 457L347 454L315 386ZM301 424L305 404L315 424H301Z" fill="#CFD8DC" />
                <path d="M158 293C158 293 177.248 539.016 141 658C141 658 38.5389 653.998 29 650C19.4612 645.812 21.7651 379.018 50 324C49.8093 323.81 93.7083 291.477 158 293Z" fill="#2DC8A8" />
                <path d="M114 296C114 296 134.696 326.238 161 340C161 340 161.808 335.307 162 329C162 327.28 162 326.102 162 324C162 320.751 161.384 316.823 161 313C160.808 310.898 160.384 309.294 160 307C157.312 292.856 151.128 277.69 135 271C135 271 130.8 271.986 126 276C124.848 276.956 123.152 277.853 122 279C120.464 280.72 119.344 283.515 118 286C117.232 287.529 116.576 288.28 116 290C115.232 291.911 114.576 293.898 114 296Z" fill="#1D85E8" />
                <path d="M116 291C125.353 301.952 140.813 316.742 162 330C162 328.271 162 326.114 162 324C141.386 310.742 126.589 296.376 118 286C117.046 287.345 116.763 289.271 116 291Z" fill="#CFD8DC" />
                <path d="M122 279C130.645 288.487 143.517 301.426 161 313C160.808 310.913 160.384 309.277 160 307C144.631 296.374 132.685 284.538 125 276C123.847 276.949 123.153 277.862 122 279Z" fill="#CFD8DC" />
                <path d="M-39 357C-8.97128 364.609 9.29501 517.048 -8.00002 553C-25.2951 588.952 -50.0475 612.86 -93 579C-135.953 545.14 -116.558 369.984 -89 358C-75.8862 352.483 -39 357 -39 357Z" fill="#CFD8DC" />
                <path d="M223 223C223 223 222.761 225.807 222 229C201.268 232.381 176.467 227.878 170 226C169.429 222.244 170 220 170 220C187.689 224.695 223 223 223 223Z" fill="white" />
            </g>
        </svg>

    )
}

export default DefaultUserIcon