import React, { useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Avatar } from '@material-ui/core';
import userimg from "../../assets/images/Default_User_Image.png";
import { useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import { delete_cookie, fileNotifications, getFromSearch, updateFileNotifications } from '../../service/service';
import { useClickOutside, useDebounce } from '../../utils/helper';
import { KeyboardArrowUp } from '@material-ui/icons';
import { useEffect } from 'react';
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import moment from 'moment';
import { saveAs } from "file-saver";
import { toast } from 'react-hot-toast';

const url = 'https://seahorse-app-x9ss8.ondigitalocean.app'

function Navbar({ background }) {
    const history = useHistory()
    const user = useSelector(selectUserBasicInfo);
    const userData = useSelector(selectUserDetails);
    const [isActive, setIsActive] = useState(false);
    const [notifications, setNotifications] = useState([])
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const moreRef = useRef();
    const notificationRef = useRef();
    const [isNoti, setIsNoti] = useState(false);
    const [Platform, setPlatform] = useState(window?.navigator.platform);

    var logout = async function userLogout() {
        const res = delete_cookie("token");
        if (res) {
            window.location = "/";
        }
    }

    useClickOutside(() => isNoti ? setIsNoti(false) : setIsActive(false), isNoti ? notificationRef : moreRef)

    const debounced = useDebounce(search, 300)
    useEffect(() => {
        if (debounced)
            getSearchResults();
        else
            setData([])
    }, [debounced])

    const getSearchResults = async () => {
        const res = await getFromSearch(search, user?.id || 0);
        let arr = []
        if (res.data?.users)
            arr = [...arr, ...res.data.users]
        if (res.data?.projects)
            arr = [...arr, ...res.data.projects]
        if (res.data?.companies)
            arr = [...arr, ...res.data.companies]

        arr.sort(() => Math.random() - 0.5);
        setData(arr.slice(0, 5));
        // setData(arr);
    }

    const getNotifications = async () => {
        const res = await fileNotifications(5);
        if (res?.data?.length) {
            setNotifications(res.data)
        }
    }
    useEffect(() => {
        if (userData) {
            getNotifications()
        }
    }, [userData])

    const downloadFile = (os) => {
        toast.success("Downloading file...")
        if (os === 'mac') {
            saveAs(
                url + '/downloads/greyffiti.dmg',
                "Greyffiti.dmg"
            );
        } else if (os === 'macIntel') {
            saveAs(
                url + '/downloads/greyffiti.dmg',
                "Greyffiti.dmg"
            );
        } else {
            saveAs(
                url + '/downloads/greyffiti.exe',
                "Greyffiti.exe"
            );
        }
    };

    const menuList = [
        {
            id: 1,
            name: "Dashboard",
            url: '/dashboard'
        },
        {
            id: 2,
            name: "Insights",
            url: '/dashboard'
        },
        {
            id: 3,
            name: "Projects",
            url: `/${userData?.username}/`
        },
        {
            id: 4,
            name: "Repositories",
            url: `/${userData?.username}/all`
        },
        {
            id: 7,
            name: "Account",
            url: '/userProfile'
        },
        {
            id: 9,
            name: "Account settings",
            url: '/userProfile'
        },
        {
            id: 10,
            name: "Resources"
        },
        {
            id: 11,
            name: "FAQs",
            url: '/faq'
        },
        {
            id: 12,
            name: "How it works",
        },
        {
            id: 13,
            name: "Pricing",
            url: '/pricing'
        },
        {
            id: 14,
            name: "Help & Support",
        },
        {
            id: 16,
            name: "SignOut"
        }
    ];

    const Notification = ({ item, i, length }) => {
        const onClick = async () => {
            history.push(`/${item.username}/${item.project}/${item.repo}/issue/${item.issue_id}`)
            await updateFileNotifications({ id: item.id });
        }
        if (['create', 'comment', 'close', 'delete'].includes(item.type))
            return (<>
                <div className='flex items-center gap-2 cursor-pointer py-1' onClick={onClick}>
                    <Avatar src={item?.profile_pic || userimg} className="border mb-4" />
                    <div className='flex-grow'>
                        <p className='text-xs text-gray-600 line-clamp-3 leading-normal'>
                            <span className='cursor-pointer text-blue font-medium hover:text-blue-600'>{item?.username}</span>
                            {
                                item.type == 'create' ? ' has assigned an issue to you ' :
                                    item.type == 'comment' ? ` has commented on issue "${item.title}" ` :
                                        item.type == 'close' ? ` has closed the issue "${item.title}" ` :
                                            item.type == 'delete' ? ` has deleted the issue "${item.title}" ` : ''
                            }
                            in
                            <span className='cursor-pointer text-blue font-medium hover:text-blue-600'> {item.project}/{item.repo}</span>
                        </p>
                        <p className='text-xs italic text-right text-gray-400 mt-1'>{moment(item.created_at).fromNow()}</p>
                    </div>
                </div>
                {i < length - 1 && < hr className='w-full mx-auto' />}
            </>)
        else return (<></>)
    }

    return (
        <>
            <nav className={`flex items-center justify-between px-5 py-3 lg:px-10 sticky top-0 z-[70] ${background ? `bg-[${background}]` : 'bg-white'}`}>
                <div className="flex items-center justify-center">
                    <div className="h-14 w-12 object-contain">
                        <img src={logo} alt="logo" className='cursor-pointer' onClick={() => history.push('/dashboard')} />
                    </div>
                    <ul className="hidden lg:flex items-center space-x-6 md:space-x-8 xl:space-x-10 pl-10 text-lg font-medium">
                        <li className="cursor-pointer"><NavLink activeStyle={{ color: '#1C85E8' }} className='text-gray-800 hover:text-blue' to='/dashboard'>Dashboard</NavLink></li>
                        <li className="cursor-pointer"><NavLink activeStyle={{ color: '#1C85E8' }} className='text-gray-800 hover:text-blue' to='/feed'>Feed</NavLink></li>
                        <li className="cursor-pointer"><NavLink activeStyle={{ color: '#1C85E8' }} className='text-gray-800 hover:text-blue' to='/explore'>Explore</NavLink></li>
                        <li className="cursor-pointer relative"><NavLink activeStyle={{ color: '#1C85E8' }} className='text-gray-800 hover:text-blue' to='/events'>Events</NavLink> <span className='pre-alpha absolute -bottom-2 left-14 min-w-max'>Pre alpha</span></li>
                    </ul>
                </div>

                <div className="flex items-center space-x-4 relative" ref={moreRef}>
                    {userData?.organization && <Avatar src={userData?.organization?.profile_pic || userimg} className="border" />}

                    {!window?.location?.pathname.includes('explore') && <form onSubmit={() => search && history.push(`/explore?value=${search}`)} className='max-w-xs p-2 hidden sm:flex gap-2 items-center bg-gray-100 rounded border relative'>
                        <SearchIcon />
                        <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Across Greyffiti" className='flex-grow outline-none border-none bg-transparent' />

                        {data?.length && search?.length ? <div className='bg-white border rounded-lg p-4 w-full absolute top-12 space-y-3'>
                            {data.map(item => {
                                if (item.email)
                                    return (<div className='flex items-center gap-2 cursor-pointer my-1' onClick={() => { history.push(`/userProfile/${item?.username}`); setSearch('') }}>
                                        <Avatar src={item?.profile_pic || userimg} className="border" />
                                        <div className='flex-grow'>
                                            <h1 className='text-sm font-medium line-clamp-1'>{item?.username}</h1>
                                            <p className='text-xs text-gray-500 line-clamp-1'>{item?.name}</p>
                                        </div>
                                    </div>)
                                if (item.display_name)
                                    return (<div className='flex items-center gap-2 cursor-pointer my-1' onClick={() => { history.push(`/userProfile/${item?.username}`); setSearch('') }}>
                                        <Avatar src={item?.profile_pic || userimg} className="border" />
                                        <div className='flex-grow'>
                                            <h1 className='text-sm font-medium line-clamp-1'>{item?.name}</h1>
                                            <p className='text-xs text-gray-500 line-clamp-1'>{item?.username}</p>
                                        </div>
                                    </div>)
                                if (item.project_type_id)
                                    return (<div className='flex items-center gap-2 cursor-pointer my-1' onClick={() => { history.push(`/${item?.username}/${item?.name}`); setSearch('') }}>
                                        <Avatar src={UserBannerImage} className="border" />
                                        <div className='flex-grow'>
                                            <h1 className='text-sm font-medium line-clamp-1'>{item?.name}</h1>
                                            <p className='text-xs text-gray-500 line-clamp-1'>{item?.project_topic}</p>
                                        </div>
                                    </div>)
                                else return (<></>)
                            })}
                        </div> : <></>}
                    </form>}
                    {userData && <div className='relative' ref={notificationRef}>
                        {notifications.length > 0 && < h1 className='w-4 h-4 rounded-full text-xs font-medium absolute -top-1 right-2 bg-red text-white text-center'>{notifications.length}</h1>
                        }
                        <NotificationsIcon className='mx-4 cursor-pointer' onClick={() => setIsNoti(!isNoti)} />
                        {notifications?.length && isNoti ? <div className='bg-white border rounded-lg p-4 absolute top-12 right-0 space-y-3 min-w-full max-w-xs w-72' style={{ zIndex: 999 }}>
                            {notifications.map((item, i) => <Notification key={i} i={i} length={notifications.length} item={item} />)}
                        </div> : <></>}
                    </div>}
                    {user ? <div className='flex items-center cursor-pointer' onClick={() => setIsActive(!isActive)}>
                        <Avatar src={(userData?.role == 'admin') ? userData?.organization?.profile_pic : (user?.profile_pic || userimg)} className={`border ${user?.toggle_state == "off" ? 'border-gray-300' : user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} />
                        {isActive ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </div> :
                        <div className="flex items-center space-x-4">
                            <Link to="/login"><button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium bg-white">Login</button></Link>
                            <span className='hidden sm:inline-flex'>or</span>
                            <Link to="/register"> <button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium hidden sm:inline-flex bg-white">Create Account</button></Link>
                        </div>
                    }
                    {isActive && <div className='absolute top-14 right-0 popup-bg shadow-xl px-4 py-2 max-w-xs border rounded'>
                        <div className='absolute transform -top-2 right-2 w-4 h-4 border -rotate-45 popup-bg' style={{ borderColor: '#eee #eee transparent transparent' }} />

                        <div className='flex items-center gap-4 py-2'>
                            <img className={`w-10 h-10 rounded-full border object-cover bg-white ${user?.toggle_state == "off" ? 'border-gray-300' : user?.status_type == 'online' ? 'border-green-400' : user?.status_type == 'offline' ? 'border-red-400' : user?.status_type == 'working' ? 'border-blue-400' : user?.status_type == 'customized' ? 'border-yellow-400' : ''}`} src={user?.profile_pic || userimg} alt='' />
                            <h1 className='text-lg font-semibold text-blue cursor-pointer' onClick={() => history.push(`/userProfile/${userData?.username}`)}>
                                {userData?.organization?.created_by == userData?.id ? userData?.organization?.name
                                    :
                                    userData?.username?.length > 15 ? userData?.username?.slice(0, 13) + '...' : userData?.username}
                            </h1>
                        </div>

                        <h1 className="p-2 font-medium popup-bg-focus rounded-md text-gray-800">Dashboard</h1>
                        <h1 onClick={() => history.push('/dashboard')} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Insights</h1>
                        <h1 onClick={() => history.push(`/${userData?.username}/`)} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Projects</h1>
                        <h1 onClick={() => history.push(`/${userData?.username}/all`)} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Repositories</h1>

                        <h1 className="p-2 font-medium popup-bg-focus rounded-md text-gray-800 mt-2">Account</h1>
                        {/* <h1 onClick={() => history.push(`/userProfile/${userData?.username}`)} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Account settings</h1> */}
                        <h1 onClick={() => history.push(`/userProfile/edit`)} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Edit Profile</h1>

                        <h1 className="p-2 font-medium popup-bg-focus rounded-md text-gray-800 mt-2">Resources</h1>
                        {/* <h1 onClick={() => Platform?.toLowerCase()?.includes('mac') ? downloadFile('mac') : downloadFile('')} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Download App</h1> */}
                        <h1 onClick={() => history.push('/faq')} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">FAQs</h1>
                        <h1 className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">How it works</h1>
                        <h1 onClick={() => history.push('/pricing')} className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Pricing</h1>
                        <h1 className="p-2 font-medium text-gray-800 hover:text-blue-500 cursor-pointer">Help & Support</h1>
                        <h1 onClick={logout} className="p-2 font-medium border-t-2 border-gray-300 text-red hover:text-red-500 cursor-pointer">SignOut</h1>

                        {/* {menuList.map(menu => <h1 onClick={() => menu.id === 16 ? logout() : history.push(menu.url || '/dashboard')} key={menu.id} className="p-2 border-b font-medium text-gray-500 hover:text-blue-500 cursor-pointer">{menu.name}</h1>)} */}
                    </div>}
                </div>
            </nav>
        </>
    )
}

export default Navbar


