import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getEvents } from '../../service/service';
import Navbar from '../ReusableComponents/Navbar'

function EventAdmin() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    let res = await getEvents();
    if (res.success) {
      setEvents(res.data)
    }
  }

  useEffect(() => {
    fetchEvents();
  }, [])

  return (
    <>
      <Navbar background='#F6FAFD' />
      <div className='min-h-[90vh] bg-[#F6FAFD] p-5'>
        <div className='lg:px-10'>
          <div className='py-2 border-b flex items-center justify-between w-full'>
            <h1 className='text-2xl lg:text-3xl font-medium text-navy'>Your Events</h1>
            <span className='text-blue text-lg hover:text-blue-700 cursor-pointer'>Show All</span>
          </div>

          {
            !events.length
              ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Events Available </h1>
              :
              <div className='flex flex-wrap items-center justify-start gap-10 lg:gap-16 py-10'>
                {events.map((item, i) => item.title.toLowerCase().includes(search.toLowerCase()) && <div key={i} className='max-w-[19rem] rounded-xl overflow-hidden hover:shadow-lg group relative'
                  // onClick={() => history.push(`/organizationId/event/${item.id}`)}
                  onClick={() => history.push(`/events/${item.id}`)}
                >
                  <img className='w-full h-60 object-cover scale-110 group-hover:scale-100 transition-all duration-150' src={item.thumbnail} alt="" />
                  <div className='w-full bg-white absolute bottom-0 left-0 right-0 flex items-start justify-between gap-4 py-3 px-4 transform translate-y-20 group-hover:translate-y-0 transition-all duration-150'>
                    <div className='cursor-pointer' onClick={() => history.push(`/events/${item.id}`)}>
                      <h1 className='text-lg font-medium text-gray-800'>{item.title}</h1>
                      <p className='text-gray-500 text-sm'>By {item.organization_name}</p>
                    </div>
                    <div>
                      <div className='text-lg font-medium text-gray-800 flex items-center gap-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>
                        <span>4</span>
                      </div>
                      <p className='text-gray-500 text-sm'>100</p>
                    </div>
                  </div>
                </div>)}
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default EventAdmin