import React, { useEffect, useState } from "react";
import { nameFieldValidator, descriptionFieldValidator } from '../../utils/validation';
import { addNewRepository, editRepositoryById } from "../../service/service";
import toast from 'react-hot-toast'
import { useSelector } from "react-redux";
import { selectProjectList } from "../../slices/projectSlice";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

function AddRepository({ getFreshRepositoryList, projectData, setAddModal, isEdit = false, repoData, username }) {
  const [errors, setErrors] = useState({});
  const history = useHistory()
  const [project, setProject] = useState(projectData);
  const projectList = useSelector(selectProjectList)
  const [formData, setFormData] = useState({
    name: isEdit ? repoData.name : "",
    description: isEdit ? repoData.description : "",
    repository_type: isEdit ? repoData.repository_type == '1' ? 'public' : 'private' : "public",
    project_type: project?.project_type_id ?? '1', /// check
    repository_topics: project?.project_topics ?? 1
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (project) {
      setFormData({ ...formData, repository_type: project.project_type_id == '1' ? 'public' : 'private' })
    }
  }, [project])

  const getProject = async (projectId) => {
    try {
      const idx = projectList.findIndex(item => item.id == projectId)
      if (idx > -1) {
        setProject(projectList[idx])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isEdit && !projectData) {
      getProject(repoData.project_id)
    }
  }, [])


  function submit(e) {
    e.preventDefault();
    setErrors(validation(formData));
  }

  async function addRepository() {
    let name = formData.name;
    let description = formData.description;
    let repository_type = formData.repository_type;
    let repository_topics = `${formData.repository_topics || 1}`;
    let project_id = project?.id
    let data = { name, description, repository_type, repository_topics, project_id };
    const res = isEdit ? await editRepositoryById({ name: formData.name, description: formData.description, id: repoData.id, project_id: project.id, repository_type: formData.repository_type == 'public' ? '1' : '2' }) : await addNewRepository(data);
    let alertMessage = res.message
    if (!res.success) {
      if (res.message === "Repository Exists") {
        toast.error("Repository Name already exists", { id: 'error' })
        errors.name = "Repository Name already exists"
        setErrors(errors)
      } else {
        toast.error(alertMessage, { id: 'error' })
      }
    } else {
      toast.success(`Repository ${isEdit ? 'Updated' : 'Created'} Successfully`, { id: 'success' })
      if (!isEdit && res.data?.projectName) {
        history.push(`/${username}/${res.data?.projectName}/${name}/main`)
      }
      setFormData({ name: "", description: "", repository_type: "public", repository_topics: "", project_type: "1" });
      getFreshRepositoryList();
      setAddModal(false)
    }
  }

  const validation = (formData) => {
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (formData.name) {
      formData.name = formData.name.trim();
      // const isExist = repos.findIndex(item => item.name?.toLowerCase() == formData.name?.toLowerCase())
      // if (!isEdit && isExist > -1) {
      //   toast.error("Repository Name already exist", { id: 'error' })
      //   errors.name = "Repository Name already exist";
      // }
      if (formData.name.length < 3) {
        toast.error("Repository Name should have minimum 3 characters", { id: 'error' })
        errors.name = "Repository Name should have minimum 3 characters";
      }
      if (formData.name.length > 20) {
        toast.error("Repository Name can have maximum 20 characters", { id: 'error' })
        errors.name = "Repository Name can have maximum 20 characters";
      }
    }
    if (formData.description) {
      if (formData.description.length < 5) {
        toast.error("Repository description should have minimum 5 characters", { id: 'error' })
        errors.description = "Repository description should have minimum 5 characters";
      }
      if (formData.description.length > 120) {
        toast.error("Repository description can have maximum 120 characters", { id: 'error' })
        errors.description = "Repository description can have maximum 120 characters";
      }
    }
    if (Object.keys(errors).length === 0) {
      addRepository();
    }
    return errors;
  };

  const handle = (e) => {
    errors.name = " ";
    errors.description = " ";
    errors.projectName = " ";
    const { id } = e.target
    let newValue = e.target.value

    if (e.target.id.match("name")) {
      if (!nameFieldValidator(newValue)) {
        newValue = newValue.replace(/[^ A-Za-z0-9_-]/gi, "");
        toast.error("No special characters except \"\-\" and \"\_\" are allowed", { id: 'error' })
        errors.name = "No special characters except \"\-\" and \"\_\" are allowed";
      } else if (newValue.length === 20) {
        toast.error("Maximum of 20 characters", { id: 'error' })
        errors.name = "Maximum of 20 characters"
      }
    }
    if (e.target.id.match("description")) {
      if (!descriptionFieldValidator(newValue)) {
        newValue = newValue.replace(/[^ A-Za-z0-9]/gi, "");
        toast.error("No special characters are allowed", { id: 'error' })
        errors.description = "No special characters are allowed";
      } else if (newValue.length === 120) {
        toast.error("Maximum of 120 characters in description", { id: 'error' })
        errors.description = "Maximum of 120 characters"
      }
    }
    setFormData(prevState => ({
      ...prevState,
      [id]: newValue
    }))
  };

  const selectOne = (event) => {
    if (project?.project_type_id == 2) {
      return toast("Selected project is private", { id: 'info' })
    }
    let ptype = event.target.value;
    setFormData({
      ...formData,
      repository_type: ptype
    });
  };

  const handleChange = (event) => {
    let idx = projectList.findIndex(item => item.id == event.target.value);
    if (idx > -1) {
      setProject(projectList[idx])
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
        <div className='max-w-lg bg-white p-5 rounded-md w-full'>
          <form onSubmit={submit} className='p-5 w-full'>
            <h1 className='pb-1 border-b text-xl lg:text-3xl font-semibold text-gray-900 mb-6'>{isEdit ? 'Edit' : 'Add'} Repository</h1>
            {!projectData && !isEdit ?
              <div className="w-full mb-6">
                <FormControl className='w-full'>
                  <InputLabel id="selectInput">Project Name</InputLabel>
                  <Select
                    labelId="selectInput"
                    id="open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={project?.id || ''}
                    onChange={handleChange}
                    className="w-full"
                    defaultValue=""
                  >
                    <option value="" disabled={true} className="py-1 px-2 font-medium cursor-pointer">Select Project</option>
                    {projectList.map(item => <option key={item.id} value={item.id} className="py-1 px-2 font-medium cursor-pointer">{item.name}</option>)}
                  </Select>
                </FormControl>
              </div>
              : <div className='border focus:border-gray-900 bg-gray-200 rounded-md w-full px-4 py-2 mb-4 text-lg flex items-center gap-4'>
                <input required type="text" disabled value={project?.name} className={`w-full font-medium bg-transparent outline-none border-none flex-grow`} placeholder="Project name" onChange={handle} />
              </div>}

            <div className='mb-6'>
              <TextField size="small" label="Repository Name" name="name" id="name" value={formData.name} onChange={handle} variant="outlined" className={`w-full`} />
            </div>

            <div className='mb-6'>
              <TextField size="small" label="Repository Description" name="description" id="description" value={formData.description} onChange={handle} variant="outlined" className={`w-full`} />
            </div>

            <div className='text-lg text-gray-800 font-medium pb-1 mt-6 border-b'>Repository Type
              <div className='group relative inline-block mx-4'>
                <InfoRounded fontSize="medium" className="text-gray-500 cursor-pointer" />
                <div className='text-gray-500 p-2 rounded bg-white border font-light absolute bottom-6 w-48 z-10 hidden group-hover:block transition-all duration-300 ease-in-out select-none shadow-md' style={{ fontSize: '10px', lineHeight: 1.2 }}>
                  <h1 className='font-medium text-gray-900'>Public:</h1>
                  All files and folders will be visible to users visiting repository page. The repository can be downloaded and modified. Users can search for this repository.
                  <br />
                  <br />
                  <h1 className='font-medium text-gray-900'>Private:</h1>
                  All file and folders will be visible to users contributing to the repository on invitation bases. Users not invited to the repository and cannot search for this repository.
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center my-2 gap-6 text-lg" onChange={selectOne}>
              <div className="form-check">
                <input onChange={selectOne} value="public" className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="ProjectType" id="public" checked={formData.repository_type == 'public'} />
                <label className={`form-check-label inline-block text-gray-800 ${project?.project_type_id == 2 ? 'text-gray-300' : ''}`} htmlFor="public">
                  Public
                </label>
              </div>
              <div className="form-check">
                <input onChange={selectOne} value="private" className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="ProjectType" id="private" checked={formData.repository_type == 'private'} />
                <label className="form-check-label inline-block text-gray-800" htmlFor="private">
                  Private
                </label>
              </div>
            </div>
            <div className='flex items-center gap-8 mb-4 mt-10'>
              <button type='reset' className='bg-red py-1.5 px-6 rounded text-lg text-white' onClick={() => setAddModal(false)} >
                Cancel
              </button>
              <button type='submit' className='bg-blue py-1.5 px-6 rounded text-lg text-white' onClick={submit}>
                {isEdit ? 'Update' : 'Create'} Repository
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRepository;


