import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserBasicInfo, selectUserDetails } from '../../slices/userSlice';
import Navbar from '../ReusableComponents/Navbar'
import UserCard from '../ReusableComponents/UserCard';
import { useHistory, useParams } from 'react-router-dom';
import { ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import { getBranchList, getUserByUsername } from '../../service/service';
import { routeList } from '../../slices/routeSlice';
import { repoBranches, selectRepoBranches } from '../../slices/repoSlice';
import { useRef } from 'react';
import { useClickOutside } from '../../utils/helper';
import BranchList from '../Branch/BranchList';
import AddBranch from '../Branch/AddBranch';
import ArrowUpRight from '../ReusableComponents/ArrowUpRight';
import MiniLoader from '../ReusableComponents/MiniLoader';


function Branch() {
    const user = useSelector(selectUserBasicInfo);
    // const userData = useSelector(selectUserDetails);
    const [userData, setUserData] = useState()
    const history = useHistory();
    const dispatch = useDispatch()
    const { repo, project, repoId, username } = useParams()
    // const branchList = useSelector(selectRepoBranches);
    const [branchList, setBranchList] = useState([])
    const [loading, setLoading] = useState(false);
    const [branchPopUp, setBranchPopUp] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [branchSearch, setBranchSearch] = useState('')
    const [isSort, setIsSort] = useState(false);
    const [isAsc, setIsAsc] = useState(true)

    const moreRef = useRef()
    const cancelRef = useRef()
    useClickOutside(() => setIsSort(false), moreRef)
    // useClickOutside(() => setBranchPopUp(false), cancelRef)

    const fetchBranchList = async () => {
        const res = await getBranchList({ repo: repoId });
        if (res.data) {
            setBranchList(res.data)
            // dispatch(repoBranches(res.data))
        }
        setLoading(false)
    }
    const getUser = async () => {
        const res = await getUserByUsername(username);
        if (res.success) {
            // console.log(res)
            setUserData(res.data)
        }
        else {
            history.push("/404")
        }
    }
    useEffect(() => {
        if (username) {
            getUser();
        }
    }, [username])
    useEffect(() => {
        if (repoId) {
            fetchBranchList()
        }
    }, [user, userData, repoId])

    const sortByName = () => {
        if (branchList) {
            let arr = [...branchList]
            let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
            dispatch(repoBranches(sorted));
            setIsSort(false)
        }
    }
    const sortByTime = () => {
        if (branchList) {
            let arr = [...branchList]
            let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
            dispatch(repoBranches(sorted));
            setIsSort(false)
        }
    }

    const handleBranchOrder = () => {
        let arr = [...branchList];
        arr.reverse();
        dispatch(repoBranches(arr));
        setIsAsc(!isAsc)
    }


    const navigateProject = () => {
        dispatch(routeList(`/${username}/${project}`))
        history.push(`/${username}/${project}`)
    }
    const navigateRepo = () => {
        if (history.length > 2) {
            history.goBack();
        }
        else {
            history.push(`/${username}/${project}/${repo}/${branchList[0]?.name}`)
        }
    }

    return (
        <>
            <Navbar />
            <div className='flex items-start relative'>
                {loading ? (
                    <><MiniLoader /></>
                ) : (
                    <>
                        <UserCard user={{ ...userData, ...user }} className="lg:max-w-sm hidden md:inline-flex">
                            <>
                                <div className='flex items-center gap-2 flex-wrap text-gray-500'>
                                    <span className='text-gray-800 cursor-pointer hover:text-blue-500' onClick={() => history.push('/dashboard')}>Dashboard {" >"}</span>
                                    <span className='cursor-pointer hover:text-blue-500' onClick={navigateProject}>{project} {" >"}</span>
                                    <span className='text-gray-800 hover:text-blue-500 font-medium cursor-pointer' onClick={navigateRepo}>{repo}</span>
                                </div>
                                <h1 className='text-2xl font-semibold block my-4'>All Branches</h1>

                                <button onClick={navigateRepo} className='bg-blue text-white py-2 px-5 rounded-lg shadow-lg text-lg font-medium'>Go Back</button>
                            </>
                        </UserCard>

                        <div className='w-full flex-grow h-full p-5'>
                            <div className='flex items-center gap-8 xl:gap-12 py-2 border-b'>
                                <h1 className='text-lg font-medium cursor-pointer leading-loose text-navy border-b-2 border-blue-500'>Branches {branchList?.length > 0 ? `(${branchList.length})` : ''}</h1>
                            </div>
                            <div className='w-full'>
                                <div className='flex items-center justify-between gap-4 my-4'>
                                    <div className=' p-2 max-w-sm border rounded-lg bg-white flex w-full'>
                                        <SearchOutlined />
                                        <input type="text" placeholder='Search branch here...' onChange={(e) => branchList.length ? setBranchSearch(e.target.value) : {}} className=" flex-grow outline-none border-none px-2" />
                                    </div>

                                    <div className='flex items-center gap-4 relative z-30'>
                                        <div ref={moreRef} className='p-2 max-w-xs min-w-max border rounded-lg bg-white flex justify-between cursor-pointer w-full'>
                                            <p onClick={() => setIsSort(!isSort)}>Sort By</p>
                                            {!isSort ? <ExpandMore onClick={() => setIsSort(!isSort)} />
                                                : <ExpandLess onClick={() => setIsSort(!isSort)} />}
                                            {isSort && <div className='p-2 bg- min-w-max rounded-lg shadow-lg absolute top-12 left-0 popup-bg'>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1 border-b' onClick={sortByName}>Name</p>
                                                <p className='font-medium text-gray-800 hover:text-blue-500 cursor-pointer p-1' onClick={sortByTime}>Date Created</p>
                                            </div>}
                                        </div>
                                        <div className='p-2 border rounded-lg transform transition-all duration-300' onClick={handleBranchOrder}>
                                            <ArrowUpRight className={`transform transition-transform duration-300 ${isAsc ? 'rotate-0' : 'rotate-90'}`} />
                                        </div>
                                        <button className='px-10 py-2 bg-blue text-white rounded min-w-max' onClick={() => setAddModal(true)}>
                                            Create Branch
                                        </button>
                                    </div>
                                </div>
                                <div className='my-4'>
                                    {!branchList?.length
                                        ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>Loading ...</h1>
                                        : <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
                                            <thead>
                                                <tr className='font-medium text-gray-500'>
                                                    <td>Name</td>
                                                    <td>Description</td>
                                                    <td>Type</td>
                                                    <td>By</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody className='text-sm font-normal text-gray-600 tagsTable'>
                                                {
                                                    branchList?.map((branch, i) => branch.name.toLowerCase().includes(branchSearch.trim().toLowerCase()) && <BranchList key={i} branchData={branch} repoName={repo} projectName={project} userName={username} fetchBranchList={fetchBranchList} />)
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {addModal &&
                <AddBranch getFreshBranchList={fetchBranchList} setAddModal={(value) => setAddModal(value)} mainBranchId={branchList[0]?.id} />
            }
        </>
    )
}

export default Branch