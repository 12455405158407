import React, { useRef, useState } from 'react'
import UserBordered from '../../assets/icons/UserBordered'
import { SearchOutlined } from '@material-ui/icons'
import { generateInviteLink, getAllFilesList, getOrganizations, removeRetailerInvite, retailerInvite, updateInviteFiles } from '../../service/service'
import { useEffect } from 'react'
import { useClickOutside } from '../../utils/helper'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import fileimg from '../../assets/images/Files.png'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const InviteRetailer = ({ type = 'repo', projectId, repoId, files, onClose, contributors, currentBranch, branches, retailerLink, linkExpired, refetch, branchId, fileArr }) => {
    const [editFile, setEditFile] = useState(false);
    const [organizations, setOrganizations] = useState([])
    const [invitees, setInvitees] = useState([])
    const [search, setSearch] = useState('')
    const [show, setShow] = useState(false)
    const [invited, setInvited] = useState([])
    const [selectedFileList, setSelectedFileList] = useState([])
    const [isActive, setIsActive] = useState('');
    const [branch, setBranch] = useState(currentBranch);

    useEffect(() => {
        if (branchId && branches?.length) {
            let bh = branches.find(item => item.id == branchId)
            setBranch(bh)
        }
    }, [branchId, branches])

    const [fileList, setFileList] = useState(files || []);

    const getFiles = async () => {
        const res = await getAllFilesList(Number(branch?.id));
        if (res.data?.length) {
            if (res.data?.length > 0) {
                setFileList(res.data)
            }
            else if (res.data[0]?.children) {
                setFileList(res.data[0]?.children)
            }
        } else {
            setFileList([])
        }
    }

    useEffect(() => {
        if (branch?.id) {
            getFiles();
        }
    }, [branch])

    const ref = useRef()
    useClickOutside(() => setShow(false), ref)

    const getRetailers = async () => {
        let res = await getOrganizations({ type, projectId, repoId })
        if (res.success) {
            setInvitees([])
            setInvited(res.data?.invites || [])
            setOrganizations(res.data?.organizations || [])
        }
    }

    useEffect(() => {
        if (type && (projectId || repoId))
            getRetailers();
    }, [type, projectId, repoId])

    useEffect(() => {
        if (fileArr) {
            setSelectedFileList(JSON.parse(fileArr || '[]'))
        } else {
            setSelectedFileList(fileList?.map(item => Number(item?.folder_id || item?.file_id)))
        }
    }, [fileList, invited])


    const handleRemoveInvitee = (id, index) => {
        // let idx = invitees?.findIndex(item => item?.id == id)
        // if (idx > -1) {
        // }
        let arr = [...invitees]
        let item = arr.splice(index, 1);
        setInvitees(arr);
        setOrganizations([...item, ...organizations])
    }

    const handleRemoveInvite = async (id, index) => {
        let res = await removeRetailerInvite(id)
        if (res.success) {
            getRetailers();
        }
    }


    const sendInvite = async () => {
        let list = invitees?.map(item => item?.created_by)
        if (!list?.length) {
            return toast.error("No Organization selected", { id: 'error' })
        }
        if (!selectedFileList?.length)
            return toast.error(`No ${type == 'project' ? 'repository' : 'file'} selected`, { id: 'error' })

        let data = {
            type: type,
            project_id: projectId,
            repo_id: repoId,
            status: 'pending',
            branch_id: branch?.id,
            file_list: type == 'repo' ? JSON.stringify(selectedFileList) : '',
            invitees: list
        }
        let res = await retailerInvite(data)

        if (res.success) {
            setInvitees([])
            toast.success('Invited Successfully');
            getRetailers()
            // setInvited([res.data, ...invited])
        }
        else {
            toast.error("Unable to Invite")
        }
    }

    const [linkCopy, setLinkCopy] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLinkCopy(false)
        }, 2000)
    }, [linkCopy])

    const copyLink = () => {
        setLinkCopy(true);
        navigator.clipboard.writeText(`${window.location.origin}/retailer/${retailerLink}`)
    }

    const generateLink = async () => {
        let res = await generateInviteLink({ projectId, repoId, branchId: branch?.id, file_list: JSON.stringify(selectedFileList) });
        if (res.success) {
            retailerLink = res.data.link_id;
            linkExpired = res.data.generated_at;
            setLinkCopy(true);
            navigator.clipboard.writeText(`${window.location.origin}/retailer/${res.data.link_id}`);
            refetch();
        }
    }

    const updateFiles = async () => {
        let res = await updateInviteFiles({ file_list: JSON.stringify(selectedFileList), branch_id: branch?.id, link_id: retailerLink, repo_id: repoId })
        if (res.success) {
            toast.success("Files Access Updated")
        }
    }

    return (
        <>
            <div className='fixed inset-0 z-[100] bg-transparent grid place-items-center'>
                <div className='fixed inset-0 bg-black bg-opacity-50'></div>
                <div className='z-10 bg-white w-full max-w-4xl rounded-lg p-5 xl:p-10 relative'>
                    <svg onClick={onClose} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-gray-500 cursor-pointer absolute top-4 right-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1 className='text-3xl font-semibold text-gray-800'>Share files with Retailers</h1>
                    <div className='flex items-center gap-8 my-4'>
                        <div className='bg-gray-200 rounded-lg flex-grow px-2 relative group' ref={ref}>
                            <input onFocus={() => setShow(true)} type="text" value={search} onChange={(e) => setSearch(e.target.value)} className='w-full p-2 outline-none bg-transparent' placeholder='Enter Retailer name or paste link' />

                            {show ? <div className='bg-white p-3 rounded-lg shadow-lg absolute top-10 left-0 w-full'>
                                {organizations?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase()))?.length ?
                                    organizations?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase()))?.slice(0, 5)?.map((item, i) => (
                                        <div key={i} className='flex items-center gap-2 p-2 rounded cursor-pointer hover:bg-gray-50' onClick={() => {
                                            let i = contributors?.findIndex(data => data.contributer_id == item?.created_by)
                                            if (i > -1) {
                                                toast.error(`${item?.name} is already part of your contributors cannot send retailer invite`, { id: 'err' })
                                            }
                                            else {
                                                let idx = organizations?.findIndex(data => data?.id == item?.id)
                                                if (idx > -1) {
                                                    let arr = [...organizations]
                                                    arr.splice(idx, 1)
                                                    setOrganizations(arr);
                                                    setInvitees([...invitees, item])
                                                }
                                            }
                                        }}>
                                            {
                                                item?.profile_pic ?
                                                    <img className='w-10 h-10 object-cover rounded-full' src={item?.profile_pic} alt='' />
                                                    :
                                                    <UserBordered className='w-10 h-10 rounded-full' />
                                            }
                                            <h1 className='font-medium text-gray-800 flex-grow'>{item?.name}</h1>
                                        </div>
                                    )) :
                                    <h1>No Organization found</h1>}
                            </div> : ''}
                        </div>

                        <button onClick={retailerLink && linkExpired && moment(linkExpired)?.diff(moment(), 'hours') > -23 ? copyLink : generateLink} className='flex items-center gap-1 bg-green font-medium text-lg text-white py-2 px-8 rounded-lg relative outline-none'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                            </svg>
                            <span>
                                {
                                    retailerLink && linkExpired && moment(linkExpired)?.diff(moment(), 'hours') > -23
                                        ?
                                        'Copy Link'
                                        :
                                        'Generate Link'
                                }
                            </span>

                            {linkCopy ? <div className='bg-gray-50 shadow-md border text-gray-800 rounded-lg text-sm py-1.5 px-4 font-normal absolute -top-10 left-1/2 transform -translate-x-1/2'>
                                <span>Copied!!!</span>
                            </div> : ''}
                        </button>
                    </div>
                    <div className='flex items-center gap-1 cursor-pointer' onClick={() => setEditFile(true)}>
                        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.1042 9.29679L15.6771 4.92179L17.1354 3.46346C17.5347 3.06415 18.0253 2.8645 18.6073 2.8645C19.1885 2.8645 19.6788 3.06415 20.0781 3.46346L21.5365 4.92179C21.9358 5.3211 22.1441 5.80304 22.1615 6.36763C22.1788 6.93152 21.9878 7.41311 21.5885 7.81242L20.1042 9.29679ZM18.5938 10.8333L7.55208 21.8749H3.125V17.4478L14.1667 6.40617L18.5938 10.8333Z" fill="#C86E2D" />
                        </svg>
                        <span style={{ color: 'rgba(200, 110, 45, 1)' }}>Change selected {type == 'project' ? 'repositories' : 'files and folders'}</span>
                    </div>
                    <p style={{ color: 'rgba(200, 110, 45, 1)' }} className='ml-6'>({selectedFileList?.length} {type == 'project' ? 'repositories' : 'files and folders'})</p>

                    <div className='py-4 h-[60vh] overflow-y-auto'>
                        {invitees?.length ?
                            <>
                                <div className='flex w-full items-center justify-between pr-4'>
                                    <h1 className='text-2xl font-medium text-gray-800 mb-2'>New Invites</h1>
                                    <button disabled={!invitees?.length} onClick={sendInvite} className='bg-blue disabled:bg-gray-300 text-white font-medium py-2 text-lg rounded-lg px-8 outline-none'>
                                        Invite
                                    </button>
                                </div>
                                <div className='flex flex-col w-full'>
                                    {
                                        invitees?.map((item, i) => <Row key={i} index={i} user={item} onRemove={handleRemoveInvitee} />)
                                    }
                                </div>
                            </>
                            :
                            <></>}

                        <h1 className='text-2xl font-medium text-gray-800 mb-2 mt-4'>Invited</h1>
                        <div className='flex flex-col w-full'>
                            {
                                invited?.length ? invited?.map((item, i) => <Row key={i} index={i} user={item} onRemove={handleRemoveInvite} />) : <h1></h1>
                            }
                        </div>
                    </div>

                </div>
            </div>

            {editFile ? <div className='fixed inset-0 z-[101] bg-transparent grid place-items-center'>
                <div className='fixed inset-0 bg-black bg-opacity-50'></div>
                <div className='z-10 bg-white w-full max-w-4xl rounded-lg p-5 xl:p-10 relative'>
                    <svg onClick={() => setEditFile(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-gray-500 cursor-pointer absolute top-4 right-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1 className='text-3xl font-semibold text-gray-800'>Select {type == 'project' ? 'Repositories' : 'Files'} to share</h1>
                    <div className='flex items-center gap-2 my-4'>
                        <div className=' p-2 border flex-grow rounded-lg bg-white flex w-full relative'>
                            <SearchOutlined />
                            <input type="text" placeholder='Search files here...' className=" flex-grow outline-none border-none px-2" />
                        </div>
                        <div className='relative w-[200px]'>
                            <button className='text-white bg-blue py-1 flex items-center justify-between w-full px-4 rounded-lg' onClick={() => setIsActive(!isActive)}>
                                <span >{branch?.name}</span>
                                <ArrowDropDownIcon fontSize='large' className={`${branches?.length < 2 ? 'opacity-0' : ''}`} />
                            </button>
                            {
                                isActive ?
                                    <div className='absolute top-10 left-0 p-2 bg-gray-50 rounded-lg shadow-lg w-full border'>
                                        {branches?.map(item => <h1 onClick={() => { setBranch(item); setIsActive(false) }} className='font-medium py-1 px-2 rounded-lg cursor-pointer hover:bg-slate-100'>{item.name}</h1>)}
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                    <div className='min-h-[57vh] flex items-start flex-wrap gap-10 p-4'>
                        {
                            type == 'project' ?
                                fileList?.map((item, i) => (
                                    <div key={i} className=''>
                                        <svg width="100" viewBox="0 0 174 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M76.56 13.2609V7.33846C76.56 3.73764 73.4441 0.818359 69.5999 0.818359H6.9601C3.11592 0.818359 0 3.7373 0 7.33846V45.7128H174V24.1277C174 20.5269 170.884 17.6076 167.04 17.6076H81.2001C78.6374 17.6076 76.56 15.6616 76.56 13.2609Z" fill="#FE8083" />
                                            <path d="M164.72 138.181H9.28014C4.15481 138.181 0 134.289 0 129.488V44.1614H76.8197C78.7771 44.1614 80.6442 43.3894 81.9629 42.0344L90.5137 33.2487C91.8324 31.8936 93.6995 31.1216 95.6568 31.1216H174V129.488C174 134.289 169.845 138.181 164.72 138.181Z" fill="#FFC258" />
                                        </svg>
                                        <label htmlFor={item?.name}>
                                            <div className='mt-2 text-sm flex items-start gap-2'>
                                                <input type="checkbox" className='w-4 h-4' checked={selectedFileList?.includes(Number(item?.id))} onChange={() => {
                                                    let idx = selectedFileList?.findIndex(data => data == Number(item?.id))
                                                    if (idx > -1) {
                                                        let arr = [...selectedFileList]
                                                        arr.splice(idx, 1);
                                                        setSelectedFileList(arr)
                                                    }
                                                    else {
                                                        setSelectedFileList([...selectedFileList, Number(item?.id)])
                                                    }
                                                }} name={item?.name} id={item?.name} />
                                                <span>{item.name}</span>
                                            </div>
                                        </label>
                                    </div>
                                ))
                                :

                                fileList?.map((item, i) => (
                                    <div key={i} className=''>
                                        {
                                            item?.type == 'file'
                                                ?
                                                <img
                                                    className='w-16 h-16 object-contain'
                                                    src={fileimg}
                                                    alt='file-icon'
                                                />
                                                :
                                                <svg className='w-16 h-16' viewBox="0 0 174 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M76.56 13.2609V7.33846C76.56 3.73764 73.4441 0.818359 69.5999 0.818359H6.9601C3.11592 0.818359 0 3.7373 0 7.33846V45.7128H174V24.1277C174 20.5269 170.884 17.6076 167.04 17.6076H81.2001C78.6374 17.6076 76.56 15.6616 76.56 13.2609Z" fill="#FE8083" />
                                                    <path d="M164.72 138.181H9.28014C4.15481 138.181 0 134.289 0 129.488V44.1614H76.8197C78.7771 44.1614 80.6442 43.3894 81.9629 42.0344L90.5137 33.2487C91.8324 31.8936 93.6995 31.1216 95.6568 31.1216H174V129.488C174 134.289 169.845 138.181 164.72 138.181Z" fill="#FFC258" />
                                                </svg>
                                        }
                                        <label htmlFor={item?.name}>
                                            <div className='mt-2 text-sm flex items-center gap-2'>
                                                <input type="checkbox" className='w-4 h-4' checked={selectedFileList?.includes(Number(item?.folder_id || item?.file_id))} onChange={() => {
                                                    let idx = selectedFileList?.findIndex(data => data == Number(item?.folder_id || item?.file_id))
                                                    if (idx > -1) {
                                                        let arr = [...selectedFileList]
                                                        arr.splice(idx, 1);
                                                        setSelectedFileList(arr)
                                                    }
                                                    else {
                                                        setSelectedFileList([...selectedFileList, Number(item?.folder_id || item?.file_id)])
                                                    }
                                                }} name={item?.name} id={item?.name} />
                                                <span>{item.name}</span>
                                            </div>
                                        </label>
                                    </div>
                                ))
                        }

                    </div>

                    <div className='flex items-center justify-between w-full border-t pt-4'>
                        <label htmlFor='selectAll'>
                            <div className=' text-sm flex items-center gap-2'>
                                <input type="checkbox" className='w-4 h-4' checked={fileList?.length == selectedFileList?.length} onChange={e => {
                                    if (fileList?.length == selectedFileList?.length) {
                                        setSelectedFileList([])
                                    }
                                    else {
                                        if (type == 'project') {
                                            setSelectedFileList(fileList?.map(item => Number(item?.id)))
                                        }
                                        else {
                                            setSelectedFileList(fileList?.map(item => Number(item?.folder_id || item?.file_id)))
                                        }
                                    }
                                }} name="selectAll" id="selectAll" />
                                <span>Select All Files</span>
                            </div>
                        </label>
                        <div className='relative'>
                            <button className='text-white bg-blue py-2 px-8 rounded-lg font-medium' onClick={() => { updateFiles(); setEditFile(false) }}>Share</button>
                        </div>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default InviteRetailer

const Row = ({ user, onRemove, index }) => {
    return (
        <div className='flex items-center gap-4 py-1.5 px-2 rounded-lg hover:bg-gray-50'>
            {
                user?.profile_pic ?
                    <img className='w-12 h-12 object-cover rounded-full' src={user?.profile_pic} alt='' />
                    :
                    <UserBordered className='w-12 h-12 object-cover rounded-full' />
            }
            <div className='flex-grow'>
                <h1 className='text-xl font-medium text-gray-800'>{user?.name || user?.username}</h1>
                <h1 className={`text-xs capitalize ${user?.status == 'pending' ? 'text-orange-400' : user?.status == 'accepted' ? 'text-blue' : user?.status == 'rejected' ? 'text-red' : 'text-gray-800'}`}>{user?.status}</h1>
            </div>

            <button className='bg-red text-white py-2 rounded-lg px-8' onClick={() => onRemove(user?.id, index)}>
                Remove
            </button>
        </div>
    )
}