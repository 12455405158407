import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    fileList: [],
    filePreview: {
        title: '',
        url: '',
        open: false
    }
}

export const fileSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fileList: (state, action) => {
            if (action.payload)
                state.fileList = action.payload
        },
        filePreview: (state, action) => {
            if (action.payload)
                state.filePreview = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { fileList, filePreview } = fileSlice.actions

export const selectFileList = (state) => state.file.fileList
export const selectFilePreview = (state) => state.file.filePreview

export default fileSlice.reducer