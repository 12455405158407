import React from 'react'

function FolderIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="174" height="139" viewBox="0 0 174 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M76.56 13.2609V7.33846C76.56 3.73764 73.4441 0.818359 69.5999 0.818359H6.9601C3.11592 0.818359 0 3.7373 0 7.33846V45.7128H174V24.1277C174 20.5269 170.884 17.6076 167.04 17.6076H81.2001C78.6374 17.6076 76.56 15.6616 76.56 13.2609Z" fill="#FE8083" />
            <path d="M164.72 138.181H9.28014C4.15481 138.181 0 134.289 0 129.488V44.1614H76.8198C78.7771 44.1614 80.6442 43.3894 81.9629 42.0344L90.5137 33.2487C91.8324 31.8936 93.6995 31.1216 95.6568 31.1216H174V129.488C174 134.289 169.845 138.181 164.72 138.181Z" fill="#FFC258" />
        </svg>

    )
}

export default FolderIcon