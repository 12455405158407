import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { confirmEmail, confirmUserInfo } from "../../service/service";
import toast from "react-hot-toast";

const useForm = () => {
  const [flagLoaderButton, setFlagLoaderButton] = React.useState(false);
  var { verificationKey } = useParams();
  const [values, setValues] = useState("");
  useEffect(() => {
    confirmUserInfoApi();
  })

  const submitUserForm = (e) => {
    e.preventDefault();
    confirmEmailAPI();
  };

  async function confirmEmailAPI() {
    setFlagLoaderButton(true);
    const res = await confirmEmail(verificationKey);
    if (!res.data) {
      if (res.response && res.response.data) {
        let message = res.response.data.message;
        if (message === "Expiry Date Crossed") {
          toast.error("Account Confirmation Link Expired", { id: 'error' })
        } else {
          toast.error(message, { id: 'error' })
        }
      } else {
        toast.error('Something went wrong!!!', { id: 'error' })
      }
    } else {
      window.location.href = `/emailVerified/${verificationKey}`;
      toast.success("Email Confirmed Successsfully", { id: 'success' })
      verificationKey = "";
    }
    setFlagLoaderButton(false);
  }

  async function confirmUserInfoApi() {
    const res = await confirmUserInfo(verificationKey);
    if (res.data != null) {
      const name = res.data.name;
      setValues(name);
    } else {
      toast.error("Something went wrong!!!", { id: 'error' })
    }
  }

  return { submitUserForm, values, flagLoaderButton };
};

export default useForm;
