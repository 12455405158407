import React from 'react'

function DeleteIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2917 2.75H8.70835L7.79168 3.66667H4.58334V5.5H17.4167V3.66667H14.2083L13.2917 2.75ZM14.6667 8.25V17.4167H7.33335V8.25H14.6667ZM5.5 6.41666H16.5V17.4167C16.5 18.425 15.675 19.25 14.6667 19.25H7.33334C6.325 19.25 5.5 18.425 5.5 17.4167V6.41666Z" fill="#FE8083" />
        </svg>

    )
}

export default DeleteIcon