import React from 'react'

function WarningIcon({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="alert/warning_24px">
                <path id="icon/outlined/alert/warning_24px" fill-rule="evenodd" clip-rule="evenodd" d="M116.822 125.417C124.526 125.417 129.336 117.073 125.477 110.406L78.6545 29.5316C74.8028 22.8788 65.1976 22.8788 61.346 29.5316L14.5238 110.406C10.6641 117.073 15.4747 125.417 23.1781 125.417H116.822ZM70.0002 107.917C66.7786 107.917 64.1669 105.305 64.1669 102.083C64.1669 98.8617 66.7786 96.25 70.0002 96.25C73.2219 96.25 75.8336 98.8617 75.8336 102.083C75.8336 105.305 73.2219 107.917 70.0002 107.917ZM64.1669 78.75C64.1669 81.9717 66.7786 84.5834 70.0002 84.5834C73.2219 84.5834 75.8336 81.9717 75.8336 78.75V67.0834C75.8336 63.8617 73.2219 61.25 70.0002 61.25C66.7786 61.25 64.1669 63.8617 64.1669 67.0834V78.75Z" fill="#FFC258" />
            </g>
        </svg>

    )
}

export default WarningIcon