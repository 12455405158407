import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { addCommentTag, addIssueComment, closeIssue, deleteIssue, getDetailedIssue, getIssueList, listIssueComment, listTags } from '../../service/service';
import Navbar from '../ReusableComponents/Navbar'
import moment from 'moment';
import Tag from '../ReusableComponents/Tag';
import { Tooltip } from '@material-ui/core';
import { CloseOutlined, MoreVertOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { issueComments, issueList, selectIssueComments } from '../../slices/issueSlice';
import { selectUserDetails } from '../../slices/userSlice';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import toast from 'react-hot-toast';
import Loader from '../ReusableComponents/Loader';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import LikeIcon from '../../assets/icons/LikeIcon';
import Dislike from '../../assets/icons/Dislike';
import fileimg from '../../assets/images/Files.png'
import DownloadIcon from '../../assets/icons/DownloadIcon';
import { filePreview } from '../../slices/fileSlice';
import NoResults from '../../assets/images/NoResults.svg'
import TextEditor from '../ReusableComponents/TextEditor';
import draftToHtml from 'draftjs-to-html';
import UserImage from '../../assets/images/Default_User_Image.png';
import ContributorHover from '../ReusableComponents/ContributorHover';
import { useClickOutside } from '../../utils/helper';
import TagPopup from '../ReusableComponents/TagPopup';
import { preview } from '../../slices/projectSlice';

const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', 'jfif']

const initial = `{"blocks":[{"key":"31ak3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`

function IssueDetail() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { issueId, username, project, repo } = useParams();
    const [issueData, setIssueData] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const userData = useSelector(selectUserDetails)
    const comments = useSelector(selectIssueComments);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false)
    const [saving, setSaving] = useState(false)

    const [formData, setFormData] = useState({
        issue_id: issueId,
        comment: ''
    })
    const [more, setMore] = useState(false)
    const moreRef = useRef()
    const [tagIds, setTagIds] = useState([])
    const [tagList, setTagList] = useState([]);
    const [tagPopup, setTagPopup] = useState(false);
    const [tempTagList, setTempTagList] = useState([]);
    const [activeComment, setActiveComment] = useState(null)


    useClickOutside(() => setMore(false), moreRef)

    const getTagsList = async () => {
        const res = await listTags()
        if (res.data)
            setTagList(res.data)
    }

    useEffect(() => {
        getTagsList()
    }, [])

    const fetchComments = async () => {
        const issueCommentsRes = await listIssueComment(issueId);
        if (issueCommentsRes.data) {
            dispatch(issueComments(issueCommentsRes.data))
        }
    }
    const getIssueData = async () => {
        const res = await getDetailedIssue(issueId);
        if (res?.message == 'Invalid Issue') {
            setNotFound(true)
        }
        else if (res.success) {
            setIssueData(res.data);
            fetchComments()
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!isNaN(issueId)) {
            getIssueData();
        }
    }, [issueId]);

    useEffect(() => {
        if (issueData && userData) {
            if (issueData?.assignee?.length) {
                for (let us of issueData?.assignee) {
                    if (us.contributer_id == userData?.id) {
                        setIsAssigned(true)
                    }
                }
            }
            else if (issueData.author_id == userData?.id) {
                setIsAssigned(true)
            }
            else if (issueData?.assignee?.length == 0) {
                setIsAssigned(true)
            }
        }
    }, [issueData, userData])

    async function getFreshIssueList() {
        const issues = await getIssueList();
        dispatch(issueList(issues.data || []));
    }

    async function deleteIssueById(issueId) {
        await deleteIssue(issueId);
        setDeleteModal(false);
        history.push(`/${username}/${project}/${repo}/${issueData?.branchname}?issue=true`)
        getFreshIssueList()
    }

    const submitComment = async () => {
        if (!formData.comment && formData.comment != initial) return toast.error("Please add some comment");
        setSaving(true)
        const res = await addIssueComment(formData);
        if (res?.data && res?.success) {
            dispatch(issueComments([{ ...res.data, comment: formData.comment, profile_pic: userData?.profile_pic }, ...comments]))
            setFormData({
                issue_id: issueId,
                comment: "",
            })
        }
        setSaving(false)
    }

    const issueClose = async () => {
        const res = await closeIssue(issueId)
        if (res.success) {
            history.push(`/${username}/${project}/${repo}/${issueData?.branchname}?issue=true`)
        }
    }

    // useEffect(() => {
    //     if (issueData?.comment) {
    //         let tags = document.querySelectorAll('.commentText img');
    //         for (let tag of tags) {
    //             console.log(tag)
    //             tag.onclick = () => dispatch(filePreview({ url: tag.src, title: '', open: true }))
    //             tag.addEventListener('click', () => dispatch(filePreview({ url: tag.src, title: '', open: true })))
    //         }
    //     }
    // })

    const onTagClick = (id) => {
        if (tagIds.includes(id)) {
            let arr = [...tagIds];
            let idx = arr.findIndex(item => item == id);
            if (idx > -1) {
                arr.splice(idx, 1);
                setTagIds(arr);
            }

            let tagArr = [...tempTagList];
            let i = tagArr.findIndex(item => item.id == id);
            if (i > -1) {
                tagArr.splice(i, 1);
                setTempTagList(tagArr)
            }
        }
        else {
            setTagIds(pre => [...pre, id]);
            let item = tagList.find(item => item.id == id)
            setTempTagList(pre => [...pre, item]);
        }
    }

    function createMarkup(data) {
        return {
            __html: draftToHtml(JSON.parse(data))
        };
    }

    async function handleAddTag() {
        let tags = tempTagList.map(tag => { return { tag_name: tag.name, tag_color: tag.color, tag_description: tag.description } });
        if (tags.length && activeComment) {
            let data = { comment_id: activeComment.id, username: username, issue_id: activeComment.issue_id, profile_pic: userData.profile_pic, user_id: userData.id, tags: tags }
            let res = await addCommentTag(data);
            if (res.success && res.data?.length) {
                fetchComments();
                setMore(false);
                setTagPopup(false);
            }
            setActiveComment(null);
            setTagIds([])
            setTempTagList([])
        }
    }
    const clickTag = (comment) => {
        setActiveComment(comment);
        setTagPopup(true)
    }
    const handleFileClick = () => {
        if (extensions.includes(issueData?.file?.file_format?.toLowerCase())) {
            if (issueData?.file?.commitAttachment && issueData?.file?.commitAttachment !== 'undefined') {
                dispatch(filePreview({ url: issueData?.file?.commitAttachment, title: issueData?.file.name, open: true }));
            }
            else if (issueData?.file?.file_url && issueData?.file?.file_url !== 'undefined') {
                dispatch(filePreview({ url: issueData?.file?.file_url, title: issueData?.file.name, open: true }));
            }
        }
        else {
            if (issueData?.file?.file_format?.toLowerCase() == '.pdf' && issueData?.file?.file_url && issueData?.file?.file_url !== 'undefined') {
                dispatch(preview({ isOpen: true, pdfUrl: issueData?.file?.file_url, title: issueData?.file?.name }))
            }
        }
    }

    if (loading) return <Loader />

    return (
        <>
            <Navbar />

            {notFound ?
                <div className='max-w-3xl mx-auto pt-10'>
                    <img src={NoResults} className='mx-auto max-w-xs lg:max-w-md' alt="" />
                    <h1 className='text-3xl lg:text-4xl font-semibold text-gray-600 text-center mt-10 p-5'>Looks like, the issue you're searching does not exist </h1>
                </div>
                : <div className='p-5 xl:px-10 2xl:px-16'>
                    <h1><span className='text-gray-600 cursor-pointer hover:text-blue-500 pl-3 lg:pl-10' onClick={() => history.push('/dashboard')}>{"Dashboard >"}</span> <span className='text-gray-600 cursor-pointer hover:text-blue-500' onClick={() => history.push(`/${userData.username}/${issueData.project}`)}>{issueData.project}{" > "}</span ><span className='text-gray-800 font-semibold cursor-pointer hover:text-blue-500' onClick={() => history.push(`/${userData.username}/${issueData.project}/${issueData.repo}/${issueData?.branchname}`)}>{issueData.repo}</span><span className='text-white bg-blue rounded-full px-4 text-sm py-1 ml-4'>{issueData.repository_type === 1 ? 'Public' : 'Private'}</span> </h1>
                    <div className='flex items-start gap-10'>
                        <div className='w-full max-w-xs mx-10 hidden lg:flex flex-col justify-center gap-4 mt-6'>
                            <h1 className='text-2xl xl:text-3xl font-semibold'>{issueData.title}</h1>
                            <div className='flex items-center justify-between'>
                                {issueData.issue_status === 'Open' && <span className='rounded-full bg-blue text-white py-1 px-5 font-medium'>{issueData.issue_status}</span>}
                                {issueData.issue_status === 'Closed' && <span className='rounded-full bg-green text-white py-1 px-5 font-medium'>{issueData.issue_status}</span>}
                                <span>Updated {moment(issueData.updated_at).fromNow()}</span>
                            </div>
                            <div className='border rounded-lg bg-white shadow-md my-2 p-4'>
                                <p className='text-xl font-semibold text-gray-800'>Assignee {Array.isArray(issueData?.assignee)?.length > 0 ? `(${issueData?.assignee?.length})` : ''}</p>
                                <div className='flex flex-wrap items-center justify-start gap-6 '>
                                    {Array.isArray(issueData.assignee) && issueData.assignee?.map((details, i) => (
                                        <div className='relative group' key={i}>
                                            <ContributorHover data={{ name: details?.name, username: details?.username, profile_pic: details?.profile_pic }} />
                                            <img className='w-10 h-10 rounded-full my-1 mx-2 border' src={details?.profile_pic || UserImage} alt='' />
                                        </div>
                                    ))
                                    }
                                    {issueData?.assignee?.length < 1 && <h1 className='text-base text-gray-500 my-2 font-medium'>No Assignee</h1>}
                                </div>
                            </div>

                            <div className='border rounded-lg bg-white shadow-md p-4'>
                                <p className='text-xl font-semibold text-gray-800'>Tags {Array.isArray(issueData?.tags)?.length > 0 ? `(${issueData?.tags?.length})` : ''}</p>
                                <div className='flex flex-wrap items-center justify-start gap-6 p-2'>
                                    {issueData.tags?.map(tag => <Tag key={tag.id} text={tag?.name} color={tag?.color} />)}
                                </div>
                                {issueData?.tags?.length ? '' : <h1 className='text-base text-gray-500 my-2 font-medium'>No Tags</h1>}
                            </div>
                        </div>
                        <div className='flex-grow px-5'>
                            <div className='flex w-full justify-end transform -translate-y-6'>
                                <CloseOutlined fontSize='large' className='cursor-pointer' onClick={() => history.push(`/${username}/${project}/${repo}/${issueData?.branchname}?issue=true`)} />
                            </div>
                            <div className='flex items-center justify-between gap-4 border-b pb-2 mb-3'>
                                <h1 className='text-2xl font-semibold'>Comments</h1>
                                <div>
                                    {(issueData.author_id == userData?.id && issueData.issue_status !== 'Closed') && <button className='py-1.5 px-5 bg-blue text-white font-semibold rounded mr-4' onClick={() => history.push(`/${userData.username}/${project}/${repo}/${issueData?.file_id}/issue/${issueData?.id}/edit`)}>Edit Issue</button>}
                                    {(issueData.author_id == userData?.id && issueData.issue_status !== 'Closed') && <button className='py-1.5 px-5 bg-green text-white font-semibold rounded mr-4' onClick={() => setCloseModal(true)}>Close Issue</button>}
                                    {issueData.author_id == userData?.id && <button className='py-1.5 px-5 bg-red text-white font-semibold rounded' onClick={() => setDeleteModal(true)}>Delete Issue</button>}
                                </div>
                            </div>
                            <div className='flex gap-2 items-start z-20'>
                                {
                                    issueData?.author_pic ?
                                        <img className='w-10 h-10 rounded-full object-cover' src={issueData?.author_pic} alt="" />
                                        : <DefaultUserIcon className='w-10 h-10 rounded-full object-cover' />
                                }
                                <div className='flex-grow bg-white shadow-md rounded px-4 py-2 z-10'>
                                    <div className='flex items-center gap-2 w-full'>
                                        <span className='font-medium text-gray-900'>{issueData?.author}</span>
                                        <span className='text-gray-600 text-xs'>commented {moment(issueData.created_at).fromNow()}</span>
                                        <span className='flex-grow'></span>
                                        {issueData.author_id == userData?.id && <span className='border px-2 text-gray-600 rounded-2xl'>Author</span>}
                                        {/* <div ref={moreRef} className='relative'>
                                            <MoreVertOutlined color='action' className='cursor-pointer' onClick={() => setMore(!more)} />
                                            {more && <div className='py-2 px-4 bg-white shadow-lg rounded-md absolute top-8 right-1 min-w-max'>
                                                <h1 className='cursor-pointer' onClick={() => setTagPopup(true)}>Add Tag</h1>
                                            </div>}
                                        </div> */}
                                    </div>
                                    {
                                        (issueData.comment && draftToHtml(JSON.parse(issueData.comment)) != `<p></p>\n`) ?
                                            <div className='mt-2 mb-4 commentText' dangerouslySetInnerHTML={createMarkup(issueData.comment)} />
                                            :
                                            <p className='mt-2 mb-4 commentText'>no description was added for this issue</p>
                                    }
                                    {issueData?.file && <div className='flex flex-col items-baseline gap-2 pb-4'>
                                        <img
                                            className='w-8 h-8 object-contain'
                                            src={fileimg}
                                            alt='file-icon'
                                        />

                                        <div className='flex items-center gap-2'>
                                            <h1 className='text-sm font-semibold'>{issueData?.file?.name}</h1>
                                            {((issueData?.file?.file_url || issueData?.file?.commitattachment) && extensions.includes(issueData?.file?.file_format.toLowerCase())) && <svg onClick={handleFileClick} className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                                                <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
                                            </svg>}
                                            {(issueData.file.commitlink && issueData.file.commitlink != 'undefined') &&
                                                <a href={issueData.file?.commitlink} rel="noreferrer" target="_blank">  <svg className='z-20 cursor-pointer' width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
                                                    <g clip-path="url(#clip0_840_314)">
                                                        <path d="M9.77215 8.22823C9.3626 7.81884 8.80722 7.58887 8.22815 7.58887C7.64907 7.58887 7.0937 7.81884 6.68415 8.22823L5.13965 9.77222C4.73009 10.1818 4.5 10.7373 4.5 11.3165C4.5 11.8957 4.73009 12.4512 5.13965 12.8607C5.54921 13.2703 6.10469 13.5004 6.6839 13.5004C7.26311 13.5004 7.81859 13.2703 8.22815 12.8607L9.00015 12.0887" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                                                        <path d="M8.22852 9.77215C8.63807 10.1815 9.19344 10.4115 9.77252 10.4115C10.3516 10.4115 10.907 10.1815 11.3165 9.77215L12.861 8.22815C13.2706 7.81859 13.5007 7.26311 13.5007 6.6839C13.5007 6.10469 13.2706 5.54921 12.861 5.13965C12.4515 4.73009 11.896 4.5 11.3168 4.5C10.7376 4.5 10.1821 4.73009 9.77252 5.13965L9.00052 5.91165" stroke="#1D85E8" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_840_314">
                                                            <rect width="12" height="12" fill="white" transform="translate(3 3)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg></a>
                                            }
                                            <DownloadIcon onClick={async () => {
                                                if (issueData?.file?.type === 'file') {
                                                    let tId = toast.loading("Downloading file ...");
                                                    fetch(issueData?.file?.file_url, {
                                                        method: 'GET',
                                                        mode: 'cors',
                                                        cache: 'no-cache',
                                                        credentials: 'same-origin',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        redirect: 'follow',
                                                        referrerPolicy: 'no-referrer'
                                                    })
                                                        .then(resp => resp.blob())
                                                        .then(blob => {
                                                            const url = window.URL.createObjectURL(blob);
                                                            const a = document.createElement('a');
                                                            a.style.display = 'none';
                                                            a.href = url;
                                                            a.download = issueData?.file?.name;
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            window.URL.revokeObjectURL(url);
                                                            toast.success("File is downloaded ")
                                                        })
                                                        .catch(() => toast.error('Unable to download file at this moment'));
                                                    toast.dismiss(tId)
                                                }
                                            }} />
                                        </div>
                                    </div>}
                                    {/* <div className='flex items-center my-2'>
                                    <LikeIcon className={'w-5'} />
                                    <span className="text-xs text-gray-500">0</span>
                                    <Dislike className={'w-5 ml-4'} />
                                    <span className="text-xs text-gray-500">0</span>
                                </div> */}
                                </div>
                            </div>

                            {issueData.tags?.map((tag, i) => <div key={i} className={`relative w-full ml-7 flex items-baseline -mt-4`} style={{ transform: `translateY(-${i * 25}px)` }}>
                                <div className='relative'>
                                    <svg width="84" height="120" viewBox="0 0 30 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.00001 -4.63341e-06C1.00001 -4.63341e-06 1.00001 40.2758 1.00001 101.219C1.00002 162.163 83 144.675 83 144.675" stroke="#CFD8DC" stroke-width="2" />
                                    </svg>
                                    <span className='absolute left-4 bottom-4 z-20'>
                                        {
                                            issueData?.author_pic ?
                                                <img className='w-10 h-10 rounded-full object-cover' src={issueData?.author_pic} alt="" />
                                                : <DefaultUserIcon className='w-10 h-10 rounded-full object-cover' />
                                        }
                                    </span>
                                </div>
                                <div className='bg-white relative shadow-md p-4 rounded-lg flex items-center gap-4 w-full mr-7'>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="28" height="28" rx="14" fill="#EBEEF3" />
                                        <path d="M16.7033 21.805C16.5359 21.9725 16.337 22.1054 16.1182 22.1961C15.8994 22.2868 15.6648 22.3335 15.4279 22.3335C15.191 22.3335 14.9565 22.2868 14.7376 22.1961C14.5188 22.1054 14.32 21.9725 14.1525 21.805L7.02833 14.6808C6.69012 14.3427 6.50008 13.8841 6.5 13.4058V8.3025C6.5 7.30833 7.30833 6.5 8.30333 6.5H13.4058C13.8833 6.5 14.3425 6.69 14.6808 7.02833L21.805 14.1525C21.9725 14.32 22.1054 14.5188 22.1961 14.7376C22.2868 14.9565 22.3335 15.191 22.3335 15.4279C22.3335 15.6648 22.2868 15.8994 22.1961 16.1182C22.1054 16.337 21.9725 16.5359 21.805 16.7033L16.7033 21.805Z" stroke="#7F92A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9.41699 9.41602L9.83366 9.83268" stroke="#7F92A4" stroke-width="2" stroke-linecap="round" />
                                    </svg>

                                    <h1 className='font-medium text-gray-700'>{tag.user_name}</h1>
                                    <span className='text-gray-400'>added the</span>
                                    <span className='ml-4'>
                                        <Tag text={tag.name} color={tag.color} />
                                    </span>
                                    <span className='text-gray-400'>
                                        tag
                                    </span>
                                    <h1 className='ml-auto text-sm text-gray-300'>{moment(tag.created_at).fromNow()}</h1>
                                </div>
                            </div>)}

                            {issueData.issue_status === 'Closed' ?
                                <h1 className='text-3xl font-semibold text-gray-600 text-center my-10'>Issue "{issueData?.title}" is closed now</h1>
                                :
                                <>
                                    {
                                        (isAssigned || (issueData.author_id == userData?.id)) ?
                                            <div className='flex gap-2 items-start mt-6'>
                                                {
                                                    userData?.profile_pic ?
                                                        <img className='w-10 h-10 rounded-full' src={userData?.profile_pic} alt="" />
                                                        : <DefaultUserIcon className='w-10 h-10 rounded-full' />
                                                }
                                                <div className='flex-grow bg-white shadow-md rounded px-4 py-2'>
                                                    <h1 className='text-gray-500'>Comment as <span className='text-gray-900 font-semibold italic'>{userData?.username}</span></h1>
                                                    <TextEditor placeholder="Leave a comment" onChange={(data) => setFormData({ ...formData, comment: data })} loading={saving} />
                                                    <button onClick={submitComment} className='bg-blue text-white font-semibold py-1.5 px-5 my-6 text-lg rounded'>Comment</button>
                                                </div>
                                            </div>
                                            :
                                            <><h1 className='text-xl font-semibold text-gray-600 text-center my-10'>You are not allowed add comment to issue that are not assigned to you</h1></>
                                    }
                                </>}

                            <div className='space-y-6 my-6 flex-grow'>
                                {
                                    comments && comments?.map(comment => <CommentList key={comment.id} comment={comment} isAuthor={comment.user_id == issueData.author_id} createMarkup={createMarkup} clickTag={clickTag} isAssigned={issueData.issue_status === 'Open'} />)
                                }
                            </div>

                        </div>
                    </div>
                </div>}
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to delete "${issueData.title}"`} type="warn" onCancel={() => setDeleteModal(false)} onOk={() => deleteIssueById(issueData.id)} />
            <ConfirmModal open={closeModal} heading={`Are you sure you want to close "${issueData.title}"`} type="warn" onCancel={() => setCloseModal(false)} onOk={() => issueClose(issueData.id)} />
            {tagPopup && <TagPopup tagList={tagList} tagIds={tagIds} onTagClick={onTagClick} username={username} onCancel={() => { setActiveComment(null); setTagPopup(false) }} onSave={handleAddTag} />}

        </>
    )
}

export default IssueDetail


const CommentList = ({ comment, isAuthor, createMarkup, clickTag, isAssigned }) => {
    const [more, setMore] = useState(false);
    const moreRef = useRef()

    useClickOutside(() => setMore(false), moreRef)
    console.log(comment.metadata)
    return (
        <>
            <div style={{ margin: '50px 0' }}>
                <div key={comment.id} className='flex gap-2 items-start z-40 relative'>
                    {
                        comment?.profile_pic ?
                            <img className='w-10 h-10 rounded-full border-2 border-gray-100 shadow-md' src={comment?.profile_pic} alt="" />
                            : <DefaultUserIcon className='w-10 h-10 rounded-full' />
                    }
                    <div className='flex-grow bg-white shadow-md rounded px-4 py-2'>
                        <div className='flex items-center gap-2 w-full'>
                            <span className='font-medium text-gray-900'>{comment?.username}</span>
                            <span className='text-gray-600 text-xs'>commented {moment(comment.created_at).fromNow()}</span>
                            <span className='flex-grow'></span>
                            {isAuthor && <span className='border px-2 text-gray-600 rounded-2xl text-xs'>Author</span>}
                            {isAssigned && <div ref={moreRef} className='relative'>
                                <MoreVertOutlined color='action' className='cursor-pointer' onClick={() => setMore(!more)} />
                                {more && <div className='py-2 px-4 bg-white shadow-lg rounded-md absolute top-8 right-1 min-w-max'>
                                    <h1 className='cursor-pointer hover:text-blue-500' onClick={() => clickTag(comment)}>Add Tag</h1>
                                </div>}
                            </div>}
                        </div>
                        <div className='mt-2 mb-4 commentText' dangerouslySetInnerHTML={createMarkup(comment.comment)} />
                        {/* <div className='flex items-center my-2'>
                                            <LikeIcon className='w-5' />
                                            <span className="text-xs text-gray-500">0</span>
                                            <Dislike className='w-5 ml-4' />
                                            <span className="text-xs text-gray-500">0</span>
                                        </div> */}
                    </div>
                </div>
                {Boolean(comment.tags?.length) && comment.tags.map(tag => <div className='pl-14 -mt-4 flex items-end'>
                    <div className='max-w-max -mt-16'>
                        <svg width="84" height="148" viewBox="0 0 84 148" fill="none" xmlns="http://www.w3.org/2000/svg" className='z-0 relative'>
                            <path d="M1.00001 -4.63341e-06C1.00001 -4.63341e-06 1.00001 40.2758 1.00001 101.219C1.00002 162.163 83 144.675 83 144.675" stroke="#CFD8DC" stroke-width="2" />
                        </svg>
                    </div>
                    <div className='flex items-center gap-2 my-4 bg-white shadow-md rounded px-4 py-2 w-full transform translate-y-10 relative z-20'>
                        <div className='w-10 h-10 border-2 border-gray-100 shadow-md rounded-full overflow-hidden absolute bottom-6 -left-[6.3rem] bg-blue-400'>
                            <img src={tag.profile_pic} className="w-full h-full object-cover" alt="" />
                        </div>

                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="14" fill="#EBEEF3" />
                            <path d="M16.7033 21.805C16.5359 21.9725 16.337 22.1054 16.1182 22.1961C15.8994 22.2868 15.6648 22.3335 15.4279 22.3335C15.191 22.3335 14.9565 22.2868 14.7376 22.1961C14.5188 22.1054 14.32 21.9725 14.1525 21.805L7.02833 14.6808C6.69012 14.3427 6.50008 13.8841 6.5 13.4058V8.3025C6.5 7.30833 7.30833 6.5 8.30333 6.5H13.4058C13.8833 6.5 14.3425 6.69 14.6808 7.02833L21.805 14.1525C21.9725 14.32 22.1054 14.5188 22.1961 14.7376C22.2868 14.9565 22.3335 15.191 22.3335 15.4279C22.3335 15.6648 22.2868 15.8994 22.1961 16.1182C22.1054 16.337 21.9725 16.5359 21.805 16.7033L16.7033 21.805Z" stroke="#7F92A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.41699 9.41602L9.83366 9.83268" stroke="#7F92A4" stroke-width="2" stroke-linecap="round" />
                        </svg>
                        <p className='mr-4'>{tag.username} <span className='text-gray-400'>added the</span></p>
                        <Tag text={tag.tag_name} color={tag.tag_color} />
                        <p className='text-gray-400'>tag</p>
                        <p className='flex-grow text-slate-400 text-right'>{moment(tag.updated_at).fromNow()}</p>
                    </div>

                </div>)}

                {comment?.metadata ? JSON.parse(comment?.metadata)?.map((tag, i) => <div key={i} className={`relative w-full ml-7 flex items-baseline -mt-4`} style={{ transform: `translateY(-${i * 25}px)`, zIndex: 30 - i }}>
                    <div className='relative'>
                        <svg width="84" height="120" viewBox="0 0 30 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00001 -4.63341e-06C1.00001 -4.63341e-06 1.00001 40.2758 1.00001 101.219C1.00002 162.163 83 144.675 83 144.675" stroke="#CFD8DC" stroke-width="2" />
                        </svg>
                        <span className='absolute left-4 bottom-4 z-20'>
                            {
                                comment?.profile_pic ?
                                    <img className='w-10 h-10 rounded-full object-cover' src={comment?.profile_pic} alt="" />
                                    : <DefaultUserIcon className='w-10 h-10 rounded-full object-cover' />
                            }
                        </span>
                    </div>
                    <div className='bg-white relative shadow-md p-4 rounded-lg flex items-center gap-2 w-full mr-7'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 p-1.5 mr-2 bg-gray-200 rounded-full text-gray-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                        </svg>

                        <a className='font-medium text-gray-700 cursor-pointer hover:text-blue-500' href={`http://localhost:3000/userProfile/${tag.actionTo}`}>{tag.actionTo}</a>
                        <span className='text-gray-400'>{tag.action} assignee by</span>
                        <a className='font-medium text-gray-700 cursor-pointer hover:text-blue-500' href={`http://localhost:3000/userProfile/${tag.actionBy}`}>{tag.actionBy}</a>
                    </div>
                </div>) : <></>}
            </div>
        </>
    )
}