import React from 'react';
import TDesign from '../../assets/images/Topic Unselected/TDesign.svg'
import Architecture from '../../assets/images/Topic Unselected/Architecture.svg'
import GraphicDesign from '../../assets/images/Topic Unselected/GraphicDesign.svg'
import UIUXDesign from '../../assets/images/Topic Unselected/UIUXDesign.svg'
import Interaction from '../../assets/images/Topic Unselected/Interaction.svg'
import Motion from '../../assets/images/Topic Unselected/Motion.svg'
import ProductDesign from '../../assets/images/Topic Unselected/ProductDesign.svg'
import FashionDesign from '../../assets/images/Topic Unselected/FashionDesign.svg'
import GameDesign from '../../assets/images/Topic Unselected/GameDesign.svg'
import Crafts from '../../assets/images/Topic Unselected/Crafts.svg'

import TDesignSelected from '../../assets/images/Topic Selected/TDesignSelected.svg'
import ArchitectureSelected from '../../assets/images/Topic Selected/ArchitectureSelected.svg'
import GraphicDesignSelected from '../../assets/images/Topic Selected/GraphicDesignSelected.svg'
import UIUXDesignSelected from '../../assets/images/Topic Selected/UIUXDesignSelected.svg'
import InteractionSelected from '../../assets/images/Topic Selected/InteractionSelected.svg'
import MotionSelected from '../../assets/images/Topic Selected/MotionSelected.svg'
import ProductDesignSelected from '../../assets/images/Topic Selected/ProductDesignSelected.svg'
import FashionDesignSelected from '../../assets/images/Topic Selected/FashionDesignSelected.svg'
import GameDesignSelected from '../../assets/images/Topic Selected/GameDesignSelected.svg'
import CraftsSelected from '../../assets/images/Topic Selected/CraftsSelected.svg'

export const TopicsData = [
    {
      id: 1,
      icon: <img alt="" src={Interaction} />,
      title: 'Interaction',
      selectedIcon: <img alt="" src={InteractionSelected} />
    },
    {
      id: 2,
      icon: <img alt="" src={GraphicDesign} />,
      title: 'Graphic Design',
      selectedIcon: <img alt="" src={GraphicDesignSelected} />
    },
    {
      id: 3,
      icon: <img alt="" src={UIUXDesign} />,
      title: 'UI/UX Design',
      selectedIcon: <img alt="" src={UIUXDesignSelected} />
    },
    {
      id: 4,
      icon: <img alt="" src={Motion} />,
      title: 'Media & Entertainment',
      selectedIcon: <img alt="" src={MotionSelected} />
    },
    {
      id: 5,
      icon: <img alt="" src={Architecture} />,
      title: 'Architecture',
      selectedIcon: <img alt="" src={ArchitectureSelected} />
    },
    {
      id: 6,
      icon: <img alt="" src={TDesign} />,
      title: '3D Design',
      selectedIcon: <img alt="" src={TDesignSelected} />
    },
    {
      id: 7,
      icon: <img alt="" src={ProductDesign} />,
      title: 'Product Design',
      selectedIcon: <img alt="" src={ProductDesignSelected} />
    },
    {
      id: 8,
      icon: <img alt="" src={FashionDesign} />,
      title: 'Fashion Design',
      selectedIcon: <img alt="" src={FashionDesignSelected} />
    },
    {
      id: 9,
      icon: <img alt="" src={GameDesign} />,
      title: 'Game Design',
      selectedIcon: <img alt="" src={GameDesignSelected} />
    },
    {
      id: 10,
      icon: <img alt="" src={Crafts} />,
      title: 'Crafts',
      selectedIcon: <img alt="" src={CraftsSelected} />
    }
  ]