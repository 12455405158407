import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlanOptions } from './Upgrade'
import moment from 'moment'
import usePayment from '../../utils/usePayment'
import { downgradePlan } from '../../service/service'
import toast from 'react-hot-toast'
import ConfirmModal from '../ReusableComponents/ConfirmModal'

function ProUserAccount({ user, subscription }) {
    const [active, setActive] = useState(false)
    const history = useHistory()
    const [confirm, setConfirm] = useState(false);
    const { handleOrder } = usePayment();

    const downgrade = async () => {
        toast.promise(downgradePlan(), {
            loading: 'Downgrading...',
            success: () => window.location.reload(),
            error: 'Unable to downgrade'
        })
    }

    return (
        <>{confirm ? <ConfirmModal open={confirm} onCancel={() => setConfirm(false)} okText={'Downgrade'} type='warn' heading={'Are you sure to downgrade plan?'} onOk={() => downgrade()} /> : <></>}
            <section>
                <h1 className="text-2xl font-semibold text-left mb-3">Account Upgrades</h1>
                <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-xl font-medium text-blue'>You are a pro member of greyffiti</h1>
                        <svg width="61" height="46" viewBox="0 0 61 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8691 31.3838L38.4359 33.3321L40.331 23.3421L23.2453 4.7172L12.9124 5.50919L12.8691 31.3838Z" fill="#FFC258" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M40.3308 23.3417L12.8689 31.3834L23.2451 4.7168" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.6641 5.89648L38.3141 33.1064" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.9505 13.185L31.7862 14.0312L31.6123 25.8973" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M43.9175 28.4961C48.0153 24.737 48.4708 21.1205 45.284 17.6465C48.4708 21.1205 52.1131 20.9779 56.2109 17.2187C52.1131 20.9779 51.6576 24.5944 54.8445 28.0683C51.6576 24.5944 48.0153 24.737 43.9175 28.4961Z" fill="#DB614D" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div className='flex items-center gap-3 mt-1'>
                        <h1 className='text-lg'>Upcoming renewal date: </h1>
                        <div className='text-right'>
                            <h1>{moment(subscription?.expiry_date).format('DD/MM/YY')}</h1>
                            <p className='text-sm italic text-gray-500'>(Expiring {moment(subscription?.expiry_date).fromNow()})</p>
                        </div>
                        <div className='relative max-w-max ml-auto group'>
                            <span className='absolute -top-16 left-1/2 transform -translate-x-1/2 bg-white text-gray-800 text-sm font-medium py-1.5 px-3 rounded w-[210px] text-center hidden shadow group-hover:block z-10'>
                                Too early! come back before 1 month of expiry
                            </span>
                            <button className='px-10 py-1.5 rounded-lg outline-none bg-blue disabled:bg-gray-400 text-white' disabled={moment(subscription?.expiry_date).diff(moment(), 'days') > 31} onClick={() => handleOrder({ planId: 2 })}>Renew Now</button>
                        </div>
                    </div>
                    <h1 className='text-yellow text-right cursor-pointer underline'>view transaction history</h1>


                    <div className='w-full h-0.5 bg-gray-400 my-10' />

                    <div>
                        <h1 className='text-xl font-medium text-red'>Downgrade from pro to free</h1>
                        <div className='flex items-start gap-4 mt-1'>
                            <h1 className='text-red text-sm'>Projects which have exceeded the storage limit will no longer allow for new uploads
                                Contributors part of repositories will be made contributors for entire project
                                all contributors will be made as read and write</h1>
                            <button className='px-10 py-1.5 rounded-lg ml-4 outline-none bg-red disabled:bg-gray-400 text-white' onClick={() => { console.log("downgrade clicked"); setConfirm(true) }}>Downgrade</button>
                        </div>
                    </div>

                    <div className='w-full h-0.5 bg-gray-400 my-10' />

                    <div>
                        <h1 className='text-xl font-medium text-blue'>Change you account to team or company</h1>
                        <h1 className='text-blue text-sm my-1 max-w-xl'>Make sure you create a separate account if you wish to upgrade. Personal info will be lost otherview
                            All projects and repositories will stay as it is</h1>
                        <h1 className='text-blue font-medium cursor-pointer' onClick={() => history.push('/pricing')}>View pricing page for more info by clicking here</h1>
                        <button className='px-10 py-1.5 rounded-lg float-right outline-none bg-blue disabled:bg-gray-400 text-white'>Upgrade Account</button>
                    </div>
                </div>
            </section>
            {active && <PlanOptions active={active} setActive={setActive} />}
        </>
    )
}

export default ProUserAccount