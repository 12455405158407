import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react'

function LinkList({ fileData, setIsLink, getLinks, isAccess }) {


    const openLink = () => {
        let link = fileData.link
        if (!link.includes('http')) {
            link = `https://${link}`
        }
        window.open(link, 'blank')
    }
    return (
        <>
            <tr className='bg-white rounded-sm shadow transform hover:bg-gray-50'>
                <td onClick={() => openLink()} className="cursor-pointer">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 40C25.2116 39.9998 29.304 38.6008 32.6344 36.0227C35.9648 33.4447 38.3447 29.8335 39.4004 25.7563M21 40C16.7883 39.9998 12.696 38.6008 9.36561 36.0227C6.03519 33.4447 3.65528 29.8335 2.59956 25.7563M21 40C26.2461 40 30.5 31.4922 30.5 21C30.5 10.5078 26.2461 2 21 2M21 40C15.7539 40 11.5 31.4922 11.5 21C11.5 10.5078 15.7539 2 21 2M39.4004 25.7563C39.791 24.2363 40 22.6424 40 21C40.0052 17.7322 39.1637 14.5189 37.5574 11.6731M39.4004 25.7563C33.7704 28.8774 27.4372 30.5101 21 30.5C14.3247 30.5 8.05256 28.7794 2.59956 25.7563M2.59956 25.7563C2.20003 24.2025 1.99858 22.6044 2.00001 21C2.00001 17.6117 2.88667 14.4281 4.44256 11.6731M21 2C24.3698 1.9986 27.6794 2.89376 30.5891 4.59364C33.4987 6.29352 35.9038 8.73691 37.5574 11.6731M21 2C17.6302 1.9986 14.3206 2.89376 11.4109 4.59364C8.50125 6.29352 6.09621 8.73691 4.44256 11.6731M37.5574 11.6731C32.9608 15.6545 27.0811 17.8421 21 17.8333C14.6709 17.8333 8.88222 15.5111 4.44256 11.6731" stroke="#F6C36A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </td>
                <td className='font-medium text-gray-800 cursor-pointer' >
                    <p className='flex items-center gap-1'>
                        <Tooltip title={fileData.name}><span onClick={() => openLink()}>{fileData.name.length > 16 ? `${fileData.name.slice(0, 15)}...` : fileData.name}</span></Tooltip>
                    </p>
                    <p className='text-gray-500 font-light mt-3 text-xs'>Added {moment(fileData.created_at).fromNow()}</p>
                </td>
                <td className='max-w-sm'>
                    <p className='max-w-sm'>{(fileData.description && fileData.description !== "undefined") ? fileData.description : 'link added'}</p>
                </td>
                <td>{fileData?.username || ''}</td>
                <td className='capitalize'>{fileData.type || ''}</td>
                <td className='relative'>
                    <div className='flex flex-col items-center justify-center'>
                        <div className='flex items-end gap-2 cursor-pointer text-blue hover:text-blue-500 font-medium' onClick={() => openLink()}>
                            <span>Open Link</span>
                            <span>
                                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 4H3.25C2.65326 4 2.08097 4.23705 1.65901 4.65901C1.23705 5.08097 1 5.65326 1 6.25V16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H13.75C14.3467 19 14.919 18.7629 15.341 18.341C15.7629 17.919 16 17.3467 16 16.75V8.5M5.5 14.5L19 1M19 1H13.75M19 1V6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                        </div>
                        {isAccess ? <div className='flex items-end gap-2 cursor-pointer text-sm text-yellow hover:text-yellow-500 mt-2' onClick={() => setIsLink([fileData])}>
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.87156 2.43182L10.9512 1.35151C11.1763 1.12644 11.4816 1 11.7999 1C12.1182 1 12.4234 1.12644 12.6485 1.35151C12.8736 1.57658 13 1.88184 13 2.20014C13 2.51844 12.8736 2.8237 12.6485 3.04877L5.85242 9.84484C5.51407 10.183 5.09682 10.4315 4.63835 10.568L2.91997 11.08L3.43197 9.36165C3.56846 8.90318 3.81701 8.48593 4.15516 8.14758L9.87156 2.43182ZM9.87156 2.43182L11.5599 4.12012M10.5999 8.52006V11.56C10.5999 11.9419 10.4482 12.3082 10.1781 12.5782C9.90806 12.8483 9.5418 13 9.15989 13H2.43998C2.05807 13 1.69181 12.8483 1.42176 12.5782C1.15171 12.3082 1 11.9419 1 11.56V4.84011C1 4.4582 1.15171 4.09194 1.42176 3.82189C1.69181 3.55184 2.05807 3.40013 2.43998 3.40013H5.47994" stroke="#F7CA7D" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span>Edit Link</span>
                        </div> : <></>}

                    </div>
                    {/* <p className={`text-gray-500 font-light text-xs mt-3 text-center`}>Updated {moment(fileData.updated_at).fromNow()}</p> */}
                </td>
            </tr>
        </>
    )
}

export default LinkList