import React from 'react'
import './Loader.css'
import HeartIcon from '../../assets/images/heartIcon.svg'
import SprayIcon from '../../assets/images/sprayIcon.svg'

function NewLoader() {
    return (
        <>
            <div className='fixed w-full min-h-screen inset-0 bg-white flex flex-col items-center justify-center' style={{ zIndex: 50001 }}>
                <h1 className='-mt-20 mb-10 text-2xl font-medium text-navy select-none'>Loading ...</h1>
                <div className='relative select-none'>
                    <img src={HeartIcon} className="heart" alt='' />
                    <img src={SprayIcon} className="spray " alt='' />
                </div>
            </div>
        </>
    )
}

export default NewLoader

// https://codepen.io/Valgo/pen/PowZaNY

// https://codepen.io/toshiya-marukubo/pen/dypmMQq

// https://codepen.io/austinmzach/pen/nPJOBO