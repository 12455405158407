import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React,{useState , useEffect} from 'react'
import toggle_off_24px from '../../assets/images/toggle_off_24px.svg'
import toggle_on_24px from '../../assets/images/toggle_on_24px.svg'
import Restore from './components/Restore'
import Save from './components/Save'
import useStyle from './Styling'

function Privacy(props) {
    const classes = useStyle();
    const [data, setData] = useState({
        private_repository: false, 
        manage_data: false, 
        manage_activity: false, 
        public_account: "false"
    })
    const [settingsType, setSettingsType] = useState("privacy")
    const [privateRepo, setPrivateRepo] = useState("")
    const [publicRepo, setPublicRepo] = useState("")
    const [manageData, setManageData] = useState("")
    const [manageActivity, setManageActivity] = useState("")

   useEffect (()=>{
    setOldValue()
   }, []) 

   const restoreUpdate =() =>{
        setPrivateRepo("false")
        setPublicRepo("false")
        setManageData("false")
        setManageActivity("false")
}

   const setOldValue  = () =>{
       const settingsData = props.settingsListOld.data
        if (settingsData !== undefined){
            settingsData.map(function (item) {
                if (item.setting_type === "privacy") {
                    setSettingsType(item.setting_type)
                    if(item.setting_key == "private_repository"){
                        setData({
                        private_repository: item.key_value
                        })
                        setPrivateRepo(item.key_value)
                    }
                    if(item.setting_key == "public_account"){
                        setData({
                            public_account: item.key_value
                        })
                        setPublicRepo(item.key_value)
                    }
                    if(item.setting_key == "manage_data"){
                        setData({
                            manage_data: item.key_value
                        })
                        setManageData(item.key_value)
                    }
                    if(item.setting_key == "manage_activity"){
                        setData({
                            manage_activity: item.key_value
                        })
                        setManageActivity(item.key_value)
                    }
                };
            })
        } 
   }


    const toggleFunctionPublic = (value) => {
        setPublicRepo(value)
        setData({
            private_repository:privateRepo,
            public_account: value,
            manage_data: manageData,
            manage_activity: manageActivity,
        })
    }

    const toggleFunctionPrivate = (value) => {
        setPrivateRepo(value)
        setData({
            private_repository: value,
            public_account: publicRepo,
            manage_data: manageData,
            manage_activity: manageActivity
        })
    }

    const toggleFunctionData = (value) => {
        setManageData(value)
        setData({
            private_repository: privateRepo,
            public_account: publicRepo,
            manage_data: value,
            manage_activity: manageActivity
        })
        
    }

    const toggleFunctionActivity = (value) => {
        setManageActivity(value)
        setData({
            private_repository: privateRepo,
            public_account: publicRepo,
            manage_data: manageData,
            manage_activity: value
        })
    }
    return (
        <div >
            <List className={classes.listBox}>
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Public account info
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                    {publicRepo ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> toggleFunctionPublic("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> toggleFunctionPublic("true")}/>
                    }
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Private repositroy
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                    {privateRepo ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> toggleFunctionPrivate("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> toggleFunctionPrivate("true")}/>
                    }
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Manage data
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                    {manageData ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> toggleFunctionData("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> toggleFunctionData("true")}/>
                    }
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem className={classes.listContent}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    className={classes.title}>
                                    Manage activity
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                            </React.Fragment>
                        }>
                    </ListItemText>
                    <ListItemIcon >
                        {manageActivity ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> toggleFunctionActivity("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> toggleFunctionActivity("true")}/>
                    }
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem>
                    <Restore type={settingsType} 
                             getSettingsList={props.getSettingsList} 
                             restoreUpdate={restoreUpdate}
                             alertMsg={props.alertMsg}/>
                    <Save data={data} 
                          getSettingsList={props.getSettingsList}
                          alertMsg={props.alertMsg}/>
                </ListItem>
            </List>
        </div>
    )
}

export default Privacy
