import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Navbar from '../ReusableComponents/Navbar'
import { CloseOutlined, SearchOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails } from '../../slices/userSlice';
import { selectRepoContributors } from '../../slices/repoSlice';
import { createIssue, fileDetail, getAllFilesList, getBranchList, getIssueList, listTagsByRepo } from '../../service/service';
import toast from 'react-hot-toast';
import { issueList } from '../../slices/issueSlice';
import AddAssigneeForm from './addAssigneeForm';
import { TextField, Tooltip } from '@material-ui/core';
import { useRef } from 'react';
import { useClickOutside } from '../../utils/helper';
import fileimg from '../../assets/images/Files.png'
import FolderIcon from '../../assets/icons/FolderIcon';
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon';
import TextEditor from '../ReusableComponents/TextEditor';
import Tag from '../ReusableComponents/Tag';
import TagPopup from '../ReusableComponents/TagPopup';

function CreateIssue() {
    const dispatch = useDispatch()
    const cancelRef = useRef()
    const branchRef = useRef();
    const history = useHistory()
    const { username, project, repo, fileId } = useParams()
    const userData = useSelector(selectUserDetails);
    const [projectId, setProjectId] = useState(null);
    const [repoId, setRepoId] = useState(null);
    const [branchId, setBranchId] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const repoContributors = useSelector(selectRepoContributors);
    const [assigneeIds, setAssigneeIds] = useState([])
    const [tagIds, setTagIds] = useState([])
    const [assigneePopUp, setAssigneePopUp] = useState(false)
    const [filePopUp, setFilePopUp] = useState(false);
    const [isClicked, setIsClicked] = useState(false)
    const [rootFolder, setRootFolder] = useState(null);
    const [fileSearch, setFileSearch] = useState('');
    const [branches, setBranches] = useState([]);

    const [formData, setFormData] = useState({
        title: '',
        comment: '',
        profile_pic: userData?.profile_pic
    });

    const [fileDetails, setFileDetails] = useState(false)
    const [folderList, setFolderList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();
    const [tagList, setTagList] = useState([]);
    const [tagPopup, setTagPopup] = useState(false);
    const [tempTagList, setTempTagList] = useState([])
    const [lastFolder, setLastFolder] = useState([]);
    const [files, setFiles] = useState([]);

    useClickOutside(() => isClicked ? setIsClicked(false) : setFilePopUp(false), isClicked ? branchRef : cancelRef)

    const getTagsList = async () => {
        const res = await listTagsByRepo(repoId)
        if (res.data)
            setTagList(res.data)
    }

    useEffect(() => {
        if (repoId) {
            getTagsList()
        }
    }, [repoId])

    async function getFreshIssueList() {
        const issueData = await getIssueList();
        dispatch(issueList(issueData.data || []));
    }

    const getFiles = async () => {
        const folders = await getAllFilesList(Number(selectedBranch?.id));
        setFiles(folders.data)
        setFolderList(folders.data)
    }

    const fetchBranchList = async () => {
        const res = await getBranchList({ repo, project });
        if (res.data) {
            setBranches(res.data)
        }
    }

    const getFileInfo = async () => {
        const res = await fileDetail(fileId);
        if (res.data) {
            setFileDetails(res.data);
        }
    }
    useEffect(() => {
        if (repo) {
            fetchBranchList()
        }
    }, [repo]);

    useEffect(() => {
        if (branches?.length) {
            let branch = branches[0];
            setBranchId(branch?.id);
            setProjectId(branch?.project_id)
            setSelectedBranch(branch);
            setRepoId(branch?.repo_id);
            setOwnerId(branch?.user_id);
        }
    }, [branches]);

    useEffect(() => {
        if (fileDetails) {
            let branch = branches.find((item) => item.id == fileDetails.branch_id);
            if (branch) {
                setBranchId(branch?.id);
                setSelectedBranch(branch)
            }
        }
    }, [fileDetails])


    function getFileDetailsFunction(folderList) {
        for (let i = 0; i < folderList?.length; i++) {
            if (folderList[i].type == 'folder') {
                getFileDetailsFunction(folderList[i].children)
            }
            else {
                if (folderList[i].file_id == fileId) {
                    setFileDetails(folderList[i]);
                    break;
                }
            }
        }
    }

    useEffect(() => {
        if (!isNaN(fileId)) {
            getFileInfo();
        }
    }, [fileId])

    useEffect(() => {
        if (selectedBranch) {
            getFiles();
        }
    }, [selectedBranch])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value })
    }
    const onSubmit = async () => {

        if (!formData.title || !formData.comment) {
            return toast.error("Please fill the all details", { id: 'error' })
        }
        // console.log(assigneeIds, ownerId)
        // let updatedAssignee = userData?.id == ownerId ? [...assigneeIds] : [...assigneeIds, ownerId];
        let updatedAssignee = [...assigneeIds];
        // console.log(updatedAssignee)

        const _formData = {
            file_id: fileDetails?.file_id || fileDetails?.id,
            type: fileDetails.type || 'file',
            contributor: updatedAssignee && updatedAssignee.length > 0 ? updatedAssignee.toString() : "",
            assignee: updatedAssignee && updatedAssignee.length > 0 ? updatedAssignee.toString() : "",
            tags: tagIds && tagIds.length > 0 ? tagIds.toString() : "",
            title: formData.title,
            comment: formData.comment,
            project_id: projectId,
            repo_id: repoId,
            branch_id: selectedBranch?.id
        }
        const res = await createIssue(_formData);
        if (res?.success) {
            toast.success("Issue Created");
            getFreshIssueList();
            history.push(`/${username}/${project}/${repo}/${selectedBranch?.name}?issue=true`)
        }
    }

    const handlePopupClose = (type) => {
        if (type == 'cancel') {
            setAssigneeIds([])
        }
        setAssigneePopUp(false)
    }

    const onTagClick = (id) => {
        if (tagIds.includes(id)) {
            let arr = [...tagIds];
            let idx = arr.findIndex(item => item == id);
            if (idx > -1) {
                arr.splice(idx, 1);
                setTagIds(arr);
            }

            let tagArr = [...tempTagList];
            let i = tagArr.findIndex(item => item.id == id);
            if (i > -1) {
                tagArr.splice(i, 1);
                setTempTagList(tagArr)
            }
        }
        else {
            setTagIds(pre => [...pre, id]);
            let item = tagList.find(item => item.id == id)
            setTempTagList(pre => [...pre, item]);
        }
    }

    function getFolderDetailsFunction(folderList, last) {
        for (let i = 0; i < folderList?.length; i++) {
            if (folderList[i].type == 'folder') {
                if (folderList[i]?.folder_id == last) {
                    setRootFolder(folderList[i]);
                    setFolderList(folderList[i].children);
                    break;
                }
                else if (folderList[i].children)
                    getFolderDetailsFunction(folderList[i].children, last);

            }
        }
    }

    const backFolder = () => {
        if (lastFolder.length > 1) {
            let arr = [...lastFolder]
            arr.pop();
            let last = arr[arr.length - 1];
            getFolderDetailsFunction(files, last)
            setLastFolder(arr)
        }
        else {
            setRootFolder(null)
            setFolderList(files)
            setLastFolder([])
        }
    }

    return (
        <>
            <Navbar />
            <div className='p-5 w-full bg-white '>
                <div className='flex items-start gap-10 2xl:gap-x-16 justify-center'>
                    <div className='w-full max-w-xs flex flex-col justify-center gap-4'>
                        <div className='flex items-center gap-2 flex-wrap text-gray-500 transform -translate-y-4'>
                            <span className=' font-semibold text-gray-900 hover:text-blue-500 cursor-pointer' onClick={() => history.push(`/${username}`)}>Project {" >"}</span>
                            <span className='font-medium text-gray-500 hover:text-blue-500 cursor-pointer' onClick={() => history.push(`/${username}/${project}`)}>{project} {" >"}</span>
                            <span className='text-gray-500 font-medium hover:text-blue-500 cursor-pointer' onClick={() => history.push(`/${username}/${project}/${repo}/${selectedBranch?.name}`)}>{repo}</span>
                        </div>
                        <h1 className='text-2xl xl:text-3xl font-semibold'>Create New Issue</h1>

                        <div className='border rounded-lg bg-white shadow-md my-2 p-4'>
                            <p className='text-xl font-semibold text-gray-800'>Assignee</p>

                            <div className='flex flex-wrap items-center justify-start gap-2 my-2 '>
                                {repoContributors.map(ctr => assigneeIds.includes(ctr.contributer_id) && <Tooltip title={ctr.contributor_name}>
                                    {
                                        ctr.profile_pic ?
                                            <img className='w-10 h-10 rounded-full object-cover' src={ctr.profile_pic} alt='' />
                                            : <DefaultUserIcon className='w-10 h-10 rounded-full object-cover' />
                                    }
                                </Tooltip>)}
                            </div>
                            <p className='text-right my-2'>
                                <span className='text-blue cursor-pointer' onClick={() => setAssigneePopUp(true)}>Add/Remove</span>
                            </p>
                        </div>

                        <div className='border rounded-lg bg-white shadow-md p-4'>
                            <p className='text-xl font-semibold text-gray-800'>Tags</p>
                            <div className='flex flex-wrap items-center justify-start gap-6 my-4 px-2'>
                                {tempTagList?.map(tag => <Tag key={tag.id} text={tag.name} color={tag.color} />)}
                            </div>
                            <p className='text-right my-2'>
                                <span className='text-blue cursor-pointer' onClick={() => setTagPopup(true)}>Add/Remove</span>
                            </p>
                        </div>
                    </div>
                    <div className='flex-grow max-w-5xl'>
                        <div className='flex w-full justify-end transform -translate-y-6'>
                            <CloseOutlined fontSize='large' className='cursor-pointer' onClick={() => history.goBack()} />
                        </div>
                        <div className='flex gap-2 items-start'>
                            {
                                userData?.profile_pic ?
                                    <img className='w-10 h-10 rounded-full shadow-lg' src={userData?.profile_pic} alt="" />
                                    : <DefaultUserIcon className='w-10 h-10 rounded-full shadow-lg' />
                            }
                            <div className='flex-grow bg-white shadow-md rounded px-4 py-2'>
                                <div className='mb-6 w-full'>
                                    <TextField size="small" label="Issue Title" name="title" id="title" value={formData.title} onChange={handleChange} variant="outlined" className={`w-full`} />
                                </div>

                                <TextEditor placeholder="Leave a description about the issue" onChange={(data) => {
                                    let e = { target: { value: data, id: 'comment' } };
                                    handleChange(e);
                                }} />

                                {fileDetails ?
                                    <div className='flex items-center gap-4 mt-4'> <div>
                                        <img
                                            className='w-10 h-10 object-contain'
                                            src={fileimg}
                                            alt='file-icon'
                                        />
                                        <h1 className='text-xs font-semibold text-gray-600'>{fileDetails.name}</h1>
                                    </div>
                                        <button onClick={() => setFilePopUp(true)} className='bg-yellow text-white font-semibold py-1.5 px-5 rounded-sm'>Change File</button>
                                    </div> :
                                    <button onClick={() => setFilePopUp(true)} className='bg-yellow text-white font-semibold py-1.5 px-5 rounded-sm block mt-4'>Select file from Repository</button>
                                }
                                <p className='text-gray-600 text-lg mt-3 -mb-1'><span className='font-medium'>Branch:</span> {selectedBranch?.name}</p>
                                <button onClick={onSubmit} className='bg-blue text-white font-semibold py-2 px-5 my-6 text-lg rounded-sm'>Create Issue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {filePopUp && <div className='fixed w-full h-screen top-0 left-0 bg-black grid place-items-center bg-opacity-50' style={{ zIndex: 1000 }}>
                <div className='max-w-xl bg-white p-5 w-full rounded-md flex flex-col h-[70vh] overflow-y-auto' ref={cancelRef}>
                    <div className='flex w-full items-center justify-between border-b pb-1 mb-4'>
                        <h1 className='text-xl font-semibold text-gray-900'>Choose file</h1>
                        <CloseOutlined fontSize='large' className='cursor-pointer' onClick={() => { setFilePopUp(false); getFiles(); setRootFolder(null); setFileSearch('') }} />
                    </div>
                    <div className='flex items-center mb-4 gap-4'>
                        <div className='flex-grow p-2 border rounded-lg bg-white flex w-full'>
                            <SearchOutlined />
                            <input type="text" value={fileSearch} placeholder='Search files here...' onChange={(e) => setFileSearch(e.target.value)} className="flex-grow outline-none border-none px-2" />
                        </div>
                        <div ref={branchRef} className='bg-blue text-white relative rounded-lg py-2 px-4 flex items-center gap-1 cursor-pointer'>
                            <span onClick={() => setIsClicked(true)}>{selectedBranch?.name}</span>
                            <svg onClick={() => setIsClicked(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                            {isClicked && <div className='absolute top-11 left-0 p-2 popup-bg text-gray-900 w-full rounded-lg shadow-lg font-medium'>
                                {branches?.map(branch => <p onClick={() => { setSelectedBranch(branch); setIsClicked(false) }} key={branch.id}>{branch.name}</p>)}
                            </div>}
                        </div>
                    </div>
                    {rootFolder && <div className='w-full border-b border-dashed mb-4 pb-4 flex items-center gap-2'>
                        <span onClick={backFolder} className='cursor-pointer text-gray-600 hover:text-gray-800'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </span>
                        <div className='bg-gray-100 shadow py-1.5 px-2 rounded cursor-pointer max-w-max'>
                            <FolderIcon className='w-10 h-10 object-contain cursor-pointer' />
                            <h1 className='text-xs text-center font-semibold text-gray-600'>{rootFolder?.name}</h1>
                        </div>
                    </div>}
                    <div className='flex items-start flex-wrap gap-6'>
                        {selectedBranch && folderList.map((fileData, i) => {
                            return fileData?.name?.toLowerCase()?.includes(fileSearch?.toLowerCase()) && fileData?.repo_id == repoId && <div onClick={() => { fileData.type === 'file' ? setFileDetails(fileData) : setFolderList(fileData.children); fileData.children ? setRootFolder(fileData) : console.log("file selected"); if (fileData?.folder_id) { setLastFolder((prev) => [... new Set([...prev, fileData.folder_id])]) } }} key={i} className={`${((fileDetails?.file_id == fileData.file_id || fileDetails?.id == fileData.file_id) && fileDetails) ? 'bg-gray-100 shadow' : ''} p-1.5 rounded cursor-pointer`}>
                                {fileData.type === 'file' ? (
                                    <img
                                        className='w-10 h-10 object-contain'
                                        src={fileimg}
                                        alt='file-icon'
                                    />
                                ) : (
                                    <FolderIcon className='w-10 h-10 object-contain cursor-pointer' />
                                )}
                                <h1 className='text-xs text-center font-semibold text-gray-600' title={fileData?.name}>{fileData?.name?.length > 12 ? `...${fileData?.name?.slice(fileData?.name?.length - 12, fileData?.name?.length)}` : fileData?.name}</h1>
                            </div>
                        }
                        )}
                    </div>
                    {folderList.length ? <></> : <h1 className='font-semibold text-xl text-center my-6 text-gray-500'>No Files available in selected branch</h1>}
                    <button type='submit' className='bg-blue mt-auto py-1.5 px-6 rounded text-lg text-white' onClick={() => { setFilePopUp(false); getFiles(); setRootFolder(null); setFileSearch('') }}>
                        Continue
                    </button>
                </div>
            </div>}
            {assigneePopUp && <AddAssigneeForm assignee={repoContributors} setAssigneeIds={setAssigneeIds} assigneeIds={assigneeIds} handlePopupClose={handlePopupClose} />}

            {tagPopup && <TagPopup tagList={tagList} tagIds={tagIds} onTagClick={onTagClick} username={username} project={project} showCreate={selectedBranch?.project_owner == userData?.id} onCancel={() => setTagPopup(false)} onSave={() => setTagPopup(false)} />}
        </>
    )
}

export default CreateIssue
