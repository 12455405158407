import React from 'react'

function DribbbleIcon({ className }) {
    return (
        <svg className={className} width="586" height="565" viewBox="0 0 586 565" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M293 0C131.246 0 0 126.543 0 282.5C0 438.458 131.246 565 293 565C454.436 565 586 438.458 586 282.5C586 126.543 454.436 0 293 0ZM486.532 130.22C521.489 171.277 542.464 223.671 543.099 280.355C534.837 278.824 452.211 262.584 368.951 272.695C367.045 268.712 365.455 264.422 363.549 260.133C358.464 248.491 352.745 236.541 347.024 225.204C439.181 189.049 481.13 136.96 486.532 130.22ZM293 41.6703C356.557 41.6703 414.712 64.6503 458.886 102.337C454.436 108.465 416.619 157.183 327.638 189.354C286.644 116.738 241.201 57.2966 234.21 48.1046C252.958 43.815 272.662 41.6703 293 41.6703ZM186.542 64.3438C193.215 72.923 237.705 132.671 279.335 203.755C162.389 233.783 59.1085 233.17 47.9859 233.17C64.193 158.408 116.628 96.2093 186.542 64.3438ZM42.5835 282.807C42.5835 280.355 42.5835 277.904 42.5835 275.453C53.3883 275.759 174.783 277.291 299.673 241.137C306.983 254.617 313.656 268.405 320.013 282.193C316.834 283.113 313.338 284.033 310.161 284.951C181.139 325.089 112.497 434.78 106.777 443.972C67.0531 401.383 42.5835 344.699 42.5835 282.807ZM293 523.942C235.162 523.942 181.774 504.946 139.509 473.08C143.958 464.195 194.803 369.824 335.901 322.333C336.536 322.025 336.855 322.025 337.49 321.72C372.764 409.655 387.066 483.497 390.878 504.639C360.688 517.201 327.638 523.942 293 523.942ZM432.508 482.579C429.967 467.871 416.619 397.4 383.887 310.688C462.381 298.738 531.022 318.349 539.603 321.107C528.799 388.208 488.757 446.117 432.508 482.579Z" fill="#092C4C" />
        </svg>

    )
}

export default DribbbleIcon