import { configureStore } from "@reduxjs/toolkit";
import counterReducer from './slices/counterSlice'
import userReducer from './slices/userSlice'
import projectReducer from './slices/projectSlice'
import repoReducer from './slices/repoSlice'
import fileReducer from './slices/fileSlice'
import routeReducer from './slices/routeSlice'
import issueReducer from './slices/issueSlice'

export const store = configureStore({
    reducer: { counter: counterReducer, user: userReducer, project: projectReducer, repo: repoReducer, file: fileReducer, route: routeReducer, issue: issueReducer }
})