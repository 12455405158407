import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../../assets/images/Greyffiti-White-Logo.png";

function Thankyou() {
    return (
        <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
        <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
        <div className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
            <h1 className="text-3xl md:text-4xl font-bold text-blue text-center">Thank you!</h1>
            <Link to="/login"><button type='submit' className='bg-blue py-3 px-6 mt-10 text-lg text-white font-semibold rounded-lg'>
                Go Home
            </button>
            </Link>
        </div>
    </div>
    )
}

export default Thankyou
