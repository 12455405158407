import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userBasicInfo: null,
    userDetails: null,
    allUsers: null,
    plan: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userBasicInfo: (state, action) => {
            state.userBasicInfo = action.payload
        },
        userDetails: (state, action) => {
            state.userDetails = action.payload
        },
        allUsers: (state, action) => {
            state.allUsers = action.payload
        },
        userPlan: (state, action) => {
            state.allUsers = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { userBasicInfo, userDetails, allUsers, userPlan } = userSlice.actions

export const selectUserBasicInfo = (state) => state.user.userBasicInfo
export const selectUserDetails = (state) => state.user.userDetails
export const selectAllUsers = (state) => state.user.allUsers
export const selectUserPlan = (state) => state.user.userPlan

export default userSlice.reducer