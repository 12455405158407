import React from 'react'
import Peoples from '../../assets/images/Peoples.svg'
import RocketWithDB from '../../assets/images/RocketWithDB.svg'
import RocketWithCloud from '../../assets/images/RocketWithCloud.svg'
import Diamond from '../../assets/images/Diamond.svg'
import BuildingWithDB from '../../assets/images/BuildingWithDB.svg'
import BuildingWithCloud from '../../assets/images/BuildingWithCloud.svg'
import UserImageWithBorder from '../../assets/images/UserImageWithBorder.svg'
import { useState } from 'react'
import Navbar from './Navbar'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserBasicInfo } from '../../slices/userSlice'
import logo from '../../assets/images/logo.png'
import { MenuItem, TextField } from '@material-ui/core'

const plans = [
    {
        id: 7,
        name: 'Free',
        icon: UserImageWithBorder,
        price: 0,
        desc: [
            {
                title: 'Public Projects',
                desc: [
                    'Storage per project 1 gb',
                    'Number of collaborators per project 5',
                    'Cannot Invite users per repository without inviting user to project'
                ]
            },
            {
                title: 'Private Projects',
                desc: [
                    'Storage per project 1 gb',
                    'Number of collaborators per project 3'
                ]
            },
            'Cannot Invite users per repository without inviting user to project',
            'Version history restore 5 version back',
            `60% revenue from marketplace's paid assets and plugins`,
            'Up to 5 users support in discussion boards for private repos',
            'Discussion boards with upto 3 contributors'

        ]
    },
    {
        id: 1,
        name: 'Pro',
        icon: Diamond,
        price: 15,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Storage per project 15 gb',
                    'Number of collaborators per project 15'
                ]
            },
            'Version history restore 15 version back',
            `70% revenue from marketplace's paid assets and plugins`,
            'Up to 5 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Discussion boards',
            'Swiftlet pro subscription',
            'Freelancing opportunity'
        ]
    },
    {
        id: 2,
        name: 'Teams',
        icon: Peoples,
        price: 20,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Storage per project 50 gb',
                    'Number of collaborators per project 20'
                ]
            },
            'Version history restore 20 version back',
            `70% revenue from marketplace's paid assets and plugins`,
            'Up to 10 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Swiftlet pro subscription'
        ]
    },
    {
        id: 3,
        name: 'Startup with Greyffiti cloud',
        icon: RocketWithDB,
        price: 42,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Storage per project 60 gb',
                    'Number of collaborators per project 50'
                ]
            },
            'Version history restore 50 version back',
            'Organize Events',
            `75% revenue from marketplace's paid assets and plugins`,
            'Up to 20 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Swiftlet pro subscription',
            'Visibility in marketplace and connect'
        ]
    },
    {
        id: 4,
        name: 'Startup with Own cloud',
        icon: RocketWithCloud,
        price: 47,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Unlimited Storage per project',
                    'Number of collaborators per project 50'
                ]
            },
            'Version history restore unlimited version back',
            'Organize Events',
            'Front face your public repositories on greyffiti',
            `75% revenue from marketplace's paid assets and plugins`,
            'Up to 20 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Swiftlet pro subscription',
            'Visibility in marketplace and connect'
        ]
    },
    {
        id: 5,
        name: 'organization with Greyffiti cloud',
        icon: BuildingWithDB,
        price: 47,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Storage per project 1 TB',
                    'Number of collaborators per project 55'
                ]
            },
            'Version history restore unlimited version back',
            'Organize Events',
            `90% revenue from marketplace's paid assets and plugins`,
            'Up to 55 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Swiftlet pro subscription',
            'Visibility in marketplace and connect'
        ]
    },
    {
        id: 6,
        name: 'organization with Own cloud',
        icon: BuildingWithCloud,
        price: 52,
        desc: [
            {
                title: 'Private repos/projects',
                desc: [
                    'Unlimited Storage per project',
                    'Number of collaborators per project 55'
                ]
            },
            'Version history restore unlimited version back',
            'Organize Events',
            'Front face your public repositories on Greyffiti',
            `90% revenue from marketplace's paid assets and plugins`,
            'Up to 55 users support in discussion boards for private repos',
            'Access rights while inviting',
            'Swiftlet pro subscription',
            'Visibility in marketplace and connect'
        ]
    },
]

const currencies = [
    {
        value: 'USD',
        label: 'USD ($)',
    },
    {
        value: 'AED',
        label: 'AED (د.إ)',
    },
    {
        value: 'INR',
        label: 'INR (₹)',
    }
];

function NewPricing() {
    const [monthly, setMonthly] = useState({
        individual: false,
        team: false,
        startup: false,
        enterprise: false
    });
    const [currency, setCurrency] = useState('INR');

    const user = useSelector(selectUserBasicInfo);
    const history = useHistory();

    return (
        <>
            <div className='w-full min-h-screen bg-green pb-10'>
                {user ? <Navbar /> :
                    <nav className="flex items-center justify-between px-5 py-3 lg:px-10 sticky top-0 bg-white z-50">
                        <div className="flex items-center justify-center">
                            <div className="h-12 w-10 object-contain" onClick={() => history.push("/")}>
                                <img src={logo} alt="logo" />
                            </div>
                            <ul className="hidden lg:flex items-center space-x-6 md:space-x-8 xl:space-x-10 pl-10 text-lg font-medium">
                                <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#home`}>Home</a></li>
                                <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#about`}>About Us</a></li>
                                <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#features`}>Features</a></li>
                                <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}/pricing`}>Pricing</a></li>
                                <li className="cursor-pointer"><a className='text-gray-800 hover:text-blue' href={`${window.location.origin}#support`}>Support</a></li>
                            </ul>
                        </div>

                        <div className="flex items-center space-x-4">
                            <Link to="/login"><button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium">Login</button></Link>
                            <span className='hidden sm:inline-flex'>or</span>
                            <Link to="/register"> <button className=" text-gray-800 border-2 border-gray-100 px-4 py-2 rounded-lg font-medium hidden sm:inline-flex">Create Account</button></Link>
                        </div>
                    </nav>
                }

                <div className='max-w-7xl mx-auto p-5'>

                    <h1 className='text-center text-3xl lg:text-4xl font-bold text-white mt-4'>Choose the plan that’s right for you.</h1>

                    {/* individual */}
                    <div className='bg-white py-4 px-8 max-w-max rounded-lg flex items-center gap-8 mt-16 mx-auto'>
                        <h1 className='text-2xl font-medium text-gray-800'>For individual Creators</h1>
                        <div>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-l-3xl ${monthly.individual ? 'text-yellow bg-white' : 'text-white bg-yellow'}`} onClick={() => setMonthly({ ...monthly, individual: false })}>Yearly</button>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-r-3xl ${monthly.individual ? 'text-white bg-yellow' : 'text-yellow bg-white'}`} onClick={() => setMonthly({ ...monthly, individual: true })}>Monthly</button>
                        </div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            variant="outlined"
                            className='w-32'
                            size='small'
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='flex flex-wrap items-stretch justify-center gap-16 mt-4 w-full'>
                        {
                            plans.slice(0, 2).map(plan => <PlanCard key={plan.id} plan={plan} currency={currency} monthly={monthly.individual} />)
                        }
                    </div>

                    {/* teams  */}
                    <div className='bg-white py-4 px-8 max-w-max rounded-lg flex items-center gap-8 mt-16 mx-auto'>
                        <h1 className='text-2xl font-medium text-gray-800'>For teams or group side gig</h1>
                        <div>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-l-3xl ${monthly.team ? 'text-yellow bg-white' : 'text-white bg-yellow'}`} onClick={() => setMonthly({ ...monthly, team: false })}>Yearly</button>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-r-3xl ${monthly.team ? 'text-white bg-yellow' : 'text-yellow bg-white'}`} onClick={() => setMonthly({ ...monthly, team: true })}>Monthly</button>
                        </div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            variant="outlined"
                            className='w-32'
                            size='small'
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='flex flex-wrap items-stretch justify-center gap-16 mt-4 w-full'>
                        {
                            plans.slice(2, 3).map(plan => <PlanCard key={plan.id} plan={plan} currency={currency} monthly={monthly.team} />)
                        }
                    </div>


                    {/* startup */}
                    <div className='bg-white py-4 px-8 max-w-max rounded-lg flex items-center gap-8 mt-16 mx-auto'>
                        <h1 className='text-2xl font-medium text-gray-800'>For Startup</h1>
                        <div>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-l-3xl ${monthly.startup ? 'text-yellow bg-white' : 'text-white bg-yellow'}`} onClick={() => setMonthly({ ...monthly, startup: false })}>Yearly</button>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-r-3xl ${monthly.startup ? 'text-white bg-yellow' : 'text-yellow bg-white'}`} onClick={() => setMonthly({ ...monthly, startup: true })}>Monthly</button>
                        </div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            variant="outlined"
                            className='w-32'
                            size='small'
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='flex flex-wrap items-stretch justify-center gap-16 mt-4 w-full'>
                        {
                            plans.slice(3, 5).map(plan => <PlanCard key={plan.id} plan={plan} currency={currency} monthly={monthly.startup} />)
                        }
                    </div>

                    {/* enterprise */}
                    <div className='bg-white py-4 px-8 max-w-max rounded-lg flex items-center gap-8 mt-16 mx-auto'>
                        <h1 className='text-2xl font-medium text-gray-800'>For Enterprise</h1>
                        <div>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-l-3xl ${monthly.enterprise ? 'text-yellow bg-white' : 'text-white bg-yellow'}`} onClick={() => setMonthly({ ...monthly, enterprise: false })}>Yearly</button>
                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-r-3xl ${monthly.enterprise ? 'text-white bg-yellow' : 'text-yellow bg-white'}`} onClick={() => setMonthly({ ...monthly, enterprise: true })}>Monthly</button>
                        </div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            variant="outlined"
                            className='w-32'
                            size='small'
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='flex flex-wrap items-stretch justify-center gap-16 mt-4 w-full'>
                        {
                            plans.slice(5, 7).map(plan => <PlanCard key={plan.id} plan={plan} currency={currency} monthly={monthly.enterprise} />)
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default NewPricing


const PlanCard = ({ plan, monthly, currency }) => {
    return (
        <div className='p-5 border rounded-md bg-white shadow w-full max-w-md'>
            <div className='py-4 flex items-start justify-between gap-3'>
                <div className='h-full'>
                    <h1 className='text-navy text-lg lg:text-2xl font-bold mb-4'>{plan.name}</h1>
                    <p className='text-lg text-navy font-medium font-serif'>{currency == 'AED' ? 'د.إ' : currency == 'USD' ? '$' : '₹'}<span className='text-xl'>{plan.price * (monthly ? 1 : 11)}</span></p>
                    <p className='text-gray-500 group-hover:text-gray-50 text-xs'>per {monthly ? 'month' : 'year'}</p>
                </div>
                <img className="w-16 h-16 p-1 object-contain" src={plan.icon} alt="" />
            </div>
            <hr className='w-full mx-auto my-2' />
            {
                plan.desc.map((item, i) => typeof (item) == 'string'
                    ? <p className='text-base text-gray-700 my-2 leading-loose'>● {item}</p>
                    : typeof (item) == 'object'
                        ? <>
                            <h1 className='text-gray-700 text-base leading-loose'>● {item.title}</h1>
                            {
                                item.desc.map(item => typeof (item) == 'string'
                                    ? <p className='text-sm text-gray-600 my-2 ml-5'>● {item}</p> : <></>)
                            }
                        </>
                        : <></>
                )
            }
        </div>
    )
}