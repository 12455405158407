import React from 'react'
import {
    Typography,
} from '@material-ui/core'
import useStyle from '../Styling'

function Header(props) {
    const {title} = props;
    const classes = useStyle();
    return (
        <div>
            <Typography className={classes.settingsHeadding}>
                {title}
            </Typography>
        </div>
    )
}

export default Header