import { React, useState, useEffect } from "react";
import { isUsernameAvailable, updateUserName, userDetail } from '../../service/service';
import toast from "react-hot-toast";
import { TextField } from "@material-ui/core";
import ArrowWhite from "../../assets/icons/ArrowWhite";
import { useDebounce } from "../../utils/helper";

function AccountStepper({ handleNext, handleBack, activeStep }) {
  const [countDetail, setCountDetail] = useState(1);
  const [flagLoaderButton, setFlagLoaderButton] = useState(false);
  const [fields, setFields] = useState({
    username: "",
    email: "",
  });
  useEffect(() => getUserDetails(), []);

  const debounced = useDebounce(fields.username, 500);

  const checkUsername = async (username) => {
    let isUsername = await isUsernameAvailable(username);
    if (!isUsername.data) {
      toast.error(isUsername.message, { id: 'username' })
    }
  }

  useEffect(() => {
    if (debounced) {
      checkUsername(debounced);
    }
  }, [debounced])

  async function getUserDetails() {
    if (countDetail === 1) {
      const res = await userDetail();
      if (res.data != null) {
        const username = res.data.username;
        setFields(prevState => ({
          ...prevState,
          username: username
        }))
        const email = res.data.email;
        setFields(prevState => ({
          ...prevState,
          email: email
        }))
      }
      setCountDetail(countDetail + 1)
    }
  }
  const handleChange = e => {
    e.preventDefault();
    const { id } = e.target
    let newValue = e.target.value
    if (e.target.id.match("username")) {
      newValue = newValue.replace(/[^A-Za-z0-9_-]/gi, "");
    }
    setFields(prevState => ({
      ...prevState,
      [id]: newValue
    }))
  };
  const validation = () => {
    let formIsValid = true;
    if (fields.username === "") {
      formIsValid = false;
      toast.error('Username cannot be empty', { id: 'error' })
    }
    if (fields.username.length < 5) {
      formIsValid = false;
      toast.error('Username must be at least 5 characters!', { id: 'error' })
    }
    if (fields.username.length > 15) {
      formIsValid = false;
      toast.error('Username must be at less than 15 characters!', { id: 'error' })
    }
    return formIsValid
  }

  async function updateName(e) {
    e.preventDefault();
    if (validation()) {
      let name = fields.username;
      let isUsername = await isUsernameAvailable(name);

      if (isUsername.data) {
        let data = { name };
        setFlagLoaderButton(true);
        const res = await updateUserName(data);
        if (res.data != null) {
          toast.success('Updated Successfully', { id: 'success' })
          handleNext();
        }
      }
      else {
        toast.error(isUsername.message, { id: 'username' })
      }
    }
    setFlagLoaderButton(false);
  }
  return (
    <div className='max-w-3xl mx-auto'>
      <h1 className="text-3xl lg:text-4xl font-bold text-left">Account</h1>
      <form className="flex flex-col justify-start w-full max-w-xl my-5">
        <div className=' max-w-2xl'>
          <TextField size="small" label="Enter username" name="username" id="username" value={fields.username} onChange={handleChange} variant="outlined" className={`w-full`} />
        </div>
        <p className="text-green text-xs mt-0.5">Only Alphabets, Numbers, - and _ allowed </p>
        <div className='bg-gray-200 border rounded-md w-full px-4 py-2 text-lg flex items-center gap-4 my-6'>
          <input required disabled type="email" name="email" id="email" value={fields.email} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Email address" onChange={handleChange} />
        </div>
        <div className="flex items-center gap-6">
          <button disabled={activeStep === 0} onClick={handleBack} className='py-2 px-6 text-lg font-semibold rounded-lg flex-shrink-0 mx-4 disabled:text-gray-600 border border-blue-500 disabled:border-gray-500'>
            Back
          </button>
          <button disabled={flagLoaderButton} type='submit' onClick={updateName} className='flex-shrink-0 bg-blue py-2 px-4 text-lg text-white font-semibold rounded-lg'>
            Save & Next
            {flagLoaderButton ? <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block ml-2" viewBox="0 0 24 24"></svg> : <ArrowWhite className="inline-flex w-7 h-7 mx-2" />
            }
          </button>
        </div>
      </form>
    </div>
  );
}
export default AccountStepper; 