import React from 'react'

function ArrowWhite({ onClick, className }) {
    return (
        <svg onClick={() => onClick ? onClick() : {}} className={className} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M66.4706 19.2529L93.7447 46.4753C95.4184 48.1458 95.4184 50.8542 93.7447 52.5247L66.4706 79.7471C64.797 81.4176 62.0834 81.4176 60.4097 79.7471C58.736 78.0766 58.736 75.3682 60.4097 73.6977L80.3677 53.7776H5V45.2224H80.3677L60.4097 25.3023C58.736 23.6318 58.736 20.9234 60.4097 19.2529C62.0834 17.5824 64.797 17.5824 66.4706 19.2529Z" fill="white" />
        </svg>
    )
}

export default ArrowWhite