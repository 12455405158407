import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import toggle_off_24px from '../../assets/images/toggle_off_24px.svg'
import toggle_on_24px from '../../assets/images/toggle_on_24px.svg'
import Restore from './components/Restore'
import Save from './components/Save'
import useStyle from './Styling'

function AccountSettings(props) {
    const [data, setData] = useState({email_notification:false})
    const [settingsType, setSettingsType] = useState("account")
    const [account, setAccount] = useState(props.accountSetting)
  
    const classes = useStyle();

useEffect(() => { 
    setOldValue()
}, [])

const restoreUpdate =() =>{
    setAccount(false)
    setData({
        email_notification:"false"
    })
}

const setOldValue = () => {
  const settingsData= props.settingsListOld.data
     if (settingsData !== undefined){
        settingsData.map(function (item) {
            if (item.setting_type === "account") {
                if(item.setting_key === "email_notification"){
                    setData({
                        email_notification:item.key_value
                    })
                    setAccount(item.key_value)
                }
            };
        })
    } 
}

const setToggle = (value) => {
    setAccount(value)
    setData({
        email_notification: value
    })   
}
return (
    <div>
        <List className={classes.listBox}>
            <ListItem className={classes.listContent}>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Typography
                                className={classes.title}>
                                Email notification
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                className={classes.description}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Typography>
                        </React.Fragment>
                    }>
                </ListItemText>
                <ListItemIcon>
                {account ==="true" ?
                    <img src={ toggle_on_24px} className={classes.toggle} onClick={(e)=> setToggle("false")} />
                    :
                    <img src={toggle_off_24px} className={classes.toggle} onClick={(e)=> setToggle("true")}/>
                }
                </ListItemIcon>
            </ListItem>
            <Divider />
            <ListItem>
                <Restore type={settingsType} 
                         getSettingsList={props.getSettingsList} 
                         restoreUpdate={restoreUpdate} 
                         alertMsg={props.alertMsg}/>
                <Save data={data} 
                      getSettingsList={props.getSettingsList} 
                      alertMsg={props.alertMsg}/>
            </ListItem>
        </List>
    </div>
)
}

export default AccountSettings
