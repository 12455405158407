import { React, useState, useEffect } from 'react'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { urlValidator } from '../../utils/urlValidation'
import { socialLink, userSocialDetail } from '../../service/service'
import toast from 'react-hot-toast'
import DribbbleIcon from '../../assets/icons/DribbbleIcon'
import { LinkedIn, Twitter, Instagram, GitHub, Pinterest } from '@material-ui/icons'
import validator from 'validator';
function Social() {

    const [fields, setFields] = useState({
        twitterUrl: '',
        linkedinUrl: '',
        dribbbleUrl: '',
        instagramUrl: '',
        pinterestUrl: '',
        githuburl: '',
        websiteUrl: ''
    })
    const [errors, setErrors] = useState({})
    const [countDetail, setCountDetail] = useState(1)
    const [flagLoaderButton, setFlagLoaderButton] = useState(false)

    useEffect(() => getUserSocialDetail(), [])

    async function getUserSocialDetail() {
        if (countDetail === 1) {
            const res = await userSocialDetail()
            if (res.data !== null) {
                if (res.data.dribbble !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        dribbbleUrl: res.data.dribbble
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        dribbbleUrl: ''
                    }))
                }
                if (res.data.twitter !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        twitterUrl: res.data.twitter
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        twitterUrl: ''
                    }))
                }
                if (res.data.linkedin !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        linkedinUrl: res.data.linkedin
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        linkedinUrl: ''
                    }))
                }
                if (res.data.instagram !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        instagramUrl: res.data.instagram
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        instagramUrl: ''
                    }))
                }

                if (res.data.github !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        githuburl: res.data.github
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        githuburl: ''
                    }))
                }
                if (res.data.website !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        websiteUrl: res.data.website
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        websiteUrl: ''
                    }))
                }
                if (res.data.pinterest !== 'undefined') {
                    setFields((prevState) => ({
                        ...prevState,
                        pinterestUrl: res.data.pinterest
                    }))
                } else {
                    setFields((prevState) => ({
                        ...prevState,
                        pinterestUrl: ''
                    }))
                }
            }
            setCountDetail(countDetail + 1)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        const { id } = e.target
        let newValue = e.target.value
        setFields((prevState) => ({
            ...prevState,
            [id]: newValue
        }))
    }

    const validationForm = () => {
        let temp = { ...errors }
        let formIsValid = true

        if (fields.twitterUrl !== '') {
            if (!urlValidator(fields.twitterUrl, 'twitter')) {
                formIsValid = false;
                toast.error("Twitter url is invalid", { id: 'error' })
            }
        }
        if (fields.linkedinUrl !== '') {
            if (!urlValidator(fields.linkedinUrl, 'linkedin')) {
                formIsValid = false;
                toast.error("Linkedin url is invalid", { id: 'error' })
            }
        }
        if (fields.dribbbleUrl !== '') {
            if (!urlValidator(fields.dribbbleUrl, 'dribbble')) {
                formIsValid = false;
                toast.error("Dribbble url is invalid", { id: 'error' })
            }
        }
        if (fields.instagramUrl !== '') {
            if (!urlValidator(fields.instagramUrl, 'instagram')) {
                formIsValid = false;
                toast.error("Instagram url is invalid", { id: 'error' })
            }
        }
        if (fields.pinterestUrl !== '') {
            if (!urlValidator(fields.pinterestUrl, 'pinterest')) {
                formIsValid = false;
                toast.error("Pinterest url is invalid", { id: 'error' })
            }
        }
        if (fields.githuburl !== '') {
            if (!urlValidator(fields.githuburl, 'gitHub')) {
                formIsValid = false;
                toast.error("Github url is invalid", { id: 'error' })
            }
        }
        if (fields.websiteUrl !== '') {
            if (!validator.isURL(fields.websiteUrl)) {
                formIsValid = false;
                toast.error("Website url is invalid", { id: 'error' });
            }
        }
        setErrors({
            ...temp
        })
        return formIsValid
    }

    const createsocialinfo = (e) => {
        e.preventDefault()
        if (validationForm()) {
            setFlagLoaderButton(true)
            socialLinkAPI()
        }
    }
    async function socialLinkAPI() {
        let twitter = fields.twitterUrl
        let linkedin = fields.linkedinUrl
        let dribbble = fields.dribbbleUrl
        let instagram = fields.instagramUrl
        let pinterest = fields.pinterestUrl
        let github = fields.githuburl
        let website = fields.websiteUrl
        let data = {
            twitter,
            linkedin,
            dribbble,
            instagram,
            pinterest,
            github,
            website
        }
        const res = await socialLink(data)
        if (res.data != null) {
            toast.success("Social Info Updated Successfully", { id: 'success' })
        }
        setFlagLoaderButton(false)
    }
    return (
        <>
            <h1 className="text-2xl font-semibold text-left mb-3">Web & Social Links</h1>
            <div className='rounded-lg p-5 shadow-lg shadow-gray-200 bg-[#F6FAFD]'>
                <div className='max-w-xl bg-white border rounded-md w-full px-4 py-2 mt-4 text-base flex items-center gap-4'>
                    <input type='url' name="websiteUrl" id="websiteUrl" value={fields.websiteUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add Website" onChange={handleChange} />
                    {fields.websiteUrl !== '' &&
                        !validator.isURL(fields.websiteUrl) && (
                            <WarningRoundedIcon className='text-red' />
                        )}
                </div>

                <table className='w-full max-w-xl my-4 border-separate border-spacing-y-4'>
                    <tbody>
                        <tr>
                            <td>
                                <Twitter className=' text-gray-600' />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Twitter</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="twitterUrl" id="twitterUrl" value={fields.twitterUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.twitterUrl !== '' &&
                                        !urlValidator(fields.twitterUrl, 'twitter') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <LinkedIn className=" text-gray-600" />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Linkedin</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="linkedinUrl" id="linkedinUrl" value={fields.linkedinUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.linkedinUrl !== '' &&
                                        !urlValidator(fields.linkedinUrl, 'linkedin') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <DribbbleIcon className='opacity-80 w-6 h-6' />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Dribbble</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="dribbbleUrl" id="dribbbleUrl" value={fields.dribbbleUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.dribbbleUrl !== '' &&
                                        !urlValidator(fields.dribbbleUrl, 'dribbble') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Instagram className=' text-gray-600' />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Instagram</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="instagramUrl" id="instagramUrl" value={fields.instagramUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.instagramUrl !== '' &&
                                        !urlValidator(fields.instagramUrl, 'instagram') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Pinterest className=' text-gray-600' />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Pinterest</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="pinterestUrl" id="pinterestUrl" value={fields.pinterestUrl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.pinterestUrl !== '' &&
                                        !urlValidator(fields.pinterestUrl, 'pinterest') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <GitHub className=' text-gray-600' />
                            </td>
                            <td>
                                <h1 className='text-lg font-medium'>Github</h1>
                            </td>
                            <td>
                                <div className=' bg-white border rounded-md w-full px-4 py-2 text-base flex items-center gap-4'>
                                    <input type='url' name="githuburl" id="githuburl" value={fields.githuburl} className={`w-full bg-transparent outline-none flex-grow`} placeholder="Add URL" onChange={handleChange} />
                                    {fields.githuburl !== '' &&
                                        !urlValidator(fields.githuburl, 'gitHub') && (
                                            <WarningRoundedIcon className='text-red' />
                                        )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="flex items-center w-full justify-start mt-8">
                    <button disabled={flagLoaderButton} type='submit' onClick={createsocialinfo} className='flex-shrink-0 bg-blue py-2 px-10 text-lg text-white font-semibold rounded-lg'>
                        Save
                        {flagLoaderButton ? <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block ml-2" viewBox="0 0 24 24"></svg> : ''}
                    </button>
                </div>
            </div>
        </>
    )
}
export default Social
