import { Viewer } from '@react-pdf-viewer/core';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { preview } from '../../slices/projectSlice';
const colors = ['rgba(45, 200, 168, 0.27)', '#CF98FB', '#FEBEFF', 'rgba(29, 133, 232, 0.48)', 'rgba(255, 194, 88, 0.39)',]


// const Project = ({ project, username }) => {
//     const dispatch = useDispatch()
//     return <div className='p-5 bg-white rounded-lg shadow-lg hover:shadow-xl shadow-gray-200 border w-full max-w-[336px] cursor-pointer select-none' onClick={() => project?.readme ? dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name })) : toast("No preview available", { id: 'preview' })}>
//         <h1 className='line-clamp-1 font-semibold tracking-wide'>{project?.name}</h1>
//         <p className='line-clamp-2 text-sm text-gray-600 my-3'>{project?.description}</p>
//         <p>{project?.repoLength ?? 0} repository</p>
//         <p className='text-blue my-2'>{project?.topic}</p>
//         <div className='flex items-center justify-between gap-2 mt-2'>
//             <span className='text-gray-400 text-sm'>
//                 Updated {moment(project?.updated_at).fromNow()}
//             </span>
//             <p className='flex items-center gap-1 text-sm'>
//                 <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <circle cx="9" cy="9" r="8.5" fill="white" stroke="#1D85E8" />
//                     <path d="M11.14 6L8.14 9.87L6 7.28L3 11.14H15L11.14 6Z" fill="#1D85E8" />
//                 </svg>

//                 {/* <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99967 4.99998C6.28634 4.99998 7.33301 3.95331 7.33301 2.66665C7.33301 1.37998 6.28634 0.333313 4.99967 0.333313C3.71301 0.333313 2.66634 1.37998 2.66634 2.66665C2.66634 3.95331 3.71301 4.99998 4.99967 4.99998ZM4.99967 1.66665C5.55301 1.66665 5.99967 2.11331 5.99967 2.66665C5.99967 3.21998 5.55301 3.66665 4.99967 3.66665C4.44634 3.66665 3.99967 3.21998 3.99967 2.66665C3.99967 2.11331 4.44634 1.66665 4.99967 1.66665ZM2.17967 8.33331H5.03301C5.01301 8.43998 4.99967 8.55331 4.99967 8.66665V9.66665H0.333008V8.66665C0.333008 7.11331 3.43967 6.33331 4.99967 6.33331C5.37301 6.33331 5.83301 6.37998 6.31967 6.46665C5.84634 6.78665 5.45301 7.18665 5.22634 7.67331C5.18967 7.67331 5.15134 7.67165 5.11301 7.66998C5.07467 7.66831 5.03634 7.66665 4.99967 7.66665C3.97967 7.66665 2.83967 7.99998 2.17967 8.33331ZM9.99967 6.66665C8.77301 6.66665 6.33301 7.33998 6.33301 8.66665V9.66665H13.6663V8.66665C13.6663 7.33998 11.2263 6.66665 9.99967 6.66665ZM11.6663 3.99998C11.6663 4.62665 11.313 5.16665 10.8063 5.45331C10.5663 5.58665 10.293 5.66665 9.99967 5.66665C9.70634 5.66665 9.43301 5.58665 9.19301 5.45331C8.68634 5.16665 8.33301 4.62665 8.33301 3.99998C8.33301 3.07998 9.07967 2.33331 9.99967 2.33331C10.9197 2.33331 11.6663 3.07998 11.6663 3.99998Z" fill="#092C4C" />
//                 </svg>
//                 <span>
//                     {project.contributors ?? 0}
//                 </span> */}
//             </p>
//         </div>
//     </div>
// }

const Project = ({ project }) => {
    const dispatch = useDispatch();
    if (!project?.spotlight) return <></>;
    return <div className='rounded-lg h-60 hover:border hover:shadow-lg pb-2 max-w-[300px] min-w-[300px] group transition-all duration-100 ease-out'>
        <h1 className='text-lg font-semibold text-gray-800 mt-2 text-center opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out'>{project.name}</h1>
        <div className='w-full h-52 relative overflow-y-scroll cursor-pointer rounded-lg transform group-hover:scale-90 transition-all duration-300 ease-out' onClick={() => dispatch(preview({
            isOpen: true, pdfUrl: project?.readme, title: project?.name, projectInfo: {
                link: `/${project.username}/${project.name}`,
                buttonText: project.project_type_id == 1 ? 'Public' : 'Private'
            }
        }))}>
            {/* <div className='absolute top-0 left-0 w-full h-full z-30 cursor-pointer' onClick={() => dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name }))} /> */}
            <Viewer
                fileUrl={project?.readme}
            />
        </div>
    </div >
}

const ProjectCard = ({ project, color }) => {
    const dispatch = useDispatch();
    if (!project?.spotlight) return <></>;
    return <div className='p-10 w-full last:rounded-br-xl' style={{
        background: color
    }}>
        <div className='rounded-lg w-full bg-white max-w-[360px] mx-auto hover:shadow-lg pb-2 min-w-[300px] group transition-all duration-100 ease-out'>
            <h1 className='text-lg py-2 font-semibold text-gray-800 mt-2 text-center opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out'>{project.name}</h1>
            <div className='w-full h-52 relative overflow-y-scroll cursor-pointer rounded-lg transform transition-all duration-300 ease-out' onClick={() => dispatch(preview({
                isOpen: true, pdfUrl: project?.readme, title: project?.name, projectInfo: {
                    link: `/${project.username}/${project.name}`,
                    buttonText: project.project_type_id == 1 ? 'Public' : 'Private'
                }
            }))}>
                {/* <div className='absolute top-0 left-0 w-full h-full z-30 cursor-pointer' onClick={() => dispatch(preview({ isOpen: true, pdfUrl: project?.readme, title: project?.name }))} /> */}
                <Viewer
                    fileUrl={project?.readme}
                />
            </div>
        </div>
    </div >
}

function ProjectSpotlight({ projects, free }) {
    const [colorArr, setColorArr] = useState([])

    useEffect(() => {

        if (projects?.length) {

            let arr2 = ['rgba(45, 200, 168, 0.27)', 'rgba(45, 200, 168, 0.27)'];

            let count = 1;
            for (let i = 2; i < projects?.length; i = i + 6) {
                // arr[count] = [i, i + 2, i + 3]
                arr2[i] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 2] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 3] = colors[Math.abs((count - 5) % 5)]
                count += 2;
            }

            count = 2

            for (let i = 3; i < projects?.length; i = i + 6) {
                // arr[count] = [i, i + 3, i + 4]
                arr2[i] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 3] = colors[Math.abs((count - 5) % 5)]
                arr2[i + 4] = colors[Math.abs((count - 5) % 5)]
                count += 2;
            }
            setColorArr(arr2)
        }
    }, [projects?.length])

    return (
        <>
            {free ? <div className='min-h-[300px] flex items-start gap-10 flex-wrap w-full'>
                {
                    projects?.length ?
                        projects?.map(project => <Project key={project.id} project={project} />)
                        :
                        <h1 className='text-3xl font-semibold text-gray-600 text-center mx-auto mt-10'>No Project is available</h1>
                }
            </div> :
                <div className='min-h-[300px] grid md:grid-cols-2 lg:grid-cols-3 w-full rounded-xl overflow-hidden'>
                    {
                        projects?.length ?
                            projects?.map((project, i) => <ProjectCard color={colorArr[i]} key={i} project={project} />)
                            :
                            <h1 className='text-3xl font-semibold text-gray-600 text-center mx-auto mt-10'>No Project is available</h1>
                    }
                </div>
            }
        </>
    )
}

export default ProjectSpotlight