import React from 'react'
import UserBannerImage from '../../assets/images/Default_User_Cover_Image.png'
import DefaultUserIcon from '../../assets/icons/DefaultUserIcon'
import { useState } from 'react';
import ConfirmModal from '../ReusableComponents/ConfirmModal';
import { exitFromOrganization } from '../../service/service';
import { toast } from 'react-hot-toast';

function CompanyDetail({ user }) {
    const [deleteModal, setDeleteModal] = useState(false);

    let organization = user.organization

    const handleExit = async () => {
        setDeleteModal(false)
        let res = await exitFromOrganization();
        console.log(res)
        if (res.success) {
            toast.success("User Exit Successfully!");
            window.localStorage.setItem("greyffiti_token", '')
            window.location.replace('/login')
        }
    }
    return (
        <>
            <section>
                <h1 className="text-2xl font-semibold text-left mb-3">Company Details</h1>
                <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] '>
                    <div className='relative w-full'>
                        <img
                            src={
                                !organization.banner_pic
                                    ? UserBannerImage
                                    : organization.banner_pic
                            }
                            alt='banner'
                            className='w-full h-36 object-cover z-10'
                        />
                    </div>
                    <div className='grid place-items-center -mt-16'>
                        {
                            !organization.profile_pic ?
                                <DefaultUserIcon className='w-28 h-28 z-20 shadow-lg rounded-full bg-white border-2 border-green-500' />
                                :
                                <img src={organization.profile_pic} alt='profile_pic' className='w-28 h-28 z-20 shadow-lg rounded-full bg-white border-2 border-green-500' />
                        }
                        <h1 className='mt-2 text-xl font-medium'>{organization?.display_name}</h1>
                    </div>
                    <div className='p-5 lg:px-10 pb-10'>
                        <div className='bg-white rounded-lg w-full h-28 border p-3'>
                            {organization.bio}
                        </div>
                        <h1 className='capitalize my-2'>Company Type: <span className='font-medium'>{organization.type}</span></h1>

                        <div className='border-t-2 border-rose-300 w-full my-8' />
                        <div className='flex items-start justify-between'>
                            <div className='text-red max-w-md'>
                                <h1 className='text-xl font-medium'>Exit this organization?</h1>
                                <p>If you exist the organization you need to contact the concerned team at your firm to add you back.<br />
                                    You cannot request being part.</p>
                            </div>
                            <button className='bg-red text-white py-2 px-10 rounded-lg font-medium outline-none' onClick={() => setDeleteModal(true)}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmModal open={deleteModal} heading={`Are you sure you want to exit from the organization`} type="warn" okText='Yes' onCancel={() => setDeleteModal(false)} onOk={(e) => handleExit()} />
        </>
    )
}

export default CompanyDetail