import React from "react";
import { Warning } from "@material-ui/icons";
import logo from "../../assets/images/Greyffiti-New-Logo-White 1.png";
import validation from "./forgotPasswordValidation";
import { Link } from "react-router-dom";
import MailIcon from "../../assets/icons/MailIcon";

function ForgotPassword() {
    const { handleChange, handleFormSubmit, values, errors, flagLoaderButton } = validation();

    return (
        <>
            <div className='max-h-screen h-screen overflow-hidden relative bg-red flex items-center justify-center'>
                <Link to="/"><img src={logo} alt="logo" className={" object-contain w-12 absolute top-4 left-4"} style={{ zIndex: 1 }} /></Link>
                <form onSubmit={handleFormSubmit} className="w-full max-w-lg bg-white m-4 p-5 md:p-10 rounded-md shadow-md flex flex-col items-center justify-center">
                    <h1 className="text-3xl md:text-4xl font-bold text-blue">Forgot Password?</h1>
                    <p className="md:text-lg font-medium text-center my-4">Please, enter your registered email. You'll receive a link to create a new password via email.</p>
                    <MailIcon className='m-2' />
                    <div className='max-w-xs bg-white rounded-md w-full px-4 py-2 text-lg flex items-center gap-4 border border-gray-300 focus-within:border-gray-700 my-4'>
                        <input required type="email" name="email" id="email" value={values.email} className={`w-full bg-transparent outline-none border-none flex-grow`} placeholder="Enter your email" onChange={handleChange} />
                        {errors.email && <Warning className="text-red" />}
                    </div>
                    <button disabled={flagLoaderButton} type='submit' onClick={handleFormSubmit} className='bg-blue py-3 px-6 mt-4 text-lg text-white font-semibold rounded-lg'>
                        Send
                        {flagLoaderButton && <svg className="animate-spin h-5 w-5 border-2 rounded-md border-white inline-block mx-4" viewBox="0 0 24 24"></svg>}
                    </button>
                </form>
            </div>
        </>
    );
}

export default ForgotPassword;