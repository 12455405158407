import React from 'react'
import { useHistory } from 'react-router-dom'
import { plans } from '../Profile/Upgrade'
import { useState } from 'react';

function CompanyUserAccount({ user }) {
    const history = useHistory()
    const [selected, setSelected] = useState(user?.plan);

    return (
        <section>
            <h1 className="text-2xl font-semibold text-left mb-3">Account Upgrades</h1>
            <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                <div className='flex items-center gap-4'>
                    <h1 className='text-xl font-medium text-blue'>You on Greffiti Enterprise with greyffiti cloud storage</h1>
                    <img className='h-10' src={plans.find(item => item?.planId == user?.plan?.id).icon} alt='' />
                </div>

                <div className='flex items-center gap-3 mt-1'>
                    <h1 className='text-lg'>Upcoming renewal date: </h1>
                    <div className='text-right'>
                        <h1>12/1/12</h1>
                        <p className='text-sm italic text-gray-500'>(8 months to go)</p>
                    </div>
                    <button className='px-10 py-1.5 rounded-lg ml-auto outline-none bg-blue disabled:bg-gray-400 text-white'>Renew Now</button>
                </div>
                <h1 className='text-yellow text-right cursor-pointer underline'>view transaction history</h1>

                <div className='w-full h-0.5 bg-gray-400 my-10' />

                <div className='flex items-start justify-center flex-wrap gap-5'>
                    {
                        plans.map(plan => <div key={plan.id} onClick={() => setSelected(plan)} className={`p-3 border cursor-pointer rounded-md ${selected?.id == plan.id ? 'bg-blue-500' : 'bg-white'} shadow group hover:bg-blue-500 flex items-start justify-between gap-3 max-w-[200px] min-w-[10rem] h-[7.5rem]`}>
                            <div className='h-full flex flex-col'>
                                <h1 className={`${selected?.id == plan?.id ? 'text-white' : 'text-navy'} text-sm font-bold flex-grow group-hover:text-white`}>{plan.name}</h1>
                                <p className={`text-xs ${selected?.id == plan?.id ? 'text-white' : 'text-navy'} font-medium group-hover:text-white font-serif`}>$<span className='text-base'>{plan.price}</span></p>
                                <p className={`${selected?.id == plan?.id ? 'text-gray-50' : 'text-gray-500'} group-hover:text-gray-50 text-xs`}>per month</p>
                            </div>
                            <img className="w-16 h-16 p-1 object-contain" src={plan.icon} alt="" />
                        </div>)
                    }
                </div>

                <button className='px-10 py-1.5 rounded-lg float-right outline-none bg-blue disabled:bg-gray-400 text-white my-6'>Change Plan</button>

            </div>
        </section>
    )
}

export default CompanyUserAccount