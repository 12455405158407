import settings_grey from '../../../assets/images/settings_grey.svg'
import settings_white from '../../../assets/images/settings_white.svg'
import settings_applications from '../../../assets/images/settings_applications.svg'
import settings_applications_white_24px from '../../../assets/images/settings_applications_white_24px.svg'
import eq_24px from '../../../assets/images/eq_24px.svg'
import eq_white_24px from '../../../assets/images/eq_white_24px.svg'
import security_24 from '../../../assets/images/security_24.svg'
import security_white_24px from '../../../assets/images/security_white_24px.svg'
import memory_24px from '../../../assets/images/memory_24px.svg'
import memory_white_24px from '../../../assets/images/memory_white_24px.svg'
import cloud_off_24px from '../../../assets/images/cloud_off_24px.svg'
import cloud_off_white_24px from '../../../assets/images/cloud_off_white_24px.svg'

export const  SettingsData =[
    {
        title: 'Account Settings',
        id:'account',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={settings_grey} />,
        whiteIcon: <img src={settings_white} />,
    },
    {
        title: 'System Settings',
        id:'system',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={settings_applications} />,
        whiteIcon: <img src={settings_applications_white_24px} />,

    },
    {
        title: 'Access',
        id:'access',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={eq_24px} />,
        whiteIcon: <img src={eq_white_24px} />,

    },
    {
        title: 'Security and Login',
        id:'security',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={security_24} />,
        whiteIcon: <img src={security_white_24px} />,

    },
    {
        title: 'Privacy',
        id:'privacy',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={memory_24px} />,
        whiteIcon: <img src={memory_white_24px} />,

    },
    {
        title: 'Deactivate Account',
        id:'deactivate',
        description:'Lorem ipsum dolor sit amet, consecte tur adipiscing elit.',
        icon: <img src={cloud_off_24px} />,
        whiteIcon: <img src={cloud_off_white_24px} />,
    }
];
