import { useState } from "react";
import { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsListBySize, getRepositoryListBySize } from "../service/service";
import { projectList, selectProjectList } from "../slices/projectSlice";
import { repoList, selectRepoList } from "../slices/repoSlice";


export const useClickOutside = (handler, ref = null) => {
  const domRef = ref || createRef(null);

  useEffect(() => {
    const localHandler = (e) => {
      if (!domRef.current) return;
      if (!domRef.current.contains(e.target)) handler();
    };
    document.addEventListener("mousedown", localHandler);
    return () => document.removeEventListener("mousedown", localHandler);
  }, [domRef, handler]);

  return domRef;
};

export const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay);
    return () => {
      clearTimeout(handler);
    }
  }, [value, delay]);

  return debouncedValue;
}

export const debounce = (func, delay = 300) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export const useSort = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjectList);
  const repos = useSelector(selectRepoList);

  const sortProjectByName = (callback, setProjects) => {
    if (projects) {
      let arr = [...projects]
      let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
      setProjects(sorted)
      dispatch(projectList(sorted));
      callback();
    }
  }
  const sortProjectByCreated = (callback, setProjects) => {
    if (projects) {
      let arr = [...projects]
      let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
      setProjects(sorted)
      dispatch(projectList(sorted));
      callback();
    }
  }
  const sortProjectByUpdated = (callback, setProjects) => {
    if (projects) {
      let arr = [...projects]
      let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
      setProjects(sorted)
      dispatch(projectList(sorted));
      callback();
    }
  }

  const sortProjectBySize = async (callback, username, setProjects) => {
    const res = await getProjectsListBySize(username);
    if (res.data) {
      setProjects(res.data)
      dispatch(projectList(res.data));
    }
    callback()
  }

  const sortRepoByName = (callback, setRepos) => {
    if (repos) {
      let arr = [...repos]
      let sorted = arr.sort((a, b) => a.name.localeCompare(b.name));
      setRepos(sorted);
      dispatch(repoList(sorted));
      callback();
    }
  }
  const sortRepoByCreated = (callback, setRepos) => {
    if (repos) {
      let arr = [...repos]
      let sorted = arr.sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
      setRepos(sorted);
      dispatch(repoList(sorted));
      callback();
    }
  }
  const sortRepoByUpdated = (callback, setRepos) => {
    if (repos) {
      let arr = [...repos]
      let sorted = arr.sort((a, b) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
      setRepos(sorted);
      dispatch(repoList(sorted));
      callback();
    }
  }

  const sortRepoBySize = async (callback, setRepos) => {
    const res = await getRepositoryListBySize();
    if (res.data) {
      setRepos(res.data);
      dispatch(repoList(res.data));
    }
    callback()
  }

  return {
    sortProjectByName, sortProjectByCreated, sortProjectByUpdated, sortProjectBySize,
    sortRepoByName, sortRepoByCreated, sortRepoByUpdated, sortRepoBySize,
  }
}


export const fetchFoldersFromJson = async (jsonData, platform = 'web') => {
  let listFolders = [];
  let dataList = JSON.parse(jsonData);
  for (let data of dataList) {
    let root = data?.path?.split("\\").join('/')
    if (data.type == "directory" || data.type == "folder") {
      var obj = { 'path': data.path, 'name': data.name, 'type': 'folder' }
      listFolders.push(obj);
    }
    if (data.children) {
      listFolders = FetchFolderChild(data.children, listFolders, root, platform)
    }
  }
  return listFolders
}


const FetchFolderChild = (obj, listFolders, root, platform) => {
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].type == "directory" || obj[i].type == "folder") {
      let path = '';

      path = obj[i].path.split("\\").join('/')

      var obj_new = { 'path': path, 'name': obj[i].name, 'type': 'folder' }
      listFolders.push(obj_new);
    }
    if (obj[i].children) {
      FetchFolderChild(obj[i].children, listFolders, root, platform)
    }
  }
  return listFolders
}



export function encrypt(message, key) {
  let encryptedMessage = '';
  for (let i = 0; i < message.length; i++) {
    const char = message[i];
    const charCode = char.charCodeAt(0);
    const keyCharCode = key.charCodeAt(i % key.length); // Retrieve the character code of the key at the corresponding position
    const encryptedCharCode = (charCode + keyCharCode) % 256; // Perform simple shift using the key value
    const encryptedChar = String.fromCharCode(encryptedCharCode);
    encryptedMessage += encryptedChar;
  }
  return encryptedMessage;
}

export function decrypt(encryptedMessage, key) {
  let decryptedMessage = '';
  for (let i = 0; i < encryptedMessage.length; i++) {
    const char = encryptedMessage[i];
    const charCode = char.charCodeAt(0);
    const keyCharCode = key.charCodeAt(i % key.length); // Retrieve the character code of the key at the corresponding position
    const decryptedCharCode = (charCode - keyCharCode + 256) % 256; // Reverse the shift using the key value
    const decryptedChar = String.fromCharCode(decryptedCharCode);
    decryptedMessage += decryptedChar;
  }
  return decryptedMessage;
}


export function formatLargeNumber(number) {
  if (Math.abs(number) < 1e3) {
    return number.toString();
  } else if (Math.abs(number) < 1e6) {
    return (number / 1e3).toFixed(1) + 'K';
  } else if (Math.abs(number) < 1e9) {
    return (number / 1e6).toFixed(1) + 'M';
  } else {
    return (number / 1e9).toFixed(1) + 'B';
  }
}