import { TextField } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast';
import MoneyPlant from '../../assets/images/MoneyPlant.svg'
import MoneyJar from '../../assets/images/MoneyJar.svg'
import { nameFieldValidator } from '../../utils/validation';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Peoples from '../../assets/images/Peoples.svg'
import RocketWithDB from '../../assets/images/RocketWithDB.svg'
import RocketWithCloud from '../../assets/images/RocketWithCloud.svg'
import BuildingWithDB from '../../assets/images/BuildingWithDB.svg'
import BuildingWithCloud from '../../assets/images/BuildingWithCloud.svg'
import Pro from './Pro';
import validator from 'validator';
import { createOrganization, handleFreelanceData } from '../../service/service';

export const plans = [
    // {
    //     id: 3,
    //     name: 'Teams',
    //     icon: Peoples,
    //     price: 15,
    //     planId: 3
    // },
    {
        id: 4,
        name: 'Startup with Greyffiti cloud',
        icon: RocketWithDB,
        price: 37,
        planId: 4
    },
    {
        id: 5,
        name: 'Startup with Own cloud',
        icon: RocketWithCloud,
        price: 37,
        planId: 5
    },
    {
        id: 6,
        name: 'organization with Greyffiti cloud',
        icon: BuildingWithDB,
        price: 42,
        planId: 6
    },
    {
        id: 7,
        name: 'organization with Own cloud',
        icon: BuildingWithCloud,
        price: 42,
        planId: 7
    },
]

const businessTypes = [
    {
        id: 1,
        name: 'retailer',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia enim, eligendi aspernatur optio ab nulla accusantium voluptatum inventore officia ipsam!'
    },
    {
        id: 2,
        name: 'service',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia enim, eligendi aspernatur optio ab nulla accusantium voluptatum inventore officia ipsam!'
    },
    {
        id: 3,
        name: 'product',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia enim, eligendi aspernatur optio ab nulla accusantium voluptatum inventore officia ipsam!'
    },
]


function Upgrade({ user }) {
    const [active, setActive] = useState(false);
    const [pro, setPro] = useState(false)

    return (
        <>
            <h1 className="text-2xl font-semibold text-left mb-3">Account Upgrades</h1>
            <div className='rounded-lg overflow-hidden min-h-[60vh] shadow-lg shadow-gray-200 bg-[#F6FAFD] p-5 lg:px-10'>
                <div className='py-2 flex items-start gap-6'>
                    <div>
                        <h1 className='text-lg font-semibold'>Upgrade to pro</h1>
                        <p className='text-sm lg:text-base py-2 text-gray-600 max-w-xl'>Unlock more with Greyffiti Pro membership. Get early access to new and features.
                            Get the most out of Greyffiti’s versioning, collaborative interactions</p>
                    </div>
                    <button type='button' className='text-red border border-red-400 rounded-3xl py-1.5 px-5 font-medium text-lg hover:bg-red-400 hover:text-white transition-colors duration-150 ease-in-out' onClick={() => setPro(true)}>Upgrade</button>
                </div>
                <hr className='w-full my-4 -ml-5' />
                <div className='py-2 flex items-start gap-6'>
                    <div>
                        <h1 className='text-lg font-semibold'>Change to Company Profile</h1>
                        <p className='text-sm lg:text-base py-2 text-gray-600 max-w-xl'>Organize your team, startup, or company and unlock the most out of Greyffiti’s powerful collaboration</p>
                    </div>
                    <button type='button' className='text-red border border-red-400 rounded-3xl py-1.5 px-5 font-medium text-lg hover:bg-red-400 hover:text-white transition-colors duration-150 ease-in-out' onClick={() => setActive(true)}>Change</button>
                </div>
            </div>


            {active && <PlanOptions active={active} setActive={setActive} />}
            {pro && <Pro pro={pro} setPro={setPro} />}
        </>
    )
}

export default Upgrade

export const PlanOptions = ({ active, setActive }) => {
    const [index, setIndex] = useState(0);
    const [fields, setFields] = useState({
        name: '',
        website: '',
        bio: ''
    });
    const history = useHistory();
    const [monthly, setMonthly] = useState(true);
    const [loading, setLoading] = useState(-1);
    const [selected, setSelected] = useState();
    const [businessType, setBusinessType] = useState()

    const handleChange = e => {
        e.preventDefault()
        const { id } = e.target
        let newValue = e.target.value
        setFields(prevState => ({
            ...prevState,
            [id]: newValue
        }))
    }

    const upgradePlan = async (e) => {
        e?.preventDefault();
        if (fields.name) {
            if (!nameFieldValidator(fields?.name)) {
                fields.name = fields?.name.replace(/[^A-Za-z ]/gi, '')
                return toast.error('No special characters except "-" and "_" are allowed in name', { id: 'error' })
            } else if (fields?.name.length > 50) {
                return toast.error('Name must be less than 50 chracters')
            }
        }
        if (!fields.name) {
            return toast.error('Please enter the company name', { id: 'error' })
        }
        if (fields.website) {
            if (!validator.isURL(fields.website)) {
                return toast.error('Invalid company website url', { id: 'error' })
            }
        }
        if (!fields.website) {
            return toast.error('Please enter valid company website', { id: 'error' })
        }
        if (fields.bio?.length < 5) {
            return toast.error('Please fill the company details ')
        }
        if (!selected?.planId) {
            return toast.error('Please select a plan to continue')
        }

        setLoading(50);
        let res = await createOrganization({ ...fields, plan_id: selected?.planId, type: businessType?.name, details: selected?.name });
        if (res.success) {
            handleFreelanceData({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            toast.success("Profile Upgraded Successfully!");
            window.location.reload();
            setFields({
                name: '',
                website: '',
                bio: ''
            });
            setBusinessType();
            setIndex(0);
        }
        else if (res.data == 'exist') {
            toast.error(res.message)
        }

        setLoading(-1);
    }

    return (
        <>
            <div className='flex items-center justify-center fixed inset-0 h-screen overflow-hidden' style={{ zIndex: 1001 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-60 ' style={{ zIndex: 10 }} onClick={() => { setActive(false); setIndex(0) }}>
                </div>
                <div style={{ zIndex: 10 }} className='rounded-lg bg-white w-full p-10 pb-5 max-w-6xl min-h-[90vh]'>
                    <div className='w-full bg-gray-300 h-1 relative my-4'>
                        <div className={`h-1 rounded-xl bg-blue absolute left-0 top-0 ${index == 1 ? 'w-1/3' : index == 2 ? 'w-2/3' : index == 3 ? 'w-full' : ''}`} />
                        <div className='w-5 h-5 rounded-full absolute transform top-1/2 -translate-y-1/2 left-0 bg-blue' onClick={() => setIndex(0)} />
                        <div className={`w-5 h-5 rounded-full absolute transform top-1/2 -translate-y-1/2 left-1/3 -translate-x-1/3 ${index >= 1 ? 'bg-blue' : 'bg-gray-300'}`} onClick={() => setIndex(1)} />
                        {selected?.id !== 3 ? <div className={`w-5 h-5 rounded-full absolute transform top-1/2 -translate-y-1/2 right-1/3 ${index >= 2 ? 'bg-blue' : 'bg-gray-300'}`} onClick={() => setIndex(2)} /> : ''}
                        <div className={`w-5 h-5 rounded-full absolute transform top-1/2 -translate-y-1/2 right-0 ${index >= 3 ? 'bg-blue' : 'bg-gray-300'}`} onClick={() => setIndex(3)} />
                    </div>
                    <div className='w-full flex items-center gap-5'>
                        {index == 2 ? <></> : <div className={`p-5 w-96 h-96 flex-shrink-0 ${index == 2 && 'p-0'}`}>
                            <img className='w-full h-full object-contain' src={index !== 2 ? MoneyPlant : MoneyJar} alt='' />
                        </div>}

                        <div className='p-5 flex-grow min-w-[320px] min-h-[65vh] flex flex-col '>
                            {index == 0 && <div className='py-6 h-[50vh]'>
                                <h1 className='text-lg font-semibold'>Benefits you’ll get</h1>
                                <ol className='text-sm lg:text-base p-3 text-gray-600 list-decimal space-y-2'>
                                    <li>Nunc in ac neque, diam posuere felis, nec mattis.</li>
                                    <li> In sit montes, leo consectetur in venenatis pellentesque rhoncus.</li>
                                    <li> Porta sodales viverra a porta quam ut dictum ut quis.</li>
                                    <li> Vitae id libero, vulputate duis dolor, diam et ut.</li>
                                    <li> Nunc in ac neque, diam posuere felis, nec mattis.</li>
                                    <li> In sit montes, leo consectetur in venenatis pellentesque rhoncus.</li>
                                    <li> Porta sodales viverra a porta quam ut dictum ut quis.</li>
                                    <li> Vitae id libero, vulputate duis dolor, diam et ut.</li>
                                </ol>
                            </div>}
                            {
                                index == 3 && <div className='py-6 h-[50vh]'>
                                    <div className=''>
                                        <TextField size="small" label={selected?.id == 3 ? "Team Name" : "Company Name"} name="name" id="name" value={fields.name} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                                    </div>
                                    <div className='my-6'>
                                        <TextField size="small" label={selected?.id == 3 ? "Team Display Name" : "Company Display Name"} name="display_name" id="display_name" value={fields?.display_name} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                                    </div>
                                    {selected?.id == 3 ? <></> : <div className='my-6'>
                                        <TextField size="small" label="Company Website" name="website" id="website" value={fields?.website} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                                    </div>}
                                    <div className='my-6'>
                                        <TextField multiline={true} minRows={5} size="small" label={selected?.id == 3 ? "Team Details" : "Company Details"} name="bio" id="bio" value={fields?.bio} onChange={handleChange} variant="outlined" className={`w-full bg-white`} />
                                    </div>
                                    {/* <div className=' bg-white border rounded-md w-full px-4 py-2 text-lg flex items-center gap-4'>
                                        <select onChange={handleChange} name="type" id="type" value={fields.type} className="outline-none w-full">
                                            <option aria-label='None' value='' selected disabled>Select Company Type</option>
                                            <option value="retailer">Retailer</option>
                                            <option value="service">Service</option>
                                            <option value="product">Product</option>
                                        </select>
                                    </div> */}
                                </div>
                            }

                            {
                                index == 1 && <div className='pt-6 h-[60vh]'>
                                    <div className='flex items-center w-full justify-between'>
                                        <h1 className='text-lg font-semibold'>Choose Plan</h1>
                                        <div>
                                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-l-3xl ${monthly ? 'text-yellow bg-white' : 'text-white bg-yellow'}`} onClick={() => setMonthly(false)}>Yearly</button>
                                            <button className={`border border-yellow-400 py-1.5 px-5 font-medium rounded-r-3xl ${monthly ? 'text-white bg-yellow' : 'text-yellow bg-white'}`} onClick={() => setMonthly(true)}>Monthly</button>
                                        </div>
                                    </div>
                                    {/* <h1 className='font-medium text-lg text-center mt-6 text-gray-500'>Plan list will be here</h1> */}
                                    <div className='flex items-start justify-center flex-wrap gap-5 mt-10'>
                                        {
                                            plans.map(plan => <div key={plan.id} onClick={() => setSelected(plan)} className={`p-3 border cursor-pointer rounded-md ${selected?.id == plan.id ? 'bg-blue-500' : 'bg-white'} shadow group hover:bg-blue-500 flex items-start justify-between gap-3 max-w-[200px] min-w-[10rem] h-[7.5rem]`}>
                                                <div className='h-full flex flex-col'>
                                                    <h1 className={`${selected?.id == plan?.id ? 'text-white' : 'text-navy'} text-sm font-bold flex-grow group-hover:text-white`}>{plan.name}</h1>
                                                    <p className={`text-xs ${selected?.id == plan?.id ? 'text-white' : 'text-navy'} font-medium group-hover:text-white font-serif`}>$<span className='text-base'>{plan.price * (monthly ? 1 : 11)}</span></p>
                                                    <p className={`${selected?.id == plan?.id ? 'text-gray-50' : 'text-gray-500'} group-hover:text-gray-50 text-xs`}>per {monthly ? 'month' : 'year'}</p>
                                                </div>
                                                <img className="w-16 h-16 p-1 object-contain" src={plan.icon} alt="" />
                                            </div>)
                                        }
                                    </div>
                                    <div className='text-right mt-4'>
                                        <h1>Learn more about <span className='text-blue hover:text-blue-600 cursor-pointer' onClick={() => history.push("/pricing")}>Plan & Pricing</span></h1>
                                    </div>
                                </div>
                            }
                            {
                                index == 2 && <div className='py-6 h-[50vh]'>

                                    <div className='grid grid-cols-3 gap-10'>
                                        {
                                            businessTypes?.map(item => <div key={item.id} onClick={() => setBusinessType(item)} className={`rounded-lg cursor-pointer border-2 w-full max-w-xs overflow-hidden`}>
                                                <div className={`${businessType?.id == item.id ? 'bg-blue' : 'bg-gray-300'} h-40 p-4`}>
                                                    {businessType?.id == item.id ? <svg className='mx-auto' width="120" height="120" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M50.5 78.125L69.25 96.875L100.5 59.375" stroke="white" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M75.5 137.5C110.018 137.5 138 109.518 138 75C138 40.4822 110.018 12.5 75.5 12.5C40.9822 12.5 13 40.4822 13 75C13 109.518 40.9822 137.5 75.5 137.5Z" stroke="white" stroke-width="12" />
                                                    </svg> : ''}
                                                </div>
                                                <div className={`p-4 ${businessType?.id == item.id ? 'bg-blue' : ''}`}>
                                                    <h1 className={`${businessType?.id == item.id ? 'text-white' : 'text-gray-800'} text-lg font-medium capitalize`}>{item.name}</h1>
                                                    <p className={`${businessType?.id == item.id ? 'text-white' : 'text-gray-400'} text-sm text-justify`}>{item?.description}</p>
                                                </div>

                                            </div>)
                                        }
                                    </div>

                                </div>
                            }
                            <div className='flex items-center justify-between gap-4 pt-6'>
                                {index !== 3 ? <>
                                    <button className='text-lg' onClick={() => index == 1 ? setIndex(0) : index == 2 ? setIndex(1) : index == 3 ? setIndex(2) : {}}>{index >= 1 ? 'Back' : ''}</button>
                                    <button className='text-white bg-blue rounded-md py-1.5 px-8 text-lg font-medium' onClick={() => index == 0 ? setIndex(1) : index == 1 ? selected.id != 3 ? setIndex(2) : setIndex(3) : setIndex(3)}>{index == 0 ? 'Next' : 'Save & Next'}</button>
                                </>
                                    :
                                    <>
                                        <button className='text-lg' onClick={() => selected.id != 3 ? setIndex(2) : setIndex(1)}>Back</button>
                                        <div className='text-right space-y-2'>
                                            <button className='text-white bg-blue rounded-md py-1.5 px-8 text-lg font-medium' onClick={upgradePlan}>Continue</button>
                                            {/* <h1>Learn more about <span className='text-blue hover:text-blue-600 cursor-pointer' onClick={() => history.push("/pricing")}>Plan & Pricing</span></h1> */}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(loading >= 0 && loading <= 100) && <div className='flex gap-8 flex-col items-center justify-center fixed inset-0 h-screen overflow-hidden' style={{ zIndex: 1004 }}>
                <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-90 ' style={{ zIndex: 10 }}>
                </div>
                <h1 className='text-4xl text-white font-semibold text-center z-10'>Upgrading your profile...</h1>
                <div className='rounded-lg bg-white w-full max-w-2xl h-2 z-10 relative overflow-hidden'>
                    <div className={`rounded-xl bg-blue z-20 absolute left-0 top-0 h-2 transition-loader`} />
                </div>
            </div>
            }
        </>
    )
}
