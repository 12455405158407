import React from 'react'
import Navbar from '../ReusableComponents/Navbar'
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getEvents } from '../../service/service';
import moment from 'moment';

const types = [
    {
        id: 1,
        name: 'Competitions',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mx-auto p-2 rounded-lg bg-blue-100 text-navy">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
        </svg>

    },
    {
        id: 2,
        name: 'Hackathons',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mx-auto p-2 rounded-lg bg-blue-100 text-navy">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
        </svg>

    },
    {
        id: 3,
        name: 'Conferences',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mx-auto p-2 rounded-lg bg-blue-100 text-navy">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>

    },
    {
        id: 4,
        name: 'Online Meetup',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mx-auto p-2 rounded-lg bg-blue-100 text-navy">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
        </svg>

    }
]


function EventsHome() {
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        let res = await getEvents();
        if (res.success) {
            setEvents(res.data)
        }
    }
    useEffect(() => {
        fetchEvents();
    }, [])

    return (
        <>
            <Navbar background='#F6FAFD' />
            <div className='min-h-[90vh] bg-[#F6FAFD] p-5'>
                <div className='min-h-[45vh] flex flex-col items-center justify-center gap-4'>
                    <h1 className='text-3xl md:text-4xl xl:text-5xl font-bold text-navy text-center'>Welcome To Events</h1>
                    <p className='lg:text-lg text-gray-600 text-center'>explore hackathons, competitions, conferences and much more</p>
                    <div className='p-2 my-2 flex gap-2 border w-full max-w-sm rounded items-center relative flex-grow-0 bg-white'>
                        <SearchIcon />
                        <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" className='flex-grow outline-none border-none bg-transparent' />
                    </div>

                    <div className='flex flex-wrap items-center justify-center gap-6 my-2'>
                        {types.map((item, i) => <div key={i} className='p-2 text-gray-600 text-center max-w-max'>
                            {item.icon}
                            <p>{item.name}</p>
                        </div>)}
                    </div>
                </div>
                {/* <hr className='w-full my-8 mx-auto max-w-7xl' /> */}
                <div className='lg:px-10'>
                    <div className='py-2 border-b flex items-center justify-between w-full'>
                        <h1 className='text-2xl lg:text-3xl font-medium text-navy'>Trending events across greyffiti</h1>
                        <span className='text-blue text-lg hover:text-blue-700 cursor-pointer'>Show All</span>
                    </div>
                    {
                        !events.length
                            ? <h1 className='text-3xl font-semibold text-gray-600 text-center mt-10'>No Events Available </h1>
                            :
                            <div className='flex flex-wrap items-center justify-start gap-10 lg:gap-16 py-10'>
                                {events.map((item, i) => item.title.toLowerCase().includes(search.toLowerCase()) && <>
                                    <div key={i} className='w-full max-w-xs bg-white rounded-xl overflow-hidden cursor-pointer' onClick={() => history.push(`/events/${item.id}`)}>
                                        <div className='w-full h-44 relative'>
                                            <img className='w-full h-full object-cover' src={item.thumbnail} alt="" />
                                            <div className='w-full h-44 bg-gradient-to-t from-black to-transparent grid place-items-center absolute inset-0'>
                                                <span className='text-gray-800 font-medium py-1 px-4 rounded-3xl bg-white absolute top-2 right-2 capitalize'>{item.type}</span>
                                                <p className='text-white font-medium text-lg absolute bottom-2 left-4 capitalize'>{item.title}</p>
                                            </div>
                                        </div>
                                        {item.event_start && <div className='px-4 py-2 bg-white'>
                                            <p className='text-base'>{moment(item.event_start).format("DD MMM, ddd")} | <span className='capitalize'>{item.mode}</span></p>
                                            <p className='text-base'>{moment(item.event_start).format("HH:mm A")} to {moment(item.event_end).format("HH:mm A")}</p>
                                            <p  className='text-sm mt-1 text-red'>{!item?.freeevent ? 'Paid' : 'Free'}</p>
                                        </div>}
                                    </div>
                                </>
                                )}
                            </div>
                    }
                </div>

            </div>
        </>
    )
}

export default EventsHome
// https://images.unsplash.com/photo-1668586556253-24f561a92712