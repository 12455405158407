import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    routeList: null,
}

export const routeSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        routeList: (state, action) => {
            state.routeList = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { routeList } = routeSlice.actions

export const selectRouteList = (state) => state.route.routeList

export default routeSlice.reducer