import "./constant.css"

export const accessRights = [
  {
    id: 1,
    name: 'View & Edit',
    color: '#62C57E',
    icon: 'https://greyffiti2021.blob.core.windows.net/greyffiti/1686632162039_pencil.svg',
  },
  {
    id: 2,
    name: 'View Only',
    color: '#FBD449',
    icon: 'https://greyffiti2021.blob.core.windows.net/greyffiti/1686632177099_book.svg',
  }
]

export const commonQs = [
  {
    question: "What is Greyffiti?",
    answer: "Greyffiti is a free collaborative tool for visual engineers which allows users to upload, share, collaborate and view projects.",
    open: true
  },
  {
    question: "Is greyffiti free?",
    answer: "Greyffiti is free for personal use and has payment tiers based on your use cases and organization types. For more information refer to the pricing page from here.",
    open: false
  },
  {
    question: "Can I use greyffiti without sign in?",
    answer: 'Yes to view public projects. To create projects, upload files, add or be a contributor you must sign in. We suggest to sign up to get the most out of greyffiti.',
    open: false
  },
  {
    question: "How can I protect my project from non authorized users viewing it?",
    answer: "You can select between public or private projects. Private projects are restricted for your own selected contributors. Public projects can be viewed by registered and non registered users can view and download contents of the projects but cannot modify any of the contents.",
    open: false
  },
  {
    question: "Is the desktop app mandatory?",
    answer: "No, for files upto 500 mb, files over 500 mb we recommend you using desktop app for faster upload times.",
    open: false
  },
  {
    question: "How can I share projects or files in greyffiti?",
    answer: "For public project you can use the share button next to the project name. for private project you must add the user as a contributor.",
    open: false
  },
  {
    question: "How much data can I store?",
    answer: "Depending on the payment tier the values differ please refer to the pricing page from here for more details.",
    open: false
  },
  {
    question: "What is copywriting and IP protection in greyffiti?",
    answer: "We as greyffiti do not own any of the files uploaded, the uploader of the files are the owner.",
    open: false
  }
]
export const commonQsOld = [
  {
    question: "What is Greyffiti?",
    answer: "Greyffiti is a platform solely for designers, architects, draftsmen to store, share and deliver securely. There is no need of sharing through informal ways with your customers.",
    open: true
  },
  {
    question: "How to use Greyffiti?",
    answer: "Greyffiti is a simple and user friendly to use. It has simple structure to save files based on the project you are handling.",
    open: false
  },
  {
    question: "Can I register to use?",
    answer: ["Yes. Please visit the page", <a href="https://greyffiti.com"> (https://greyffiti.com) </a>, "for further information. Select what is suitable for your use and register."],
    open: false
  },
  {
    question: "Is my data safe?",
    answer: "Yes. Your data is protected, and you have all the authority to share information to others of your choice.",
    open: false
  },
  {
    question: "What is standalone application? Why should I install?",
    answer: "Greyffiti need to handle huge files of drawings for 3D models, Architecture. To save space for our users and bandwidth while uploading files, standalone application compresses them.",
    open: false
  },
  {
    question: "How do I share files with others?",
    answer: "You can share a repository by making it as public which all users of Greyffiti can view. If you would like to share to people of your choice, then you can do that too.",
    open: false
  },
  {
    question: "Can others contribute to my work?",
    answer: "Yes. You can add contributors to your repository, and they too can start collaborating along with you.",
    open: false
  },
  {
    question: "Can others download my files?",
    answer: "It depends on your permissions to others. If they have permission to download, then they can.",
    open: false
  },
  {
    question: "Can I go back to previous version of my file?",
    answer: " Yes. You can choose to revert to previous version or old version of the file based on your necessity.",
    open: false
  },
  {
    question: "I need more information on this platform, can I connect with Greyffiti?",
    answer: "Yes. You can drop an email (info@greyffiti.com) to Greyffiti team with your question and contact details.  We will respond on the working days and take the conversation further.",
    open: false
  }
]

export const privacyLists = [
  {
    msg: "kinds of personal information that may be collected by us",
  },
  {
    msg: "measures taken by us to ensure its privacy and security",
  },
  {
    msg: "duration for which it may be retained by us; and",
  },
  {
    msg: "how you may access and control its use",
  },
];

export const privacyquestions = [
  {
    title: "Your Information is Safe",
    subTitle: `We at Nebutech Analytics LLP (hereinafter mentioned as
        "NEBUTECH") on behalf of greyffiti.com are committed to protect our
        customer's personal information and/or sensitive personal data
        and strive to maintain the privacy of your personal
        informtion. For your information, "Personal information" is any
        information that can be used by itself to uniquely
        identify,contact,or locate a person,or can be used with
        information available from other sources to uniquely identify an
        individual. For the purpose of this policy, sensitive personal
        data or information has been considered as apart of personal
        information.`,
    subTitle2: `NEBUTECH's Privacy Policy is designed and developed to address
        the privacy and security of your personal information provided
        to us. This Privacy Policy describes the personal information
        which we may collect and provides our approach towards handling
        or dealing with the same.The policy is designed to make you
        understand:`
  },
];

export const privacyQuestionAns = [
  {
    question: "Collection of Personal Information",
    answer: `NEBUTECH and its authorized third parties will connect
        information pertaining to your identity, demographics, and
        related evidentiary documentation.For the purpose of this
        document, a 'Third Party' is a service provider who associates
        with NEBUTECH and is involved in handling, managing, storing,
        processing, protecting and transmitting information of NEBUTECH.
        This definition also includes all sub-contractors, consultants
        and/or representatives of the Third party. We may also collect
        your personal information when you use our service or websites
        or otherwise interact with us during the course of our
        relationship. Personal information collected and held by us may
        include tour name, date of birth, current addresses, mobilephone
        number, email address, occupation and information contained for
        any payments if required. NEBUTECH and its authorized third
        parties collect, store, process following types of Sensitive
        Personal Information such as password, financial information (
        details of Bank account, credit card, debit card, or other
        payment instrument details), physiological information for
        providing our products, services and for use of our website. We
        may also hold information related to your utilization of our
        services which may include your browsing history on our website,
        location details and additional information provided by you
        while using our services. We may keep a log of the activities
        performed by you on our network and websites by using various
        internet techniques such as web cookies, web beacons, server log
        files, etc. for analytical purposes and for analysis of the
        amiability of various features on our site.This Information may
        be used to provide you with a better experience at our portal
        along with evidentiary purposes. At any time while you are
        surfing our site, if you do not wish to share surfing
        information, you may opt out of receiving the cookies from our
        site by making appropriate changes to your browser privacy
        settings. In case you do not provide your information or consent
        for usuage of personal information or later on withdraw your
        consent for usuage of the personal information so collected,
        NEBUTECH reserves the right to not provide the services or to
        withdraw the services for which the said information was sought.`
  },
  {
    question: `Disclosure and Transfer of Personal Information`,
    answer: `Internal Use: As explained in the section “Collection of
        Personal Information” NEBUTECH and its employees may utilize
        some or all available personal information for intern,
        marginTop: "5px"al assessments, measures, operations and related
        activities. Authorized Third Parties: NEBUTECH may at its
        discretion employ, contract or include third parties (as defined
        in section 1: Collection of personal information) external to
        itself for strategic, tactical and operational purposes. Such
        agencies though external to NEBUTECH, will always be entities
        which are covered by contractual agreements. These agreements in
        turn include NEBUTECH’s guidelines to the management, treatment
        and secrecy of personal information. We may transfer your
        personal information or other information collected, stored,
        processed by us to any other entity or organization located in
        India or outside India only in case it is necessary for
        providing services to you or if you have consented (at the time
        of collection of information) to the same. This may also include
        sharing of aggregated information with them in order for them to
        understand our environment and consequently, provide you with
        better services. While sharing your personal information with
        third parties, adequate measures shall be taken to ensure that
        reasonable security practices are followed at the third party.
        We may obtain your consent for sharing your personal information
        in several ways, such as in writing, online, through
        “click-through” agreements; orally, including through
        interactive voice response; or when your consent is part of the
        terms and conditions pursuant to which we provide you service.
        We, however assure you that NEBUTECH does not disclose your
        personal information to unaffiliated third parties (parties
        outside NEBUTECH corporate network and its Strategic and
        Business Partners) which could lead to invasion of your privacy.
        Government Agencies: We may also share your personal information
        with Government agencies or other authorized law enforcement
        agencies (LEAs) mandated under law to obtain such information
        for the purpose of verification of identity or for prevention,
        detection, investigation including but not limited to cyber
        incidents, prosecution, and punishment of offences.`
  },
  {
    question: `Security Practices and Procedures`,
    answer: `We adopt reasonable security practices and procedures, in line
        with international standard, to include, technical, operational,
        managerial and physical security controls in order to protect
        your personal information from unauthorized access, or
        disclosure while it is under our control. Our security practices
        and procedures limit access to personal information on need-only
        basis. Further, our employees are bound by Code of Conduct and
        Confidentiality Policies which obligate them to protect the
        confidentiality of personal information. We take adequate steps
        to ensure that our third parties adopt reasonable level of
        security practices and procedures to ensure security of personal
        information. We may retain your personal information for as long
        as required to provide you with services or if otherwise
        required under any law. When we dispose of your personal
        information, we use reasonable procedures to erase it or render
        it unreadable (for example, shredding documents and wiping
        electronic media). Internet Use - We maintain the security of
        our internet connections, however for reasons outside of our
        control, security risks may still arise. Any personal
        information transmitted to us or from our online products or
        services will therefore be your own risk. However, we will
        strive to ensure the security of your information. We observe
        reasonable security measures to protect your personal
        information against hacking and virus dissemination.`
  },
  {
    question: `Update of Personal Information`,
    answer: `We strive to keep our records updated with your latest
        information. To this end, if you see any discrepancy in your
        personal information or if a part of your personal information
        changes, we request you to write to us at support@nebutech.in
        and communicate the change(s) for updating our records. In case
        of non-compliance with the terms and conditions and privacy
        policy, NEBUTECH reserves the right to remove your non-compliant
        information from its systems. Further, depending on the
        seriousness of the non-compliance, we may choose to discontinue
        some or all of the services being provided to you by us.`
  },
  {
    question: `Feedback and Concerns`,
    answer: `We are committed to safeguard your personal information
        collected and handled by us and look forward to your continued
        support for the same. In case of any feedback or concern
        regarding protection of your personal information, you can
        contact us at support@nebutech.in. We will strive to address
        your feedback and concerns in a timely and effective manner.
        NEBUTECH reserves the right to amend or modify this Privacy
        Policy at any time, as and when the need arises. We request you
        to visit our website http://nebutech.company.in/ periodically
        for contemporary information and changes.`
  }

]



export const timeZoneCityToCountry = {
  "Europe/Andorra": "Andorra",
  "Asia/Dubai": "United Arab Emirates",
  "Asia/Kabul": "Afghanistan",
  "Europe/Tirane": "Albania",
  "Asia/Yerevan": "Armenia",
  "Antarctica/Casey": "Antarctica",
  "Antarctica/Davis": "Antarctica",
  "Antarctica/Mawson": "Antarctica",
  "Antarctica/Palmer": "Antarctica",
  "Antarctica/Rothera": "Antarctica",
  "Antarctica/Troll": "Antarctica",
  "Antarctica/Vostok": "Antarctica",
  "America/Argentina/Buenos_Aires": "Argentina",
  "America/Argentina/Cordoba": "Argentina",
  "America/Argentina/Salta": "Argentina",
  "America/Argentina/Jujuy": "Argentina",
  "America/Argentina/Tucuman": "Argentina",
  "America/Argentina/Catamarca": "Argentina",
  "America/Argentina/La_Rioja": "Argentina",
  "America/Argentina/San_Juan": "Argentina",
  "America/Argentina/Mendoza": "Argentina",
  "America/Argentina/San_Luis": "Argentina",
  "America/Argentina/Rio_Gallegos": "Argentina",
  "America/Argentina/Ushuaia": "Argentina",
  "Pacific/Pago_Pago": "Samoa (American)",
  "Europe/Vienna": "Austria",
  "Australia/Lord_Howe": "Australia",
  "Antarctica/Macquarie": "Australia",
  "Australia/Hobart": "Australia",
  "Australia/Melbourne": "Australia",
  "Australia/Sydney": "Australia",
  "Australia/Broken_Hill": "Australia",
  "Australia/Brisbane": "Australia",
  "Australia/Lindeman": "Australia",
  "Australia/Adelaide": "Australia",
  "Australia/Darwin": "Australia",
  "Australia/Perth": "Australia",
  "Australia/Eucla": "Australia",
  "Asia/Baku": "Azerbaijan",
  "America/Barbados": "Barbados",
  "Asia/Dhaka": "Bangladesh",
  "Europe/Brussels": "Belgium",
  "Europe/Sofia": "Bulgaria",
  "Atlantic/Bermuda": "Bermuda",
  "Asia/Brunei": "Brunei",
  "America/La_Paz": "Bolivia",
  "America/Noronha": "Brazil",
  "America/Belem": "Brazil",
  "America/Fortaleza": "Brazil",
  "America/Recife": "Brazil",
  "America/Araguaina": "Brazil",
  "America/Maceio": "Brazil",
  "America/Bahia": "Brazil",
  "America/Sao_Paulo": "Brazil",
  "America/Campo_Grande": "Brazil",
  "America/Cuiaba": "Brazil",
  "America/Santarem": "Brazil",
  "America/Porto_Velho": "Brazil",
  "America/Boa_Vista": "Brazil",
  "America/Manaus": "Brazil",
  "America/Eirunepe": "Brazil",
  "America/Rio_Branco": "Brazil",
  "Asia/Thimphu": "Bhutan",
  "Europe/Minsk": "Belarus",
  "America/Belize": "Belize",
  "America/St_Johns": "Canada",
  "America/Halifax": "Canada",
  "America/Glace_Bay": "Canada",
  "America/Moncton": "Canada",
  "America/Goose_Bay": "Canada",
  "America/Toronto": "Canada",
  "America/Nipigon": "Canada",
  "America/Thunder_Bay": "Canada",
  "America/Iqaluit": "Canada",
  "America/Pangnirtung": "Canada",
  "America/Winnipeg": "Canada",
  "America/Rainy_River": "Canada",
  "America/Resolute": "Canada",
  "America/Rankin_Inlet": "Canada",
  "America/Regina": "Canada",
  "America/Swift_Current": "Canada",
  "America/Edmonton": "Canada",
  "America/Cambridge_Bay": "Canada",
  "America/Yellowknife": "Canada",
  "America/Inuvik": "Canada",
  "America/Dawson_Creek": "Canada",
  "America/Fort_Nelson": "Canada",
  "America/Whitehorse": "Canada",
  "America/Dawson": "Canada",
  "America/Vancouver": "Canada",
  "Indian/Cocos": "Cocos (Keeling) Islands",
  "Europe/Zurich": "Switzerland",
  "Africa/Abidjan": "Côte d'Ivoire",
  "Pacific/Rarotonga": "Cook Islands",
  "America/Santiago": "Chile",
  "America/Punta_Arenas": "Chile",
  "Pacific/Easter": "Chile",
  "Asia/Shanghai": "China",
  "Asia/Urumqi": "China",
  "America/Bogota": "Colombia",
  "America/Costa_Rica": "Costa Rica",
  "America/Havana": "Cuba",
  "Atlantic/Cape_Verde": "Cape Verde",
  "Indian/Christmas": "Christmas Island",
  "Asia/Nicosia": "Cyprus",
  "Asia/Famagusta": "Cyprus",
  "Europe/Prague": "Czech Republic",
  "Europe/Berlin": "Germany",
  "Europe/Copenhagen": "Denmark",
  "America/Santo_Domingo": "Dominican Republic",
  "Africa/Algiers": "Algeria",
  "America/Guayaquil": "Ecuador",
  "Pacific/Galapagos": "Ecuador",
  "Europe/Tallinn": "Estonia",
  "Africa/Cairo": "Egypt",
  "Africa/El_Aaiun": "Western Sahara",
  "Europe/Madrid": "Spain",
  "Africa/Ceuta": "Spain",
  "Atlantic/Canary": "Spain",
  "Europe/Helsinki": "Finland",
  "Pacific/Fiji": "Fiji",
  "Atlantic/Stanley": "Falkland Islands",
  "Pacific/Chuuk": "Micronesia",
  "Pacific/Pohnpei": "Micronesia",
  "Pacific/Kosrae": "Micronesia",
  "Atlantic/Faroe": "Faroe Islands",
  "Europe/Paris": "France",
  "Europe/London": "Britain (UK)",
  "Asia/Tbilisi": "Georgia",
  "America/Cayenne": "French Guiana",
  "Europe/Gibraltar": "Gibraltar",
  "America/Nuuk": "Greenland",
  "America/Danmarkshavn": "Greenland",
  "America/Scoresbysund": "Greenland",
  "America/Thule": "Greenland",
  "Europe/Athens": "Greece",
  "Atlantic/South_Georgia": "South Georgia & the South Sandwich Islands",
  "America/Guatemala": "Guatemala",
  "Pacific/Guam": "Guam",
  "Africa/Bissau": "Guinea-Bissau",
  "America/Guyana": "Guyana",
  "Asia/Hong_Kong": "Hong Kong",
  "America/Tegucigalpa": "Honduras",
  "America/Port-au-Prince": "Haiti",
  "Europe/Budapest": "Hungary",
  "Asia/Jakarta": "Indonesia",
  "Asia/Pontianak": "Indonesia",
  "Asia/Makassar": "Indonesia",
  "Asia/Jayapura": "Indonesia",
  "Europe/Dublin": "Ireland",
  "Asia/Jerusalem": "Israel",
  "Asia/Kolkata": "India",
  "Asia/Calcutta": "India",
  "Indian/Chagos": "British Indian Ocean Territory",
  "Asia/Baghdad": "Iraq",
  "Asia/Tehran": "Iran",
  "Atlantic/Reykjavik": "Iceland",
  "Europe/Rome": "Italy",
  "America/Jamaica": "Jamaica",
  "Asia/Amman": "Jordan",
  "Asia/Tokyo": "Japan",
  "Africa/Nairobi": "Kenya",
  "Asia/Bishkek": "Kyrgyzstan",
  "Pacific/Tarawa": "Kiribati",
  "Pacific/Kanton": "Kiribati",
  "Pacific/Kiritimati": "Kiribati",
  "Asia/Pyongyang": "Korea (North)",
  "Asia/Seoul": "Korea (South)",
  "Asia/Almaty": "Kazakhstan",
  "Asia/Qyzylorda": "Kazakhstan",
  "Asia/Qostanay": "Kazakhstan",
  "Asia/Aqtobe": "Kazakhstan",
  "Asia/Aqtau": "Kazakhstan",
  "Asia/Atyrau": "Kazakhstan",
  "Asia/Oral": "Kazakhstan",
  "Asia/Beirut": "Lebanon",
  "Asia/Colombo": "Sri Lanka",
  "Africa/Monrovia": "Liberia",
  "Europe/Vilnius": "Lithuania",
  "Europe/Luxembourg": "Luxembourg",
  "Europe/Riga": "Latvia",
  "Africa/Tripoli": "Libya",
  "Africa/Casablanca": "Morocco",
  "Europe/Monaco": "Monaco",
  "Europe/Chisinau": "Moldova",
  "Pacific/Majuro": "Marshall Islands",
  "Pacific/Kwajalein": "Marshall Islands",
  "Asia/Yangon": "Myanmar (Burma)",
  "Asia/Ulaanbaatar": "Mongolia",
  "Asia/Hovd": "Mongolia",
  "Asia/Choibalsan": "Mongolia",
  "Asia/Macau": "Macau",
  "America/Martinique": "Martinique",
  "Europe/Malta": "Malta",
  "Indian/Mauritius": "Mauritius",
  "Indian/Maldives": "Maldives",
  "America/Mexico_City": "Mexico",
  "America/Cancun": "Mexico",
  "America/Merida": "Mexico",
  "America/Monterrey": "Mexico",
  "America/Matamoros": "Mexico",
  "America/Mazatlan": "Mexico",
  "America/Chihuahua": "Mexico",
  "America/Ojinaga": "Mexico",
  "America/Hermosillo": "Mexico",
  "America/Tijuana": "Mexico",
  "America/Bahia_Banderas": "Mexico",
  "Asia/Kuala_Lumpur": "Malaysia",
  "Asia/Kuching": "Malaysia",
  "Africa/Maputo": "Mozambique",
  "Africa/Windhoek": "Namibia",
  "Pacific/Noumea": "New Caledonia",
  "Pacific/Norfolk": "Norfolk Island",
  "Africa/Lagos": "Nigeria",
  "America/Managua": "Nicaragua",
  "Europe/Amsterdam": "Netherlands",
  "Europe/Oslo": "Norway",
  "Asia/Kathmandu": "Nepal",
  "Pacific/Nauru": "Nauru",
  "Pacific/Niue": "Niue",
  "Pacific/Auckland": "New Zealand",
  "Pacific/Chatham": "New Zealand",
  "America/Panama": "Panama",
  "America/Lima": "Peru",
  "Pacific/Tahiti": "French Polynesia",
  "Pacific/Marquesas": "French Polynesia",
  "Pacific/Gambier": "French Polynesia",
  "Pacific/Port_Moresby": "Papua New Guinea",
  "Pacific/Bougainville": "Papua New Guinea",
  "Asia/Manila": "Philippines",
  "Asia/Karachi": "Pakistan",
  "Europe/Warsaw": "Poland",
  "America/Miquelon": "St Pierre & Miquelon",
  "Pacific/Pitcairn": "Pitcairn",
  "America/Puerto_Rico": "Puerto Rico",
  "Asia/Gaza": "Palestine",
  "Asia/Hebron": "Palestine",
  "Europe/Lisbon": "Portugal",
  "Atlantic/Madeira": "Portugal",
  "Atlantic/Azores": "Portugal",
  "Pacific/Palau": "Palau",
  "America/Asuncion": "Paraguay",
  "Asia/Qatar": "Qatar",
  "Indian/Reunion": "Réunion",
  "Europe/Bucharest": "Romania",
  "Europe/Belgrade": "Serbia",
  "Europe/Kaliningrad": "Russia",
  "Europe/Moscow": "Russia",
  "Europe/Simferopol": "Russia",
  "Europe/Kirov": "Russia",
  "Europe/Volgograd": "Russia",
  "Europe/Astrakhan": "Russia",
  "Europe/Saratov": "Russia",
  "Europe/Ulyanovsk": "Russia",
  "Europe/Samara": "Russia",
  "Asia/Yekaterinburg": "Russia",
  "Asia/Omsk": "Russia",
  "Asia/Novosibirsk": "Russia",
  "Asia/Barnaul": "Russia",
  "Asia/Tomsk": "Russia",
  "Asia/Novokuznetsk": "Russia",
  "Asia/Krasnoyarsk": "Russia",
  "Asia/Irkutsk": "Russia",
  "Asia/Chita": "Russia",
  "Asia/Yakutsk": "Russia",
  "Asia/Khandyga": "Russia",
  "Asia/Vladivostok": "Russia",
  "Asia/Ust-Nera": "Russia",
  "Asia/Magadan": "Russia",
  "Asia/Sakhalin": "Russia",
  "Asia/Srednekolymsk": "Russia",
  "Asia/Kamchatka": "Russia",
  "Asia/Anadyr": "Russia",
  "Asia/Riyadh": "Saudi Arabia",
  "Pacific/Guadalcanal": "Solomon Islands",
  "Indian/Mahe": "Seychelles",
  "Africa/Khartoum": "Sudan",
  "Europe/Stockholm": "Sweden",
  "Asia/Singapore": "Singapore",
  "America/Paramaribo": "Suriname",
  "Africa/Juba": "South Sudan",
  "Africa/Sao_Tome": "Sao Tome & Principe",
  "America/El_Salvador": "El Salvador",
  "Asia/Damascus": "Syria",
  "America/Grand_Turk": "Turks & Caicos Is",
  "Africa/Ndjamena": "Chad",
  "Indian/Kerguelen": "French Southern & Antarctic Lands",
  "Asia/Bangkok": "Thailand",
  "Asia/Dushanbe": "Tajikistan",
  "Pacific/Fakaofo": "Tokelau",
  "Asia/Dili": "East Timor",
  "Asia/Ashgabat": "Turkmenistan",
  "Africa/Tunis": "Tunisia",
  "Pacific/Tongatapu": "Tonga",
  "Europe/Istanbul": "Turkey",
  "Pacific/Funafuti": "Tuvalu",
  "Asia/Taipei": "Taiwan",
  "Europe/Kiev": "Ukraine",
  "Europe/Uzhgorod": "Ukraine",
  "Europe/Zaporozhye": "Ukraine",
  "Pacific/Wake": "US minor outlying islands",
  "America/New_York": "United States",
  "America/Detroit": "United States",
  "America/Kentucky/Louisville": "United States",
  "America/Kentucky/Monticello": "United States",
  "America/Indiana/Indianapolis": "United States",
  "America/Indiana/Vincennes": "United States",
  "America/Indiana/Winamac": "United States",
  "America/Indiana/Marengo": "United States",
  "America/Indiana/Petersburg": "United States",
  "America/Indiana/Vevay": "United States",
  "America/Chicago": "United States",
  "America/Indiana/Tell_City": "United States",
  "America/Indiana/Knox": "United States",
  "America/Menominee": "United States",
  "America/North_Dakota/Center": "United States",
  "America/North_Dakota/New_Salem": "United States",
  "America/North_Dakota/Beulah": "United States",
  "America/Denver": "United States",
  "America/Boise": "United States",
  "America/Phoenix": "United States",
  "America/Los_Angeles": "United States",
  "America/Anchorage": "United States",
  "America/Juneau": "United States",
  "America/Sitka": "United States",
  "America/Metlakatla": "United States",
  "America/Yakutat": "United States",
  "America/Nome": "United States",
  "America/Adak": "United States",
  "Pacific/Honolulu": "United States",
  "America/Montevideo": "Uruguay",
  "Asia/Samarkand": "Uzbekistan",
  "Asia/Tashkent": "Uzbekistan",
  "America/Caracas": "Venezuela",
  "Asia/Ho_Chi_Minh": "Vietnam",
  "Pacific/Efate": "Vanuatu",
  "Pacific/Wallis": "Wallis & Futuna",
  "Pacific/Apia": "Samoa (western)",
  "Africa/Johannesburg": "South Africa",
  "America/Antigua": "Antigua & Barbuda",
  "America/Anguilla": "Anguilla",
  "Africa/Luanda": "Angola",
  "Antarctica/McMurdo": "Antarctica",
  "Antarctica/DumontDUrville": "Antarctica",
  "Antarctica/Syowa": "Antarctica",
  "America/Aruba": "Aruba",
  "Europe/Mariehamn": "Åland Islands",
  "Europe/Sarajevo": "Bosnia & Herzegovina",
  "Africa/Ouagadougou": "Burkina Faso",
  "Asia/Bahrain": "Bahrain",
  "Africa/Bujumbura": "Burundi",
  "Africa/Porto-Novo": "Benin",
  "America/St_Barthelemy": "St Barthelemy",
  "America/Kralendijk": "Caribbean NL",
  "America/Nassau": "Bahamas",
  "Africa/Gaborone": "Botswana",
  "America/Blanc-Sablon": "Canada",
  "America/Atikokan": "Canada",
  "America/Creston": "Canada",
  "Africa/Kinshasa": "Congo (Dem. Rep.)",
  "Africa/Lubumbashi": "Congo (Dem. Rep.)",
  "Africa/Bangui": "Central African Rep.",
  "Africa/Brazzaville": "Congo (Rep.)",
  "Africa/Douala": "Cameroon",
  "America/Curacao": "Curaçao",
  "Europe/Busingen": "Germany",
  "Africa/Djibouti": "Djibouti",
  "America/Dominica": "Dominica",
  "Africa/Asmara": "Eritrea",
  "Africa/Addis_Ababa": "Ethiopia",
  "Africa/Libreville": "Gabon",
  "America/Grenada": "Grenada",
  "Europe/Guernsey": "Guernsey",
  "Africa/Accra": "Ghana",
  "Africa/Banjul": "Gambia",
  "Africa/Conakry": "Guinea",
  "America/Guadeloupe": "Guadeloupe",
  "Africa/Malabo": "Equatorial Guinea",
  "Europe/Zagreb": "Croatia",
  "Europe/Isle_of_Man": "Isle of Man",
  "Europe/Jersey": "Jersey",
  "Asia/Phnom_Penh": "Cambodia",
  "Indian/Comoro": "Comoros",
  "America/St_Kitts": "St Kitts & Nevis",
  "Asia/Kuwait": "Kuwait",
  "America/Cayman": "Cayman Islands",
  "Asia/Vientiane": "Laos",
  "America/St_Lucia": "St Lucia",
  "Europe/Vaduz": "Liechtenstein",
  "Africa/Maseru": "Lesotho",
  "Europe/Podgorica": "Montenegro",
  "America/Marigot": "St Martin (French)",
  "Indian/Antananarivo": "Madagascar",
  "Europe/Skopje": "North Macedonia",
  "Africa/Bamako": "Mali",
  "Pacific/Saipan": "Northern Mariana Islands",
  "Africa/Nouakchott": "Mauritania",
  "America/Montserrat": "Montserrat",
  "Africa/Blantyre": "Malawi",
  "Africa/Niamey": "Niger",
  "Asia/Muscat": "Oman",
  "Africa/Kigali": "Rwanda",
  "Atlantic/St_Helena": "St Helena",
  "Europe/Ljubljana": "Slovenia",
  "Arctic/Longyearbyen": "Svalbard & Jan Mayen",
  "Europe/Bratislava": "Slovakia",
  "Africa/Freetown": "Sierra Leone",
  "Europe/San_Marino": "San Marino",
  "Africa/Dakar": "Senegal",
  "Africa/Mogadishu": "Somalia",
  "America/Lower_Princes": "St Maarten (Dutch)",
  "Africa/Mbabane": "Eswatini (Swaziland)",
  "Africa/Lome": "Togo",
  "America/Port_of_Spain": "Trinidad & Tobago",
  "Africa/Dar_es_Salaam": "Tanzania",
  "Africa/Kampala": "Uganda",
  "Pacific/Midway": "US minor outlying islands",
  "Europe/Vatican": "Vatican City",
  "America/St_Vincent": "St Vincent",
  "America/Tortola": "Virgin Islands (UK)",
  "America/St_Thomas": "Virgin Islands (US)",
  "Asia/Aden": "Yemen",
  "Indian/Mayotte": "Mayotte",
  "Africa/Lusaka": "Zambia",
  "Africa/Harare": "Zimbabwe"
}